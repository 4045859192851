import { useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Alert } from '@mui/material';
import { EMAIL_LIMIT_PER_ROLLING_24H } from '@realadvisor/constants';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import { useAppData } from '../providers/AppDataProvider';

const SENT_EMAIL_COUNT_OVER_ROLLING_24H_QUERY = gql(/* GraphQL */ `
  query SentEmailCountOverRolling24H($userId: uuid!, $date: timestamptz!) {
    email_messages_aggregate(
      where: {
        created_by: { _eq: $userId }
        is_sent: { _eq: true }
        status: { _eq: "sent" }
        date: { _gte: $date }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const EmailCounterAlert = ({
  onCount,
}: {
  onCount?: (limitReached: boolean) => void;
}) => {
  const { t } = useLocale();
  const { me } = useAppData();

  const nowMinus24Hours = useMemo(() => {
    return new Date(Date.now() - 24 * 60 * 60 * 1000);
  }, []);

  const { data } = useQuery(SENT_EMAIL_COUNT_OVER_ROLLING_24H_QUERY, {
    variables: {
      userId: me?.id ?? '',
      date: nowMinus24Hours.toISOString(),
    },
    skip: me?.id == null,
  });

  const limitReached =
    (data?.email_messages_aggregate?.aggregate?.count ?? 0) >=
    EMAIL_LIMIT_PER_ROLLING_24H;

  useEffect(() => {
    if (onCount) {
      onCount(limitReached);
    }
  }, [limitReached, onCount]);

  return (
    <Alert
      sx={{
        marginX: 3,
      }}
      severity={limitReached ? 'error' : 'info'}
    >
      {limitReached
        ? t(
            'You have reached the limit of emails ({{limit}}) you can send in a rolling 24 hours',
            {
              limit: EMAIL_LIMIT_PER_ROLLING_24H,
            },
          )
        : t(
            'You have sent {{count}} / {{limit}} emails in the last rolling 24 hours',
            {
              count: data?.email_messages_aggregate?.aggregate?.count ?? 0,
              limit: EMAIL_LIMIT_PER_ROLLING_24H,
            },
          )}
    </Alert>
  );
};
