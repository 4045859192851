import { createContext, useEffect, useMemo } from 'react';

import {
  gql,
  makeVar,
  useApolloClient,
  useReactiveVar,
  useSuspenseQuery,
} from '@apollo/client';
import {
  type IntrospectionQuery,
  type IntrospectionType,
  type IntrospectionTypeRef,
  getIntrospectionQuery,
} from 'graphql/utilities/getIntrospectionQuery';

const createSchemaQuery = (isScraperSchema?: boolean) =>
  gql(
    getIntrospectionQuery().replace(
      'query IntrospectionQuery',
      `query ${isScraperSchema ? 'scrapers' : ''}IntrospectionQuery`,
    ),
  );

// Create separate schema vars for each client
export const defaultSchemaVar = makeVar<
  IntrospectionQuery['__schema'] | undefined
>(undefined);
export const scrapersSchemaVar = makeVar<
  IntrospectionQuery['__schema'] | undefined
>(undefined);

export interface SchemaContext {
  getTypeFromPath: (path: string[]) => IntrospectionType | undefined;
  getTypeByName: (name: string) => IntrospectionType | undefined;
  resolveTypeName: (type: IntrospectionType) => string | null;
  getValueFromPath: (path: string[], where: any) => any;
  isNullable: (path: string[]) => boolean;
}

type SchemaInitializerProps = {
  isScraperSchema?: boolean;
};

export const SchemaInitializer = ({
  isScraperSchema,
}: SchemaInitializerProps) => {
  const client = useApolloClient();
  const accessToken = localStorage.getItem('access_token');
  const schemaVar = isScraperSchema ? scrapersSchemaVar : defaultSchemaVar;

  const query = useMemo(
    () => createSchemaQuery(isScraperSchema),
    [isScraperSchema],
  );

  const { data } = useSuspenseQuery<IntrospectionQuery>(query, {
    skip:
      schemaVar() != null ||
      accessToken == null ||
      location.pathname.includes('select-tenant') ||
      location.pathname.includes('login'),
    fetchPolicy: 'no-cache',
    context: isScraperSchema ? { clientName: 'scrapers' } : undefined,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    schemaVar(data.__schema);
    const normalizedData = client.cache.extract();
    client.cache.restore({
      ...normalizedData,
      [isScraperSchema ? 'scrapersSchema' : 'schema']: {
        __schema: data.__schema,
      },
    });
  }, [data, client, isScraperSchema, schemaVar]);

  return null;
};

export const SchemaContext = createContext<SchemaContext | undefined>(
  undefined,
);

export const getValueFromPath = (path: string[], where: any) => {
  const [, ...rest] = path;
  let value = where;
  for (let i = 0; i < rest.length; i++) {
    value = value?.[rest[i]];
  }
  return value;
};

interface SchemaProviderProps {
  children: React.ReactNode;
  isScraperSchema?: boolean;
}

export const SchemaProvider = ({
  children,
  isScraperSchema,
}: SchemaProviderProps) => {
  const typeNameCache = new Map();
  const schemaVar = isScraperSchema ? scrapersSchemaVar : defaultSchemaVar;
  // Use reactive var instead of cache, so it can update itself once the schema is loaded
  const schema = useReactiveVar(schemaVar);

  const types = schema?.types as IntrospectionType[];

  const getTypeByName = useMemo(() => {
    const typeByNameCache = new Map();
    return (name: string): IntrospectionType | undefined => {
      if (!types) {
        return undefined;
      }

      if (typeByNameCache.has(name)) {
        return typeByNameCache.get(name);
      }

      const type = types.find(t => t.name === name);
      typeByNameCache.set(name, type);

      if (!type) {
        throw new Error(`Could not find type with name ${name}`);
      }

      return type;
    };
  }, [types]);

  const resolveTypeName = (type: IntrospectionType | IntrospectionTypeRef) => {
    const typeKey = JSON.stringify(type);
    if (typeNameCache.has(typeKey)) {
      return typeNameCache.get(typeKey);
    }

    let currentType = type;

    while (currentType) {
      if (currentType.kind === 'LIST' || currentType.kind === 'NON_NULL') {
        currentType = currentType.ofType;
      } else if (currentType.name) {
        typeNameCache.set(typeKey, currentType.name);
        return currentType.name;
      } else {
        break;
      }
    }

    typeNameCache.set(typeKey, null);
    return null;
  };

  const getTypeFromPath = (path: string[]) => {
    if (!path?.length) {
      return undefined;
    }

    const [baseType, ...rest] = path;
    let currentType = getTypeByName(baseType);

    if (!currentType) {
      return undefined;
    }

    let isNullable = true;

    for (const key of rest) {
      if (currentType?.kind !== 'INPUT_OBJECT') {
        return undefined;
      }

      // Special case for _and and _or items, we skip the index part of the path.
      if (!isNaN(parseInt(key))) {
        continue;
      }

      const field = currentType?.inputFields?.find(f => f.name === key);
      if (!field) {
        return undefined;
      }

      // Check field's type nullability
      let fieldType = field.type;
      isNullable = true;

      while (fieldType) {
        if (fieldType.kind === 'NON_NULL') {
          isNullable = false;
          fieldType = fieldType.ofType;
          break;
        } else if (fieldType.kind === 'LIST') {
          fieldType = fieldType.ofType;
        } else {
          break;
        }
      }

      const typeName = resolveTypeName(field.type);
      if (!typeName) {
        return undefined;
      }

      currentType = getTypeByName(typeName);
      if (!currentType) {
        return undefined;
      }
    }

    return { type: currentType, isNullable };
  };

  const isNullable = (path: string[]): boolean => {
    const result = getTypeFromPath(path);
    return result?.isNullable ?? true;
  };

  // Provide the memoized functions through context
  return (
    <SchemaContext.Provider
      value={{
        getTypeFromPath: path => getTypeFromPath(path)?.type,
        getTypeByName,
        resolveTypeName,
        getValueFromPath,
        isNullable,
      }}
    >
      {children}
    </SchemaContext.Provider>
  );
};
