import { createContext, useContext, useMemo } from 'react';

import { useSuspenseQuery } from '@apollo/client';
import { parseJwt } from '@realadvisor/auth-client';

import { gql } from '../__generated__';
import { type MeQuery } from '../__generated__/graphql';

const ME_QUERY = gql(/* GraphQL */ `
  query Me {
    get_me {
      user_ids_in_led_teams
      show_plans
      me {
        id
        is_admin
        is_broker
        language
        # GTM UserDetails params (used for GTM intercom setup)
        first_name
        last_name
        full_name
        has_used_trial_button
        has_free_crm_access
        auto_assign_leads
        can_user_login_as
        can_edit_settings
        modules
        show_in_agency_pages
        tenant_id
        intercom_hash
        default_team {
          id
          name
          lng
          lat
          show_in_agency_pages
        }
        teams_users {
          team_id
        }
        emails {
          id
          email
          primary
          verified
        }
        tenant {
          resources_en
          resources_fr
          resources_de
          resources_es
          resources_it
          id
          name
          country_code
          lng
          lat
          help_link_en
          help_link_fr
          help_link_de
          help_link_it
          help_link_es
          image {
            url
          }
        }
        subscriptions(order_by: { user_id: asc, status: asc }, limit: 1) {
          status
          customer
        }
        phone_numbers(order_by: { primary: desc, verified: desc }, limit: 1) {
          id
          number
          primary
          verified
        }
        user_images(where: { is_primary: { _eq: true } }, limit: 1) {
          id
          image {
            id
            url
          }
        }
        teams: teams_users(where: { deactivated: { _eq: false } }) {
          is_leader
          team_id
          team {
            id
            organisation_id
          }
        }
        nylas_account: logins(where: { provider: { _eq: "nylas-v3" } }) {
          id
          invalid
        }
      }
    }
  }
`);

export type Me =
  | (MeQuery['get_me']['me'] & {
      show_plans: MeQuery['get_me']['show_plans'];
      user_ids_in_led_teams: NonNullable<
        MeQuery['get_me']['user_ids_in_led_teams']
      >;
    })
  | null;

export const AppDataContext = createContext<{
  me: Me;
  noAccess?: { userId: string };
} | null>(null);

export const AppDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const accessToken = localStorage.getItem('access_token');
  let decodedToken: any;
  let isNotAdminNorBroker = false;
  let isMultitenantRole = false;
  // decode access token
  if (accessToken) {
    decodedToken = parseJwt(accessToken);
    isMultitenantRole =
      decodedToken?.['https://hasura.io/jwt/claims'][
        'x-hasura-default-role'
      ] === 'multitenant';
    if (
      decodedToken.user?.isAdmin === false &&
      decodedToken.user?.isBroker === false
    ) {
      // user is not admin or broker, so he cannot access crm, redirect to noaccess page
      isNotAdminNorBroker = true;
    }
  }

  const { data } = useSuspenseQuery(ME_QUERY, {
    skip:
      accessToken == null ||
      isNotAdminNorBroker ||
      isMultitenantRole ||
      (location.pathname.includes('noaccess') && isNotAdminNorBroker) ||
      location.pathname.includes('select-tenant') ||
      location.pathname.includes('login'),
    fetchPolicy: 'no-cache',
  });

  // Query schema in background
  const appData = useMemo(() => {
    if (data?.get_me.me == null || data.get_me.me.tenant == null) {
      if (accessToken == null || isMultitenantRole) {
        return {
          me: null,
        };
      }
      return {
        noAccess: { userId: decodedToken.user?.id },
        me: null,
      };
    }

    return {
      me: {
        ...data.get_me.me,
        show_plans: data.get_me.show_plans,
        user_ids_in_led_teams: data.get_me.user_ids_in_led_teams ?? [],
      },
    };
  }, [data?.get_me, accessToken, decodedToken, isMultitenantRole]);

  return (
    <AppDataContext.Provider value={appData}>
      {children}
    </AppDataContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AppDataContext);

  if (context == null) {
    throw Error('useAppData must be used within an AppDataProvider');
  }
  return context;
};
