// @flow

import * as React from 'react';

import createMentionPlugin, {
  MentionSuggestions,
} from '@draft-js-plugins/mention';
import { computePosition, flip, size } from '@floating-ui/dom';
import { List, ListItem, ListItemText, Paper } from '@mui/material';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import getRangesForDraftEntity from 'draft-js/lib/getRangesForDraftEntity';
import { createPortal } from 'react-dom';

import { useLocale } from '../../src/hooks/locale';
import { useTheme } from '../hooks/theme';

function getAllEntities(contentState: ContentState) {
  const entities = [];
  contentState.getBlockMap().forEach(block => {
    const blockKey = block.getKey();
    block.findEntityRanges(character => {
      const entityKey = character.getEntity();

      if (entityKey != null) {
        const style = character.getStyle();
        const entity = contentState.getEntity(entityKey);
        const entityType = entity.getType();

        if (entityType !== '#mention') {
          return;
        }

        entities.push({
          entity,
          entityKey,
          style,
          block,
          blockKey,
        });
      }
    });
  });
  return entities;
}

export const applySuggestions = (
  editorState: EditorState,
  suggestions: { [string]: string, ... },
): EditorState => {
  let contentState = editorState.getCurrentContent();

  const entities = getAllEntities(contentState);
  entities.forEach(({ entity, entityKey, style, blockKey }) => {
    const block = contentState.getBlockForKey(blockKey);
    const entityData = entity.getData();
    const mention = entityData.mention;

    const name = suggestions[mention.id] || '';
    contentState = contentState.replaceEntityData(entityKey, {
      mention: {
        name,
        id: mention.id,
      },
    });

    let entitySelection;
    getRangesForDraftEntity(block, entityKey).forEach(range => {
      entitySelection = new SelectionState({
        anchorOffset: range.start,
        anchorKey: blockKey,
        focusOffset: range.end,
        focusKey: blockKey,
        isBackward: false,
      });
    });

    if (entitySelection) {
      contentState = Modifier.replaceText(
        contentState,
        entitySelection,
        name,
        style,
        entityKey,
      );
    }
  });

  return EditorState.push(editorState, contentState, 'apply-entity');
};

type ColorsForLink = {|
  backgroundColor: string,
  linkClr: string,
|};

const getReportTypeFromText = (link: string) => {
  try {
    const url = new URL(link);
    const typeFromUrl = url.searchParams.get('documentId');
    if (typeFromUrl != null) {
      const type = atob(typeFromUrl).split(':')[0];
      if (type === 'Lot') {
        return 'lot';
      }
      if (type === 'Lead') {
        return 'appraisal';
      }
      if (type === 'CmaReport') {
        return 'cma';
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

const getElemForReportLink = (
  t: (text: string) => string,
  link: string,
  reportType: string,
  { backgroundColor, linkClr }: ColorsForLink,
) => {
  if (reportType) {
    let label = t('appraisalReport');
    if (reportType === 'lot') {
      label = t('lotBrochure');
    }
    if (reportType === 'cma') {
      label = t('cmaReport');
    }
    return (
      <span
        css={{
          display: 'inline-block',
          backgroundColor,
          color: linkClr,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <a href={link}>{label}</a>
      </span>
    );
  }
  return null;
};
const Mention = ({ children }) => {
  const { colors } = useTheme();
  const { t } = useLocale();
  const childElem = child => {
    return (
      <span
        css={{
          display: 'inline-block',
          backgroundColor: colors.blue50,
          color: colors.blue700,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {child}
      </span>
    );
  };
  if (Array.isArray(children) && children[0]) {
    const reportLink = children[0].props.text;
    const reportType = getReportTypeFromText(reportLink);
    if (reportType != null) {
      const linkElem = getElemForReportLink(t, reportLink, reportType, {
        backgroundColor: colors.blue50,
        linkClr: colors.blue700,
      });
      return linkElem;
    }
    return childElem(children);
  }
  return childElem(children);
};

const SuggestionEntry = ({
  mention,
  isFocused,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
}) => {
  const { text, colors } = useTheme();
  return (
    <ListItem
      selected={isFocused}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
    >
      <ListItemText>
        <Mention>{mention.name}</Mention>
        {mention.id !== mention.name && (
          <div css={[text.caption, { color: colors.mediumText }]}>
            {mention.id}
          </div>
        )}
      </ListItemText>
    </ListItem>
  );
};

const PopoverComponent = React.forwardRef((props, ref) => {
  if (document.body == null) {
    return null;
  }
  return createPortal(
    <Paper
      ref={ref}
      css={{
        marginTop: '0.4em',
        position: 'absolute',
        width: 320,
        minWidth: 320,
        maxWidth: 320,
        cursor: 'pointer',
        zIndex: 10000,
        overflowY: 'scroll',
      }}
    >
      <List
        css={{
          span: {
            maxWidth: '280px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      >
        {/* $FlowFixMe[prop-missing] fix cloneElement shit */}
        {props.children}
      </List>
    </Paper>,
    document.body,
  );
});

const filterSuggestions = (suggestions, value) => {
  value = value.toLowerCase().trim();
  // Always search by id
  // TODO: we can implement better search
  const filteredSuggestions = suggestions.filter(
    suggestion =>
      value.length === 0 || suggestion.id.toLowerCase().includes(value),
  );
  return filteredSuggestions.slice(0, 20);
};

type Suggestion = {|
  id: string,
  name: string,
|};

type SuggestionsProps = {|
  suggestions: $ReadOnlyArray<Suggestion>,
|};

const Suggestions = ({ suggestions, ...props }: SuggestionsProps) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  return (
    <MentionSuggestions
      {...props}
      open={open}
      onOpenChange={value => setOpen(value)}
      popoverComponent={<PopoverComponent />}
      entryComponent={SuggestionEntry}
      suggestions={filterSuggestions(suggestions, search)}
      onSearchChange={({ value }) => setSearch(value)}
    />
  );
};

type SuggestionsPlugin = {|
  Suggestions: SuggestionsProps => React.Node,
  suggestionsPlugin: any,
|};

export type PositionSuggestionsParams = {|
  decoratorRect: ClientRect,
  popover: HTMLElement,
  props: {|
    open: boolean,
    suggestions: any[],
    store: any,
  |},
|};

const positionSuggestions = ({
  decoratorRect,
  popover,
}: PositionSuggestionsParams) => {
  const virtualReference = {
    getBoundingClientRect() {
      return decoratorRect;
    },
  };
  computePosition(virtualReference, popover, {
    placement: 'bottom-start',
    middleware: [
      flip(),
      size({
        apply: ({ availableHeight, elements }) => {
          const { reference } = elements;
          const rect = reference.getBoundingClientRect();
          popover.style.width = `${rect.width}px`;
          popover.style.maxHeight = `${Math.min(250, availableHeight)}px`;
        },
      }),
    ],
  }).then(({ x, y }) => {
    popover.style.position = 'absolute';
    popover.style.left = `${x}px`;
    popover.style.top = `${y}px`;
  });
  return {};
};

export const createSuggestionsPlugin = (): SuggestionsPlugin => {
  const suggestionsPlugin = createMentionPlugin({
    mentionComponent: ({ children }) => <Mention>{children}</Mention>,
    mentionSuggestionsComponent: Suggestions,
    mentionTrigger: '#',
    entityMutability: 'IMMUTABLE',
    positionSuggestions,
  });
  return {
    suggestionsPlugin,
    Suggestions: suggestionsPlugin.MentionSuggestions,
  };
};
