import { useEffect, useMemo, useRef, useState } from 'react';

import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import type {
  DateRange,
  PickersActionBarProps,
  PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { endOfDay, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';

import { type Translate, useLocale } from '../../../../src/hooks/locale';
import { fromDateStringToUTC, toUTCDate } from '../../../utils/dates';
import { useFiltersSearchParams } from '../useFiltersSearchParams';

import { FilterChip } from './FilterChip';
import { type QuickFilterProps } from './QuickFilters';

const shortcutsItems: (
  t: Translate,
) => PickersShortcutsItem<DateRange<Date>>[] = (t: Translate) => [
  {
    label: t('Today'),
    getValue: () => {
      const today = new Date();
      return [startOfDay(today), endOfDay(today)];
    },
  },
  {
    label: t('Yesterday'),
    getValue: () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return [startOfDay(yesterday), endOfDay(yesterday)];
    },
  },
  {
    label: t('Last week'),
    getValue: () => {
      const today = new Date();
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);
      return [
        startOfWeek(lastWeek, { weekStartsOn: 1 }),
        endOfWeek(lastWeek, { weekStartsOn: 1 }),
      ];
    },
  },
  {
    label: t('This month'),
    getValue: () => {
      const today = new Date();
      return [
        startOfDay(new Date(today.getFullYear(), today.getMonth(), 1)),
        endOfDay(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
      ];
    },
  },
  {
    label: t('Last 30 days'),
    getValue: () => {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setDate(today.getDate() - 30);
      return [startOfDay(lastMonth), endOfDay(today)];
    },
  },
  {
    label: t('Last month'),
    getValue: () => {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);
      return [
        startOfDay(new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1)),
        endOfDay(
          new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0),
        ),
      ];
    },
  },
  {
    label: t('This year'),
    getValue: () => {
      const today = new Date();
      return [
        startOfDay(new Date(today.getFullYear(), 0, 1)),
        endOfDay(new Date(today.getFullYear(), 11, 31)),
      ];
    },
  },
];

const CustomToolBar = (props: any) => {
  const {
    value: [from, to],
    onRangePositionChange,
    onChange,
    ownerState: { onAccept },
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // Create refs for both date inputs
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  // Automatically focus the appropriate input when the range position changes
  useEffect(() => {
    if (props.rangePosition === 'start' && startInputRef.current) {
      startInputRef.current.focus();
    } else if (props.rangePosition === 'end' && endInputRef.current) {
      endInputRef.current.focus();
    }
  }, [props.rangePosition]);

  return (
    <form
      style={{
        gridColumn: isTablet ? 1 : 2,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
      }}
      onSubmit={e => {
        e.preventDefault();
        onAccept();
      }}
    >
      <Box sx={{ flexGrow: 1, pt: 2, px: 4 }}>
        <DateField
          inputRef={startInputRef}
          autoFocus={props.rangePosition === 'start'}
          value={from}
          onChange={(date, { validationError }) => {
            if (!validationError) {
              onChange([date, to]);
            }
          }}
          variant="outlined"
          size="small"
          fullWidth
          onFocus={() => onRangePositionChange('start')}
        />
      </Box>
      <Box sx={{ flexGrow: 1, pt: 2, px: 4 }}>
        <DateField
          inputRef={endInputRef}
          autoFocus={props.rangePosition === 'end'}
          value={to}
          onChange={(date, { validationError }) => {
            if (!validationError) {
              onChange([from, date]);
            }
          }}
          variant="outlined"
          size="small"
          fullWidth
          onFocus={() => onRangePositionChange('end')}
        />
      </Box>
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

const parseDateOrInterval = (
  dateString: string,
  accountForTz: boolean,
): number => {
  if (dateString.includes('now()')) {
    const interval = dateString.replace('now()', '').trim();
    const regex = /(\d{1,4})\s{1,5}(years?|months?|days?)/g;

    const match = regex.exec(interval);

    if (match) {
      const [, value] = match;
      const unit = match[0].replace(/\d+\s*/, '');
      const isSubtraction = interval.includes('-');
      const intervalValue = parseInt(value) * (isSubtraction ? -1 : 1);

      const now = new Date();

      if (unit.startsWith('day')) {
        now.setDate(now.getDate() + intervalValue);
      } else if (unit.startsWith('month')) {
        now.setMonth(now.getMonth() + intervalValue);
      } else if (unit.startsWith('year')) {
        now.setFullYear(now.getFullYear() + intervalValue);
      }

      return now.getTime();
    }
  }

  return accountForTz
    ? new Date(dateString).getTime()
    : fromDateStringToUTC(dateString).getTime();
};

export const extractDateRangeValues = (
  valueFromPath: any,
  accountForTz = true,
) => {
  const startDate = valueFromPath?._gte
    ? parseDateOrInterval(valueFromPath?._gte, accountForTz)
    : null;
  const endDate = valueFromPath?._lte
    ? parseDateOrInterval(valueFromPath?._lte, accountForTz)
    : null;

  return {
    startDate,
    endDate,
  };
};

export const DateFilter = ({
  label,
  path,
  where,
  addWhereClause,
  getValueFromPath,
  deleteWhereClause,
  queryParamsScope,
  disabled,
  type,
}: QuickFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);
  const { dateLocale, t } = useLocale();
  const pathValue = getValueFromPath(path, where);
  const isDateType = type.name === 'date_comparison_exp';

  const { startDate, endDate } = extractDateRangeValues(pathValue, !isDateType);

  const [range, setRange] = useState<DateRange<Date>>([
    startDate ? new Date(startDate) : null,
    endDate ? new Date(endDate) : null,
  ]);
  const [rangePosition, setRangePosition] = useState<'start' | 'end'>('start');

  useEffect(() => {
    setRange([
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
    ]);
  }, [startDate, endDate]);

  const handleAccept = (handleClose: () => void) => {
    let newWhere = deleteWhereClause(where, path, true);
    let _gte: null | Date = null;
    let _lte: null | Date = null;

    if (range[0]) {
      _gte = isDateType ? toUTCDate(range[0]) : startOfDay(range[0]);
    }

    if (range[1]) {
      _lte = isDateType ? toUTCDate(range[1], true) : endOfDay(range[1]);
    }

    if (_gte) {
      newWhere = addWhereClause(newWhere, [...path, '_gte'], _gte);
    }
    if (_lte) {
      newWhere = addWhereClause(newWhere, [...path, '_lte'], _lte);
    }
    setFiltersParams(newWhere);
    handleClose();
  };

  const labelValueStart = range[0] ? format(range[0], 'd MMM yyyy') : '...';
  const labelValueEnd = range[1] ? format(range[1], 'd MMM yyyy') : '...';
  const labelValue =
    range[0] || range[1] ? `${labelValueStart} - ${labelValueEnd}` : null;
  const displayLabel = labelValue ? [label, labelValue].join(': ') : label;

  const handleDelete =
    range[0] || range[1]
      ? () => {
          setRange([null, null]);
          setFiltersParams(deleteWhereClause(where, path, true));
        }
      : undefined;

  const memoizedShortcutsItems = useMemo(() => shortcutsItems(t), [t]);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FilterChip
      label={displayLabel}
      onDelete={handleDelete}
      icon={<DateRangeIcon />}
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <Paper elevation={3} sx={{ borderRadius: 3, mt: 0.5 }}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={dateLocale}
            localeText={{
              cancelButtonLabel: t('Cancel'),
            }}
          >
            <StaticDateRangePicker
              calendars={isMobile ? 1 : 2}
              rangePosition={rangePosition}
              onRangePositionChange={setRangePosition}
              value={range}
              slots={{
                toolbar: CustomToolBar,
              }}
              slotProps={{
                shortcuts: {
                  items: memoizedShortcutsItems,
                  onChange: setRange,
                },
                actionBar: {
                  onCancel: handleClose,
                  onAccept: () => handleAccept(handleClose),
                } as PickersActionBarProps,
              }}
              onChange={([start, end]) => {
                setRange([start, end]);
              }}
              sx={{
                borderRadius: 3,
                '.MuiDateRangeCalendar-root': {
                  ...(isTablet && {
                    margin: '0 auto',
                  }),
                },
                '& .MuiPickersLayout-shortcuts': {
                  gridArea: '2 / 1 / 3',
                  ...((isTablet || isMobile) && {
                    overflowY: 'auto',
                    display: 'flex',
                    margin: '0 auto',
                    paddingTop: 1.5,
                    paddingBottom: 2,
                    '& .MuiListItem-root': {
                      paddingLeft: 0,
                    },
                    '& .MuiListItem-root:last-child': {
                      paddingRight: 0,
                    },
                  }),
                  ...(isTablet && {
                    maxWidth: 560,
                  }),
                  ...(isMobile && {
                    maxWidth: 253,
                  }),
                },
                '& .MuiPickersLayout-contentWrapper': {
                  gridArea: '2 / 2',
                  ...(isTablet && {
                    gridArea: '3 / 1',
                  }),
                },
                '& .MuiPickersLayout-actionBar': {
                  ...(isTablet && {
                    gridArea: '4 / 1',
                  }),
                },
                '& .MuiPickersArrowSwitcher-root': {
                  ...((isTablet || isMobile) && {
                    paddingTop: 0,
                  }),
                },
              }}
            />
          </LocalizationProvider>
        </Paper>
      )}
    />
  );
};
