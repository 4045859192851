// @flow

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { graphql, useLazyLoadQuery } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';

import type { TemplatesDialogQuery } from './__generated__/TemplatesDialogQuery.graphql';

type Props = {|
  open: boolean,
  onClose: () => void,
  // TODO cover with proper types
  onTemplateSelect: (node: any) => void,
|};

/* eslint-disable relay/unused-fields */
const TemplatesList = ({ search, onTemplateSelect }) => {
  const { t } = useLocale();
  const data = useLazyLoadQuery<TemplatesDialogQuery>(
    graphql`
      query TemplatesDialogQuery($search: String) {
        emailTemplates(
          first: 100
          filters: {
            lotId_null: true
            developmentId_null: true
            search: $search
          }
        ) {
          totalCount
          edges {
            node {
              id
              name
              cc
              text
              subject
              subjectPlaintext
              addSignature
              files {
                id
                size
                name
                url
                mimetype
              }
            }
          }
        }
      }
    `,
    { search },
  );
  /* eslint-enable relay/unused-fields */

  const { textColor, text } = useTheme();

  const templates = [];
  for (const edge of data.emailTemplates?.edges ?? []) {
    if (edge?.node != null) {
      templates.push(edge.node);
    }
  }

  return templates.length ? (
    templates.map(node => (
      <ListItem
        key={node.id}
        button={true}
        onClick={() => onTemplateSelect(node)}
      >
        <ListItemText
          primary={node.name}
          secondary={node.subjectPlaintext}
          css={text.truncate(1)}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
    ))
  ) : (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      p={3}
    >
      <Box css={[textColor('disabledText')]} mb={4}>
        {t('No templates')}
      </Box>
      <Button
        component={Link}
        to={`/emails/templates/add`}
        color="primary"
        variant="outlined"
      >
        {t('addTemplate')}
      </Button>
    </Flex>
  );
};

const TemplatesDialogContent = ({ onTemplateSelect }) => {
  const { t } = useLocale();
  const [search, setSearch] = React.useState('');
  return (
    <>
      <Box px={3} css={{ minHeight: '60px' }}>
        <TextField
          label={t('search')}
          variant="filled"
          value={search}
          onChange={event => setSearch(event.target.value)}
        />
      </Box>
      <List>
        <React.Suspense fallback={null}>
          <TemplatesList search={search} onTemplateSelect={onTemplateSelect} />
        </React.Suspense>
      </List>
    </>
  );
};

export const TemplatesDialog = ({
  onClose,
  onTemplateSelect,
  open,
}: Props): React.Node => {
  const { t } = useLocale();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      css={{
        '.MuiDialog-paper': {
          height: '100%',
        },
      }}
    >
      <DialogTitle>{t('selectTemplate')}</DialogTitle>
      <TemplatesDialogContent onTemplateSelect={onTemplateSelect} />
    </Dialog>
  );
};
