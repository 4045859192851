import * as React from 'react';

import { ButtonBase, Dialog, DialogTitle } from '@mui/material';
import * as ReactDOM from 'react-dom';
import { Box, Flex, useSystem } from 'react-system';

import { useTheme } from '../hooks/theme';
import { CloseOutline } from '../icons/close-outline';
import { ExpandOutline } from '../icons/expand-outline';
import { MinimizeOutline } from '../icons/minimize-outline';

type Props = {
  open: boolean;
  disableCollapse?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  onCollapse?: () => void;
  title: string;
  children: React.ReactNode;
  extraActions?: {
    onClick: () => void;
    title: string;
    icon: React.ReactNode;
  }[];
};

const Portal = ({ children }: { children: React.ReactNode }) => {
  if (document.body == null) {
    return null;
  } else {
    return ReactDOM.createPortal(children, document.body);
  }
};

export const CollapsableDialog = (props: Props) => {
  const { text, colors, borderRadius, depth } = useTheme();
  const { media } = useSystem();
  // Must maintain its own state to know when to show minimized to tray button etc.
  const [open, setOpen] = React.useState(props.open);

  return (
    <>
      <Dialog
        open={props.open || open}
        onClose={props.onClose}
        fullScreen={props.fullScreen}
      >
        <DialogTitle>
          <Flex alignItems="center">
            <Box css={[text.h6, { fontWeight: text.font.medium }]}>
              {props.title}
            </Box>
            <div
              css={{
                flexGrow: 1,
                display: 'grid',
                justifyContent: 'flex-end',
                gap: 12,
                gridTemplateColumns: 'repeat(auto-fit, 20px)',
              }}
            >
              {props.extraActions != null &&
                props.extraActions.map(node => (
                  <React.Fragment key={node.title}>
                    <ButtonBase
                      title={node.title}
                      focusRipple={true}
                      onClick={node.onClick}
                    >
                      {node.icon}
                    </ButtonBase>
                  </React.Fragment>
                ))}
              {props.disableCollapse !== true && (
                <ButtonBase
                  focusRipple={true}
                  onClick={() => {
                    setOpen(false);
                    if (props.onCollapse != null) {
                      props.onCollapse();
                    }
                  }}
                >
                  <MinimizeOutline size={20} />
                </ButtonBase>
              )}
              <ButtonBase focusRipple={true} onClick={props.onClose}>
                <CloseOutline size={20} />
              </ButtonBase>
            </div>
          </Flex>
        </DialogTitle>
        {props.children}
      </Dialog>
      {!open && !props.open && (
        <Portal>
          <Flex
            alignItems="center"
            css={media({
              backgroundColor: colors.primaryMain,
              color: colors.white,
              position: 'fixed',
              bottom: 0,
              right: 16,
              left: [16, 'auto'],
              display: 'flex',
              alignItems: 'center',
              padding: '12px 16px',
              borderTopLeftRadius: borderRadius.medium,
              borderTopRightRadius: borderRadius.medium,
              minWidth: 300,
              zIndex: depth.modal,
            })}
          >
            <ButtonBase
              focusRipple={true}
              css={{
                marginRight: 16,
                flexGrow: 1,
                justifyContent: 'start',
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <span css={text.buttonText}>{props.title}</span>
            </ButtonBase>
            <ButtonBase
              focusRipple={true}
              css={{ marginRight: 8 }}
              onClick={() => setOpen(true)}
            >
              <ExpandOutline size={20} />
            </ButtonBase>
            <ButtonBase focusRipple={true} onClick={props.onClose}>
              <CloseOutline size={20} />
            </ButtonBase>
          </Flex>
        </Portal>
      )}
    </>
  );
};
