import { memo } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Chip, useTheme } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import { type UserChipFragment } from '../__generated__/graphql';

import { getUserDisplayName } from './UserCard';

export const USER_CHIP_FRAGMENT = gql(/* GraphQL */ `
  fragment UserChip on users {
    id
    ...UserCardDisplayName
    user_images(where: { is_primary: { _eq: true } }, limit: 1) {
      id
      image {
        id
        url
      }
    }
  }
`);

export const UserChip: React.FC<{ user: UserChipFragment }> = memo(
  ({ user }) => {
    const { text } = useTheme();
    const { t } = useLocale();

    const displayName = getUserDisplayName(user, true);
    const [firstName, ...lastNames] = displayName.split(' ');
    const imageUrl = user?.user_images?.[0]?.image?.url;

    return (
      <Chip
        avatar={
          imageUrl == null ? (
            <Avatar>
              <PersonIcon />
            </Avatar>
          ) : (
            <Avatar src={imageUrl} />
          )
        }
        label={
          firstName === ''
            ? t('Anonymous')
            : [`${firstName.charAt(0).toUpperCase()}.`, ...lastNames].join(' ')
        }
        sx={{
          position: 'relative',
          maxWidth: '100%',
          '& .MuiChip-label': {
            ...text.ellipsis,
            maxWidth: '100%',
            display: 'block',
          },
          bgcolor: '#ebebeb',
        }}
      />
    );
  },
);
