import { type FC } from 'react';

import type { CMAReportComponentProps } from '../shared';

import StepAgentBase from './StepAgentBase';

export const StepAgencyProfile: FC<CMAReportComponentProps> = props => {
  return <StepAgentBase {...props} />;
};

export default StepAgencyProfile;
