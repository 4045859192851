// @flow

import * as React from 'react';

import {
  Accordion,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Carousel } from '@realadvisor/carousel';
import { Image } from '@realadvisor/image';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';
// $FlowFixMe[untyped-import]
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { PropertyDrawer } from '../components/BasicListItem/PropertyDrawer';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { BookmarkBorder } from '../icons/bookmark-border';
import { ChevronRight } from '../icons/chevron-right';
import { Fullscreen } from '../icons/fullscreen';
import { Home } from '../icons/home';
import { OutlinedFlag } from '../icons/outlined-flag';
import { Public } from '../icons/public';
import { AccordionCardSummary } from '../shared/accordion-card-summary';
import { webURL } from '../utils/url';

import type { propertyCard_property$key } from './__generated__/propertyCard_property.graphql';
import type { propertyCard_root$key } from './__generated__/propertyCard_root.graphql';
import type { propertyCardOwnersMutation } from './__generated__/propertyCardOwnersMutation.graphql';
import type { propertyCardPlot_location$key } from './__generated__/propertyCardPlot_location.graphql';
import type { propertyCardPlotQuery } from './__generated__/propertyCardPlotQuery.graphql';
import { UserEditableCard } from './user-editable-card';

type Props = {|
  root: propertyCard_root$key,
  property: propertyCard_property$key,
  defaultExpanded: boolean,
|};

export const Plot = (props: {|
  location: ?propertyCardPlot_location$key,
|}): React.Node => {
  const location = useFragment(
    graphql`
      fragment propertyCardPlot_location on Location {
        plot {
          localityId
          plotId
          surface
          landRegistryURL
        }
        zone {
          definition {
            cantonZoneName
          }
        }
      }
    `,
    props.location,
  );
  const { text } = useTheme();
  const { locale } = useLocale();

  const plot = location?.plot;
  const zone = location?.zone;
  const landRegistryURL = plot?.landRegistryURL;
  const plotSurfaceString = `${
    plot?.surface?.toLocaleString(locale) || '-'
  } m²`;

  if (plot != null) {
    return (
      <>
        {plot.localityId != null && plot.plotId != null && (
          <ListItem>
            <ListItemIcon>
              <BookmarkBorder />
            </ListItemIcon>
            <ListItemText css={text.subtitle2}>
              <Box css={text.subtitle2}>
                {[plot.localityId, ':', plot.plotId]}
              </Box>
            </ListItemText>
          </ListItem>
        )}
        {plot.surface != null && (
          <ListItem>
            <ListItemIcon>
              <Fullscreen />
            </ListItemIcon>
            <ListItemText>{plotSurfaceString}</ListItemText>
          </ListItem>
        )}
        {zone?.definition?.cantonZoneName != null && (
          <ListItem>
            <ListItemIcon>
              <OutlinedFlag />
            </ListItemIcon>
            <ListItemText>{zone.definition.cantonZoneName}</ListItemText>
          </ListItem>
        )}
        {landRegistryURL != null && (
          <ListItem>
            <ListItemIcon>
              <Public />
            </ListItemIcon>
            <ListItemText css={text.truncate(1)}>
              <Link target="_blank" href={landRegistryURL}>
                {landRegistryURL}
              </Link>
            </ListItemText>
          </ListItem>
        )}
        <Divider />
      </>
    );
  }
  return null;
};

const PlotWithData = ({ propertyId }) => {
  const data = useLazyLoadQuery<propertyCardPlotQuery>(
    graphql`
      query propertyCardPlotQuery($id: ID!) {
        property: node(id: $id) {
          ... on Property {
            location {
              ...propertyCardPlot_location
            }
          }
        }
      }
    `,
    { id: propertyId },
  );
  return <Plot location={data.property?.location} />;
};

const Owners = ({ property }) => {
  const { t } = useLocale();
  const [editPropertyOwners, updating] =
    useMutation<propertyCardOwnersMutation>(
      graphql`
        mutation propertyCardOwnersMutation($input: EditPropertyOwnersInput!) {
          editPropertyOwners(input: $input) {
            property {
              ...propertyCard_property
              id
            }
          }
        }
      `,
    );
  const [adding, setAdding] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const owners = property.owners ?? [];
  const ownersIds = owners.map(d => d.id) ?? [];

  if (updating) {
    return (
      <Flex flexGrow={1} justifyContent="center" alignItems="center" p={3}>
        <CircularProgress disableShrink={true} />
      </Flex>
    );
  }

  return (
    <>
      {owners.map((owner, index) => (
        <React.Fragment key={owner.id}>
          <UserEditableCard
            isCreatable={true}
            user={owner}
            label={t('selectOwner')}
            // do not suggest owners from other items
            filters={{ id_nin: ownersIds.filter(id => id !== owner.id) }}
            onChange={(user, isEditingUser) => {
              const nextIds = user
                ? ownersIds.map(id => (id === owner.id ? user.id : id))
                : ownersIds.filter(id => id !== owner.id);
              editPropertyOwners({
                variables: {
                  input: {
                    propertyId: property.id,
                    ownersIds: nextIds,
                  },
                },
              });
              setEditing(isEditingUser);
            }}
          />
          {index !== owners.length - 1 && (
            <Divider css={{ margin: '4px 24px' }} />
          )}
        </React.Fragment>
      ))}
      {editing === true || adding ? (
        <UserEditableCard
          isCreatable={true}
          label={t('selectOwner')}
          // do not suggest already selected owners
          filters={{ id_nin: ownersIds }}
          user={null}
          onChange={user => {
            if (user) {
              const ownersIds = owners.map(owner => owner.id);
              editPropertyOwners({
                variables: {
                  input: {
                    propertyId: property.id,
                    ownersIds: [...ownersIds, user.id],
                  },
                },
              });
            }
            setAdding(false);
            setEditing(false);
          }}
        />
      ) : (
        <Button
          size="small"
          variant="outlined"
          onClick={() => setAdding(true)}
          css={{ marginTop: 14 }}
        >
          {t('addOwner')}
        </Button>
      )}
    </>
  );
};

const makeListingsLink = ({ property, tenantId, language }) => {
  const MIN_DIFF_PRICE = 500000;
  const MAX_PRICE_IN_AGG_SEARCH_FILTER = 5000000;
  let min = property.latestAppraisal?.realadvisor?.min;
  let max = property.latestAppraisal?.realadvisor?.max;
  if (min != null && max != null) {
    const dMin = Math.floor(min / MIN_DIFF_PRICE);
    min = MIN_DIFF_PRICE * dMin;
    const dMax = Math.ceil(max / MIN_DIFF_PRICE);
    max = MIN_DIFF_PRICE * dMax;
    // when min and max < MIN_DIFF_PRICE
    if (min === 0 && max === 0) {
      min = null;
      max = MIN_DIFF_PRICE;
    }
    if (max > MAX_PRICE_IN_AGG_SEARCH_FILTER) {
      max = null;
    }
  }

  const query: { [string]: string, ... } = {
    language,
    offerType: property.isRental === true ? 'rent' : 'buy',
  };

  if (property.municipalityObject != null) {
    query.placeSlugs = JSON.stringify([
      { slug: property.municipalityObject.slug },
    ]);
  }

  query.compositePropertyType_eq = JSON.stringify(
    property.compositePropertyType,
  );

  if (property.isRental === true) {
    if (max != null) {
      query.grossRent_lte = JSON.stringify(max);
    }
    if (min != null) {
      query.grossRent_gte = JSON.stringify(min);
    }
  } else {
    if (max != null) {
      query.salePrice_lte = JSON.stringify(max);
    }
    if (min != null) {
      query.salePrice_gte = JSON.stringify(min);
    }
  }

  return webURL(`listings`, { ...query, tenantId: tenantId ?? '' });
};

export const PropertyCard = (props: Props): React.Node => {
  const { tenantSettings } = useFragment(
    graphql`
      fragment propertyCard_root on Query {
        tenantSettings {
          id
        }
      }
    `,
    props.root,
  );
  const property = useFragment(
    graphql`
      fragment propertyCard_property on Property {
        id
        formattedAddress
        isRental
        livingSurface
        builtSurface
        numberOfRooms
        numberOfBedrooms
        compositePropertyType
        propertyType {
          label
        }
        propertyImages {
          title
          image {
            url
          }
        }
        latestAppraisal {
          realadvisor {
            min
            max
          }
        }
        municipalityObject {
          slug
        }
        owners {
          id
          ...userEditableCard_user
        }
      }
    `,
    props.property,
  );

  const { t, language } = useLocale();

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(search);
  const openPropertyDrawer = searchParams.get('propertyDrawer');

  const { text } = useTheme();
  const [propertyDrawer, setPropertyDrawer] = React.useState(
    openPropertyDrawer != null ? true : false,
  );
  const m2 = 'm\u00B2';
  const [expanded, setExpanded] = React.useState(true);
  const images = property.propertyImages ?? [];
  const imageRatio = 9 / 16;

  const toggleCardAccordion = (event, expanded) => {
    setExpanded(expanded);
  };
  return (
    <Accordion
      defaultExpanded={props.defaultExpanded}
      onChange={toggleCardAccordion}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={t('Property')}
        subTitle={property.propertyType?.label ?? ''}
      />
      {images.length > 0 && (
        <div css={{ position: 'relative' }}>
          <Carousel
            media={{
              gap: 0,
              itemsOnScreen: 1,
              aspectRatio: imageRatio,
            }}
          >
            {images.map((item, index) => {
              const options = { w: 700, c: 'fill', f: 'jpg' };
              return (
                <Image
                  key={index}
                  css={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  alt={item.title ?? ''}
                  loading={'lazy'}
                  options={options}
                  src={item.image.url}
                />
              );
            })}
          </Carousel>
        </div>
      )}
      <List dense={true}>
        <PropertyDrawer
          propertyId={property.id}
          open={propertyDrawer}
          onClose={() => {
            setPropertyDrawer(false);
            if (openPropertyDrawer != null) {
              navigate(pathname, { replace: true });
            }
          }}
        />
        <ListItem>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText>
            <Box mb={2} css={text.body2}>
              {property.formattedAddress}
            </Box>
            <Box css={text.subtitle2}>
              {[
                property.propertyType?.label,
                property.livingSurface != null
                  ? `${property.livingSurface} ${m2}`
                  : property.builtSurface != null
                  ? `${property.builtSurface} ${m2}`
                  : null,
                property.numberOfRooms != null
                  ? t('roomsCount', { count: property.numberOfRooms })
                  : property.numberOfBedrooms != null
                  ? t('numberOfBedroomsWithCount', {
                      count: property.numberOfBedrooms,
                    })
                  : null,
              ]
                .filter(Boolean)
                .join(' - ')}
            </Box>
          </ListItemText>
        </ListItem>

        <React.Suspense
          fallback={
            <Flex justifyContent="center" pb={3}>
              <CircularProgress disableShrink={true} />
            </Flex>
          }
        >
          <PlotWithData propertyId={property.id} />
        </React.Suspense>
      </List>
      <CardContent>
        <Button
          css={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            fontSize: text.size(14),
          }}
          size="small"
          endIcon={<ChevronRight />}
          onClick={() => setPropertyDrawer(true)}
        >
          {t('editProperty')}
        </Button>
        <Button
          css={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            fontSize: text.size(14),
          }}
          component="a"
          target="_blank"
          size="small"
          endIcon={<ChevronRight />}
          href={makeListingsLink({
            property,
            tenantId: tenantSettings?.id,
            language,
          })}
        >
          {t('similarProperties')}
        </Button>
        <Owners property={property} />
      </CardContent>
    </Accordion>
  );
};
