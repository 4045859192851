import { COUNT_LIMIT, DEFAULT_LIMIT, DEFAULT_OFFSET } from '../../src/config';

export const getPaginationVariables = (
  searchParams: URLSearchParams,
  defaultLimitOverride?: number,
  approximateRowCount = false,
) => {
  const showRealCount = searchParams.get('show_real_count') === 'true';

  let countLimit = undefined;

  if (approximateRowCount === true) {
    if (showRealCount === true) {
      countLimit = undefined;
    } else {
      countLimit = COUNT_LIMIT;
    }
  }

  const limit = parseInt(
    searchParams.get('limit') ??
      (defaultLimitOverride ?? DEFAULT_LIMIT).toString(),
  );
  const offset = parseInt(
    searchParams.get('offset') ?? DEFAULT_OFFSET.toString(),
  );
  return { countLimit, limit, offset };
};
