// @flow

import * as React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  ToggleButton,
} from '@mui/material';
import { Form, useFormik } from '@realadvisor/form';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';
import { Box } from 'react-system';

import { ToolbarDialog } from '../controls/editor-toolbar';
import { ProgressButton } from '../controls/progress-button';
import { useLocale } from '../hooks/locale';
import { AddCircleOutline } from '../icons/add-circle-outline';
import { webURL } from '../utils/url';

import type { editorSnippetDevelopmentQuery } from './__generated__/editorSnippetDevelopmentQuery.graphql';
import type {
  editorSnippetLotQuery,
  editorSnippetLotQuery$data,
} from './__generated__/editorSnippetLotQuery.graphql';
import type { editorSnippetTenantQuery } from './__generated__/editorSnippetTenantQuery.graphql';
import { type Development, DevelopmentInput } from './development-input';
import { LotInput } from './lot-input';

type Props = {|
  lot?: editorSnippetLotQuery$data['lotById'],
  focusEditor: (callback?: () => void) => void,
  editorState: EditorState,
  onChange: EditorState => void,
  dynamicSnippet?: boolean,
|};

const tenantQuery = graphql`
  query editorSnippetTenantQuery {
    tenantSettings {
      id
    }
  }
`;

const lotQuery = graphql`
  query editorSnippetLotQuery($id: ID!) {
    lotById(id: $id) {
      id
      ...lotInput_lot @relay(mask: false)
      product {
        id
      }
      property {
        postcode
        locality
        numberOfRooms
        livingSurface
        propertyType {
          label
        }
      }
    }
    tenantSettings {
      id
    }
  }
`;

const developmentQuery = graphql`
  query editorSnippetDevelopmentQuery($id: ID!) {
    development: node(id: $id) {
      ... on Development {
        id
        slug
        ...developmentInput_development @relay(mask: false)
        lots {
          id
          property {
            postcode
            locality
            numberOfRooms
            livingSurface
            propertyType {
              label
            }
          }
        }
      }
    }
    tenantSettings {
      id
    }
  }
`;

const SnippetDialogContent = ({
  initialLot,
  onSubmit,
  onClose,
  dynamicSnippet,
}) => {
  const { t } = useLocale();
  const [loading, setLoading] = React.useState(false);
  const environment = useRelayEnvironment();
  const form = useFormik({
    initialValues: {
      type: 'lot',
      lot: initialLot,
      development: (null: ?Development),
    },
    validate: () => ({}),
    onSubmit: values => {
      setLoading(true);

      if (values.type === 'lot') {
        if (values.lot) {
          const lotId = values.lot.id;
          fetchQuery<editorSnippetLotQuery>(environment, lotQuery, {
            id: lotId,
          }).subscribe({
            next: response => {
              const { lotById: lot, tenantSettings } = response;
              if (lot != null) {
                onSubmit({
                  type: 'lot',
                  lot,
                  development: null,
                  tenantId: tenantSettings?.id,
                });
              }
            },
          });
        } else {
          fetchQuery<editorSnippetTenantQuery>(
            environment,
            tenantQuery,
            {},
          ).subscribe({
            next: ({ tenantSettings }) => {
              onSubmit({
                type: 'lot',
                lot: null,
                development: null,
                tenantId: tenantSettings?.id,
              });
            },
          });
        }
      }

      if (values.type === 'development' && values.development) {
        const developmentId = values.development.id;
        fetchQuery<editorSnippetDevelopmentQuery>(
          environment,
          developmentQuery,
          { id: developmentId },
        ).subscribe({
          next: response => {
            const { development, tenantSettings } = response;
            if (development != null) {
              onSubmit({
                type: 'development',
                lot: null,
                development,
                tenantId: tenantSettings?.id,
              });
            }
          },
        });
      }
    },
  });

  return (
    <Form onSubmit={form.submitForm}>
      <DialogTitle>{t('insertSnippet')}</DialogTitle>

      <DialogContent>
        <TextField
          select={true}
          label={t('snippetType')}
          variant="filled"
          required={true}
          value={form.values.type}
          onChange={event => form.setValues({ type: event.target.value })}
          disabled={dynamicSnippet}
        >
          <MenuItem value="lot">{t('lot')}</MenuItem>
          <MenuItem value="development">{t('development')}</MenuItem>
        </TextField>
        {form.values.type === 'lot' && dynamicSnippet !== true && (
          <Box my={2}>
            <FormControl required={true}>
              <InputLabel>{t('lot')}</InputLabel>
              <LotInput
                value={form.values.lot}
                onChange={lot => form.setValues({ lot })}
              />
            </FormControl>
          </Box>
        )}
        {form.values.type === 'development' && (
          <Box my={2}>
            <FormControl required={true}>
              <InputLabel>{t('development')}</InputLabel>
              <DevelopmentInput
                filters={{ isPublished_eq: true }}
                value={form.values.development}
                onChange={development => form.setValues({ development })}
              />
            </FormControl>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <ProgressButton
          disabled={
            dynamicSnippet !== true &&
            (form.values.type === 'lot'
              ? form.values.lot == null
              : form.values.development == null)
          }
          loading={loading}
          onClick={form.submitForm}
        >
          {t('add')}
        </ProgressButton>
      </DialogActions>
    </Form>
  );
};

export const SnippetToggleButton = (props: Props): React.Node => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { language } = useLocale();

  const getLotSnippetEntityData = (lot, tenantId) => {
    const listingUrl = webURL('product', {
      id: lot?.product?.id ?? '#snippet.productId',
      tenantId: tenantId ?? '',
      language,
      utm_medium: 'email',
      utm_source: 'crm_email',
      utm_campaign: 'lot_link',
    });

    if (lot) {
      const { property } = lot;

      // Note that in case of saving an email template,
      // below data would be stored in the database as part of template json
      return {
        type: 'lot',
        title: lot.title,
        postcode: property.postcode || '',
        locality: property.locality || '',
        listingUrl,
        thumbnailUrl: lot.primaryPropertyImage?.image.url,
        propertyTypeLabel: property.propertyType?.label,
        numberOfRooms: property.numberOfRooms,
        livingSurface: property.livingSurface,
      };
    }
    return {
      type: 'lot',
      title: '#snippet.title',
      postcode: '#snippet.postcode',
      locality: '#snippet.locality',
      listingUrl: decodeURIComponent(listingUrl),
      thumbnailUrl: '#snippet.thumbnailUrl',
      propertyTypeLabel: '#snippet.propertyTypeLabel',
      numberOfRooms: '#snippet.numberOfRooms',
      livingSurface: '#snippet.livingSurface',
    };
  };

  const getDevelopmentSnippetEntityData = (development, tenantId) => {
    const thumbnailUrl = development.primaryDevelopmentImage?.image.url;
    const lots = development.lots || [];

    const listingUrl = webURL('development', {
      slug: development.slug ?? '',
      tenantId: tenantId ?? '',
      language,
      utm_medium: 'email',
      utm_source: 'crm_email',
      utm_campaign: 'development_link',
    });

    if (lots.length === 0) {
      // Note that in case of saving an email template,
      // below data would be stored in the database as part of template json
      return {
        listingUrl,
        type: 'development',
        title: development.title,
        thumbnailUrl,
      };
    }
    const numberOfRooms = lots.map(lot => lot.property.numberOfRooms || 0);
    const livingSurface = lots.map(lot => lot.property.livingSurface || 0);
    const { property } = lots[0];
    return {
      listingUrl,
      type: 'development',
      title: development.title,
      thumbnailUrl,
      postcode: property.postcode,
      locality: property.locality,
      propertyTypeLabel: property.propertyType?.label,
      numberOfRooms: [Math.min(...numberOfRooms), Math.max(...numberOfRooms)],
      livingSurface: [Math.min(...livingSurface), Math.max(...livingSurface)],
    };
  };

  const addSnippetEntity = entityData => {
    const editorState = props.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'PROPERTY_SNIPPET',
      'IMMUTABLE',
      entityData,
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    const newState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      ' ',
    );
    setAnchorEl(null);
    // Editor must be in focus when doing changes,
    // otherwise Safari will blow up
    props.focusEditor(() => props.onChange(newState));
  };

  const closeDialog = () => {
    setAnchorEl(null);
    props.focusEditor();
  };

  return (
    <>
      <ToggleButton
        tabIndex={-1}
        value="snippet"
        selected={false}
        onMouseDown={event => {
          event.preventDefault();
          setAnchorEl(event.currentTarget);
        }}
      >
        <AddCircleOutline />
      </ToggleButton>

      <ToolbarDialog
        anchorEl={anchorEl}
        open={anchorEl != null}
        onClose={closeDialog}
      >
        <SnippetDialogContent
          initialLot={props.lot}
          onSubmit={({ type, lot, development, tenantId }) => {
            if (type === 'lot') {
              addSnippetEntity(getLotSnippetEntityData(lot, tenantId));
            }
            if (type === 'development' && development) {
              addSnippetEntity(
                getDevelopmentSnippetEntityData(development, tenantId),
              );
            }
          }}
          onClose={closeDialog}
          dynamicSnippet={props.dynamicSnippet}
        />
      </ToolbarDialog>
    </>
  );
};
