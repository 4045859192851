import type { ReactNode } from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  type Operation,
  createHttpLink,
  from,
  fromPromise,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import {
  getFragmentDefinitions,
  getMainDefinition,
} from '@apollo/client/utilities';
import { refreshToken } from '@realadvisor/auth-client';
import type { ErrorsEmitter } from '@realadvisor/error';
import {
  type IntrospectionInputObjectType,
  type IntrospectionObjectType,
  type IntrospectionQuery,
  type IntrospectionTypeRef,
  type SelectionSetNode,
  type TypeNode,
} from 'graphql';
import pick from 'lodash.pick';
import { createNanoEvents } from 'nanoevents';
import { RelayEnvironmentProvider } from 'react-relay';
import type { FetchFunction } from 'relay-runtime';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import * as config from './src/config';
import { extractLanguageFromUrl, fallbackLanguage } from './src/locale';

// only do this in dev mode // local dev only
let TYPE_MAP: Map<string, IntrospectionObjectType> | undefined = undefined;
let INPUT_TYPE_MAP: Map<string, IntrospectionInputObjectType> | undefined =
  undefined;
let JSON_SCHEMA: IntrospectionQuery | undefined = undefined;
if (import.meta.env.DEV === true) {
  import('./apollo/__generated__/graphql.schema.json').then(
    module => (JSON_SCHEMA = module.default as unknown as IntrospectionQuery),
  );
}

export const getSchema = (operation: Operation) => {
  if (JSON_SCHEMA != null) {
    return JSON_SCHEMA.__schema;
  } else {
    const cache = operation.getContext().cache as InMemoryCache;
    const normalizedCache = cache.extract();
    return normalizedCache?.['schema']?.['__schema'] as
      | IntrospectionQuery['__schema']
      | undefined;
  }
};

const addIdToSelectionSet = (
  selectionSet: SelectionSetNode | undefined,
  typeOrObject: string,
  typeMap: Map<string, IntrospectionObjectType>,
  parentType: string,
): void => {
  if (!selectionSet) {
    return;
  }

  if (typeOrObject.endsWith('_aggregate')) {
    return;
  }

  // Helper function to find a field's type name
  const getFieldTypeName = (
    parentTypeName: string,
    fieldName: string,
  ): string | null => {
    const type = typeMap.get(parentTypeName);
    if (!type) {
      return null;
    }

    const field = type.fields?.find(f => f.name === fieldName);
    if (!field) {
      return null;
    }

    // Unwrap non-null and list types
    let currentType = field.type;
    while ('ofType' in currentType && currentType.ofType) {
      currentType = currentType.ofType;
    }
    return 'name' in currentType ? currentType.name : null;
  };

  // Function to check if a nested path has an ID field
  const checkNestedPathForId = (path: string): boolean => {
    const parts = path.split('.');
    let currentTypeName = 'query_root';

    for (const part of parts) {
      // If we're at the first part and it's not found in query_root,
      // try looking up the type directly in the type map
      if (currentTypeName === 'query_root') {
        // Check type map first since it's a direct lookup
        const type = typeMap.get(part);
        if (type) {
          currentTypeName = part;
          continue;
        }

        // Fall back to checking field type if not found in type map
        const nextTypeName = getFieldTypeName(currentTypeName, part);
        if (!nextTypeName) {
          return false;
        }
        currentTypeName = nextTypeName;
      } else {
        const nextTypeName = getFieldTypeName(currentTypeName, part);
        if (!nextTypeName) {
          return false;
        }
        currentTypeName = nextTypeName;
      }
    }

    const type = typeMap.get(currentTypeName);
    return (
      (type?.kind === 'OBJECT' && type.fields?.some(f => f.name === 'id')) ||
      false
    );
  };

  // Check if current type has ID and add if needed
  const currentTypeHasId = checkNestedPathForId(
    `${parentType}.${typeOrObject}`,
  );

  if (currentTypeHasId) {
    const hasIdSelection = selectionSet.selections.some(
      selection => selection.kind === 'Field' && selection.name.value === 'id',
    );

    if (!hasIdSelection) {
      selectionSet.selections = [
        {
          kind: 'Field',
          name: {
            kind: 'Name',
            value: 'id',
          },
        },
        ...selectionSet.selections,
      ];
    }
  }

  // Process nested selections
  for (const selection of selectionSet.selections) {
    if (selection.kind === 'Field' && selection.selectionSet) {
      addIdToSelectionSet(
        selection.selectionSet,
        selection.name.value,
        typeMap,
        parentType ? `${parentType}.${typeOrObject}` : typeOrObject,
      );
    }
  }
};

const getTimeZoneId = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getNestedType = (type: TypeNode): string => {
  if (type.kind === 'NamedType') {
    return type.name.value;
  }
  return getNestedType(type.type);
};

const getNestedInputType = (type: IntrospectionTypeRef) => {
  if (type.kind === 'NON_NULL' || type.kind === 'LIST') {
    return getNestedInputType(type.ofType);
  }

  return type;
};

const createEnvironment = ({
  endpoint,
  errorsEmitter,
}: {
  endpoint: string;
  errorsEmitter: ErrorsEmitter;
}) => {
  const fetchQuery: FetchFunction = async (operation, { ...variables }) => {
    // relay removes directives likes @deleteEdge before passing to fetch
    // though $connections variable is left and hasura fails because of it
    // prevent here passing it to hasura and leave only for relay
    delete variables.connections;

    const headers = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    const access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      headers.set('Authorization', `Bearer ${access_token}`);
    }
    const request: RequestInit = {
      method: 'POST',
      headers,
      credentials: 'include',
      body: JSON.stringify({
        query: operation.text,
        variables,
        name: operation.name,
      }),
    };

    const response = await fetch(endpoint, request);
    const data = await response.json();
    if (data.errors) {
      if (
        data.errors.some((e: { message: string | string[] }) =>
          e.message.includes('JWT is expired'),
        )
      ) {
        await refreshToken();
        return fetchQuery(operation, variables, {});
      } else {
        errorsEmitter.emit('add', operation.name, data.errors);
      }
    }

    if (data.data != null && 'me' in data.data && data.data.me == null) {
      // Check if 'me' object exists in Relay store
      const store = relayApiEnvironment?.getStore();
      const meRef = (
        store?.getSource().get('client:root')?.me as {
          __ref: string;
        } | null
      )?.__ref;
      if (meRef != null) {
        const meRecord = store?.getSource().get(meRef);
        if (meRecord != null) {
          data.data.me = meRecord;
        }
      }
    }

    return data;
  };

  return new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  });
};

export const errorsEmitter: ErrorsEmitter = createNanoEvents();

export const fetchApiQuery = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  const headers = new Headers({
    ...(init?.headers ?? {}),
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
  const access_token = localStorage.getItem('access_token');
  if (access_token != null) {
    headers.set('Authorization', `Bearer ${access_token}`);
  }
  const request: RequestInit = {
    ...(init ?? {}),
    headers,
    credentials: 'include',
  };

  const response = await fetch(input, request);
  const responseClone = response.clone();
  const data = await responseClone.json();
  if (data.errors) {
    if (
      data.errors?.some((e: { message: string | string[] }) =>
        e.message.includes('JWT is expired'),
      )
    ) {
      await refreshToken();
      return fetchApiQuery(input, request);
    } else {
      errorsEmitter.emit('add', input.toString(), data.errors);
    }
  } else if (data.error?.includes('JWT is expired')) {
    await refreshToken();
    return fetchApiQuery(input, request);
  }

  return response;
};

type Props = {
  children: ReactNode;
};

let relayApiEnvironment: null | Environment = null;
export const RelayApiWrapper = (props: Props) => {
  if (relayApiEnvironment == null) {
    const lng = extractLanguageFromUrl() ?? fallbackLanguage;
    const tz = getTimeZoneId();
    const endpoint = `${config.api_origin}/graphql?lng=${lng}&tz=${tz}`;
    relayApiEnvironment = createEnvironment({
      endpoint,
      errorsEmitter,
    });
  }
  return (
    <RelayEnvironmentProvider environment={relayApiEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  );
};

let relayHasuraEnvironment: null | Environment = null;
export const RelayHasuraWrapper = (props: Props) => {
  if (relayHasuraEnvironment == null) {
    const tz = getTimeZoneId() ?? '';
    const endpoint = `${config.hasura_origin}/v1beta1/relay?tz=${tz}`;
    relayHasuraEnvironment = createEnvironment({
      endpoint,
      errorsEmitter,
    });
  }
  return (
    <RelayEnvironmentProvider environment={relayHasuraEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  );
};

let relayHasuraRawEnvironment: null | Environment = null;
export const RelayHasuraRawWrapper = (props: Props) => {
  if (relayHasuraRawEnvironment == null) {
    const tz = getTimeZoneId() ?? '';
    const endpoint = `${config.hasura_origin}/v1/graphql?tz=${tz}`;
    relayHasuraRawEnvironment = createEnvironment({
      endpoint,
      errorsEmitter,
    });
  }

  return (
    <RelayEnvironmentProvider environment={relayHasuraRawEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  );
};

export const ApolloHasuraWrapper = (props: Props) => {
  const endpoint = `${config.hasura_origin}/v1/graphql`;
  const scrapersEndpoint = `${config.scrapers_origin}/v1/graphql`;

  const httpMainApiLink = createHttpLink({
    uri: endpoint,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const httpScrapersLink = createHttpLink({
    uri: scrapersEndpoint,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const httpLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'scrapers',
    httpScrapersLink, //if above
    httpMainApiLink,
  );

  const authLink = setContext((_, { headers }) => {
    // allow anonymous requests for tenant slug validation
    if (localStorage.getItem('access_token') == null) {
      return { headers };
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (
            err.extensions?.code === 'invalid-jwt' &&
            err.message === 'Could not verify JWT: JWTExpired'
          ) {
            return fromPromise(
              refreshToken()
                .catch(error => {
                  throw error;
                })
                .then(() => true),
            )
              .filter(value => Boolean(value))
              .flatMap(() => {
                return forward(operation);
              });
          } else {
            return;
          }
        }
      }

      if (networkError) {
        throw new Error(`[Network error]: ${networkError}`);
      }
    },
  );

  const removeTypenameLink = removeTypenameFromVariables();

  const addIdsToQuery = new ApolloLink((operation, forward) => {
    const definition = getMainDefinition(operation.query);
    const fragments = getFragmentDefinitions(operation.query);

    if (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'query'
    ) {
      const schema = getSchema(operation);

      if (!schema) {
        return forward(operation);
      }
      if (TYPE_MAP == null) {
        TYPE_MAP = new Map(
          schema.types.filter(t => t.kind === 'OBJECT').map(t => [t.name, t]),
        );
      }

      // Process definition selections
      if (definition.selectionSet?.selections) {
        for (const selection of definition.selectionSet.selections) {
          if (selection.kind === 'Field') {
            addIdToSelectionSet(
              selection.selectionSet,
              selection.name.value,
              TYPE_MAP,
              'query_root',
            );
          }
        }
      }

      // Process fragments
      for (const fragment of fragments) {
        addIdToSelectionSet(
          fragment.selectionSet,
          fragment.typeCondition.name.value,
          TYPE_MAP,
          'query_root',
        );
      }

      // ensure operation.query is updated by creating a new object
      operation.query = {
        ...operation.query,
        definitions: [...operation.query.definitions],
      };
    }

    return forward(operation);
  });

  // this link will prune mutation input variables to only include fields that are defined in the input class
  const pruneMutationInput = new ApolloLink((operation, forward) => {
    const definition = getMainDefinition(operation.query);
    if (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'mutation'
    ) {
      const schema = getSchema(operation);

      if (!schema) {
        return forward(operation);
      }

      for (const variableDef of definition.variableDefinitions ?? []) {
        try {
          // go through all nested type until we find NamedType
          const typeName = getNestedType(variableDef.type);

          // thanks to TS 5.5, filter can infer proper type
          const inputObject = schema.types
            .filter(t => t.kind === 'INPUT_OBJECT')
            .find(t => t.name === typeName);

          const fields = inputObject?.inputFields.map(field => field.name);
          if (!fields || fields.length === 0) {
            continue;
          }

          const name = variableDef.variable.name.value;
          if (operation.variables?.[name] != null) {
            const variable = operation.variables[name];
            if (Array.isArray(variable)) {
              operation.variables[name] = variable.map(v => {
                if (v != null) {
                  return pick(v, fields);
                } else {
                  return v;
                }
              });
            } else {
              operation.variables[name] = pick(variable, fields);
            }
          }
        } catch {
          // do nothing => this middleware is best effort
        }
      }
    }
    return forward(operation);
  });

  const updateNestedDates = (typeName: string, value: any) => {
    const inputObject = INPUT_TYPE_MAP?.get(typeName);
    // We don't want to go down the "on_conflict" fields.
    const fields = inputObject?.inputFields.filter(
      ({ name }) => name !== 'on_conflict',
    );

    if (fields == null || fields.length === 0) {
      return;
    }

    for (const field of fields) {
      const type = getNestedInputType(field.type);

      const fieldValue = value[field.name];

      if (fieldValue == null) {
        continue;
      }

      // We don't support the type "date array" in our model, so if the nested type is a date, it shouldn't be an array.
      if (type.name === 'date') {
        const date = new Date(fieldValue);
        // We substract back the timezone offset so that we do not account for it when in UTC.
        date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
        value[field.name] = date.toISOString();
      } else if (type.kind === 'INPUT_OBJECT') {
        const fieldValueArray = Array.isArray(fieldValue)
          ? fieldValue
          : [fieldValue];

        for (const item of fieldValueArray) {
          updateNestedDates(type.name, item);
        }
      }
    }
  };

  // this link will force the field of type "date only" (no time) to be converted to ISO string so we don't have a shift because of client timezone
  const forceDateIsoMutationInput = new ApolloLink((operation, forward) => {
    const definition = getMainDefinition(operation.query);

    if (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'mutation'
    ) {
      const schema = getSchema(operation);

      if (!schema) {
        return forward(operation);
      }

      if (INPUT_TYPE_MAP == null) {
        INPUT_TYPE_MAP = new Map(
          schema.types
            .filter(t => t.kind === 'INPUT_OBJECT')
            .map(t => [t.name, t]),
        );
      }

      for (const variableDef of definition.variableDefinitions ?? []) {
        try {
          // go through all nested type until we find NamedType
          const typeName = getNestedType(variableDef.type);
          const variableName = variableDef.variable.name.value;

          if (operation.variables?.[variableName] == null) {
            continue;
          }

          const variableValue = operation.variables[variableName];

          if (typeName === 'date') {
            const date = new Date(variableValue);
            // We substract back the timezone offset so that we do not account for it when in UTC.
            date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
            operation.variables[variableName] = date.toISOString();
          } else {
            updateNestedDates(typeName, variableValue);
          }
        } catch (error) {
          // do nothing => this middleware is best effort
          if (!config.isProduction) {
            console.error(error);
          }
        }
      }
    }

    return forward(operation);
  });

  // Allow us to pass a execute a custom link per query when provided through the context
  const customLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();

    if (
      context.customLink == null ||
      typeof context.customLink !== 'function'
    ) {
      return forward(operation);
    }

    return context.customLink(operation, forward);
  });

  const apolloClient = new ApolloClient({
    link: from([
      removeTypenameLink,
      addIdsToQuery,
      customLink,
      pruneMutationInput,
      forceDateIsoMutationInput,
      errorLink,
      authLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      resultCacheMaxSize: 100000,
      typePolicies: {
        cma_report_files: { keyFields: ['cma_report_id', 'file_id'] },
        property_files: { keyFields: ['property_id', 'file_id'] },
        Query: {
          fields: {
            lots_by_pk: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'lots',
                  id: args?.id,
                });
              },
            },
            dictionaries_by_pk: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'dictionaries',
                  id: args?.id,
                });
              },
            },
          },
        },
      },
    }),
    connectToDevTools: import.meta.env.NODE_ENV !== 'production',
  });

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
