// @flow

import * as React from 'react';

import { Avatar } from '@mui/material';
import { Image } from '@realadvisor/image';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { graphql, useFragment } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';

import { useTheme } from '../hooks/theme';
import { Person } from '../icons/person';

import type { userInfo_enquiry$key } from './__generated__/userInfo_enquiry.graphql';
import type { userInfo_user$key } from './__generated__/userInfo_user.graphql';

type Props = {|
  user?: userInfo_user$key,
  enquiry?: userInfo_enquiry$key,
  referrer?: null | string,
  enableLink?: boolean,
  showSensitiveData?: boolean,
|};

const getFirstChar = str => str?.trim()[0] ?? '';
const getAbbr = (firstName, lastName) => {
  if (firstName == null && lastName == null) {
    return null;
  }
  return (getFirstChar(firstName) + getFirstChar(lastName)).toUpperCase();
};

const UserLink = ({ id, children }) => {
  return <Link to={`/users/${id}`}>{children}</Link>;
};

export const UserInfo = (props: Props): React.Node => {
  const { referrer, enableLink = false, showSensitiveData = true } = props;
  const { text, colors } = useTheme();

  const user = useFragment(
    graphql`
      fragment userInfo_user on User {
        id
        firstName
        lastName
        primaryEmail {
          email
        }
        primaryPhoneNumber {
          number
        }
        primaryImage {
          url
        }
      }
    `,
    props.user,
  );

  const enquiry = useFragment(
    graphql`
      fragment userInfo_enquiry on Enquiry {
        firstName
        lastName
        emailAddress
        phoneNumber
      }
    `,
    props.enquiry,
  );

  if (user != null && enquiry != null) {
    console.error('Only user or only enquiry should be passed to UserInfo');
  }
  if (user == null && enquiry == null) {
    console.error('User or enquiry should be passed to UserInfo');
  }

  const userId = user?.id;
  const firstName = user?.firstName ?? enquiry?.firstName;
  const lastName =
    showSensitiveData === true
      ? user?.lastName ?? enquiry?.lastName
      : (user?.lastName ?? enquiry?.lastName ?? '').slice(0, 1) + '.';
  const email = user?.primaryEmail?.email ?? enquiry?.emailAddress;
  const phoneNumber = user?.primaryPhoneNumber?.number ?? enquiry?.phoneNumber;

  const imageUrl = user?.primaryImage?.url ?? null;
  const parsedNumber =
    phoneNumber == null ? null : parsePhoneNumberFromString(phoneNumber, 'CH');
  const formattedPhone =
    parsedNumber == null ? null : parsedNumber.formatInternational();

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <div css={{ position: 'relative' }}>
        <Avatar>
          {imageUrl != null ? (
            <Image src={imageUrl} srcSize={[[40, 40]]} />
          ) : (
            getAbbr(firstName, lastName) ?? <Person />
          )}
        </Avatar>
        {referrer != null && (
          <Avatar
            css={{
              height: 18,
              width: 18,
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
            src={`https://www.google.com/s2/favicons?domain=${referrer}`}
          />
        )}
      </div>
      {/* make truncate work */}
      <div css={{ minWidth: 0 }}>
        <div css={[text.body2, text.truncate(1)]}>
          {enableLink === false || userId == null ? (
            [firstName, ' ', lastName]
          ) : (
            <UserLink id={userId}>{[firstName, ' ', lastName]}</UserLink>
          )}
        </div>
        {showSensitiveData === true && (
          <>
            <div
              css={[
                text.caption,
                text.truncate(1),
                { color: colors.mediumText },
              ]}
            >
              {email}
            </div>
            {phoneNumber != null && (
              <div
                css={[
                  text.caption,
                  text.truncate(1),
                  { color: colors.mediumText },
                ]}
              >
                {formattedPhone}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
