import { useCallback, useState } from 'react';

import {
  useApolloClient,
  useFragment as useApolloFragment,
  useMutation,
} from '@apollo/client';
import {
  Box,
  Checkbox,
  CircularProgress,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  DataGridPremium,
  type GridColDef,
  type GridRowOrderChangeParams,
  type GridRowsProp,
} from '@mui/x-data-grid-premium';
import { useFileDialog } from '@realadvisor/hooks';

import { FullScreenDropZone } from '../../../shared/dropzone';
import { toGlobalId } from '../../../shared/global-id';
import { FileIcon } from '../../../src/controls/file-icon';
import { FileViewerDialog } from '../../../src/controls/file-viewer-dialog';
import { TemplateViewerDialog } from '../../../src/controls/template-viewer-dialog';
import { useLocale } from '../../../src/hooks/locale';
import {
  type StepAppendAddPropertyFileMutation,
  type StepAppendAddPropertyFileMutationVariables,
  type StepAppendFilesFragment,
} from '../../__generated__/graphql';
import { FileDeleteDialog } from '../../components/FileDeleteDialog';
import { type FormDefinitionType, RaForm } from '../../components/form/RaForm';
import RASnackbar from '../../components/RASnackbar';
import { UploadImageCard } from '../../components/UploadCard';
import { usePropertyFileUpload } from '../../hooks/usePropertyFileUpload';
import { useFileActionsDef, useFileDef } from '../../utils/files/fileDefs';
import { useIsDragging } from '../../utils/mouseEvents';

import { useCMAEditor } from './CMAReportEditor/CMAReportEditorWorkflow';
import {
  STEP_ADD_CMA_REPORT_FILE,
  STEP_APPEND_ADD_PROPERTY_FILE,
  STEP_APPEND_FILES_FRAGMENT,
  STEP_DELETE_CMA_REPORT_FILE,
  STEP_UPDATE_CMA_REPORT_FILE,
  STEP_UPDATE_CMA_REPORT_FILE_VISIBILITY,
} from './cmaReportsQueries';
import { type CMAReportComponentProps, FooterActions } from './shared';

type CmaReportFile = NonNullable<
  StepAppendFilesFragment['cma_report_files'][number]
>;

const StepAppendFiles = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const { t } = useLocale();
  const { cache } = useApolloClient();
  const [gridEl, setGridEl] = useState<HTMLElement | null>(null);
  const isReOrdering = useIsDragging({ target: gridEl });
  const [templateOpen, setTemplateOpen] = useState(false);
  const { cmaReport } = useCMAEditor();
  const [error, setError] = useState<Error | null>(null);

  const { data, complete } = useApolloFragment({
    fragment: STEP_APPEND_FILES_FRAGMENT,
    fragmentName: 'StepAppendFiles',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const cmaReportFiles = data?.cma_report_files ?? [];
  const propertyId = data?.lead?.property?.id;

  const [addCmaReportFile, { loading: addingCmaFile }] = useMutation(
    STEP_ADD_CMA_REPORT_FILE,
  );

  const [updateCmaReportFile, { loading: updatingCmaFile }] = useMutation(
    STEP_UPDATE_CMA_REPORT_FILE,
  );

  const [updateVisibility, { loading: updatingVisibility }] = useMutation(
    STEP_UPDATE_CMA_REPORT_FILE_VISIBILITY,
  );

  const [deleteCmaReportFile] = useMutation(STEP_DELETE_CMA_REPORT_FILE);

  const handleRowOrderChange = async ({
    row,
    targetIndex,
  }: GridRowOrderChangeParams) => {
    const targetOrderNr = cmaReportFiles[targetIndex]?.order_nr;

    await updateCmaReportFile({
      variables: {
        file_id: row.file.id,
        cma_report_id: cmaReportId,
        input: {
          order_nr: targetOrderNr,
        },
      },
    });
  };

  const [viewerFile, setViewerFile] = useState<CmaReportFile['file'] | null>(
    null,
  );
  const [deleteFile, setDeleteFile] = useState<CmaReportFile | null>(null);

  const [createFile] = useMutation(STEP_APPEND_ADD_PROPERTY_FILE, {
    update: async (_, { data }) => {
      if (data?.insert_property_files_one) {
        await addCmaReportFile({
          variables: {
            object: {
              cma_report_id: cmaReportId,
              file_id: data.insert_property_files_one.file_id,
              is_visible: true,
            },
            cma_report_id: cmaReportId,
          },
        });
      }
    },
  });

  const { upload, pendingFiles, FileErrors } = usePropertyFileUpload<
    StepAppendAddPropertyFileMutation,
    StepAppendAddPropertyFileMutationVariables
  >({
    propertyId: propertyId ?? '',
    createFile,
  });

  const handleDrop = (files: File[]) => {
    const pdfFiles = files.filter(file => file.type === 'application/pdf');

    if (files.length > pdfFiles.length) {
      setError(
        new Error(
          t('{{count}} files were ignored because they are not PDFs', {
            count: files.length - pdfFiles.length,
          }),
        ),
      );
    }

    pdfFiles.forEach(file => upload(file));
  };

  const openFileDialog = useFileDialog({
    accept: 'application/pdf',
    onChange: handleDrop,
  });

  const onGridRefChange = useCallback((el: HTMLElement | null) => {
    if (el != null) {
      setGridEl(el);
    }
  }, []);

  const fileDef = useFileDef<CmaReportFile>();
  const fileActionsDef = useFileActionsDef<CmaReportFile>({
    setViewerFile,
    setDeleteFile,
    deleteFile,
  });

  if (!complete) {
    return (
      <Stack gap={2} margin={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton
            key={`step-append-files-${index}`}
            variant="rounded"
            height="50px"
          />
        ))}
      </Stack>
    );
  }

  const handleVisibilityChange = (fileId: string, isVisible: boolean) => {
    updateVisibility({
      variables: {
        file_id: fileId,
        cma_report_id: cmaReportId,
        is_visible: isVisible,
      },
    });
  };

  const columns: GridColDef<CmaReportFile>[] = [
    fileDef,
    {
      field: 'is_visible',
      flex: 1,
      minWidth: 50,
      headerName: t('Attach to CMA'),
      renderCell: ({ row: { is_visible, file } }) => (
        <Checkbox
          size="small"
          sx={{
            '&.MuiCheckbox-root': {
              padding: '4px 10px',
            },
          }}
          disableRipple
          checked={is_visible}
          onChange={evt => handleVisibilityChange(file.id, evt.target.checked)}
        />
      ),
      sortable: false,
    },
    fileActionsDef,
  ];

  const updating = addingCmaFile || updatingCmaFile || updatingVisibility;

  const stepAppendFilesFormDefinition: FormDefinitionType = () => [
    {
      type: 'custom',
      name: 'append_files',
      gridProps: { md: 12 },
      element: (
        <>
          {viewerFile && (
            <FileViewerDialog
              open
              url={viewerFile?.url ?? ''}
              name={viewerFile?.name ?? ''}
              onClose={() => setViewerFile(null)}
            />
          )}
          {deleteFile && (
            <FileDeleteDialog
              open
              onDelete={() => {
                cache.evict({
                  id: cache.identify({
                    __typename: 'cma_report_files',
                    cma_report_id: deleteFile.cma_report_id,
                    file_id: deleteFile.file.id,
                  }),
                });
                cache.evict({
                  id: cache.identify({
                    __typename: 'property_files',
                    property_id: propertyId,
                    file_id: deleteFile.file.id,
                  }),
                });
                cache.gc();

                deleteCmaReportFile({
                  variables: {
                    file_id: deleteFile.file.id,
                    cma_report_id: cmaReportId,
                  },
                });
              }}
              fileId={deleteFile.file.id}
              onClose={() => setDeleteFile(null)}
            />
          )}
          <FullScreenDropZone
            onDrop={handleDrop}
            disabled={isReOrdering}
            accept="*"
          />
          <Box p={2} mb={1} mx={-2}>
            <UploadImageCard noMinHeight onClick={openFileDialog} />
          </Box>
          <DataGridPremium
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
            }}
            autoHeight
            rows={cmaReportFiles as GridRowsProp<CmaReportFile>}
            columns={columns}
            rowReordering
            ref={onGridRefChange}
            onRowOrderChange={handleRowOrderChange}
            getRowId={row => row.file.id}
            rowHeight={64}
            disableAutosize
            disableColumnMenu
            disableColumnSelector
            disableColumnFilter
            disableColumnSorting
            disableMultipleRowSelection
            disableColumnReorder
            hideFooter
          />
          {pendingFiles.length > 0 && (
            <Table sx={{ widht: '100%', tableLayout: 'fixed' }}>
              <TableBody>
                {pendingFiles.map((file, index) => (
                  <TableRow key={`${file.name}-${index}`}>
                    <TableCell padding="checkbox">
                      <FileIcon mimetype={file.type} />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ maxWidth: '100%' }}>
                        <Typography noWrap>{file.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <FileErrors />
        </>
      ),
    },
  ];

  return (
    <>
      <RaForm
        formDefinition={stepAppendFilesFormDefinition}
        onSubmit={() => Promise.resolve()}
        actionButtonsComponent={
          <FooterActions
            {...props}
            updating={updating}
            showTemplate
            setTemplateOpen={setTemplateOpen}
          />
        }
        contentScrollable
      />

      <TemplateViewerDialog
        templateId={toGlobalId('DocTemplate', cmaReport.doc_template?.id ?? '')}
        documentId={toGlobalId('CmaReport', cmaReport.id)}
        open={templateOpen}
        onClose={() => setTemplateOpen(false)}
        language={cmaReport.language}
      />

      {error && (
        <RASnackbar message={error.message} onClose={() => setError(null)} />
      )}
    </>
  );
};

export default StepAppendFiles;
