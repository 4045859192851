// @flow

import * as React from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { graphql, useMutation } from 'react-relay';

import { ProgressButton } from '../controls/progress-button';
import { useLocale } from '../hooks/locale';

import type { fileDeleteDialogMutation } from './__generated__/fileDeleteDialogMutation.graphql';

type Props = {|
  parentId: string,
  fileId: string,
  open: boolean,
  onClose: () => void,
|};

export const FileDeleteDialog = (props: Props): React.Node => {
  const { t } = useLocale();
  const [deleteFile, deleting] = useMutation<fileDeleteDialogMutation>(
    graphql`
      mutation fileDeleteDialogMutation($input: DeleteFileInput!) {
        deleteFile(input: $input) {
          property {
            files {
              id
            }
          }
          development {
            files {
              id
            }
          }
          user {
            files {
              id
            }
          }
          emailTemplate {
            files {
              id
            }
          }
        }
      }
    `,
  );

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('areYouSureToDeleteFile')}</DialogTitle>
      <DialogActions>
        <Button onClick={props.onClose}>{t('Cancel')}</Button>
        <ProgressButton
          variant="text"
          loading={deleting}
          color="secondary"
          onClick={() => {
            deleteFile({
              variables: {
                input: {
                  parentId: props.parentId,
                  id: props.fileId,
                },
              },
              onCompleted: () => {
                props.onClose();
              },
            });
          }}
        >
          {t('delete')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};
