import { useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-premium';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import {
  translateAppraisalReason,
  translateSaleHorizon,
} from '../../../src/utils/lead-labels';
import type {
  GetTeamContactRequestsQuery,
  GetTeamValuationRequestsQuery,
} from '../../__generated__/graphql';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { TimeAgo } from '../../components/TimeAgo';

import {
  GET_TEAM_CONTACT_REQUESTS,
  GET_TEAM_VALUATION_REQUESTS,
} from './teamQueries';

const TeamContactRequest = () => {
  const { teamId } = useParams();
  const { t } = useLocale();

  const { data } = useQuery(GET_TEAM_CONTACT_REQUESTS, {
    variables: { teamId: teamId ?? '' },
    skip: teamId == null,
  });

  const columns: GridColDef<GetTeamContactRequestsQuery['enquiries'][0]>[] = [
    {
      field: 'created_at',
      headerName: t('Date'),
      width: 80,
      renderCell: ({ row }) =>
        row?.created_at && <TimeAgo dateString={row?.created_at} />,
    },
    {
      field: 'enquiry',
      headerName: t('Contact'),
      width: 160,
      renderCell: ({ row }) =>
        row && [row?.first_name, row?.last_name].join(' '),
    },
    {
      field: 'contact_details',
      headerName: t('Contact Details'),
      width: 180,
      display: 'flex',
      renderCell: ({ row }) => (
        <Stack>
          <Typography variant="body2">{row?.phone_number}</Typography>
          <Typography variant="caption">{row?.email_address}</Typography>
        </Stack>
      ),
    },
    {
      field: 'message',
      valueGetter: (_, row) => row?.message,
      headerName: t('Message'),
      width: 280,
    },
    {
      field: 'contact_reason',
      headerName: t('Contact Reason'),
      valueGetter: (_, row) => row?.contact_reason,
      width: 180,
    },
    {
      field: 'agent',
      headerName: t('Agent'),
      width: 240,
      renderCell: ({ row }) => row?.broker && <UserInfo user={row.broker} />,
    },
  ];

  return (
    <DataGridPremium
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      sx={{
        borderColor: 'transparent',
        maxHeight: '100%',
      }}
      columns={columns}
      rows={data?.enquiries ?? []}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
    />
  );
};

const TeamValuationRequest = () => {
  const { teamId } = useParams();
  const { t } = useLocale();

  const { data } = useQuery(GET_TEAM_VALUATION_REQUESTS, {
    variables: { teamId: teamId ?? '' },
    skip: teamId == null,
  });

  const columns: GridColDef<GetTeamValuationRequestsQuery['lead_agents'][0]>[] =
    [
      {
        field: 'created_at',
        headerName: t('Date'),
        width: 100,
        renderCell: ({ row }) =>
          row?.created_at && <TimeAgo dateString={row?.created_at} />,
      },
      {
        field: 'contact',
        headerName: t('Contact'),
        width: 240,
        renderCell: ({ row }) =>
          row.lead?.contact &&
          [row.lead?.contact?.first_name, row.lead?.contact?.last_name].join(
            ' ',
          ),
      },
      {
        field: 'contact_details',
        headerName: t('Contact Details'),
        width: 180,
        display: 'flex',
        renderCell: ({ row }) => (
          <Stack>
            <Typography variant="body2">
              {row?.lead?.contact?.phone_numbers?.[0]?.number}
            </Typography>
            <Typography variant="caption">
              {row?.lead?.contact?.emails?.[0]?.email}
            </Typography>
          </Stack>
        ),
      },
      {
        field: 'appraisal_reason',
        headerName: t('Appraisal Reason'),
        valueGetter: (_, row) =>
          translateAppraisalReason(t, row?.lead?.appraisal_reason),
        width: 180,
      },
      {
        field: 'sale_horizon',
        valueGetter: (_, row) =>
          translateSaleHorizon(t, row?.lead?.sale_horizon),
        headerName: t('Sale Horizon'),
        width: 180,
      },
      {
        field: 'agent',
        headerName: t('Agent'),
        width: 240,
        renderCell: ({ row }) => row?.user && <UserInfo user={row.user} />,
      },
    ];

  const leadAgents = data?.lead_agents?.filter(row => row?.lead != null) ?? [];

  return (
    <DataGridPremium
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      sx={{
        borderColor: 'transparent',
        maxHeight: '100%',
      }}
      columns={columns}
      rows={leadAgents}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
    />
  );
};

export const TeamContacts = () => {
  const { t } = useLocale();
  const [tab, setTab] = useState('valuation-requests');

  return (
    <>
      <Box
        borderRadius={4}
        m={[2, 4]}
        component={Paper}
        variant="outlined"
        sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}
      >
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent, tab: string) => setTab(tab)}
        >
          <Tab label={t('Valuation Requests')} value="valuation-requests" />
          <Tab label={t('Contact Requests')} value="contact-requests" />
        </Tabs>
        <Divider />
        <Box sx={{ minHeight: 0 }}>
          {tab === 'valuation-requests' && <TeamValuationRequest />}
          {tab === 'contact-requests' && <TeamContactRequest />}
        </Box>
      </Box>
    </>
  );
};
