import React from 'react';

import type { SetHook } from '../../../../src/hooks/set';
import type { StepListingsCompareFragment } from '../../../__generated__/graphql';
import ComparableListings from '../../comparable-listings/ComparableListings';

import ListingAutomatic from './ListingAutomatic';

interface ListingWrapperProps {
  mode: 'automatic' | 'manual';
  selection: SetHook<string | number>;
  onSelect: (id: string | number) => void;
  data?: StepListingsCompareFragment;
}

const ListingWrapper: React.FC<ListingWrapperProps> = ({
  mode,
  selection,
  onSelect,
  data,
}) => {
  if (mode === 'automatic' && data) {
    return (
      <ListingAutomatic selection={selection} onSelect={onSelect} data={data} />
    );
  } else if (mode === 'manual') {
    return (
      <ComparableListings
        selection={selection}
        onSelect={onSelect}
        isDrawer={true}
      />
    );
  }
  return null;
};

export default ListingWrapper;
