import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Form, useFormik } from '@realadvisor/form';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useResponsive } from 'react-system';

import IndicatorLeadStage from '../apollo/components/IndicatorLeadStage';
import { RelayHasuraWrapper } from '../networking';
import { useLocale } from '../src/hooks/locale';

import type { financingLeadLostDialogQuery } from './__generated__/financingLeadLostDialogQuery.graphql';
import { fromHasuraGlobalId, toGlobalId } from './global-id';

type FinancingLeadLostDialogProps = {
  open: boolean;
  onClose: () => void;
  onChange?: ((note: string) => void) | null;
  onConfirm: (note: string, lostStageId: string) => void;
};

type Stage = {
  id: string;
  label: string;
  label_en: string;
};

type StageSelectProps = {
  value: Stage | null;
  error: boolean;
  onChange: (value: Stage) => void;
};

type State = {
  note: string;
  stage: Stage | null;
};

const FinancingLeadLostTypeSelect = ({
  value,
  error,
  onChange,
}: StageSelectProps) => {
  const data = useLazyLoadQuery<financingLeadLostDialogQuery>(
    graphql`
      query financingLeadLostDialogQuery {
        lead_stages_connection(
          where: {
            _and: [
              { pipeline: { name: { _eq: "financing" } } }
              { status: { _eq: lost } }
            ]
          }
        ) {
          edges {
            node {
              id
              label
              label_en
            }
          }
        }
      }
    `,
    {},
  );

  const stages = (data.lead_stages_connection?.edges ?? [])
    .map(edge => edge?.node)
    .filter(Boolean);

  return (
    <Select
      value={value?.id ?? ''}
      error={error}
      onChange={event => {
        const id = event.target.value;
        const stage = stages.find(item => item.id === id);
        if (stage) {
          onChange(stage as Stage);
        }
      }}
    >
      {stages.map((item, index) => (
        <MenuItem value={item.id} key={index}>
          <IndicatorLeadStage status="lost">{item.label}</IndicatorLeadStage>
        </MenuItem>
      ))}
    </Select>
  );
};

export const FinancingLeadLostDialog = (
  props: FinancingLeadLostDialogProps,
) => {
  const responsive = useResponsive();
  const { t } = useLocale();
  const { values, setValues, errors, valid, setTouched, submitForm } =
    useFormik<State>({
      initialValues: {
        note: '',
        stage: null,
      },
      validate: values => {
        const errors: {
          stage?: string | null;
          note?: string | null;
        } = {};

        if (values.stage == null) {
          errors.stage = t('reasonRequired');
        }

        if (values.stage?.label === 'Other' && values.note.trim() === '') {
          errors.note = t('Please leave a comment');
        }

        return errors;
      },
      onSubmit: values => {
        const stage = values.stage;

        if (stage) {
          const note = stage.label === 'Other' ? values.note : stage.label;

          props.onConfirm(
            note,
            toGlobalId('LeadStage', fromHasuraGlobalId(stage.id)),
          );
        }
      },
    });

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={responsive([true, false])}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>{t('Why is this lead dead?')}</DialogTitle>
      <DialogContent>
        <Form onSubmit={submitForm}>
          <div css={{ display: 'grid', gap: 16 }}>
            <FormControl variant="filled">
              <InputLabel>{t('financingLeadLostReasons')}</InputLabel>
              <RelayHasuraWrapper>
                <React.Suspense fallback={null}>
                  <FinancingLeadLostTypeSelect
                    value={values.stage}
                    error={errors.stage != null}
                    onChange={event => {
                      setValues({
                        stage: event,
                      });

                      props.onChange?.(
                        event.label === 'Other' ? values.note : event.label,
                      );
                    }}
                  />
                </React.Suspense>
              </RelayHasuraWrapper>

              <FormHelperText error={errors.stage != null}>
                {errors.stage ?? ''}
              </FormHelperText>
            </FormControl>
            {values.stage?.label_en === 'Other' && (
              <FormControl required={true}>
                <TextField
                  variant="filled"
                  required={true}
                  error={errors.note != null}
                  helperText={errors.note}
                  multiline={true}
                  label={t('Note')}
                  autoFocus={true}
                  value={values.note}
                  onBlur={() => setTouched({ note: true })}
                  onChange={event => {
                    setValues({
                      note: event.target.value,
                    });
                    props.onChange?.(event.target.value);
                  }}
                />
              </FormControl>
            )}
          </div>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('Back')}</Button>
        <Button color="primary" disabled={!valid} onClick={submitForm}>
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
