// @flow

import * as React from 'react';

import {
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';

import { Menu } from '../../controls/popup';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Lock } from '../../icons/lock';
import { LockOpen } from '../../icons/lock-open';

type Props = {|
  visibility: ?string,
  onChange: (visibility: 'private' | 'shared') => void,
  variant?: 'chip' | 'icon',
  disabled?: boolean,
|};

export const EmailVisibilityButton = ({
  visibility,
  onChange,
  variant = 'chip',
  disabled,
}: Props): React.Node => {
  const [menu, setMenu] = React.useState(false);
  const targetRef = React.useRef(null);
  const { t } = useLocale();
  const { colors } = useTheme();

  if (visibility == null) {
    return null;
  }

  return (
    <>
      {variant === 'chip' && (
        <Chip
          icon={
            visibility === 'shared' ? (
              <LockOpen size={18} />
            ) : (
              <Lock size={18} />
            )
          }
          ref={targetRef}
          label={
            visibility === 'shared'
              ? t('visibilityShared')
              : t('visibilityPrivate')
          }
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setMenu(val => !val);
          }}
          color={visibility === 'shared' ? 'primary' : 'default'}
        />
      )}

      {variant === 'icon' && (
        <IconButton
          disabled={disabled}
          size="small"
          css={{
            ':disabled': {
              background: colors.grey700,
              color: colors.white,
            },
            background:
              visibility === 'shared' ? colors.grey700 : colors.grey300,
            color: visibility === 'shared' ? colors.white : colors.grey600,
            '&:hover': {
              background:
                visibility === 'shared' ? colors.grey600 : colors.grey200,
            },
            padding: '7px',
          }}
          ref={targetRef}
          color={visibility === 'shared' ? 'primary' : 'default'}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setMenu(val => !val);
          }}
        >
          {visibility === 'shared' && <LockOpen size={22} />}
          {visibility === 'private' && <Lock size={22} />}
        </IconButton>
      )}

      <Menu referenceRef={targetRef} open={menu} onClose={() => setMenu(false)}>
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onChange('shared');
            setMenu(false);
          }}
        >
          <ListItemIcon>
            <LockOpen size={24} />
          </ListItemIcon>
          <ListItemText
            css={{ whiteSpace: 'normal' }}
            primary={t('shareWithinYourCompany')}
            secondary={t('shareWithinYourCompanyExplanation')}
          />
        </MenuItem>
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onChange('private');
            setMenu(false);
          }}
        >
          <ListItemIcon>
            <Lock size={24} />
          </ListItemIcon>
          <ListItemText
            css={{ whiteSpace: 'normal' }}
            primary={t('private')}
            secondary={t('keepThisConversationPrivateExplanation')}
          />
        </MenuItem>
      </Menu>
    </>
  );
};
