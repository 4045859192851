import {
  type SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { PersonOutlineOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  type AutocompleteInputChangeReason,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  debounce,
} from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import type {
  GetUsersByIdsQuery,
  GetUsersByIdsQueryVariables,
  UserFilterFragment,
  UserFilterSearchUsersTsvQuery,
  Users_Bool_Exp,
} from '../../../__generated__/graphql';
import { USER_AVATAR_FRAGMENT, UserAvatar } from '../../UserAvatar';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';

import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';
import { AutocompletePaper, AutocompleteTextField } from './QuickFilters';

const USER_FILTER_FRAGMENT = gql`
  ${USER_AVATAR_FRAGMENT}
  fragment UserFilter on users {
    ...UserAvatar
    id
    full_name
    primaryEmail: emails(
      order_by: { primary: desc, verified: desc }
      limit: 1
    ) {
      email
    }
  }
`;

const SEARCH_USERS_TSV = gql`
  ${USER_FILTER_FRAGMENT}
  query UserFilterSearchUsersTsv($q: String!, $where: users_bool_exp) {
    search_users_tsv(
      args: { search_text: $q }
      where: $where
      order_by: { full_name: asc, is_broker: desc }
      limit: 10
    ) {
      id
      ...UserFilter
    }
  }
`;

const GET_DEFAULT_USERS = gql`
  ${USER_FILTER_FRAGMENT}
  query GetUsersByIds(
    $ids: [uuid!]!
    $isNoneSelected: Boolean!
    $where: users_bool_exp
  ) {
    selected_users: users(where: { id: { _in: $ids } })
      @skip(if: $isNoneSelected) {
      id
      ...UserFilter
    }

    initial_users: users(
      where: $where
      order_by: { full_name: asc, is_broker: desc }
      limit: 10
    ) {
      id
      ...UserFilter
    }
  }
`;

type NullableUserOption = { id: null; full_name: string };
type UserOption = UserFilterFragment | NullableUserOption;

interface UserMultiSelectProps {
  onClose?: () => void;
  onChange: (value: (string | null)[]) => void;
  defaultValue: (string | null)[];
  isNullable: boolean;
  filters?: Users_Bool_Exp;
}

export const UserMultiSelect = ({
  onChange,
  onClose,
  defaultValue,
  isNullable,
  filters,
}: UserMultiSelectProps) => {
  const { t } = useLocale();
  const [options, setOptions] = useState<UserFilterFragment[]>([]);
  const [inputValue, setInputValue] = useState('');
  const isNoneSelected = defaultValue.includes(null);

  const noUserOption = useMemo(
    () => ({
      id: null,
      full_name: t('None'),
    }),
    [t],
  );

  const [selectedOptions, setSelectedOptions] = useState<UserOption[]>(
    isNoneSelected ? [noUserOption] : [],
  );

  const [searchUsers, { loading: searchLoading }] =
    useLazyQuery<UserFilterSearchUsersTsvQuery>(SEARCH_USERS_TSV);

  const defaultIds = defaultValue.filter(v => v != null);

  const { data, loading: defaultLoading } = useQuery<
    GetUsersByIdsQuery,
    GetUsersByIdsQueryVariables
  >(GET_DEFAULT_USERS, {
    variables: {
      ids: defaultIds,
      isNoneSelected,
      where: { id: { _nin: defaultIds }, ...(filters ?? {}) },
    },
  });

  const updateOptions = useMemo(
    () =>
      (newValue: UserFilterFragment[], initialTeams?: UserFilterFragment[]) => {
        const combined = [...newValue, ...(initialTeams ?? [])];
        const uniqueCombined = combined.filter(
          (team, idx, arr) => arr.findIndex(t => t.id === team.id) === idx,
        );
        setOptions(uniqueCombined);
      },
    [],
  );

  useEffect(() => {
    if (data && options.length === 0 && !inputValue) {
      setSelectedOptions(
        isNoneSelected ? [noUserOption] : data?.selected_users ?? [],
      );
      updateOptions(data?.selected_users ?? [], data?.initial_users ?? []);
    }
  }, [
    data,
    inputValue,
    isNoneSelected,
    noUserOption,
    options.length,
    updateOptions,
  ]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchValue: string) => {
        const { data: searchData } = await searchUsers({
          variables: {
            q: searchValue,
            where: {
              id: { _nin: defaultValue },
              ...(filters ?? {}),
            },
          },
        });
        if (!searchData?.search_users_tsv?.length) {
          setOptions([]);
        } else {
          setOptions(searchData?.search_users_tsv ?? []);
        }
      }, 275),
    [searchUsers, defaultValue, filters],
  );

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (searchValue) {
        debouncedSearch(searchValue);
      } else {
        updateOptions(data?.selected_users ?? [], data?.initial_users);
      }
    },
    [data?.initial_users, data?.selected_users, debouncedSearch, updateOptions],
  );

  const displayOptions = useMemo(() => {
    if (!isNullable || options.length === 0) {
      return options;
    }
    return [noUserOption, ...options];
  }, [isNullable, noUserOption, options]);

  const handleToggleOptions = useCallback(
    (_: any, newValue: UserOption[]) => {
      const currentHasNull = selectedOptions.some(v => v.id == null);
      const newHasBroker = newValue.some(v => v.id != null);

      const finalValue =
        currentHasNull && newHasBroker
          ? newValue.filter(v => v.id != null)
          : newValue.some(v => v.id == null)
          ? [noUserOption]
          : newValue;

      setSelectedOptions(finalValue);
      onChange(finalValue.map(v => v.id));
    },
    [onChange, noUserOption, selectedOptions],
  );

  const handleInputChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      newValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason !== 'input') {
        return;
      }
      setInputValue(newValue);
      handleSearch(newValue);
    },
    [handleSearch],
  );

  const loading = searchLoading || defaultLoading;

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      openOnFocus
      open
      disablePortal
      disableClearable
      size="small"
      clearOnBlur={false}
      value={selectedOptions}
      loading={loading}
      onClose={onClose}
      sx={{ width: 350 }}
      options={displayOptions}
      getOptionLabel={option => option.full_name ?? ''}
      filterOptions={x => x}
      PaperComponent={AutocompletePaper}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleToggleOptions}
      renderInput={params => (
        <AutocompleteTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} key={option.id} dense>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Checkbox
              edge="start"
              checked={selected}
              tabIndex={-1}
              disableRipple
            />
            {option.id != null && (
              <ListItemAvatar>
                <UserAvatar user={option} />
              </ListItemAvatar>
            )}
          </ListItemIcon>
          <ListItemText
            primary={option.full_name}
            secondary={option.id != null ? option.primaryEmail[0]?.email : null}
          />
        </ListItem>
      )}
      noOptionsText={t('No options')}
    />
  );
};

export const UserFilter = ({
  label,
  path,
  where,
  addWhereClause,
  deleteWhereClause,
  queryParamsScope,
  disabled,
  isNullable,
  filter,
}: QuickFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);
  const { getValueFromPath } = useSchema();

  const nullValuePath = useMemo(() => {
    const [nullPath, ...restPath] = path;
    return [nullPath, '_not', ...restPath];
  }, [path]);

  const defaultValue = useMemo(
    () =>
      getValueFromPath(path, where)?.id?._in ??
      (getValueFromPath(nullValuePath, where) ? [null] : []),
    [getValueFromPath, path, where, nullValuePath],
  );

  // Filters the autocomplete list of users
  const whereFilter = filter
    ? addWhereClause({}, filter.path, filter.value)
    : {};

  const handleChange = useCallback(
    (value: (string | null)[]) => {
      let newWhere = deleteWhereClause(where, path, true);

      if (defaultValue.includes(null)) {
        newWhere = deleteWhereClause(newWhere, nullValuePath, true);
      }

      if (value.length > 0 && value.includes(null)) {
        newWhere = addWhereClause(newWhere, nullValuePath, {});
      } else if (value.length > 0) {
        newWhere = addWhereClause(newWhere, [...path, 'id', '_in'], value);
      }
      setFiltersParams(newWhere);
    },
    [
      deleteWhereClause,
      where,
      path,
      defaultValue,
      setFiltersParams,
      nullValuePath,
      addWhereClause,
    ],
  );

  const handleDelete =
    defaultValue.length > 0 ? () => handleChange([]) : undefined;

  return (
    <FilterChip
      label={label}
      icon={<PersonOutlineOutlined />}
      onDelete={handleDelete}
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <UserMultiSelect
          isNullable={isNullable ?? false}
          onClose={handleClose}
          onChange={handleChange}
          defaultValue={defaultValue}
          filters={whereFilter}
        />
      )}
    />
  );
};
