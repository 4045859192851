import { useApolloClient } from '@apollo/client';

import { ListingPropertyImages } from '../listings/ListingPropertyImages';

import { useCMAEditor } from './CMAReportEditor/CMAReportEditorWorkflow';
import { STEP_COVER_PHOTO_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActionsComponentWithoutForm,
  useUpdateCmaReport,
} from './shared';

const StepPhotos = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const { propertyId } = useCMAEditor();
  const client = useApolloClient();

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-photos',
  );

  return (
    <>
      <ListingPropertyImages
        propertyId={propertyId}
        canEditImagesMeta={false}
        canOpenRightPanel={false}
        onUpdate={() => {
          updateCmaReport({});
        }}
        onAdd={newImages => {
          client.cache.updateFragment(
            {
              id: `cma_reports:${cmaReportId}`,
              fragment: STEP_COVER_PHOTO_FRAGMENT,
              fragmentName: 'StepCoverPhoto',
            },
            data => {
              const existingsImages =
                data?.lead?.property?.property_images ?? [];
              const propertyImages = [...existingsImages, ...newImages];

              return {
                ...data,
                id: data?.id ?? '',
                updated_at: data?.updated_at ?? '',
                lead: {
                  id: data?.lead?.id ?? '',
                  property: {
                    ...data?.lead?.property,
                    id: data?.lead?.property?.id ?? '',
                    updated_at: data?.lead?.property?.updated_at ?? '',
                    property_images: propertyImages,
                  },
                },
              };
            },
          );

          updateCmaReport({});
        }}
      />

      <FooterActionsComponentWithoutForm updating={updating} {...props} />
    </>
  );
};

export default StepPhotos;
