import { Box, Tooltip, useTheme } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { abbrDateDifference } from '../../../src/utils/abbrDateDifference';
import { gql } from '../../__generated__';
import { type LeadUpdatedTimeAgoFragment } from '../../__generated__/graphql';
import { TimeAgo } from '../TimeAgo';

export const LEAD_UPDATED_TIME_AGO_FRAGMENT = gql(/* GraphQL */ `
  fragment LeadUpdatedTimeAgo on leads {
    created_at
    requalified_at
  }
`);

interface LeadUpdatedTimeAgoProps {
  shortVersion?: boolean;
  showBothDate: boolean;
  customCreationDate?: string | null;
  lead: LeadUpdatedTimeAgoFragment;
  suffixLabel?: string;
}

const TooltipTitle: React.FC<{
  createdAt: Date;
  requalifiedAt: Date | null;
}> = ({ createdAt, requalifiedAt }) => {
  const { t, intlLocale } = useLocale();

  return (
    <>
      <div>
        {`${t('created')}: ${new Intl.DateTimeFormat(intlLocale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }).format(createdAt)}`}
      </div>
      {requalifiedAt && (
        <div>
          {t('requalifiedAt')}:{' '}
          {new Intl.DateTimeFormat(intlLocale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }).format(requalifiedAt)}
        </div>
      )}
    </>
  );
};

export const LeadUpdatedTimeAgo: React.FC<LeadUpdatedTimeAgoProps> = ({
  lead,
  shortVersion = false,
  showBothDate,
  customCreationDate = null,
  suffixLabel,
}) => {
  const { t } = useLocale();
  const now = new Date();
  const { palette } = useTheme();

  const createdAt = new Date(lead.created_at);
  const requalifiedAt =
    lead.requalified_at != null ? new Date(lead.requalified_at) : null;
  const parsedCreatedAt = abbrDateDifference(t, new Date(), createdAt).text;
  const parsedRequalifiedAt =
    requalifiedAt && abbrDateDifference(t, new Date(), requalifiedAt).text;

  let dateDisplay = null;

  if (customCreationDate != null) {
    dateDisplay = <TimeAgo dateString={customCreationDate} />;
  } else if (showBothDate === true && lead.requalified_at != null) {
    dateDisplay = (
      <Tooltip
        title={
          <TooltipTitle createdAt={createdAt} requalifiedAt={requalifiedAt} />
        }
        placement="top"
      >
        <div>
          {parsedCreatedAt}
          {` .
              ${
                abbrDateDifference(t, now, new Date(lead.requalified_at)).text
              } ♺`}
        </div>
      </Tooltip>
    );
  } else if (shortVersion === true) {
    dateDisplay = (
      <Tooltip
        title={
          <TooltipTitle createdAt={createdAt} requalifiedAt={requalifiedAt} />
        }
        placement="top"
      >
        <div>{parsedRequalifiedAt ?? parsedCreatedAt}</div>
      </Tooltip>
    );
  } else {
    dateDisplay = (
      <TimeAgo dateString={lead.requalified_at ?? lead.created_at} />
    );
  }

  return (
    <Box sx={theme => theme.mixins.truncate()}>
      {dateDisplay}
      {suffixLabel != null && (
        <Box
          sx={theme => ({
            ...theme.mixins.truncate(),
            color: palette.grey[700],
          })}
        >
          {suffixLabel}
        </Box>
      )}
    </Box>
  );
};
