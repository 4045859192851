import { gql } from '../__generated__';

export const GET_PROPERTY_TYPES = gql(/* GraphQL */ `
  query getPropertyTypes {
    property_types {
      id
      name
      label
      main_type
      type
    }
  }
`);
