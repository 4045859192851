import { memo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Box, Typography, useTheme } from '@mui/material';
import { Image } from '@realadvisor/image';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../../src/locale';
import { formatPrice } from '../../../../src/utils/format-price';
import { NoPhotosPlaceholder } from '../../../components/NoPhotoPlaceholder';
import type { ComparableListingsCmaListing } from '../../comparable-listings/ComparableListings';

import type { ListingAutomaticType } from './ListingAutomatic';
import { initialListingComparableDetails } from './ListingComparableDetails';
import ListingStatusChip from './ListingStatusChip';
import { usePropertyLabel } from './StepListingsCompare';

export type Listing =
  | ListingAutomaticType
  | NonNullable<ComparableListingsCmaListing>[number];

type ListingCardInfoProps = {
  listing: Listing;
  variant: 'horizontal' | 'vertical';
};
const ListingCardInfo = ({ listing, variant }: ListingCardInfoProps) => {
  const client = useApolloClient();
  const getPropertyLabel = usePropertyLabel;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t, locale, countryCode } = useLocale();
  const theme = useTheme();
  const [imageLoadError, setImageLoadError] = useState(false);
  const listingImages = listing.images?.map(image => image);
  const [primaryImage] = listingImages;
  const currencyToFormat = getCurrencyByCountryCode(countryCode);

  const handleClickCard = () => {
    const compositeListingId = `${listing.id}/${listing.state_id}`;
    const encodedListingId = btoa(compositeListingId);

    navigate({
      pathname: `${encodedListingId}`,
      search: searchParams.toString(),
    });
  };

  return (
    <>
      <Box
        onMouseEnter={() =>
          client.query(
            initialListingComparableDetails(
              listing.id,
              countryCode,
              listing.state_id ?? '',
            ),
          )
        }
        onClick={handleClickCard}
        sx={[
          {
            background: theme.palette.grey[200],
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          },
          variant === 'vertical'
            ? {
                height: '200px',
                alignSelf: 'stretch',
              }
            : {
                width: { xs: '124px', sm: '180px' },
                minWidth: { xs: '124px', sm: '180px' },
              },
        ]}
      >
        <Box
          sx={[
            {
              position: 'absolute',
              zIndex: 1,
            },
            variant === 'vertical'
              ? { top: '8px', left: '8px' }
              : {
                  bottom: { xs: '4px', sm: {} },
                  left: { xs: '4px', sm: '14px' },
                  top: { sm: '4px' },
                },
          ]}
        />
        {primaryImage != null && !imageLoadError ? (
          <Box sx={{ flexGrow: 1, height: 0 }}>
            <Image
              src={{
                bucketName: primaryImage.bucket_name ?? '',
                fileName: primaryImage.file_name ?? '',
              }}
              onError={() => setImageLoadError(true)}
            />
          </Box>
        ) : (
          <NoPhotosPlaceholder ratio={variant === 'vertical' ? 1 / 2 : 1} />
        )}
      </Box>
      <Box
        onClick={handleClickCard}
        display="flex"
        px={2}
        py={1}
        flexDirection="column"
        flexGrow={1}
        width="100%"
      >
        <Box>
          <Typography
            sx={[
              {
                lineHeight: 1.3,
                fontWeight: 'bold',
                fontSize: '1rem',
                whiteSpace: 'nowrap',
              },
            ]}
          >
            {getPropertyLabel(listing.property_type)}
          </Typography>

          <Typography
            sx={{
              ...theme.typography.body2,
              color: theme.palette.grey['800'],
              fontSize: '0.8rem',
            }}
          >
            <Box>
              {[
                listing.number_of_rooms != null &&
                  t('roomsCount', { count: listing.number_of_rooms }),
                `${listing.computed_surface ?? 0} m²`,
                listing.land_surface != null &&
                  t('plotSurfaceOf', {
                    plotSurface: listing.land_surface ?? '',
                  }),
              ]
                .filter(Boolean)
                .join(' · ')}
            </Box>
            <Box>
              {[
                [listing.route, listing.street_number]
                  .filter(Boolean)
                  .join(' ')
                  .trim(),
                [listing.postcode, listing.locality]
                  .filter(Boolean)
                  .join(' ')
                  .trim(),
              ]
                .filter(Boolean)
                .join(', ')}
            </Box>
          </Typography>
          <Box sx={{ marginTop: '4px' }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', lineHeight: 1.3 }}
            >
              {formatPrice(listing.sale_price ?? 0, locale, currencyToFormat)}
            </Typography>
            <Typography
              sx={{
                ...theme.typography.body2,
                color: theme.palette.grey['800'],
              }}
            >
              {formatPrice(
                listing.computed_price_per_sqm ?? 0,
                locale,
                currencyToFormat,
              )}{' '}
              / m²
            </Typography>
          </Box>
        </Box>

        {variant === 'horizontal' ? (
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            gap={1}
            mt={0.5}
          >
            <ListingStatusChip listing={listing} variant="status" />
            <ListingStatusChip listing={listing} variant="duration" />
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            gap={1}
            position="absolute"
            top={8}
            left={8}
            sx={{
              zIndex: 1,
            }}
          >
            <ListingStatusChip listing={listing} variant="status" />
            <ListingStatusChip listing={listing} variant="duration" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(ListingCardInfo);
