import { gql } from '../../__generated__';

export const PREPARE_UPLOAD = gql(/* GraphQL */ `
  mutation PrepareUpload(
    $id: String!
    $file_type: String!
    $file_name: String!
    $mime_type: String!
  ) {
    prepare_upload(
      id: $id
      file_type: $file_type
      file_name: $file_name
      mime_type: $mime_type
    ) {
      id
      url
      signed_url
    }
  }
`);
