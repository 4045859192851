import * as React from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
} from '@mui/material';
import { Carousel } from '@realadvisor/carousel';
import { useConstant } from '@realadvisor/hooks';
import { useResizeRect } from '@realadvisor/observe';

import { Fullscreen } from '../../icons/fullscreen';
import { useLocale } from '../../src/hooks/locale';
import { useTheme } from '../../src/hooks/theme';

import type { CardImage } from './select-image-card';

type Props = {
  images: CardImage[];
  onViewIndexChange: (index: number) => void;
  onShowFullScreen: (index: number) => void;
  onShowGrid: () => void;
  viewIndex: number;
  content: (image: CardImage, onShowGrid: () => void) => JSX.Element;
};

// allows to use callback in effects without triggering update on every render
const useStableCallback = <T extends (...args: any[]) => any>(
  callback: T,
): T => {
  const callbackRef = React.useRef(callback);
  const stable: any = React.useCallback(
    (...args: any) => callbackRef.current?.(...args),
    [],
  );
  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  return stable;
};

const CarouselPositionTrack = React.memo<{
  index: number;
  onChange: (index: number) => void;
}>(({ index, onChange }) => {
  React.useLayoutEffect(() => {
    onChange(index);
  }, [index, onChange]);
  return null;
});

export const EditImageCard = ({
  images,
  viewIndex,
  onShowFullScreen,
  onShowGrid,
  content,
  onViewIndexChange,
}: Props) => {
  const { t } = useLocale();
  const { text, colors } = useTheme();

  const carouselContainerRef = React.useRef(null);
  const carouselContainerRect = useResizeRect(carouselContainerRef);

  const initialViewIndex = useConstant(() => viewIndex);

  const stableOnViewIndexChange = useStableCallback(onViewIndexChange);
  const CarouselPosition = React.useCallback(
    ({ value }: { value: number }) => {
      return (
        <CarouselPositionTrack
          index={value - 1}
          onChange={stableOnViewIndexChange}
        />
      );
    },
    [stableOnViewIndexChange],
  );

  return (
    <Card
      css={{
        display: 'flex',
        flexDirection: 'column',
        margin: '8px',
        overflow: 'hidden',
      }}
    >
      <Grid container={true} spacing={0} alignItems="flex-start">
        <Grid
          ref={carouselContainerRef}
          css={{ position: 'relative', backgroundColor: '#000' }}
          item={true}
          xs={12}
          sm={6}
        >
          <CardActions
            css={{
              position: 'absolute',
              left: 0,
              right: 0,
              flexDirection: 'row-reverse',
              flexShrink: 0,
              zIndex: 1,
              svg: {
                fill: 'white',
              },
            }}
          >
            <IconButton
              onClick={() => onShowFullScreen(viewIndex)}
              size="small"
              color="primary"
            >
              <Fullscreen />
            </IconButton>
            <div css={{ flexGrow: 1 }} />
            <Button
              onClick={onShowGrid}
              size="small"
              color="primary"
              css={{ color: 'white' }}
              disableRipple={true}
            >
              {t('Back')}
            </Button>
          </CardActions>

          {carouselContainerRect && (
            <Carousel
              media={[
                {
                  gap: 0,
                  itemsOnScreen: 1,
                  aspectRatio: 1,
                },
                {
                  gap: 0,
                  itemsOnScreen: 1,
                  aspectRatio: 360 / carouselContainerRect.width,
                },
              ]}
              initialOffset={initialViewIndex}
              Position={CarouselPosition}
            >
              {images.map(item => {
                return (
                  <img
                    key={item.id}
                    css={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    src={item.image.url}
                  />
                );
              })}
            </Carousel>
          )}

          {images[viewIndex].title != null && (
            <div
              css={[
                text.subtitle2,
                {
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  padding: '8px 16px',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: colors.white,
                },
              ]}
            >
              {images[viewIndex].title}
            </div>
          )}
          <div
            css={[
              text.subtitle2,
              {
                position: 'absolute',
                bottom: 0,
                right: 0,
                padding: '8px 16px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: colors.white,
              },
            ]}
          >
            {viewIndex + 1}/{images.length}
          </div>
        </Grid>

        <Grid item={true} xs={12} sm={6}>
          <CardContent css={{ height: '100%' }}>
            {content(images[viewIndex], onShowGrid)}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
