import { memo } from 'react';

import { Tooltip, type TooltipProps } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import { formatTimeAgo } from '../utils/formatting';

interface TimeAgoProps {
  dateString?: string | null;
  addSuffix?: boolean;
  ignoreTime?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
}

export const TimeAgo = memo(
  ({
    dateString,
    addSuffix = false,
    ignoreTime = false,
    tooltipPlacement = 'top',
  }: TimeAgoProps) => {
    const { t, dateLocale } = useLocale();

    if (!dateString) {
      return null;
    }

    const { timeAgo, tooltipDate } = formatTimeAgo(
      dateString,
      addSuffix,
      ignoreTime,
      t,
      dateLocale,
      true,
    );

    return (
      <Tooltip title={tooltipDate} placement={tooltipPlacement} arrow={true}>
        <time>{timeAgo}</time>
      </Tooltip>
    );
  },
);
