import { useEffect, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { useFormContext, useWatch } from 'react-hook-form';
import type { To } from 'react-router-dom';

import { gql } from '../../__generated__';
import type { GetListingPreviewQuery } from '../../__generated__/graphql';
import {
  ListingCardSnippet,
  ListingCardSnippetSkeletton,
} from '../ListingCardSnippet';

const GET_LISTING_PREVIEW_QUERY = gql(/* GraphQL */ `
  query GetListingPreview(
    $listingId: uuid!
    $includeOffer: Boolean!
    $offerId: uuid
  ) {
    lots_by_pk(id: $listingId) {
      id
      broker_id
      ...ListingCardSnippet
      mandate_type
      last_accepted_offer: offers(where: { id: { _eq: $offerId } })
        @include(if: $includeOffer) {
        id
        amount
        buyer_id
      }
    }
  }
`);

export type ListingPreview = NonNullable<
  GetListingPreviewQuery['lots_by_pk']
> | null;

type RaListingPreviewProps = {
  srcListingFieldName?: string;
  targetListingFieldName?: string;
  listingLink?: (listingId: string) => To;
  onListingFetched?: (listing: ListingPreview) => void;
  preventFormDataUpdate?: boolean;
  orientation?: 'horizontal' | 'vertical';
  includeOfferInQuery?: string;
};

export type RaListingPreviewData<
  TSrcFieldName extends string = 'listing_id',
  TTargetFieldName extends string = '__listing',
> = {
  [Property in TSrcFieldName | TTargetFieldName]: Property extends TSrcFieldName
    ? string | null
    : ListingPreview;
};

export const RaListingPreview: React.FC<RaListingPreviewProps> = ({
  listingLink,
  onListingFetched,
  srcListingFieldName = 'listing_id',
  targetListingFieldName = '__listing',
  preventFormDataUpdate = false,
  orientation = 'horizontal',
  includeOfferInQuery,
}) => {
  const { setValue } = useFormContext();
  const listingId = useWatch({ name: srcListingFieldName });
  const listingRef = useRef<ListingPreview>(null);
  const { data, loading } = useQuery(GET_LISTING_PREVIEW_QUERY, {
    variables: {
      listingId,
      offerId: includeOfferInQuery,
      includeOffer: includeOfferInQuery != null,
    },
    skip: listingId == null,
  });

  useEffect(() => {
    if (data?.lots_by_pk?.id !== listingRef.current?.id) {
      onListingFetched?.(data?.lots_by_pk ?? null);
      listingRef.current = data?.lots_by_pk ?? null;

      if (!preventFormDataUpdate) {
        setValue(targetListingFieldName, data?.lots_by_pk ?? null);
      }
    }
  }, [
    data?.lots_by_pk,
    onListingFetched,
    setValue,
    targetListingFieldName,
    preventFormDataUpdate,
  ]);

  return (
    <>
      {data?.lots_by_pk != null && (
        <ListingCardSnippet
          listing={data.lots_by_pk}
          link={listingLink?.(data.lots_by_pk.id)}
          orientation={orientation}
        />
      )}
      {data?.lots_by_pk == null && listingId != null && !loading && (
        <ListingCardSnippetSkeletton
          orientation={orientation}
          restrictedAccess={true}
        />
      )}
      {loading && <ListingCardSnippetSkeletton orientation={orientation} />}
    </>
  );
};
