import { type ReactNode, useEffect, useState } from 'react';

import type { Interpolation } from '@emotion/react';
import {
  AppBar,
  DialogActions,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
} from '@mui/material';
import { Style } from 'react-head';
import { Box, useResponsive, useSystem } from 'react-system';

import { useTheme } from '../hooks/theme';
import { KeyboardArrowLeft } from '../icons/keyboard-arrow-left';

type ChildrenProps = {
  children: ReactNode;
};

type DrawerLayerProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  paperBg?: string;
  width?: ReadonlyArray<number | string>;
};

export const DrawerHeader = ({
  onClose,
  children,
  actions,
}: {
  onClose: () => void;
  children: ReactNode;
  actions?: ReactNode;
}) => {
  const { text } = useTheme();
  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton color="inherit" onClick={onClose}>
          <KeyboardArrowLeft />
        </IconButton>
        <Box ml={2} css={[text.h6, text.truncate(1)]} flexGrow={1}>
          {children}
        </Box>
        {actions}
      </Toolbar>
    </AppBar>
  );
};

export const DrawerContent = ({
  className,
  children,
}: {
  className?: string;
  css?: Interpolation<unknown>;
  children: ReactNode;
}) => {
  return (
    <div
      css={{
        flexGrow: 1,
        position: 'relative',
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export const DrawerBottomToolbar = ({ children }: ChildrenProps) => {
  const { colors } = useTheme();
  return (
    <Box
      css={{
        flexShrink: 0,
        position: 'sticky',
        zIndex: 10,
        bottom: 0,
        background: colors.white,
        // fixes collapsing of stupid margins in DialogActions
        overflow: 'hidden',
      }}
    >
      <Divider />
      <DialogActions>{children}</DialogActions>
    </Box>
  );
};

const MobileStyle = ({ open }: { open: boolean }) => {
  const responsive = useResponsive();
  if (open) {
    return responsive([
      <Style key="mobile">
        {`
          html {
            overflow: hidden;
          }
          body {
            overflow: hidden;
            position: relative;
          }
        `}
      </Style>,
      null,
    ]);
  }
  return null;
};

export const DrawerLayer = (props: DrawerLayerProps) => {
  const { media } = useSystem();
  const { colors, muiDrawerZIndex } = useTheme();

  // Render Drawer animation and set Tab activate
  useEffect(() => setOpen(props.open), [props.open]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setTimeout(props.onClose, 300);
  };

  return (
    <>
      <MobileStyle open={open} />
      <Drawer
        css={{
          '.MuiDrawer-paper': media({
            width: props.width ?? ['100vw', 500],
            backgroundColor: props.paperBg ?? colors.white,
          }) as any,
          zIndex: muiDrawerZIndex + 1,
        }}
        open={open}
        onClose={handleClose}
        anchor="right"
      >
        {props.children}
      </Drawer>
    </>
  );
};
