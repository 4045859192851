import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  Chip,
  Tooltip,
} from '@mui/material';
import { graphql, useFragment } from 'react-relay';

import { useLocale } from '../hooks/locale';
import type { leadQualificationCard_lead$data } from '../routes/LeadDetails/__generated__/leadQualificationCard_lead.graphql';

type Options = { title: string; value: string }[];
type Props = {
  options: Options;
  renderInput: (props: AutocompleteRenderInputParams) => JSX.Element;
  value?: Options;
  onChange: (event: React.ChangeEvent<{}>, value: Options) => void;
  tagData?: leadQualificationCard_lead$data['tags'] | null;
};

export const AutocompleteMultiple = ({
  options,
  renderInput,
  value,
  onChange,
  tagData,
}: Props) => {
  const { t, intlLocale } = useLocale();

  const dateTimeFormat = new Intl.DateTimeFormat(intlLocale, {
    dateStyle: 'medium',
    timeStyle: 'short',
  });

  const tags = useFragment(
    graphql`
      fragment autocompleteMultiple_tag on LeadsTag @relay(plural: true) {
        dictionary {
          name
        }
        createdAt
        createdBy {
          displayName
        }
      }
    `,
    tagData ?? null,
  );

  return (
    <Autocomplete
      filterSelectedOptions={true}
      multiple={true}
      options={options}
      getOptionLabel={option => option.title}
      value={value}
      renderInput={renderInput}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const tagInfo = Array.isArray(tags)
            ? tags.find(tag => tag.dictionary?.name === option.value)
            : tags;

          return (
            <Tooltip
              key={index}
              title={
                <div>
                  {t('Created by')}: {tagInfo.createdBy?.displayName ?? '-'}
                  <br />
                  {t('Created on')}:{' '}
                  {dateTimeFormat.format(new Date(tagInfo.createdAt))}
                </div>
              }
              placement="top"
            >
              <Chip
                variant="outlined"
                label={option.title}
                {...getTagProps({ index })}
              />
            </Tooltip>
          );
        })
      }
      onChange={onChange}
    />
  );
};
