import { useCallback, useMemo, useState } from 'react';

import { type QueryOptions, useMutation, useQuery } from '@apollo/client';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Visibility from '@mui/icons-material/Visibility';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-premium';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import { toGlobalId } from '../../../shared/global-id';
import { TemplateViewerDialog } from '../../../src/controls/template-viewer-dialog';
import { useLocale } from '../../../src/hooks/locale';
import { TopbarTitle } from '../../../src/shared/topbar';
import { gql } from '../../__generated__';
import type { GetCmaReportsListQuery } from '../../__generated__/graphql';
import { PropertyCell } from '../../components/data-grid/PropertyCell';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { TimeAgo } from '../../components/TimeAgo';
import { useAppData } from '../../providers/AppDataProvider';
import { getPaginationVariables } from '../../utils/paginations';

import { DELETE_CMA_REPORT, GET_CMA_REPORTS_COUNT } from './cmaReportsQueries';
import CreateCMAReport from './create-cma-report/CreateCMAReport';

export const initialCMAReportsListQuery = (
  searchParams: URLSearchParams,
): QueryOptions => {
  const where = JSON.parse(searchParams.get('where') ?? '{}');
  const order_by = JSON.parse(
    searchParams.get('order_by') ?? '[{ "created_at": "desc" }]',
  );
  const { limit, offset } = getPaginationVariables(searchParams);

  return {
    query: GET_CMA_REPORTS_LIST,
    variables: {
      where,
      order_by,
      limit,
      offset,
    },
  };
};

export const GET_CMA_REPORTS_LIST = gql(/* GraphQL */ `
  query GetCMAReportsList(
    $where: cma_reports_bool_exp!
    $order_by: [cma_reports_order_by!]
    $limit: Int!
    $offset: Int!
  ) {
    cma_reports(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      language
      suggested_market_value
      is_price_range
      price_range_min
      price_range_max
      language
      broker {
        ...UserInfo_user
      }
      contact {
        ...UserInfo_user
      }
      cover_image {
        url
      }
      doc_template {
        id
        name
      }
      updated_at
      created_at

      lead {
        id
        property {
          ...PropertyCell_property
        }
      }
    }
  }
`);

const Actions = ({ node }: { node: CMAReport }) => {
  const { t } = useLocale();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);

  const [deleteCmaReport, { loading: deleting }] = useMutation(
    DELETE_CMA_REPORT,
    {
      variables: { id: node.id },
      refetchQueries: [GET_CMA_REPORTS_LIST, GET_CMA_REPORTS_COUNT],
    },
  );

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>{t('areYouSureYouWantToDeleteCmaReport')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            {t('Cancel')}
          </Button>
          <LoadingButton
            color="secondary"
            type="submit"
            variant="contained"
            loading={deleting}
            onClick={() => {
              deleteCmaReport({
                update: cache => {
                  cache.evict({ id: `cma_reports:${node.id}` });
                  cache.gc();
                  setOpenDeleteDialog(false);
                },
              });
            }}
          >
            {t('delete')}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <IconButton
        title={t('viewAndDownload')}
        onClick={() => setTemplateOpen(true)}
      >
        <Visibility />
      </IconButton>

      <IconButton title={t('delete')} onClick={() => setOpenDeleteDialog(true)}>
        <DeleteForever />
      </IconButton>

      <TemplateViewerDialog
        templateId={toGlobalId('DocTemplate', node.doc_template?.id ?? '')}
        documentId={toGlobalId('CmaReport', node.id)}
        open={templateOpen}
        onClose={() => setTemplateOpen(false)}
      />
    </>
  );
};

type CMAReport = GetCmaReportsListQuery['cma_reports'][number];

const CMAReports = () => {
  const { t, locale } = useLocale();
  const { me } = useAppData();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { variables } = useMemo(
    () => initialCMAReportsListQuery(searchParams),
    [searchParams],
  );
  const { where, order_by, limit, offset } = variables ?? {};

  const { data, loading } = useQuery(GET_CMA_REPORTS_LIST, {
    variables: { where, order_by, limit, offset },
  });
  const cmaReports = data?.cma_reports ?? [];

  const { data: totalCountData } = useQuery(GET_CMA_REPORTS_COUNT, {
    variables: { where },
  });

  const totalCount =
    totalCountData?.cma_reports_aggregate?.aggregate?.count ?? 0;

  const cmaReportsLink = (id: string) => ({
    pathname: `${id}/page-cover`,
    search: `${searchParams.toString()}`,
  });

  const columns: GridColDef<CMAReport>[] = [
    {
      field: 'lead.property.route,lead.property.street_number',
      headerName: t('Property'),
      width: 250,
      renderCell: ({ row }) => {
        return (
          row.lead?.property && (
            <PropertyCell
              image_url={row.cover_image?.url}
              property={row.lead?.property}
            />
          )
        );
      },
    },
    {
      field: 'contact.first_name,contact.last_name',
      headerName: t('contact'),
      width: 200,
      renderCell: ({ row }) =>
        row.contact == null ? null : <UserInfo user={row.contact} />,
    },
    {
      field: 'broker.first_name,broker.last_name',
      headerName: t('broker'),
      width: 250,
      renderCell: ({ row }) =>
        row.broker != null && <UserInfo user={row.broker} />,
    },
    {
      field: 'suggested_market_value',
      headerName: t('suggestedMarketValue'),
      width: 200,
      align: 'right',
      renderCell: ({ row }) => (
        <div>
          {row.is_price_range
            ? row.price_range_min && row.price_range_max
              ? `${row.price_range_min.toLocaleString(
                  locale,
                )} - ${row.price_range_max.toLocaleString(locale)}`
              : '-'
            : row.suggested_market_value
            ? row.suggested_market_value.toLocaleString(locale)
            : '-'}
        </div>
      ),
    },
    {
      field: 'updated_at',
      headerName: t('lastEdited'),
      width: 150,
      renderCell: ({ row }) => <TimeAgo dateString={row.updated_at} />,
    },
    {
      field: 'created_at',
      headerName: t('createdAt'),
      width: 150,
      renderCell: ({ row }) => <TimeAgo dateString={row.created_at} />,
    },
    {
      field: 'actions',
      width: 200,
      sortable: false,
      type: 'actions',
      align: 'right',
      renderCell: ({ row }) => <Actions node={row} />,
    },
  ];

  const handleCreateCMA = useCallback(
    (newCMAReportId: string) => {
      searchParams.set('language', me?.language);
      setSearchParams(searchParams);
      navigate({
        pathname: `./${newCMAReportId}/page-cover`,
        search: searchParams.toString(),
      });
    },
    [me?.language, navigate, searchParams, setSearchParams],
  );

  return (
    <>
      <TopbarTitle>{t('CMA Reports')}</TopbarTitle>
      <ListToolbar
        tableName="cma_reports"
        quickFilters={[
          {
            label: t('Created'),
            path: ['cma_reports_bool_exp', 'created_at'],
          },
          {
            label: t('Updated'),
            path: ['cma_reports_bool_exp', 'updated_at'],
          },
          {
            label: t('Suggested market value'),
            path: ['cma_reports_bool_exp', 'suggested_market_value'],
          },
        ]}
        newLink={{ pathname: 'new', search: searchParams.toString() }}
      />
      <RaDataGrid
        columns={columns}
        rows={cmaReports}
        loading={loading}
        rowCount={totalCount}
        onRowClick={({ id }) => navigate(cmaReportsLink(id as string))}
      />

      <Routes>
        <Route
          path="new/*"
          element={
            <CreateCMAReport
              onClose={() => {
                navigate({
                  pathname: '.',
                  search: searchParams.toString(),
                });
              }}
              onCreated={handleCreateCMA}
            />
          }
        />
      </Routes>
    </>
  );
};

export default CMAReports;
