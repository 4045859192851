import { memo, useState } from 'react';

import Explore from '@mui/icons-material/Explore';
import MoreVert from '@mui/icons-material/MoreVert';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { endOfDay, format, isToday, parseISO } from 'date-fns';

import { AssignmentInd } from '../../../icons/assignment-ind';
import { AssignmentTurnedIn } from '../../../icons/assignment-turned-in';
import { MeetingRoom } from '../../../icons/meeting-room';
import { Phone } from '../../../icons/phone';
import { RoundWarning } from '../../../icons/round-warning';
import { useLocale } from '../../../src/hooks/locale';
import { abbrDateDifference } from '../../../src/utils/abbrDateDifference';
import {
  getHorizonLabel,
  translateAppraisalReason,
} from '../../../src/utils/lead-labels';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { LeadUpdatedTimeAgo } from '../../components/lead/LeadUpdatedTimeAgo';
import { useAppData } from '../../providers/AppDataProvider';
import { formatAddress } from '../../utils/formatting';
import { userCanViewLeadDetails } from '../../utils/listingPermissions';

import type { Lead, MenuAction } from './LeadsKanbanView';

type ItemMenuProps = {
  onUpdate: (action: MenuAction) => void;
};

const ItemMenu = ({ onUpdate }: ItemMenuProps) => {
  const { t } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={e => {
            onUpdate({
              type: 'stage',
            });
            handleClose(e);
          }}
        >
          {t('Change stage')}
        </MenuItem>
      </Menu>
    </>
  );
};

const LeadCard = memo(
  ({
    lead,
    onUpdate,
  }: {
    lead: Lead;
    onUpdate: (action: MenuAction) => void;
  }) => {
    const { t, locale } = useLocale();
    const { palette, text } = useTheme();
    const { me } = useAppData();

    const formatMillions = (value: number) => {
      const formatted = (value / 1_000_000).toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return t('{{value}}M', { value: formatted });
    };

    const { contact, property } = lead;
    const contactName = [
      contact?.first_name,
      userCanViewLeadDetails(me, lead)
        ? contact?.last_name
        : contact?.last_name
        ? contact.last_name.slice(0, 1) + '.'
        : '',
    ]
      .filter(Boolean)
      .join(' ');

    const contactLabel =
      contact == null
        ? t('No contact')
        : contactName === ''
        ? contact.emails?.[0]?.email
        : contactName;

    const address = property ? formatAddress(property, ', ', false) : '';

    const relationship = {
      owner: t('Owner'),
      tenant: t('Tenant renter'),
      buyer: t('Buyer'),
      heir: t('Heir'),
      agent: t('Agent'),
      other: t('Other'),
      not_set: null,
    }[lead.relationship];

    const appraisal = property?.latest_appraisal;
    const appraisalMinMax = [
      appraisal?.min == null ? [] : [formatMillions(appraisal.min)],
      appraisal?.max == null ? [] : [formatMillions(appraisal.max)],
    ].join(' – ');

    const appraisalPerceived =
      lead.appraisal_perceived_value == null
        ? null
        : formatMillions(lead.appraisal_perceived_value);

    const activity =
      lead.next_activity?.[0] == null
        ? null
        : {
            type: lead.next_activity[0].activity_type,
            overdue: lead.next_activity[0].due_at
              ? new Date(lead.next_activity[0].due_at) < new Date()
              : false,
            date: lead.next_activity[0].start_date
              ? endOfDay(parseISO(lead.next_activity[0].start_date))
              : lead.next_activity[0].due_at
              ? parseISO(lead.next_activity[0].due_at)
              : null,
          };

    const isTodayActivity =
      activity?.date != null ? isToday(activity.date) : false;
    const overdueBy =
      activity?.date != null
        ? abbrDateDifference(t, new Date(), activity.date, true).text
        : '';

    const activityIconMap = {
      task: AssignmentTurnedIn,
      call: Phone,
      visit: MeetingRoom,
      assignment: AssignmentInd,
      note: RoundWarning,
    } as const;

    const ActivityIcon =
      activity == null
        ? RoundWarning
        : activityIconMap[activity.type as keyof typeof activityIconMap] ??
          RoundWarning;

    const horizonLabel = getHorizonLabel(t, {
      appraisalReason: lead.appraisal_reason,
      saleHorizon: lead.sale_horizon,
      buyHorizon: lead.buy_horizon,
    });

    return (
      <Box
        sx={{
          py: 1,
        }}
      >
        {/* Header with name and menu */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={1}
          pl={2}
        >
          <Box sx={{ flex: 1, mr: 1, width: 0 }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {contactLabel}
            </Typography>
            {address && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 'auto',
                  typography: {
                    fontSize: 12,
                  },
                }}
              >
                {address}
              </Typography>
            )}
          </Box>
          <Stack direction="row" alignItems="center">
            <div
              css={{
                color: palette.grey[600],
                marginLeft: 'auto',
              }}
            >
              <LeadUpdatedTimeAgo
                shortVersion={true}
                lead={lead}
                showBothDate={me?.is_admin ?? false}
              />
            </div>

            <ItemMenu onUpdate={onUpdate} />
          </Stack>
        </Stack>

        {/* Tags */}
        {(relationship ||
          lead.appraisal_reason !== 'not_set' ||
          horizonLabel) && (
          <Stack direction="row" mb={1.5} pl={2} flexWrap="wrap" gap={1}>
            {relationship && (
              <Chip
                label={relationship}
                size="small"
                sx={{
                  bgcolor: 'grey.100',
                  height: '24px',
                  '& .MuiChip-label': {
                    px: 1.5,
                    py: 0.5,
                    fontSize: '0.75rem',
                  },
                }}
              />
            )}
            {lead.appraisal_reason !== 'not_set' && (
              <Chip
                label={translateAppraisalReason(t, lead.appraisal_reason)}
                size="small"
                sx={{
                  bgcolor: 'grey.100',
                  height: '24px',
                  '& .MuiChip-label': {
                    px: 1.5,
                    py: 0.5,
                    fontSize: '0.75rem',
                  },
                }}
              />
            )}
            {horizonLabel && (
              <Chip
                label={horizonLabel}
                size="small"
                sx={{
                  bgcolor: 'grey.100',
                  height: '24px',
                  '& .MuiChip-label': {
                    px: 1.5,
                    py: 0.5,
                    fontSize: '0.75rem',
                  },
                }}
              />
            )}
          </Stack>
        )}

        {(lead.mandate_probability || lead.predicted_listing_date) && (
          <Stack direction="row-reverse" gap={1} mb={1} mr={2}>
            {lead.mandate_probability && (
              <Chip
                label={lead.mandate_probability + '%'}
                color={lead.mandate_probability >= 50 ? 'success' : 'warning'}
                size="small"
              />
            )}
            {lead.predicted_listing_date && (
              <Chip
                label={format(
                  new Date(lead.predicted_listing_date ?? ''),
                  'MMM yyyy',
                )}
                color="success"
                size="small"
              />
            )}
          </Stack>
        )}

        {/* Values */}
        {(appraisalMinMax !== '' || appraisalPerceived != null) && (
          <Box
            sx={{
              bgcolor: 'grey.100',
              borderRadius: 1,
              py: 0.5,
              px: 2,
              mb: 1.5,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {appraisalMinMax !== '' && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Explore fontSize="small" />
                  <Typography variant="body1" fontWeight={500}>
                    {appraisalMinMax}
                  </Typography>
                </Stack>
              )}
              {appraisalPerceived != null && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <SmsOutlined fontSize="small" />
                  <Typography variant="body1" fontWeight={500}>
                    {appraisalPerceived}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Box>
        )}

        {/* Footer */}
        <Stack
          direction="row"
          px={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: text.font.medium,
                color:
                  activity == null || activity.overdue
                    ? palette.error.main
                    : isTodayActivity
                    ? palette.warning.main
                    : palette.success.main,
              }}
            >
              <ActivityIcon size={18} css={{ marginRight: '0.3em' }} />
              {activity == null
                ? t('No activity')
                : activity.overdue
                ? t('overdueBy', {
                    time: overdueBy,
                  })
                : t('dueIn', {
                    time: overdueBy,
                  })}
            </div>
          </Stack>
          {lead.broker && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <UserInfo user={lead.broker} anonymize={true} isSmall={true} />
            </Stack>
          )}
        </Stack>
      </Box>
    );
  },
);

export default LeadCard;
