import { useEffect, useMemo, useRef, useState } from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { TravelExplore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type mapboxgl from 'mapbox-gl';
import { Link } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { Edit } from '../../../src/icons/edit';
import { OpenInNew } from '../../../src/icons/open-in-new';
import type {
  GetCatchmentAreaQuery,
  Leads_Bool_Exp,
  SetCatchmentAreaMutation,
  SetCatchmentAreaMutationVariables,
} from '../../__generated__/graphql';
import {
  Leads_Status_Enum,
  Place_Type_Enum_Enum,
} from '../../__generated__/graphql';
import { Mapbox } from '../../components/Mapbox';
import { PlacePicker } from '../../components/place-picker/PlacePicker';
import { useAppData } from '../../providers/AppDataProvider';

const GET_CATCHMENT_AREA = gql`
  query GetCatchmentArea($userId: uuid!) {
    users_by_pk(id: $userId) {
      catchment_areas {
        place {
          id
          postcode
          population
          lat
          lng
        }
      }
    }
  }
`;

const SET_CATCHMENT_AREA = gql`
  mutation SetCatchmentArea(
    $userId: uuid!
    $objects: [catchment_areas_insert_input!]!
  ) {
    delete_catchment_areas(where: { user_id: { _eq: $userId } }) {
      affected_rows
    }
    insert_catchment_areas(objects: $objects) {
      affected_rows
    }
  }
`;

export const UserCatchmentArea = ({ userId }: { userId: string }) => {
  const { me } = useAppData();
  const isAdmin = me?.is_admin;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const mapbox = useRef<mapboxgl.Map | null>(null);
  const [mapReady, setMapReady] = useState(false);
  const { t } = useLocale();
  const { data, refetch } = useQuery<GetCatchmentAreaQuery>(
    GET_CATCHMENT_AREA,
    {
      variables: { userId },
    },
  );

  const [setCatchmentArea] = useMutation<
    SetCatchmentAreaMutation,
    SetCatchmentAreaMutationVariables
  >(SET_CATCHMENT_AREA);

  const boundingBox: [number, number, number, number] | undefined = useMemo(
    () =>
      data?.users_by_pk?.catchment_areas.length ?? 0 > 1
        ? data?.users_by_pk?.catchment_areas.reduce<
            [number, number, number, number]
          >(
            ([minLng, minLat, maxLng, maxLat], { place: { lat, lng } }) => [
              Math.min(minLng, lng),
              Math.min(minLat, lat),
              Math.max(maxLng, lng),
              Math.max(maxLat, lat),
            ],
            [Infinity, Infinity, -Infinity, -Infinity],
          )
        : undefined,
    [data],
  );

  const localitiesIds = useMemo(
    () => data?.users_by_pk?.catchment_areas.map(({ place }) => place.id) ?? [],
    [data],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const whereClause = useMemo(() => {
    const where: Leads_Bool_Exp = {
      completed: { _eq: true },
      stage: { status: { _in: [Leads_Status_Enum.Active] } },
      property: {
        places: {
          id: {
            _in: localitiesIds,
          },
        },
      },
    };

    return JSON.stringify(where);
  }, [localitiesIds]);

  const leadsLink = {
    pathname: '/leads',
    search: `where=${encodeURIComponent(whereClause)}`,
  };

  useEffect(() => {
    if (!mapbox.current) {
      return;
    }

    mapbox.current?.on('load', () => {
      mapbox.current?.addSource('localities', {
        type: 'vector',
        url: `mapbox://spingwun.amdtvcir`,
      });

      mapbox.current?.addLayer({
        id: 'localities',
        type: 'fill',
        source: 'localities',
        'source-layer': 'crm-locality-poly-0j1cq1',
        paint: {
          'fill-color': '#2196F3',
          'fill-opacity': 0.35,
        },
        filter: ['in', 'id', ...[]],
      });

      mapbox.current?.addLayer({
        id: 'localities-outline',
        type: 'line',
        source: 'localities',
        'source-layer': 'crm-locality-poly-0j1cq1',
        paint: {
          'line-color': '#2196F3',
          'line-width': 2,
        },
        filter: ['in', 'id', ...[]],
      });

      setMapReady(true);
    });
  }, []);

  useEffect(() => {
    if (mapbox.current && boundingBox) {
      const map = mapbox.current;

      // Perform the fitBounds operation
      map.fitBounds(boundingBox, {
        padding: 100,
        duration: 250,
        maxZoom: 12,
      });

      // One-time handler for when initial fitBounds completes
      const onInitialMoveEnd = () => {
        if (map.getZoom() < 7.5) {
          map.setZoom(7.5);
        }
        // Remove the listener after first execution
        map.off('moveend', onInitialMoveEnd);
      };

      // Add the one-time event listener
      map.on('moveend', onInitialMoveEnd);
    }
  }, [boundingBox]);

  useEffect(() => {
    if (mapbox.current?.getLayer('localities')) {
      mapbox.current?.setFilter('localities', ['in', 'id', ...localitiesIds]);
    }
    if (mapbox.current?.getLayer('localities-outline')) {
      mapbox.current?.setFilter('localities-outline', [
        'in',
        'id',
        ...localitiesIds,
      ]);
    }
  }, [localitiesIds, mapReady]);

  return (
    <>
      <Box sx={{ p: 2, mb: 2, position: 'relative' }}>
        <Typography variant="h6">{t('Catchment area')}</Typography>
        <Box
          sx={{
            mt: 1,
            minHeight: 300,
            height: '100%',
            position: 'relative',
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Mapbox defaultZoom={10} mapRef={mapbox} />
          <Box
            sx={{
              p: 1,
              position: 'absolute',
              top: 10,
              left: 10,
              background: 'rgba(255, 255, 255, 0.25)',
              borderRadius: 2,
              backdropFilter: 'blur(15px)',
              boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)',
              border: '1px solid rgba(255, 255, 255, 0.18)',
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>{t('Population')}</strong>
                  </td>
                  <td align="right" style={{ paddingLeft: 25 }}>
                    {data?.users_by_pk?.catchment_areas
                      .reduce(
                        (acc, { place }) => acc + (place.population ?? 0),
                        0,
                      )
                      .toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{t('Max distance')}</strong>
                  </td>
                  <td align="right" style={{ paddingLeft: 25 }}>
                    {boundingBox
                      ? `${Math.round(
                          Math.sqrt(
                            Math.pow(
                              (boundingBox[3] - boundingBox[1]) * 111.32,
                              2,
                            ) +
                              Math.pow(
                                (boundingBox[2] - boundingBox[0]) *
                                  111.32 *
                                  Math.cos(Math.PI / 4),
                                2,
                              ),
                          ),
                        ).toLocaleString()} km`
                      : ''}
                  </td>
                </tr>
              </tbody>
            </table>
            {(data?.users_by_pk?.catchment_areas?.length ?? 0) > 0 && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                component={Link}
                to={leadsLink}
                target="_blank"
                startIcon={<OpenInNew />}
                sx={{ mt: 1, width: '100%' }}
              >
                {t('View leads')}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
            }}
          >
            {isAdmin && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setOpen(true)}
                startIcon={<Edit />}
              >
                {t('Edit')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {error && (
        <Box sx={{ m: 2 }}>
          <Alert severity="error">
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </Alert>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        fullScreen={isMobile}
        maxWidth="lg"
        PaperProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TravelExplore />
            {t('Edit catchment area')}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 400, p: 2 }}>
          <PlacePicker
            defaultValue={localitiesIds}
            onChange={v => setValue(v)}
            placeTypes={[Place_Type_Enum_Enum.Locality]}
          />
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Button onClick={() => setOpen(false)} variant="outlined">
            {t('Cancel')}
          </Button>
          <LoadingButton
            loading={loading}
            onClick={async () => {
              setLoading(true);
              setError(null);
              await setCatchmentArea({
                variables: {
                  userId,
                  objects: value.map(id => ({
                    place_id: id,
                    user_id: userId,
                  })),
                },
                refetchQueries: [GET_CATCHMENT_AREA],
              })
                .catch(e => setError(e))
                .then(() => setOpen(false))
                .finally(() => {
                  setLoading(false);
                  refetch();
                });
            }}
            variant="contained"
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
