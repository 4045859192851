import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Form, type FormikErrors, useFormik } from '@realadvisor/form';
import { isBefore, parseISO } from 'date-fns';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';
import { Box, useResponsive, useSystem } from 'react-system';

import { OrganisationLegacyInput } from '../../shared/organisation-input';
import { DateInput, formatDate, parseDate } from '../controls/date-input';
import { PriceInput } from '../controls/price-input';
import { Radio } from '../controls/radio';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { toISODateString } from '../utils/date-utils';

import type { financingWonDialog_lead$key } from './__generated__/financingWonDialog_lead.graphql';
import type { financingWonDialogMutation } from './__generated__/financingWonDialogMutation.graphql';
import type { financingWonDialogQuery } from './__generated__/financingWonDialogQuery.graphql';

type Props = {
  lead: financingWonDialog_lead$key;
  open: boolean;
  onCreate: (leadId: string) => void;
  onClose: () => void;
};

const string_of_number = (number: number | null) =>
  number != null ? String(number) : '';

const number_of_string = (string: string) => {
  const parsed = Number.parseFloat(string);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
};

const fromISOString = (date: string | null): any =>
  date != null ? formatDate(parseISO(date)) : '';

const FinancingWonForm = (props: Omit<Props, 'open'>) => {
  const lead = useFragment(
    graphql`
      fragment financingWonDialog_lead on Lead {
        id
        mortgageRevenue
        mortgagePropertyValue
        mortgageInsuranceRevenue
        mortgagePurchaseDate
        mortgageStartDate
        mortgageLender {
          ...organisationInput_organisation @relay(mask: false)
        }
        mortgageInsuranceProvider {
          ...organisationInput_organisation @relay(mask: false)
        }
      }
    `,
    props.lead,
  );

  const data = useLazyLoadQuery<financingWonDialogQuery>(
    graphql`
      query financingWonDialogQuery {
        wonStages: stages(pipelineName: "financing", status: "won") {
          id
        }
      }
    `,
    {},
  );

  const wonStageId = data.wonStages?.[0]?.id;

  const { media } = useSystem();
  const { t } = useLocale();
  const { text } = useTheme();
  const [sellLifeInsurance, setSellLifeInsurance] = React.useState(
    lead.mortgageInsuranceProvider?.id != null,
  );
  const [upsertLead, upserting] = useMutation<financingWonDialogMutation>(
    graphql`
      mutation financingWonDialogMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            id
            mortgageRevenue
            mortgagePropertyValue
            mortgageInsuranceRevenue
            mortgagePurchaseDate
            mortgageLenderName
            mortgageStartDate
            mortgageLender {
              ...organisationInput_organisation @relay(mask: false)
            }
            mortgageInsuranceProvider {
              ...organisationInput_organisation @relay(mask: false)
            }
          }
        }
      }
    `,
  );

  type FormState = {
    mortgageLenderName: string;
    mortgageRevenue: string;
    mortgagePropertyValue: string;
    mortgageInsuranceProviderId: string | null;
    mortgageInsuranceRevenue: string;
    mortgagePurchaseDate: string;
    mortgageStartDate: string;
  };

  const form = useFormik<FormState>({
    initialValues: {
      mortgageLenderName: lead.mortgageLender?.name ?? '',
      mortgageRevenue: string_of_number(lead.mortgageRevenue),
      mortgagePropertyValue: string_of_number(lead.mortgagePropertyValue),
      mortgageInsuranceProviderId: lead.mortgageInsuranceProvider?.id ?? null,
      mortgageInsuranceRevenue: string_of_number(lead.mortgageInsuranceRevenue),
      mortgagePurchaseDate:
        lead.mortgagePurchaseDate != null
          ? fromISOString(lead.mortgagePurchaseDate)
          : formatDate(new Date()),
      mortgageStartDate:
        lead.mortgageStartDate != null
          ? fromISOString(lead.mortgageStartDate)
          : '',
    },

    validate: values => {
      const errors: FormikErrors<FormState> = {};
      if (values.mortgageRevenue === '') {
        errors.mortgageRevenue = t('revenueRequired');
      }
      if (values.mortgagePropertyValue === '') {
        errors.mortgagePropertyValue = t('mortgageValueRequired');
      }
      if (values.mortgageLenderName === '') {
        errors.mortgageLenderName = t('lenderNameRequired');
      }

      const mortgageStartDate = parseDate(values.mortgageStartDate);
      const mortgagePurchaseDate = parseDate(values.mortgagePurchaseDate);

      if (mortgageStartDate == null) {
        errors.mortgageStartDate = t('mortgageStartDateRequired');
      } else if (mortgagePurchaseDate == null) {
        errors.mortgagePurchaseDate = t('mortgagePurchaseDateRequired');
      } else if (isBefore(mortgageStartDate, mortgagePurchaseDate)) {
        errors.mortgageStartDate = t('mortgageStartDateInvalid');
      }

      // mortgageStartDateInvalid
      if (
        sellLifeInsurance === true &&
        values.mortgageInsuranceProviderId == null
      ) {
        errors.mortgageInsuranceProviderId = t('insuranceProviderRequired');
      }
      if (sellLifeInsurance === true) {
        if (values.mortgageInsuranceRevenue === '') {
          errors.mortgageInsuranceRevenue = t('revenueRequired');
        }
      }
      return errors;
    },

    onSubmit: values => {
      const input = {
        id: lead.id,
        // TODO-status: What lead stage should we use here?
        stageId: wonStageId,
        mortgageLenderName: values.mortgageLenderName,
        mortgageRevenue: number_of_string(values.mortgageRevenue),
        mortgagePropertyValue: number_of_string(values.mortgagePropertyValue),
        mortgageInsuranceProviderId: values.mortgageInsuranceProviderId,
        mortgageInsuranceRevenue: number_of_string(
          values.mortgageInsuranceRevenue,
        ),
        mortgagePurchaseDate: toISODateString(
          parseDate(values.mortgagePurchaseDate),
        ),
        mortgageStartDate: toISODateString(parseDate(values.mortgageStartDate)),
      };
      upsertLead({
        variables: { input: { lead: input } },
        onCompleted: data => {
          if (data.upsertLead?.lead) {
            props.onCreate(data.upsertLead.lead.id);
          }
        },
      });
    },
  });

  return (
    <>
      <DialogContent>
        <Form css={{ display: 'grid', gap: 16 }} onSubmit={form.submitForm}>
          <Box css={text.subtitle2}>{t('financingWonMortgage')}</Box>

          <Box
            css={media({
              display: 'grid',
              gap: 16,
              gridTemplateColumns: ['1fr', '1fr 1fr'],
            })}
          >
            <FormControl
              required={true}
              error={form.errors.mortgagePropertyValue != null}
            >
              <InputLabel shrink={true}>{t('mortgageValue')}</InputLabel>
              <PriceInput
                value={form.values.mortgagePropertyValue}
                onChange={mortgagePropertyValue =>
                  form.setValues({ mortgagePropertyValue })
                }
                onBlur={() => form.setTouched({ mortgagePropertyValue: true })}
              />
              {form.errors.mortgagePropertyValue != null && (
                <FormHelperText>
                  {form.errors.mortgagePropertyValue}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              required={true}
              error={form.errors.mortgageRevenue != null}
            >
              <InputLabel shrink={true}>{t('grossCommission')}</InputLabel>
              <PriceInput
                value={form.values.mortgageRevenue}
                onChange={mortgageRevenue =>
                  form.setValues({ mortgageRevenue })
                }
                onBlur={() => form.setTouched({ mortgageRevenue: true })}
              />
              {form.errors.mortgageRevenue != null && (
                <FormHelperText>{form.errors.mortgageRevenue}</FormHelperText>
              )}
            </FormControl>

            <TextField
              required={true}
              label={t('lender')}
              value={form.values.mortgageLenderName}
              error={form.errors.mortgageLenderName != null}
              helperText={form.errors.mortgageLenderName}
              onBlur={() => form.setTouched({ mortgageLenderName: true })}
              onChange={event =>
                form.setValues({ mortgageLenderName: event.target.value })
              }
            />

            <FormControl
              required={true}
              error={form.errors.mortgagePurchaseDate != null}
            >
              <DateInput
                label={t('signedOn')}
                value={form.values.mortgagePurchaseDate}
                onChange={mortgagePurchaseDate =>
                  form.setValues({ mortgagePurchaseDate })
                }
              />
              {form.errors.mortgagePurchaseDate != null && (
                <FormHelperText>
                  {form.errors.mortgagePurchaseDate}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              required={true}
              error={form.errors.mortgageStartDate != null}
            >
              <DateInput
                label={t('mortgageStartDate')}
                value={form.values.mortgageStartDate}
                onChange={mortgageStartDate =>
                  form.setValues({ mortgageStartDate })
                }
              />
              {form.errors.mortgageStartDate != null && (
                <FormHelperText>{form.errors.mortgageStartDate}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box css={text.subtitle2}>{t('financingWonInsurance')}</Box>
          <Box width={1} p={2}>
            <RadioGroup row={true}>
              <FormControlLabel
                label={t('yes')}
                control={
                  <Radio
                    checked={sellLifeInsurance === true}
                    onChange={() => setSellLifeInsurance(true)}
                  />
                }
              />
              <FormControlLabel
                label={t('no')}
                control={
                  <Radio
                    checked={sellLifeInsurance === false}
                    onChange={() => setSellLifeInsurance(false)}
                  />
                }
              />
            </RadioGroup>
          </Box>
          {sellLifeInsurance === true && (
            <Box
              css={media({
                display: 'grid',
                gap: 16,
                gridTemplateColumns: ['1fr', '1fr 1fr'],
              })}
            >
              <FormControl
                required={true}
                error={form.errors.mortgageInsuranceProviderId != null}
              >
                <InputLabel>{t('insuranceProvider')}</InputLabel>
                <OrganisationLegacyInput
                  value={lead.mortgageInsuranceProvider}
                  onChange={insurance =>
                    form.setValues({
                      mortgageInsuranceProviderId: insurance?.id ?? null,
                    })
                  }
                  onBlur={() =>
                    form.setTouched({ mortgageInsuranceProviderId: true })
                  }
                />
                {form.errors.mortgageInsuranceProviderId != null && (
                  <FormHelperText>
                    {form.errors.mortgageInsuranceProviderId}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                required={true}
                error={form.errors.mortgageInsuranceRevenue != null}
              >
                <InputLabel shrink={true}>{t('grossCommission')}</InputLabel>
                <PriceInput
                  value={form.values.mortgageInsuranceRevenue}
                  onChange={mortgageInsuranceRevenue =>
                    form.setValues({ mortgageInsuranceRevenue })
                  }
                  onBlur={() =>
                    form.setTouched({ mortgageInsuranceRevenue: true })
                  }
                />
                {form.errors.mortgageInsuranceRevenue != null && (
                  <FormHelperText>
                    {form.errors.mortgageInsuranceRevenue}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          )}
        </Form>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose}>{t('Cancel')}</Button>
        <Button
          disabled={form.valid === false || upserting}
          onClick={form.submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export const FinancingWonDialog = (props: Props) => {
  const { t } = useLocale();
  const responsive = useResponsive();
  const fullScreen = responsive([true, false]);

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('dealWon')}</DialogTitle>
      <FinancingWonForm
        lead={props.lead}
        onCreate={props.onCreate}
        onClose={props.onClose}
      />
    </Dialog>
  );
};
