import { forwardRef, useCallback, useMemo, useState } from 'react';

import { type ApolloError, useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Stack,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { format } from 'date-fns';
import { type DeepPartial } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { customPalette } from '../../../src/styles';
import { gql } from '../../__generated__';
import { InsertListingLotsStatusEnumEnum } from '../../__generated__/graphql';
import { CREATE_LISTING } from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import {
  type FormDefinitionType,
  type FormFieldDefinitionType,
  RaForm,
} from '../form/RaForm';
import { EEditListingForms } from '../listing-overview-form/ListingOverviewEditModal';
import {
  type TMandateFormData,
  useListingOverviewEditFormDefinition,
} from '../listing-overview-form/listingOverviewFormDefinition';
import { MutationErrorSnackbar } from '../MutationErrorModal';

type CreateListingModalProps = {
  fromLeadId: string;
  open: boolean;
  onClose: () => void;
  onListingCreated?: (listingId: string) => void;
  developmentId?: string;
  onCancel?: () => void;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type CreateListingFormData = {
  seller_id: string | null;
  broker_id: string | null;
} & TMandateFormData;

const GET_CREATE_LISTING_LEAD_DATA_QUERY = gql(/* GraphQL */ `
  query GetCreateListingLeadData($id: uuid!) {
    leads_by_pk(id: $id) {
      id
      broker_id
      property {
        id
        created_at
      }
      contact_id
    }
  }
`);

const GET_ORIGINS_QUERY = gql(/* GraphQL */ `
  query GetLotOriginsCreateListing {
    origins: dictionaries(where: { type: { _eq: lot_origin_types } }) {
      id
      name
    }
  }
`);

export const CreateListingModal: React.FC<CreateListingModalProps> = ({
  fromLeadId,
  open,
  onClose,
  onListingCreated,
  developmentId,
  onCancel,
}) => {
  const { t } = useLocale();
  const [error, setError] = useState<ApolloError | null>(null);
  const { me } = useAppData();

  const { data } = useQuery(GET_CREATE_LISTING_LEAD_DATA_QUERY, {
    variables: { id: fromLeadId },
    skip: !open,
  });

  // refoactor to use default when implemented in dictionnaries
  const origins = useQuery(GET_ORIGINS_QUERY, {
    skip: !open,
  });

  const [createListing] = useMutation(CREATE_LISTING);

  const defaultOrigin = useMemo(
    () =>
      origins.data?.origins.find(
        origin => origin.name === 'realadvisor_appraisal',
      ),
    [origins.data],
  );

  const handleModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const mandateFormDefinition = useListingOverviewEditFormDefinition({
    formType: EEditListingForms.MANDATE,
    listing: {
      origin_id: defaultOrigin?.id,
      property:
        data?.leads_by_pk?.property != null
          ? {
              ...data.leads_by_pk.property,
              property_images: [],
            }
          : {
              id: '',
              created_at: '',
              property_images: [],
            },
    },
  });

  const formDefinition = useMemo<FormDefinitionType<CreateListingFormData>>(
    () =>
      ({ context }) =>
        [
          {
            type: 'category-title',
            label: t('Stakeholders'),
            name: 'stakeholders-title',
          },
          {
            name: 'seller_id',
            label: t('seller'),
            type: 'user',
          },
          {
            name: 'broker_id',
            label: t('broker'),
            type: 'user',
          },
          {
            type: 'category-title',
            label: t('Mandate'),
            name: 'mandate-title',
          },
          ...((mandateFormDefinition?.formDefinition({ t, context }) ??
            []) as FormFieldDefinitionType<CreateListingFormData>[]),
        ],
    [mandateFormDefinition, t],
  );

  const defaultValues = useMemo<DeepPartial<CreateListingFormData>>(() => {
    return {
      ...(mandateFormDefinition?.defaultValues ?? {}),
      seller_id: data?.leads_by_pk?.contact_id ?? null,
      broker_id: data?.leads_by_pk?.broker_id ?? null,
      signed_at: format(new Date(), 'yyyy-MM-dd'),
    };
  }, [data, mandateFormDefinition]);

  const onSubmit = useCallback(
    async (formData: CreateListingFormData) => {
      const mandateData =
        mandateFormDefinition?.transformData(formData)?.lot ??
        ({} as TMandateFormData);
      const result = await createListing({
        variables: {
          lot: {
            ...mandateData,
            status: InsertListingLotsStatusEnumEnum.Draft,
            broker_id: formData.broker_id,
            sellers:
              formData.seller_id == null
                ? undefined
                : { data: [{ user_id: formData.seller_id }] },
            origin_id: defaultOrigin?.id,
            development_id: developmentId,
            property_id: data?.leads_by_pk?.property?.id,
            lead_id: fromLeadId,
            dummy_living_surface:
              me?.tenant.country_code === 'ES'
                ? 'built_surface'
                : 'living_surface',
          },
        },
        onError: error => setError(error),
      });

      if (result.data?.insert_lots_derived?.id != null) {
        onListingCreated?.(result.data.insert_lots_derived.id);
        handleModalClose();
      }

      return result;
    },
    [
      createListing,
      fromLeadId,
      data?.leads_by_pk?.property?.id,
      defaultOrigin?.id,
      developmentId,
      mandateFormDefinition,
      me?.tenant.country_code,
      onListingCreated,
      handleModalClose,
    ],
  );

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => handleModalClose()}
        TransitionComponent={Transition}
        sx={theme => ({
          '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
          '& .MuiDialog-paper': {
            backgroundColor: customPalette.superLightBlue,
            [theme.breakpoints.down('sm')]: {
              margin: 0,
              width: '100%',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
          '& .MuiDialog-container': {
            [theme.breakpoints.down('sm')]: {
              alignItems: 'end',
            },
          },
        })}
      >
        <Stack
          direction="row"
          component={Paper}
          elevation={0}
          borderRadius={0}
          justifyContent="space-between"
          alignItems="center"
          sx={theme => ({
            position: 'sticky',
            top: 0,
            paddingX: 2,
            paddingY: 1,
            zIndex: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <DialogTitle sx={{ p: 0 }}>{t('Create listing')}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleModalClose()}
            sx={{
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <RaForm
          allowSubmitNotDirty
          formDefinition={formDefinition}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          validate={mandateFormDefinition?.validate}
          onCancel={() => {
            handleModalClose();
            onCancel?.();
          }}
          actionButtonsSlotProps={{
            container: {
              elevation: 0,
              sx: theme => ({
                borderTop: `1px solid ${theme.palette.divider}`,
              }),
            },
          }}
          gridProps={{
            alignItems: 'end',
          }}
        />
        <MutationErrorSnackbar error={error} onClose={() => setError(null)} />
      </Dialog>
    </>
  );
};
