import { useState } from 'react';

import { Alert, type AlertColor, Portal, Snackbar } from '@mui/material';

type SnackbarProps = {
  message: string;
  onClose: () => void;
  severity?: AlertColor;
  autoHideDuration?: number;
};

const RASnackbar = ({
  message,
  onClose,
  severity = 'error',
  autoHideDuration = 5000,
}: SnackbarProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  return (
    <Portal>
      <Snackbar
        open={isOpen}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        TransitionProps={{
          onExited: onClose,
        }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default RASnackbar;
