import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import KeyIcon from '@mui/icons-material/Key';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/PersonOutline';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { logoutUser } from '@realadvisor/auth-client';
import { useNavigate } from 'react-router-dom';

import { gql } from '../../apollo/__generated__';
import { LoadingSpinner } from '../../apollo/components/LoadingSpinner';
import { useAppData } from '../../apollo/providers/AppDataProvider';
import { useLocale } from '../hooks/locale';

const NO_ACCESS_QUERY = gql(/* GraphQL */ `
  query NoAccess($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      display_name
      phone_numbers {
        number
      }
      emails {
        email
      }
    }
  }
`);

export const NoAccess = (props: { id?: string }) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { data } = useQuery(NO_ACCESS_QUERY, {
    variables: { id: props.id ?? '' },
    skip: props.id == null || me?.is_admin || me?.is_broker,
  });

  useEffect(() => {
    if (me?.is_admin || me?.is_broker) {
      navigate(`/dashboard`);
    }
    setIsLoading(false);
  }, [me, navigate]);

  const user = data?.users[0];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      py={4}
      px={2}
    >
      <Container maxWidth="sm">
        <Card>
          <Box
            py={4}
            px={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            borderBottom={1}
            borderColor="divider"
          >
            <Typography
              variant="h5"
              align="center"
              fontWeight={500}
              color="text.primary"
              sx={{ lineHeight: 1.4 }}
            >
              {t("You don't have access to")}
              <br />
              <Box component="span" color="primary.main" fontWeight={600}>
                {t('RealAdvisor CRM')}
              </Box>
            </Typography>
          </Box>

          <CardContent>
            <Alert severity="warning" variant="outlined" sx={{ mb: 3 }}>
              {t(
                "The account you're currently using doesn't have permission to access this application.",
              )}
            </Alert>

            <Paper variant="outlined">
              <CardHeader
                title={t('Account Information')}
                titleTypographyProps={{
                  variant: 'h6',
                  color: 'primary',
                  gutterBottom: true,
                }}
                sx={{ pb: 0 }}
              />

              <CardContent sx={{ py: 1 }}>
                <List dense>
                  {user?.display_name && (
                    <ListItem>
                      <ListItemIcon>
                        <PersonIcon color="action" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            <Box component="span" fontWeight="medium">
                              {t('Name')}:
                            </Box>{' '}
                            {user.display_name}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {props.id && (
                    <ListItem>
                      <ListItemIcon>
                        <KeyIcon color="action" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            sx={{ wordBreak: 'break-all' }}
                          >
                            <Box component="span" fontWeight="medium">
                              {t('User ID')}:
                            </Box>{' '}
                            {props.id}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {user?.emails && user.emails.length > 0 && (
                    <ListItem>
                      <ListItemIcon>
                        <MailOutlineIcon color="action" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            <Box component="span" fontWeight="medium">
                              {t('Email')}:
                            </Box>{' '}
                            {user.emails.map(item => item.email).join(', ')}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {user?.phone_numbers && user.phone_numbers.length > 0 && (
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon color="action" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            <Box component="span" fontWeight="medium">
                              {t('Phone number')}:
                            </Box>{' '}
                            {user.phone_numbers
                              .map(phone => phone.number)
                              .join(', ')}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Paper>

            <Stack spacing={1.5} mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={logoutUser}
              >
                {t('Log in with another account')}
              </Button>

              <Typography variant="body2" color="text.secondary" align="center">
                {t(
                  'If you believe you should have access, please contact our support team or your account manager',
                )}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};
