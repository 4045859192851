// @flow

import * as React from 'react';

import { CircularProgress, Dialog, IconButton, Slide } from '@mui/material';

import { docs_origin } from '../config';
import { type Language, useLocale } from '../hooks/locale';
import { Close } from '../icons/close';

const SlideUp = React.forwardRef((props, ref) => (
  <Slide ref={ref} direction="up" {...props} />
));

type Props = {|
  templateId: string,
  documentId: string,
  open: boolean,
  onClose: () => void,
  language?: Language,
|};

const NewDialogContent = ({
  templateId,
  documentId,
  onClose,
  language: overrideLanguage,
}) => {
  const [loaded, setLoaded] = React.useState(false);
  const { language } = useLocale();

  const documentUrl = new URL('/document', docs_origin);
  documentUrl.searchParams.set('templateId', templateId);
  documentUrl.searchParams.set('documentId', documentId);
  documentUrl.searchParams.set('in-crm-dialog', 'true');
  documentUrl.searchParams.set('language', overrideLanguage ?? language);
  documentUrl.searchParams.set(
    'timezone',
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'UTC',
  );

  return (
    <>
      {loaded === false && (
        <>
          <div
            css={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              backgroundColor: '#e0e0e0',
            }}
          ></div>
          <div
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: 64,
              backgroundColor: '#1a65af',
            }}
          ></div>
          <CircularProgress
            disableShrink={true}
            css={{
              position: 'absolute',
              left: 'calc(50% - 20px)',
              top: 'calc(50% - 20px)',
            }}
          />
        </>
      )}
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          width: 64,
          height: 64,
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
        }}
      >
        <IconButton color="inherit" onClick={onClose} title="Close dialog">
          <Close />
        </IconButton>
      </div>
      <iframe
        src={documentUrl.href}
        style={{
          border: 0,
          height: '100%',
          visibility: loaded ? 'visible' : 'hidden',
        }}
        allow="clipboard-write"
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </>
  );
};

export const TemplateViewerDialog = ({
  templateId,
  documentId,
  open,
  onClose,
  language,
}: Props): React.Node => {
  return (
    <Dialog
      open={open}
      fullScreen={true}
      onClose={onClose}
      TransitionComponent={SlideUp}
    >
      <NewDialogContent
        templateId={templateId}
        documentId={documentId}
        onClose={onClose}
        language={language}
      />
    </Dialog>
  );
};
