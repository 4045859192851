import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { dropTargetForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import {
  type Edge,
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { Box, Card } from '@mui/material';
import { createPortal } from 'react-dom';

import { useKanbanState } from './KanbanBoard';

type KanbanItemProps<ItemData = any> = {
  data: ItemData;
  itemId: string;
  renderItem: (data: ItemData) => React.ReactNode;
  columnId?: string;
  onClick?: () => void;
  onHeightCalculated: (height: number) => void;
};

const ItemContent = forwardRef<
  HTMLDivElement,
  {
    isDragging?: boolean;
    renderItem: (data: any) => React.ReactNode;
    data: any;
    closestEdge: Edge | null;
    onItemClicked?: () => void;
    isPreview?: boolean;
  }
>(
  (
    {
      isDragging = false,
      renderItem,
      data,
      closestEdge,
      onItemClicked,
      isPreview = false,
    },
    ref,
  ) => {
    const renderedItem = useMemo(() => renderItem(data), [data, renderItem]);

    return (
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          width: '100%',
          transform: isPreview ? 'rotate(5deg)' : 'none',
        }}
      >
        <Card
          ref={ref}
          variant="outlined"
          elevation={0}
          onClick={onItemClicked}
          sx={{
            my: 0.5,
            transition: 'all 0.3s ease',
            cursor: 'grab',
            opacity: isDragging ? 0.4 : 1,
            borderColor: 'grey.300',
            borderRadius: '6px',
            width: '100%',
          }}
        >
          {renderedItem}
        </Card>
        {closestEdge && <DropIndicator edge={closestEdge} gap="6px" />}
      </Box>
    );
  },
);

type State =
  | { type: 'idle' }
  | { type: 'preview'; container: HTMLElement; rect: DOMRect }
  | { type: 'dragging' };

const idleState: State = { type: 'idle' };
const draggingState: State = { type: 'dragging' };

export const KanbanItem = memo(
  ({
    renderItem,
    data,
    itemId,
    columnId,
    onClick,
    onHeightCalculated,
  }: KanbanItemProps) => {
    const { disabled, instanceId } = useKanbanState();
    const ref = useRef<HTMLDivElement | null>(null);
    const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
    const [state, setState] = useState<State>(idleState);

    useEffect(() => {
      const element = ref.current;

      if (element == null) {
        return;
      }

      return combine(
        draggable({
          element,
          getInitialData: () => ({
            content: data,
            type: 'card',
            itemId,
            instanceId,
            columnId,
          }),
          onGenerateDragPreview: ({ location, source, nativeSetDragImage }) => {
            const rect = source.element.getBoundingClientRect();

            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: () => {
                // I don't know why but the helper preserveOffsetOnSource() is not working, so we are using this workaround.
                return {
                  x: location.current.input.clientX - rect.x,
                  y: location.current.input.clientY - rect.y,
                };
              },
              render({ container }) {
                setState({ type: 'preview', container, rect });

                return () => setState(draggingState);
              },
            });
          },
          onDragStart: () => setState(draggingState),
          onDrop: () => setState(idleState),
        }),
        dropTargetForExternal({
          element,
        }),
        dropTargetForElements({
          element,
          canDrop: ({ source }) => {
            return (
              source.data.instanceId === instanceId &&
              source.data.type === 'card' &&
              !disabled
            );
          },
          getIsSticky: () => true,
          getData: ({ input, element }) =>
            attachClosestEdge(
              { content: data, type: 'card', itemId, columnId },
              {
                input,
                element,
                allowedEdges: ['top', 'bottom'],
              },
            ),
          onDragEnter: args => {
            if (args.source.data.itemId !== itemId) {
              setClosestEdge(extractClosestEdge(args.self.data));
            }
          },
          onDrag: args => {
            if (args.source.data.itemId !== itemId) {
              setClosestEdge(extractClosestEdge(args.self.data));
            }
          },
          onDragLeave: () => {
            setClosestEdge(null);
          },
          onDrop: () => {
            setClosestEdge(null);
          },
        }),
      );
    }, [instanceId, itemId, columnId, data, disabled]);

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const height = ref.current.getBoundingClientRect().height;
      // We include 4px margin on bottom
      onHeightCalculated(height + 4);
    }, [onHeightCalculated]);

    return (
      <>
        <ItemContent
          isDragging={state.type === 'dragging'}
          renderItem={renderItem}
          data={data}
          closestEdge={closestEdge}
          ref={ref}
          onItemClicked={onClick}
        />
        {state.type === 'preview' &&
          createPortal(
            <Box
              sx={{
                /**
                 * Ensuring the preview has the same dimensions as the original.
                 *
                 * Using `border-box` sizing here is not necessary in this
                 * specific example, but it is safer to include generally.
                 */
                boxSizing: 'border-box',
                width: `${state.rect.width}px`,
                height: `${state.rect.height}px`,
              }}
            >
              <ItemContent
                renderItem={renderItem}
                data={data}
                closestEdge={null}
                isPreview
              />
            </Box>,
            state.container,
          )}
      </>
    );
  },
);
