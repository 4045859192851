import { useState } from 'react';

import { Clear } from '@mui/icons-material';
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  DateField,
  type DateFieldProps,
  DateTimeField,
} from '@mui/x-date-pickers-pro';

import { addWhereClause, deleteWhereClause } from '../Filters';
import { useSchema } from '../useSchema';

interface ScalarProps {
  name: string;
  node: any;
  path: string[];
  where: any;
  setWhere: (where: any) => void;
}

const parseTimeFilterValue = (intervalOrDateString: string) => {
  const interval: {
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
  } = {
    years: undefined,
    months: undefined,
    days: undefined,
    hours: undefined,
    minutes: undefined,
  };

  if (!intervalOrDateString) {
    return { dateType: 'exact_date', date: new Date(), interval };
  }

  if (
    intervalOrDateString.includes('now() -') ||
    intervalOrDateString.includes('now() +')
  ) {
    const dateType = intervalOrDateString.includes('now() -')
      ? 'days_ago'
      : 'days_from_now';
    intervalOrDateString = intervalOrDateString
      .replace('now() -', '')
      .replace('now() +', '');

    const regex = /(\d{1,4})\s{1,5}(years?|months?|days?|hours?|minutes?)/g;
    let match;
    while ((match = regex.exec(intervalOrDateString)) != null) {
      const value = parseInt(match[1]);
      const unit = match[2];
      if (unit.includes('year')) {
        interval.years = value;
      } else if (unit.includes('month')) {
        interval.months = value;
      } else if (unit.includes('day')) {
        interval.days = value;
      } else if (unit.includes('hour')) {
        interval.hours = value;
      } else if (unit.includes('minute')) {
        interval.minutes = value;
      }
    }

    return { dateType, date: null, interval };
  } else {
    return {
      dateType: 'exact_date',
      date: new Date(intervalOrDateString),
      interval,
    };
  }
};

const generateIntervalString = (
  interval: {
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
  },
  dateType: string,
) => {
  const intervalString = Object.entries(interval)
    .filter(([_, val]) => val)
    .map(([key, val]) => `${val} ${val === 1 ? key.slice(0, -1) : key}`)
    .join(' ');

  return dateType === 'days_ago'
    ? `now() - ${intervalString}`
    : `now() + ${intervalString}`;
};

const TimestamptzField = ({
  defaultValue,
  where,
  path,
  onChange,
  dateOnly = false,
}: {
  defaultValue: any;
  where: any;
  path: string[];
  onChange: (value: any) => void;
  dateOnly?: boolean;
}) => {
  const getValueFromPath = (obj: any, path: string[]) => {
    return path.reduce(
      (acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : null),
      obj,
    );
  };

  const valueAtPath = getValueFromPath(where, path);
  const intervalOrDateString = valueAtPath || defaultValue;
  const {
    dateType: initialDateType,
    date,
    interval: initialInterval,
  } = parseTimeFilterValue(intervalOrDateString);

  const [interval, setInterval] = useState(initialInterval);
  const [dateType, setDateType] = useState(initialDateType);

  const handleIntervalChange = (field: keyof typeof interval, value: any) => {
    const newInterval = { ...interval, [field]: parseInt(value) };
    setInterval(newInterval);

    const intervalString = generateIntervalString(newInterval, dateType);

    onChange(intervalString);
  };

  const handleDateTypeChange = (value: string) => {
    setDateType(value);
    let newValue;
    if (value === 'exact_date') {
      newValue = new Date().toISOString();
    } else if (value === 'days_from_now') {
      newValue = generateIntervalString(interval, 'days_from_now');
    } else if (value === 'days_ago') {
      newValue = generateIntervalString(interval, 'days_ago');
    }
    onChange(newValue);
  };

  const dateProps: Pick<
    DateFieldProps<Date>,
    'value' | 'variant' | 'size' | 'sx' | 'clearable'
  > = {
    value: date,
    variant: 'outlined',
    size: 'small',
    clearable: true,
    sx: {
      background: 'white',
      width: 200,
      '& .MuiInputBase-input': {
        padding: '3px 10px !important',
      },
    },
  };

  return (
    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
      <Select
        value={dateType}
        onChange={e => handleDateTypeChange(e.target.value)}
        variant="outlined"
        size="small"
        sx={{
          background: 'white',
          width: 150,
          '& .MuiInputBase-input': {
            padding: '3px 10px !important',
          },
        }}
      >
        <MenuItem value="exact_date">Exact date</MenuItem>
        <MenuItem value="days_from_now">Days from now</MenuItem>
        <MenuItem value="days_ago">Days ago</MenuItem>
      </Select>
      {dateType === 'exact_date' &&
        (dateOnly ? (
          <DateField
            onChange={(date, { validationError }) => {
              if (!validationError) {
                if (date) {
                  onChange(date);
                }
              }
            }}
            {...dateProps}
          />
        ) : (
          <DateTimeField
            onChange={(date, { validationError }) => {
              if (!validationError) {
                if (date) {
                  onChange(date);
                }
              }
            }}
            {...dateProps}
          />
        ))}
      {['days_ago', 'days_from_now'].includes(dateType) && (
        <>
          {(['years', 'months', 'days', 'hours', 'minutes'] as const).map(
            (unit, index) => {
              const placeholders: Record<typeof unit, string> = {
                years: 'Y',
                months: 'Mo',
                days: 'D',
                hours: 'H',
                minutes: 'Mi',
              };
              const styles = {
                background: 'white',
                width: 44,
                '& .MuiInputBase-input': {
                  padding: '3px 10px !important',
                },
              };

              return (
                <TextField
                  key={index}
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder={placeholders[unit]}
                  value={interval[unit] || ''}
                  sx={styles}
                  onChange={e => handleIntervalChange(unit, e.target.value)}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                />
              );
            },
          )}
        </>
      )}
    </div>
  );
};

const getDateDefaultValue = (node: any, removeTime = false) => {
  try {
    if (
      typeof node === 'string' &&
      (node.includes('now() -') || node.includes('now() +'))
    ) {
      return node;
    } else {
      // Create a new Date object from the node value
      let date = new Date(node);

      if (removeTime) {
        date = new Date(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          0,
          0,
          0,
          0,
        );
      }

      return date.toISOString();
    }
  } catch (e) {
    console.error('Error parsing date', e);
    return new Date().toISOString();
  }
};

export const Scalar = ({ name, node, path, where, setWhere }: ScalarProps) => {
  const { getTypeFromPath, getValueFromPath } = useSchema();
  const type = getTypeFromPath(path);

  if (!type) {
    console.info('No type found for', path);
    return <div>No type found for {path.join('.')}</div>;
  }

  let inputType;
  let defaultValue;

  switch (type?.name) {
    case 'String':
      inputType = 'text';
      defaultValue = node.toString();
      break;
    case 'Int':
    case 'Float':
    case 'float8':
      inputType = 'number';
      defaultValue = parseFloat(node);
      break;
    case 'date':
      inputType = 'date';
      defaultValue = getDateDefaultValue(node, true);
      break;
    case 'timestamptz': {
      inputType = 'datetime-local';
      defaultValue = getDateDefaultValue(node);
      break;
    }
    case 'jsonb':
      inputType = 'text';
      defaultValue = JSON.stringify(node);
      break;
    case 'Boolean':
      inputType = 'checkbox';
      defaultValue = node;
      break;
    default:
      defaultValue = undefined;
      inputType = 'text';
  }

  if (['date', 'datetime-local'].includes(inputType)) {
    return (
      <TimestamptzField
        defaultValue={defaultValue}
        where={where}
        path={path}
        dateOnly={inputType === 'date'}
        onChange={(value: any) => {
          const parentPath = path.slice(0, -1);
          const parentValue = getValueFromPath(parentPath, where);
          const currentKey = path[path.length - 1];

          parentValue[currentKey] = value;
          let newWhere = deleteWhereClause(where, parentPath);

          if (value) {
            newWhere = addWhereClause(newWhere, parentPath, parentValue);
            setWhere(newWhere);
          }
        }}
      />
    );
  }

  if (Array.isArray(node)) {
    return (
      <Autocomplete
        value={node}
        multiple
        freeSolo
        size="small"
        options={[]}
        onChange={(e, value) => {
          let newWhere = deleteWhereClause(where, path);
          if (value) {
            newWhere = addWhereClause(newWhere, path, value);
            setWhere(newWhere);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={`+ Add filter (${name})`}
            sx={{
              background: 'white',
              width: 350,
              '& .MuiInputBase-root': {
                padding: '0 !important',
              },
            }}
          />
        )}
      />
    );
  }

  if (inputType === 'date' && Array.isArray(node)) {
    return (
      <Autocomplete
        value={node}
        multiple
        freeSolo
        size="small"
        options={[]}
        onChange={(e, value) => {
          let newWhere = deleteWhereClause(where, path);
          if (value) {
            newWhere = addWhereClause(newWhere, path, value);
            setWhere(newWhere);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={`+ Add filter (${name})`}
            sx={{
              background: 'white',
              width: 350,
              '& .MuiInputBase-root': {
                padding: '0 !important',
              },
            }}
          />
        )}
      />
    );
  }

  if (inputType === 'text' && Array.isArray(node)) {
    return (
      <Autocomplete
        value={node}
        multiple
        freeSolo
        size="small"
        options={[]}
        onChange={(e, value) => {
          let newWhere = deleteWhereClause(where, path);
          if (value) {
            newWhere = addWhereClause(newWhere, path, value);
            setWhere(newWhere);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={node?.length === 0 ? `+ Add filter (${name})` : ''}
            sx={{
              background: 'white',
              minWidth: 350,
              '& .MuiInputBase-root': {
                padding: '0 !important',
              },
            }}
          />
        )}
      />
    );
  }

  if (inputType === 'text') {
    return (
      <TextField
        variant="outlined"
        size="small"
        defaultValue={defaultValue}
        onChange={e => {
          const newWhere = addWhereClause(where, path, e.target.value);
          setWhere(newWhere);
        }}
        sx={{
          background: 'white',
          width: 200,
          '& .MuiInputBase-input': {
            padding: '3px 10px !important',
          },
        }}
      />
    );
  }

  if (inputType === 'number') {
    return (
      <TextField
        type="number"
        variant="outlined"
        size="small"
        defaultValue={defaultValue}
        onChange={e => {
          const newWhere = addWhereClause(
            where,
            path,
            parseFloat(e.target.value),
          );
          setWhere(newWhere);
        }}
        sx={{
          background: 'white',
          width: 200,
          '& .MuiInputBase-input': {
            padding: '3px 10px !important',
          },
        }}
      />
    );
  }

  if (inputType === 'checkbox') {
    return (
      <input
        type="checkbox"
        defaultChecked={defaultValue}
        onChange={() => {
          const newWhere = addWhereClause(where, path, !node);
          setWhere(newWhere);
        }}
      />
    );
  }

  return (
    <div>
      <label>
        <i>{name}</i>
      </label>
      <TextField
        type={inputType}
        onChange={e => {
          const newWhere = addWhereClause(where, path, e.target.value);
          setWhere(newWhere);
        }}
        defaultValue={defaultValue}
      />
      <IconButton
        sx={{
          padding: '3px',
        }}
        size="small"
        color="error"
        onClick={() => {
          const newWhere = deleteWhereClause(where, path);
          setWhere(newWhere);
        }}
      >
        <Clear sx={{ fontSize: 20 }} />
      </IconButton>
    </div>
  );
};
