import { useEffect, useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { RaSelect } from '../form/RaSelect';

import { type TAvailabilityFormData } from './listingOverviewFormDefinition';

export type ListingAvailability =
  | 'available_now'
  | 'available_at'
  | 'by_request';

const yesterday = new Date(Date.now() - 86400000); // 24 hours ago in milliseconds
const yesterdayStr = yesterday.toISOString().split('T')[0];

export const ListingOverviewAvailabilitySelect: React.FC = () => {
  const { t } = useLocale();
  const { control, setValue, getValues } =
    useFormContext<TAvailabilityFormData>();
  const availability = useWatch<TAvailabilityFormData>({
    control,
    name: 'availability',
  });

  useEffect(() => {
    if (availability === 'available_now') {
      setValue('available_from', yesterdayStr);
    } else if (
      availability === 'by_request' ||
      (availability === 'available_at' &&
        getValues('available_from') === yesterdayStr)
    ) {
      // Needs to be reset to null when the value changes back to "available_at" from "available_now" or if the value is "by_request".
      setValue('available_from', null);
    }
  }, [availability, setValue, getValues]);

  return useMemo(
    () => (
      <RaSelect
        name="availability"
        label={t('Availability')}
        control={control}
        options={[
          { value: 'by_request', label: t('Available upon request') },
          { value: 'available_now', label: t('Available immediately') },
          { value: 'available_at', label: t('Available at a future date') },
        ]}
      />
    ),
    [control, t],
  );
};
