import { useState } from 'react';

import { Tooltip, type TooltipProps } from '@mui/material';

type OptionalTooltipProps = Omit<
  TooltipProps,
  'open' | 'onClose' | 'onOpen'
> & {
  disabled?: boolean;
};

export const OptionalTooltip: React.FC<OptionalTooltipProps> = ({
  disabled = false,
  ...tooltipProps
}) => {
  const [opened, setOpened] = useState(false);

  const handleClose = () => {
    setOpened(false);
  };

  const handleOpen = () => {
    if (!disabled) {
      setOpened(true);
    }
  };

  return (
    <Tooltip
      {...tooltipProps}
      open={opened}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
};
