import { CheckBoxOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';

import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';

export const getBooleanDefaultValue = (
  valueFromPath: any,
  isNullable?: boolean,
) => {
  const defaultTrueChecked =
    valueFromPath?._eq === true ||
    valueFromPath?._in?.includes(true) ||
    valueFromPath?._neq === false;
  const defaultFalseChecked =
    valueFromPath?._eq === false ||
    valueFromPath?._in?.includes(false) ||
    valueFromPath?._neq === true;
  const defaultNullChecked =
    isNullable &&
    (valueFromPath?._is_null || typeof valueFromPath?._neq === 'boolean');

  return {
    defaultTrueChecked,
    defaultFalseChecked,
    defaultNullChecked,
  };
};

export const BooleanFilter = ({
  label,
  path,
  addWhereClause,
  deleteWhereClause,
  where,
  queryParamsScope,
  disabled,
  isNullable = true,
}: QuickFilterProps) => {
  const { t } = useLocale();
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);
  const { getValueFromPath } = useSchema();
  const initialValue = getValueFromPath(path, where);
  const { defaultTrueChecked, defaultFalseChecked, defaultNullChecked } =
    getBooleanDefaultValue(initialValue, isNullable);

  const apply = ({
    trueChecked = defaultTrueChecked,
    falseChecked = defaultFalseChecked,
    nullChecked = defaultNullChecked,
  }: {
    trueChecked?: boolean;
    falseChecked?: boolean;
    nullChecked?: boolean;
  }) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (trueChecked && falseChecked && (nullChecked || !isNullable)) {
      setFiltersParams(newWhere);
      return;
    } else if (!trueChecked && !falseChecked && !nullChecked) {
      setFiltersParams(newWhere);
      return;
    } else if (trueChecked && falseChecked) {
      newWhere = addWhereClause(newWhere, [...path, '_in'], [true, false]);
    } else if (trueChecked && nullChecked) {
      newWhere = addWhereClause(newWhere, [...path, '_neq'], false);
    } else if (falseChecked && nullChecked) {
      newWhere = addWhereClause(newWhere, [...path, '_neq'], true);
    } else if (trueChecked) {
      newWhere = addWhereClause(newWhere, [...path, '_eq'], true);
    } else if (falseChecked) {
      newWhere = addWhereClause(newWhere, [...path, '_eq'], false);
    } else if (nullChecked) {
      newWhere = addWhereClause(newWhere, [...path, '_is_null'], true);
    }
    return setFiltersParams(newWhere);
  };

  const handleDelete = () => {
    const newWhere = deleteWhereClause(where, path, true);
    setFiltersParams(newWhere);
  };

  return (
    <FilterChip
      label={label}
      icon={<CheckBoxOutlined />}
      onDelete={
        defaultTrueChecked || defaultFalseChecked || defaultNullChecked
          ? handleDelete
          : undefined
      }
      disabled={disabled?.(where) ?? false}
      renderFilter={() => (
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultTrueChecked}
                  onChange={e => {
                    apply({
                      trueChecked: e.target.checked,
                    });
                  }}
                />
              }
              label={t('True')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultFalseChecked}
                  onChange={e => {
                    apply({
                      trueChecked: defaultTrueChecked,
                      falseChecked: e.target.checked,
                      nullChecked: defaultNullChecked,
                    });
                  }}
                />
              }
              label={t('False')}
            />
            {isNullable && (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={defaultNullChecked}
                    onChange={e => {
                      apply({
                        trueChecked: defaultTrueChecked,
                        falseChecked: defaultFalseChecked,
                        nullChecked: e.target.checked,
                      });
                    }}
                  />
                }
                label={t('Null')}
              />
            )}
          </FormGroup>
        </Paper>
      )}
    />
  );
};
