import { useQuery } from '@apollo/client';

import { useLocale } from '../../../../src/hooks/locale';
import type { SetHook } from '../../../../src/hooks/set';
import type {
  StepListingsCompareAlgoQuery,
  StepListingsCompareFragment,
} from '../../../__generated__/graphql';
import RaGrid from '../../../components/RaGrid';
import ComparableListingsSkeleton from '../../comparable-listings/ComparableListingsSkeleton';
import { STEP_LISTINGS_COMPARE_ALGO } from '../cmaReportsQueries';
import { getAutoAlgoParams } from '../shared';

import ListingCardInfo from './ListingCardInfo';
import { SelectableCard } from './SelectableCard';

interface ListingAutomaticProps {
  data: StepListingsCompareFragment;
  onSelect: (id: string | number) => void;
  selection: SetHook<string | number>;
}

export type ListingAutomaticType = Omit<
  NonNullable<
    | StepListingsCompareAlgoQuery['ch_get_similar_listings_vector']
    | StepListingsCompareAlgoQuery['fr_get_similar_listings_vector']
    | StepListingsCompareAlgoQuery['es_get_similar_listings_vector']
    | StepListingsCompareAlgoQuery['it_get_similar_listings_vector']
  >[number],
  'property_type'
> & {
  property_type: null | string;
};

const ListingAutomatic = ({
  selection,
  onSelect,
  data: fragmentData,
}: ListingAutomaticProps) => {
  const { countryCode } = useLocale();

  const autoAlgoParams = getAutoAlgoParams(fragmentData?.lead, countryCode);
  const { data, loading } = useQuery(STEP_LISTINGS_COMPARE_ALGO, {
    ...autoAlgoParams,
  });

  const listings: ListingAutomaticType[] =
    data?.ch_get_similar_listings_vector ??
    data?.es_get_similar_listings_vector ??
    data?.fr_get_similar_listings_vector ??
    data?.it_get_similar_listings_vector ??
    [];

  const renderItemCard = (listing: ListingAutomaticType) => {
    const isSelected = selection.has(listing.id);
    return (
      <SelectableCard
        selected={isSelected}
        onChange={() => onSelect(listing.id)}
      >
        <ListingCardInfo listing={listing} variant="vertical" />
      </SelectableCard>
    );
  };

  return (
    <RaGrid
      items={listings}
      loading={loading}
      pageSize={12}
      renderItemCard={renderItemCard}
      renderSkeleton={() => <ComparableListingsSkeleton />}
      selection={selection as SetHook<string | number>}
    />
  );
};

export default ListingAutomatic;
