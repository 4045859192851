// @flow

import * as React from 'react';

import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { useLocale } from '../../hooks/locale';
import { Delete } from '../../icons/delete';

import type { placePickerListQuery } from './__generated__/placePickerListQuery.graphql';
import { PlaceListItem } from './place-list-item';
import type { PlacePickerPlace } from './place-picker';

type Props = {|
  items: $ReadOnlyArray<PlacePickerPlace>,
  onRemove: (objectId: string) => void,
|};

export const PlacePickerList = ({ items, onRemove }: Props): React.Node => {
  const { t } = useLocale();

  const data = useLazyLoadQuery<placePickerListQuery>(
    graphql`
      query placePickerListQuery($ids: [String!]) {
        places(objectId_in: $ids, limit: 100, all: true) {
          objectId
          ...placeListItem_place
        }
      }
    `,
    { ids: items.map(x => x.objectId) },
  );

  return (
    <List dense={true}>
      {items.length === 0 ? (
        <ListItem css={{ textAlign: 'center' }}>
          <ListItemText secondary={t('noPlaceSelected')} />
        </ListItem>
      ) : (
        data.places.map(item => (
          <ListItem key={item.objectId}>
            <React.Suspense fallback={null}>
              <PlaceListItem place={item} />
            </React.Suspense>
            <ListItemSecondaryAction>
              <IconButton onClick={() => onRemove(item.objectId)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      )}
    </List>
  );
};
