import * as React from 'react';

import { type FilledInputProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { format, isValid, parse } from 'date-fns';

type Props = {
  Input?: (props: FilledInputProps) => JSX.Element;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  label?: string;
};

const DATE_FORMAT = 'dd/MM/yyyy';

export const parseDate = (date: string): null | Date => {
  const parsed = parse(date, DATE_FORMAT, Date.now());
  if (!isValid(parsed)) {
    return null;
  }
  return parsed;
};

// any here fixes "recursion limit exceeded"
export const formatDate = (date: Date | number): any => {
  return isValid(date) ? format(date, DATE_FORMAT) : '';
};

const useNow = () => {
  const nowRef = React.useRef<null | Date>(null);
  if (nowRef.current == null) {
    const now = new Date();
    nowRef.current = now;
    return now;
  } else {
    return nowRef.current;
  }
};

export const DateInput = (props: Props) => {
  const now = useNow();

  return (
    <DatePicker
      label={props.label ?? ''}
      value={parseDate(props.value) ?? now}
      onChange={date => {
        if (date != null) {
          props.onChange(formatDate(date));
        }
      }}
    />
  );
};
