import React, { memo, useCallback, useMemo } from 'react';

import {
  type InternalRefetchQueriesInclude,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { AddLocationAltOutlined, HomeWorkOutlined } from '@mui/icons-material';
import { Alert } from '@mui/material';
import {
  type DeepPartial,
  type ErrorOption,
  type FieldPath,
  useFormContext,
} from 'react-hook-form';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import type {
  GetCreateTransactionDataQuery,
  GetPropertyTypesQuery,
  Properties_Insert_Input,
  Property_Transactions_Insert_Input,
} from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type DrawerTab,
  generateTab,
} from '../../components/drawer/DrawerTopBar';
import type { RaAddressType } from '../../components/form/RaAddressInput';
import {
  type FormDefinitionType,
  type FormFieldDefinitionType,
  type NonInternalProps,
  RaForm,
  createCategoryElement,
} from '../../components/form/RaForm';
import {
  type ListingPreview,
  RaListingPreview,
  type RaListingPreviewData,
} from '../../components/form/RaListingPreview';
import {
  createPropertyTypeOptions,
  propertyDetailsConditionalFields,
} from '../../components/property-form/forms-definitions/propertyDetailsFormDefinition';
import type { PropertyTypeLight } from '../../components/property-form/propertyFormQueries';
import { PropertyTypeProvider } from '../../components/property-form/PropertyTypeProvider';
import { useAppData } from '../../providers/AppDataProvider';
import { numberContainsDecimals } from '../../utils/validation';

import {
  type TransactionFormContext,
  type TransactionFormData,
  transactionFormDefinition,
} from './transactionFormDefinition';
import {
  CREATE_TRANSACTION,
  GET_USER_DEFAULT_TEAM_ID,
} from './transactionQueries';

type PropertyTypes = GetPropertyTypesQuery['property_types'];
type CreateTransactionListingFormData = Omit<
  TransactionFormData,
  'sellers_ids' | 'buyers_ids' | 'created_by' | 'created_at'
> &
  RaListingPreviewData;
type CreateTransactionScratchFormData = Omit<
  TransactionFormData,
  'sellers_ids' | 'buyers_ids' | 'created_by' | 'created_at'
> & {
  __main_type: string;
  __property_type?: PropertyTypeLight;
  property_type_id: Properties_Insert_Input['property_type_id'];
  property: Pick<
    Properties_Insert_Input,
    | 'number_of_bedrooms'
    | 'number_of_rooms'
    | 'built_surface'
    | 'living_surface'
    | 'land_surface'
    | Exclude<keyof RaAddressType, 'google_place_id'>
  >;
};
type CreateTransactionFormData =
  | CreateTransactionListingFormData
  | CreateTransactionScratchFormData;
type CreateTransactionFormContext = Omit<
  TransactionFormContext,
  'includeMetadata' | 'onUserCreateSelected'
> & {
  propertyTypes: PropertyTypes;
  isTenantSpanish: boolean;
  countryCode: string;
  fromListing: boolean;
  freezeListingChoice: boolean;
  fromOffer: string | null;
};

const GET_CREATE_TRANSACTION_DATA_QUERY = gql(/* GraphQL */ `
  query GetCreateTransactionData(
    $includeListing: Boolean!
    $includeOffer: Boolean!
    $offerId: uuid
    $listingId: uuid
  ) {
    lots(where: { id: { _eq: $listingId } }) @include(if: $includeListing) {
      id
      broker_id
      mandate_type
      broker {
        id
        default_team_id
      }
      ...ListingCardSnippet
      last_accepted_offer: offers(where: { id: { _eq: $offerId } })
        @include(if: $includeOffer) {
        id
        amount
        buyer_id
      }
    }
    property_types {
      id
      name
      label
      main_type
      type
    }
  }
`);

const RaListingPreviewWrapper: React.FC<{
  freezeListingChoice: boolean;
  fromOffer: string | null;
}> = memo(({ freezeListingChoice, fromOffer }) => {
  const { setValue, getValues } = useFormContext<CreateTransactionFormData>();
  const [purchasePrice, sellerBroker] = getValues([
    'purchase_price',
    'seller_broker_id',
  ]);

  const onListingFetched = useCallback(
    (listing: ListingPreview) => {
      if (
        fromOffer != null &&
        listing?.last_accepted_offer != null &&
        listing.last_accepted_offer.length > 0 &&
        purchasePrice == null
      ) {
        setValue('purchase_price', listing.last_accepted_offer[0].amount, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      if (sellerBroker == null && listing?.broker_id != null) {
        setValue('seller_broker_id', listing.broker_id, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [fromOffer, purchasePrice, sellerBroker, setValue],
  );

  const listingLink = useMemo(
    () =>
      freezeListingChoice
        ? undefined
        : (listingId: string) => ({
            pathname: `/listings/${listingId}`,
          }),
    [freezeListingChoice],
  );

  return (
    <RaListingPreview
      includeOfferInQuery={fromOffer ?? undefined}
      onListingFetched={onListingFetched}
      listingLink={listingLink}
    />
  );
});

const formDefinition: FormDefinitionType<
  CreateTransactionFormData,
  CreateTransactionFormContext
> = ({ t, context }) => {
  const {
    fromListing,
    freezeListingChoice,
    fromOffer,
    ...transactionFormContext
  } = context;
  const fieldDefinitions: FormFieldDefinitionType<CreateTransactionFormData>[] =
    [];

  if (fromListing) {
    fieldDefinitions.push(
      ...([
        {
          name: 'listing_title',
          label: t('Listing'),
          type: 'category-title',
        },
        {
          type: 'lot',
          name: 'listing_id',
          label: t('Listing'),
          disabled: () => freezeListingChoice || context.readonly,
        },
        {
          type: 'custom',
          name: 'listingPreview',
          element: (
            <RaListingPreviewWrapper
              freezeListingChoice={freezeListingChoice}
              fromOffer={fromOffer}
            />
          ),
          gridProps: { md: 12 },
        },
      ] as FormFieldDefinitionType<CreateTransactionFormData>[]),
    );
  } else {
    fieldDefinitions.push(createCategoryElement('address', t('Address')), {
      path: 'property',
      label: t('Name'),
      type: 'address',
      countryRestriction: context.countryCode,
      requiredFields: () => ({
        locality: true,
        postcode: true,
      }),
      gridProps: { xs: 12, md: 12, lg: 12, xl: 12 },
    });
  }

  fieldDefinitions.push(
    ...(transactionFormDefinition({
      t,
      context: {
        ...transactionFormContext,
        includeMetadata: false,
        includeSellers: false,
        includeBuyers: false,
      },
    }) as FormFieldDefinitionType<CreateTransactionFormData>[]),
  );

  if (!fromListing) {
    fieldDefinitions.push(
      ...([
        createCategoryElement('property_details_title', t('Property details')),
        {
          name: '__main_type',
          label: t('Property main type'),
          type: 'select',
          options: () =>
            createPropertyTypeOptions(
              context.propertyTypes,
              pt => pt.type === 'main',
              'name',
            ),
          required: true,
        },
        {
          name: 'property_type_id',
          label: t('Property type'),
          type: 'select',
          options: ({ __main_type }: CreateTransactionScratchFormData) =>
            createPropertyTypeOptions(
              context.propertyTypes,
              pt => pt.main_type === __main_type && pt.type === 'simple',
              'id',
            ),
          required: true,
        },
        {
          name: context.isTenantSpanish
            ? 'property.number_of_bedrooms'
            : 'property.number_of_rooms',
          label: context.isTenantSpanish
            ? t('Number of bedrooms')
            : t('Number of rooms'),
          type: 'number',
          decimalNumbers: 1,
          render: ({
            __main_type,
            __property_type,
          }: CreateTransactionScratchFormData) => {
            if (__property_type == null) {
              return false;
            }

            const renderFn = propertyDetailsConditionalFields.get(
              context.isTenantSpanish
                ? 'number_of_bedrooms'
                : 'number_of_rooms',
            );

            return renderFn
              ? renderFn({
                  __main_type,
                  __property_type,
                })
              : true;
          },
          required: true,
          min: 1,
        },
        {
          name: context.isTenantSpanish
            ? 'property.built_surface'
            : 'property.living_surface',
          label: context.isTenantSpanish
            ? t('Built surface')
            : t('Living surface'),
          type: 'number',
          render: ({
            __main_type,
            __property_type,
          }: CreateTransactionScratchFormData) => {
            if (__property_type == null) {
              return false;
            }

            const renderFn = propertyDetailsConditionalFields.get(
              context.isTenantSpanish ? 'built_surface' : 'living_surface',
            );

            return renderFn
              ? renderFn({
                  __main_type,
                  __property_type,
                })
              : true;
          },
          required: true,
          min: 0,
        },
        {
          name: 'property.land_surface',
          label: t('Land surface'),
          type: 'number',
          render: ({
            __main_type,
            __property_type,
          }: CreateTransactionScratchFormData) => {
            if (__property_type == null) {
              return false;
            }

            const renderFn =
              propertyDetailsConditionalFields.get('land_surface');

            return renderFn
              ? renderFn({
                  __main_type,
                  __property_type,
                })
              : true;
          },
          required: true,
          min: 0,
        },
      ] as FormFieldDefinitionType<CreateTransactionFormData>[]),
    );
  }

  return fieldDefinitions;
};

const CreateTransactionForm: React.FC<{
  propertyTypes: PropertyTypes;
  handleSubmit: (
    values: NonInternalProps<CreateTransactionFormData>,
    rawValues: CreateTransactionFormData,
  ) => Promise<any>;
  fromListing: boolean;
  onCancel: () => void;
  defaultListing?:
    | NonNullable<GetCreateTransactionDataQuery['lots']>[number]
    | null;
  freezeListingChoice?: boolean;
  fromOffer?: string | null;
}> = ({
  propertyTypes,
  handleSubmit,
  fromListing,
  onCancel,
  defaultListing,
  freezeListingChoice = false,
  fromOffer = null,
}) => {
  const { me } = useAppData();
  const { t, countryCode } = useLocale();

  const [getTeam] = useLazyQuery(GET_USER_DEFAULT_TEAM_ID);
  const defaultValues = useMemo<DeepPartial<CreateTransactionFormData>>(() => {
    if (!fromListing) {
      return { property: {}, __know_signature_date: true };
    }

    const defaultListingValues: DeepPartial<CreateTransactionFormData> = {
      listing_id: defaultListing?.id,
      __know_signature_date: true,
    };

    if (defaultListing != null) {
      if (
        fromOffer != null &&
        defaultListing?.last_accepted_offer != null &&
        defaultListing.last_accepted_offer.length > 0
      ) {
        defaultListingValues.purchase_price =
          defaultListing.last_accepted_offer[0].amount;
      }

      if (defaultListing?.broker_id != null) {
        defaultListingValues.seller_broker_id = defaultListing.broker_id;
        defaultListingValues.seller_broker_team_id =
          defaultListing.broker?.default_team_id;
      }
    }

    return defaultListingValues;
  }, [fromListing, defaultListing, fromOffer]);

  const context = useMemo<CreateTransactionFormContext>(
    () => ({
      propertyTypes,
      isTenantSpanish: me?.tenant.country_code === 'ES',
      countryCode,
      fullAccess: me?.is_admin ?? false,
      readonly: false,
      fromListing,
      freezeListingChoice,
      fromOffer,
    }),
    [
      propertyTypes,
      me,
      countryCode,
      fromListing,
      freezeListingChoice,
      fromOffer,
    ],
  );

  return (
    <RaForm
      formDefinition={formDefinition}
      key={fromListing ? 'listing' : 'scratch'}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      validate={values => {
        const errors: [FieldPath<CreateTransactionFormData>, ErrorOption][] =
          [];

        if (
          'property' in values &&
          values.property.number_of_rooms != null &&
          !numberContainsDecimals(values.property.number_of_rooms, [0, 5])
        ) {
          errors.push([
            'property.number_of_rooms',
            {
              message: t('The number of rooms must be a 0.5 increment'),
            },
          ]);
        }

        if (values.purchase_price === 0) {
          errors.push([
            'purchase_price',
            {
              type: 'min',
              message: t('The purchase price must be greater than 0'),
            },
          ]);
        }

        return errors;
      }}
      context={context}
      onCancel={isDirty => !isDirty && onCancel()}
      onChange={(data, name, _, { setValue }) => {
        if (name === 'seller_broker_id') {
          // set seller_broker_team_id if seller_broker_id is changed
          if (
            data?.seller_broker_team_id == null &&
            data?.seller_broker_id != null
          ) {
            getTeam({ variables: { id: data.seller_broker_id } }).then(
              response =>
                setValue(
                  'seller_broker_team_id',
                  response?.data?.users_by_pk?.default_team_id,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                ),
            );
          }
        } else if (name === 'buyer_broker_id') {
          // set buyer_broker_team_id if buyer_broker_id is changed
          if (
            data?.buyer_broker_team_id == null &&
            data?.buyer_broker_id != null
          ) {
            getTeam({ variables: { id: data.buyer_broker_id } }).then(
              response =>
                setValue(
                  'buyer_broker_team_id',
                  response?.data?.users_by_pk?.default_team_id,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                ),
            );
          }
        }
      }}
    >
      <PropertyTypeProvider propertyTypes={propertyTypes} />
    </RaForm>
  );
};

export const TransactionCreateDrawer = ({
  maxWidth = 960,
  refetchQueries,
  noTabs,
  onCreate,
  freezeListingChoice,
}: {
  maxWidth?: number;
  refetchQueries?: InternalRefetchQueriesInclude;
  noTabs?: boolean;
  onCreate?: (id: string) => void;
  freezeListingChoice?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const [createTransaction] = useMutation(CREATE_TRANSACTION, {
    refetchQueries,
  });

  const defaultListingId = searchParams.get('selected-listing');
  const fromOffer = searchParams.get('from-offer');

  const { data, error } = useQuery(GET_CREATE_TRANSACTION_DATA_QUERY, {
    variables: {
      listingId: defaultListingId,
      includeListing: defaultListingId != null,
      offerId: fromOffer,
      includeOffer: fromOffer != null,
    },
  });
  const property_types = data?.property_types ?? [];
  const defaultListing = data?.lots?.[0] ?? null;

  const matchA = useMatch('/transactions/new/:tabName/*');
  const matchB = useMatch('/dashboard/add-transaction/:tabName/*');
  const currentTab =
    matchA?.params.tabName ?? matchB?.params.tabName ?? 'scratch';

  const tabsDef: [string, string, React.ReactElement?][] = [
    [t('From scratch'), 'scratch', <AddLocationAltOutlined />],
    [t('From listing'), 'listing', <HomeWorkOutlined />],
  ];

  const tabs: DrawerTab[] | undefined = noTabs
    ? undefined
    : tabsDef.map(([label, value, icon]) =>
        generateTab([searchParams.toString(), label, value, icon]),
      );

  const handleClose = () => {
    searchParams.delete('selected-listing');
    searchParams.delete('from-offer');

    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });
  };

  const handleSubmit = useCallback(
    async (
      values: NonInternalProps<CreateTransactionFormData>,
      rawValues: CreateTransactionFormData,
    ) => {
      let input: Property_Transactions_Insert_Input = {};

      if ('property' in values) {
        const { property, property_type_id, ...transaction } = values;
        input = {
          ...transaction,
          property: {
            data: {
              ...property,
              property_type_id,
            },
          },
        };
      } else {
        const { listing_id, ...transaction } = values;
        const selectedListing = (rawValues as CreateTransactionListingFormData)
          .__listing;

        const sellers = selectedListing?.sellers.map(({ user_id }) => ({
          user_id,
        }));

        const buyers =
          selectedListing?.last_accepted_offer?.[0].buyer_id != null
            ? [
                {
                  user_id: selectedListing?.last_accepted_offer?.[0].buyer_id,
                },
              ]
            : undefined;

        input = {
          ...transaction,
          lot_id: listing_id,
          property_id: selectedListing?.property.id,
          buyers: buyers != null ? { data: buyers } : undefined,
          sellers: sellers != null ? { data: sellers } : undefined,
        };
      }

      const res = await createTransaction({
        variables: {
          transaction: input,
        },
      });

      if (res.data?.insert_property_transactions_one?.id != null) {
        onCreate?.(res.data.insert_property_transactions_one.id);
      }
    },
    [createTransaction, onCreate],
  );

  return (
    <Drawer
      onClose={handleClose}
      currentTab={currentTab}
      title={t('Create transaction')}
      tabs={tabs}
      maxWidth={maxWidth}
    >
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={{
                pathname: 'scratch',
                search: searchParams.toString(),
              }}
            />
          }
        />
        <Route
          path="scratch"
          element={
            <CreateTransactionForm
              propertyTypes={property_types}
              handleSubmit={handleSubmit}
              fromListing={false}
              onCancel={handleClose}
            />
          }
        />
        <Route
          path="listing"
          element={
            <CreateTransactionForm
              propertyTypes={property_types}
              handleSubmit={handleSubmit}
              fromListing={true}
              onCancel={handleClose}
              defaultListing={defaultListing}
              freezeListingChoice={freezeListingChoice}
              fromOffer={fromOffer}
            />
          }
        />
      </Routes>
    </Drawer>
  );
};

export default TransactionCreateDrawer;
