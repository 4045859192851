import { useCallback, useMemo } from 'react';

import {
  type InternalRefetchQueriesInclude,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Alert, LinearProgress } from '@mui/material';
import { type ErrorOption, type FieldPath } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import type {
  TransactionFormFragmentFragment,
  UserSelectFragment,
} from '../../__generated__/graphql';
import { useAddUserModal } from '../../components/AddUserModal';
import {
  type FormDefinitionType,
  type FormFieldDefinitionType,
  type NonInternalProps,
  RaForm,
} from '../../components/form/RaForm';
import {
  RaListingPreview,
  type RaListingPreviewData,
} from '../../components/form/RaListingPreview';

import { transactionFormDefinition } from './transactionFormDefinition';
import {
  GET_USER_DEFAULT_TEAM_ID,
  TRANSACTION_FORM_QUERY,
  TRANSACTION_FORM_UPDATE,
} from './transactionQueries';

type TransactionFormData = Omit<
  TransactionFormFragmentFragment,
  'id' | 'lot' | 'sellers' | 'buyers'
> &
  RaListingPreviewData & {
    __know_signature_date: boolean;
    sellers_ids: string[];
    buyers_ids: string[];
  };

type TransactionFormContext = {
  readonly: boolean;
  fullAccess: boolean;
  preventListingChange: boolean;
  hideListingField: boolean;
  onUserCreateSelected: (userData: {
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  }) => Promise<UserSelectFragment | undefined>;
};

const formDefinition: FormDefinitionType<
  TransactionFormData,
  TransactionFormContext
> = ({ t, context }) => {
  const fieldsDefinition: FormFieldDefinitionType<TransactionFormData>[] =
    context.hideListingField
      ? []
      : [
          {
            name: 'listing_title',
            label: t('Listing'),
            type: 'category-title',
          },
          {
            type: 'lot',
            name: 'listing_id',
            label: t('Listing'),
            disabled: () => context.readonly || context.preventListingChange,
            gridProps: { md: 12 },
          },
          {
            type: 'custom',
            name: 'listingPreview',
            element: (
              <RaListingPreview
                listingLink={listingId => ({
                  pathname: `/listings/${listingId}`,
                })}
              />
            ),
            gridProps: { md: 12 },
          },
        ];

  Array.prototype.push.apply(
    fieldsDefinition,
    transactionFormDefinition({
      t,
      context: {
        ...context,
        isPurchasePriceRequired: false,
      },
    }) as FormFieldDefinitionType<TransactionFormData>[],
  );

  return fieldsDefinition.map(fieldDef =>
    fieldDef.disabled == null
      ? { ...fieldDef, disabled: () => context.readonly }
      : fieldDef,
  );
};

type TransactionFormProps = {
  transactionId: string;
  readonly?: boolean;
  fullAccess?: boolean;
  preventListingChange?: boolean;
  hideListingField?: boolean;
  onCancel?: () => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

export const TransactionForm: React.FC<TransactionFormProps> = ({
  transactionId,
  readonly = false,
  fullAccess = false,
  preventListingChange = false,
  hideListingField = false,
  onCancel,
  refetchQueries,
}) => {
  const { t } = useLocale();
  const { data, loading, error } = useQuery(TRANSACTION_FORM_QUERY, {
    variables: { id: transactionId },
  });
  const [AddUserModal, openAddUserModal] = useAddUserModal();
  const [getTeam] = useLazyQuery(GET_USER_DEFAULT_TEAM_ID);
  const [updateTransaction] = useMutation(TRANSACTION_FORM_UPDATE, {
    refetchQueries: [
      ...(refetchQueries ?? []),
      {
        query: TRANSACTION_FORM_QUERY,
        variables: { id: transactionId },
      },
    ],
  });

  const defaultValues = useMemo<TransactionFormData | undefined>(() => {
    if (data?.property_transactions_by_pk == null) {
      return undefined;
    }

    const {
      id: _,
      lot: _lot,
      lot_id,
      sellers,
      buyers,
      ...transactionData
    } = data.property_transactions_by_pk;

    return {
      __listing: null,
      __know_signature_date: transactionData.signature_date != null,
      ...transactionData,
      listing_id: lot_id ?? null,
      sellers_ids: sellers.map(({ user_id }) => user_id).filter(v => v != null),
      buyers_ids: buyers.map(({ user_id }) => user_id).filter(v => v != null),
    };
  }, [data?.property_transactions_by_pk]);

  const onSubmit = useCallback(
    (
      formData: NonInternalProps<TransactionFormData>,
      { __know_signature_date }: TransactionFormData,
    ) => {
      const {
        sellers_ids,
        buyers_ids,
        estimated_signature_date,
        signature_date,
        payment_date,
        ...transactionData
      } = formData;
      const sellers = sellers_ids.map(id => ({
        transaction_id: transactionId,
        user_id: id,
      }));
      const buyers = buyers_ids.map(id => ({
        transaction_id: transactionId,
        user_id: id,
      }));

      const estimatedSignatureDate = __know_signature_date
        ? null
        : estimated_signature_date;
      const signatureDate = !__know_signature_date ? null : signature_date;
      const paymentDate = !__know_signature_date ? null : payment_date;

      return updateTransaction({
        variables: {
          id: transactionId,
          transaction: {
            ...transactionData,
            estimated_signature_date: estimatedSignatureDate,
            signature_date: signatureDate,
            payment_date: paymentDate,
          },
          sellers,
          buyers,
        },
      });
    },
    [transactionId, updateTransaction],
  );

  const onUserCreateSelected = useCallback(
    async (userData: {
      first_name: string | null;
      last_name: string | null;
      email: string | null;
    }) => {
      const newUser: {
        firstName?: string;
        lastName?: string;
        email?: string;
      } = {};

      if (userData.first_name != null) {
        newUser.firstName = userData.first_name;
      }

      if (userData.last_name != null) {
        newUser.lastName = userData.last_name;
      }

      if (userData.email != null) {
        newUser.email = userData.email;
      }

      return openAddUserModal(newUser);
    },
    [openAddUserModal],
  );

  if (loading) {
    return (
      <LinearProgress
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2000,
        }}
      />
    );
  }

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}

      <RaForm
        formDefinition={formDefinition}
        context={{
          readonly,
          preventListingChange,
          onUserCreateSelected,
          fullAccess,
          hideListingField,
        }}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onCancel={isDirty => !isDirty && onCancel?.()}
        validate={data => {
          const errors: [FieldPath<TransactionFormData>, ErrorOption][] = [];

          if (data.purchase_price === 0) {
            errors.push([
              'purchase_price',
              {
                type: 'min',
                message: t('The purchase price must be greater than 0'),
              },
            ]);
          }

          return errors;
        }}
        onChange={(data, name, type, { setValue }) => {
          if (type !== 'change') {
            return;
          }

          // set seller_broker_team_id if seller_broker_id is changed
          if (
            name === 'seller_broker_id' &&
            data?.seller_broker_team_id == null &&
            data?.seller_broker_id != null
          ) {
            getTeam({ variables: { id: data.seller_broker_id } }).then(
              response =>
                setValue(
                  'seller_broker_team_id',
                  response?.data?.users_by_pk?.default_team_id,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                ),
            );
          }
          // set buyer_broker_team_id if buyer_broker_id is changed
          if (
            name === 'buyer_broker_id' &&
            data?.buyer_broker_team_id == null &&
            data?.buyer_broker_id != null
          ) {
            getTeam({ variables: { id: data.buyer_broker_id } }).then(
              response =>
                setValue(
                  'buyer_broker_team_id',
                  response?.data?.users_by_pk?.default_team_id,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                ),
            );
          }
        }}
      />
      {AddUserModal}
    </>
  );
};
