import React, { useCallback, useRef, useState } from 'react';

import { Box, FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import { type Control, Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { HEX_REGEXP } from '../../../src/pages/general-settings';
import { borderRadius } from '../../../src/styles';

import { RaLabel } from './RaLabel';

export type RaColorPicker = {
  control: Control<any>;
  name: string;
  label: string;
};

export const RaColorPicker = ({ control, name, label }: RaColorPicker) => {
  const { t } = useLocale();
  const [localColor, setLocalColor] = useState('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const rules = {
    validate: (value: string) => {
      if (!value) {
        return true;
      }

      if (!HEX_REGEXP.test(value)) {
        return t('colorShouldBeHex');
      }

      return true;
    },
  };

  const debouncedOnChange = useCallback(
    (value: string, onChange: (value: string) => void) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        onChange(value);
        timeoutRef.current = null;
      }, 100);
    },
    [],
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newColor = e.target.value;
          setLocalColor(newColor);
          debouncedOnChange(newColor, field.onChange);
        };

        return (
          <FormControl fullWidth error={!!error}>
            <RaLabel label={label} />
            <Box sx={{ display: 'flex' }}>
              <div
                css={{
                  position: 'relative',
                  overflow: 'hidden',
                  flexShrink: 0,
                  width: 40,
                  height: 40,
                  marginRight: 8,
                  border: '1px solid #000000',
                  borderRadius: borderRadius.small,
                }}
              >
                <input
                  type="color"
                  css={{
                    cursor: 'pointer',
                    position: 'absolute',
                    width: '120%',
                    height: '130%',
                    border: 'none',
                    padding: 0,
                    left: -3,
                    top: -5,
                  }}
                  value={localColor || field.value || ''}
                  onChange={handleColorChange}
                />
              </div>
              <OutlinedInput
                autoComplete="off"
                size="small"
                fullWidth
                sx={{
                  background: 'white',
                }}
                value={localColor || field.value || ''}
                onChange={handleColorChange}
              />
            </Box>

            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
