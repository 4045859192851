// @flow

import * as React from 'react';

import { Divider } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { Flex } from 'react-system';

import { useLocale } from '../hooks/locale';
import {
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../hooks/url-search-params';
import { BaseDateFilter } from '../shared/base-date-filter';

export type DateRangeParams = {|
  startDate: Field<Date | null>,
  endDate: Field<Date | null>,
|};

export const dateRangeParams: DateRangeParams = {
  startDate: makeField({
    get: params => {
      const timestamp = params.getNumber('startDate');
      if (timestamp == null) {
        return null;
      } else {
        return startOfDay(timestamp);
      }
    },
    set: (params, value) =>
      params.setNumber('startDate', value?.getTime() ?? null),
  }),
  endDate: makeField({
    get: params => {
      const timestamp = params.getNumber('endDate');
      if (timestamp == null) {
        return null;
      } else {
        return endOfDay(timestamp);
      }
    },
    set: (params, value) =>
      params.setNumber('endDate', value?.getTime() ?? null),
  }),
};

const useDateRangeParams = makeUrlSearchParamsHook(dateRangeParams);

type DateRangeFilterProps = {|
  top?: React.Node,
  onReset?: () => void,
  label?: string,
|};

export const DateRangeFilter = (props: DateRangeFilterProps): React.Node => {
  const { t } = useLocale();
  const { label = t('dates'), top = null, onReset = () => {} } = props;
  const [params, setParams] = useDateRangeParams();
  const { startDate, endDate } = params;
  const dateRange =
    startDate && endDate ? { start: startDate, end: endDate } : null;
  const setDateRange = ({ start, end }) => {
    const endDate = end ?? start;
    setParams({ startDate: start, endDate });
  };

  return (
    <BaseDateFilter
      dateRange={dateRange}
      setDateRange={setDateRange}
      onReset={() => {
        onReset();
        setParams({ startDate: null, endDate: null });
      }}
      label={label}
      dialogTitle={label}
      position="top"
    >
      {top != null && (
        <Flex
          justifyContent="space-between"
          px={3}
          width={['280px', '540px']}
          flexWrap="wrap"
        >
          {top}
        </Flex>
      )}
      <Divider />
    </BaseDateFilter>
  );
};
