import { useState } from 'react';

import { FormControl, FormHelperText } from '@mui/material';
import { type Control, Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import {
  AutocompleteMulti,
  type AutocompleteMultiProps,
} from '../AutocompleteMulti';
import {
  type RaAutoCompleteCreatableItem,
  isCreatableItem,
} from '../data-grid/RaAutoComplete';

import { RaLabel } from './RaLabel';

type RaTagsProps<T> = {
  onFilter: (inputStr: string) => Promise<T[]> | T[];
  control: Control<any>;
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
} & Pick<
  AutocompleteMultiProps<T>,
  'InputProps' | 'getOptionLabel' | 'isOptionEqualToValue' | 'renderTags'
> &
  (
    | {
        onNewTagSelected: (tag: string) => Promise<T> | T;
        renderOption?: (
          props: React.HTMLAttributes<HTMLLIElement>,
          option: T | RaAutoCompleteCreatableItem<T>,
        ) => React.ReactNode;
      }
    | {
        onNewTagSelected?: never;
        renderOption?: (
          props: React.HTMLAttributes<HTMLLIElement>,
          option: T,
        ) => React.ReactNode;
      }
  );

export const RaTags = <T,>(props: RaTagsProps<T>): React.ReactNode => {
  const { t } = useLocale();
  const [isLoading, setIsLoading] = useState(false);
  const rules = {
    required: props.required ? t('This field is required') : undefined,
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={rules}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <RaLabel label={props.label} required={props.required} />
          <AutocompleteMulti<T>
            loading={isLoading}
            disabled={props.disabled}
            error={!!error}
            creatable={props.onNewTagSelected != null}
            values={value}
            onFilter={props.onFilter}
            getOptionLabel={props.getOptionLabel}
            renderOption={(
              optProps: React.HTMLAttributes<HTMLLIElement>,
              option: any,
            ) => props.renderOption?.(optProps, option)}
            renderTags={props.renderTags}
            InputProps={props.InputProps}
            isOptionEqualToValue={props.isOptionEqualToValue}
            ref={ref}
            onChange={async (
              values: (T | RaAutoCompleteCreatableItem<T>)[],
            ) => {
              const creatableItem = values.find(isCreatableItem);

              if (creatableItem != null) {
                if (props.onNewTagSelected != null) {
                  const result = props.onNewTagSelected(
                    creatableItem.inputValue,
                  );
                  let createdItem: T;

                  if (result instanceof Promise) {
                    setIsLoading(true);
                    createdItem = await result;
                    setIsLoading(false);
                  } else {
                    createdItem = result;
                  }

                  onChange([...values, createdItem]);
                }
              } else {
                onChange(values);
              }
            }}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
