import * as React from 'react';

import { FilledInput } from '@mui/material';
import { useRifm } from 'rifm';

import { useLocale } from '../hooks/locale';
import { useSelectionBound } from '../hooks/selection-bound';
import { makeNumberFormat } from '../utils/number-format';

type Props = {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
};

type Format = (value: string) => string;

const appendUnit =
  (format: Format, unit: string): Format =>
  string =>
    format(string) + unit;

export const PercentInput = (props: Props) => {
  const { locale } = useLocale();
  const { accept, format, delocalizeNumber, localizeNumber } = makeNumberFormat(
    { scale: 2, locale },
  );
  const formatWithUnit = appendUnit(format, '%');
  const rifm = useRifm({
    accept,
    format: formatWithUnit,
    value: localizeNumber(props.value),
    onChange: formatted => props.onChange(delocalizeNumber(formatted)),
  });

  const inputRef = React.useRef(null);
  useSelectionBound(inputRef, 0, -2);

  return (
    <FilledInput
      inputRef={inputRef}
      value={rifm.value}
      onChange={rifm.onChange}
      onBlur={props.onBlur}
    />
  );
};
