import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useLocale } from '../hooks/locale';
import { Email } from '../icons/email';

import { ConnectEmailPanel } from './ConnectEmailPanel';

type Props = {
  onSuccess: () => void;
  onError: () => void;
};

export const ConnectEmailButton = ({ onSuccess, onError }: Props) => {
  const { t } = useLocale();
  const [connectDialog, setConnectDialog] = React.useState(false);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={connectDialog}
        onClose={() => setConnectDialog(false)}
      >
        <DialogContent>
          <ConnectEmailPanel
            onSuccess={onSuccess}
            onError={onError}
            onCancel={() => {
              setConnectDialog(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <Button
        variant={'contained'}
        color="secondary"
        autoFocus={true}
        startIcon={<Email />}
        onClick={() => setConnectDialog(true)}
      >
        {t('connectEmail')}
      </Button>
    </>
  );
};
