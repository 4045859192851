import { Fragment } from 'react';

import { useQuery } from '@apollo/client';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { type Currency } from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import { gql } from '../../__generated__';
import type {
  GetListingTransactionsDataQuery,
  UserCardDisplayNameFragment,
} from '../../__generated__/graphql';
import { toHumanReadableDate } from '../../utils/formatting';
import { ListingAccordion } from '../ListingAccordion';
import { getUserDisplayName } from '../UserCard';

interface ListingTransactionsCardProps {
  listingId: string | null | undefined;
  openedDefault?: boolean;
  onEditTransaction?: (transactionId: string) => void;
}

const GET_LISTING_TRANSACTIONS_DATA = gql(/* GraphQL */ `
  query GetListingTransactionsData($id: uuid!) {
    property_transactions(
      where: { lot_id: { _eq: $id } }
      order_by: { payment_date: asc_nulls_last, created_at: asc }
    ) {
      ...TransactionFormFragment
      commissions(order_by: { payment_date: asc }) {
        id
        type
        amount
        contact {
          ...UserCardDisplayName
        }
      }
      lot {
        currency
      }
      property {
        country_code
      }
    }
  }
`);

type PropertyTransaction =
  GetListingTransactionsDataQuery['property_transactions'][number];

type CommissionData = {
  id: any;
  amount?: number | null;
  sharePercentage: number;
  contact?: UserCardDisplayNameFragment | null;
};

const computeCommission = (transaction: PropertyTransaction) => {
  const totalCommission = transaction.commissions.find(
    ({ type }) => type === 'total',
  )?.amount;
  const sharePercentage =
    ((totalCommission ?? 0) / (transaction.purchase_price ?? 0)) * 100;

  const commissionSplits = transaction.commissions.reduce((acc, commision) => {
    if (commision.type === 'split') {
      acc.push({
        ...commision,
        sharePercentage:
          ((commision.amount ?? 0) / (transaction.purchase_price ?? 0)) * 100,
      });
    }

    return acc;
  }, [] as CommissionData[]);

  return {
    totalCommission,
    sharePercentage,
    commissionSplits,
  };
};

const Commission: React.FC<{
  transaction: PropertyTransaction;
  currency: Currency;
}> = ({ transaction, currency }) => {
  const { t, locale } = useLocale();
  const signatureDate =
    transaction.signature_date != null
      ? t('Signed {{date}}', {
          date: toHumanReadableDate(locale, transaction.signature_date, {
            month: 'numeric',
          }),
        })
      : null;
  const estimatedSignatureDate =
    transaction.estimated_signature_date != null
      ? t('Predicted signature date: {{date}}', {
          date: toHumanReadableDate(
            locale,
            transaction.estimated_signature_date,
            { month: 'numeric' },
          ),
        })
      : null;

  const { totalCommission, sharePercentage, commissionSplits } =
    computeCommission(transaction);

  return (
    <>
      <Typography
        variant="body2"
        sx={theme => ({ ...theme.mixins.truncate(), color: 'neutral.main' })}
      >
        {[
          signatureDate ?? estimatedSignatureDate ?? t('Not signed yet'),
          ...(totalCommission != null
            ? [
                t('{{percentage}}% commission', {
                  percentage: sharePercentage.toLocaleString(locale, {
                    maximumFractionDigits: 1,
                  }),
                }),
                formatPrice(totalCommission, locale, currency),
              ]
            : []),
        ].join(' • ')}
      </Typography>
      {commissionSplits.map(commission => (
        <Typography
          key={commission.id}
          variant="body2"
          sx={theme => ({ ...theme.mixins.truncate(), color: 'neutral.main' })}
        >
          {[
            commission.contact != null
              ? getUserDisplayName(commission.contact)
              : null,
            commission.amount != null
              ? formatPrice(commission.amount, locale, currency)
              : '-',
          ]
            .filter(Boolean)
            .join(' • ')}
        </Typography>
      ))}
    </>
  );
};

export const ListingTransactionsCardSkeleton: React.FC<{
  openedDefault?: boolean;
}> = ({ openedDefault }) => {
  const { t } = useLocale();
  return (
    <ListingAccordion title={t('Transactions')} defaultExpanded={openedDefault}>
      <Stack>
        <Typography variant="body1">
          <Skeleton width={70} />
        </Typography>
        <Typography variant="body2">
          <Skeleton width={130} />
        </Typography>
        <Typography variant="body2">
          <Skeleton width={100} />
        </Typography>
        <Typography variant="body2">
          <Skeleton width={90} />
        </Typography>
      </Stack>
    </ListingAccordion>
  );
};

export const ListingTransactionsCard: React.FC<
  ListingTransactionsCardProps
> = ({ listingId, onEditTransaction, openedDefault = false }) => {
  const { t, locale } = useLocale();
  const { data, loading } = useQuery(GET_LISTING_TRANSACTIONS_DATA, {
    variables: { id: listingId ?? '' },
    skip: listingId == null,
  });

  return (
    <ListingAccordion title={t('Transactions')} defaultExpanded={openedDefault}>
      {data?.property_transactions.length === 0 && (
        <Button
          startIcon={<AddCircleOutlineOutlinedIcon />}
          size="small"
          component={Link}
          to={{
            pathname: `/listings/${listingId}/transaction/new/listing`,
            search: `?selected-listing=${listingId}`,
          }}
          sx={{ display: 'inline-flex' }}
        >
          {t('Add a transaction')}
        </Button>
      )}

      {loading && (
        <Stack>
          <Typography variant="body1">
            <Skeleton width={70} />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={130} />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={90} />
          </Typography>
        </Stack>
      )}

      {!loading &&
        data?.property_transactions.map((transaction, index) => {
          const currencyToFormat = (transaction.lot?.currency ??
            (transaction.property.country_code === 'CH'
              ? 'CHF'
              : 'EUR')) as Currency;

          return (
            <Fragment key={transaction.id}>
              {index > 0 && <Divider sx={{ my: 2 }} />}
              <Link
                to={`/listings/${listingId}/transaction/${transaction.id}`}
                css={{ display: 'block', position: 'relative' }}
              >
                <Stack>
                  <Typography
                    variant="body1"
                    sx={theme => theme.mixins.truncate()}
                  >
                    {formatPrice(
                      transaction.purchase_price,
                      locale,
                      currencyToFormat,
                    )}
                  </Typography>
                  <Commission
                    transaction={transaction}
                    currency={currencyToFormat}
                  />
                </Stack>
                {onEditTransaction != null && (
                  <IconButton
                    sx={{
                      position: 'absolute',
                      // To account for the button padding so it seems like it's on the edge of the parent
                      top: -8,
                      right: -8,
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();

                      onEditTransaction(transaction.id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Link>
            </Fragment>
          );
        })}
    </ListingAccordion>
  );
};
