import * as React from 'react';
import {
  ButtonGroup,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Divider,
  Modal,
} from '@mui/material';
import type { TranslatorStore, TranslatorIndex } from './engine';
import { _buildIndexKey } from './engine';
import { encode, decode } from './codec';

const help = `
Use the following commands for searching
:empty - shows all missing translations or marked as empty
:keys - shows all translations for specified space separated keys

Any other search term will show similar matches.

Use "Find" button to select translations in UI.

"Preview" - update UI with entered translations
"Reset" - clear all local changes and refresh UI
"Publish" - change local translations or create pull request

`;

type Props = {
  project: string;
  store: TranslatorStore;
  onStoreInvalidate: () => void;
  onPublish: (updatedBy: string) => void;
};

export { encode as encodeKeyIntoValue };

// check for existing and for magic prefix hardcoded in scanner
const isEmptyValue = (v: null | string) =>
  v == null || v === '' || v.startsWith('\0__');

type Index = {
  availableLanguages: ReadonlyArray<string>;
  availableKeys: Set<string>;
  emptyKeys: Set<string>;
  tokens: Set<[string, string]>;
};

const indexValue = (
  storeIndex: TranslatorIndex,
  currentLanguage: string,
): Index => {
  const availableKeys = new Set<string>();
  const availableLanguages = new Set<string>();
  const emptyKeys = new Set<string>();
  const tokens = new Set<[string, string]>();
  const currentData = new Map();
  for (const [, { language, key, value }] of storeIndex) {
    availableLanguages.add(language);
    availableKeys.add(key);
    if (language === currentLanguage) {
      currentData.set(key, value);
    }
    if (isEmptyValue(value) === false) {
      tokens.add([key, value.toLocaleLowerCase()]);
    }
  }
  // split into own loop to not duplicated for each language
  for (const key of availableKeys) {
    tokens.add([key, key.toLocaleLowerCase()]);
    // find empty translations in all keys
    if (isEmptyValue(currentData.get(key))) {
      emptyKeys.add(key);
    }
  }
  return {
    availableLanguages: Array.from(availableLanguages),
    availableKeys,
    emptyKeys,
    tokens,
  };
};

const searchTerm = (index: Index, rawTerm: string): ReadonlyArray<string> => {
  if (rawTerm === '') {
    return Array.from(index.availableKeys);
  }
  if (rawTerm.startsWith(':')) {
    const [command, ...tokens] = rawTerm.slice(1).split(/\s+/);
    if (command === 'empty') {
      return Array.from(index.emptyKeys);
    }
    if (command === 'keys') {
      // skip missing or duplicated keys
      const unique = new Set<string>();
      for (const key of tokens) {
        if (index.availableKeys.has(key)) {
          unique.add(key);
        }
      }
      return Array.from(unique);
    }
  }
  const matched = [];
  const term = rawTerm.toLocaleLowerCase();
  for (const [key, token] of index.tokens) {
    if (token.includes(term)) {
      matched.push({ key, rank: token.length });
    }
  }
  const sorted = matched.sort((a, b) => a.rank - b.rank);
  const unique = new Set<string>();
  for (const { key } of sorted) {
    unique.add(key);
  }
  return Array.from(unique);
};

const useStableCallback = <T extends (...args: ReadonlyArray<any>) => any>(
  callback: T,
): T => {
  const callbackRef = React.useRef(callback);
  const stable: any = React.useCallback(
    (...args: ReadonlyArray<any>) => callbackRef.current(...args),
    [],
  );
  React.useEffect(() => {
    callbackRef.current = callback;
  });
  return stable;
};

const dividerColor = 'rgba(0, 0, 0, 0.12)';

const Container = ({
  children,
  innerRef,
}: {
  innerRef: { current: null | HTMLDivElement };
  children: React.ReactNode;
}) => {
  // TODO implement resizing and store size in storage
  const [width] = React.useState(500);
  React.useEffect(() => {
    if (document.body) {
      document.body.style.marginRight = width + 'px';
    }
  }, [width]);
  return (
    <Modal
      open={true}
      disableScrollLock={true}
      hideBackdrop={true}
      disableEscapeKeyDown={true}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      style={{
        pointerEvents: 'none',
      }}
    >
      <div
        ref={innerRef}
        style={{
          pointerEvents: 'auto',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          width,
          overflowY: 'scroll',
          backgroundColor: '#fff',
          borderLeft: `1px solid ${dividerColor}`,
        }}
      >
        {children}
      </div>
    </Modal>
  );
};

const Toolbar = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        position: 'sticky',
        zIndex: 1000,
        top: 0,
        backgroundColor: '#fff',
        padding: 8,
        borderBottom: `1px solid ${dividerColor}`,
      }}
    >
      {children}
    </div>
  );
};

const useTextSelector = ({
  ignoreRef,
  onSelect,
}: {
  ignoreRef: { current: null | HTMLDivElement };
  onSelect: (text: string) => void;
}): [() => void, boolean] => {
  // an object to invalidate effect
  const [state, dispatch] = React.useReducer<
    React.Reducer<{ selecting: boolean }, boolean>
  >((_prev, selecting) => ({ selecting }), { selecting: false });
  const selectText = useStableCallback(onSelect);
  React.useEffect(() => {
    if (state.selecting && document.body != null) {
      const body = document.body;
      const unbind = () => {
        body.removeEventListener('click', handleClick, true);
        window.removeEventListener('keydown', handleKeyDown);
        dispatch(false);
      };
      const handleClick = (event: MouseEvent) => {
        if (
          event.target instanceof HTMLElement &&
          ignoreRef.current?.contains(event.target) === false
        ) {
          event.preventDefault();
          event.stopPropagation();
          unbind();
          let text = '';
          if (event.target instanceof HTMLElement) {
            text = event.target.textContent ?? '';
          }
          if (event.target instanceof HTMLInputElement) {
            text = event.target.placeholder;
          }
          selectText(text);
        }
      };
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          unbind();
        }
      };
      body.addEventListener('click', handleClick, true);
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        unbind();
      };
    }
  }, [selectText, state, ignoreRef]);
  const startSelecting = () => dispatch(true);
  return [startSelecting, state.selecting];
};

const LoadMoreIndicator = ({ loadMore }: { loadMore: () => void }) => {
  const targetRef = React.useRef<null | HTMLDivElement>(null);
  const stableLoadMore = useStableCallback(loadMore);
  const instanceRef = React.useRef<null | IntersectionObserver>(null);
  if (
    instanceRef.current == null &&
    typeof IntersectionObserver !== 'undefined'
  ) {
    instanceRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        stableLoadMore();
      }
    });
  }
  React.useEffect(() => {
    if (instanceRef.current && targetRef.current) {
      const instance = instanceRef.current;
      const element = targetRef.current;
      instance.observe(element);
      return () => {
        instance.unobserve(element);
      };
    }
  }, [targetRef]);
  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={targetRef}
        style={{
          position: 'absolute',
          // intersection offset
          top: -200,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
        }}
      />
    </div>
  );
};

type State = {
  renderedLanguage: string;
  renderedKeysCount: number;
  term: string;
};

type Action =
  | { type: 'invalidate' }
  | { type: 'set_language'; language: string }
  | { type: 'search'; term: string }
  | { type: 'load_more' };

type Dispatch = (action: Action) => void;

const initialRenderedKeysCount = 10;
const reduceTranslator = (state: State, action: Action): State => {
  if (action.type === 'invalidate') {
    return {
      ...state,
    };
  }
  if (action.type === 'set_language') {
    return {
      ...state,
      renderedLanguage: action.language,
    };
  }
  if (action.type === 'search') {
    return {
      ...state,
      renderedKeysCount: initialRenderedKeysCount,
      term: action.term,
    };
  }
  if (action.type === 'load_more') {
    return {
      ...state,
      renderedKeysCount: state.renderedKeysCount + 10,
    };
  }
  throw Error('invariant');
};

// inputs rerendering is not performant
// better to memoize them in big lists
const TranslatorCard = React.memo<{
  draft: TranslatorIndex;
  language: string;
  tkey: string;
  indexValue: string;
  draftValue: null | string;
  dispatch: Dispatch;
}>(({ draft, language, tkey: key, indexValue, draftValue, dispatch }) => {
  const value = draftValue ?? indexValue;
  const indexKey = _buildIndexKey(language, key);
  return (
    <div style={{ display: 'grid', padding: '16px 8px', gap: 8 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{key}</div>
        <IconButton
          style={{ visibility: draft.has(indexKey) ? 'visible' : 'hidden' }}
          size="small"
          onClick={() => {
            draft.delete(indexKey);
            dispatch({ type: 'invalidate' });
          }}
        >
          <span style={{ fontSize: 12 }}>❌</span>
        </IconButton>
      </div>
      <TextField
        variant="filled"
        fullWidth={true}
        multiline={true}
        size="small"
        label={language}
        // render magic value as empty string
        value={isEmptyValue(value) ? '' : value}
        onChange={event => {
          const newValue = event.target.value;
          if (newValue === indexValue) {
            draft.delete(indexKey);
          } else {
            draft.set(indexKey, { language, key, value: newValue });
          }
          dispatch({ type: 'invalidate' });
        }}
      />
    </div>
  );
});

const useStorage = (
  project: string,
  store: TranslatorStore,
  dispatch: Dispatch,
) => {
  const draftKey = `__translator_${project}_draft`;
  const initialized = React.useRef(false);
  React.useEffect(() => {
    const draft = store.draft;
    if (initialized.current === false) {
      initialized.current = true;
      // recover draft from storage
      const string = localStorage.getItem(draftKey);
      const data = string == null ? [] : JSON.parse(string);
      for (const { language, key, value } of data) {
        draft.set(_buildIndexKey(language, key), {
          language,
          key,
          value,
        });
      }
      dispatch({ type: 'invalidate' });
    } else {
      // save draft to storage
      localStorage.setItem(
        draftKey,
        JSON.stringify(Array.from(draft.values())),
      );
    }
  }, [draftKey, store, dispatch]);
};

export const TranslatorDevtool = (props: Props) => {
  const { store } = props;
  const draft = store.draft;
  const [state, dispatch] = React.useReducer(reduceTranslator, {
    renderedLanguage: store.currentLanguage,
    renderedKeysCount: initialRenderedKeysCount,
    term: '',
  });

  // sync store with local storage
  useStorage(props.project, store, dispatch);

  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [startSelecting, selecting] = useTextSelector({
    ignoreRef: containerRef,
    onSelect: text => {
      const keys = decode(text);
      const term = `:keys ${keys.join(' ')}`;
      dispatch({ type: 'search', term });
    },
  });

  React.useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [state.term]);

  const searchIndex = React.useMemo(
    () => indexValue(store.index, state.renderedLanguage),
    [store, state.renderedLanguage],
  );
  const matchedKeys = React.useMemo(
    () => searchTerm(searchIndex, state.term),
    [searchIndex, state.term],
  );
  const renderedKeys = matchedKeys.slice(0, state.renderedKeysCount);

  return (
    <Container innerRef={containerRef}>
      <Toolbar>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <Button
            style={selecting ? { borderColor: 'blue', color: 'blue' } : {}}
            variant="outlined"
            size="small"
            onClick={startSelecting}
          >
            Find
          </Button>
          <div style={{ cursor: 'help' }} title={help}>
            &nbsp;(?)
          </div>
          <div style={{ flexGrow: 1 }} />
          <ButtonGroup variant="outlined" size="small">
            <Button
              disabled={draft.size === 0}
              onClick={props.onStoreInvalidate}
            >
              Preview
            </Button>
            <Button
              disabled={draft.size === 0}
              onClick={() => {
                draft.clear();
                props.onStoreInvalidate();
              }}
            >
              Reset
            </Button>
            <Button
              disabled={draft.size === 0}
              onClick={() => {
                const updatedByKey = '__translator_updatedby';
                let updatedBy = localStorage.getItem(updatedByKey);
                if (updatedBy == null) {
                  updatedBy = prompt('Please enter your name');
                  if (updatedBy == null || updatedBy === '') {
                    return;
                  }
                  localStorage.setItem(updatedByKey, updatedBy);
                }
                const readyToPublish = confirm(
                  'Please make sure you made all necessary changes.\n' +
                    'Better publish translations in one batch.',
                );
                if (readyToPublish) {
                  props.onPublish(updatedBy);
                }
              }}
            >
              Publish ({draft.size})
            </Button>
          </ButtonGroup>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <TextField
            style={{ flexGrow: 1 }}
            label="Search..."
            InputProps={{
              endAdornment: state.term !== '' && (
                <IconButton
                  size="small"
                  onClick={() => dispatch({ type: 'search', term: '' })}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 24,
                      height: 24,
                      fontSize: 16,
                    }}
                  >
                    ❌
                  </span>
                </IconButton>
              ),
            }}
            variant="filled"
            size="small"
            value={state.term}
            onChange={event =>
              dispatch({ type: 'search', term: event.target.value })
            }
          />
          <TextField
            variant="filled"
            size="small"
            label="Lng"
            select={true}
            value={state.renderedLanguage}
            onChange={event => {
              dispatch({
                type: 'set_language',
                language: event.target.value as string,
              });
            }}
          >
            {searchIndex.availableLanguages.map(language => (
              <MenuItem key={language} value={language}>
                {language}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Toolbar>
      {renderedKeys.map((key, i) => {
        const index = store.index;
        const indexKey = _buildIndexKey(state.renderedLanguage, key);
        const indexItem = index.get(indexKey);
        const draftItem = draft.get(indexKey);
        return (
          <React.Fragment key={key}>
            <TranslatorCard
              draft={draft}
              language={state.renderedLanguage}
              tkey={key}
              // index value should always exist to not mess with empty value
              indexValue={indexItem?.value ?? ''}
              draftValue={draftItem?.value ?? null}
              dispatch={dispatch}
            />
            {i !== renderedKeys.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
      <LoadMoreIndicator loadMore={() => dispatch({ type: 'load_more' })} />
    </Container>
  );
};
