import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useTheme } from '../hooks/theme';

type Props = {
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  loading: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick: () => void;
  children: React.ReactNode;
  autoFocus?: boolean;
  component?: React.ElementType;
};

export const ProgressButton = ({
  color = 'primary',
  disabled = false,
  size = 'medium',
  variant = 'contained',
  loading,
  startIcon,
  endIcon,
  onClick,
  children,
  autoFocus,
  component,
}: Props) => {
  const theme = useTheme();
  let circleSize = 20;
  if (size === 'small') {
    circleSize = 18;
  }
  return (
    <Button
      css={[{ position: 'relative' }, theme.text.ellipsis]}
      color={color}
      disabled={loading || disabled === true}
      size={size}
      variant={variant}
      startIcon={startIcon}
      endIcon={
        loading && disabled !== true ? (
          <CircularProgress color={color} size={circleSize} disableShrink />
        ) : (
          endIcon
        )
      }
      onClick={onClick}
      autoFocus={autoFocus}
      component={component ?? 'div'}
    >
      {children}
    </Button>
  );
};
