import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import { gql } from '../../__generated__';
import type {
  Lead_Agent_Source_Enum_Enum,
  Leads_Relationship_Enum,
  Leads_Sale_Horizon_Enum,
} from '../../__generated__/graphql';
import { useAppData } from '../../providers/AppDataProvider';
import { AddUserModal } from '../AddUserModal';
import { type FormDefinitionType, RaForm } from '../form/RaForm';
import { LoadingSpinner } from '../LoadingSpinner';

export type ContactFormData = {
  contact_id: string;
  relationship: Leads_Relationship_Enum | null;
  sale_horizon: Leads_Sale_Horizon_Enum | null;
  source: Lead_Agent_Source_Enum_Enum | null;
};

const GET_SOURCES = gql(/* GraphQL */ `
  query GetSources {
    dictionaries(where: { type: { _eq: lead_source_types } }) {
      id
      name
      label
    }
  }
`);

type ContactStepProps = {
  data: ContactFormData;
  footerActionsComponent: React.ReactNode;
  onFormSubmitted: (data: Partial<ContactFormData>) => Promise<void>;
};

export const CreateLeadContactStep = ({
  data,
  footerActionsComponent,
  onFormSubmitted,
}: ContactStepProps) => {
  const { me } = useAppData();
  const [userCreationData, setUserCreationData] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
  } | null>(null);

  const { data: sourcesData, loading: sourcesLoading } = useQuery(GET_SOURCES, {
    skip: !me?.is_admin,
  });

  const sources = useMemo(() => {
    return (
      sourcesData?.dictionaries.map(source => ({
        value: source.name,
        label: source.label ?? source.name,
      })) ?? []
    );
  }, [sourcesData]);

  const [valuesOverride, setValuesOverride] = useState<
    Partial<ContactFormData>
  >({
    source: 'crm' as Lead_Agent_Source_Enum_Enum,
  });

  const formDefinition = useMemo<FormDefinitionType<ContactFormData>>(
    () =>
      ({ t }) =>
        [
          {
            type: 'user',
            name: 'contact_id',
            label: t('Contact'),
            required: true,
            gridProps: { xs: 12 },
            createUserSelected: userData => {
              const newUser: {
                firstName?: string;
                lastName?: string;
                email?: string;
              } = {};

              if (userData.first_name != null) {
                newUser.firstName = userData.first_name;
              }

              if (userData.last_name != null) {
                newUser.lastName = userData.last_name;
              }

              if (userData.email != null) {
                newUser.email = userData.email;
              }

              setUserCreationData(newUser);
            },
          },
          {
            type: 'select',
            name: 'relationship',
            label: t('Relationship'),
            gridProps: { xs: 12 },
            options: () => [
              { value: 'owner', label: t('Owner') },
              { value: 'tenant', label: t('Tenant renter') },
              { value: 'buyer', label: t('Buyer') },
              { value: 'heir', label: t('Heir') },
              { value: 'agent', label: t('Agent') },
              { value: 'other', label: t('Other') },
            ],
          },
          {
            type: 'select',
            name: 'sale_horizon',
            label: t('Sale horizon'),
            gridProps: { xs: 12 },
            options: () => [
              { value: 'not_set', label: t('Not set') },
              { value: 'not_selling', label: t('Not selling') },
              { value: 'already_for_sale', label: t('Already for sale') },
              { value: 'under_6_months', label: t('0-6 months') },
              { value: 'from_6_to_12_months', label: t('6-12 months') },
              { value: 'from_1_to_2_years', label: t('1-2 years') },
              { value: 'from_2_years', label: t('2 or more years') },
            ],
          },
          ...(me?.is_admin
            ? [
                {
                  type: 'select' as const,
                  name: 'source' as const,
                  label: t('Source'),
                  gridProps: { xs: 12 },
                  options: () => sources,
                },
              ]
            : []),
        ],
    [me?.is_admin, sources],
  );

  if (sourcesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <RaForm
        formDefinition={formDefinition}
        defaultValues={{ ...data, ...valuesOverride }}
        onSubmit={onFormSubmitted}
        actionButtonsComponent={footerActionsComponent}
      />

      <AddUserModal
        opened={userCreationData != null}
        user={userCreationData ?? undefined}
        onClose={user => {
          setUserCreationData(null);
          if (user != null) {
            setValuesOverride(prev => ({
              ...prev,
              contact_id: user.id,
            }));
          }
        }}
      />
    </>
  );
};

CreateLeadContactStep.displayName = 'CreateLeadContactStep';
