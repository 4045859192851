import { gql } from '../../__generated__';

export const GET_CMA_REPORTS_COUNT = gql(/* GraphQL */ `
  query GetCmaReportsCount($where: cma_reports_bool_exp) {
    cma_reports_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const UPDATE_STEP_SETTINGS = gql(/* GraphQL */ `
  mutation UpdateStepSettings($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepSettings
    }
  }
`);

export const STEP_SETTINGS_FRAGMENT = gql(/* GraphQL */ `
  fragment StepSettings on cma_reports {
    id
    language
    font_family
    doc_template {
      id
    }
    updated_at
  }
`);

export const STEP_COVER_PHOTO_FRAGMENT = gql(/* GraphQL */ `
  fragment StepCoverPhoto on cma_reports {
    id
    report_title
    updated_at
    contact {
      ...UserInfo_user
    }
    broker {
      ...UserInfo_user
      show_in_agency_pages
      default_team {
        show_in_agency_pages
      }
    }
    cover_image {
      id
      url
    }
    lead {
      id
      property {
        id
        updated_at
        lat
        lng
        property_images(
          where: { display_on_brochure: { _eq: true } }
          order_by: { order_nr: desc }
        ) {
          id
          image {
            id
            url
          }
        }
      }
    }
  }
`);

export const STEP_HEDONISTIC_VALUATION_FRAGMENT = gql(/* GraphQL */ `
  fragment StepHedonisticValuation on cma_reports {
    id
    updated_at
    include_hedonistic_valuation
    lead {
      id
      property {
        id
        updated_at
        latest_appraisal {
          id
          updated_at
        }
        appraisals(order_by: { created_at: asc_nulls_last }) {
          id
        }
      }
    }
  }
`);

export const CMA_REPORT_FILE_FRAGMENT = gql(/* GraphQL */ `
  fragment CmaReportFile on cma_report_files {
    cma_report_id
    file_id
    is_visible
    order_nr
    file {
      id
      name
      url
      mimetype
      created_at
    }
  }
`);

export const CMA_PROPERTY_FILE_FRAGMENT = gql(/* GraphQL */ `
  fragment CmaPropertyFile on property_files {
    property_id
    file_id
    order_nr
    file {
      id
      name
      url
      mimetype
      created_at
    }
  }
`);

export const STEP_DESCRIPTION_FRAGMENT = gql(/* GraphQL */ `
  fragment StepDescription on cma_reports {
    id
    updated_at
    include_description
    description
  }
`);

export const UPDATE_STEP_PHOTOS = gql(/* GraphQL */ `
  mutation UpdateStepPhotos($id: uuid!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }) {
      id
      updated_at
    }
  }
`);

export const UPDATE_DESCRIPTION = gql(/* GraphQL */ `
  mutation UpdateDescription($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepDescription
    }
  }
`);

export const STEP_APPEND_FILES_FRAGMENT = gql(/* GraphQL */ `
  fragment StepAppendFiles on cma_reports {
    id
    cma_report_files(order_by: { order_nr: desc }) {
      cma_report_id
      file_id
      ...CmaReportFile
    }
    lead {
      id
      property {
        id
        property_files(order_by: { order_nr: desc }) {
          property_id
          file_id
          ...CmaPropertyFile
        }
      }
    }
  }
`);

export const CMA_REPORT_EDITOR_FRAGMENT = gql(/* GraphQL */ `
  fragment CMAReportEditor on cma_reports {
    id
    updated_at
    language
    lead {
      id
      property {
        id
        updated_at
        street_number
        route
        postcode
        locality
        updated_at
      }
    }

    doc_template {
      id
      content
      filename_template
      translations
    }
  }
`);

export const STEP_INTRODUCTION_FRAGMENT = gql(/* GraphQL */ `
  fragment StepIntroduction on cma_reports {
    id
    updated_at
    introduction
  }
`);

export const STEP_PROPERTY_DETAILS_FRAGMENT = gql(/* GraphQL */ `
  fragment StepPropertyDetails on cma_reports {
    id
    updated_at
    lead {
      id
      property {
        id
        updated_at
      }
    }
  }
`);

export const STEP_FINAL_FRAGMENT = gql(/* GraphQL */ `
  fragment StepFinal on cma_reports {
    id
    updated_at
    positive_comments
    negative_comments
    suggested_market_value
    is_price_range
    price_range_min
    price_range_max
    show_probability
    probability_min
    probability_max
    probability_time_period
  }
`);

export const STEP_POTENTIAL_BUYERS_FRAGMENT = gql(/* GraphQL */ `
  fragment StepPotentialBuyers on cma_reports {
    id
    updated_at
    max_budget
    min_budget
    include_potential_buyers
    suggested_market_value
    lead {
      id
      property {
        id
        latest_appraisal {
          id
          min
          max
        }
      }
    }
  }
`);

export const DELETE_CMA_REPORT = gql(/* GraphQL */ `
  mutation DeleteCmaReport($id: uuid!) {
    delete_cma_reports_by_pk(id: $id) {
      id
    }
  }
`);

export const DOC_TEMPLATES_CMA_REPORT = gql(/* GraphQL */ `
  query DocTemplatesCmaReport {
    doc_templates(where: { type: { _eq: cma_report }, active: { _eq: true } }) {
      id
      name
      active
      doc_templates_tenants(order_by: { is_default: asc_nulls_last }) {
        tenant_id
        is_default
      }
    }
  }
`);

export const GET_CMA_REPORT = gql(/* GraphQL */ `
  query GetCmaReport($id: uuid!) {
    cma_reports_by_pk(id: $id) {
      ...CMAReportEditor
      ...StepIntroduction
      ...StepCoverPhoto
      ...StepPropertyDetails
      ...StepDescription
      ...StepHedonisticValuation
      ...StepListingsCompare
      ...StepCapRateValuation
      ...StepIntrinsicValuation
      ...StepFinal
      ...StepPotentialBuyers
      ...StepAppendFiles
      ...StepSettings
      ...CmaReportInclude
      lead {
        id
        property {
          id
        }
      }
    }
    property_types {
      id
      label
      name
    }
  }
`);

export const GET_PROPERTY_APPRAISABLE = gql(/* GraphQL */ `
  query GetPropertyAppraisable($propertyId: uuid!) {
    property_appraisable(property_id: $propertyId) {
      appraisable {
        result
        errors {
          path
        }
      }
    }
  }
`);

export const UPDATE_CMA_REPORT_DOC_TEMPLATE = gql(/* GraphQL */ `
  mutation UpdateCmaReportDocTemplate(
    $id: uuid!
    $input: cma_reports_set_input!
  ) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      updated_at
      doc_template {
        id
        name
        type
      }
    }
  }
`);

export const UPDATE_CMA_COVER = gql(/* GraphQL */ `
  mutation UpdateCmaStepCover($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepCoverPhoto
    }
  }
`);

export const STEP_COVER_INSERT_PROPERTY_IMAGE = gql(/* GraphQL */ `
  mutation StepCoverInsertPropertyImage(
    $object: property_images_insert_input!
  ) {
    insert_property_images_one(object: $object) {
      id
      image {
        id
        url
      }
    }
  }
`);

export const UPDATE_CMA_INTRODUCTION = gql(/* GraphQL */ `
  mutation UpdateCmaIntroduction($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepIntroduction
    }
  }
`);

export const UPDATE_PROPERTY_DETAILS = gql(/* GraphQL */ `
  mutation UpdatePropertyDetails($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepPropertyDetails
    }
  }
`);

export const UPDATE_HEDONISTIC_VALUATION = gql(/* GraphQL */ `
  mutation UpdateHedonisticValuation(
    $id: uuid!
    $input: cma_reports_set_input!
  ) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepHedonisticValuation
    }
  }
`);

export const UPDATE_FINAL = gql(/* GraphQL */ `
  mutation UpdateFinal($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepFinal
    }
  }
`);

export const UPDATE_POTENTIAL_BUYERS = gql(/* GraphQL */ `
  mutation UpdatePotentialBuyers($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepPotentialBuyers
    }
  }
`);

export const STEP_ADD_CMA_REPORT_FILE = gql(/* GraphQL */ `
  mutation StepAddCmaReportFile(
    $object: cma_report_files_insert_input!
    $cma_report_id: uuid!
  ) {
    insert_cma_report_files_one(object: $object) {
      cma_report_id
      file_id
      cma_report {
        id
        cma_report_files(order_by: { order_nr: desc }) {
          cma_report_id
          file_id
          ...CmaReportFile
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }) {
      id
      updated_at
    }
  }
`);

export const STEP_DELETE_CMA_REPORT_FILE = gql(/* GraphQL */ `
  mutation StepDeleteCmaReportFile($file_id: uuid!, $cma_report_id: uuid!) {
    delete_cma_report_files(
      where: {
        file_id: { _eq: $file_id }
        cma_report_id: { _eq: $cma_report_id }
      }
    ) {
      returning {
        cma_report_id
        file_id
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }) {
      id
      updated_at
    }
  }
`);

export const STEP_UPDATE_CMA_REPORT_FILE = gql(/* GraphQL */ `
  mutation StepUpdateCmaReportFile(
    $input: cma_report_files_set_input!
    $file_id: uuid!
    $cma_report_id: uuid!
  ) {
    update_cma_report_files(
      where: {
        file_id: { _eq: $file_id }
        cma_report_id: { _eq: $cma_report_id }
      }
      _set: $input
    ) {
      returning {
        cma_report_id
        file_id
        cma_report {
          id
          cma_report_files(order_by: { order_nr: desc }) {
            cma_report_id
            file_id
            ...CmaReportFile
          }
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const STEP_UPDATE_CMA_REPORT_FILE_VISIBILITY = gql(/* GraphQL */ `
  mutation StepUpdateCmaReportFileVisibility(
    $file_id: uuid!
    $cma_report_id: uuid!
    $is_visible: Boolean!
  ) {
    update_cma_report_files(
      where: {
        file_id: { _eq: $file_id }
        cma_report_id: { _eq: $cma_report_id }
      }
      _set: { is_visible: $is_visible }
    ) {
      returning {
        cma_report_id
        file_id
        is_visible
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const STEP_APPEND_ADD_PROPERTY_FILE = gql(/* GraphQL */ `
  mutation StepAppendAddPropertyFile($object: property_files_insert_input!) {
    insert_property_files_one(object: $object) {
      property_id
      file_id
      property {
        id
        property_files(order_by: { order_nr: desc }) {
          property_id
          file_id
          ...CmaPropertyFile
        }
      }
    }
  }
`);

export const STEP_LISTINGS_COMPARE_FRAGMENT = gql(/* GraphQL */ `
  fragment StepListingsCompare on cma_reports {
    id
    include_comparables_valuation
    comparable_listings(order_by: { order_nr: asc }) {
      cma_report_id
      order_nr
      aggregates_db__merged_listings_clusters_id
    }
    lead {
      id
      property {
        id
        number_of_rooms
        number_of_bedrooms
        living_surface
        built_surface
        usable_surface
        land_surface
        locality_place {
          id
          label
        }
        state
        lat
        lng
        latest_appraisal {
          value
        }
        property_type {
          main_type
          name
        }
      }
    }
    updated_at
  }
`);

export const STEP_LISTINGS_COMPARE_LISTINGS = gql(/* GraphQL */ `
  query StepListingsCompareListings(
    $ids: [Int!]!
    $ch: Boolean!
    $es: Boolean!
    $fr: Boolean!
    $it: Boolean!
  ) {
    ch_cma_listings(where: { id: { _in: $ids } }) @include(if: $ch) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      places {
        id
        label
      }
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }

    fr_cma_listings(where: { id: { _in: $ids } }) @include(if: $fr) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      places {
        id
        label
      }
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }
    es_cma_listings(where: { id: { _in: $ids } }) @include(if: $es) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      places {
        id
        label
      }
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }
    it_cma_listings(where: { id: { _in: $ids } }) @include(if: $it) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      places {
        id
        label
      }
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }
  }
`);

export const STEP_LISTINGS_COMPARE_ALGO = gql(/* GraphQL */ `
  query StepListingsCompareAlgo(
    $built_surface: Float
    $lat: Float
    $living_surface: Float
    $lng: Float
    $number_of_rooms: Int
    $property_main_type: String
    $sale_price: Float
    $usable_surface: Float
    $ch: Boolean!
    $es: Boolean!
    $fr: Boolean!
    $it: Boolean!
  ) {
    ch_get_similar_listings_vector(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $ch) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }

    es_get_similar_listings_vector(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $es) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }

    fr_get_similar_listings_vector(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $fr) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }

    it_get_similar_listings_vector(
      args: {
        _built_surface: $built_surface
        _lat: $lat
        _living_surface: $living_surface
        _lng: $lng
        _number_of_rooms: $number_of_rooms
        _property_main_type: $property_main_type
        _sale_price: $sale_price
        _usable_surface: $usable_surface
      }
      limit: 10
    ) @include(if: $it) {
      id
      number_of_rooms
      computed_surface
      land_surface
      sale_price
      computed_price_per_sqm
      offline_since
      created_at
      country_code
      route
      street_number
      postcode
      locality
      state_id
      property_type
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
      valid_from
    }
  }
`);

export const UPDATE_COMPARABLES_LIST = gql(/* GraphQL */ `
  mutation UpdateComparablesList($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      include_comparables_valuation
      updated_at
    }
  }
`);

export const INSERT_CMA_REPORTS_COMPARABLES = gql(/* GraphQL */ `
  mutation InsertCmaReportsComparables(
    $id: uuid!
    $objects: [cma_reports_comparables_insert_input!]!
  ) {
    delete_cma_reports_comparables(where: { cma_report_id: { _eq: $id } }) {
      __typename
    }

    insert_cma_reports_comparables(objects: $objects) {
      returning {
        aggregates_db__merged_listings_clusters_id
        cma_report_id
        order_nr
      }
    }
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const UPDATE_CMA_REPORTS_COMPARABLES = gql(/* GraphQL */ `
  mutation UpdateCmaReportsComparables(
    $cma_report_id: uuid!
    $listing_id: bigint!
    $order_nr: Float!
  ) {
    update_cma_reports_comparables(
      where: {
        cma_report_id: { _eq: $cma_report_id }
        aggregates_db__merged_listings_clusters_id: { _eq: $listing_id }
      }
      _set: { order_nr: $order_nr }
    ) {
      returning {
        cma_report {
          comparable_listings(order_by: { order_nr: asc }) {
            order_nr
            cma_report_id
            aggregates_db__merged_listings_clusters_id
          }
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
  }
`);

export const DELETE_CMA_REPORTS_COMPARABLES = gql(/* GraphQL */ `
  mutation DeleteCmaReportsComparables(
    $cma_report_id: uuid!
    $listing_id: bigint!
  ) {
    delete_cma_reports_comparables(
      where: {
        cma_report_id: { _eq: $cma_report_id }
        aggregates_db__merged_listings_clusters_id: { _eq: $listing_id }
      }
    ) {
      returning {
        cma_report {
          comparable_listings(order_by: { order_nr: asc }) {
            order_nr
            cma_report_id
            aggregates_db__merged_listings_clusters_id
          }
        }
      }
    }

    update_cma_reports_by_pk(pk_columns: { id: $cma_report_id }, _set: {}) {
      id
      updated_at
    }
  }
`);

// The primary key (PK) for cma_listings consists of `id` and `state_id`.
// This is because we use partitioning by `state_id` to significantly improve performance.
// @kirillvakalov
export const COMPARABLE_CMA_LISTING_DETAILS = gql(/* GraphQL */ `
  query ComparableCmaListingDetails(
    $id: Int!
    $stateId: uuid!
    $isCh: Boolean!
    $isFr: Boolean!
    $isEs: Boolean!
    $isIt: Boolean!
  ) {
    ch_cma_listings_by_pk(id: $id, state_id: $stateId) @include(if: $isCh) {
      id
      title
      address
      offline_since
      valid_from
      description
      offline_since
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      offline_since
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      images(limit: 10, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
    }
    fr_cma_listings_by_pk(id: $id, state_id: $stateId) @include(if: $isFr) {
      id
      title
      address
      offline_since
      valid_from
      description
      offline_since
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      offline_since
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      images(limit: 10, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
    }
    es_cma_listings_by_pk(id: $id, state_id: $stateId) @include(if: $isEs) {
      id
      title
      address
      offline_since
      valid_from
      description
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      images(limit: 10, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
    }
    it_cma_listings_by_pk(id: $id, state_id: $stateId) @include(if: $isIt) {
      id
      title
      address
      offline_since
      valid_from
      description
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      offer_type
      sale_price
      price_unit
      rent_net
      rent_extra
      currency
      country_code
      living_surface
      built_surface
      computed_price_per_sqm
      property_type
      floor_of_flat
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      construction_year
      property_main_type
      description
      agency_name
      agency_contact_address
      agency_contact_person
      url
      has_view
      has_cellar
      has_elevator
      has_fireplace
      has_storage_room
      has_swimming_pool
      is_child_friendly
      is_wheelchair_accessible
      images(limit: 10, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
      created_at
    }
  }
`);

export const COMPARABLE_CMA_LISTINGS = gql(/* GraphQL */ `
  query ComparableCmaListings(
    $page: Int
    $pageSize: Int
    $chWhere: ch_cma_listings_bool_exp
    $frWhere: fr_cma_listings_bool_exp
    $esWhere: es_cma_listings_bool_exp
    $itWhere: it_cma_listings_bool_exp
    $isCh: Boolean!
    $isEs: Boolean!
    $isFr: Boolean!
    $isIt: Boolean!
  ) {
    ch_cma_listings(limit: $pageSize, offset: $page, where: $chWhere)
      @include(if: $isCh) {
      id
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      computed_price_per_sqm
      property_type
      offline_since
      valid_from
      state_id
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
    }
    fr_cma_listings(limit: $pageSize, offset: $page, where: $frWhere)
      @include(if: $isFr) {
      id
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      computed_price_per_sqm
      property_type
      offline_since
      valid_from
      state_id
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
    }
    es_cma_listings(limit: $pageSize, offset: $page, where: $esWhere)
      @include(if: $isEs) {
      id
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      computed_price_per_sqm
      property_type
      offline_since
      valid_from
      state_id
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
    }
    it_cma_listings(limit: $pageSize, offset: $page, where: $itWhere)
      @include(if: $isIt) {
      id
      sale_price
      number_of_rooms
      computed_surface
      land_surface
      route
      street_number
      postcode
      locality
      computed_price_per_sqm
      property_type
      offline_since
      valid_from
      state_id
      images(limit: 1, order_by: [{ image_index: asc_nulls_last }]) {
        id
        file_name
        bucket_name
      }
    }
  }
`);

export const COMPARABLE_CMA_LISTINGS_COUNT = gql(/* GraphQL */ `
  query ComparableCmaListingsCount(
    $chWhere: ch_cma_listings_bool_exp
    $frWhere: fr_cma_listings_bool_exp
    $esWhere: es_cma_listings_bool_exp
    $itWhere: it_cma_listings_bool_exp
    $isCh: Boolean!
    $isEs: Boolean!
    $isFr: Boolean!
    $isIt: Boolean!
  ) {
    ch_cma_listings_aggregate(where: $chWhere) @include(if: $isCh) {
      aggregate {
        count
      }
    }
    fr_cma_listings_aggregate(where: $frWhere) @include(if: $isFr) {
      aggregate {
        count
      }
    }
    es_cma_listings_aggregate(where: $esWhere) @include(if: $isEs) {
      aggregate {
        count
      }
    }
    it_cma_listings_aggregate(where: $itWhere) @include(if: $isIt) {
      aggregate {
        count
      }
    }
  }
`);

export const CREATE_CMA_REPORT = gql(/* GraphQL */ `
  mutation CreateCmaReport($input: cma_reports_insert_input!) {
    insert_cma_reports_one(
      object: $input
      on_conflict: { constraint: cma_reports_pkey, update_columns: [] }
    ) {
      id
    }
  }
`);

export const CMA_REPORT_INCLUDE_FRAGMENT = gql(/* GraphQL */ `
  fragment CmaReportInclude on cma_reports {
    id
    include_cover
    include_introduction
    include_property
    include_description
    include_photos
    include_hedonistic_valuation
    include_comparables_valuation
    include_cuprate_valuation
    include_intrinsic_valuation
    include_final_value
    include_potential_buyers
    include_agent
    include_agent_agency
    include_client_testimonial
    include_append_files
  }
`);

export const UPDATE_CMA_REPORT_STEP_VISIBILITY = gql(/* GraphQL */ `
  mutation UpdateCmaReportStepVisibility(
    $id: uuid!
    $input: cma_reports_set_input!
  ) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      ...CmaReportInclude
      updated_at
    }
  }
`);

export const STEP_CAP_RATE_VALUATION_FRAGMENT = gql(/* GraphQL */ `
  fragment StepCapRateValuation on cma_reports {
    id
    updated_at
    caprate_estimated_monthly_rent
    caprate_minimum_required_yield
    lead {
      id
      property {
        id
        latest_appraisal {
          id
          value
          rent_value
        }
      }
    }
  }
`);

export const STEP_INTRINSIC_VALUATION_FRAGMENT = gql(/* GraphQL */ `
  fragment StepIntrinsicValuation on cma_reports {
    id
    updated_at
    construction_cost_base
    intrinsic_building_surface
    intrinsic_construction_cost
    intrinsic_annual_deprecation_rate
    intrinsic_land_value
    intrinsic_custom_fields
    lead {
      id
      property {
        id
        building_volume
        construction_year
        renovation_year
        land_surface
        living_surface
        usable_surface
        basement_surface
        commercial_surface
        balcony_surface
        garden_surface
        gross_floor_surface
        residential_surface
        terrace_surface
        weighted_floor_surface
      }
    }
  }
`);

export const UPDATE_CAP_RATE_VALUATION = gql(/* GraphQL */ `
  mutation UpdateCapRateValuation($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepCapRateValuation
    }
  }
`);

export const UPDATE_INTRINSIC_VALUATION = gql(/* GraphQL */ `
  mutation UpdateIntrinsicValuation(
    $id: uuid!
    $input: cma_reports_set_input!
  ) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepIntrinsicValuation
    }
  }
`);
