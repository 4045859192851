import { useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Box, CircularProgress, Stack } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import {
  type GetLeadsQuery,
  type GetLeadsQueryVariables,
  Leads_Status_Enum,
  Order_By,
} from '../../__generated__/graphql';
import { CreateListingModal } from '../../components/create-listing/CreateListingModal';
import type { IndicatorStatus } from '../../components/IndicatorLeadStage';
import { KanbanBoard } from '../../components/kanban/KanbanBoard';

import DialogUpdatePipeline from './DialogUpdatePipeline';
import { useUpdateLeadsStageInCache } from './kanbanCache';
import LeadsKanbanColumn from './LeadsKanbanColumn';
import { GET_PIPELINE_STAGES, UPDATE_LEAD } from './leadsQueries';

export type Lead = NonNullable<GetLeadsQuery['leads'][number]>;

export type MenuAction = {
  type: 'stage';
};

export const PAGE_SIZE = 20;

type LeadsKanbanViewProps = {
  isLegacyMode: boolean;
  pipelineId: string | null;
  where: GetLeadsQueryVariables['where'];
  selectedRows: GridRowSelectionModel;
  setSelectedRows: (rows: string[]) => void;
};

export const buildQueryVars = (
  where: GetLeadsQueryVariables['where'],
  stageIdToUse: string,
) => ({
  where: {
    _and: [where ?? {}, { stage_id: { _eq: stageIdToUse } }],
  },
  limit: PAGE_SIZE,
  offset: 0,
  order_by: [{ requalified_or_created_at: Order_By.DescNullsLast }],
});

export const LeadsKanbanView = ({
  isLegacyMode,
  pipelineId,
  where,
  selectedRows,
  setSelectedRows,
}: LeadsKanbanViewProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const updateLeadsStage = useUpdateLeadsStageInCache();

  const [pipelineDialog, setPipelineDialog] = useState<{
    opened: boolean;
    leadId?: string;
  }>({ opened: false });

  const [createListingState, setCreateListingState] = useState<{
    open: boolean;
    fromLeadId: string;
    targetStageId: string;
  }>({ open: false, fromLeadId: '', targetStageId: '' });

  const { data: stagesData, loading: stagesLoading } = useQuery(
    GET_PIPELINE_STAGES,
    {
      variables: { pipeline_id: pipelineId ?? '' },
      skip: pipelineId == null,
    },
  );

  const stages = useMemo(
    () => stagesData?.pipelines_by_pk?.stages ?? [],
    [stagesData?.pipelines_by_pk?.stages],
  );

  const leadLink = useCallback(
    (id: string) =>
      isLegacyMode
        ? {
            pathname: `/leads/${toGlobalId('Lead', id)}`,
            search: searchParams.toString(),
          }
        : {
            pathname: `/v2/leads/${id}`,
            search: searchParams.toString(),
          },
    [isLegacyMode, searchParams],
  );

  const handleLeadUpdate = useCallback((leadId: string, action: MenuAction) => {
    if (action.type === 'stage') {
      setPipelineDialog({
        opened: true,
        leadId,
      });
    }
  }, []);

  const [columnItems, setColumnItems] = useState<Record<string, Lead[]>>({});

  const updateColumnItems = useCallback((columnId: string, items: Lead[]) => {
    setColumnItems(prev => {
      if (JSON.stringify(prev[columnId]) === JSON.stringify(items)) {
        return prev;
      }
      return {
        ...prev,
        [columnId]: items,
      };
    });
  }, []);

  // Get all leads across all columns
  const allLeads = useMemo(
    () => Object.values(columnItems).flat(),
    [columnItems],
  );

  const [updateLead] = useMutation(UPDATE_LEAD);

  const columns = useMemo(() => {
    if (!stages.length) {
      return [];
    }

    return stages.map(stage => ({
      id: stage.id,
      data: {
        id: stage.id,
        label: stage.label ?? '',
        status: stage.status,
      },
      extractItems: (items: Lead[]) =>
        items.filter(item => item.stage?.id === stage.id),
      render: ({
        column,
        index,
      }: {
        column: { data: { label: string; id: string; status: string } };
        index: number;
      }) => (
        <LeadsKanbanColumn
          where={where}
          columnId={column.data.id}
          label={column.data.label}
          status={column.data.status as IndicatorStatus}
          onItemClick={(lead: Lead) => navigate(leadLink(lead.id))}
          index={index}
          onLeadUpdate={handleLeadUpdate}
          onItemsChange={items => {
            updateColumnItems(column.data.id, items);
          }}
        />
      ),
    }));
  }, [stages, where, handleLeadUpdate, navigate, leadLink, updateColumnItems]);

  const onLeadMove = (
    leadId: string,
    stageId: string,
    destinationIndex: number,
  ) => {
    const item = allLeads.find(lead => lead.id === leadId);
    if (!item) {
      return;
    }

    // Find the target stage
    const targetStage = stages.find(stage => stage.id === stageId);
    if (!targetStage) {
      return;
    }

    // If the lead has no lots and we're moving to a won stage, open the modal and skip the update
    if (
      item.lots?.length === 0 &&
      targetStage.status === Leads_Status_Enum.Won
    ) {
      setCreateListingState({
        open: true,
        fromLeadId: leadId,
        targetStageId: stageId,
      });
      return;
    }

    const changedStage = item.stage?.id !== stageId;

    updateLeadsStage({
      stageId,
      leadIds: [leadId],
      where,
      stagesData,
      isKanbanView: true,
      destinationIndex,
    });

    if (changedStage) {
      updateLead({
        variables: {
          id: leadId,
          set: {
            stage_id: stageId,
          },
        },
      });
    }
  };

  if (stagesLoading) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center" p={3}>
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, height: 0 }}>
      <KanbanBoard
        items={allLeads}
        selectedItems={selectedRows as string[]}
        onItemsSelected={setSelectedRows}
        columns={columns}
        getItemId={(item: Lead) => item?.id ?? ''}
        onItemMoved={(item, columnId, destinationIndex) => {
          if (item?.id) {
            onLeadMove(item.id, columnId, destinationIndex);
          }
        }}
      />

      <DialogUpdatePipeline
        leadsIds={pipelineDialog.leadId ? [pipelineDialog.leadId] : []}
        pipeline="sales"
        initialPipelineId={pipelineId ?? ''}
        open={pipelineDialog.opened}
        onCancel={() => setPipelineDialog({ opened: false })}
        updateCacheFn={({ stageId, leadIds }) =>
          updateLeadsStage({
            stageId,
            leadIds,
            where,
            stagesData,
            isKanbanView: true,
          })
        }
      />

      <CreateListingModal
        open={createListingState.open}
        onClose={() =>
          setCreateListingState({
            open: false,
            fromLeadId: '',
            targetStageId: '',
          })
        }
        fromLeadId={createListingState.fromLeadId}
        onListingCreated={async listingId => {
          // Update the lead's stage first
          if (createListingState.targetStageId) {
            await updateLead({
              variables: {
                id: createListingState.fromLeadId,
                set: {
                  stage_id: createListingState.targetStageId,
                },
              },
            });
          }
          // Then close modal and navigate
          setCreateListingState({
            open: false,
            fromLeadId: '',
            targetStageId: '',
          });
          navigate(`/listings/${listingId}`);
        }}
      />
    </Box>
  );
};

export default LeadsKanbanView;
