import { FormControl, FormHelperText } from '@mui/material';
import type { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { OrganisationSelect } from '../organisation-select/OrganisationSelect';

import { RaLabel } from './RaLabel';

export type RaOrganisationProps = {
  errors: any;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  label: string;
};

export const RaOrganisation = ({
  getValues,
  setValue,
  errors,
  name,
  label,
}: RaOrganisationProps) => {
  const organisationId = getValues(name);
  return (
    <FormControl fullWidth error={!!errors[name]}>
      <RaLabel label={label} />
      <OrganisationSelect
        organisationId={organisationId}
        onChange={organisationId =>
          setValue(name, organisationId, { shouldDirty: true })
        }
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
};
