// @flow

import * as React from 'react';

import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { graphql, useFragment } from 'react-relay';
import { Box } from 'react-system';

import { Menu } from '../controls/popup';
import { useLocale } from '../hooks/locale';
import { MoreVert } from '../icons/more-vert';

import type { userEditableCard_user$key } from './__generated__/userEditableCard_user.graphql';
import { type User, UserInput, type UserSearchFilters } from './user-input';
import { UserSectionInput } from './user-section-input';
import { UserSummary } from './user-summary';

export type { UserSearchFilters };

type Props = {|
  user: ?userEditableCard_user$key,
  prefill?: ?userEditableCard_user$key,
  menuItems?: React.Node,
  label?: string,
  error?: boolean,
  required?: boolean,
  disabled?: boolean,
  helperText?: React.Node,
  isCreatable?: boolean,
  showBroker?: boolean,
  showDelete?: boolean,
  menuButtonTitle?: string,
  deleteButtonTitle?: string,
  filters?: UserSearchFilters,
  sections?: $ReadOnlyArray<{|
    title: string,
    filter: any => boolean,
    sort?: (a: any, b: any) => number,
    notFoundMessage?: string,
  |}>,
  summaryChildren?: React.Node,
  onChange: (?User, ?boolean) => void,
  onBlur?: () => void,
  autoFocus?: boolean,
  showSubscriptionStatus?: boolean,
|};

export const UserEditableCard = (props: Props): React.Node => {
  const userFragment = graphql`
    fragment userEditableCard_user on User {
      ...userSummary_user
      ...userInput_user @relay(mask: false)
    }
  `;

  const user = useFragment(userFragment, props.user);
  const initialPrefill = useFragment(userFragment, props.prefill);
  const { t } = useLocale();
  const menuRef = React.useRef(null);
  const [menu, setMenu] = React.useState(false);

  const [{ prefill, editing }, dispatch] = React.useReducer(
    (state, action) => {
      switch (action) {
        case 'assign':
          return { prefill: state.prefill, editing: false };
        case 'edit':
          return { prefill: null, editing: true };
        case 'change':
          return { prefill: null, editing: false };
        default:
          throw Error('not expected action');
      }
    },
    { prefill: user == null ? initialPrefill : null, editing: false },
  );

  return (
    <div css={{ width: '100%', position: 'relative' }}>
      {editing || user == null ? (
        <Box p={3}>
          <FormControl
            variant="filled"
            error={props.error}
            required={props.required}
          >
            <InputLabel>{props.label ?? t('selectUser')}</InputLabel>
            {props.sections != null ? (
              <UserSectionInput
                showSubscriptionStatus={props.showSubscriptionStatus}
                sections={props.sections}
                creatable={props.isCreatable ?? false}
                clearable={props.showDelete ?? true}
                autoFocus={props.autoFocus === true || user != null}
                focusOnDelete={true}
                filters={props.filters}
                value={editing ? null : user ?? prefill}
                onChange={newUser => {
                  if (newUser == null) {
                    dispatch('edit');
                  } else {
                    dispatch('change');
                  }

                  if (user != null && newUser == null) {
                    props.onChange(newUser, true);
                  } else {
                    props.onChange(newUser);
                  }
                }}
              />
            ) : (
              <UserInput
                creatable={props.isCreatable ?? false}
                clearable={props.showDelete ?? true}
                autoFocus={props.autoFocus === true || user != null}
                focusOnDelete={true}
                filters={props.filters}
                value={editing ? null : user ?? prefill}
                onChange={newUser => {
                  if (newUser == null) {
                    dispatch('edit');
                  } else {
                    dispatch('change');
                  }
                  if (user != null && newUser == null) {
                    props.onChange(newUser, true);
                  } else {
                    props.onChange(newUser);
                  }
                }}
              />
            )}
            {editing && (
              <Button
                color="inherit"
                css={{ marginTop: '16px', alignSelf: 'flex-start' }}
                onClick={() => {
                  dispatch('change');
                }}
              >
                {t('Cancel')}
              </Button>
            )}
            {props.helperText != null && (
              <FormHelperText>{props.helperText}</FormHelperText>
            )}
          </FormControl>
          {prefill && (
            <Box pt={3}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch('assign');
                  props.onChange(prefill);
                }}
              >
                {t('assign')}
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <UserSummary
          user={user}
          showBroker={props.showBroker}
          showTitle={props.showBroker}
          secondaryAction={
            props.disabled !== true && (
              <>
                <IconButton
                  ref={menuRef}
                  edge="end"
                  title={props.menuButtonTitle}
                  onClick={() => setMenu(v => !v)}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  referenceRef={menuRef}
                  open={menu}
                  onClose={() => setMenu(false)}
                >
                  {props.menuItems ?? null}
                  <MenuItem onClick={() => dispatch('edit')}>
                    {t('Edit')}
                  </MenuItem>
                  {(props.showDelete ?? true) && (
                    <MenuItem
                      title={props.deleteButtonTitle}
                      onClick={() => {
                        dispatch('change');
                        props.onChange(null);
                      }}
                    >
                      {t('remove')}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )
          }
        >
          {props.summaryChildren}
        </UserSummary>
      )}
    </div>
  );
};
