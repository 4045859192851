import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import type { ListingPriceFragment } from '../__generated__/graphql';
import { getListingPrices } from '../utils/propertyTemplateFunctions';

interface ListingPriceProps {
  listing?: ListingPriceFragment | null;
  isLoading?: boolean;
}

export const LISTING_PRICE_FRAGMENT = gql(/* GraphQL */ `
  fragment ListingPrice on lots {
    offer_type
    sale_price
    price_unit
    rent_net
    rent_extra
    currency
    hide_price_on_portals
    property {
      id
      country_code
      computed_surface
      latest_appraisal {
        id
        value
        price_per_living_surface_meter_squared
      }
    }
  }
`);

export const ListingPriceSkeleton = () => {
  return (
    <Box>
      <Typography variant="h5">
        <Skeleton width={100} />
      </Typography>

      <Typography variant="body1" mt={'-4px'}>
        <Skeleton width={150} />
      </Typography>
    </Box>
  );
};

export const ListingPrice: React.FC<ListingPriceProps> = ({
  listing,
  isLoading = false,
}) => {
  const theme = useTheme();
  const { t, locale } = useLocale();

  if (isLoading || listing == null) {
    return <ListingPriceSkeleton />;
  }

  const prices = getListingPrices(listing, t, locale);
  const primaryPrice =
    prices.primary != null ? (
      listing.hide_price_on_portals === true ? (
        <s>{prices.primary}</s>
      ) : (
        prices.primary
      )
    ) : (
      t('priceOnRequest')
    );

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {primaryPrice}
        </Typography>
        {prices.appraisalDiff != null && (
          <Typography
            variant="subtitle2"
            color={
              prices.appraisalDiff > 0
                ? theme.palette.error.main
                : theme.palette.success.main
            }
            pl={1}
            mt={'-4px'}
          >
            {prices.appraisalDiff > 0 ? '+' : ''}
            {prices.appraisalDiff.toLocaleString(locale, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            %
          </Typography>
        )}
      </Stack>

      <Typography variant="body2">{prices.secondary ?? '-'}</Typography>
    </Box>
  );
};
