import * as React from 'react';

import {
  Button,
  ButtonBase,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {
  Form,
  type FormikErrors,
  type FormikHook,
  useFormik,
} from '@realadvisor/form';
import { useDropZone, useFileDialog } from '@realadvisor/hooks';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { Box, Flex } from 'react-system';

import { isProduction } from '../config';
import { ColorPicker } from '../controls/color-picker';
import { CountryInput } from '../controls/country-input';
import { DrawerBottomToolbar } from '../controls/drawer';
import { PhoneInput } from '../controls/phone-input';
import { ProgressButton } from '../controls/progress-button';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { AddressInput } from '../shared/address-input';
import { useFileUpload } from '../shared/file-upload';
import { TopbarTitle } from '../shared/topbar';

import type {
  TenantEmailTemplate,
  generalSettingsQuery,
  generalSettingsQuery$data,
} from './__generated__/generalSettingsQuery.graphql';
import type { generalSettingsUpdateTenantSettingsMutation } from './__generated__/generalSettingsUpdateTenantSettingsMutation.graphql';

graphql`
  fragment generalSettings_tenantSettings on Tenant {
    name
    emailSenderName
    emailSenderEmail
    defaultPipeline {
      id
    }
    emailTemplate
    availableEmailTemplates
    route
    streetNumber
    postcode
    state
    locality
    countryCode
    mainColor
    phoneMain
    googleTagManagerId
    sendInBlueApiKey
    stripeSecretKey
    stripeWebhookSecret
    stripeSecretKeyTest
    stripeWebhookSecretTest
    logoImage {
      url
    }
    helpLinkFr
    helpLinkEs
    helpLinkDe
    helpLinkEn
    helpLinkIt
    debugEmailMode
  }
`;

const BrandSettings = ({ form }: { form: FormikHook<FormState> }) => {
  const { t } = useLocale();
  const { borderRadius } = useTheme();
  const targetRef = React.useRef(null);
  const [accepted, setAccepted] = React.useState(false);
  const [uploadFile] = useFileUpload();

  const { values, setValues, errors, setTouched } = form;
  const update = (file: File) => {
    uploadFile(file, 'images', logoImageUrl => {
      if (logoImageUrl != null) {
        const { origin, pathname } = new URL(logoImageUrl);
        setValues({ logoImageUrl: `${origin}${pathname}` });
      }
    });
  };

  useDropZone({
    targetRef,
    accept: 'image/*',
    onDragEnter: (_accepted, rejected) => {
      setAccepted(rejected.length === 0);
    },
    onDrop: files => {
      if (accepted && files.length !== 0) {
        update(files[0]);
      }
    },
  });
  const openFileDialog = useFileDialog({
    accept: 'image/*',
    multiple: false,
    onChange: files => {
      if (files.length !== 0) {
        update(files[0]);
      }
    },
  });
  return (
    <Flex px={2}>
      <Box
        width={1 / 3}
        css={{
          marginTop: 16,
          marginRight: 16,
          height: 128,
        }}
      >
        <ButtonBase
          ref={targetRef}
          css={{
            width: '100%',
            height: 92,
            display: 'flex',
            position: 'relative',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderRadius: borderRadius.medium,
          }}
          focusRipple={true}
          onClick={openFileDialog}
        >
          {values.logoImageUrl != null && (
            <img
              css={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              src={values.logoImageUrl}
              alt="Tenant Logo"
            />
          )}
        </ButtonBase>
        <Button
          color="secondary"
          onClick={() => {
            setValues({ logoImageUrl: null });
          }}
          disabled={values.logoImageUrl == null}
        >
          {t('removeImage')}
        </Button>
      </Box>
      <Box width={2 / 3}>
        <TextField
          error={errors.name != null}
          required={true}
          label={t('displayName')}
          margin="normal"
          value={values.name}
          helperText={errors.name}
          onChange={event => {
            const name = event.target.value;
            setValues({ name });
          }}
          onBlur={() => setTouched({ name: true })}
        />
        <ColorPicker
          color={values.mainColor}
          onChange={mainColor => setValues({ mainColor })}
        />
      </Box>
    </Flex>
  );
};

const AddressForm = ({ form }: { form: FormikHook<FormState> }) => {
  const { t } = useLocale();
  const { values, setValues } = form;
  return (
    <Box
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(260px,1fr))',
        gridGap: 16,
      }}
      px={2}
      pt={3}
    >
      <FormControl>
        <InputLabel>{t('route')}</InputLabel>
        <AddressInput
          value={values.route ?? ''}
          onChange={value => setValues({ route: value })}
          onSelect={address => {
            if (address) {
              setValues({
                countryCode: address.countryCode ?? '',
                route: address.route ?? '',
                streetNumber: address.streetNumber ?? '',
                locality: address.locality ?? '',
                postcode: address.postcode ?? '',
                state: address.state ?? '',
              });
            }
          }}
        />
      </FormControl>
      <TextField
        label={t('streetNumber')}
        value={values.streetNumber}
        onChange={event => setValues({ streetNumber: event.target.value })}
      />

      <TextField
        label={t('postcode')}
        value={values.postcode}
        onChange={event => setValues({ postcode: event.target.value })}
      />
      <TextField
        label={t('locality')}
        value={values.locality}
        onChange={event => setValues({ locality: event.target.value })}
      />
      <TextField
        label={t('state')}
        value={values.state}
        onChange={event => setValues({ state: event.target.value })}
      />
      <FormControl>
        <InputLabel>{t('country')}</InputLabel>
        <CountryInput
          value={values.countryCode}
          onChange={countryCode =>
            setValues({ countryCode: countryCode ?? '' })
          }
        />
      </FormControl>
    </Box>
  );
};

const LeadsSettings = ({
  pipelines,
  form,
}: {
  pipelines: generalSettingsQuery$data['pipelines'];
  form: FormikHook<FormState>;
}) => {
  const { t } = useLocale();
  const { values, setValues } = form;

  return (
    <Flex flexDirection="column" px={2}>
      <TextField
        label={t('defaultPipeline')}
        value={values.defaultPipelineId}
        select={true}
        onChange={event => {
          const defaultPipelineId = event.target.value;
          setValues({ defaultPipelineId });
        }}
        margin="normal"
      >
        {pipelines.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </Flex>
  );
};

const ValuationSettings = ({
  availableEmailTemplates,
  form,
}: {
  availableEmailTemplates: NonNullable<
    generalSettingsQuery$data['tenantSettings']
  >['availableEmailTemplates'];
  form: FormikHook<FormState>;
}) => {
  const { t } = useLocale();
  const { values, setValues } = form;
  const NONE = 'none';

  return (
    <Flex flexDirection="column" px={2} css={{ gap: 16, marginTop: 8 }}>
      <TextField
        label={t('emailTemplate')}
        value={values.emailTemplate ?? NONE}
        select={true}
        onChange={event => {
          const emailTemplate = event.target.value;
          setValues({
            emailTemplate:
              emailTemplate === NONE
                ? null
                : (emailTemplate as TenantEmailTemplate),
          });
        }}
      >
        <MenuItem key="template-none" value={NONE}>
          {t('None')}
        </MenuItem>
        {(availableEmailTemplates ?? []).map(template => (
          <MenuItem key={template} value={template as string}>
            {template}
          </MenuItem>
        ))}
      </TextField>
      <FormControl variant="filled" error={form.errors.phoneMain != null}>
        <InputLabel>{t('phoneNumber')}</InputLabel>
        <PhoneInput
          value={values.phoneMain ?? ''}
          onChange={phoneMain => setValues({ phoneMain })}
        />
      </FormControl>
    </Flex>
  );
};

const EmailSettings = ({ form }: { form: FormikHook<FormState> }) => {
  const { t } = useLocale();
  const { values, setValues } = form;

  return (
    <Flex flexDirection="column" px={2} css={{ gap: 16, marginTop: 8 }}>
      <TextField
        label={t('emailSenderName')}
        value={values.emailSenderName}
        onChange={event => {
          const emailSenderName = event.target.value;
          setValues({ emailSenderName });
        }}
      />
      <TextField
        label={t('emailSenderEmail')}
        value={values.emailSenderEmail}
        onChange={event => {
          const emailSenderEmail = event.target.value;
          setValues({ emailSenderEmail });
        }}
      />
    </Flex>
  );
};

const HelpLinkSettings = ({ form }: { form: FormikHook<FormState> }) => {
  const { t } = useLocale();
  const { values, setValues } = form;

  return (
    <Flex flexDirection="column" px={2} css={{ gap: 16, marginTop: 8 }}>
      <TextField
        label={t('helpLink', { countryCode: 'EN' })}
        value={values.helpLinkEn ?? ''}
        onChange={event => {
          const value = event.target.value.trim();
          const helpLinkEn = value === '' ? null : value;
          setValues({ helpLinkEn });
        }}
      />
      <TextField
        label={t('helpLink', { countryCode: 'FR' })}
        value={values.helpLinkFr ?? ''}
        onChange={event => {
          const value = event.target.value.trim();
          const helpLinkFr = value === '' ? null : value;
          setValues({ helpLinkFr });
        }}
      />
      <TextField
        label={t('helpLink', { countryCode: 'DE' })}
        value={values.helpLinkDe ?? ''}
        onChange={event => {
          const value = event.target.value.trim();
          const helpLinkDe = value === '' ? null : value;
          setValues({ helpLinkDe });
        }}
      />
      <TextField
        label={t('helpLink', { countryCode: 'ES' })}
        value={values.helpLinkEs ?? ''}
        onChange={event => {
          const value = event.target.value.trim();
          const helpLinkEs = value === '' ? null : value;
          setValues({ helpLinkEs });
        }}
      />
      <TextField
        label={t('helpLink', { countryCode: 'IT' })}
        value={values.helpLinkIt ?? ''}
        onChange={event => {
          const value = event.target.value.trim();
          const helpLinkIt = value === '' ? null : value;
          setValues({ helpLinkIt });
        }}
      />
    </Flex>
  );
};

const PublicKeys = ({ form }: { form: FormikHook<FormState> }) => {
  const { t } = useLocale();
  const { values, setValues } = form;

  return (
    <Flex flexDirection="column" px={2} css={{ gap: 16, marginTop: 8 }}>
      <TextField
        label={t('googleTagManagerId')}
        value={values.googleTagManagerId ?? ''}
        onChange={event => {
          const value = event.target.value.trim();
          const googleTagManagerId = value === '' ? null : value;
          setValues({ googleTagManagerId });
        }}
      />
      <TextField
        label={t('sendInBlueApiKey')}
        value={values.sendInBlueApiKey ?? ''}
        onChange={event => {
          const value = event.target.value.trim();
          const sendInBlueApiKey = value === '' ? null : value;
          setValues({ sendInBlueApiKey });
        }}
      />
      {isProduction ? (
        <>
          <TextField
            label={t('stripeSecretKey')}
            value={values.stripeSecretKey ?? ''}
            onChange={event => {
              const value = event.target.value.trim();
              const stripeSecretKey = value === '' ? null : value;
              setValues({ stripeSecretKey });
            }}
          />
          <TextField
            label={t('stripeWebhookSecret')}
            value={values.stripeWebhookSecret ?? ''}
            onChange={event => {
              const value = event.target.value.trim();
              const stripeWebhookSecret = value === '' ? null : value;
              setValues({ stripeWebhookSecret });
            }}
          />
        </>
      ) : (
        <>
          <TextField
            label={`${t('stripeSecretKey')} (test mode)`}
            value={values.stripeSecretKeyTest ?? ''}
            onChange={event => {
              const value = event.target.value.trim();
              const stripeSecretKeyTest = value === '' ? null : value;
              setValues({ stripeSecretKeyTest });
            }}
          />
          <TextField
            label={`${t('stripeWebhookSecret')} (test mode)`}
            value={values.stripeWebhookSecretTest ?? ''}
            onChange={event => {
              const value = event.target.value.trim();
              const stripeWebhookSecretTest = value === '' ? null : value;
              setValues({ stripeWebhookSecretTest });
            }}
          />
        </>
      )}
    </Flex>
  );
};

const SectionHeading = ({ children }: { children: React.ReactNode }) => {
  const { text } = useTheme();

  return (
    <Box px={2} pt={3} css={text.subtitle2}>
      {children}
    </Box>
  );
};

export const HEX_REGEXP = /^#[0-9a-f]{6}$/i;

type FormState = {
  name: string;
  emailSenderName: string;
  emailSenderEmail: string;
  emailTemplate: TenantEmailTemplate | null;
  route: string;
  streetNumber: string;
  postcode: string;
  state: string;
  locality: string;
  countryCode: string;
  mainColor: string;
  logoImageUrl: string | null;
  helpLinkEn: string | null;
  helpLinkFr: string | null;
  helpLinkDe: string | null;
  helpLinkEs: string | null;
  helpLinkIt: string | null;
  phoneMain: string;
  defaultPipelineId: string | null;
  googleTagManagerId: string | null;
  sendInBlueApiKey: string | null;
  stripeSecretKey: string | null;
  stripeWebhookSecret: string | null;
  stripeSecretKeyTest: string | null;
  stripeWebhookSecretTest: string | null;
  debugEmailMode: boolean;
};

export const GeneralSettings = () => {
  const { t } = useLocale();
  const { colors } = useTheme();

  const data = useLazyLoadQuery<generalSettingsQuery>(
    graphql`
      query generalSettingsQuery {
        tenantSettings {
          ...generalSettings_tenantSettings @relay(mask: false)
        }
        pipelines {
          id
          name
          label
          leadType
          stages {
            __typename
          }
        }
      }
    `,
    {},
  );

  const [updateTenantSettings, updating] =
    useMutation<generalSettingsUpdateTenantSettingsMutation>(
      graphql`
        mutation generalSettingsUpdateTenantSettingsMutation(
          $input: UpdateTenantSettingsInput!
        ) {
          updateTenantSettings(input: $input) {
            tenantSettings {
              ...generalSettings_tenantSettings @relay(mask: false)
            }
          }
        }
      `,
    );

  const pipelines = data.pipelines.filter(
    pipeline => pipeline.leadType === 'sales' && pipeline.stages.length > 0,
  );
  const form = useFormik<FormState>({
    initialValues: {
      name: data.tenantSettings?.name ?? '',
      emailSenderName: data.tenantSettings?.emailSenderName ?? '',
      emailSenderEmail: data.tenantSettings?.emailSenderEmail ?? '',
      emailTemplate: data.tenantSettings?.emailTemplate ?? null,
      route: data.tenantSettings?.route ?? '',
      streetNumber: data.tenantSettings?.streetNumber ?? '',
      postcode: data.tenantSettings?.postcode ?? '',
      state: data.tenantSettings?.state ?? '',
      locality: data.tenantSettings?.locality ?? '',
      countryCode: data.tenantSettings?.countryCode ?? '',
      mainColor: data.tenantSettings?.mainColor ?? colors.blue700,
      logoImageUrl: data.tenantSettings?.logoImage?.url ?? null,
      helpLinkEn: data.tenantSettings?.helpLinkEn ?? null,
      helpLinkFr: data.tenantSettings?.helpLinkFr ?? null,
      helpLinkDe: data.tenantSettings?.helpLinkDe ?? null,
      helpLinkEs: data.tenantSettings?.helpLinkEs ?? null,
      helpLinkIt: data.tenantSettings?.helpLinkIt ?? null,
      phoneMain: data.tenantSettings?.phoneMain ?? '',
      defaultPipelineId: data.tenantSettings?.defaultPipeline?.id ?? null,
      googleTagManagerId: data.tenantSettings?.googleTagManagerId ?? null,
      sendInBlueApiKey: data.tenantSettings?.sendInBlueApiKey ?? null,
      stripeSecretKey: data.tenantSettings?.stripeSecretKey ?? null,
      stripeWebhookSecret: data.tenantSettings?.stripeWebhookSecret ?? null,
      stripeSecretKeyTest: data.tenantSettings?.stripeSecretKeyTest ?? null,
      stripeWebhookSecretTest:
        data.tenantSettings?.stripeWebhookSecretTest ?? null,
      debugEmailMode: data.tenantSettings?.debugEmailMode ?? false,
    },
    validate: values => {
      const errors: FormikErrors<FormState> = {};
      if (values.name === '') {
        errors.name = t('nameIsRequired');
      }
      if (HEX_REGEXP.test(values.mainColor ?? '') === false) {
        errors.mainColor = t('colorShouldBeHex');
      }
      return errors;
    },
    onSubmit: values => {
      updateTenantSettings({
        variables: {
          input: values,
        },
        onCompleted: form.resetForm,
      });
    },
  });

  return (
    <>
      <TopbarTitle>{t('General')}</TopbarTitle>
      <Paper>
        <Form onSubmit={form.submitForm}>
          <Container maxWidth="sm" style={{ marginBottom: 32 }}>
            <SectionHeading>{t('brandSettings')}</SectionHeading>
            <BrandSettings form={form} />
            <SectionHeading>{t('address')}</SectionHeading>
            <AddressForm form={form} />
            <SectionHeading>{t('leadsSettings')}</SectionHeading>
            <LeadsSettings form={form} pipelines={pipelines} />
            <SectionHeading>{t('valuationSettings')}</SectionHeading>
            <ValuationSettings
              form={form}
              availableEmailTemplates={
                data.tenantSettings?.availableEmailTemplates ?? []
              }
            />
            <SectionHeading>{t('emailSettings')}</SectionHeading>
            <EmailSettings form={form} />
            <SectionHeading>{t('publicKeys')}</SectionHeading>
            <PublicKeys form={form} />
            <SectionHeading>{t('helpLinks')}</SectionHeading>
            <HelpLinkSettings form={form} />
            {!isProduction && (
              <>
                <SectionHeading>{t('Debug Modes')}</SectionHeading>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.values.debugEmailMode}
                      onChange={() =>
                        form.setValues(prevValues => ({
                          debugEmailMode: !prevValues.debugEmailMode,
                        }))
                      }
                    />
                  }
                  label={t('Debug email mode')}
                />
              </>
            )}
          </Container>
          <DrawerBottomToolbar>
            <Button
              disabled={!form.changed}
              onClick={form.resetForm}
              color="secondary"
            >
              {t('Cancel')}
            </Button>
            <ProgressButton
              variant="contained"
              color="primary"
              disabled={!form.valid || !form.changed}
              loading={updating}
              onClick={form.submitForm}
            >
              {t('save')}
            </ProgressButton>
          </DrawerBottomToolbar>
        </Form>
      </Paper>
    </>
  );
};
