import * as React from 'react';

import type { Interpolation } from '@emotion/react';
import { Button, CardActions, CardContent, Chip } from '@mui/material';
import { sanitizeHtml } from '@realadvisor/html-sanitizer';
import prettyBytes from 'pretty-bytes';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { Flex } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { EmailEnvelopeOutline } from '../../icons/email-envelope-outline';
import { Fold } from '../../icons/fold';
import { Forward } from '../../icons/forward';
import { MoreHoriz } from '../../icons/more-horiz';
import { Notes } from '../../icons/notes';
import { Reply } from '../../icons/reply';
import { Unfold } from '../../icons/unfold';
import { shortenFilename } from '../../utils/string';
import { ConnectEmailForm } from '../EmailForm/ConnectEmailForm';
import { findQuotes } from '../EmailForm/findQuotes';
import { mediaCss } from '../EmailForm/renderPropertySnippet';

import type {
  FeedEmail_activity$data,
  FeedEmail_activity$key,
} from './__generated__/FeedEmail_activity.graphql';
import type { FeedEmail_me$key } from './__generated__/FeedEmail_me.graphql';
import type { FeedEmailMessageQuery } from './__generated__/FeedEmailMessageQuery.graphql';

type Props = {
  me: null | FeedEmail_me$key;
  activity: FeedEmail_activity$key;
  onCreate: () => void;
};

const Preview = ({
  styles,
  children,
}: {
  styles: Interpolation<unknown>;
  children: string | null;
}) => {
  const { text } = useTheme();
  return (
    <div
      css={[styles, { strong: { fontWeight: text.font.bold } }]}
      dangerouslySetInnerHTML={{
        __html: children != null ? sanitizeHtml(children) : '',
      }}
    />
  );
};

const Attachments = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      css={{
        padding: 5,
        lineHeight: '40px',
        div: {
          marginRight: 5,
        },
      }}
    >
      {children}
    </div>
  );
};

const renderAttachments = (
  files: NonNullable<
    NonNullable<FeedEmail_activity$data['emailMessage']>['emailMessageFiles']
  >,
) => {
  if (!files.length) {
    return null;
  }

  return (
    <Attachments>
      {files.map(file => (
        <Chip
          key={file?.nylasFileId}
          label={`${shortenFilename(file?.name ?? '')} (${prettyBytes(
            file?.size ?? 0,
          )})`}
        />
      ))}
    </Attachments>
  );
};

export const UnfoldedEmailMessage = ({
  emailMessageId,
}: {
  emailMessageId: string;
}) => {
  const { colors } = useTheme();
  const [showQuoted, setShowQuoted] = React.useReducer(r => !r, false);
  const data = useLazyLoadQuery<FeedEmailMessageQuery>(
    graphql`
      query FeedEmailMessageQuery($id: ID!) {
        message: node(id: $id) {
          ... on EmailMessage {
            id
            body
          }
        }
      }
    `,
    { id: emailMessageId },
  );
  const { message, quotes } = findQuotes(data.message?.body ?? '');
  return (
    <>
      <Preview styles={mediaCss}>{message}</Preview>
      {quotes != null && (
        <>
          <Flex>
            <Button
              css={{
                padding: '0 6px',
                minWidth: 0,
                lineHeight: 0,
                borderRadius: '10px',
                backgroundColor: colors.grey300,
              }}
              onClick={setShowQuoted}
            >
              <MoreHoriz size={20} />
            </Button>
          </Flex>
          {showQuoted && <Preview styles={{}}>{quotes}</Preview>}
        </>
      )}
    </>
  );
};

export const FeedEmail = (props: Props) => {
  const { t } = useLocale();
  const { text, colors } = useTheme();
  const statuses = {
    sent: t('statusSent'),
    pending: t('statusPending'),
    error: t('statusError'),
  };
  const [folded, setFolded] = React.useState(true);
  const [replying, setReplying] = React.useState(false);
  const [forwarding, setForwarding] = React.useState(false);

  const me = useFragment(
    graphql`
      fragment FeedEmail_me on User {
        id
      }
    `,
    props.me,
  );

  const activity = useFragment(
    graphql`
      fragment FeedEmail_activity on ActivityEmail {
        id
        emailMessage {
          ...ConnectEmailForm_replyToMessage
          ...ConnectEmailForm_forwardMessage
          id
          subject
          status
          snippet
          to {
            name
            user {
              id
            }
          }
          from {
            name
            user {
              id
            }
          }
          cc {
            name
            user {
              id
            }
          }
          bcc {
            name
            user {
              id
            }
          }
          emailMessageFiles {
            nylasFileId
            name
            size
          }
        }
      }
    `,
    props.activity,
  );

  const userExists = (
    list: NonNullable<FeedEmail_activity$data['emailMessage']>['from'],
  ) => list.some(u => u?.user?.id === me?.id);
  const canReply =
    userExists(activity.emailMessage?.from ?? []) ||
    userExists(activity.emailMessage?.to ?? []) ||
    userExists(activity.emailMessage?.cc ?? []) ||
    userExists(activity.emailMessage?.bcc ?? []);

  return (
    <>
      <CardContent
        css={{
          paddingTop: 0,
          display: 'grid',
          gridGap: 12,
          gridTemplateColumns: '24px 1fr',
          wordBreak: 'break-word',
        }}
      >
        {activity.emailMessage?.status != null &&
          ['pending', 'error'].includes(activity.emailMessage.status) && (
            <div css={{ gridColumn: '1 / -1' }}>
              <Chip
                label={
                  statuses[
                    (activity.emailMessage.status as keyof typeof statuses) ||
                      'pending'
                  ]
                }
                style={
                  {
                    error: {
                      backgroundColor: colors.errorText,
                      color: colors.white,
                    },
                    pending: {
                      color: colors.black,
                    },
                  }[activity.emailMessage.status || 'pending']
                }
              />
            </div>
          )}
        {activity.emailMessage && (
          <>
            {activity.emailMessage.subject != null && (
              <>
                <Notes />
                <div css={text.body2}>{activity.emailMessage.subject}</div>
              </>
            )}
            {activity.emailMessage.snippet != null && (
              <>
                <EmailEnvelopeOutline />
                <div css={text.body2}>
                  {folded && <span>{activity.emailMessage.snippet}</span>}

                  {!folded && (
                    <React.Suspense fallback={null}>
                      <UnfoldedEmailMessage
                        emailMessageId={activity.emailMessage.id}
                      />
                    </React.Suspense>
                  )}

                  {activity.emailMessage &&
                    renderAttachments(activity.emailMessage.emailMessageFiles)}

                  <Flex pt={3}>
                    <Button
                      css={{ textTransform: 'none' }}
                      onClick={() => setFolded(!folded)}
                      color="primary"
                      size="small"
                      startIcon={
                        folded ? <Unfold size={12} /> : <Fold size={12} />
                      }
                    >
                      {folded ? t('showMore') : t('hide')}
                    </Button>
                  </Flex>
                </div>
              </>
            )}
          </>
        )}
      </CardContent>

      {activity.emailMessage && (
        <>
          {canReply && (
            <CardActions css={{ justifyContent: 'flex-end', padding: 16 }}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<Reply size={15} />}
                onClick={() => {
                  setReplying(true);
                  setForwarding(false);
                }}
              >
                {t('reply')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<Forward />}
                onClick={() => {
                  setReplying(false);
                  setForwarding(true);
                }}
              >
                {t('forward')}
              </Button>
            </CardActions>
          )}

          {replying && (
            <ConnectEmailForm
              replyToMessage={activity.emailMessage}
              EmailFormProps={{
                isDialog: true,
                dialogTitle: t('sendAReply'),
                onCancel: () => {
                  setReplying(false);
                },
                onEmailSent: () => {
                  setReplying(false);
                  props.onCreate();
                },
              }}
            />
          )}

          {forwarding && (
            <ConnectEmailForm
              forwardMessage={activity.emailMessage}
              EmailFormProps={{
                isDialog: true,
                dialogTitle: t('forwardEmail'),
                onCancel: () => {
                  setForwarding(false);
                },
                onEmailSent: () => {
                  setForwarding(false);
                  props.onCreate();
                },
              }}
            />
          )}
        </>
      )}
    </>
  );
};
