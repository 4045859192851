import { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { DeleteOutline, PersonAdd } from '@mui/icons-material';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { DataGridPremium, type GridColDef } from '@mui/x-data-grid-premium';

import { useLocale } from '../../../src/hooks/locale';
import type { GetLeadAgentsQuery } from '../../__generated__/graphql';
import IndicatorLeadStage from '../../components/IndicatorLeadStage';
import { TimeAgo } from '../../components/TimeAgo';
import { UserSelect } from '../../components/user-select/UserSelect';
import { UserAvatar } from '../../components/UserAvatar';

import {
  ADD_LEAD_AGENT,
  DELETE_LEAD_AGENT,
  GET_LEAD_AGENTS,
} from './leadsQueries';

interface LeadAgentsProps {
  leadId: string;
}

type LeadAgent = GetLeadAgentsQuery['lead_agents'][number];

export const LeadAgents = ({ leadId }: LeadAgentsProps) => {
  const { t } = useLocale();
  const [mutationError, setMutationError] = useState<string | null>(null);
  const [showUserSelect, setShowUserSelect] = useState<boolean>(false);

  const { data: { lead_agents } = {}, loading } = useQuery(GET_LEAD_AGENTS, {
    variables: { lead_id: leadId },
  });

  const [addLeadAgent] = useMutation(ADD_LEAD_AGENT, {
    refetchQueries: ['GetLead', 'LeadFeed', 'GetLeadAgents'],
  });
  const [DeleteLeadAgent] = useMutation(DELETE_LEAD_AGENT, {
    refetchQueries: ['GetLead', 'LeadFeed', 'GetLeadAgents'],
  });

  const columns: GridColDef<LeadAgent>[] = [
    {
      field: 'name',
      headerName: t('Agent'),
      width: 200,
      display: 'flex',
      renderCell: params => (
        <Stack direction="row" spacing={1} alignItems="center">
          <UserAvatar user={params.row.user} />
          <Stack>
            <Typography variant="body2" noWrap>
              {params.row.user.first_name} {params.row.user.last_name}
            </Typography>
            <Typography variant="body2" noWrap color="text.secondary">
              {params.row.user.default_team?.name}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      field: 'source',
      headerName: t('Source'),
      width: 100,
      display: 'flex',
      renderCell: params => (
        <Stack>
          <Typography variant="body2" noWrap>
            {params.row.source}
          </Typography>
          <Typography variant="body2" noWrap color="text.secondary">
            <TimeAgo dateString={params.row.created_at} />
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'status',
      headerName: t('Status'),
      width: 100,
      display: 'flex',
      renderCell: params => (
        <Stack>
          <Typography variant="body2" noWrap>
            {params.row.claimed_at
              ? t('Claimed')
              : params.row.passed_at
              ? t('Passed')
              : t('Pending')}
          </Typography>
          <Typography variant="body2" noWrap color="text.secondary">
            <TimeAgo
              dateString={
                params.row.claimed_at ??
                params.row.passed_at ??
                params.row.created_at
              }
            />
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'stage',
      headerName: t('Stage'),
      width: 100,
      display: 'flex',
      renderCell: params => {
        if (!params.row.lead_stage?.label) {
          return '-';
        }
        return (
          <IndicatorLeadStage status={params.row.lead_stage.status}>
            {params.row.lead_stage.label}
          </IndicatorLeadStage>
        );
      },
    },
    {
      field: 'win_probability',
      headerName: t('Win probability'),
      width: 100,
      display: 'flex',
      valueGetter: (_, row) => row.win_probability,
    },
    {
      field: 'predicted_win_date',
      headerName: t('Predicted win date'),
      width: 100,
      display: 'flex',
      valueGetter: (_, row) => row.predicted_win_date,
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      width: 80,
      sortable: false,
      filterable: false,
      display: 'flex',
      renderCell: params => (
        <Button
          size="small"
          disabled
          //disabled={params.row.source === 'result_page'}
          onClick={() =>
            DeleteLeadAgent({
              variables: { id: params.row.id },
            }).catch(error => {
              setMutationError(error.message);
            })
          }
        >
          <DeleteOutline />
        </Button>
      ),
    },
  ];

  return (
    <Stack spacing={1}>
      <DataGridPremium<LeadAgent>
        rows={lead_agents || []}
        columns={columns}
        loading={loading}
        disableRowSelectionOnClick
        autoHeight
        getRowId={row => row.id}
        hideFooter={true}
        sx={{
          backgroundColor: '#fff',
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />

      {mutationError && <Alert severity="error">{mutationError}</Alert>}
      <Stack spacing={1}>
        {showUserSelect && (
          <UserSelect
            autoFocus
            InputProps={{
              onBlur: () => setShowUserSelect(false),
            }}
            onBlur={() => setShowUserSelect(false)}
            onChange={user_id => {
              if (!user_id) {
                return;
              }
              addLeadAgent({
                variables: {
                  lead_id: leadId,
                  user_id,
                },
                onError: error => {
                  setMutationError(error.message);
                },
              }).then(() => setShowUserSelect(false));
            }}
            filters={{
              is_broker: { _eq: true },
              id: { _nin: lead_agents?.map(agent => agent.user.id) },
            }}
          />
        )}
        {false && !showUserSelect && (
          <Button onClick={() => setShowUserSelect(true)}>
            <PersonAdd sx={{ mr: 1 }} />
            {t('Add agent')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
