import { useMemo } from 'react';

import { useLocale } from '../../../src/hooks/locale';
import { type FormDefinitionType, RaForm } from '../../components/form/RaForm';
import { type RaAddressType } from '../form/RaAddressInput';

type CreateLeadAddressStepProps = {
  data: RaAddressType;
  footerActionsComponent: React.ReactNode;
  onFormSubmitted: (data: Partial<RaAddressType>) => Promise<void>;
};

export const CreateLeadAddressStep = ({
  data,
  footerActionsComponent,
  onFormSubmitted,
}: CreateLeadAddressStepProps) => {
  const { countryCode } = useLocale();

  const formDefinition = useMemo<FormDefinitionType<RaAddressType>>(
    () =>
      ({ t }) =>
        [
          {
            type: 'address',
            name: 'address',
            label: t('Address'),
            required: true,
            gridProps: { xs: 12 },
            countryRestriction: countryCode,
          },
        ],
    [countryCode],
  );

  return (
    <RaForm
      formDefinition={formDefinition}
      defaultValues={data}
      onSubmit={onFormSubmitted}
      actionButtonsComponent={footerActionsComponent}
      contentScrollable
    />
  );
};

CreateLeadAddressStep.displayName = 'CreateLeadAddressStep';
