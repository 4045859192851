import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  useFragment as useApolloFragment,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  Skeleton,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../../src/hooks/locale';
import type { StepListingsCompareListingsQuery } from '../../../__generated__/graphql';
import type { CMAPropertyTypes } from '../CMAReportEditor/CMAReportEditor';
import { useCMAEditor } from '../CMAReportEditor/CMAReportEditorWorkflow';
import {
  INSERT_CMA_REPORTS_COMPARABLES,
  STEP_LISTINGS_COMPARE_ALGO,
  STEP_LISTINGS_COMPARE_FRAGMENT,
  STEP_LISTINGS_COMPARE_LISTINGS,
} from '../cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActionsComponentWithoutForm,
  getAutoAlgoParams,
} from '../shared';

import AggregatesListings from './AggregatesListings';
import ComparableListingsSection from './ComparableListingsSection';
import ConfirmationRefreshDialog from './ConfirmationDialog';
import ListingComparableDetails from './ListingComparableDetails';

type StepListingsCompareListings =
  | StepListingsCompareListingsQuery['ch_cma_listings']
  | StepListingsCompareListingsQuery['es_cma_listings']
  | StepListingsCompareListingsQuery['fr_cma_listings']
  | StepListingsCompareListingsQuery['it_cma_listings'];

export type ComparableListing =
  NonNullable<StepListingsCompareListings>[number];

const PropertyTypesContext = createContext<CMAPropertyTypes | null>(null);

type ComparableListingsSectionProps = {
  children: React.ReactNode;
  propertyTypes: CMAPropertyTypes;
};

export const PropertyTypesProvider = ({
  children,
  propertyTypes,
}: ComparableListingsSectionProps) => (
  <PropertyTypesContext.Provider value={propertyTypes}>
    {children}
  </PropertyTypesContext.Provider>
);

const usePropertyTypes = () => {
  const context = useContext(PropertyTypesContext);
  if (context == null) {
    throw new Error(
      'usePropertyTypes must be used within a PropertyTypesProvider',
    );
  }
  return context;
};

export const usePropertyLabel = (propertyTypeName: string | null) => {
  const propertyTypes = usePropertyTypes();
  return propertyTypes.find(pt => pt.name === propertyTypeName)?.label ?? '';
};

const StepListingsCompare = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const { countryCode } = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const [manualSelectionOpen, setManualSelectionOpen] = useState(false);
  const [refreshOpen, setRefreshOpen] = useState(false);
  const [listings, setListings] = useState<ComparableListing[]>([]);
  const { propertyTypes } = useCMAEditor();

  const { data, complete } = useApolloFragment({
    fragment: STEP_LISTINGS_COMPARE_FRAGMENT,
    fragmentName: 'StepListingsCompare',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const handleOpenManualSelection = useCallback(() => {
    setManualSelectionOpen(true);

    // We use setTimeout(0) to update the URL after React finishes updating the state
    // This fixes a bug where the 'where' parameter would get lost if we try to update
    // both state and URL at the same time
    setTimeout(() => {
      const newParams = new URLSearchParams(searchParams);

      const whereParam = JSON.stringify({
        places: {
          id: {
            _in: data?.lead?.property?.locality_place?.id
              ? [data.lead.property.locality_place.id]
              : [],
          },
        },
        property_types: {
          name: {
            _in: data?.lead?.property?.property_type?.name
              ? [data.lead.property.property_type.name]
              : [],
          },
        },
      });

      newParams.set('where', whereParam);
      setSearchParams(newParams);
    }, 0);
  }, [data, searchParams, setSearchParams]);

  const handleCloseManualSelection = useCallback(() => {
    // Clear all search parameters
    setSearchParams(new URLSearchParams());
    setManualSelectionOpen(false);
  }, [setSearchParams]);

  const listingsInCmaReport = useMemo(
    () =>
      data?.comparable_listings?.map(listing => listing).filter(Boolean) ?? [],
    [data?.comparable_listings],
  );

  const { refetch } = useQuery(STEP_LISTINGS_COMPARE_LISTINGS, {
    skip: !complete,
    fetchPolicy: 'network-only',
    variables: {
      ids:
        listingsInCmaReport.map(
          listing => listing?.aggregates_db__merged_listings_clusters_id,
        ) ?? [],
      ch: countryCode === 'CH',
      es: countryCode === 'ES',
      fr: countryCode === 'FR',
      it: countryCode === 'IT',
    },
    context: {
      clientName: 'scrapers',
    },
    onCompleted: comparableData => {
      const comparableListings =
        comparableData?.ch_cma_listings ??
        comparableData?.es_cma_listings ??
        comparableData?.fr_cma_listings ??
        comparableData?.it_cma_listings ??
        [];

      const sortedListings = listingsInCmaReport
        .map(listing =>
          comparableListings.find(
            comparable =>
              comparable.id ===
              listing?.aggregates_db__merged_listings_clusters_id,
          ),
        )
        .filter(Boolean) as ComparableListing[];
      setListings(sortedListings);
    },
  });

  const [insertComparable] = useMutation(INSERT_CMA_REPORTS_COMPARABLES);

  const autoAlgoParams = getAutoAlgoParams(data?.lead, countryCode);
  const [runAutomaticAlgo, { loading: runningAutomaticAlgo }] = useLazyQuery(
    STEP_LISTINGS_COMPARE_ALGO,
    { ...autoAlgoParams, fetchPolicy: 'network-only' },
  );

  const generateComparable = useCallback(() => {
    runAutomaticAlgo({
      onCompleted: async listingsData => {
        const listings =
          listingsData?.ch_get_similar_listings_vector ??
          listingsData?.es_get_similar_listings_vector ??
          listingsData?.fr_get_similar_listings_vector ??
          listingsData?.it_get_similar_listings_vector ??
          [];
        insertComparable({
          variables: {
            id: cmaReportId,
            objects: listings.map(listing => ({
              aggregates_db__merged_listings_clusters_id: listing.id,
              cma_report_id: cmaReportId,
            })),
          },
          update: (cache, { data: updatedData }) => {
            const insert_cma_reports_comparables =
              updatedData?.insert_cma_reports_comparables;
            if (!insert_cma_reports_comparables) {
              return;
            }

            cache.modify({
              id: cache.identify({
                __typename: 'cma_reports',
                id: cmaReportId,
              }),
              fields: {
                comparable_listings() {
                  return insert_cma_reports_comparables.returning?.map(
                    listing => listing,
                  );
                },
              },
            });
          },
        });
      },
    });
  }, [runAutomaticAlgo, insertComparable, cmaReportId]);

  const activationConstraint = { delay: 12, tolerance: 2 };
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleClickConfirmDialog = useCallback(() => {
    generateComparable();
    setRefreshOpen(false);
  }, [generateComparable]);

  if (!complete) {
    return (
      <Stack gap={3} m={2}>
        <Stack direction={'row'}>
          <Skeleton variant="circular" width={24} height={24} sx={{ mr: 2 }} />
          <Skeleton variant="rounded" width={'40%'} height={24} />
        </Stack>
        <Stack gap={2}>
          {Array.from({ length: 5 }).map((_, idx) => (
            <Card
              key={`comparable-skeleton-${idx}`}
              variant="outlined"
              elevation={0}
            >
              <Typography variant="h6">
                <Skeleton />
              </Typography>
              <Typography variant="h4">
                <Skeleton />
              </Typography>
            </Card>
          ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <PropertyTypesProvider propertyTypes={propertyTypes}>
      <Dialog
        open={manualSelectionOpen}
        fullScreen
        css={{
          '.MuiDialog-paperFullScreen': {
            maxWidth: 'max(800px, calc(100vw - 156px))',
          },
        }}
        TransitionComponent={Transition}
        onClose={handleCloseManualSelection}
      >
        <DialogContent
          sx={{
            padding: '0 !important',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AggregatesListings
            data={data}
            cmaReportId={cmaReportId}
            refetch={refetch}
            onClose={handleCloseManualSelection}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationRefreshDialog
        open={refreshOpen}
        onConfirm={handleClickConfirmDialog}
        onClose={() => setRefreshOpen(false)}
      />
      <Box
        css={{
          flexGrow: 1,
          position: 'relative',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <ComparableListingsSection
          setManualSelectionOpen={handleOpenManualSelection}
          setRefreshOpen={setRefreshOpen}
          cmaReportId={cmaReportId}
          runningAutomaticAlgo={runningAutomaticAlgo}
          generateComparable={generateComparable}
          listings={listings}
          sensors={sensors}
          setListings={setListings}
        />

        <FooterActionsComponentWithoutForm updating={false} {...props} />
      </Box>

      <Routes>
        <Route
          path=":encodedListingId"
          element={<ListingComparableDetails />}
        />
      </Routes>
    </PropertyTypesProvider>
  );
};

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

export default StepListingsCompare;
