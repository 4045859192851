import {
  FormControl,
  FormHelperText,
  InputAdornment,
  type InputBaseProps,
  OutlinedInput,
} from '@mui/material';
import type { UseFormRegister } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';

import { RaLabel } from './RaLabel';

type IProps = Omit<
  InputBaseProps,
  'name' | 'autoComplete' | 'size' | 'fullWidth' | 'sx' | 'value' | 'onChange'
> & {
  register: UseFormRegister<any>;
  name: string;
  errors: any;
  label: string;
  suffix?: string;
  prefix?: string;
};

export const RaTextField: React.FC<IProps> = ({
  name,
  errors,
  label,
  register,
  required = false,
  multiline = false,
  minRows,
  suffix,
  prefix,
  ...inputProps
}) => {
  const { t } = useLocale();
  return (
    <FormControl fullWidth error={!!errors[name]}>
      <RaLabel label={label} required={required} />
      <OutlinedInput
        autoComplete="off"
        size="small"
        fullWidth
        sx={{
          background: 'white',
          '& .MuiInputAdornment-positionStart': {
            marginTop: '0px !important',
          },
        }}
        required={required}
        multiline={multiline}
        minRows={multiline ? minRows ?? 3 : undefined}
        endAdornment={
          suffix ? (
            <InputAdornment position="end">{suffix}</InputAdornment>
          ) : null
        }
        startAdornment={
          prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : null
        }
        {...inputProps}
        {...register(name, {
          required: required ? t('This field is required') : required,
          // Prevent only white spaces
          pattern: {
            value: /\S+/,
            message: t('This field contains only white spaces'),
          },
        })}
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
};
