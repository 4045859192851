// @flow

import * as React from 'react';

import { Avatar, Tooltip } from '@mui/material';
import { Image } from '@realadvisor/image';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';
import { Box, Flex, useSystem } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { Brightness1 } from '../icons/brightness-1';
import { RoundWarning } from '../icons/round-warning';
import { getCurrencyByCountryCode, getDefaultLocationOnMap } from '../locale';
import { translateMortgageType } from '../routes/FinancingRequests/translations';
import { formatPrice } from '../utils/format-price';
import { getHostName } from '../utils/getHostName';
import { getStaticMapLink } from '../utils/static-map';

import type { leadSnippet_lead$key } from './__generated__/leadSnippet_lead.graphql';
import type { leadSnippetQuery } from './__generated__/leadSnippetQuery.graphql';
import {
  LeadQualificationFunnel,
  commercialTypes,
  landTypes,
} from './lead-qualification-funnel';
import { LeadUpdatedTimeAgo } from './lead-updated-time-ago';

type Props = {|
  lead: ?leadSnippet_lead$key,
  showMap?: boolean,
  showAddress?: boolean,
  controls?: React.Node,
  bottomButton?: React.Node,
  openOnNewTab?: boolean,
  hasLink?: boolean,
  inSidebar?: boolean, // TODO: Remove this in future cleanup, it's currently needed to fit the component in a sidebar
  customCreationDate?: ?string,
|};

const formatIncome = (income, currency, locale) => {
  if (income < 1_000) {
    return income.toLocaleString(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      currency,
      style: 'currency',
    });
  }
  if (income < 1_000_000) {
    return (
      (income / 1_000).toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currency,
        style: 'currency',
      }) + 'K'
    );
  }
  return (
    (income / 1_000_000).toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency,
      style: 'currency',
    }) + 'M'
  );
};

const getAppraisalPerception = (
  colors,
  appraisalPerception: ?string,
  appraisalPerceivedValue: ?number,
  max: ?number,
  min: ?number,
) => {
  if (
    appraisalPerception === 'just_right' ||
    (appraisalPerceivedValue != null &&
      min != null &&
      max != null &&
      min <= appraisalPerceivedValue &&
      max >= appraisalPerceivedValue)
  ) {
    return '👍';
  } else if (
    appraisalPerception === 'too_low' ||
    (appraisalPerceivedValue != null &&
      max != null &&
      max < appraisalPerceivedValue)
  ) {
    return <RoundWarning size={18} fill={colors.error} />;
  } else if (
    appraisalPerception === 'too_high' ||
    (appraisalPerceivedValue != null &&
      min != null &&
      min > appraisalPerceivedValue)
  ) {
    return <Brightness1 size={15} fill={colors.success} />;
  } else {
    return '❓';
  }
};

const formatNumber = (value, locale) =>
  value == null ? '' : value.toLocaleString(locale);

const formatCurrencyRange = (range, currency, locale) =>
  `${formatPrice(range?.min, locale, currency)} - ${formatPrice(
    range?.max,
    locale,
    currency,
  )}`;

const BaseLeadSnippet = ({
  lead,
  showMap,
  showAddress,
  inSidebar,
  controls,
  bottomButton,
  customCreationDate,
  isAdmin,
}) => {
  const { text, colors, textColor } = useTheme();
  const { t, locale, countryCode } = useLocale();
  const { property, appraisalPropertyOccupied, appraisalPropertyUsage } = lead;
  const { media } = useSystem();

  const { lat: defaultLat, lng: defaultLng } =
    getDefaultLocationOnMap(countryCode);
  const lat = property?.lat ?? defaultLat;
  const lng = property?.lng ?? defaultLng;
  const currency = getCurrencyByCountryCode(property?.countryCode ?? 'CH');

  const getPropertyOccupied = () => {
    switch (appraisalPropertyOccupied) {
      case 'owner_occupied':
        return t('ownerOccupied');
      case 'tenant_occupied':
        return t('tenantOccupied');
      case 'vacant':
        return t('vacant');
      case 'i_dont_know':
        return t('unknownOccupancy');
      default:
        return null;
    }
  };

  const getPropertyUsage = () => {
    switch (appraisalPropertyUsage) {
      case 'primary_residence':
        return t('primaryResidence');
      case 'secondary_home':
        return t('secondaryHome');
      default:
        return null;
    }
  };

  let totalRentalIncome = 0;

  if (
    property?.propertyType?.name === 'house_multiple_dwelling' &&
    property?.residentialYearlyRentalIncome != null
  ) {
    totalRentalIncome += property.residentialYearlyRentalIncome;
  }
  if (
    property?.propertyType?.name === 'indus_commercial' &&
    property?.commercialYearlyRentalIncome != null
  ) {
    totalRentalIncome += property.commercialYearlyRentalIncome;
  }
  if (
    property?.propertyType?.name === 'indus_commercial_and_residential' &&
    property?.commercialYearlyRentalIncome != null &&
    property?.residentialYearlyRentalIncome != null
  ) {
    totalRentalIncome +=
      property.commercialYearlyRentalIncome +
      property.residentialYearlyRentalIncome;
  }
  if (
    property?.incomeIncludesParking === false &&
    property?.parkingYearlyRentalIncome != null
  ) {
    totalRentalIncome += property.parkingYearlyRentalIncome;
  }

  const properties = [];
  // Property type
  properties.push(property?.propertyType?.label);

  if (
    property?.propertyType?.name === 'house_multiple_dwelling' ||
    property?.propertyType?.name === 'indus_commercial_and_residential'
  ) {
    // Number of residential units
    properties.push(
      t('numberOfResidentialUnitsCount', {
        count: property.numberOfResidentialUnits ?? 0,
      }),
    );
    // Residential surface
    properties.push(
      t('surfaceOf', {
        surface: property.residentialSurface ?? 0,
      }),
    );
  }
  if (
    property?.propertyType?.name === 'indus_commercial' ||
    property?.propertyType?.name === 'indus_commercial_and_residential'
  ) {
    // Number of commercial tenants
    properties.push(
      t('numberOfCommercialTenantsCount', {
        count: property.numberOfCommercialUnits ?? 0,
      }),
    );
    // Commercial surface
    properties.push(
      t('surfaceOf', {
        surface: property.commercialSurface ?? 0,
      }),
    );
  }

  if (
    !commercialTypes.includes(property?.propertyType?.name) &&
    !landTypes.includes(property?.propertyType?.name)
  ) {
    // numberOfRooms
    if (property?.numberOfRooms != null) {
      properties.push(t('roomsShort', { count: property.numberOfRooms }));
    } else {
      properties.push(
        t('numberOfBedroomsWithCount', {
          count: property?.numberOfBedrooms ?? 0,
        }),
      );
    }
  }

  if (commercialTypes.includes(property?.propertyType?.name)) {
    // Total rental income (realised)
    properties.push(
      t('totalRentalIncome', {
        income: formatIncome(totalRentalIncome, currency, locale),
      }),
    );
  } else if (!landTypes.includes(property?.propertyType?.name)) {
    properties.push(
      property?.livingSurface != null
        ? `${property.livingSurface.toLocaleString(locale, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })} m²`
        : property?.builtSurface != null
        ? `${property.builtSurface.toLocaleString(locale, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })} m²`
        : null,
    );
  }

  // Plot size
  if (landTypes.includes(property?.propertyType?.name)) {
    properties.push(
      property?.landSurface != null
        ? t('surfaceOf', {
            surface: property.landSurface.toLocaleString(locale, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
          })
        : null,
    );
  } else {
    properties.push(
      property?.landSurface != null
        ? t('plotSurfaceOf', {
            plotSurface: property.landSurface.toLocaleString(locale, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
          })
        : null,
    );
  }

  if (
    landTypes.includes(property?.propertyType?.name) &&
    property?.plotNumber != null
  ) {
    properties.push(`${t('plot')} ${property?.plotNumber ?? ''}`);
  }

  if (!commercialTypes.includes(property?.propertyType?.name)) {
    properties.push(getPropertyOccupied());
    properties.push(getPropertyUsage());
  }

  const propertyDetails = properties.filter(x => x).join(' · ');
  return (
    <Flex flexWrap="wrap">
      {showMap && (
        <Box
          flexGrow={0}
          flexShrink={0}
          width={inSidebar ? '100%' : ['100%', '140px']}
        >
          <Image
            css={media({
              display: inSidebar ? 'flex' : ['flex', 'none'],
              flexFlow: 'column',
              aspectRatio: ['5 / 2', 'auto'],
              flex: '1 1 0',
            })}
            loading={'lazy'}
            src={getStaticMapLink({
              lat,
              lng,
              zoom: 10,
              width: 256,
              height: 128,
              markers: lead.userCanViewLeadDetails ? [{ lat, lng }] : [],
            })}
          />
          {!inSidebar && (
            <Image
              css={media({
                display: ['none', 'flex'],
                flexFlow: 'column',
                aspectRatio: ['auto', '1 / 1'],
                flex: '1 1 0',
              })}
              loading={'lazy'}
              src={getStaticMapLink({
                lat,
                lng,
                zoom: 10,
                width: 200,
                height: 200,
                scale: 2,
                markers: lead.userCanViewLeadDetails ? [{ lat, lng }] : [],
              })}
            />
          )}
        </Box>
      )}
      {lead.stage?.pipeline?.leadType === 'sales' && (
        <Box flexGrow={1} flexBasis={inSidebar ? ['100%', 0, '100%'] : 0} p={3}>
          <Flex alignItems="center" flexWrap={inSidebar ? 'wrap' : 'nowrap'}>
            <Flex alignItems="center" flexGrow={1}>
              <Box css={text.subtitle2} pb={1}>
                {lead.contact?.firstName != null || lead.contact?.lastName
                  ? [
                      lead.relationship === 'owner' ? '✅' : '✖️',
                      ' ',
                      lead.contact?.firstName,
                      ' ',
                      lead.userCanViewLeadDetails
                        ? lead.contact?.lastName
                        : `${
                            lead.contact?.lastName != null
                              ? lead.contact.lastName.slice(0, 1) + '.'
                              : ''
                          }`,
                    ]
                  : [
                      lead.relationship === 'owner' ? '✅' : '✖️',
                      ' ',
                      t('unknown'),
                    ]}
              </Box>

              {(lead.referrer != null || lead.contact?.referrer) && (
                <Tooltip
                  title={
                    <>
                      <div>User: {lead.contact?.referrer}</div>
                      <div>Lead: {lead.referrer}</div>
                      <div>Landing page: {lead.landingPageUrl}</div>
                    </>
                  }
                >
                  <Avatar
                    css={{ height: 18, width: 18, marginLeft: 8 }}
                    src={`https://www.google.com/s2/favicons?domain=${
                      lead.contact?.referrer != null
                        ? getHostName(lead.contact.referrer)
                        : lead.referringDomain || ''
                    }`}
                  />
                </Tooltip>
              )}
            </Flex>
            <Flex
              alignItems="center"
              css={[text.caption, textColor('mediumText')]}
            >
              <LeadUpdatedTimeAgo
                lead={lead}
                customCreationDate={customCreationDate}
                showBothDate={isAdmin ?? false}
              />
            </Flex>
            {controls != null && <Box pl={2}>{controls}</Box>}
          </Flex>

          <Box css={[text.caption, textColor('mediumText')]} pb={1}>
            <LeadQualificationFunnel lead={lead} />
          </Box>

          <Flex
            flexWrap="wrap"
            alignItems="center"
            css={[text.subtitle2]}
            pb={1}
          >
            {getAppraisalPerception(
              colors,
              lead.appraisalPerception,
              lead.appraisalPerceivedValue,
              lead.property?.latestAppraisal?.realadvisor?.max,
              lead.property?.latestAppraisal?.realadvisor?.min,
            )}
            <Box p={1} />
            {formatCurrencyRange(
              lead.property?.latestAppraisal?.realadvisor,
              currency,
              locale,
            )}
          </Flex>

          <Box css={[text.caption, textColor('mediumText')]} pb={1}>
            {propertyDetails}
          </Box>

          {showAddress && (
            <div css={[text.body2]}>
              {lead.userCanViewLeadDetails
                ? lead.property?.formattedAddress ??
                  lead.appraisalPropertyFormattedAddress
                : lead.property?.formattedLocality}
            </div>
          )}
          {bottomButton != null && <Box mt={2}>{bottomButton}</Box>}
        </Box>
      )}
      {lead.stage?.pipeline?.leadType === 'financing' && (
        <Box flexGrow={1} flexBasis={inSidebar ? ['100%', 0, '100%'] : 0} p={3}>
          <Box>
            <Box css={text.subtitle2}>
              {lead.mortgageIsConstruction === true
                ? t('newDevelopment')
                : t('existingProperty')}
            </Box>

            {showAddress && (
              <div css={[text.body2]}>
                {lead.property?.formattedAddress ??
                  lead.mortgageGoogleAddress?.formattedAddress ??
                  '-'}
              </div>
            )}

            <Box css={text.body2}>
              {(v => {
                switch (v) {
                  case 'primary':
                    return t('primaryResidence');

                  case 'secondary':
                    return t('secondaryResidence');

                  case 'investment':
                    return t('investment');

                  default:
                    return null;
                }
              })(lead.mortgagePropertyUsage)}
            </Box>

            <Box
              css={[
                text.body1,
                {
                  color: colors.highText,
                  fontWeight: text.font.medium,
                  marginTop: 8,
                },
              ]}
            >
              {formatPrice(lead.mortgagePropertyValue, locale, currency)}
            </Box>
          </Box>
          {lead.completed &&
            lead.mortgageMortgageType != null &&
            lead.mortgageAnalysis && (
              <div css={[text.body2, textColor('mediumText')]}>
                {[
                  lead.mortgageReportType === 'financing_passport'
                    ? t('financingPassport')
                    : translateMortgageType(t, lead.mortgageMortgageType ?? ''),
                  ', ',
                  currency,
                  ' ',
                  formatNumber(lead.mortgageAnalysis?.maxPropertyValue, locale),
                ]}
              </div>
            )}
          {bottomButton != null && <Box mt={2}>{bottomButton}</Box>}
        </Box>
      )}
    </Flex>
  );
};

const BaseLeadSnippetLink = ({
  lead,
  showMap,
  showAddress,
  openOnNewTab,
  inSidebar,
  controls,
  bottomButton,
  customCreationDate,
  isAdmin,
}) => {
  const linkBase =
    lead.stage?.pipeline?.leadType === 'financing' ? 'financing' : 'leads';

  return (
    <Link
      to={`/${linkBase}/${lead.id}`}
      target={openOnNewTab ? '_blank' : '_self'}
    >
      <BaseLeadSnippet
        lead={lead}
        showMap={showMap}
        showAddress={showAddress}
        openOnNewTab={openOnNewTab}
        inSidebar={inSidebar}
        controls={controls}
        bottomButton={bottomButton}
        customCreationDate={customCreationDate}
        isAdmin={isAdmin}
      />
    </Link>
  );
};

export const LeadSnippet = ({
  showMap = true,
  showAddress = true,
  openOnNewTab = false,
  inSidebar = false,
  controls = null,
  bottomButton = null,
  hasLink = true,
  customCreationDate = null,
  ...props
}: Props): React.Node => {
  const lead = useFragment(
    graphql`
      fragment leadSnippet_lead on Lead {
        ...leadQualificationFunnel_lead
        ...leadUpdatedTimeAgo_lead
        id
        stage {
          pipeline {
            leadType
          }
        }
        referrer
        referringDomain
        landingPageUrl
        completed
        userCanViewLeadDetails
        appraisalPerceivedValue
        appraisalPerception
        appraisalPropertyUsage
        relationship
        appraisalPropertyOccupied
        mortgagePropertyValue
        mortgagePropertyUsage
        mortgageIsConstruction
        mortgageMortgageType
        mortgageReportType
        mortgageAnalysis {
          maxPropertyValue
        }
        mortgageGoogleAddress {
          formattedAddress
        }
        appraisalPropertyFormattedAddress
        property {
          countryCode
          formattedAddress
          formattedLocality
          lat
          lng
          livingSurface
          builtSurface
          landSurface
          numberOfRooms
          numberOfBedrooms
          numberOfResidentialUnits
          residentialSurface
          residentialYearlyRentalIncome
          parkingYearlyRentalIncome
          numberOfCommercialUnits
          commercialSurface
          commercialYearlyRentalIncome
          incomeIncludesParking
          plotNumber
          propertyType {
            label
            name
          }
          latestAppraisal {
            realadvisor {
              min
              max
            }
          }
        }
        contact {
          firstName
          lastName
          referrer
        }
      }
    `,
    props.lead,
  );

  const root = useLazyLoadQuery<leadSnippetQuery>(
    graphql`
      query leadSnippetQuery {
        me {
          isAdmin
        }
      }
    `,
    {},
  );

  return (
    lead != null && (
      <>
        {hasLink && (
          <BaseLeadSnippetLink
            lead={lead}
            showMap={showMap}
            showAddress={showAddress}
            openOnNewTab={openOnNewTab}
            inSidebar={inSidebar}
            controls={controls}
            bottomButton={bottomButton}
            customCreationDate={customCreationDate}
            isAdmin={root.me?.isAdmin}
          />
        )}
        {!hasLink && (
          <BaseLeadSnippet
            lead={lead}
            showMap={showMap}
            showAddress={showAddress}
            openOnNewTab={openOnNewTab}
            inSidebar={inSidebar}
            controls={controls}
            bottomButton={bottomButton}
            customCreationDate={customCreationDate}
            isAdmin={root.me?.isAdmin}
          />
        )}
      </>
    )
  );
};
