import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Alert } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { PropertyFiles } from '../../components/PropertyFiles';

import { GET_TRANSACTION_DOCUMENTS } from './transactionQueries';

export const TransactionFiles: React.FC<{ transactionId: string }> = ({
  transactionId,
}) => {
  const { t } = useLocale();
  const { data, loading, refetch, error } = useQuery(
    GET_TRANSACTION_DOCUMENTS,
    {
      variables: { id: transactionId },
    },
  );

  const propertyId = data?.property_transactions_by_pk?.property.id;
  const propertyFiles = useMemo(
    () => data?.property_transactions_by_pk?.property.property_files ?? [],
    [data?.property_transactions_by_pk?.property.property_files],
  );

  if (error != null) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (!loading && propertyId == null) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {t('No property found for this listing')}
      </Alert>
    );
  }

  return (
    <PropertyFiles
      propertyId={propertyId}
      files={propertyFiles}
      refetchQueries={[GET_TRANSACTION_DOCUMENTS]}
      onDelete={() => refetch()}
      loading={loading}
      hideColumns={['is_visible']}
    />
  );
};
