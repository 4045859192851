import { useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import AdsClickOutlined from '@mui/icons-material/AdsClickOutlined';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import Close from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { useSet } from '../../../../src/hooks/set';
import type { StepListingsCompareFragment } from '../../../__generated__/graphql';
import { INSERT_CMA_REPORTS_COMPARABLES } from '../cmaReportsQueries';

import ListingWrapper from './ListingWrapper';

const SELECTION_LIMIT = 10;

const AggregatesListings = ({
  onClose,
  data,
  refetch,
  cmaReportId,
}: {
  onClose: () => void;
  data: StepListingsCompareFragment;
  refetch: any;
  cmaReportId: string;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useLocale();
  const listings = data?.comparable_listings ?? [];
  const selection = useSet<string | number>(
    listings.map(listing => listing.aggregates_db__merged_listings_clusters_id),
  );

  const [tab, setTab] = useState<'automatic' | 'manual'>('manual');

  const badgeRef = useRef<HTMLDivElement | null>(null);
  const animateBadge = () => {
    const bounceLeftRight = {
      margin: [
        '0',
        '0 -30px 0 0',
        '0 22px 0 0',
        '0 -14px 0 0',
        '0 -8px 0 0',
        '0 4px 0 0',
        '0',
      ],
    };
    badgeRef.current
      ?.querySelector('.MuiBadge-badge')
      ?.animate(bounceLeftRight, {
        duration: 600,
        iterations: 1,
        easing: 'cubic-bezier(.6, 0.08, 0.8, .6)',
      });
  };
  const handleSelect = (id: string | number) => {
    if (selection.has(id)) {
      return selection.delete(id);
    }
    if (selection.size < SELECTION_LIMIT) {
      return selection.add(id);
    }
    // run animation that indicates it's already reached the SELECTION_LIMIT
    animateBadge();
  };

  const tabs = (
    <Tabs
      value={tab}
      onChange={(_, value) => setTab(value)}
      sx={theme => ({
        '.MuiButtonBase-root': {
          height: { xs: '48px', md: '64px' },
          textTransform: 'none',
          minWidth: '0',
        },
        '.MuiTabs-indicator': {
          backgroundColor: theme.palette.primary.main,
          borderRadius: '3px 3px 0 0',
        },
        '.Mui-selected': {
          color: theme.palette.common.black,
        },
        '.MuiTab-root:not(.Mui-selected)': {
          fontWeight: '500',
        },
      })}
    >
      <Tab
        icon={<AdsClickOutlined />}
        iconPosition="start"
        label={t('manualFiltering')}
        value="manual"
      />
      <Tab
        icon={<AutoAwesome />}
        iconPosition="start"
        label={t('automaticAlgorithm')}
        value="automatic"
      />
    </Tabs>
  );

  const [insertComparable, { loading: insertingComparable }] = useMutation(
    INSERT_CMA_REPORTS_COMPARABLES,
  );

  const buttons = (
    <Box display="flex">
      <Button
        variant="text"
        disabled={selection.size === 0}
        css={{ marginRight: 8 }}
        onClick={selection.clear}
      >
        {t('clear')}
      </Button>

      <LoadingButton
        variant="contained"
        color="primary"
        loading={insertingComparable}
        loadingPosition="start"
        startIcon={
          <Box
            sx={{
              borderRadius: '50px',
              fontSize: '12px !important',
              padding: '0 8px',
              backgroundColor:
                selection.size === SELECTION_LIMIT
                  ? theme.palette.error.dark
                  : theme.palette.primary.dark,
            }}
          >
            {insertingComparable
              ? null
              : `${selection.size}/${SELECTION_LIMIT}`}
          </Box>
        }
        onClick={() => {
          insertComparable({
            variables: {
              id: cmaReportId,
              objects: selection.values().map(id => ({
                aggregates_db__merged_listings_clusters_id: id,
                cma_report_id: cmaReportId,
              })),
            },
            update: (cache, { data: updatedData }) => {
              const insert_cma_reports_comparables =
                updatedData?.insert_cma_reports_comparables;
              if (!insert_cma_reports_comparables) {
                return;
              }

              cache.modify({
                id: cache.identify({
                  __typename: 'cma_reports',
                  id: cmaReportId,
                }),
                fields: {
                  comparable_listings() {
                    return insert_cma_reports_comparables.returning?.map(
                      listing => listing,
                    );
                  },
                },
              });
            },
            onCompleted: () => {
              onClose();
              refetch();
            },
          });
        }}
      >
        <Typography variant="button">
          {isMobile ? t('save') : t('saveSelection')}
        </Typography>
      </LoadingButton>
    </Box>
  );

  return (
    <>
      <AppBar color="inherit" elevation={0} position="sticky">
        <Toolbar
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'stretch', sm: 'center' },
            borderBottom: `2px solid ${theme.palette.divider}`,
          }}
        >
          <Box display="flex" alignItems="center" flexGrow={1}>
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <Close />
            </IconButton>
            <Box
              sx={{
                ...theme.typography.h6,
                fontWeight: theme.text.font.bold,
                flexGrow: { xs: 1, sm: {} },
              }}
            >
              {t('listings')}
            </Box>
            {!isMobile && <div css={{ flexGrow: 1 }}>{tabs}</div>}
            {buttons}
          </Box>
          {isMobile && <div css={{ marginLeft: 20 }}>{tabs}</div>}
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <ListingWrapper
          mode={tab}
          data={data}
          selection={selection}
          onSelect={handleSelect}
        />
      </Box>
    </>
  );
};

export default AggregatesListings;
