import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material';
import { Form, type FormikErrors, useFormik } from '@realadvisor/form';
import { graphql, useMutation } from 'react-relay';
import { Box, Flex, useResponsive } from 'react-system';

import { PhoneInput } from '../controls/phone-input';
import { useLocale } from '../hooks/locale';

import type { userCreateDialogMutation } from './__generated__/userCreateDialogMutation.graphql';
import type { User } from './user-input';

type Values = {
  firstName: null | string;
  lastName: null | string;
  email?: null | string;
  phoneNumber?: null | string;
  referrer?: null | string;
};

type Props = {
  initialValues?: Values;
  open: boolean;
  onClose: () => void;
  onCreate: (user: User) => void;
};

const UserCreateForm = ({
  initialValues,
  onClose,
  onCreate,
}: {
  initialValues?: Values;
  onClose: () => void;
  onCreate: (user: User) => void;
}) => {
  const { t } = useLocale();
  const [createUser, creating] = useMutation<userCreateDialogMutation>(
    graphql`
      mutation userCreateDialogMutation($input: CreateUserInput!) {
        createUser(input: $input) {
          errors {
            primaryEmail
            primaryPhoneNumber
          }
          user {
            id
            ...userInput_user @relay(mask: false)
          }
        }
      }
    `,
  );
  type State = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  const form = useFormik<State>({
    initialValues: {
      firstName: initialValues?.firstName ?? '',
      lastName: initialValues?.lastName ?? '',
      email: initialValues?.email ?? '',
      phoneNumber: initialValues?.phoneNumber ?? '',
    },
    validate: values => {
      const errors: FormikErrors<State> = {};
      if (values.firstName.trim().length === 0) {
        errors.firstName = t('This field is required');
      } else if (values.firstName.trim().length < 2) {
        errors.firstName = t('lengthAtLeastTwoCharacter');
      }
      if (values.lastName.trim().length === 0) {
        errors.lastName = t('This field is required');
      } else if (values.lastName.trim().length < 2) {
        errors.lastName = t('lengthAtLeastTwoCharacter');
      }
      return errors;
    },
    onSubmit: values => {
      const input = {
        firstName: values.firstName,
        lastName: values.lastName,
        primaryPhoneNumber:
          values.phoneNumber === '' ? null : values.phoneNumber,
        primaryEmail: values.email === '' ? null : values.email,
        referrer: initialValues?.referrer,
      };
      createUser({
        variables: { input },
        onCompleted: payload => {
          form.setResponseErrors({
            email: payload.createUser?.errors?.primaryEmail,
            phoneNumber: payload.createUser?.errors?.primaryPhoneNumber,
          });
          if (payload.createUser?.user) {
            onCreate(payload.createUser.user);
          }
        },
      });
    },
  });

  const { values, errors, setValues, setTouched } = form;

  return (
    <Form onSubmit={form.submitForm}>
      <DialogContent>
        <Flex flexWrap="wrap" mx={-2}>
          <Box p={2} width={[1, 1 / 2]}>
            <TextField
              variant="filled"
              label={t('firstName')}
              error={errors.firstName != null}
              helperText={errors.firstName}
              value={values.firstName}
              onChange={event => setValues({ firstName: event.target.value })}
              onBlur={() => setTouched({ firstName: true })}
              onPaste={event => {
                event.preventDefault();
                const data = event.clipboardData.getData('text/plain');
                const parts = data.split(/\s+/);
                if (parts.length >= 2) {
                  setValues({
                    firstName: parts[0],
                    lastName: parts.slice(1).join(' '),
                  });
                  setTouched({ firstName: true, lastName: true });
                } else {
                  setValues({ firstName: data });
                  setTouched({ firstName: true });
                }
              }}
            />
          </Box>
          <Box p={2} width={[1, 1 / 2]}>
            <TextField
              label={t('lastName')}
              variant="filled"
              error={errors.lastName != null}
              helperText={errors.lastName}
              value={values.lastName}
              onChange={event => setValues({ lastName: event.target.value })}
              onBlur={() => setTouched({ lastName: true })}
            />
          </Box>
          <Box p={2} width={[1, 1 / 2]}>
            <TextField
              label={t('emailAddress')}
              variant="filled"
              error={errors.email != null}
              helperText={errors.email}
              value={values.email}
              onChange={event => setValues({ email: event.target.value })}
              onBlur={() => setTouched({ email: true })}
            />
          </Box>
          <Box p={2} width={[1, 1 / 2]}>
            <FormControl variant="filled" error={errors.phoneNumber != null}>
              <InputLabel>{t('phoneNumber')}</InputLabel>
              <PhoneInput
                value={values.phoneNumber}
                onChange={phoneNumber => setValues({ phoneNumber })}
                onBlur={() => setTouched({ phoneNumber: true })}
              />
              {errors.phoneNumber != null && (
                <FormHelperText>{errors.phoneNumber}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Flex>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button
          color="primary"
          disabled={!form.valid || creating}
          onClick={form.submitForm}
        >
          {t('create')}
        </Button>
      </DialogActions>
    </Form>
  );
};

export const UserCreateDialog = (props: Props) => {
  const { t } = useLocale();
  const responsive = useResponsive();
  const fullScreen = responsive([true, false]);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen={fullScreen}>
      <DialogTitle>{t('createUser')}</DialogTitle>
      <UserCreateForm
        initialValues={props.initialValues}
        onClose={props.onClose}
        onCreate={props.onCreate}
      />
    </Dialog>
  );
};
