import { useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { OpenInNew } from '../../../icons/open-in-new';
import { toGlobalId } from '../../../shared/global-id';
import { type Language, useLocale } from '../../../src/hooks/locale';
import { isRATenantId } from '../../../src/utils/tenant';
import { webURL } from '../../../src/utils/url';
import { gql } from '../../__generated__';
import { type ListingQuickActionsFragment } from '../../__generated__/graphql';
import { GET_LISTING_DETAILS } from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import { ListingAccordion } from '../ListingAccordion';
import { UPDATE_LOT_MLS } from '../MlsCard';
import { TemplatesListButtonGroup } from '../TemplatesListButtonGroup';

import { ListingPortalsButton } from './ListingPortalsButton';
import { ListingStatusSelect } from './ListingStatusSelect';

const GET_LISTING_QUICK_ACTIONS_DATA = gql(/* GraphQL */ `
  query GetListingQuickActionsData($team_ids: [uuid!], $lot_id: uuid!) {
    lot_brochure_doc_templates: doc_templates(
      limit: 100
      where: { type: { _eq: lot_brochure }, active: { _eq: true } }
    ) {
      id
      name
      active
      doc_templates_tenants {
        tenant_id
        is_default
      }
    }
    seller_report_templates: doc_templates(
      limit: 100
      where: { type: { _eq: seller_report }, active: { _eq: true } }
    ) {
      id
      name
      active
      doc_templates_tenants {
        tenant_id
        is_default
      }
    }
    portals(
      order_by: { name: asc }
      where: {
        _or: [
          { portal_listings: { lot_id: { _eq: $lot_id }, live: { _eq: true } } }
          { _and: [{ team_id: { _in: $team_ids } }, { active: { _eq: true } }] }
          {
            _and: [
              { teams_portals: { team_id: { _in: $team_ids } } }
              { active: { _eq: true } }
            ]
          }
        ]
      }
    ) {
      id
      name
      active
    }
    lots_status_enum {
      value
    }
  }
`);

export const LISTING_QUICK_ACTIONS_FRAGMENT = gql(/* GraphQL */ `
  fragment ListingQuickActions on lots {
    id
    offer_type
    ...ListingStatusSelect
    ...PortalListingButton
    shared_on_mls
    broker_id
    broker {
      id
      teams_users {
        team_id
      }
    }
    sellers {
      user_id
      seller {
        id
      }
    }
    created_by_user {
      id
    }
  }
`);

interface ListingQuickActionsProps {
  listingData?: ListingQuickActionsFragment | null;
  accordionOpenedDefault?: boolean;
  isLoading?: boolean;
}

const getLinks = (
  listingId: string | null | undefined,
  tenantId: string | null | undefined,
  language: Language,
  useLegacyLinks: boolean,
) => {
  const productId =
    useLegacyLinks && listingId != null
      ? toGlobalId('Product', listingId)
      : listingId;
  const lotId =
    useLegacyLinks && listingId != null
      ? toGlobalId('Lot', listingId)
      : listingId;
  const tenant =
    useLegacyLinks && tenantId != null
      ? toGlobalId('Tenant', tenantId)
      : tenantId;

  const listingUrl = webURL('product', {
    id: productId ?? '',
    tenantId: tenant ?? '',
    language,
  });

  return {
    listingUrl,
    calendarUrl: `/calendar?activityType_in=visit&activityStatus_in=done&activityStatus_in=pending&lotId_in=${
      lotId ?? ''
    }`,
  };
};

const REALADVISOR_PORTAL_IDS = ['51a0d37a-80f5-11e8-bedf-1791f1a4be3f'];

export const ListingQuickActionsSkeleton: React.FC<{
  accordionOpenedDefault?: boolean;
}> = ({ accordionOpenedDefault }) => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const btnSize = isMobile ? 'small' : 'medium';

  return (
    <ListingAccordion
      title={t('Quick actions')}
      defaultExpanded={accordionOpenedDefault}
    >
      <Stack direction="row" sx={{ mb: 1.5, gap: 1 }} alignItems="stretch">
        <Skeleton width={100} variant="rounded">
          <Button size={btnSize}>placeholder</Button>
        </Skeleton>
        <Skeleton width={100} height="auto" variant="rounded" />
        <Skeleton width={70} height="auto" variant="rounded" />
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ gap: 1 }}
        alignItems="stretch"
      >
        <Skeleton variant="rounded" width={90} height="auto">
          <Button size={btnSize}>placeholder</Button>
        </Skeleton>
        <Skeleton variant="rounded" width={120} height="auto" />
        <Skeleton variant="rounded" width={120} height="auto" />
        <Skeleton variant="rounded" width={100} height="auto" />
      </Stack>
    </ListingAccordion>
  );
};

export const ListingQuickActions: React.FC<ListingQuickActionsProps> = ({
  listingData,
  accordionOpenedDefault = true,
  isLoading = false,
}) => {
  const { me } = useAppData();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const { data } = useQuery(GET_LISTING_QUICK_ACTIONS_DATA, {
    variables: {
      team_ids: listingData?.broker?.teams_users.map(tu => tu.team_id),
      lot_id: listingData?.id ?? '',
    },
    skip: !listingData,
  });

  // Mutation to update lot shared mls
  const [updateLotMls, { loading }] = useMutation(UPDATE_LOT_MLS);
  const { t, language } = useLocale();

  const tenantSettings = me?.tenant;
  const btnSize = isMobile ? 'small' : 'medium';

  const { listingUrl, calendarUrl } = getLinks(
    listingData?.id,
    tenantSettings?.id,
    language,
    true,
  );

  const docTemplates =
    data?.lot_brochure_doc_templates.map(template => ({
      ...template,
      id: template.id != null ? toGlobalId('DocTemplate', template.id) : '',
    })) ?? [];

  const sellerReportTemplates =
    data?.seller_report_templates.map(template => ({
      ...template,
      id: template.id != null ? toGlobalId('DocTemplate', template.id) : '',
    })) ?? [];

  const showListingLink = useMemo(() => {
    const haveLiveRAPortalListings = listingData?.portal_listings.some(
      pl => pl.live && REALADVISOR_PORTAL_IDS.includes(pl.portal_id),
    );

    return (
      isRATenantId(tenantSettings?.id ?? '') &&
      haveLiveRAPortalListings &&
      [
        'available',
        'available_coming_soon',
        'available_off_market',
        'reserved',
      ].includes(listingData?.status ?? '')
    );
  }, [listingData, tenantSettings?.id]);

  return (
    <ListingAccordion
      title={t('Quick actions')}
      defaultExpanded={accordionOpenedDefault}
    >
      <Stack direction="row" sx={{ mb: 1.5, gap: 1 }}>
        <ListingPortalsButton
          btnSize={btnSize}
          listing={listingData}
          isLoading={isLoading}
          allPortals={data?.portals ?? []}
          refetchQueries={[
            {
              query: GET_LISTING_DETAILS,
              variables: { id: listingData?.id },
            },
          ]}
        />

        <ListingStatusSelect
          size={btnSize}
          listing={listingData}
          allStatuses={data?.lots_status_enum ?? []}
          isLoading={isLoading}
        />

        {isLoading ? (
          <Skeleton width={70} variant="rounded">
            <LoadingButton size={btnSize}>placeholder</LoadingButton>
          </Skeleton>
        ) : (
          <LoadingButton
            size={btnSize}
            color={listingData?.shared_on_mls === true ? 'success' : 'neutral'}
            loading={loading}
            variant="contained"
            loadingIndicator={
              <CircularProgress disableShrink color="inherit" size={16} />
            }
            onClick={() => {
              if (listingData == null) {
                return;
              }

              const isOn = listingData.shared_on_mls === true;
              updateLotMls({
                variables: {
                  lot_id: listingData.id,
                  shared_on_mls: !isOn,
                },
              });
            }}
          >
            {listingData?.shared_on_mls === true ? t('MLS: On') : t('MLS: Off')}
          </LoadingButton>
        )}
      </Stack>

      {isLoading ? (
        <Stack
          direction="row"
          flexWrap="wrap"
          sx={{ gap: 1 }}
          alignItems="stretch"
        >
          {showListingLink && (
            <Skeleton variant="rounded" width={90} height="auto" />
          )}
          <Skeleton variant="rounded" width={120}>
            <Button size={btnSize}>placeholder</Button>
          </Skeleton>
          <Skeleton variant="rounded" width={120} height="auto" />
          <Skeleton variant="rounded" width={100} height="auto" />
        </Stack>
      ) : (
        <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
          {showListingLink && (
            <Button
              variant="outlined"
              color="neutral"
              component="a"
              size={btnSize}
              href={listingUrl}
              target="_blank"
              endIcon={<OpenInNew />}
            >
              {t('listing')}
            </Button>
          )}
          <Button
            variant="outlined"
            color="neutral"
            component="a"
            size={btnSize}
            href={calendarUrl}
            target="_blank"
            endIcon={<OpenInNew />}
          >
            {t('visitsCalendar')}
          </Button>

          {listingData != null && sellerReportTemplates.length > 0 && (
            <TemplatesListButtonGroup
              label={
                listingData?.offer_type === 'rent'
                  ? t('Lessor Report')
                  : t('Seller Report')
              }
              size={btnSize}
              docTemplates={sellerReportTemplates}
              documentId={toGlobalId('Lot', listingData.id)}
              color="neutral"
              disabled={false}
              startIcon={
                <AutoStoriesOutlinedIcon
                  sx={{ fontSize: '1.5rem !important' }}
                />
              }
            />
          )}

          {listingData != null && docTemplates.length > 0 && (
            <TemplatesListButtonGroup
              label={t('Brochure')}
              size={btnSize}
              docTemplates={docTemplates}
              documentId={toGlobalId('Lot', listingData.id)}
              color="neutral"
              disabled={false}
              startIcon={
                <AutoStoriesOutlinedIcon
                  sx={{ fontSize: '1.5rem !important' }}
                />
              }
            />
          )}
        </Stack>
      )}
    </ListingAccordion>
  );
};
