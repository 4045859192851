import { Input, InputAdornment } from '@mui/material';
import { Box } from 'react-system';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Search } from '../../icons/search';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const SearchField = (props: Props) => {
  const { t } = useLocale();
  const { colors } = useTheme();
  return (
    <Box p={2} css={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <Input
        value={props.value}
        onChange={event => props.onChange(event.target.value)}
        placeholder={t('search')}
        disableUnderline={true}
        fullWidth={true}
        autoFocus={true}
        startAdornment={
          <InputAdornment position="start">
            <Search css={{ color: colors.grey600 }} />
          </InputAdornment>
        }
      />
    </Box>
  );
};
