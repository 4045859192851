/**
 * @generated SignedSource<<33b152b6fca74c844ecf43bd8eeda6a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeadStageStatus = "active" | "lost" | "won";
export type ClaimLeadInput = {
  clientMutationId?: string | null;
  id?: string | null;
};
export type FeedAssignmentClaimLeadMutation$variables = {
  input: ClaimLeadInput;
};
export type FeedAssignmentClaimLeadMutation$data = {
  readonly claimLead: {
    readonly lead: {
      readonly hasOpenActivityForMe: boolean;
      readonly id: string;
      readonly stage: {
        readonly id: string;
        readonly label: string;
        readonly status: LeadStageStatus;
      } | null;
      readonly userCanViewLeadDetails: boolean;
    } | null;
  } | null;
};
export type FeedAssignmentClaimLeadMutation = {
  response: FeedAssignmentClaimLeadMutation$data;
  variables: FeedAssignmentClaimLeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ClaimLeadPayload",
    "kind": "LinkedField",
    "name": "claimLead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lead",
        "kind": "LinkedField",
        "name": "lead",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userCanViewLeadDetails",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasOpenActivityForMe",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadStage",
            "kind": "LinkedField",
            "name": "stage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedAssignmentClaimLeadMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedAssignmentClaimLeadMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "641c2221d681714d5dbad59412fc4815",
    "id": null,
    "metadata": {},
    "name": "FeedAssignmentClaimLeadMutation",
    "operationKind": "mutation",
    "text": "mutation FeedAssignmentClaimLeadMutation(\n  $input: ClaimLeadInput!\n) {\n  claimLead(input: $input) {\n    lead {\n      id\n      userCanViewLeadDetails\n      hasOpenActivityForMe\n      stage {\n        id\n        label\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "70b74a123b5e8b6d93dc595cd9b8e286";

export default node;
