import { useMutation, useQuery } from '@apollo/client';
import {
  DeleteForever,
  OpenInNewOutlined,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { AccordionCard } from '../../components/AccordionCard';
import { AutoAssignAssistant } from '../../components/AutoAssignAssistant';
import { ContactCard } from '../../components/contact-card/ContactCard';
import { Drawer } from '../../components/drawer/Drawer';
import { FeedSkeleton } from '../../components/feed/FeedSkeleton';
import { PropertyForm } from '../../components/property-form/PropertyForm';
import { UserActionCard } from '../../components/UserActionCard';

import AppraisalGraph from './AppraisalGraph';
import { LeadAgents } from './LeadAgents';
import { LeadFeed } from './LeadFeed';
import { ASSIGN_LEAD, GET_LEAD } from './leadsQueries';
import { MarketingCard } from './MarketingCard';

export const LeadDrawer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { leadId } = useParams() as { leadId: string };
  const { t } = useLocale();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, error, loading } = useQuery(GET_LEAD, {
    variables: { lead_id: leadId },
  });

  const [assignLead] = useMutation(ASSIGN_LEAD, {
    refetchQueries: ['GetLead', 'LeadFeed'],
  });

  return (
    <>
      <Drawer
        onClose={() =>
          navigate({
            pathname: '../',
            search: searchParams.toString(),
          })
        }
        actions={[
          {
            label: 'Mark as won',
            disabled: false,
            onClick: () => {},
            icon: <ThumbUpOutlined />,
          },
          {
            label: 'Mark as lost',
            disabled: false,
            onClick: () => {},
            icon: <ThumbDownOutlined />,
          },
          {
            label: 'Delete',
            disabled: false,
            onClick: () => {},
            icon: <DeleteForever />,
          },
          {
            label: 'Open legacy lead',
            disabled: false,
            to: `/leads/${toGlobalId('Lead', leadId)}`,
            icon: <OpenInNewOutlined />,
          },
        ]}
        title="Lead"
      >
        {error && (
          <Alert severity="error" sx={{ m: 2 }}>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </Alert>
        )}
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} md={5}>
            <Stack spacing={1}>
              <ContactCard user={data?.leads_by_pk?.contact} />
              <Paper variant="outlined">
                <Typography variant="subtitle2" sx={{ mx: 2, my: 1 }}>
                  {t('Agent')}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ pb: 1, px: 1, pr: 2 }}>
                  <UserActionCard
                    isLoading={loading}
                    onUserChanged={(userId, onError) =>
                      assignLead({
                        variables: {
                          lead_id: leadId,
                          broker_id: userId,
                        },
                        onError: error => onError(error),
                      })
                    }
                    user={data?.leads_by_pk?.broker}
                    secondaryText={
                      data?.leads_by_pk?.broker?.default_team?.name
                    }
                  />
                </Box>
              </Paper>
              <AccordionCard
                title={t('Agents')}
                variant="outlined"
                defaultExpanded={!isMobile}
              >
                <LeadAgents leadId={leadId} />
              </AccordionCard>
              <AutoAssignAssistant
                leadId={leadId}
                tenantId={data?.leads_by_pk?.tenant_id}
              />
              <Button
                component={Link}
                to={{
                  pathname: 'property',
                  search: searchParams.toString(),
                }}
              >
                {t('Edit property')}
              </Button>

              {data?.leads_by_pk?.property?.id && (
                <AppraisalGraph
                  latestAppraisalId={
                    data?.leads_by_pk?.property?.latest_appraisal?.id
                  }
                  propertyId={data.leads_by_pk.property.id}
                />
              )}
              <MarketingCard lead={data?.leads_by_pk} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            {loading && <FeedSkeleton />}
            {data?.leads_by_pk?.contact?.id && (
              <LeadFeed
                lead_id={leadId}
                contact_id={data?.leads_by_pk?.contact?.id}
                email_addresses={
                  data?.leads_by_pk?.contact?.emails?.map(
                    email_address => email_address?.email ?? '',
                  ) ?? []
                }
              />
            )}
          </Grid>
        </Grid>
      </Drawer>
      <Routes>
        <Route
          path="property"
          element={
            <Drawer
              onClose={() =>
                navigate({
                  pathname: '.',
                  search: searchParams.toString(),
                })
              }
              title={t('Edit property')}
            >
              <PropertyForm propertyId={data?.leads_by_pk?.property?.id} />
            </Drawer>
          }
        />
      </Routes>
    </>
  );
};
