import { useCallback } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { gql } from '../__generated__';
import type { AppraisalGraphQuery } from '../__generated__/graphql';

export const APPRAISAL_GRAPH_QUERY = gql(/* GraphQL */ `
  query AppraisalGraph($appraisalId: uuid!) {
    appraisals_by_pk(id: $appraisalId) {
      ...AppraisalDetails
    }
  }
`);

export const LEAD_APPRAISAL_DETAILS_FRAGMENT = gql(/* GraphQL */ `
  fragment AppraisalDetails on appraisals {
    id
    min
    max
    value
    updated_at
    valuations {
      id
      min
      value
      max
      model
      valuation_model {
        name
        short_name
      }
    }
  }
`);

export const GENERATE_APPRAISAL = gql(/* GraphQL */ `
  mutation Appraise($property_id: String!) {
    value_property(property_id: $property_id) {
      appraisal {
        ...AppraisalDetails
      }
    }
  }
`);

type UsePropertyAppraisalReturn = {
  appraiseProperty: (property_id: string) => Promise<void>;
  appraisalData: AppraisalGraphQuery['appraisals_by_pk'];
  queryLoading: boolean;
  mutationLoading: boolean;
  queryError: unknown;
  mutationError: unknown;
};

export const usePropertyAppraisal = (
  latestAppraisalId?: string,
): UsePropertyAppraisalReturn => {
  const {
    loading: queryLoading,
    data: queryData,
    error: queryError,
  } = useQuery(APPRAISAL_GRAPH_QUERY, {
    variables: { appraisalId: latestAppraisalId || '' },
    skip: !latestAppraisalId,
  });

  const [appraise, { loading: mutationLoading, error: mutationError }] =
    useMutation(GENERATE_APPRAISAL, {
      update(cache, { data: response }) {
        const newAppraisalData = response?.value_property?.appraisal;

        cache.writeQuery<AppraisalGraphQuery>({
          query: APPRAISAL_GRAPH_QUERY,
          variables: {
            appraisalId: latestAppraisalId,
          },
          data: {
            appraisals_by_pk: newAppraisalData,
          },
        });
      },
    });

  const appraiseProperty = useCallback(
    async (property_id: string) => {
      try {
        await appraise({
          variables: {
            property_id,
          },
        });
      } catch (error) {
        console.error('Error in appraiseProperty:', error);
        throw error;
      }
    },
    [appraise],
  );

  return {
    appraiseProperty,
    appraisalData: queryData?.appraisals_by_pk,
    queryLoading,
    mutationLoading,
    queryError,
    mutationError,
  };
};
