import Tooltip from '@mui/material/Tooltip';
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';

import { useLocale } from '../hooks/locale';

type Props = {
  label?: string;
  children?: Date | string | null;
  addSuffix?: boolean;
  strict?: boolean;
  tooltipTitle?: React.ReactNode;
  toolTipTimeAgo?: boolean;
};

export const TimeAgo = ({
  label,
  children,
  addSuffix,
  strict,
  tooltipTitle,
  toolTipTimeAgo = false,
}: Props) => {
  const { dateLocale, intlLocale } = useLocale();
  const date =
    children instanceof Date
      ? children
      : children != null
      ? new Date(children)
      : new Date();
  const formatFn =
    strict === true ? formatDistanceToNowStrict : formatDistanceToNow;
  const tooltipDate = new Intl.DateTimeFormat(intlLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);

  const tooltipTimeAgo =
    label ?? formatFn(date, { locale: dateLocale, addSuffix });

  return (
    <Tooltip
      title={
        tooltipTitle != null
          ? tooltipTitle
          : toolTipTimeAgo
          ? `${tooltipDate} ${tooltipTimeAgo}`
          : tooltipDate
      }
      placement="top"
      arrow={true}
    >
      <time dateTime={date.toString()}>
        {label != null
          ? label
          : formatFn(date, {
              locale: dateLocale,
              addSuffix,
            })}
      </time>
    </Tooltip>
  );
};
