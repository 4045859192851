import * as React from 'react';

import {
  Accordion,
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { parseISO } from 'date-fns';
import { de, enGB, es, fr, it } from 'date-fns/esm/locale';
import { graphql, useFragment, useMutation } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { CreateListingModal } from '../../../apollo/components/create-listing/CreateListingModal';
import IndicatorLeadStage from '../../../apollo/components/IndicatorLeadStage';
import { fromGlobalId } from '../../../shared/global-id';
import { AutocompleteMultiple } from '../../controls/autocomplete-multiple';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Cancel } from '../../icons/cancel';
import { Close } from '../../icons/close';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';
import { number_of_string } from '../../utils/number-format';

import type {
  leadQualificationCard_lead$data,
  leadQualificationCard_lead$key,
} from './__generated__/leadQualificationCard_lead.graphql';
import type {
  leadQualificationCard_root$data,
  leadQualificationCard_root$key,
} from './__generated__/leadQualificationCard_root.graphql';
import type {
  leadQualificationCardMutation,
  leadQualificationCardMutation$variables,
} from './__generated__/leadQualificationCardMutation.graphql';

type CreateListingModalState = {
  open: boolean;
  stageId: string | null;
};

type LeadQualificationFormProps = {
  lead: leadQualificationCard_lead$data;
  dictionary: leadQualificationCard_root$data;
  setCreateListingModalState: React.Dispatch<
    React.SetStateAction<CreateListingModalState>
  >;
  updateLeadHelper: (
    values: leadQualificationCardMutation$variables['input']['lead'],
  ) => void;
};

const LeadQualificationForm = ({
  lead,
  dictionary,
  setCreateListingModalState,
  updateLeadHelper,
}: LeadQualificationFormProps) => {
  const { t, language } = useLocale();
  const datePickerLocales = {
    en: enGB,
    fr,
    de,
    it,
    es,
  };
  const { colors, text } = useTheme();

  const tagsOptions = dictionary.tagsTypes.map(tag => ({
    title: tag.label,
    value: tag.name,
  }));

  return (
    <>
      <Box width={1} p={2}>
        <FormControl variant="filled">
          <InputLabel>
            {[t('Stage'), lead.stage?.pipeline?.label].join(': ')}
          </InputLabel>
          <Select
            variant="filled"
            value={lead?.stage?.id ?? ''}
            onChange={event => {
              const stageId = event.target.value as string;
              const selectedStage = dictionary.pipelinesStages
                .flatMap(p => p.stages)
                .find(s => s.id === stageId);

              if (selectedStage?.status === 'won' && lead.lot == null) {
                setCreateListingModalState({ open: true, stageId });
              } else {
                updateLeadHelper({
                  stageId,
                });
              }
            }}
          >
            {dictionary.pipelinesStages
              .map(option => [
                [
                  <ListSubheader key={option.label}>
                    {option.label}
                  </ListSubheader>,
                ],
                [
                  option.stages.map(stage => (
                    <MenuItem value={stage.id ?? ''} key={stage.name}>
                      <IndicatorLeadStage status={stage.status}>
                        {stage.label}
                      </IndicatorLeadStage>
                    </MenuItem>
                  )),
                ],
              ])
              .flatMap(o => o)}
          </Select>
        </FormControl>
      </Box>
      <Flex width={1} flexWrap="wrap">
        <Box width={1} p={2}>
          <FormControl variant="filled">
            <InputLabel shrink>{t('Mandate probability')}</InputLabel>
            <Select
              variant="filled"
              defaultValue={lead?.mandateProbability ?? ''}
              displayEmpty
              onChange={event =>
                updateLeadHelper({
                  mandateProbability:
                    event.target.value != null
                      ? number_of_string(event.target.value as string)
                      : null,
                })
              }
            >
              <MenuItem value="" key={'none'}>
                <i>{t('Not set')}</i>
              </MenuItem>
              {dictionary.mandateProbabilityTypes.map(option => (
                <MenuItem value={option.name} key={option.name}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width={1} p={2}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={datePickerLocales[language]}
          >
            <FormControl variant="filled">
              <MobileDatePicker
                views={['year', 'month']}
                openTo="year"
                label={t('Predicted listing date')}
                value={
                  lead?.predictedListingDate
                    ? parseISO(lead?.predictedListingDate ?? '')
                    : null
                }
                onMonthChange={(date: Date) => {
                  updateLeadHelper({
                    predictedListingDate:
                      date != null ? new Date(date).toISOString() : null,
                  });
                }}
                onChange={() => {}}
                closeOnSelect={true}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment:
                        lead?.predictedListingDate != null ? (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              color="inherit"
                              onClick={event => {
                                event.stopPropagation();
                                updateLeadHelper({
                                  predictedListingDate: null,
                                });
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    },
                  },
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </Box>
        <Box width={1} p={2}>
          <FormControl variant="filled">
            <AutocompleteMultiple
              options={tagsOptions}
              value={tagsOptions.filter(tag =>
                lead?.tags?.some(t => t.dictionary?.name === tag.value),
              )}
              tagData={lead.tags}
              renderInput={params => (
                <TextField
                  {...params}
                  css={
                    lead?.tags?.length > 0 && {
                      '.MuiAutocomplete-inputRoot': {
                        paddingTop: '25px',
                        paddingBottom: '8px',
                      },
                      '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                        paddingLeft: '14px',
                      },
                      '.MuiAutocomplete-endAdornment': {
                        top: '14px',
                      },
                    }
                  }
                  variant="filled"
                  label={t('Tags')}
                  placeholder={t('Add tag')}
                />
              )}
              onChange={(_event, newValue) => {
                updateLeadHelper({
                  tags: newValue?.map(tag => tag.value),
                });
              }}
            />
          </FormControl>
          {lead.stage?.status === 'lost' && (
            <Flex alignItems="center" mt={2}>
              <Box mr={2}>
                <Avatar
                  css={{
                    backgroundColor: colors.error,
                    width: 28,
                    height: 28,
                  }}
                >
                  <Close fill={colors.white} />
                </Avatar>
              </Box>
              <Box css={[text.subtitle1, text.ellipsis]}>{t('Lead lost')}</Box>
            </Flex>
          )}
        </Box>
      </Flex>
    </>
  );
};

type LeadDetailsQualificationCardProps = {
  lead: leadQualificationCard_lead$key;
  defaultExpanded: boolean;
  root: leadQualificationCard_root$key;
};

export const LeadDetailsQualificationCard = (
  props: LeadDetailsQualificationCardProps,
) => {
  const { t, intlLocale } = useLocale();
  const navigate = useNavigate();

  const [createListingModalState, setCreateListingModalState] =
    React.useState<CreateListingModalState>({
      open: false,
      stageId: null,
    });

  const lead = useFragment(
    graphql`
      fragment leadQualificationCard_lead on Lead {
        id
        mandateProbability
        predictedListingDate
        tags {
          id
          dictionary {
            name
          }
          ...autocompleteMultiple_tag
        }
        lot {
          id
        }
        stage {
          id
          label
          status
          pipeline {
            id
            label
          }
        }
      }
    `,
    props.lead,
  );

  const root = useFragment(
    graphql`
      fragment leadQualificationCard_root on Query {
        me {
          id
          modules
        }
        mandateProbabilityTypes: dictionaries(
          type: "mandate_probability_types"
        ) {
          name
          label
        }
        tagsTypes: dictionaries(type: "tags_types") {
          id
          name
          label
        }
        pipelinesStages: pipelines(leadType: sales) {
          id
          label
          stages {
            id
            label
            name
            status
          }
        }
      }
    `,
    props.root,
  );

  const dictionary = root;
  const isBrokerageModuleIncluded = root.me?.modules.includes('brokerage');

  // expand if probability is set
  const hasProbability = lead.mandateProbability != null;
  const [expanded, setExpanded] = React.useState(
    hasProbability || props.defaultExpanded,
  );

  const dateTimeFormat = new Intl.DateTimeFormat(intlLocale, {
    month: 'long',
    year: 'numeric',
  });

  const subTitle = [];
  lead.tags != null && subTitle.push(lead.stage?.label);

  const mandateProbabilityLabel = dictionary.mandateProbabilityTypes.find(
    item => number_of_string(item.name) === lead.mandateProbability,
  )?.label;
  if (mandateProbabilityLabel != null) {
    subTitle.push(mandateProbabilityLabel);
  }
  lead.predictedListingDate != null &&
    subTitle.push(dateTimeFormat.format(new Date(lead.predictedListingDate)));

  const [updateLead] = useMutation<leadQualificationCardMutation>(graphql`
    mutation leadQualificationCardMutation($input: UpsertLeadInput!) {
      upsertLead(input: $input) {
        lead {
          ...leadQualificationCard_lead
          ...leadDetails_lead
        }
      }
    }
  `);

  const updateLeadHelper = (
    values: leadQualificationCardMutation$variables['input']['lead'],
  ) => {
    updateLead({
      variables: {
        input: {
          lead: {
            id: lead.id,
            ...values,
          },
        },
      },
    });
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={(_event, expanded) => {
          setExpanded(expanded);
        }}
      >
        <AccordionCardSummary
          expanded={expanded}
          title={t('Details')}
          subTitle={subTitle.join(', ').substring(0, 26)}
        />
        <Box p={2}>
          <LeadQualificationForm
            lead={lead}
            dictionary={dictionary}
            key={subTitle.join(', ')}
            setCreateListingModalState={setCreateListingModalState}
            updateLeadHelper={updateLeadHelper}
          />
        </Box>
      </Accordion>

      <CreateListingModal
        fromLeadId={fromGlobalId(lead.id)}
        open={createListingModalState.open}
        onClose={() =>
          setCreateListingModalState({ open: false, stageId: null })
        }
        onListingCreated={lotId => {
          if (isBrokerageModuleIncluded) {
            navigate(`/listings/${lotId}`);
          }
          updateLeadHelper({
            stageId: createListingModalState.stageId,
          });
        }}
      />
    </>
  );
};
