// @flow

import * as React from 'react';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';

import { Menu } from '../../controls/popup';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Lock } from '../../icons/lock';
import { LockOpen } from '../../icons/lock-open';
import { People } from '../../icons/people';

type Props = {|
  visibility: ?string,
  onChange: (visibility: 'private' | 'shared' | 'organisation') => void,
  disabled?: boolean,
  title?: string,
|};

export const VisibilityButton = ({
  visibility,
  onChange,
  disabled,
  title,
}: Props): React.Node => {
  const [menu, setMenu] = React.useState(false);
  const targetRef = React.useRef(null);
  const { t } = useLocale();
  const { colors } = useTheme();

  if (visibility == null) {
    return null;
  }

  return (
    <>
      <IconButton
        disabled={disabled}
        size="small"
        css={{
          ':disabled': {
            background: colors.grey700,
            color: colors.white,
          },
          background:
            visibility === 'private' ? colors.grey300 : colors.grey700,
          color: visibility === 'private' ? colors.grey600 : colors.white,
          '&:hover': {
            background:
              visibility === 'private' ? colors.grey200 : colors.grey600,
          },
          padding: '7px',
        }}
        ref={targetRef}
        color={visibility === 'private' ? 'default' : 'primary'}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setMenu(val => !val);
        }}
        title={title}
      >
        {visibility === 'shared' && <LockOpen size={22} />}
        {visibility === 'private' && <Lock size={22} />}
        {visibility === 'organisation' && <People size={22} />}
      </IconButton>

      <Menu referenceRef={targetRef} open={menu} onClose={() => setMenu(false)}>
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onChange('shared');
            setMenu(false);
          }}
        >
          <ListItemIcon>
            <LockOpen size={24} />
          </ListItemIcon>
          <ListItemText
            css={{ whiteSpace: 'normal' }}
            primary={t('shared')}
            secondary={t(
              'thisActivityWillBeVisibleToEveryoneInYourAndAgentCompany',
            )}
          />
        </MenuItem>

        <MenuItem
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onChange('organisation');
            setMenu(false);
          }}
        >
          <ListItemIcon>
            <People size={24} />
          </ListItemIcon>
          <ListItemText
            css={{ whiteSpace: 'normal' }}
            primary={t('sharedWithinYourOrganisation')}
            secondary={t('thisActivityWillBeVisibleToEveryoneInYourCompany')}
          />
        </MenuItem>

        <MenuItem
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onChange('private');
            setMenu(false);
          }}
        >
          <ListItemIcon>
            <Lock size={24} />
          </ListItemIcon>
          <ListItemText
            css={{ whiteSpace: 'normal' }}
            primary={t('private')}
            secondary={t('thisActivityWillOnlyBeVisibleToYou')}
          />
        </MenuItem>
      </Menu>
    </>
  );
};
