import { useCallback, useMemo, useState } from 'react';

import {
  type ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import Save from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import {
  Activity_Types_Enum_Enum,
  Leads_Status_Enum,
} from '../../__generated__/graphql';
import { CreateListingModal } from '../../components/create-listing/CreateListingModal';
import IndicatorLeadStage from '../../components/IndicatorLeadStage';
import { MutationErrorSnackbar } from '../../components/MutationErrorModal';
import RASnackbar from '../../components/RASnackbar';

import { GET_LEAD } from './leadsQueries';

const GET_PIPELINES = gql(/* GraphQL */ `
  query GetPipelines($leadType: lead_type!) {
    pipelines(where: { lead_type: { _eq: $leadType } }) {
      id
      label
      stages(order_by: { order_nr: asc }) {
        id
        label
        status
      }
    }
  }
`);

const UPDATE_MULTIPLE_LEADS_STAGE = gql(/* GraphQL */ `
  mutation UpdateMultipleLeadsStage(
    $ids: [uuid!]!
    $stageId: uuid!
    $activities: [activities_insert_input!]!
  ) {
    update_leads(where: { id: { _in: $ids } }, _set: { stage_id: $stageId }) {
      returning {
        id
        stage {
          id
          label
        }
      }
    }
    insert_activities(objects: $activities) {
      returning {
        id
      }
    }
  }
`);

type DialogUpdatePipelineContentProps = Omit<DialogUpdatePipelineProps, 'open'>;

const DialogUpdatePipelineContent = ({
  leadsIds,
  pipeline,
  initialPipelineId,
  initialStageId,
  onCancel,
  updateCacheFn,
}: DialogUpdatePipelineContentProps) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [apolloError, setApolloError] = useState<ApolloError | null>(null);

  const [form, setForm] = useState({
    pipelineId: initialPipelineId ?? '',
    stageId: initialStageId ?? '',
  });

  const [targetStage, setTargetStage] = useState<{
    status: Leads_Status_Enum;
    stageId: string;
    pipelineId: string;
  } | null>(null);
  const [multiWonWarning, setMultiWonWarning] = useState(false);

  const { data } = useQuery(GET_PIPELINES, {
    variables: { leadType: pipeline },
  });

  const [updateMultipleLeadsStage, { loading: updateLoading }] = useMutation(
    UPDATE_MULTIPLE_LEADS_STAGE,
    { onError: setApolloError },
  );
  const [getLead] = useLazyQuery(GET_LEAD);

  const pipelines = useMemo(() => data?.pipelines ?? [], [data]);

  const stages = useMemo(
    () =>
      (pipelines.length === 1
        ? pipelines[0]
        : pipelines.find(p => p.id === form.pipelineId)
      )?.stages ?? [],
    [pipelines, form.pipelineId],
  );

  const updateLeadsStage = useCallback(
    async (stageId: string, note?: string) =>
      await updateMultipleLeadsStage({
        variables: {
          ids: leadsIds,
          stageId,
          activities: note
            ? leadsIds.map(leadId => ({
                lead_id: leadId,
                activity_type: Activity_Types_Enum_Enum.Note,
                note,
                success: false,
                done: true,
                done_at: new Date().toISOString(),
              }))
            : [],
        },
      }),
    [updateMultipleLeadsStage, leadsIds],
  );

  const handleClose = useCallback(() => {
    setTargetStage(null);
    setMultiWonWarning(false);
    onCancel();
  }, [onCancel]);

  const onSubmit = useCallback(async () => {
    const selectedStage = stages.find(s => s.id === form.stageId);
    if (!selectedStage) {
      return;
    }

    if (selectedStage.status === 'won' && leadsIds.length > 1) {
      setMultiWonWarning(true);
      return;
    }

    updateCacheFn({
      stageId: selectedStage.id,
      leadIds: leadsIds,
    });

    if (selectedStage.status === Leads_Status_Enum.Won) {
      const lead = await getLead({
        variables: {
          lead_id: leadsIds[0],
        },
      });

      // We have a listing, so we don't need to create a listing
      // just update the stage and close the dialog
      if (lead.data?.leads_by_pk?.lots.length !== 0) {
        await updateLeadsStage(selectedStage.id);
        handleClose();
        return;
      }

      setTargetStage({
        status: selectedStage.status as Leads_Status_Enum.Won,
        stageId: selectedStage.id,
        pipelineId: form.pipelineId,
      });
    } else {
      let note;
      if (selectedStage.status === Leads_Status_Enum.Lost) {
        note = selectedStage.label ?? '';
      }

      await updateLeadsStage(selectedStage.id, note);
      handleClose();
    }
  }, [
    stages,
    leadsIds,
    updateCacheFn,
    form.stageId,
    form.pipelineId,
    getLead,
    updateLeadsStage,
    handleClose,
  ]);

  const isLoading = updateLoading;

  return (
    <>
      <DialogTitle>
        {pipelines.length > 1
          ? t('Change pipeline and stage')
          : t('Change stage')}
      </DialogTitle>
      <DialogContent>
        {pipelines.length > 1 && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
              {t('Pipeline')}
            </FormLabel>
            <Select
              fullWidth
              variant="outlined"
              size="small"
              sx={{ background: 'white' }}
              value={form.pipelineId}
              onChange={event => {
                setForm({
                  pipelineId: event.target.value as string,
                  stageId: '',
                });
              }}
            >
              {pipelines.map(pipeline => (
                <MenuItem key={pipeline.id} value={pipeline.id}>
                  {pipeline.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {t('Stage')}
          </FormLabel>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ background: 'white' }}
            value={form.stageId}
            onChange={event => {
              setForm(prev => ({
                ...prev,
                stageId: event.target.value as string,
              }));
            }}
          >
            {stages.map(stage => (
              <MenuItem key={stage.id} value={stage.id}>
                <IndicatorLeadStage status={stage.status}>
                  {stage.label}
                </IndicatorLeadStage>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button onClick={handleClose}>{t('Cancel')}</Button>
        <LoadingButton
          onClick={onSubmit}
          loadingPosition="start"
          startIcon={<Save />}
          loading={isLoading}
          color="primary"
          variant="contained"
          disabled={!form.stageId}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>

      {targetStage?.status === 'won' && (
        <CreateListingModal
          fromLeadId={leadsIds[0]}
          open
          onClose={handleClose}
          onListingCreated={lotId => {
            updateLeadsStage(targetStage.stageId).then(() => {
              navigate(`/listings/${lotId}`);
              handleClose();
            });
          }}
        />
      )}

      {multiWonWarning && (
        <RASnackbar
          message={t(
            'Converting multiple leads to listings must be done individually',
          )}
          onClose={() => setMultiWonWarning(false)}
        />
      )}

      <MutationErrorSnackbar
        error={apolloError}
        onClose={() => setApolloError(null)}
      />
    </>
  );
};

type DialogUpdatePipelineProps = {
  leadsIds: string[];
  pipeline: string;
  initialPipelineId?: string | null;
  initialStageId?: string | null;
  open: boolean;
  onCancel: () => void;
  updateCacheFn: (args: { stageId: string; leadIds: string[] }) => void;
};

export const DialogUpdatePipeline = ({
  open,
  onCancel,
  ...props
}: DialogUpdatePipelineProps) => (
  <Dialog
    open={open}
    onClose={onCancel}
    PaperProps={{
      sx: {
        width: '100%',
        maxWidth: 400,
        margin: '0 auto',
        zIndex: 15000,
      },
    }}
  >
    <DialogUpdatePipelineContent {...props} onCancel={onCancel} />
  </Dialog>
);

export default DialogUpdatePipeline;
