import {
  type ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Skeleton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useLocale } from '../../src/hooks/locale';
import type { SetHook } from '../../src/hooks/set';

import { ConditionalLinkWrapper } from './ConditionalLinkWrapper';

export type GridItem = { id: string | number; [key: string]: any };

type RaGridBaseProps<T extends GridItem> = {
  items?: T[];
  loading?: boolean;
  loadingCount?: boolean;
  totalCount?: number;
  pageSize?: number;
  emptyMessage?: string;
  renderSkeleton: () => ReactNode;
  renderItemCard: (item: T) => ReactNode;
  getItemLink?: (id: string | number) => {
    pathname: string;
    search: string;
  };
  selection?: SetHook<string | number>;
};

export type RaGridProps<T extends GridItem> = RaGridBaseProps<T>;

const gridItemProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 2,
};

const RaGrid = <T extends GridItem>({
  items = [],
  loading = false,
  loadingCount = false,
  totalCount = 0,
  pageSize = 24,
  emptyMessage,
  renderSkeleton,
  renderItemCard,
  getItemLink,
  selection,
}: RaGridProps<T>) => {
  const { t } = useLocale();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isEmpty = !loading && items.length === 0;
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamName = 'offset'; // Hardcoded to always use 'offset'
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Calculate page from URL params
  const calculatePageFromUrl = useCallback(() => {
    const offset = parseInt(searchParams.get(searchParamName) ?? '0');
    return Math.floor(offset / pageSize) + 1;
  }, [searchParams, pageSize, searchParamName]);

  const [currentPage, setCurrentPage] = useState(calculatePageFromUrl());

  // Update page when URL changes
  useEffect(() => {
    setCurrentPage(calculatePageFromUrl());
  }, [searchParams, pageSize, calculatePageFromUrl]);

  const handlePageChange = (page: number) => {
    searchParams.set(searchParamName, ((page - 1) * pageSize).toString());
    setSearchParams(searchParams);
    setCurrentPage(page);

    // Scroll the container to top instead of the window
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  return (
    <Box flexGrow={1} sx={{ position: 'relative', zIndex: 0 }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <Box
          ref={scrollContainerRef}
          sx={{
            overflow: 'auto',
            height: '100%',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ minHeight: '100%' }}>
            <Grid
              container
              spacing={2}
              p={2}
              sx={{
                mt: 0,
                pt: 0,
                minHeight: '0',
                justifyContent: isEmpty ? 'center' : 'flex-start',
              }}
            >
              {loading ? (
                Array.from({ length: pageSize }).map((_, index) => (
                  <Grid item key={`skeleton-${index}`} {...gridItemProps}>
                    {renderSkeleton()}
                  </Grid>
                ))
              ) : isEmpty ? (
                <Grid item>
                  <Typography variant="body1" align="center">
                    {emptyMessage || t('No results found')}
                  </Typography>
                </Grid>
              ) : (
                items.map(item => {
                  const itemId = item.id;
                  const isSelected = selection?.has(itemId);
                  const content = renderItemCard(item);

                  // Modified approach: let the card handle its own selection
                  // We just pass isSelected through, but don't manage click events

                  // Wrap with Link only if getItemLink is provided
                  const linkProps = getItemLink
                    ? {
                        to: getItemLink(itemId),
                        style: {
                          textDecoration: 'none',
                          height: '100%',
                          display: 'block',
                        },
                      }
                    : undefined;

                  return (
                    <Grid
                      item
                      key={`item-${itemId}`}
                      {...gridItemProps}
                      sx={{
                        ...(isSelected && {
                          '& > *': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                          },
                        }),
                      }}
                    >
                      <ConditionalLinkWrapper link={linkProps}>
                        {content}
                      </ConditionalLinkWrapper>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Box>
          {totalCount > 0 || loadingCount ? (
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                zIndex: 2,
                backgroundColor: 'background.paper',
                borderTop: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Toolbar
                sx={{ display: 'flex', justifyContent: ['center', 'start'] }}
              >
                {loadingCount ? (
                  <Skeleton
                    variant="rectangular"
                    width={300}
                    height={36}
                    sx={{ borderRadius: 1 }}
                  />
                ) : (
                  <Pagination
                    count={Math.ceil(totalCount / pageSize)}
                    color="primary"
                    shape="rounded"
                    siblingCount={isMobile ? 0 : 1}
                    page={currentPage}
                    sx={{ justifyContent: 'center' }}
                    onChange={(_e, page) => handlePageChange(page)}
                    renderItem={item => (
                      <PaginationItem
                        slots={{ next: ArrowForward, previous: ArrowBack }}
                        {...item}
                      />
                    )}
                  />
                )}
              </Toolbar>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(RaGrid) as unknown as <T extends GridItem>(
  props: RaGridProps<T>,
) => JSX.Element;
