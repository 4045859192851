import {
  type Reducer,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Fade,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DataGridPremium,
  GridActionsCellItem,
  type GridColDef,
  type GridRowId,
  type GridRowSelectionModel,
  type GridRowsProp,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { useFileDialog } from '@realadvisor/hooks';
import { Image } from '@realadvisor/image';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { v4 } from 'uuid';

import { FullScreenDropZone } from '../../../shared/dropzone';
import { type Translate, useLocale } from '../../../src/hooks/locale';
import { customPalette } from '../../../src/styles';
import { useFileUpload } from '../../utils/files/file-upload';
import { useIsDragging } from '../../utils/mouseEvents';
import { NoPhotosPlaceholder } from '../NoPhotoPlaceholder';
import { UploadImageCard } from '../UploadCard';

import { FullScreenCarousel } from './FullScreenCarousel';
import { ImageUploadingCard } from './ImageUploadingCard';

export type ImageGalleryItem = {
  id: string;
  title?: string;
  description?: string;
  order: number;
  image: {
    id: string;
    url?: string | null;
  };
};

interface ImageGalleryProps {
  images: ImageGalleryItem[];
  extraMetaColumns?: GridColDef<ImageGalleryItem>[];
  onDelete?: (id: string) => Promise<any>;
  onFilesUploaded: (images: { id: string; url: string }[]) => Promise<any>;
  onImageOrderChange?: (id: string, targetPosition: number) => Promise<any>;
  editForm?: React.ReactNode;
  hideEditColumn?: boolean;
  defaultSelectedImage?: string;
}

const CurrentEditImageContext = createContext<null | ImageGalleryItem>(null);
export const useCurrentEditImage = () => useContext(CurrentEditImageContext);

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.grey[300],
  borderRadius: 3,
}));

const EditImageDrawer: React.FC<{
  images: ImageGalleryItem[];
  editForm?: React.ReactNode;
  onFullScreenClicked: (index: number | null) => void;
  onIndexChange: (index: number) => void;
  onCloseDrawer: () => void;
}> = ({
  editForm,
  images,
  onFullScreenClicked,
  onIndexChange,
  onCloseDrawer,
}) => {
  const { imgId } = useParams();
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const mountedFirstTime = useRef(true);
  const currentImageIndex = images.findIndex(img => img.id === imgId);
  const currentImage =
    currentImageIndex !== -1 ? images[currentImageIndex] : null;

  const [open, setOpen] = useState(false);
  // Render Drawer animation and propagate current index to parent
  useEffect(() => {
    if (mountedFirstTime.current === false) {
      return;
    }

    setOpen(true);
    mountedFirstTime.current = false;
    onIndexChange(currentImageIndex);
  }, [currentImageIndex, onIndexChange]);

  const handleCloseDrawer = () => {
    setOpen(false);
    setTimeout(() => onCloseDrawer(), 300);
  };

  const renderDrawerContent = () => (
    <>
      <Box p={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {currentImage?.image.url != null ? (
            <Box
              sx={{
                position: 'relative',
                borderRadius: '10px',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  inset: 0,
                  zIndex: 1,
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 22%), linear-gradient(180deg, rgba(0, 0, 0, 0) 78.25%, rgba(0, 0, 0, 0.3) 100%)',
                }}
              ></Box>
              <IconButton
                sx={{
                  color: 'white.main',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 2,
                }}
                aria-label={t('Fullscreen')}
                onClick={() => {
                  onFullScreenClicked(
                    currentImageIndex > -1 ? currentImageIndex : null,
                  );
                }}
              >
                <FullscreenIcon />
              </IconButton>
              <Typography
                sx={{
                  color: 'white.main',
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  zIndex: 2,
                  lineHeight: 1,
                }}
              >{`${currentImageIndex + 1}/${images.length}`}</Typography>
              <Image
                src={currentImage.image.url}
                options={{ h: 360, f: 'jpg' }}
                objectFit="none"
                css={{
                  maxWidth: '100%',
                  maxHeight: '360px',
                }}
              />
            </Box>
          ) : (
            <NoPhotosPlaceholder
              sx={{ borderRadius: '10px', width: 480, maxWidth: '100%' }}
              height={360}
            />
          )}
        </Box>

        {currentImageIndex > -1 && (
          <Stack
            spacing={4}
            sx={{ marginTop: 2 }}
            justifyContent="space-between"
            direction="row"
          >
            <Button
              size="small"
              startIcon={<ChevronLeftIcon />}
              disabled={currentImageIndex === 0}
              onClick={() => onIndexChange(currentImageIndex - 1)}
            >
              {t('Previous')}
            </Button>
            <Button
              size="small"
              endIcon={<ChevronRightIcon />}
              disabled={currentImageIndex === images.length - 1}
              onClick={() => onIndexChange(currentImageIndex + 1)}
            >
              {t('Next')}
            </Button>
          </Stack>
        )}
      </Box>
      {currentImage != null && (
        <CurrentEditImageContext.Provider value={currentImage}>
          {editForm != null && editForm}
        </CurrentEditImageContext.Provider>
      )}
    </>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleCloseDrawer}
      onOpen={() => {}}
      sx={{
        '& > .MuiPaper-root': {
          height: '90%',
          overflow: 'hidden',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          bgcolor: customPalette.superLightBlue,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px 0',
          position: 'absolute',
          zIndex: 10,
          top: 0,
          left: 0,
          width: '100%',
          bgcolor: customPalette.superLightBlue,
        }}
      >
        <Puller />
      </Box>
      <Box sx={{ height: '100%', overflow: 'auto', pt: 3 }}>
        {renderDrawerContent()}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Drawer
      variant="persistent"
      anchor="right"
      open
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          boxSizing: 'border-box',
          position: 'absolute',
          border: 0,
          bgcolor: customPalette.superLightBlue,
        },
      }}
    >
      {renderDrawerContent()}
    </Drawer>
  );
};

const getGridColumns = (
  t: Translate,
  deleteAction: (imageId: string) => void,
  additionalFields: GridColDef<ImageGalleryItem>[] = [],
): GridColDef<ImageGalleryItem>[] => [
  {
    field: 'image',
    width: 100,
    renderHeader: () => null,
    display: 'flex',
    renderCell: params => (
      <Box sx={{ height: '50px', width: '100%' }}>
        {params.row.image.url != null ? (
          <Image
            src={params.row.image.url}
            options={{ w: 80, f: 'jpg' }}
            objectFit="cover"
            css={{ borderRadius: 6 }}
          />
        ) : (
          <NoPhotosPlaceholder sx={{ borderRadius: '6px' }} ratio={0.625} />
        )}
      </Box>
    ),
    sortable: false,
  },
  {
    field: 'title',
    headerName: t('Title'),
    minWidth: 180,
    display: 'flex',
    flex: 1,
    sortable: false,
    renderCell: params => (
      <Stack
        sx={theme => ({
          '& .MuiTypography-root': theme.mixins.truncate(),
          width: '100%',
        })}
      >
        <Typography variant="body1">
          {params.row.title || t('No title')}
        </Typography>
        <Typography variant="caption">
          {params.row.description || t('No description')}
        </Typography>
      </Stack>
    ),
  },
  ...additionalFields.map(field => ({ ...field, sortable: false })),
  {
    field: 'actions',
    type: 'actions',
    width: 40,
    getActions: params => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label={t('Delete')}
        onClick={() => deleteAction(params.row.id)}
        color="inherit"
        key="delete-image-action"
      />,
    ],
  },
];

type Action<T extends string = string, P = undefined> = P extends undefined
  ? {
      type: T;
    }
  : {
      type: T;
      payload: P;
    };

type UploadState = {
  id: string;
  tempUrl: string;
  state: 'uploading' | 'success' | 'error';
  error?: Error;
}[];

type UPLOAD_BATCH_IMAGE_ACTION = Action<
  'upload_batch',
  { id: string; tempUrl: string }[]
>;
type UPLOAD_IMAGE_SUCCESS_ACTION = Action<'upload_success', { ids: string[] }>;
type UPLOAD_IMAGE_ERROR_ACTION = Action<
  'upload_error',
  { ids: string[]; error: Error }
>;
type REMOVE_UPLOAD_IMAGE_ACTION = Action<
  'remove_upload_img',
  { ids: string[] }
>;
type RESET_UPLOAD_IMAGES = Action<'reset_upload_images'>;

const uploadReducer: Reducer<
  UploadState,
  | UPLOAD_BATCH_IMAGE_ACTION
  | UPLOAD_IMAGE_SUCCESS_ACTION
  | UPLOAD_IMAGE_ERROR_ACTION
  | REMOVE_UPLOAD_IMAGE_ACTION
  | RESET_UPLOAD_IMAGES
> = (state, action) => {
  switch (action.type) {
    case 'upload_batch':
      return [
        ...state,
        ...action.payload.map(
          img =>
            ({
              ...img,
              state: 'uploading',
            } as UploadState[number]),
        ),
      ];
    case 'upload_success':
      return state.map(img =>
        action.payload.ids.includes(img.id)
          ? { ...img, state: 'success' }
          : img,
      );
    case 'upload_error':
      return state.map(img =>
        action.payload.ids.includes(img.id)
          ? { ...img, state: 'error', error: action.payload.error }
          : img,
      );
    case 'remove_upload_img':
      return state.filter(img => !action.payload.ids.includes(img.id));
    case 'reset_upload_images':
      return [];
  }
};

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  onDelete,
  onFilesUploaded,
  onImageOrderChange,
  editForm,
  extraMetaColumns,
  hideEditColumn = false,
  defaultSelectedImage,
}) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const [gridImagesEl, setGridImagesEl] = useState<HTMLElement | null>(null);
  const isDraggingImages = useIsDragging({ target: gridImagesEl });

  const [fullscreenImageIndex, setFullscreenImageIndex] = useState<
    null | number
  >(null);
  const [imageToDelete, setImageToDelete] = useState<null | string>(null);
  const [selectedRow, setSelectedRow] = useState<null | GridRowId>(null);
  const [uploadingImages, dispatchUploadState] = useReducer(uploadReducer, []);
  const [uploadFile] = useFileUpload('image');

  const handleFilesDrop = (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    dispatchUploadState({ type: 'reset_upload_images' });
    handleBatchUpload(files);
  };

  const handleBatchUpload = async (files: File[]) => {
    const filesWithId = files.map(file => ({ id: v4(), file }));

    dispatchUploadState({
      type: 'upload_batch',
      payload: filesWithId.map(({ id, file }) => ({
        id,
        tempUrl: URL.createObjectURL(file),
      })),
    });

    const uploadResults = await Promise.allSettled(
      filesWithId.map(({ id, file }) => {
        return new Promise<{ url: string; id: string }>((resolve, reject) => {
          uploadFile(file, ({ data, error }) => {
            if (data) {
              return resolve({ url: data.url, id });
            }

            dispatchUploadState({
              type: 'upload_error',
              payload: { ids: [id], error },
            });

            reject(error);
          });
        });
      }),
    );

    const uploadedFiles = uploadResults
      .filter(
        (
          result,
        ): result is PromiseFulfilledResult<{ url: string; id: string }> =>
          result.status === 'fulfilled',
      )
      .map(result => result.value);

    const uploadedIds = uploadedFiles.map(({ id }) => id);

    try {
      await onFilesUploaded(uploadedFiles);

      dispatchUploadState({
        type: 'upload_success',
        payload: { ids: uploadedIds },
      });

      setTimeout(() => {
        dispatchUploadState({
          type: 'remove_upload_img',
          payload: { ids: uploadedIds },
        });
      }, 300);
    } catch (err: any) {
      dispatchUploadState({
        type: 'upload_error',
        payload: { ids: uploadedIds, error: err },
      });
    }
  };

  const openFileDialog = useFileDialog({
    accept: 'image/*',
    onChange: handleFilesDrop,
  });

  const fullScreenCarouselImages = useMemo(
    () =>
      images
        .filter(img => img.image.url != null)
        .map(img => ({
          id: img.id,
          url: img.image.url as string,
          title: img.title,
        })),
    [images],
  );

  const columns = useMemo(
    () => getGridColumns(t, id => setImageToDelete(id), extraMetaColumns),
    [t, extraMetaColumns],
  );
  const rows: GridRowsProp<ImageGalleryItem> = images;

  const onRowSelected = useCallback(
    (id: GridRowSelectionModel[number] | null) => {
      setSelectedRow(id);
      navigate({
        pathname: id == null ? './' : `./${id}`,
        search: searchParams.toString(),
      });
    },
    [navigate, searchParams],
  );

  const onGridRefChange = useCallback((el: HTMLElement | null) => {
    if (el != null) {
      setGridImagesEl(el);
    }
  }, []);

  useEffect(() => {
    if (defaultSelectedImage != null && selectedRow == null && !isMobile) {
      onRowSelected(defaultSelectedImage);
    }
  }, [defaultSelectedImage, onRowSelected, selectedRow, isMobile]);

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid
        item
        xs={12}
        md={hideEditColumn ? 12 : 6}
        sx={theme => ({
          overflow: 'auto',
          height: '100%',
          borderRight: `1px solid ${theme.palette.divider}`,
          position: 'relative',
        })}
      >
        <FullScreenDropZone
          onDrop={handleFilesDrop}
          disabled={isDraggingImages}
        />

        <Box
          p={1}
          sx={theme => ({
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: theme.palette.subMenu,
          })}
        >
          <UploadImageCard onClick={openFileDialog} noMinHeight />
        </Box>

        {uploadingImages.length > 0 && (
          <Grid container padding={1} spacing={1}>
            {uploadingImages.map(uploadingImg => {
              const imgError =
                uploadingImg.state === 'error'
                  ? new Error(t('An error occured while uploading the image.'))
                  : undefined;

              return (
                <Grid item xs={4} md={3} lg={2} key={uploadingImg.id}>
                  <Fade in={uploadingImg.state !== 'success'}>
                    <ImageUploadingCard
                      imageUrl={uploadingImg.tempUrl}
                      isLoading={uploadingImg.state === 'uploading'}
                      error={imgError}
                      height={100}
                    />
                  </Fade>
                </Grid>
              );
            })}
          </Grid>
        )}

        {useMemo(
          () => (
            <DataGridPremium
              slotProps={{
                loadingOverlay: {
                  variant: 'skeleton',
                  noRowsVariant: 'skeleton',
                },
              }}
              sx={{
                borderRadius: 0,
                borderRight: 0,
                backgroundColor: 'white.main',
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: 'none',
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: 'none',
                  },
              }}
              ref={onGridRefChange}
              autoHeight
              rows={rows}
              columns={columns}
              rowReordering={onImageOrderChange != null}
              onRowOrderChange={newModel =>
                onImageOrderChange?.(
                  newModel.row.id,
                  rows[newModel.targetIndex].order,
                )
              }
              getRowId={row => row.id}
              rowHeight={65}
              disableAutosize={true}
              disableColumnMenu={true}
              disableColumnSelector={true}
              disableColumnFilter={true}
              disableColumnResize={true}
              disableColumnSorting={true}
              disableMultipleRowSelection={true}
              disableColumnReorder={true}
              disableRowSelectionOnClick={hideEditColumn}
              hideFooter={true}
              rowSelectionModel={selectedRow != null ? [selectedRow] : []}
              onRowSelectionModelChange={model => {
                if (model.length === 1) {
                  onRowSelected(model[0]);
                }
              }}
            />
          ),
          [
            columns,
            onImageOrderChange,
            onRowSelected,
            onGridRefChange,
            rows,
            selectedRow,
            hideEditColumn,
          ],
        )}
      </Grid>
      {hideEditColumn !== true && (
        <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
          <Routes>
            <Route
              path=":imgId"
              element={
                <EditImageDrawer
                  editForm={editForm}
                  images={images}
                  onFullScreenClicked={index => setFullscreenImageIndex(index)}
                  onIndexChange={index => onRowSelected(images[index].id)}
                  onCloseDrawer={() => onRowSelected(null)}
                />
              }
            />
          </Routes>
        </Grid>
      )}

      {onDelete && (
        <Dialog
          open={imageToDelete != null}
          onClose={() => setImageToDelete(null)}
        >
          <DialogTitle>{t('Delete image')}</DialogTitle>
          <DialogContent>
            {t('Are you sure you want to delete this image?')}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setImageToDelete(null)}>
              {t('Cancel')}
            </Button>
            <Button
              onClick={async () => {
                if (imageToDelete != null) {
                  try {
                    await onDelete(imageToDelete);
                    if (selectedRow === imageToDelete) {
                      onRowSelected(null);
                    }
                  } catch {
                    // Do nothing because handled at parent level.
                  }
                }

                setImageToDelete(null);
              }}
            >
              {t('Delete')}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {fullScreenCarouselImages.length > 0 && (
        <FullScreenCarousel
          images={fullScreenCarouselImages}
          viewIndex={fullscreenImageIndex ?? 0}
          open={fullscreenImageIndex != null}
          onCloseFullScreen={() => setFullscreenImageIndex(null)}
        />
      )}
    </Grid>
  );
};
