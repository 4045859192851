import { memo, useCallback, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { UPDATE_CMA_REPORT_STEP_VISIBILITY } from '../cmaReportsQueries';

import { type ComponentStep, type StepType } from './CMAEditorContext';
import type { CMAReportType } from './CMAReportEditor';
import { useCMAEditor } from './CMAReportEditorWorkflow';

type StepperListItemProps = {
  item: StepType;
  index: number;
  isExpanded?: boolean;
  isActive: boolean;
  isStepComponentExpanded?: boolean;
  setIsStepComponentExpanded?: (expanded: boolean) => void;
  handleStepSelect?: (index: number) => void;
};

const createOptimisticResponse = (
  cmaReport: CMAReportType,
  item: ComponentStep,
  newValue: boolean,
) => ({
  update_cma_reports_by_pk: {
    id: cmaReport.id,
    updated_at: new Date().toISOString(),
    include_cover: cmaReport.include_cover,
    include_introduction: cmaReport.include_introduction,
    include_property: cmaReport.include_property,
    include_description: cmaReport.include_description,
    include_photos: cmaReport.include_photos,
    include_hedonistic_valuation: cmaReport.include_hedonistic_valuation,
    include_intrinsic_valuation: cmaReport.include_intrinsic_valuation,
    include_cuprate_valuation: cmaReport.include_cuprate_valuation,
    include_final_value: cmaReport.include_final_value,
    include_potential_buyers: cmaReport.include_potential_buyers,
    include_agent: cmaReport.include_agent,
    include_agent_agency: cmaReport.include_agent_agency,
    include_client_testimonial: cmaReport.include_client_testimonial,
    include_append_files: cmaReport.include_append_files,
    include_comparables_valuation: cmaReport.include_comparables_valuation,
    ...(item.databaseToggleField
      ? { [item.databaseToggleField]: newValue }
      : {}),
    __typename: 'cma_reports',
  },
});

const StepperListItem = ({
  item,
  index,
  isExpanded,
  isActive,
  isStepComponentExpanded,
  setIsStepComponentExpanded,
  handleStepSelect,
}: StepperListItemProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const { setCurrentStepByIndex, cmaReport } = useCMAEditor();

  const [updateStepVisibility] = useMutation(
    UPDATE_CMA_REPORT_STEP_VISIBILITY,
    {
      optimisticResponse: createOptimisticResponse(
        cmaReport,
        item as ComponentStep,
        !(item as ComponentStep).active,
      ),
    },
  );

  const handleClick = useCallback(() => {
    if ('onClick' in item && typeof item.onClick === 'function') {
      item.onClick();
      return;
    }

    if (isStepComponentExpanded === false) {
      setIsStepComponentExpanded?.(true);
    }
    handleStepSelect?.(index);
    setCurrentStepByIndex(index);
  }, [
    handleStepSelect,
    index,
    isStepComponentExpanded,
    item,
    setCurrentStepByIndex,
    setIsStepComponentExpanded,
  ]);

  const handleToggleVisibility = useCallback(
    async (e: React.MouseEvent, step: StepType) => {
      e.stopPropagation();
      if ('databaseToggleField' in step && step.databaseToggleField) {
        await updateStepVisibility({
          variables: {
            id: cmaReport.id,
            input: { [step.databaseToggleField]: !step.active },
          },
        });
      }
    },
    [cmaReport.id, updateStepVisibility],
  );

  const getListItemStyles = useMemo(
    () => ({
      padding: 0,
      '&:hover': { bgcolor: 'grey.200' },
      backgroundColor: isActive ? 'grey.300' : 'white',
      textWrap: 'nowrap',
      ...('pageId' in item &&
        item.pageId === 'page-settings' && {
          marginTop: 'auto',
          borderTop: isMobile ? '3px solid' : '1px solid',
          borderColor: 'divider',
        }),
    }),
    [isActive, item, isMobile],
  );

  return (
    <ListItem disablePadding onClick={handleClick} sx={getListItemStyles}>
      <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
        <ListItemIcon
          sx={{
            color: 'grey.700',
            minWidth: isExpanded ? undefined : 0,
            paddingLeft: 0.5,
            ...(isMobile && {
              minWidth: 40,
            }),
          }}
        >
          {item.icon}
        </ListItemIcon>
        {(isExpanded || isMobile) && (
          <>
            <ListItemText primary={item.title} sx={{ whiteSpace: 'wrap' }} />
            {'active' in item && (
              <IconButton
                onClick={e => handleToggleVisibility(e, item)}
                size="small"
              >
                {item.active ? (
                  <VisibilityIcon sx={{ fontSize: 20, color: 'black' }} />
                ) : (
                  <VisibilityOff sx={{ fontSize: 20, color: 'grey.500' }} />
                )}
              </IconButton>
            )}
          </>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default memo(StepperListItem);
