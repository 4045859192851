import { useMemo, useState } from 'react';

import { type QueryOptions, useMutation, useQuery } from '@apollo/client';
import { Delete, Person, Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
} from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  type GridColDef,
  type GridRowSelectionModel,
  type GridRowsProp,
} from '@mui/x-data-grid-premium';
import { Image } from '@realadvisor/image';
import {
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { UserCreateDialog } from '../../../src/shared/user-create-dialog';
import {
  type DeleteUsersMutation,
  type GetUsersListCountQuery,
  type GetUsersListQuery,
  type Users_Bool_Exp,
} from '../../__generated__/graphql';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import { SmartListTitle } from '../../components/SmartListTitle';
import { TimeAgo } from '../../components/TimeAgo';
import { UserSelect } from '../../components/user-select/UserSelect';
import { useAppData } from '../../providers/AppDataProvider';
import { getPaginationVariables } from '../../utils/paginations';
import { prepareWhereClauseQuery } from '../../utils/parseWhereClause';

import { UserDrawer } from './UserDrawer';
import {
  DELETE_USERS,
  GET_USERS_LIST,
  GET_USERS_LIST_COUNT,
} from './userQueries';
import { UsersExport } from './UsersExport';
import { UsersImport } from './UsersImport';

type User = GetUsersListQuery['users'][number];

export const initialUsersListQuery = (
  searchParams: URLSearchParams,
): QueryOptions => {
  const where: Users_Bool_Exp = prepareWhereClauseQuery(
    JSON.parse(searchParams.get('where') || '{}'),
  );
  const defaultFilters: Users_Bool_Exp = {
    is_deleted: { _eq: false },
  };
  const order_by = JSON.parse(
    searchParams.get('order_by') || '[{ "created_at": "desc" }]',
  );
  const { limit, offset } = getPaginationVariables(searchParams);

  return {
    query: GET_USERS_LIST,
    variables: {
      where: { ...defaultFilters, ...where },
      order_by,
      limit,
      offset,
    },
  };
};

export const Users = () => {
  const [searchParams] = useSearchParams();
  const editMode = searchParams.get('edit') === 'true';
  const navigate = useNavigate();
  const { t } = useLocale();
  const { me } = useAppData();
  const [selectedUsers, setSelectedUsers] = useState<GridRowSelectionModel>([]);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const legacyMatch = useMatch('/users');
  const { variables } = useMemo(
    () => initialUsersListQuery(searchParams),
    [searchParams],
  );
  const { where, order_by, limit, offset } = variables ?? {};

  const userLink = (id: string) =>
    legacyMatch
      ? {
          pathname: `/v1/users/${toGlobalId('User', id)}`,
        }
      : {
          pathname: `/v2/users/${id}`,
          search: searchParams.toString(),
        };

  const { data, loading, error } = useQuery<GetUsersListQuery>(GET_USERS_LIST, {
    variables: {
      where,
      order_by,
      limit,
      offset,
    },
  });
  const users: GridRowsProp<User> = data?.users ?? [];

  const { countLimit } = getPaginationVariables(searchParams, undefined, true);
  const { data: countData } = useQuery<GetUsersListCountQuery>(
    GET_USERS_LIST_COUNT,
    {
      variables: {
        where,
        limit: countLimit,
      },
    },
  );

  const [deleteUsers, deleteOptions] = useMutation<DeleteUsersMutation>(
    DELETE_USERS,
    {
      refetchQueries: [
        {
          query: GET_USERS_LIST,
          variables: {
            where,
            order_by,
            limit,
            offset,
          },
        },
      ],
    },
  );

  const handleDelete = async (ids: string[]) => {
    await deleteUsers({
      variables: {
        ids,
      },
      refetchQueries: [
        {
          query: GET_USERS_LIST,
          variables: {
            where,
            order_by,
            limit,
            offset,
          },
        },
      ],
    });
    if (deleteOptions.error) {
      console.error(deleteOptions.error);
    } else {
      setSelectedUsers([]);
    }
  };

  if (error) {
    return <Alert severity="error">{JSON.stringify(error)}</Alert>;
  }

  const columns: GridColDef<User>[] = [
    {
      field: 'created_at',
      headerName: 'Created',
      width: 75,
      renderCell: ({ value }) => <TimeAgo dateString={value} />,
    },
    {
      field: 'avatar',
      headerName: '',
      width: 50,
      sortable: false,
      filterable: false,
      display: 'flex',
      renderCell: ({ row }) => {
        const imageUrl = row?.user_images?.[0]?.image?.url;
        return (
          <Avatar>
            {imageUrl != null ? (
              <Image src={imageUrl} srcSize={[[40, 40]]} />
            ) : (
              [
                row.first_name?.charAt(0) ?? null,
                row.last_name?.charAt(0) ?? null,
              ].join('') ?? <Person />
            )}
          </Avatar>
        );
      },
    },
    {
      field: 'first_name',
      headerName: t('First name'),
      width: 100,
    },
    {
      field: 'last_name',
      headerName: t('Last name'),
      width: 150,
    },
    {
      field: 'emails',
      headerName: t('Emails'),
      width: 250,
      valueGetter: (_, row) => {
        return row.emails
          .sort(a => (a.primary ? -1 : 1))
          .map(({ email }) => email)
          .join(', ');
      },
    },
    {
      field: 'phone_numbers',
      headerName: t('Phone Numbers'),
      width: 150,
      valueGetter: (_, row) => {
        return row.phone_numbers
          .sort(a => (a.primary ? -1 : 1))
          .map(({ number }) => number)
          .join(', ');
      },
    },
  ];

  return (
    <>
      <SmartListTitle tableName="users" defaultTitle={t('Users')} />
      <Snackbar open={deleteOptions.error != null} autoHideDuration={6000}>
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
          {deleteOptions.error?.message}
        </Alert>
      </Snackbar>
      <ListToolbar
        tableName="users"
        quickFilters={[
          {
            label: 'Created at',
            path: ['users_bool_exp', 'created_at'],
          },
          {
            label: 'Subscription status',
            path: ['users_bool_exp', 'subscriptions', 'status', '_in'],
          },
          {
            label: 'Show in agency pages',
            path: ['users_bool_exp', 'show_in_agency_pages'],
          },
        ]}
        searchComponent={
          <UserSelect
            autoFocus
            InputProps={{
              startAdornment: <Search />,
            }}
            sx={{ maxWidth: 350, flexGrow: 1, ml: 0 }}
            onChange={userId => {
              if (!userId) {
                return;
              }
              navigate(userLink(userId));
            }}
          />
        }
        newLink="new"
        ImportDialog={UsersImport}
        ExportDialog={UsersExport}
        bulkActions={
          me?.is_admin && (
            <>
              <LoadingButton
                disableElevation
                onClick={() => {
                  setDeleteOpen(true);
                }}
                loading={deleteOptions.loading}
                loadingPosition="start"
                startIcon={<Delete />}
              >
                {t('Delete')}
              </LoadingButton>
              <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
                <DialogTitle>
                  {t('Are you sure you want to delete these users?')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t(
                      'This action cannot be undone. You are about to delete {{count}} users.',
                      {
                        count: selectedUsers.length,
                      },
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteOpen(false)}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    onClick={() => {
                      handleDelete(selectedUsers as string[]);
                      setDeleteOpen(false);
                    }}
                    color="error"
                  >
                    {t('Delete')}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }
        showRealCountOption
      />
      <Divider sx={{ opacity: 0.5 }} />
      <RaDataGrid
        columns={columns}
        rows={users}
        rowCount={countData?.users_aggregate?.aggregate?.count ?? 0}
        approximateRowCount
        onRowClick={({ id }) => navigate(userLink(id as string))}
        loading={loading}
        checkboxSelection={editMode}
        disableRowSelectionOnClick
        onRowSelectionModelChange={newSelection => {
          setSelectedUsers(newSelection);
        }}
        rowSelectionModel={selectedUsers}
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
        }}
        columnVisibilityModel={{
          [GRID_CHECKBOX_SELECTION_COL_DEF.field]: editMode,
        }}
        sx={{
          borderRadius: 0,
          borderColor: 'transparent',
        }}
      />
      <Routes>
        <Route
          path="new"
          element={
            <UserCreateDialog
              open={true}
              onClose={() => navigate('.')}
              onCreate={({ id }) => navigate(`/v1/users/${id}`)}
            />
          }
        />
        <Route path=":userId/*" element={<UserDrawer />} />
      </Routes>
    </>
  );
};

export default Users;
