import { Suspense, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import {
  Alert,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { Variant } from '@mui/material/styles/createTypography';
import { Carousel } from '@realadvisor/carousel';
import { Image } from '@realadvisor/image';
import { useParams } from 'react-router-dom';

import MarkdownParser from '../../../shared/markdown-parser';
import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import {
  Order_By,
  type UpdateListingMutation,
  type UpdateListingMutationVariables,
  type UpdateListingSellersMutation,
  type UpdateListingSellersMutationVariables,
} from '../../__generated__/graphql';
import { AgentCard } from '../../components/AgentCard';
import { CollapsibleContent } from '../../components/CollapsibleContent';
import { ELeadCardDisplayMode, LeadCard } from '../../components/lead/LeadCard';
import { LeadPipelineForm } from '../../components/lead/LeadPipelineForm';
import { LeadSelect } from '../../components/LeadsSelect';
import {
  ListingBuyerLeadsCard,
  ListingBuyerLeadsCardSkeleton,
} from '../../components/listing-overview/ListingBuyerLeadsCard';
import {
  ListingMandateCard,
  ListingMandateCardSkeleton,
} from '../../components/listing-overview/ListingMandateCard';
import { ListingMap } from '../../components/listing-overview/ListingMap';
import {
  ListingOffersCard,
  ListingOffersCardSkeleton,
} from '../../components/listing-overview/ListingOffersCard';
import {
  ListingQuickActions,
  ListingQuickActionsSkeleton,
} from '../../components/listing-overview/ListingQuickActions';
import {
  ListingTransactionsCard,
  ListingTransactionsCardSkeleton,
} from '../../components/listing-overview/ListingTransactionsCard';
import {
  EEditListingForms,
  ListingOverviewEditModal,
} from '../../components/listing-overview-form/ListingOverviewEditModal';
import { ListingAccordion } from '../../components/ListingAccordion';
import {
  ListingPrice,
  ListingPriceSkeleton,
} from '../../components/ListingPrice';
import { NoPhotosPlaceholder } from '../../components/NoPhotoPlaceholder';
import { PointsOfInterest } from '../../components/PointsOfInterest';
import { PropertyDataListSkeleton } from '../../components/PropertyDataList';
import { PropertyDetails } from '../../components/PropertyDetails';
import { PropertyFeatures } from '../../components/PropertyFeatures';
import RASnackbar from '../../components/RASnackbar';
import {
  SellersActionCard,
  SellersActionCardSkeleton,
} from '../../components/SellersActionCard';
import { UserActionCard } from '../../components/UserActionCard';
import { UserCardSkeleton } from '../../components/UserCard';
import { useAppData } from '../../providers/AppDataProvider';
import { getAbbrTimeAgo } from '../../utils/abbr';
import {
  formatAddress,
  formatListingAvailability,
  toHumanReadableDate,
} from '../../utils/formatting';
import type { ScrappedListing } from '../cma-reports/step-listings-compare/ListingComparableDetails';

import {
  GET_LISTING_DETAILS,
  type GetListingDetailsData,
  UPDATE_LISTING,
} from './lotsQueries';

export const LISTING_OVERVIEW_PROPERTY_FRAGMENT = gql(/* GraphQL */ `
  fragment ListingOverviewProperty on properties {
    ...PropertyPoi
    ...PropertyFeatures
    ...PropertyDetails
    ...PropertyFragment

    # Costs
    residential_yearly_rental_income
    residential_potential_rental_income
    commercial_yearly_rental_income
    commercial_potential_rental_income
    income_includes_parking
    is_parking_fully_rented
    parking_yearly_rental_income
    parking_potential_rental_income
    charges
    renovation_fund

    # Energy
    energy_certificate_version
    energy_reference_year
    energy_inspection_date
    estimated_lower_energy_cost
    estimated_higher_energy_cost
    energy_consumption_class
    gas_emissions_class
    consumption
    emissions

    # Misc
    minergie_code
    plot_number

    floor_of_flat
  }
`);

export const LISTING_OVERVIEW_FRAGMENT = gql(/* GraphQL */ `
  fragment ListingOverview on lots {
    id
    title
    description
    created_at
    status
    currency
    sale_price
    offer_type
    price_unit
    rent_net
    rent_extra
    minimum_net_seller_price
    commission_rate
    available_from
    virtual_visit_url
    video_url
    ...AddressOverridesListing
    hide_price_on_portals
    lot_reference
    broker_id
    parking_price
    is_parking_included_in_total
    broker {
      ...AgentCard_user
    }
    ...ListedByOverridesListing
    ...ListingPrice
    ...MandateForm
    ...ListingBuyerLeads
    ...ListingQuickActions
    sellers {
      lot_id
      user_id
      seller {
        ...UserCard_user
      }
    }
    created_by_user {
      id
      first_name
      last_name
    }
    lead {
      ...LeadCard
      ...LeadPipelineForm
    }
    property {
      ...ListingOverviewProperty
      ...ListingMap
      property_images(order_by: { order_nr: asc }) {
        id
        property_id
        image {
          id
          url
        }
      }
    }
  }
`);

const UPDATE_LISTING_SELLERS = gql(/* GraphQL */ `
  mutation UpdateListingSellers(
    $id: uuid!
    $sellers: [lot_sellers_insert_input!]!
  ) {
    delete_lot_sellers(where: { lot_id: { _eq: $id } }) {
      affected_rows
    }
    insert_lot_sellers(objects: $sellers) {
      returning {
        user_id
        seller {
          ...UserCard_user
        }
      }
    }
  }
`);

const EditableRow: React.FC<
  {
    onEdit?: () => void;
    variant: Variant;
    hideIcon?: boolean;
  } & React.PropsWithChildren
> = ({ onEdit, children, variant, hideIcon = false }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="flex-start"
      justifyContent="flex-start"
      marginBottom={1}
      onClick={onEdit}
      sx={{
        cursor: 'pointer',
        ...(hideIcon
          ? {}
          : {
              '&:hover': {
                '& .row-edit-icon': {
                  opacity: 1,
                },
              },
            }),
      }}
    >
      <Box sx={{ flex: 1, minWidth: 0 }}>{children}</Box>
      <Box
        className="row-edit-icon"
        sx={{
          opacity: { xs: 1, md: 0 },
          transition: 'opacity 0.3s ease',
          lineHeight: theme.typography[variant].lineHeight,
          fontSize: theme.typography[variant].fontSize,
        }}
        color={theme.palette.primary.main}
      >
        <EditIcon
          sx={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        />
      </Box>
    </Stack>
  );
};

type TGalleryImage = {
  type: 'image' | 'placeholder';
  imageUrl?: string | null;
  bucketName?: string | null;
  fileName?: string | null;
};

const ImagesCarouselSkeleton: React.FC = () => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('md'));
  const isTablet = useMediaQuery(breakpoints.up('sm'));

  const nbImagesDisplayed = isDesktop ? 3.5 : isTablet ? 2.5 : 1.5;

  return (
    <Stack direction="row" spacing={1} sx={{ maxWidth: '100%' }}>
      {Array.from(new Array(Math.ceil(nbImagesDisplayed))).map((_, index) => (
        <Skeleton key={index} variant="rectangular" width={405} height={270} />
      ))}
    </Stack>
  );
};

type ImagesCarouselType = {
  listing: GetListingDetailsData | ScrappedListing;
};

export const ImagesCarousel = ({ listing }: ImagesCarouselType) => {
  const { t } = useLocale();
  const [imageLoadError, setImageLoadError] = useState(false);

  const timeAgo = getAbbrTimeAgo(listing.created_at, t);
  const firstName =
    'created_by_user' in listing
      ? listing.created_by_user?.first_name
      : undefined;
  const lastName =
    'created_by_user' in listing
      ? listing.created_by_user?.last_name
      : undefined;
  const createdBy =
    firstName != null && lastName != null
      ? `${firstName.charAt(0)}. ${lastName}`
      : null;

  const propertyImages: TGalleryImage[] =
    'property' in listing
      ? listing.property.property_images.map(({ image }) => ({
          type: 'image',
          imageUrl: image?.url,
        }))
      : listing.images?.map(image => ({
          type: 'image',
          bucketName: image?.bucket_name,
          fileName: image?.file_name,
          imageUrl: null,
        })) ?? [];

  const imageRatio = 2 / 3;
  const nbImagesDisplayed = 3.5;
  const propertyImagesNb = propertyImages.length;

  if (propertyImagesNb < nbImagesDisplayed) {
    for (let i = 0; i < Math.ceil(nbImagesDisplayed) - propertyImagesNb; i++) {
      propertyImages.push({ type: 'placeholder' });
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Carousel
        media={[
          {
            gap: 5,
            itemsOnScreen: 1.5,
            aspectRatio: imageRatio,
          },
          {
            gap: 5,
            itemsOnScreen: 2.5,
            aspectRatio: imageRatio,
          },
          {
            gap: 5,
            itemsOnScreen: nbImagesDisplayed,
            aspectRatio: imageRatio,
          },
        ]}
      >
        {propertyImages.map((item, index) => {
          if (item.type === 'image') {
            if (item.imageUrl != null) {
              const options = { w: 400, f: 'jpg' } as const;

              return (
                <Image
                  key={index}
                  css={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  loading={'lazy'}
                  options={options}
                  objectFit="cover"
                  src={item.imageUrl}
                />
              );
            } else if (item.bucketName && item.fileName && !imageLoadError) {
              return (
                <Image
                  key={index}
                  src={{
                    bucketName: item.bucketName,
                    fileName: item.fileName,
                  }}
                  css={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  onError={() => setImageLoadError(true)}
                />
              );
            }
          }
          return <NoPhotosPlaceholder key={index} ratio={imageRatio} />;
        })}
      </Carousel>

      <Stack
        spacing={1}
        sx={{ position: 'absolute', zIndex: 1, top: 16, left: 16 }}
        direction="row"
      >
        {createdBy && (
          <Box
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: 1,
              bgcolor: 'rgb(52, 52, 52)',
              color: '#fff',
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: 700 }}
            >{`${timeAgo} - ${createdBy}`}</Typography>
          </Box>
        )}
        {'lot_reference' in listing && listing.lot_reference && (
          <Box
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: 1,
              bgcolor: 'grey.300',
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              {listing.lot_reference}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const ListingOverviewSkeleton: React.FC<{
  accordionsOpenedDefault?: boolean;
}> = ({ accordionsOpenedDefault }) => {
  const { t } = useLocale();

  return (
    <>
      <ImagesCarouselSkeleton />
      <Grid
        container
        spacing={{ xs: 0, sm: 2 }}
        px={{ xs: 0, sm: 2 }}
        py={2}
        padding={2}
        alignItems="flex-start"
      >
        <Grid item xs={12} md={8}>
          <ListingQuickActionsSkeleton
            accordionOpenedDefault={accordionsOpenedDefault}
          />
          <ListingAccordion
            title={t('Edit listing')}
            defaultExpanded={accordionsOpenedDefault}
          >
            <Typography variant="h5" marginBottom={2}>
              <Skeleton />
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginBottom={2}
            >
              <PlaceOutlinedIcon />
              <Typography variant="body1">
                <Skeleton width={200} />
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginBottom={2}
            >
              <CalendarMonthOutlinedIcon />
              <Typography variant="body1">
                <Skeleton width={200} />
              </Typography>
            </Stack>
            <Divider sx={{ width: 40, borderColor: 'black', marginY: 2.5 }} />
            <ListingPriceSkeleton />
            <Box marginBottom={2}>
              <Typography variant="body2">
                <Skeleton width="100%" />
              </Typography>
              <Typography variant="body2">
                <Skeleton width="100%" />
              </Typography>
              <Typography variant="body2">
                <Skeleton width="100%" />
              </Typography>
              <Typography variant="body2">
                <Skeleton width="100%" />
              </Typography>
              <Typography variant="body2">
                <Skeleton width="60%" />
              </Typography>
            </Box>
            <PropertyDataListSkeleton title={t('Property details')} />
            {/* No need to mock more components because it won't be in viewport */}
          </ListingAccordion>
        </Grid>
        <Grid item md={4} xs={12}>
          <ListingAccordion
            title={t('Agent')}
            defaultExpanded={accordionsOpenedDefault}
          >
            <UserCardSkeleton condensed hasSecondaryText />
          </ListingAccordion>

          <SellersActionCardSkeleton openedDefault={accordionsOpenedDefault} />

          <ListingMandateCardSkeleton openedDefault={accordionsOpenedDefault} />

          <ListingBuyerLeadsCardSkeleton
            openedDefault={accordionsOpenedDefault}
          />

          <ListingOffersCardSkeleton openedDefault={accordionsOpenedDefault} />

          <ListingTransactionsCardSkeleton
            openedDefault={accordionsOpenedDefault}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const ListingOverview = () => {
  const { t, locale } = useLocale();
  const theme = useTheme();
  const [currentEditForm, setCurrentEditForm] =
    useState<EEditListingForms | null>(null);
  const { lotId } = useParams();
  const { me } = useAppData();
  const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);
  const { data, error } = useQuery(GET_LISTING_DETAILS, {
    variables: { id: lotId ?? '' },
    skip: lotId == null,
  });
  const [updateListing] = useMutation<
    UpdateListingMutation,
    UpdateListingMutationVariables
  >(UPDATE_LISTING);
  const [updateListingSellers] = useMutation<
    UpdateListingSellersMutation,
    UpdateListingSellersMutationVariables
  >(UPDATE_LISTING_SELLERS);

  const accordionsOpenedDefault = useMediaQuery(theme.breakpoints.up('sm'));

  const listing = data?.lots_by_pk;

  return error != null ? (
    <Alert severity="error" sx={{ m: 2 }}>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </Alert>
  ) : listing == null ? (
    <ListingOverviewSkeleton
      accordionsOpenedDefault={accordionsOpenedDefault}
    />
  ) : (
    <>
      <ImagesCarousel listing={listing} />
      <Grid
        container
        spacing={{ xs: 0, sm: 2 }}
        px={{ xs: 0, sm: 2 }}
        py={2}
        padding={2}
        alignItems="flex-start"
      >
        <Grid item xs={12} md={8} mb={{ xs: 1, sm: 0 }}>
          <ListingQuickActions
            listingData={listing}
            accordionOpenedDefault={accordionsOpenedDefault}
          />
          <ListingAccordion
            title={t('Edit listing')}
            defaultExpanded={accordionsOpenedDefault}
          >
            <EditableRow
              variant="h5"
              onEdit={() =>
                setCurrentEditForm(EEditListingForms.TITLE_DESCRIPTION)
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontStyle:
                    listing?.title != null && listing.title.length > 0
                      ? 'normal'
                      : 'italic',
                }}
              >
                {listing.title || t('Add title')}
              </Typography>
            </EditableRow>
            <EditableRow
              variant="body2"
              onEdit={() => setCurrentEditForm(EEditListingForms.ADDRESS)}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <PlaceOutlinedIcon />
                <Typography variant="body2">
                  {formatAddress(listing.property, ', ', true, {
                    hideRoute: listing.address_display_option === 'hide_street',
                    hideStreetNumber:
                      listing.address_display_option === 'hide_street_number',
                    useDummyAddress: listing.use_dummy_address,
                    dummyData: listing,
                  })}
                </Typography>
              </Stack>
            </EditableRow>
            <EditableRow
              variant="body2"
              onEdit={() => setCurrentEditForm(EEditListingForms.AVAILABILITY)}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <CalendarMonthOutlinedIcon />
                <Typography variant="body2">
                  {formatListingAvailability(listing.available_from, t)}
                </Typography>
              </Stack>
            </EditableRow>
            <Divider sx={{ width: 40, borderColor: 'black', marginY: 2.5 }} />
            <EditableRow
              variant="h5"
              onEdit={() => setCurrentEditForm(EEditListingForms.PRICING)}
            >
              <ListingPrice listing={listing} />
            </EditableRow>
            <EditableRow
              variant="h6"
              onEdit={() =>
                setCurrentEditForm(EEditListingForms.TITLE_DESCRIPTION)
              }
            >
              <Box sx={{ cursor: 'pointer' }}>
                <Typography variant="h6" sx={{ fontWeight: 700, my: 1 }}>
                  {t('description')}
                </Typography>
                {listing.description?.length ? (
                  <CollapsibleContent nbLinesToShow={6}>
                    <Box
                      sx={{
                        typography: 'body2',
                        '& p, ol, ul': {
                          marginTop: 0,
                          marginBottom: '1em',
                        },
                      }}
                    >
                      <MarkdownParser markdown={listing.description} />
                    </Box>
                  </CollapsibleContent>
                ) : (
                  <em>{t('Add description')}</em>
                )}
              </Box>
            </EditableRow>
            <EditableRow
              variant="h6"
              onEdit={() => setCurrentEditForm(EEditListingForms.PROPERTY)}
            >
              <PropertyDetails property={listing.property} />
            </EditableRow>

            <EditableRow
              variant="h6"
              onEdit={() => setCurrentEditForm(EEditListingForms.EQUIPMENT)}
            >
              <PropertyFeatures property={listing.property} />
            </EditableRow>
            <EditableRow
              variant="h6"
              onEdit={() => setCurrentEditForm(EEditListingForms.POI)}
            >
              <PointsOfInterest property={listing.property} />
            </EditableRow>
            <EditableRow
              variant="h6"
              onEdit={() => setCurrentEditForm(EEditListingForms.VR_VIDEOS)}
            >
              <Box flex={1}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 700, mb: 0.5, mt: 1 }}
                >
                  {t('VR tour and Video')}
                </Typography>
                <List
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      md: 'repeat(2, 1fr)',
                    },
                    columnGap: 5,
                    pt: 0,
                    pb: 0.5,
                  }}
                >
                  <ListItem
                    divider
                    sx={{
                      justifyContent: 'space-between',
                      gap: 2,
                      minWidth: 0,
                    }}
                    disableGutters
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, flexShrink: 0 }}
                    >
                      {t('VR tour')}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ ...theme.text.ellipsis, textAlign: 'right' }}
                    >
                      {listing?.virtual_visit_url ?? t('None')}
                    </Typography>
                  </ListItem>
                  <ListItem
                    divider
                    sx={{
                      justifyContent: 'space-between',
                      gap: 2,
                      minWidth: 0,
                    }}
                    disableGutters
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, flexShrink: 0 }}
                    >
                      {t('Video')}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ ...theme.text.ellipsis, textAlign: 'right' }}
                    >
                      {listing?.video_url ?? t('None')}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </EditableRow>
            {listing?.broker && (
              <EditableRow
                variant="h6"
                onEdit={() => setCurrentEditForm(EEditListingForms.LISTED_BY)}
              >
                <Box flex={1}>
                  <Typography variant="h6" sx={{ fontWeight: 700, mt: 1 }}>
                    {t('Listed by')}
                  </Typography>
                  <AgentCard agent={listing.broker} />
                </Box>
              </EditableRow>
            )}
            <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
              {t('Location')}
            </Typography>
            <Box mb={1}>
              {formatAddress(listing.property, null).map((line, index) => (
                <Typography
                  variant="body2"
                  key={index}
                  sx={index === 0 ? { fontWeight: 700 } : {}}
                >
                  {line}
                </Typography>
              ))}
            </Box>
            <ListingMap property={listing.property} />

            <Box marginTop={2}>
              <Typography variant="body2">
                {t('Created {{date}}', {
                  date: toHumanReadableDate(locale, listing.created_at, {
                    hour: 'numeric',
                    minute: 'numeric',
                  }),
                })}
              </Typography>
            </Box>
          </ListingAccordion>
        </Grid>
        <Grid item md={4} xs={12}>
          <ListingAccordion
            title={t('Agent')}
            defaultExpanded={accordionsOpenedDefault}
          >
            <UserActionCard
              user={listing.broker}
              secondaryText={listing.broker?.default_team?.name}
              canDelete={me?.is_admin === true}
              onUserChanged={(userId, onError) =>
                updateListing({
                  variables: { id: listing.id, lot: { broker_id: userId } },
                  onError: error => onError(error),
                })
              }
              editUserInputProps={{
                orderResultsBy: [{ is_broker: Order_By.Desc }],
              }}
            />
          </ListingAccordion>

          <SellersActionCard
            openedDefault={accordionsOpenedDefault}
            sellers={listing.sellers.map(({ seller }) => seller) ?? []}
            onSellersChanged={(sellerIds, onError) =>
              updateListingSellers({
                variables: {
                  id: listing.id,
                  sellers: sellerIds.map(id => ({
                    lot_id: listing.id,
                    user_id: id,
                  })),
                },
                onError: error => onError(error),
                update: (cache, { data: mutationResult }) => {
                  if (!mutationResult?.insert_lot_sellers) {
                    return;
                  }

                  const newSellers =
                    mutationResult.insert_lot_sellers.returning;

                  cache.modify({
                    id: cache.identify(listing),
                    fields: {
                      sellers() {
                        return newSellers;
                      },
                    },
                  });
                },
              })
            }
          />

          <ListingMandateCard
            openedDefault={accordionsOpenedDefault}
            listing={listing}
            onEdit={() => setCurrentEditForm(EEditListingForms.MANDATE)}
          />

          <ListingBuyerLeadsCard
            openedDefault={accordionsOpenedDefault}
            listingId={lotId}
            buyerLeadsData={listing}
          />

          <ListingOffersCard
            openedDefault={accordionsOpenedDefault}
            listingId={lotId}
          />

          <ListingTransactionsCard
            listingId={lotId}
            openedDefault={accordionsOpenedDefault}
          />

          <ListingAccordion
            title={t('Lead')}
            defaultExpanded={accordionsOpenedDefault}
          >
            {listing.lead != null ? (
              <LeadCard
                lead={listing.lead}
                displayMode={ELeadCardDisplayMode.COMPACT}
                openOnNewTab
                onLeadChanged={(leadId, onError) =>
                  updateListing({
                    variables: { id: listing.id, lot: { lead_id: leadId } },
                    onError: error => onError(error),
                  })
                }
              >
                <LeadPipelineForm lead={listing.lead} />
              </LeadCard>
            ) : (
              <LeadSelect
                placeholder={t('Associate a lead')}
                onChange={lead =>
                  updateListing({
                    variables: { id: listing.id, lot: { lead_id: lead?.id } },
                  })
                }
              />
            )}
          </ListingAccordion>
        </Grid>
      </Grid>
      <Suspense fallback={null}>
        <ListingOverviewEditModal
          listing={listing}
          opened={currentEditForm != null}
          editForm={currentEditForm}
          onClose={() => setCurrentEditForm(null)}
          onSuccess={() => setSnackBarDisplayed(true)}
        />
      </Suspense>
      {snackBarDisplayed && (
        <RASnackbar
          message={t('Your changes were saved successfully!')}
          onClose={() => setSnackBarDisplayed(false)}
          severity="success"
        />
      )}
    </>
  );
};

export default ListingOverview;
