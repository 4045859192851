import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, Stack } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { GET_LISTING_EDIT_CONTEXT } from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import type {
  FormDefinitionType,
  FormFieldDefinitionType,
  SelectFieldDefinition,
} from '../form/RaForm';
import { RaForm } from '../form/RaForm';
import { LoadingSpinner } from '../LoadingSpinner';
import {
  type PropertyFormContext,
  type PropertyFormDetailsData,
  getPropertyDetailsFormDefinition,
} from '../property-form/forms-definitions/propertyDetailsFormDefinition';
import { getValidatePropertyDetailsFormData } from '../property-form/forms-definitions/propertyDetailsFormValidation';
import { PropertyTypeProvider } from '../property-form/PropertyTypeProvider';

type PropertyDetailsStepProps = {
  footerActionsComponent: React.ReactNode;
  onFormSubmitted: (data: Partial<PropertyFormDetailsData>) => Promise<void>;
};

const isSelectField = (
  field: FormFieldDefinitionType<PropertyFormDetailsData>,
): field is SelectFieldDefinition<PropertyFormDetailsData> =>
  field.type === 'select';

export const CreateLeadPropertyDetailsStep = ({
  footerActionsComponent,
  onFormSubmitted,
}: PropertyDetailsStepProps) => {
  const { t, locale, countryCode } = useLocale();
  const { me } = useAppData();
  const isCHTenant = me?.tenant.country_code === 'CH';

  const { data: context, loading } = useQuery(GET_LISTING_EDIT_CONTEXT);

  const formDefinition = useMemo<
    FormDefinitionType<PropertyFormDetailsData, PropertyFormContext>
  >(
    () => args => {
      const baseFields = getPropertyDetailsFormDefinition(
        isCHTenant,
        null,
        countryCode,
        locale,
      )(args);

      return baseFields.map(field => {
        // Use type guard to ensure field has 'name' property
        // Only modify select fields that are main_type or property_type_id
        if (
          isSelectField(field) &&
          (field.name === '__main_type' || field.name === 'property_type_id')
        ) {
          return {
            ...field,
            required: true,
          };
        }
        return field;
      });
    },
    [isCHTenant, locale, countryCode],
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (context == null) {
    return (
      <Stack width="100%">
        <Alert severity="error">{t('Failed to load property form')}</Alert>
      </Stack>
    );
  }

  const formContext = {
    propertyTypes: context.property_types,
    heatingTypes: context.heating_enum,
    heatingDistribution: context.heating_distribution_enum,
    coolingTypes: context.cooling_enum,
  };

  return (
    <RaForm
      formDefinition={formDefinition}
      onSubmit={onFormSubmitted}
      actionButtonsComponent={footerActionsComponent}
      context={formContext}
      contentScrollable
      validate={getValidatePropertyDetailsFormData(t)}
    >
      <PropertyTypeProvider propertyTypes={formContext.propertyTypes} />
    </RaForm>
  );
};

CreateLeadPropertyDetailsStep.displayName = 'CreateLeadPropertyDetailsStep';
