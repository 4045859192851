import { FormLabel, type FormLabelProps } from '@mui/material';

type RaLabelProps = {
  label: string;
} & Omit<FormLabelProps, 'children'>;

export const RaLabel: React.FC<RaLabelProps> = ({
  label,
  required,
  ...labelProps
}) => {
  return (
    <FormLabel
      style={{ marginBottom: '0.5rem', fontWeight: 500 }}
      sx={{
        '& .MuiFormLabel-asterisk': {
          color: 'red',
        },
      }}
      required={required}
      {...labelProps}
    >
      {label}
    </FormLabel>
  );
};
