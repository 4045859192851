import { useState } from 'react';

import { type ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { gql } from '../__generated__';
import {
  type Lead_Agent_Source_Enum_Enum,
  type Leads_Insert_Input,
  type Leads_Relationship_Enum,
  type Leads_Sale_Horizon_Enum,
} from '../__generated__/graphql';
import { useAppData } from '../providers/AppDataProvider';

const INSERT_LEAD = gql(/* GraphQL */ `
  mutation InsertLead(
    $input: leads_insert_input!
    $lead_id: uuid!
    $broker_id: uuid!
    $contact_id: uuid
    $include_call_activity: Boolean!
    $created_at: timestamptz!
    $created_at_plus_5: timestamptz!
    $created_at_plus_10: timestamptz!
    $claimed_at: timestamptz!
    $stage_id: uuid!
  ) {
    insert_leads_one(object: $input) {
      id
    }

    # Create origination activity
    origination: insert_activities_one(
      object: {
        lead_id: $lead_id
        activity_type: origination
        done: true
        success: true
        done_at: $created_at
        created_at: $created_at
      }
    ) {
      id
    }

    # Create assignment activity
    assignment: insert_activities_one(
      object: {
        lead_id: $lead_id
        activity_type: assignment
        assigned_to: $broker_id
        done: true
        success: true
        done_at: $created_at_plus_5
        created_at: $created_at_plus_5
      }
    ) {
      id
    }

    # Create call activity with target user
    call: insert_activities_one(
      object: {
        lead_id: $lead_id
        activity_type: call
        assigned_to: $broker_id
        created_at: $created_at_plus_10
        activities_target_users: { data: { target_user_id: $contact_id } }
      }
    ) @include(if: $include_call_activity) {
      id
      activities_target_users {
        target_user_id
      }
    }

    insert_lead_agents_one(
      object: {
        lead_id: $lead_id
        user_id: $broker_id
        source: crm
        claimed_at: $claimed_at
        stage_id: $stage_id
      }
    ) {
      id
    }
  }
`);

const GET_LEAD_METADATA = gql(/* GraphQL */ `
  query GetLeadMetadata($sourceName: String!) {
    pipelines(where: { name: { _eq: "brokerage" } }, limit: 1) {
      id
      stages(where: { name: { _eq: "claimed" } }, limit: 1) {
        id
        name
      }
    }
    dictionaries(
      where: { type: { _eq: lead_source_types }, name: { _eq: $sourceName } }
      limit: 1
    ) {
      id
    }
  }
`);

export type CreateLeadInput = {
  stage_id?: string | null;
  contact_id: string;
  property: {
    lat?: number | null;
    lng?: number | null;
    route?: string | null;
    street_number?: string | null;
    postcode?: string | null;
    state?: string | null;
    locality?: string | null;
    country_code?: string | null;
    property_type_id?: string | null;
    living_surface?: number | null;
    land_surface?: number | null;
    number_of_rooms?: number | null;
    number_of_bedrooms?: number | null;
    built_surface?: number | null;
    construction_year?: number | null;
    renovation_year?: number | null;
  };
  relationship?: Leads_Relationship_Enum | null;
  sale_horizon?: Leads_Sale_Horizon_Enum | null;
  source?: Lead_Agent_Source_Enum_Enum | null;
  broker_id?: string;
};

type CreateLeadHookResult = [
  ApolloError | null,
  boolean,
  (input: CreateLeadInput) => Promise<string | null>,
  () => void, // resetError function
];

export const useCreateLead = (): CreateLeadHookResult => {
  const { me } = useAppData();
  const [apolloError, setApolloError] = useState<ApolloError | null>(null);

  const [insertLead, { loading: insertLeadLoading }] = useMutation(
    INSERT_LEAD,
    {
      refetchQueries: ['GetLeads', 'GetLeadsCount'],
      onError: setApolloError,
    },
  );

  const [getLeadMetadata, { loading: metadataLoading }] =
    useLazyQuery(GET_LEAD_METADATA);

  const getSourceId = async (sourceName: string): Promise<string> => {
    const { data } = await getLeadMetadata({
      variables: { sourceName },
    });

    if (!data?.dictionaries[0]?.id) {
      throw new Error(`No match found for lead source name "${sourceName}"`);
    }

    return data.dictionaries[0].id;
  };

  const getStageId = async (): Promise<string> => {
    const { data } = await getLeadMetadata({
      variables: { sourceName: 'crm' }, // Default source for stage query
    });

    if (!data?.pipelines[0]?.id) {
      throw new Error('Brokerage pipeline not found');
    }

    const claimedStage = data.pipelines[0].stages[0];

    if (!claimedStage?.id) {
      throw new Error('No claimed stage found in brokerage pipeline');
    }

    return claimedStage.id;
  };

  const createNewLead = async (input: CreateLeadInput) => {
    // We want to force the stage_id (use for leads created from kanban)
    const source = input.source ?? 'crm';
    const sourceId = await getSourceId(source);
    const stage_id = input.stage_id ?? (await getStageId());

    // We delay the created_at otherwise the activity order is wrong in the feed
    const now = new Date();
    const plus5s = new Date(now.getTime() + 5000);
    const plus10s = new Date(now.getTime() + 10000);

    const generatedId = uuidv4();

    const leadInput: Leads_Insert_Input = {
      id: generatedId,
      source_id: sourceId,
      stage_id,
      // Lead is always completed for CRM
      completed: true,

      contact_id: input.contact_id,
      created_by: me?.id,
      broker_id: me?.id,
      claimed_by: me?.id,

      relationship: input.relationship ?? undefined,
      sale_horizon: input.sale_horizon ?? undefined,

      property: {
        data: input.property,
      },
    };

    const { data: leadData, errors: leadErrors } = await insertLead({
      variables: {
        input: leadInput,
        lead_id: generatedId,
        broker_id: me?.id ?? '',
        contact_id: input.contact_id,
        include_call_activity: !!input.contact_id,
        created_at: now.toISOString(),
        created_at_plus_5: plus5s.toISOString(),
        created_at_plus_10: plus10s.toISOString(),
        claimed_at: plus10s.toISOString(),
        stage_id,
      },
    });

    if (leadErrors || !leadData?.insert_leads_one) {
      return null;
    }

    return leadData.insert_leads_one.id;
  };

  return [
    apolloError,
    insertLeadLoading || metadataLoading,
    createNewLead,
    () => {
      setApolloError(null);
    },
  ];
};
