import type { Interpolation } from '@emotion/react';
import { createTheme, colors as muiColors } from '@mui/material';

import {
  borderRadius,
  boxShadow,
  customPalette,
  fontWeight,
  muiDrawerZIndex,
} from '../styles';

export { StyleProvider } from '../styles';

const shadows: string[] = createTheme().shadows;

const size = (px: number): string => px / 16 + 'rem';

const commonTextStyle = {
  marginTop: 0,
  marginBottom: 0,
  color: 'inherit',
  cursor: 'inherit',
};

const truncate = (lines: number): Interpolation<unknown> => {
  if (lines < 1) {
    throw Error('truncate acceps minimum 1 line');
  }
  if (lines === 1) {
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  }
  return {
    display: '-webkit-box',
    // !important fixes emotion auto unit
    WebkitLineClamp: `${lines} !important`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    // fallback for not supported browsers
    maxHeight: '100%',
  };
};

const text = {
  // https://material.io/design/typography
  size,
  font: fontWeight,
  h1: {
    ...commonTextStyle,
    fontWeight: fontWeight.regular,
    fontSize: size(96),
    lineHeight: 1,
  },
  h2: {
    ...commonTextStyle,
    fontWeight: fontWeight.regular,
    fontSize: size(60),
    lineHeight: 1,
  },
  h3: {
    ...commonTextStyle,
    fontWeight: fontWeight.regular,
    fontSize: size(48),
    lineHeight: 1.04,
  },
  h4: {
    ...commonTextStyle,
    fontWeight: fontWeight.medium,
    fontSize: size(34),
    lineHeight: 1.17,
  },
  h5: {
    ...commonTextStyle,
    fontWeight: fontWeight.medium,
    fontSize: size(24),
    lineHeight: 1.33,
  },
  h6: {
    ...commonTextStyle,
    fontWeight: fontWeight.bold,
    fontSize: size(20),
    lineHeight: 1.6,
  },
  subtitle1: {
    ...commonTextStyle,
    fontWeight: fontWeight.medium,
    fontSize: size(16),
    lineHeight: 1.75,
  },
  subtitle2: {
    ...commonTextStyle,
    fontWeight: fontWeight.bold,
    fontSize: size(14),
    lineHeight: 1.57,
  },
  body1: {
    ...commonTextStyle,
    fontWeight: fontWeight.regular,
    fontSize: size(16),
    lineHeight: 1.5,
  },
  body2: {
    ...commonTextStyle,
    fontWeight: fontWeight.regular,
    fontSize: size(14),
    lineHeight: 1.5,
  },
  button: {
    ...commonTextStyle,
    fontWeight: fontWeight.medium,
    fontSize: size(14),
    lineHeight: 1.5,
    textTransform: 'uppercase',
  } as Interpolation<unknown>,
  buttonText: {
    ...commonTextStyle,
    fontWeight: fontWeight.bold,
    fontSize: size(14),
    lineHeight: 1.5,
  },
  caption: {
    ...commonTextStyle,
    fontWeight: fontWeight.regular,
    fontSize: size(12),
    lineHeight: 1.26,
  },
  overline: {
    ...commonTextStyle,
    fontWeight: fontWeight.regular,
    fontSize: size(10),
    lineHeight: 2.66,
    textTransform: 'uppercase',
  },
  truncate,
  // deprecated
  ellipsis: truncate(1),
};

const colors = {
  ...customPalette,
  error: customPalette.red500,
  errorText: customPalette.red500,
  success: customPalette.green500,
  successText: customPalette.green500,
  warning: customPalette.orange500,
  warningText: customPalette.orange500,

  primaryLight: customPalette.blue300,
  primaryMain: customPalette.blue500,
  primaryDark: customPalette.blue700,
  borderMain: muiColors.grey['300'],
  borderDark: muiColors.grey['500'],

  // additional colors
  divider: 'rgba(0, 0, 0, 0.12)',

  // grey
  grey50: muiColors.grey['50'],
  grey100: muiColors.grey['100'],
  grey200: muiColors.grey['200'],
  grey300: muiColors.grey['300'],
  grey400: muiColors.grey['400'],
  grey500: muiColors.grey['500'],
  grey600: muiColors.grey['600'],
  grey700: muiColors.grey['700'],
  grey800: muiColors.grey['800'],
  grey900: muiColors.grey['900'],
  greyA100: muiColors.grey['A100'],
  greyA200: muiColors.grey['A200'],
  greyA400: muiColors.grey['A400'],
  greyA700: muiColors.grey['A700'],
};

const textColor = (key: keyof typeof colors) => ({
  color: colors[key],
});

const bgColor = (key: keyof typeof colors) => ({
  backgroundColor: colors[key],
});

const depth = {
  base: 100,
  appBar: 1100, // hardcoded in mui theme; don't change
  sideBar: 1150,
  floatingButton: 1200,
  modal: 1300, // hardcoded in mui theme; don't change
  inputPopup: 1400,
  tooltip: 1500, // // hardcoded in mui theme; don't change
};

export const theme = {
  text,
  colors,
  shadows,
  depth,
  borderRadius,
  boxShadow,
  sideBarWidth: 57,
  muiDrawerZIndex,

  // style getters
  textColor,
  bgColor,
};

export type Theme = typeof theme;

export const useTheme = (): Theme => {
  return theme;
};
