import { useEffect, useMemo } from 'react';

import { Grid, debounce } from '@mui/material';
import {
  type DeepPartial,
  type FieldPath,
  useFormContext,
} from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { type Currency, getCurrencyByCountryCode } from '../../../src/locale';
import { roundToTwo } from '../../../src/utils/number-format';
import { DEFAULT_TAX_RATE } from '../../utils/constants';
import { getCurrencySymbol } from '../../utils/formatting';
import { getKeys } from '../../utils/objects';
import { RaNumber } from '../form/RaNumber';

import {
  type TransactionCommissionSplitsFields,
  computeCommissionSplitsFields,
} from './TransactionCommissionSplitsField';

type TransactionTotalCommissionFieldProps = {
  currency?: string | null;
  propertyCountryCode?: string | null;
  isFixedFee?: boolean;
  disabled?: boolean;
};

export type TransactionCommissionFields = {
  amount?: number | null;
  vat?: number | null;
  __rate?: number | null;
  __vat_rate?: number | null;
  __total?: number | null;
};

export type TransactionTotalCommissionFields = {
  purchase_price?: number | null;
  total_commission: TransactionCommissionFields;
};

const calcCommissionPercentage = (
  commissionAmount: number,
  transactionTotal: number,
) =>
  commissionAmount === 0 || transactionTotal === 0
    ? null
    : roundToTwo((commissionAmount / transactionTotal) * 100);

export const computeCommissionFields = (
  fields: DeepPartial<TransactionTotalCommissionFields>,
  trigger: FieldPath<TransactionTotalCommissionFields> | 'none',
  isFixedFee?: boolean,
): Omit<TransactionTotalCommissionFields, 'purchase_price'> | null => {
  let commissionAmount = fields.total_commission?.amount ?? 0;
  const commissionPercentage = fields.total_commission?.__rate ?? 0;
  let vat = fields.total_commission?.vat ?? 0;
  let vatPercentage = fields.total_commission?.__vat_rate ?? 0;
  const commissionTotal = fields.total_commission?.__total ?? 0;
  const transactionTotal = fields.purchase_price ?? 0;

  const newData: Omit<
    TransactionTotalCommissionFields,
    'purchase_price'
  >['total_commission'] = {};

  let updateVat = false;
  let updateCommissionTotal = false;

  switch (trigger) {
    case 'purchase_price':
      if (isFixedFee) {
        newData.__rate = calcCommissionPercentage(
          commissionAmount,
          transactionTotal,
        );
      } else {
        newData.amount = roundToTwo(
          (transactionTotal * commissionPercentage) / 100,
        );
        updateVat = true;
        updateCommissionTotal = true;
      }
      break;
    case 'total_commission.amount':
      newData.__rate = calcCommissionPercentage(
        commissionAmount,
        transactionTotal,
      );
      updateVat = true;
      updateCommissionTotal = true;
      break;
    case 'total_commission.__rate':
      newData.amount = roundToTwo(
        (transactionTotal * commissionPercentage) / 100,
      );
      updateVat = true;
      updateCommissionTotal = true;
      break;
    case 'total_commission.vat':
      newData.__vat_rate = roundToTwo((vat / commissionAmount) * 100);
      updateCommissionTotal = true;
      break;
    case 'total_commission.__vat_rate':
      updateVat = true;
      updateCommissionTotal = true;
      break;
    case 'total_commission.__total': {
      const tempVat = roundToTwo(
        (commissionTotal / (100 + vatPercentage)) * vatPercentage,
      );
      newData.amount = commissionTotal - tempVat;
      newData.__rate = calcCommissionPercentage(
        commissionAmount,
        transactionTotal,
      );
      updateVat = true;
      break;
    }
    case 'none':
      newData.amount = commissionAmount;
      newData.vat = vat;
      newData.__rate = calcCommissionPercentage(
        commissionAmount,
        transactionTotal,
      );
      newData.__vat_rate =
        vat !== 0
          ? roundToTwo((vat / commissionAmount) * 100)
          : DEFAULT_TAX_RATE;
      updateCommissionTotal = true;
      break;
    default:
      return null;
  }

  commissionAmount = newData.amount ?? commissionAmount;
  vatPercentage = newData.__vat_rate ?? vatPercentage;

  if (updateVat) {
    newData.vat = roundToTwo((commissionAmount * vatPercentage) / 100);
  }

  vat = newData.vat ?? vat;

  if (updateCommissionTotal) {
    newData.__total = roundToTwo(commissionAmount + vat);
  }

  return {
    total_commission: newData,
  };
};

// Prevent to call computeCommissionFields() if not one of those fields is changed.
const supportedTriggers = [
  'purchase_price',
  'total_commission',
  'total_commission.amount',
  'total_commission.vat',
  'total_commission.__rate',
  'total_commission.__vat_rate',
  'total_commission.__total',
  'none',
] as const;

export const TransactionTotalCommissionField: React.FC<
  TransactionTotalCommissionFieldProps
> = ({ currency, propertyCountryCode, isFixedFee, disabled }) => {
  const { t, locale, countryCode } = useLocale();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<TransactionTotalCommissionFields>();
  const computedCurrency = useMemo(
    () =>
      (currency ??
        getCurrencyByCountryCode(
          propertyCountryCode ?? countryCode,
        )) as Currency,
    [countryCode, propertyCountryCode, currency],
  );

  const debouncedUpdate = useMemo(
    () =>
      debounce(
        (
          values: DeepPartial<TransactionTotalCommissionFields>,
          name: FieldPath<TransactionTotalCommissionFields>,
        ) => {
          const computedValues = computeCommissionFields(
            values,
            name,
            isFixedFee,
          );

          if (computedValues != null) {
            const updates: {
              path: FieldPath<
                TransactionTotalCommissionFields &
                  TransactionCommissionSplitsFields
              >;
              value: any;
            }[] = getKeys(computedValues.total_commission).map(key => {
              // As per react-hook-form documentation, it is more performant to target the complete field name rather than passing an object as second parameter.
              // https://react-hook-form.com/docs/useform/setvalue
              return {
                path: `total_commission.${key}`,
                value: computedValues.total_commission[key],
              };
            });

            if (
              name === 'total_commission.amount' ||
              computedValues.total_commission.amount != null
            ) {
              const valuesCloned: DeepPartial<TransactionTotalCommissionFields> =
                JSON.parse(JSON.stringify(values));

              if (computedValues.total_commission.amount != null) {
                valuesCloned.total_commission = {
                  ...(valuesCloned.total_commission ?? {}),
                  amount: computedValues.total_commission.amount,
                };
              }

              const computedSplitValues = computeCommissionSplitsFields(
                valuesCloned,
                'total_commission.amount',
                false,
              );

              computedSplitValues?.forEach(({ commission, index }) => {
                updates.push(
                  ...(getKeys(commission).map(key => ({
                    path: `split_commissions.${index}.${key}`,
                    value: commission[key],
                  })) as {
                    path: FieldPath<
                      TransactionTotalCommissionFields &
                        TransactionCommissionSplitsFields
                    >;
                    value: any;
                  }[]),
                );
              });
            }

            // Because we update the commission splits fields through the total commission "setValue", we don't have the full form, so path must be any.
            updates.slice(0, -1).forEach(({ path, value }) => {
              setValue(path as any, value, {
                shouldValidate: false,
                shouldDirty: false,
              });
            });

            setValue(
              updates[updates.length - 1].path as any,
              updates[updates.length - 1].value,
              {
                shouldValidate: true,
                shouldDirty: true,
              },
            );
          }
        },
        275,
      ),
    [isFixedFee, setValue],
  );

  useEffect(() => {
    const { unsubscribe } = watch((values, { name, type }) => {
      if (
        type === 'change' &&
        name != null &&
        supportedTriggers.includes(name)
      ) {
        debouncedUpdate(values, name);
      }
    });
    return () => unsubscribe();
  }, [watch, isFixedFee, debouncedUpdate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RaNumber
          name="total_commission.amount"
          label={t('Amount')}
          required={true}
          min={0}
          control={control}
          errors={errors}
          decimalNumbers={2}
          prefix={getCurrencySymbol(computedCurrency, locale)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <RaNumber
          name="total_commission.__rate"
          label={t('Commission rate')}
          required={true}
          min={0}
          max={100}
          control={control}
          errors={errors}
          decimalNumbers={2}
          suffix="%"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <RaNumber
          name="total_commission.vat"
          label={t('VAT')}
          required={true}
          min={0}
          control={control}
          errors={errors}
          decimalNumbers={2}
          prefix={getCurrencySymbol(computedCurrency, locale)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <RaNumber
          name="total_commission.__vat_rate"
          label={t('VAT (%)')}
          required={true}
          min={0}
          max={100}
          control={control}
          errors={errors}
          decimalNumbers={2}
          suffix="%"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <RaNumber
          name="total_commission.__total"
          label={t('Commission payable')}
          required={true}
          min={0}
          control={control}
          errors={errors}
          decimalNumbers={2}
          prefix={getCurrencySymbol(computedCurrency, locale)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
