import { type Reducer, forwardRef, useReducer, useRef, useState } from 'react';

import {
  type InternalRefetchQueriesInclude,
  useMutation,
} from '@apollo/client';
import { Alert, AlertTitle, Dialog, DialogTitle, Slide } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import type { UserSelectFragment } from '../__generated__/graphql';

import { type FormDefinitionType, RaForm } from './form/RaForm';
import RASnackbar from './RASnackbar';

export type UserCreationData = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

type AddUserModalProps = {
  user?: UserCreationData;
  opened: boolean;
  onClose: (newUser?: UserSelectFragment) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const INSERT_USER_QUERY = gql(/* GraphQL */ `
  mutation InsertUser($user: users_insert_input!) {
    insert_users_one(object: $user) {
      id
      ...UserSelect
    }
  }
`);

const formDefinition: FormDefinitionType<{
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
}> = ({ t }) => [
  {
    type: 'text',
    required: true,
    name: 'firstName',
    label: t('First name'),
  },
  {
    type: 'text',
    required: true,
    name: 'lastName',
    label: t('Last name'),
  },
  {
    type: 'text',
    name: 'email',
    label: t('Email'),
  },
  {
    type: 'phone_number',
    name: 'phone',
    label: t('Phone'),
  },
];

type ModalState = {
  isOpen: boolean;
  initialUser: UserCreationData | undefined;
  modalKey: number;
};

const modalReducer: Reducer<
  ModalState,
  | { type: 'openModal'; payload: UserCreationData | undefined }
  | { type: 'closeModal' }
> = (state, action) => {
  switch (action.type) {
    case 'openModal':
      return {
        ...state,
        isOpen: true,
        initialUser: action.payload,
        modalKey: state.modalKey + 1,
      };
    case 'closeModal':
      return {
        ...state,
        isOpen: false,
        initialUser: undefined,
      };
    default:
      return state;
  }
};

export const useAddUserModal = (
  params?: Pick<AddUserModalProps, 'refetchQueries' | 'onClose'>,
): [
  React.ReactNode,
  (user?: UserCreationData) => Promise<UserSelectFragment | undefined>,
] => {
  const [{ initialUser, isOpen, modalKey }, dispatch] = useReducer(
    modalReducer,
    {
      isOpen: false,
      initialUser: undefined,
      modalKey: 0,
    },
  );
  const resolveRef = useRef<(value: UserSelectFragment | undefined) => void>();

  const openModal = (user?: UserCreationData) => {
    dispatch({ type: 'openModal', payload: user });

    return new Promise<UserSelectFragment | undefined>(resolve => {
      resolveRef.current = resolve;
    });
  };

  const handleClose = (newUser?: UserSelectFragment) => {
    dispatch({ type: 'closeModal' });
    params?.onClose?.(newUser);
    resolveRef.current?.(newUser);
  };

  return [
    <AddUserModal
      key={modalKey}
      user={initialUser}
      opened={isOpen}
      onClose={handleClose}
      refetchQueries={params?.refetchQueries}
    />,
    openModal,
  ];
};

export const AddUserModal: React.FC<AddUserModalProps> = ({
  user,
  opened,
  onClose,
  refetchQueries,
}) => {
  const { t } = useLocale();
  const [insertUser] = useMutation(INSERT_USER_QUERY, {
    onCompleted: data => {
      if (data.insert_users_one != null) {
        setDisplaySuccessMessage(true);
        onClose(data.insert_users_one);
      }
    },
    onError: error => {
      setErrorMessage(error.message);
    },
    refetchQueries,
  });
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <>
      <Dialog
        open={opened}
        fullWidth
        maxWidth="sm"
        onClose={() => onClose()}
        TransitionComponent={Transition}
        sx={{
          '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
        }}
      >
        <DialogTitle>{t('New user')}</DialogTitle>
        <RaForm
          formDefinition={formDefinition}
          onCancel={() => onClose()}
          defaultValues={user ?? {}}
          allowSubmitNotDirty
          onSubmit={data =>
            insertUser({
              variables: {
                user: {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  emails:
                    data.email != null
                      ? { data: [{ email: data.email }] }
                      : undefined,
                  phone_numbers:
                    data.phone != null
                      ? {
                          data: [
                            {
                              number:
                                parsePhoneNumberFromString(data.phone)?.format(
                                  'E.164',
                                ) ?? '',
                            },
                          ],
                        }
                      : undefined,
                },
              },
            })
          }
        >
          {errorMessage != null && (
            <Alert severity="error" sx={{ m: 2 }}>
              <AlertTitle>
                {t('An error occured while creating the user.')}
              </AlertTitle>
              {errorMessage}
            </Alert>
          )}
        </RaForm>
      </Dialog>
      {displaySuccessMessage && (
        <RASnackbar
          onClose={() => setDisplaySuccessMessage(false)}
          message={t('User created successfully!')}
          severity="success"
        />
      )}
    </>
  );
};
