// @flow

import * as React from 'react';

import { ListItemText } from '@mui/material';
import { graphql, useFragment } from 'react-relay';

import { useLocale } from '../../hooks/locale';

import type { placeListItem_place$key } from './__generated__/placeListItem_place.graphql';

type Props = {|
  place: placeListItem_place$key,
|};

export const PlaceListItem = (props: Props): React.Node => {
  const { t } = useLocale();

  const place = useFragment(
    graphql`
      fragment placeListItem_place on Place {
        label
        type
        country: parentPlace(type: country) {
          label
        }
        state: parentPlace(type: state) {
          label
        }
        municipality: parentPlace(type: municipality) {
          label
        }
      }
    `,
    props.place,
  );

  const typeTranslated = {
    state: t('state'),
    locality: t('locality'),
    district: t('district'),
    municipality: t('Municipality'),
    neighbourhood: t('neighbourhood'),
    country: t('country'),
    region: t('region'),
    agglomeration: '',
    postcode: t('postcode'),
  }[place.type];

  const getLabel = typeOfParent => {
    switch (typeOfParent) {
      case 'municipality':
        return place.municipality?.label;
      case 'state':
        return place.state?.label;
      case 'country':
        return place.country?.label;
    }
  };

  const parents = {
    neighbourhood: ['municipality', 'state', 'country'],
    locality: ['municipality', 'state', 'country'],
    agglomeration: ['state', 'country'],
    municipality: ['state', 'country'],
    district: ['state', 'country'],
    postcode: ['state', 'country'],
    state: ['country'],
    region: ['country'],
    country: [],
  }[place.type]
    .map(getLabel)
    .filter(Boolean)
    .join(', ');

  return (
    <ListItemText
      primary={place.label}
      secondary={[parents, typeTranslated].filter(Boolean).join(' - ')}
    />
  );
};
