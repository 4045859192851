import * as React from 'react';
import type { Interpolation } from '@emotion/react';
import type {
  SourceImage,
  Options,
  ImgproxyImageOptions,
} from '@realadvisor/cloudinary';
import { cloudinaryUrl } from '@realadvisor/cloudinary';
import { getScrollableParents } from './dom-helpers';
import { Blurhash } from 'react-blurhash';

export type { ImgproxyImageOptions };

declare global {
  interface Window {
    __hydrateSrcSet: Set<string>;
    __observer: IntersectionObserver | null;
  }
}

// For now all browser's implemetation has issues with native lazy
// disable for now, set to true to check in the future.
// Chrome issue - tooo huge margins thresholds, loads everything up to 8k pixels around
// Firefox issue - no margins thresholds for elements inside other scrollable elements
// (loads image inside scrollable element only with root view intersection,
// see pages/scrollable-parent.js for details)
const NATIVE_LAZY_SUPPORT = false;

// We will not use Chrome native lazy loading because of huge threshold in current versions
// Until Chrome will change this strange behaviour
const hasNativeLazyLoading =
  NATIVE_LAZY_SUPPORT &&
  typeof HTMLImageElement !== 'undefined' &&
  'loading' in HTMLImageElement.prototype;

const hasIntersectionObserver = typeof IntersectionObserver !== 'undefined';

// Don't use any context vars in this function,
// it will be stringified
export const stringifiedFunctionUpdateImages = () => {
  const hasIntersectionObserver = typeof IntersectionObserver !== 'undefined';
  // For now all browser's implemetation has issues with native lazy
  const NATIVE_LAZY_SUPPORT = false;
  // We will not use Chrome native lazy loading because of huge threshold in current versions
  // Until Chrome will change this strange behaviour
  const hasNativeLazyLoading =
    NATIVE_LAZY_SUPPORT &&
    typeof HTMLImageElement !== 'undefined' &&
    'loading' in HTMLImageElement.prototype;

  window.__hydrateSrcSet = new Set();

  const observer =
    hasNativeLazyLoading || !hasIntersectionObserver
      ? null
      : new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                entry.target.querySelectorAll('source').forEach(sourceElt => {
                  const { srcSet } = sourceElt.dataset;
                  if (
                    srcSet != null &&
                    sourceElt.getAttribute('srcSet') == null
                  ) {
                    window.__hydrateSrcSet.add(srcSet);
                    sourceElt.setAttribute('srcSet', srcSet);
                  }
                });
              }
            });
          },
          {
            // We don't set root here as for first rendering
            // srollableParent margins can be omitted, just screen intersection is enough
            // root: scrollableParent,
            // 400 is good predefined default
            rootMargin: '400px',
            threshold: 0,
          },
        );

  window.__observer = observer;

  document.querySelectorAll('img[data-loading=lazy]').forEach(elt => {
    const { parentElement } = elt;
    if (
      parentElement != null &&
      parentElement instanceof HTMLElement &&
      parentElement.nodeName === 'PICTURE'
    ) {
      if (observer != null) {
        observer.observe(parentElement);
      } else {
        parentElement.querySelectorAll('source').forEach(sourceElt => {
          const { srcSet } = sourceElt.dataset;
          if (srcSet != null) {
            window.__hydrateSrcSet.add(srcSet);
            sourceElt.setAttribute('srcSet', srcSet);
          }
        });
      }
    }
  });
};

// On hydrate step we need to render same as was after server rendering + our custom script changes
// to avoid React errors that server content does not match
type ImageEnv = 'server' | 'hydrate' | 'client';

type ImageContextType = {
  getEnv: () => ImageEnv;
  observe: (node: HTMLElement, callback: () => void) => () => void;
  hasHydrateSrcSet: (srcSet: string) => boolean;
};

const ImageContext = React.createContext<ImageContextType>({
  getEnv: () => {
    return 'client';
  },
  observe: () => {
    throw new Error("Lazy images can't be used without ImageProvider");
  },
  hasHydrateSrcSet: () => false,
});

let imageEnv_: ImageEnv = typeof window === 'undefined' ? 'server' : 'hydrate';

const useImageContext = () => {
  return React.useContext(ImageContext);
};

export const ImageProvider = (props: {
  ssr?: boolean;
  children: React.ReactNode;
}) => {
  const [observerState] = React.useState(() => ({
    scrollableParentsObservers: new Map<
      HTMLElement | null,
      {
        observeCount: (element: HTMLElement) => void;
        unobserveCount: (element: HTMLElement) => void;
      }
    >(),
    nodeDisposeAndCallbacks: new Set<{
      invisibleElements: Set<Element>;
      disposeAndCallback: () => void;
    }>(),
  }));

  const [handleIntersect] = React.useState(
    () => (entries: Array<IntersectionObserverEntry>) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          observerState.nodeDisposeAndCallbacks.forEach(cbk => {
            if (cbk.invisibleElements.has(entry.target)) {
              cbk.invisibleElements.delete(entry.target);
              if (cbk.invisibleElements.size === 0) {
                observerState.nodeDisposeAndCallbacks.delete(cbk);
                cbk.disposeAndCallback();
              }
            }
          });
        }
      });
    },
  );

  const [createObserver] = React.useState(() => {
    if (!hasIntersectionObserver) {
      return null;
    }

    return (rootElt: null | HTMLElement) => {
      const observer = new IntersectionObserver(handleIntersect, {
        // https://bugs.webkit.org/show_bug.cgi?id=198784
        // rootMargin will not work on safari untill patch will be applied
        // so work in chrome only
        root: rootElt,
        // 400 is good predefined default
        rootMargin: '400px',
        threshold: 0,
      });

      const refCount = new Map<HTMLElement, number>();
      return {
        observeCount: (node: HTMLElement) => {
          refCount.set(node, (refCount.get(node) ?? 0) + 1);
          // unobserve to force triggering on already visible node
          observer.unobserve(node);
          observer.observe(node);
        },
        unobserveCount: (node: HTMLElement) => {
          const count = (refCount.get(node) ?? 0) - 1;
          if (count <= 0) {
            refCount.delete(node);
            observer.unobserve(node);
          } else {
            refCount.set(node, count);
          }
        },
      };
    };
  });

  const context = React.useMemo<ImageContextType>(
    () => ({
      getEnv: () => (props.ssr === true ? imageEnv_ : 'client'),
      hasHydrateSrcSet: srcSet => {
        if (typeof window === 'undefined' || window.__hydrateSrcSet == null) {
          return false;
        }

        return window.__hydrateSrcSet.has(srcSet);
      },
      observe: (node, callback) => {
        // We need to observe visibility not only element itself, but all its scrollable parents
        if (hasNativeLazyLoading) {
          throw new Error(
            'System has native loading="lazy", there is no need to observe image visibility',
          );
        }

        if (createObserver == null) {
          throw new Error('System has no support of intersection observers');
        }

        const scrollableParents = getScrollableParents(node);

        for (const scrollableParent of scrollableParents) {
          if (!observerState.scrollableParentsObservers.has(scrollableParent)) {
            observerState.scrollableParentsObservers.set(
              scrollableParent,
              createObserver(scrollableParent),
            );
          }
        }

        // Observe node and all its scrollable parents
        let current = node;
        const unobserves: Array<() => void> = [];

        const invisibleElements = new Set<HTMLElement>();
        for (const scrollableParent of scrollableParents) {
          const observer =
            observerState.scrollableParentsObservers.get(scrollableParent);

          if (observer == null) {
            throw new Error(`can't be void`);
          }

          observer.observeCount(current);

          const currElt = current; // copy to pass into closure
          unobserves.push(() => observer.unobserveCount(currElt));

          if (scrollableParent != null) {
            current = scrollableParent;
            invisibleElements.add(scrollableParent);
          }
        }

        invisibleElements.add(node);

        const nodeDisposeAndCallback = {
          invisibleElements,
          disposeAndCallback: () => {
            // We dont need observe after callback is called
            dispose();
            callback();
          },
        };

        let disposed = false;

        const dispose = () => {
          observerState.nodeDisposeAndCallbacks.delete(nodeDisposeAndCallback);
          unobserves.forEach(unobserve => unobserve());
          disposed = true;
        };

        observerState.nodeDisposeAndCallbacks.add(nodeDisposeAndCallback);

        return () => {
          // can be already disposed when disposeAndCallback is being called
          if (!disposed) {
            dispose();
          }
        };
      },
    }),
    [
      createObserver,
      observerState.scrollableParentsObservers,
      observerState.nodeDisposeAndCallbacks,
      props.ssr,
    ],
  );

  React.useEffect(() => {
    imageEnv_ = 'client';

    if (window.__observer != null) {
      // use React capabilities
      window.__observer.disconnect();
    }
  }, []);

  return (
    <ImageContext.Provider value={context}>
      {props.children}
    </ImageContext.Provider>
  );
};

const genExactMedia = (
  breakpoints: Array<string>,
  index: number,
  imagesCount: number,
) => {
  const minWidth =
    index === 0 ? null : `(min-width: ${breakpoints[index - 1]})`;

  const maxWidth =
    index >= imagesCount - 1
      ? null
      : `(max-width: ${Number.parseFloat(breakpoints[index]) - 1}px)`;

  const res = ['screen'];
  if (minWidth != null) {
    res.push(minWidth);
  }
  if (maxWidth != null) {
    res.push(maxWidth);
  }
  return res.join(' and ');
};

const noopCloudinaryUrl = (_options: Options, url: SourceImage) => {
  if (typeof url !== 'string') {
    throw new Error(
      'Only simple string URLs are supported with skipTransform currently',
    );
  }
  return url;
};

const generateMedia = (
  breakpoints: Array<string>,
  images: SourceImage | Array<ImageWithSize>,
  options: Options,
  { makeExact, skipTransform }: { skipTransform: boolean; makeExact: boolean },
): [string, Array<MediaImage>] => {
  const transformFn = !skipTransform ? cloudinaryUrl : noopCloudinaryUrl;

  if (Array.isArray(images)) {
    const src = transformFn(
      { ...options, w: images[0].width, h: images[0].height },
      images[0].file1x,
    );

    const medias = images.map((image, index) => {
      const media = makeExact
        ? genExactMedia(breakpoints, index, images.length)
        : index > 0
        ? `screen and (min-width: ${breakpoints[index - 1]})`
        : null;

      const url1x = transformFn(
        { ...options, w: image.width, h: image.height },
        image.file1x,
      );

      const props: Options = { ...options };

      if (image.height != null) {
        props.h = image.height * 2;
      }
      if (image.width != null) {
        props.w = image.width * 2;
      }

      // Reduce quality for 2x retina images
      const DEFAULT_RETINA_QUALITY = 40;
      const DEFAULT_RETINA_QUALITY_MULTIPLIER = 0.5;
      props.q =
        props.q != null
          ? Math.round(props.q * DEFAULT_RETINA_QUALITY_MULTIPLIER)
          : DEFAULT_RETINA_QUALITY;

      const url2x =
        image.file2x != null ? transformFn(props, image.file2x) : null;

      return {
        srcset: url2x == null ? url1x : `${url1x} 1x, ${url2x} 2x`,
        media,
      };
    });

    return [src, medias.reverse()];
  } else {
    const src = transformFn(options, images);
    const medias = [{ srcset: src, media: null }];
    return [src, medias];
  }
};

type PictureProps = {
  // mostly used for css loading effect
  className?: string;
  css?: Interpolation<unknown>;
  alt?: string;

  // fill: this is the default value which stretches the image to fit the content box, regardless of its aspect-ratio.
  // contain: increases or decreases the size of the image to fill the box whilst preserving its aspect-ratio.
  // cover: the image will fill the height and width of its box, once again maintaining its aspect ratio but often cropping the image in the process.
  // none: image will ignore the height and width of the parent and retain its original size.
  // scale-down: the image will compare the difference between none and contain in order to find the smallest concrete object size.
  objectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  objectPosition: string;

  // Chrome loading attrubute support

  webpMedia: Array<MediaImage>;
  baseMedia: Array<MediaImage>;
  src: string;
  backgroundColor: string | null;
  loading?: 'lazy';
  onError?: () => void;
  blurhash?: string | null;
};

export type MediaImage =
  | { media: null; srcset: string }
  | { media: string | null; srcset: string };

const Picture = (props: PictureProps) => {
  const pictureRef = React.useRef<null | HTMLElement>(null);
  const imgCtx = useImageContext();
  const [visible, setVisible] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);

  // If loading === lazy we use
  // at server: data-src-set
  // at hydrate: data-src-set and if window.__image__.has(srcSet) we use srcSet attribute
  // at client: if hasNativeLazyLoading we use srcSet, otherwise we use observer

  const hasDataSrcSet =
    props.loading === 'lazy' &&
    (imgCtx.getEnv() === 'server' || imgCtx.getEnv() === 'hydrate');

  const hasSrcSet = (srcSet: string) =>
    props.loading !== 'lazy' ||
    imgCtx.hasHydrateSrcSet(srcSet) ||
    (imgCtx.getEnv() === 'client' && hasNativeLazyLoading) ||
    visible === true;

  React.useEffect(() => {
    if (visible || props.loading !== 'lazy' || hasNativeLazyLoading) {
      return;
    }

    if (!hasIntersectionObserver) {
      setVisible(() => true);
      return;
    }

    const { current } = pictureRef;
    if (current != null) {
      return imgCtx.observe(current, () => setVisible(() => true));
    }
  }, [imgCtx, props.loading, visible]);

  return (
    <picture
      ref={pictureRef}
      className={props.className}
      css={{
        backgroundColor: props.backgroundColor ?? 'transparent',
        width: '100%',
        height: '100%',
        display: 'block',
        position: 'relative',
      }}
    >
      {props.blurhash && !imageLoaded && (
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Blurhash hash={props.blurhash} width="100%" height="100%" />
        </div>
      )}

      {props.webpMedia.map(({ media, srcset }, index) => (
        <source
          key={index}
          media={media ?? undefined}
          {...(hasSrcSet(srcset) && { srcSet: srcset })}
          {...(hasDataSrcSet && { 'data-src-set': srcset })}
          type="image/webp"
        />
      ))}

      {props.baseMedia.map(({ media, srcset }, index) => (
        <source
          key={index}
          media={media ?? undefined}
          {...(hasSrcSet(srcset) && { srcSet: srcset })}
          {...(hasDataSrcSet && { 'data-src-set': srcset })}
        />
      ))}

      <img
        className={props.className}
        // Safari + React has an issue with loading 2 pictures
        // see this https://github.com/facebook/react/issues/15215
        // and this https://bugs.webkit.org/show_bug.cgi?id=177068
        // So we will not pass src element to img
        alt={props.alt}
        css={{
          objectFit: props.objectFit,
          objectPosition: props.objectPosition,
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'relative',
          // Hide not found image icon for lazy images
          '&:after':
            props.loading === 'lazy'
              ? {
                  backgroundColor: 'white',
                  display: 'block',
                  content: "''",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1,
                  position: 'absolute',
                }
              : null,
        }}
        data-loading={props.loading}
        // eager prevents on mobile "[Intervention] Images loaded lazily and replaced with placeholders. Load events are deferred."
        loading={hasNativeLazyLoading ? props.loading : 'eager'}
        onError={props.onError}
        onLoad={() => setImageLoaded(true)}
      />
    </picture>
  );
};

export type ImageSize =
  | [[number, number]]
  | [[number, number], [number, number]]
  | [[number, number], [number, number], [number, number]]
  | [[number, number], [number, number], [number, number], [number, number]];

type ImagePropsBase = {
  skipTransform?: boolean;
  options?: ImgproxyImageOptions;
  // Real image size, not the screen size

  // mostly used for css loading effect
  className?: string;
  css?: Interpolation<unknown>;
  alt?: string;
  onError?: () => void;

  // fill: this is the default value which stretches the image to fit the content box, regardless of its aspect-ratio.
  // contain: increases or decreases the size of the image to fill the box whilst preserving its aspect-ratio.
  // cover: the image will fill the height and width of its box, once again maintaining its aspect ratio but often cropping the image in the process.
  // none: image will ignore the height and width of the parent and retain its original size.
  // scale-down: the image will compare the difference between none and contain in order to find the smallest concrete object size.
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  objectPosition?: null | string;

  loading?: 'lazy';
  backgroundColor?: string;
  blurhash?: string | null;

  PreloadComponent?: React.FunctionComponent<{
    media: Array<MediaImage>;
  }>;
};

export type ImageProxyProps = ImagePropsBase & {
  src: SourceImage;
  srcSize?: ImageSize;
};

type ImageWithSize = {
  width?: number;
  height?: number;
  file1x: SourceImage;
  file2x?: SourceImage;
};

export const LowLevelImage = (
  props: ImagePropsBase & {
    images: SourceImage | Array<ImageWithSize>;
  },
) => {
  const imgCtx = useImageContext();

  const isServer = imgCtx.getEnv() === 'server';

  const skipTransform = props.skipTransform === true;

  const { f, ...propsOptions }: ImgproxyImageOptions = props.options ?? {};
  // TODO: replace with context
  const breakpoints = [`768px`, `1280px`, `1920px`];

  const [, webpMedia] = generateMedia(
    breakpoints,
    props.images,
    { c: 'fill', f: 'webp', ...propsOptions },
    { skipTransform, makeExact: false },
  );

  const [src, baseMedia] = generateMedia(
    breakpoints,
    props.images,
    {
      c: 'fill',
      // we are enforcing jpg for all browsers not supporting webp
      // use f: 'png' at options if you need alpha channel
      f: f ?? 'jpg',
      ...propsOptions,
    },
    { skipTransform, makeExact: false },
  );

  // We need this for chrome only (as link preload with srcset works only where
  // so webp is always supported if exists
  const [, preloadWebpMedia] =
    isServer && props.PreloadComponent != null
      ? generateMedia(
          breakpoints,
          props.images,
          { c: 'fill', f: 'webp', ...propsOptions },
          { makeExact: true, skipTransform },
        )
      : [];

  const objectFit = props.objectFit ?? 'cover';
  const objectPosition = props.objectPosition ?? '50% 50%';

  const pictureElement = (
    <Picture
      className={props.className}
      webpMedia={webpMedia}
      baseMedia={baseMedia}
      src={src}
      objectFit={objectFit}
      objectPosition={objectPosition}
      alt={props.alt}
      loading={props.loading}
      onError={props.onError}
      backgroundColor={props.backgroundColor ?? null}
      blurhash={props.blurhash}
    />
  );

  return props.PreloadComponent != null && preloadWebpMedia != null ? (
    <>
      <props.PreloadComponent media={preloadWebpMedia} />
      {pictureElement}
    </>
  ) : (
    pictureElement
  );
};

export const Image = (props: ImageProxyProps) => {
  const { src, srcSize, ...otherProps } = props;

  const image = src;

  if (image == null) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(
        `Image ${props.src.toString()} cannot be converted into bucket/file`,
      );
    }
    // In case of production just not fail
    return null;
  }

  if (srcSize == null) {
    const width = otherProps.options?.w;
    const height = otherProps.options?.h;

    if (width != null || height != null) {
      return (
        <LowLevelImage
          images={[{ width, height, file1x: image, file2x: image }]}
          {...otherProps}
        />
      );
    }

    return <LowLevelImage images={image} {...otherProps} />;
  }

  const images = srcSize.map(([width, height]) => ({
    width,
    height,
    file1x: image,
    file2x: image,
  }));

  return <LowLevelImage images={images} {...otherProps} />;
};
