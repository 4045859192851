import type {
  TransactionFormFragmentFragment,
  UserSelectFragment,
} from '../../__generated__/graphql';
import type {
  FormDefinitionType,
  FormFieldDefinitionType,
} from '../../components/form/RaForm';

export type TransactionFormData = Omit<
  TransactionFormFragmentFragment,
  'id' | 'lot' | 'sellers' | 'buyers'
> & {
  __know_signature_date: boolean;
  sellers_ids: string[];
  buyers_ids: string[];
};

export type TransactionFormContext = {
  readonly: boolean;
  fullAccess: boolean;
  includeMetadata?: boolean;
  includeSellers?: boolean;
  includeBuyers?: boolean;
  isPurchasePriceRequired?: boolean;
  onUserCreateSelected?: (userData: {
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  }) => Promise<UserSelectFragment | undefined>;
};

export const transactionFormDefinition: FormDefinitionType<
  TransactionFormData,
  TransactionFormContext
> = ({ t, context }) => {
  const {
    includeSellers = true,
    includeBuyers = true,
    includeMetadata = true,
    isPurchasePriceRequired = true,
  } = context;

  const fieldsDefinition: FormFieldDefinitionType<TransactionFormData>[] = [
    {
      name: 'terms',
      label: t('Terms'),
      type: 'category-title',
    },
    {
      name: 'purchase_price',
      label: t('Purchase price'),
      type: 'number',
      required: isPurchasePriceRequired,
    },
    {
      type: 'checkbox-group',
      name: 'options',
      label: t('Options'),
      checkboxes: [
        {
          name: 'is_published',
          label: t('Publish on RealAdvisor'),
          type: 'checkbox',
          disabled: () => context.readonly,
        },
      ],
      render: () => context.fullAccess,
      gridProps: { md: 12 },
    },
    {
      name: 'dates',
      label: t('Dates'),
      type: 'category-title',
    },
    {
      name: '__know_signature_date',
      type: 'checkbox',
      label: t('Do you know the signature date?'),
      style: 'switch',
      gridProps: { md: 12 },
    },
    {
      name: 'estimated_signature_date',
      label: t('Estimated signing date'),
      type: 'date',
      required: true,
      render: ({ __know_signature_date }) => !__know_signature_date,
    },
    {
      name: 'signature_date',
      label: t('Signature date'),
      type: 'date',
      required: true,
      render: ({ __know_signature_date }) => __know_signature_date,
    },
    {
      name: 'payment_date',
      label: t('Payment date'),
      type: 'date',
      render: ({ __know_signature_date }) => __know_signature_date,
    },
    {
      name: 'stakeholders',
      label: t('Stakeholders'),
      type: 'category-title',
    },
  ];

  if (includeSellers) {
    fieldsDefinition.push({
      name: 'sellers_ids',
      label: t('Sellers'),
      type: 'user',
      multiple: true,
      createUserSelected: context.onUserCreateSelected,
    });
  }

  if (includeBuyers) {
    fieldsDefinition.push({
      name: 'buyers_ids',
      label: t('Buyers'),
      type: 'user',
      multiple: true,
      createUserSelected: context.onUserCreateSelected,
    });
  }

  fieldsDefinition.push(
    {
      name: 'seller_broker_id',
      label: t('Seller agent'),
      type: 'user',
      required: true,
      filters: { is_broker: { _eq: true } },
    },
    {
      name: 'seller_broker_team_id',
      label: t('Seller agent team'),
      type: 'team',
    },
    {
      name: 'buyer_broker_id',
      label: t('Buyer agent'),
      type: 'user',
      filters: { is_broker: { _eq: true } },
    },
    {
      name: 'buyer_broker_team_id',
      label: t('Buyer agent team'),
      type: 'team',
    },
  );

  if (includeMetadata) {
    fieldsDefinition.push({
      name: 'meta',
      label: t('Metadata'),
      type: 'category-title',
    });
    fieldsDefinition.push({
      name: 'created_at',
      label: t('Created at'),
      type: 'date',
      disabled: () => true,
    });
    fieldsDefinition.push({
      name: 'created_by',
      label: t('Created by'),
      type: 'user',
      disabled: () => true,
    });
  }

  return fieldsDefinition;
};
