import { useQuery } from '@apollo/client';
import { ListOutlined } from '@mui/icons-material';
import type { IntrospectionEnumType } from 'graphql';

import { gql } from '../../../__generated__';
import { useFiltersSearchParams } from '../useFiltersSearchParams';

import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';
import {
  AutocompletePaper,
  AutocompleteTextField,
  FilterAutocomplete,
} from './QuickFilters';

const GET_ENUMS = gql(/* GraphQL */ `
  query GetEnums {
    dictionaries(where: { type: { _eq: enums } }) {
      name
      label
    }
  }
`);

export interface EnumFilterProps extends QuickFilterProps {
  type: IntrospectionEnumType;
}

interface EnumFilterOption {
  value: string;
  label: string;
}

export const EnumFilter = ({
  type,
  label,
  path,
  where,
  addWhereClause,
  deleteWhereClause,
  getValueFromPath,
  onChange,
  queryParamsScope,
  disabled,
}: EnumFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);
  const { data } = useQuery(GET_ENUMS);
  const enumsDict = data?.dictionaries;

  // get table name by removing ending _enum from type name, sometime it ends by _enum_enum and we need to keep only one
  const tableName = type.name.replace(/_enum$/, '');

  const defaultValue: string[] | undefined = getValueFromPath(path, where);
  const defaultOptions: EnumFilterOption[] | undefined =
    defaultValue?.map(value => {
      const label =
        enumsDict?.find(d => d.name === `${tableName}.${value}`)?.label ?? '';
      return {
        value,
        label: label.length > 0 ? label : value,
      };
    }) ?? undefined;

  if (!type.enumValues) {
    throw new Error('Type is not an enum');
  }

  const options: EnumFilterOption[] = type.enumValues.map(v => {
    const label =
      enumsDict?.find(d => d.name === `${tableName}.${v.name}`)?.label ?? '';
    return {
      value: v.name,
      label: label.length > 0 ? label : v.name,
    };
  });

  const handleChange = (options: EnumFilterOption[]) => {
    const values = options.map(option => option.value) ?? [];
    let newWhere = deleteWhereClause(where, path, true);
    if (values.length > 0) {
      newWhere = addWhereClause(newWhere, path, values);
    }

    setFiltersParams(onChange?.(values, newWhere) ?? newWhere);
  };

  const chipLabel = (() => {
    if (!defaultOptions) {
      return label;
    }
    switch (defaultOptions?.length) {
      case 0:
        return label;
      case 1:
        return `${label}: ${defaultOptions[0].label}`;
      default:
        return `${label}: ${defaultOptions[0].label} +${
          defaultOptions.length - 1
        }`;
    }
  })();

  return (
    <FilterChip
      label={chipLabel}
      icon={<ListOutlined />}
      onDelete={
        (defaultOptions?.length ?? 0) > 0 ? () => handleChange([]) : undefined
      }
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <FilterAutocomplete<EnumFilterOption>
          options={options}
          onClose={handleClose}
          PaperComponent={AutocompletePaper}
          onChange={(_e, option) => handleChange(option)}
          value={defaultOptions ?? []}
          renderInput={AutocompleteTextField}
          renderOption={(props, option, { index }) => (
            <li {...props} key={`option-${option.value}` + index}>
              {option.label}
            </li>
          )}
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value;
          }}
        />
      )}
    />
  );
};
