/**
 * @generated SignedSource<<21de04dc179c7dc1a07be2e82045960d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadAppraisalInheritanceNextStep = "buy" | "sell" | "share";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadAppraisalSeparationNextStep = "buy" | "sell" | "share";
export type LeadAppraisalStep = "address" | "asset_details" | "asset_type" | "result" | "user_details" | "verification";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadGender = "female" | "male";
export type LeadMortgageBuyingStatus = "identified" | "offer" | "searching" | "visited";
export type LeadMortgageInterestRateType = "fixed" | "variable";
export type LeadMortgageLenderType = "bank" | "insurance" | "pension_fund";
export type LeadMortgageStatus = "not_started" | "working_with_bank" | "working_with_independent";
export type LeadMortgageStep = "financials" | "result" | "situation" | "type" | "verification";
export type LeadOwnership = "co_ownership" | "corporate_ownership" | "sole_ownership";
export type LeadProfessionalSituation = "business_owner" | "employed" | "retired" | "self_employed" | "unemployed";
export type LeadPropertyUsage = "not_set" | "other" | "primary_residence" | "rental_property" | "secondary_residence";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type MortgageTrancheType = "fixed" | "variable";
export type PropertyCooling = "air_conditioning" | "ceiling_fans" | "none";
export type PropertyHeatingDistribution = "floor" | "forced_air" | "other" | "radiators" | "stove";
export type PropertyOrientation = "E" | "N" | "NE" | "NW" | "S" | "SE" | "SW" | "W";
export type PropertySearchNotification = "daily" | "never" | "weekly";
export type UserMaritalStatus = "cohabitation" | "divorced" | "married" | "separated" | "single" | "widowed";
export type UpsertLeadInput = {
  clientMutationId?: string | null;
  lead?: LeadInput | null;
};
export type LeadInput = {
  accessComment?: string | null;
  accessStatus?: boolean | null;
  annualPremium?: number | null;
  appraisalInheritanceNextStep?: LeadAppraisalInheritanceNextStep | null;
  appraisalMarketTime?: string | null;
  appraisalMonthlyGrossRent?: number | null;
  appraisalNextStep?: string | null;
  appraisalOpenToOffer?: string | null;
  appraisalPerceivedValue?: number | null;
  appraisalPerception?: string | null;
  appraisalPropertyBalconySurface?: number | null;
  appraisalPropertyBuildingCondition?: number | null;
  appraisalPropertyBuiltSurface?: number | null;
  appraisalPropertyCommercialPotentialRentalIncome?: number | null;
  appraisalPropertyCommercialSurface?: number | null;
  appraisalPropertyCommercialYearlyRentalIncome?: number | null;
  appraisalPropertyConstructionYear?: number | null;
  appraisalPropertyCountry?: string | null;
  appraisalPropertyCountryCode?: string | null;
  appraisalPropertyCtn?: string | null;
  appraisalPropertyFloorOfFlat?: number | null;
  appraisalPropertyGardenSurface?: number | null;
  appraisalPropertyGoogleAddress?: GoogleAddressInput | null;
  appraisalPropertyGoogleAddressId?: string | null;
  appraisalPropertyHasElevator?: boolean | null;
  appraisalPropertyHasGarden?: boolean | null;
  appraisalPropertyHasStorageRoom?: boolean | null;
  appraisalPropertyHasSwimmingPool?: boolean | null;
  appraisalPropertyHasView?: boolean | null;
  appraisalPropertyIncomeIncludesParking?: boolean | null;
  appraisalPropertyIsCommercialFullyRented?: boolean | null;
  appraisalPropertyIsParkingFullyRented?: boolean | null;
  appraisalPropertyIsRenovated?: boolean | null;
  appraisalPropertyIsResidentialFullyRented?: boolean | null;
  appraisalPropertyKnowPlotNumber?: boolean | null;
  appraisalPropertyLandSurface?: number | null;
  appraisalPropertyLat?: number | null;
  appraisalPropertyLivingSurface?: number | null;
  appraisalPropertyLng?: number | null;
  appraisalPropertyLocality?: string | null;
  appraisalPropertyNumberOfBathrooms?: number | null;
  appraisalPropertyNumberOfBedrooms?: number | null;
  appraisalPropertyNumberOfCommercialUnits?: number | null;
  appraisalPropertyNumberOfFloors?: number | null;
  appraisalPropertyNumberOfIndoorParkings?: number | null;
  appraisalPropertyNumberOfOutdoorParkings?: number | null;
  appraisalPropertyNumberOfResidentialUnits?: number | null;
  appraisalPropertyNumberOfRooms?: number | null;
  appraisalPropertyOccupied?: string | null;
  appraisalPropertyParkingPotentialRentalIncome?: number | null;
  appraisalPropertyParkingYearlyRentalIncome?: number | null;
  appraisalPropertyPlotNumber?: string | null;
  appraisalPropertyPostcode?: string | null;
  appraisalPropertyPropertyTypeId?: string | null;
  appraisalPropertyRenovationYear?: number | null;
  appraisalPropertyResidentialPotentialRentalIncome?: number | null;
  appraisalPropertyResidentialSurface?: number | null;
  appraisalPropertyResidentialYearlyRentalIncome?: number | null;
  appraisalPropertyRoute?: string | null;
  appraisalPropertySide?: string | null;
  appraisalPropertyState?: string | null;
  appraisalPropertyStreetNumber?: string | null;
  appraisalPropertyUsage?: string | null;
  appraisalReason?: LeadAppraisalReason | null;
  appraisalSellingWith?: string | null;
  appraisalSeparationNextStep?: LeadAppraisalSeparationNextStep | null;
  appraisalStep?: LeadAppraisalStep | null;
  askingPrice?: number | null;
  assetsSecondPillar?: number | null;
  assetsThirdPillarAAnnualPremiumOrganization?: string | null;
  assetsThirdPillarAOrganisation?: string | null;
  brokerId?: string | null;
  buyHorizon?: LeadBuyHorizon | null;
  callTimes?: ReadonlyArray<string | null> | null;
  cantonalPlanComment?: string | null;
  cantonalPlanStatus?: boolean | null;
  comment?: string | null;
  completed?: boolean | null;
  constructionPermitComment?: string | null;
  constructionPermitStatus?: boolean | null;
  contactId?: string | null;
  dateOfBirth?: string | null;
  deadline?: string | null;
  debt?: number | null;
  debts?: ReadonlyArray<LeadDebtInput | null> | null;
  donation?: number | null;
  emailAddress?: string | null;
  financingSecured?: boolean | null;
  firstName?: string | null;
  gender?: LeadGender | null;
  groundComment?: string | null;
  groundStatus?: boolean | null;
  hasAcceptedTerms?: boolean | null;
  hasDependantChildren?: boolean | null;
  hasNoStreetNumber?: boolean | null;
  hasOtherCredits?: boolean | null;
  id?: string | null;
  inventoryProtectionComment?: string | null;
  inventoryProtectionStatus?: boolean | null;
  landingPageUrl?: string | null;
  lastBonus?: number | null;
  lastName?: string | null;
  localityPlanComment?: string | null;
  localityPlanStatus?: boolean | null;
  mandateProbability?: number | null;
  maritalStatus?: string | null;
  maxPropertyValue?: number | null;
  mortgageAutoMortgageType?: boolean | null;
  mortgageBuyingStatus?: LeadMortgageBuyingStatus | null;
  mortgageComments?: string | null;
  mortgageFinancialInstitution?: string | null;
  mortgageGoogleAddress?: GoogleAddressInput | null;
  mortgageGoogleAddressId?: string | null;
  mortgageHasMortgage?: boolean | null;
  mortgageInsuranceProviderId?: string | null;
  mortgageInsuranceRevenue?: number | null;
  mortgageInterestRate?: number | null;
  mortgageInterestRateType?: LeadMortgageInterestRateType | null;
  mortgageIsConstruction?: boolean | null;
  mortgageIsPropertyIdentified?: boolean | null;
  mortgageLenderId?: string | null;
  mortgageLenderName?: string | null;
  mortgageLenderType?: LeadMortgageLenderType | null;
  mortgageMortgageType?: string | null;
  mortgageNotaryId?: string | null;
  mortgageProductMortgage?: boolean | null;
  mortgageProductThirdPillar?: boolean | null;
  mortgagePropertyUsage?: string | null;
  mortgagePropertyValue?: number | null;
  mortgagePurchaseDate?: string | null;
  mortgagePurchasePrice?: number | null;
  mortgageRealEstateBrokerId?: string | null;
  mortgageReportType?: string | null;
  mortgageRevenue?: number | null;
  mortgageSell?: boolean | null;
  mortgageStartDate?: string | null;
  mortgageStatus?: LeadMortgageStatus | null;
  mortgageStep?: LeadMortgageStep | null;
  mortgageTerm?: string | null;
  nationalityId?: string | null;
  neighbourhoodPlanComment?: string | null;
  neighbourhoodPlanStatus?: boolean | null;
  neighbourhoodRelations?: string | null;
  noiseComment?: string | null;
  noiseStatus?: boolean | null;
  numberOfBuyers?: number | null;
  numberOfDependantChildren?: number | null;
  occupationId?: string | null;
  occupationText?: string | null;
  otherCredits?: number | null;
  ownership?: LeadOwnership | null;
  parentLeadId?: string | null;
  partnerAnnualPremium?: number | null;
  partnerDateOfBirth?: string | null;
  partnerDonation?: number | null;
  partnerFirstName?: string | null;
  partnerGender?: LeadGender | null;
  partnerHasOtherCredits?: boolean | null;
  partnerHasThirdPillar?: boolean | null;
  partnerLastBonus?: number | null;
  partnerLastName?: string | null;
  partnerMaritalStatus?: UserMaritalStatus | null;
  partnerNationalityId?: string | null;
  partnerOccupationText?: string | null;
  partnerOtherCredits?: number | null;
  partnerProfessionalSituation?: LeadProfessionalSituation | null;
  partnerResidenceCountryCode?: string | null;
  partnerResidenceStateId?: string | null;
  partnerSavings?: number | null;
  partnerSecondPillar?: number | null;
  partnerThirdPillar?: number | null;
  partnerThirdPillarAOrganisation?: string | null;
  partnerUseBankThirdPillar?: boolean | null;
  partnerUseInsuranceThirdPillar?: boolean | null;
  partnerWorkPermitId?: string | null;
  partnerYearlyRevenues?: number | null;
  partnerYearlyRevenuesAtRetirement?: number | null;
  phoneNumber?: string | null;
  pollutionComment?: string | null;
  pollutionStatus?: boolean | null;
  potentialDevelopment?: boolean | null;
  predictedListingDate?: string | null;
  professionalSituation?: LeadProfessionalSituation | null;
  property?: PropertyInput | null;
  propertySearchNotification?: PropertySearchNotification | null;
  propertyUsage?: LeadPropertyUsage | null;
  receivedMortgageOffer?: boolean | null;
  referrer?: string | null;
  relationship?: LeadRelationship | null;
  residenceCountryCode?: string | null;
  residenceStateId?: string | null;
  saleComments?: string | null;
  saleHasFound?: boolean | null;
  saleHorizon?: LeadSaleHorizon | null;
  saleIsSeller?: boolean | null;
  salePrice?: number | null;
  saleStarted?: boolean | null;
  savings?: number | null;
  secondContactId?: string | null;
  secondPillar?: number | null;
  servitudesComment?: string | null;
  servitudesStatus?: boolean | null;
  source?: string | null;
  sourceId?: string | null;
  sourceOrganisationId?: string | null;
  stageId?: string | null;
  stepsTaken?: string | null;
  tags?: ReadonlyArray<string | null> | null;
  thirdPillar?: number | null;
  treesComment?: string | null;
  treesStatus?: boolean | null;
  useBankThirdPillar?: boolean | null;
  useInsuranceThirdPillar?: boolean | null;
  useSecondPillar?: boolean | null;
  useThirdPillar?: boolean | null;
  userInfoIp?: string | null;
  userInfoUserAgent?: string | null;
  workPermitId?: string | null;
  yearlyRevenues?: number | null;
  yearlyRevenuesAtRetirement?: number | null;
};
export type PropertyInput = {
  balconySurface?: number | null;
  basementSurface?: number | null;
  borrowerLeadId?: string | null;
  buildingCondition?: number | null;
  buildingStandard?: number | null;
  buildingVolume?: number | null;
  buildingVolumeNorm?: string | null;
  builtSurface?: number | null;
  ceilingHeight?: number | null;
  charges?: number | null;
  commercialPotentialRentalIncome?: number | null;
  commercialSurface?: number | null;
  commercialYearlyRentalIncome?: number | null;
  constructionYear?: number | null;
  consumption?: number | null;
  cooling?: PropertyCooling | null;
  country?: string | null;
  countryCode?: string | null;
  createdBy?: string | null;
  ctn?: string | null;
  deletedByUser?: boolean | null;
  distanceToAirport?: number | null;
  distanceToCityCenter?: number | null;
  distanceToGroceryShop?: number | null;
  distanceToHighSchool?: number | null;
  distanceToHospital?: number | null;
  distanceToKindergarten?: number | null;
  distanceToMotorway?: number | null;
  distanceToPrimarySchool?: number | null;
  distanceToPublicTransport?: number | null;
  distanceToSportsCenter?: number | null;
  distanceToTrainStation?: number | null;
  emissions?: number | null;
  energyCertificateVersion?: string | null;
  energyConsumptionClass?: string | null;
  energyInspectionDate?: string | null;
  energyReferenceYear?: number | null;
  estimatedHigherEnergyCost?: number | null;
  estimatedLowerEnergyCost?: number | null;
  financingInstitutionId?: string | null;
  financingLeadId?: string | null;
  flatCondition?: number | null;
  floorOfFlat?: number | null;
  gardenSurface?: number | null;
  gasEmissionsClass?: string | null;
  googleAddressId?: string | null;
  grossFloorSurface?: number | null;
  hasAttic?: boolean | null;
  hasCellar?: boolean | null;
  hasElevator?: boolean | null;
  hasFireplace?: boolean | null;
  hasGym?: boolean | null;
  hasPhotovoltaicPanels?: boolean | null;
  hasSauna?: boolean | null;
  hasStorageRoom?: boolean | null;
  hasSwimmingPool?: boolean | null;
  hasView?: boolean | null;
  hasWellnessArea?: boolean | null;
  heating?: string | null;
  heatingDistribution?: PropertyHeatingDistribution | null;
  id?: string | null;
  incomeIncludesParking?: boolean | null;
  isChildFriendly?: boolean | null;
  isCommercialFullyRented?: boolean | null;
  isLuxury?: boolean | null;
  isMaisonette?: boolean | null;
  isOrientedEast?: boolean | null;
  isOrientedNorth?: boolean | null;
  isOrientedSouth?: boolean | null;
  isOrientedWest?: boolean | null;
  isParkingFullyRented?: boolean | null;
  isRental?: boolean | null;
  isResidentialFullyRented?: boolean | null;
  isSecondaryResidence?: boolean | null;
  isWheelchairAccessible?: boolean | null;
  landSurface?: number | null;
  lat?: number | null;
  livingSurface?: number | null;
  lng?: number | null;
  locality?: string | null;
  localityId?: string | null;
  macroLocation?: number | null;
  microLocation?: number | null;
  minergieCode?: number | null;
  monthlyGrossRent?: number | null;
  mortgage?: MortgageInput | null;
  numberOfBathrooms?: number | null;
  numberOfBedrooms?: number | null;
  numberOfCommercialUnits?: number | null;
  numberOfFlats?: number | null;
  numberOfFloors?: number | null;
  numberOfIndoorParkings?: number | null;
  numberOfLifts?: number | null;
  numberOfOutdoorParkings?: number | null;
  numberOfResidentialUnits?: number | null;
  numberOfRooms?: number | null;
  numberOfToilets?: number | null;
  orientation?: PropertyOrientation | null;
  ownersIds?: ReadonlyArray<string | null> | null;
  parkingPotentialRentalIncome?: number | null;
  parkingYearlyRentalIncome?: number | null;
  perceivedValue?: number | null;
  plotHasConstructibleReserve?: boolean | null;
  plotIsConstructible?: boolean | null;
  plotNumber?: string | null;
  postcode?: string | null;
  propertyTypeId?: string | null;
  propertyTypeName?: string | null;
  relevantServitudes?: boolean | null;
  renovationFund?: number | null;
  renovationYear?: number | null;
  residentialPotentialRentalIncome?: number | null;
  residentialSurface?: number | null;
  residentialYearlyRentalIncome?: number | null;
  route?: string | null;
  shareOfOwnership?: number | null;
  side?: string | null;
  state?: string | null;
  streetNumber?: string | null;
  subLocality?: string | null;
  terraceSurface?: number | null;
  usableSurface?: number | null;
  weightedFloorSurface?: number | null;
};
export type MortgageInput = {
  amortisation?: number | null;
  annualRentalIncome?: number | null;
  bankerId?: string | null;
  borrowerPropertyLocation?: string | null;
  expiryDate?: string | null;
  id?: string | null;
  interestRate?: number | null;
  lender?: string | null;
  lenderId?: string | null;
  loanAmount?: number | null;
  mortgageBrokerId?: string | null;
  property?: PropertyInput | null;
  propertyId?: string | null;
  signedAt?: string | null;
  startDate?: string | null;
  tranches?: ReadonlyArray<MortgageTrancheInput | null> | null;
  tranchesExist?: boolean | null;
  type?: MortgageTrancheType | null;
  value?: number | null;
};
export type MortgageTrancheInput = {
  amortisation?: number | null;
  expiryDate?: string | null;
  fixedFor?: number | null;
  interestRate?: number | null;
  loanAmount?: number | null;
  startDate?: string | null;
  type?: MortgageTrancheType | null;
};
export type GoogleAddressInput = {
  country?: string | null;
  countryCode?: string | null;
  ctn?: string | null;
  formattedAddress?: string | null;
  googlePlace?: GooglePlaceInput | null;
  googlePlaceId?: string | null;
  id?: string | null;
  lat?: number | null;
  lng?: number | null;
  locality?: string | null;
  postcode?: string | null;
  route?: string | null;
  state?: string | null;
  streetNumber?: string | null;
};
export type GooglePlaceInput = {
  address_components: ReadonlyArray<GooglePlaceAddressComponents | null>;
  formatted_address?: string | null;
  geometry?: GooglePlaceGeometry | null;
  partial_match?: boolean | null;
  place_id?: string | null;
  plus_code?: PlusCode | null;
  types?: ReadonlyArray<string | null> | null;
};
export type GooglePlaceAddressComponents = {
  long_name: string;
  short_name: string;
  types: ReadonlyArray<string>;
};
export type GooglePlaceGeometry = {
  bounds?: GooglePlaceGeometryGeometryBounds | null;
  location?: GooglePlaceGeometryLocation | null;
  location_type?: string | null;
  viewport?: GooglePlaceGeometryGeometryViewport | null;
};
export type GooglePlaceGeometryGeometryBounds = {
  east?: number | null;
  north?: number | null;
  northeast?: GooglePlaceGeometryBoundsNortheast | null;
  south?: number | null;
  southwest?: GooglePlaceGeometryBoundsSouthwest | null;
  west?: number | null;
};
export type GooglePlaceGeometryBoundsNortheast = {
  lat?: number | null;
  lng?: number | null;
};
export type GooglePlaceGeometryBoundsSouthwest = {
  lat?: number | null;
  lng?: number | null;
};
export type GooglePlaceGeometryLocation = {
  lat?: number | null;
  lng?: number | null;
};
export type GooglePlaceGeometryGeometryViewport = {
  east?: number | null;
  north?: number | null;
  northeast?: GooglePlaceGeometryViewportNortheast | null;
  south?: number | null;
  southwest?: GooglePlaceGeometryViewportSouthwest | null;
  west?: number | null;
};
export type GooglePlaceGeometryViewportNortheast = {
  lat?: number | null;
  lng?: number | null;
};
export type GooglePlaceGeometryViewportSouthwest = {
  lat?: number | null;
  lng?: number | null;
};
export type PlusCode = {
  compound_code?: string | null;
  global_code?: string | null;
};
export type LeadDebtInput = {
  debt?: number | null;
  expiration?: string | null;
  id?: string | null;
  leadId?: string | null;
  rate?: number | null;
};
export type leadContactCardUpsertMutation$variables = {
  input: UpsertLeadInput;
};
export type leadContactCardUpsertMutation$data = {
  readonly upsertLead: {
    readonly lead: {
      readonly property: {
        readonly owners: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"userEditableCard_user">;
        }> | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"leadContactCard_lead">;
    } | null;
  } | null;
};
export type leadContactCardUpsertMutation = {
  response: leadContactCardUpsertMutation$data;
  variables: leadContactCardUpsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Email",
  "kind": "LinkedField",
  "name": "primaryEmail",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referrer",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBroker",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
},
v13 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "formattedAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PhoneNumber",
    "kind": "LinkedField",
    "name": "phoneNumbers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "formattedNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number",
        "storageKey": null
      },
      (v12/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "emails",
    "plural": true,
    "selections": [
      (v6/*: any*/),
      (v12/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "broker",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadContactCardUpsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertLeadPayload",
        "kind": "LinkedField",
        "name": "upsertLead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "lead",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "leadContactCard_lead"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Property",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "owners",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "userEditableCard_user"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadContactCardUpsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertLeadPayload",
        "kind": "LinkedField",
        "name": "upsertLead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "lead",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "relationship",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "createdBy",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Property",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "owners",
                    "plural": true,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d645555b1b60533bbb679549c9fe592",
    "id": null,
    "metadata": {},
    "name": "leadContactCardUpsertMutation",
    "operationKind": "mutation",
    "text": "mutation leadContactCardUpsertMutation(\n  $input: UpsertLeadInput!\n) {\n  upsertLead(input: $input) {\n    lead {\n      ...leadContactCard_lead\n      property {\n        owners {\n          ...userEditableCard_user\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment leadContactCard_RelationshipItem on Lead {\n  id\n  relationship\n}\n\nfragment leadContactCard_lead on Lead {\n  ...leadContactCard_RelationshipItem\n  id\n  contact {\n    ...userEditableCard_user\n    displayName\n    id\n  }\n  createdBy {\n    id\n  }\n}\n\nfragment userAvatar_user on User {\n  id\n  firstName\n  lastName\n  primaryImage {\n    url\n    id\n  }\n  primaryEmail {\n    email\n    id\n  }\n  referrer\n  isBroker\n  isAdmin\n}\n\nfragment userEditableCard_user on User {\n  ...userSummary_user\n  id\n  firstName\n  lastName\n  primaryEmail {\n    email\n    id\n  }\n  primaryImage {\n    url\n    id\n  }\n  organisation {\n    name\n    formattedAddress\n    id\n  }\n  subscription {\n    status\n    id\n  }\n}\n\nfragment userSummary_user on User {\n  ...userAvatar_user\n  id\n  displayName\n  organisation {\n    id\n    name\n  }\n  phoneNumbers {\n    formattedNumber\n    number\n    primary\n    id\n  }\n  emails {\n    email\n    primary\n    id\n  }\n  broker {\n    ...userAvatar_user\n    id\n    displayName\n  }\n}\n"
  }
};
})();

(node as any).hash = "7efe8a4bd4b9dd809b9581cdfb804c17";

export default node;
