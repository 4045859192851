import { useCallback, useState } from 'react';

import { type ApolloError, useLazyQuery, useMutation } from '@apollo/client';

import {
  REALADVISOR_ES_TENANT_ID,
  REALADVISOR_FR_TENANT_ID,
  REALADVISOR_IT_TENANT_ID,
  REALADVISOR_TENANT_ID,
} from '../../../../src/utils/tenant';
import { useAppData } from '../../../providers/AppDataProvider';
import { useGetDefaultApolloTemplate } from '../../../utils/template';
import { GET_CMA_REPORTS_LIST } from '../CMAReportsList';
import {
  CREATE_CMA_REPORT,
  DOC_TEMPLATES_CMA_REPORT,
  GET_CMA_REPORTS_COUNT,
} from '../cmaReportsQueries';

type CreateCMAReportInput = {
  leadId: string;
  contactId?: string;
};

type CreateCMAReportHookResult = [
  ApolloError | null,
  boolean,
  (input: CreateCMAReportInput) => Promise<string | null>,
  () => void,
];

export const useCreateCMAReport = (): CreateCMAReportHookResult => {
  const [apolloError, setApolloError] = useState<ApolloError | null>(null);

  const [createCMAReportMutation, { loading: createCMAReportLoading }] =
    useMutation(CREATE_CMA_REPORT, {
      refetchQueries: [GET_CMA_REPORTS_LIST, GET_CMA_REPORTS_COUNT],
      onError: setApolloError,
    });

  const [getDocTemplates, { loading: getDocTemplatesLoading }] = useLazyQuery(
    DOC_TEMPLATES_CMA_REPORT,
    {
      fetchPolicy: 'network-only',
      onError: setApolloError,
    },
  );

  const { getDefaultTemplate } = useGetDefaultApolloTemplate();
  const { me } = useAppData();

  const createCMAReport = useCallback(
    async ({ leadId, contactId }: CreateCMAReportInput) => {
      const { data: docTemplatesData } = await getDocTemplates();
      const tenantId = me?.tenant.id;
      const userLanguage = me?.language;
      const language = determineLanguage(tenantId, userLanguage);

      const defaultTemplateId = getDefaultTemplate(
        docTemplatesData?.doc_templates ?? [],
      )?.id;

      const input = {
        lead_id: leadId,
        doc_template_id: defaultTemplateId,
        contact_id: contactId,
        broker_id: me?.id,
        language,
      };

      const { data, errors: cmaReportErrors } = await createCMAReportMutation({
        variables: { input },
      });

      if (cmaReportErrors || !data?.insert_cma_reports_one) {
        return null;
      }

      return data.insert_cma_reports_one.id;
    },
    [
      createCMAReportMutation,
      getDefaultTemplate,
      getDocTemplates,
      me?.id,
      me?.language,
      me?.tenant.id,
    ],
  );

  return [
    apolloError,
    createCMAReportLoading || getDocTemplatesLoading,
    createCMAReport,
    () => setApolloError(null),
  ];
};

export const determineLanguage = (
  tenantId: string | undefined,
  userLanguage: string | undefined,
): string => {
  if (tenantId === REALADVISOR_TENANT_ID && userLanguage === 'es') {
    return 'en';
  } else if (tenantId === REALADVISOR_ES_TENANT_ID && userLanguage !== 'es') {
    return 'en';
  } else if (tenantId === REALADVISOR_FR_TENANT_ID && userLanguage !== 'fr') {
    return 'en';
  } else if (tenantId === REALADVISOR_IT_TENANT_ID && userLanguage !== 'it') {
    return 'en';
  }
  return userLanguage ?? 'en';
};
