import * as React from 'react';

import type { InputBaseComponentProps } from '@mui/material';
import {
  Chip,
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import type {
  ControllerStateAndHelpers,
  DownshiftState,
  StateChangeOptions,
} from 'downshift';
import Downshift from 'downshift';

import { InputPopup, focusWrappedInputRef } from '../controls/popup';
import { useLocale } from '../hooks/locale';
import { Cancel } from '../icons/cancel';

type Item = {
  id: string;
  label: string;
  disabled?: boolean;
};

type SelectProps = {
  items: Item[];
  value: null | string;
  onChange: (value: null | string) => void;
  onBlur?: () => void;
};

type MultiSelectProps = {
  items: Item[];
  value: string[];
  onChange: (value: string[]) => void;
  onBlur?: () => void;
};

const getLabel = (item: null | Item) => item?.label ?? '';

const SelectMenu = ({
  targetRef,
  items,
  downshift,
}: {
  targetRef: { current: null | HTMLElement };
  items: Item[];
  downshift: ControllerStateAndHelpers<Item>;
}) => {
  const { t } = useLocale();
  const { isOpen, highlightedIndex, getMenuProps, getItemProps } = downshift;
  return (
    <InputPopup referenceRef={targetRef} open={isOpen}>
      <List {...getMenuProps()}>
        {items.length === 0 && (
          <ListItem disabled={true}>{t('No options')}</ListItem>
        )}
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              {...(item.disabled === true ? {} : getItemProps({ item }))}
              key={item.id}
              button={true}
              selected={highlightedIndex === index}
              disabled={item.disabled}
            >
              <ListItemText>{getLabel(item)}</ListItemText>
            </ListItem>
          ))}
      </List>
    </InputPopup>
  );
};

const filterItems = (items: Item[], inputValue: string) => {
  const lowercased = inputValue.toLowerCase();
  if (inputValue === '') {
    return items;
  }
  return items.filter(item =>
    getLabel(item).toLowerCase().includes(lowercased),
  );
};

export const SelectInput = (props: SelectProps) => {
  const { value, items } = props;
  const [filtered, search] = React.useReducer<React.Reducer<Item[], string>>(
    (_state, inputValue) => filterItems(items, inputValue),
    items,
  );
  const targetRef = React.useRef(null);
  return (
    <Downshift
      // make selectedItem always controlled by coalescing null
      selectedItem={items.find(item => item.id === value) ?? null}
      itemToString={getLabel}
      onInputValueChange={inputValue => search(inputValue)}
      onChange={selectedItem => props.onChange(selectedItem?.id ?? null)}
    >
      {downshift => {
        const { getInputProps, inputValue, selectedItem } = downshift;
        const { openMenu } = downshift;
        return (
          <div>
            <FilledInput
              {...(getInputProps({
                ref: targetRef,
                onFocus: () => {
                  search(inputValue ?? '');
                  openMenu();
                },
                onBlur: props.onBlur,
                endAdornment: selectedItem && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => props.onChange(null)}>
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
              }) as any)}
            />
            <SelectMenu
              targetRef={targetRef}
              items={filtered}
              downshift={downshift}
            />
          </div>
        );
      }}
    </Downshift>
  );
};

const multiInputStateReducer = (
  state: DownshiftState<Item>,
  changes: StateChangeOptions<Item>,
) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: false,
        inputValue: '',
      };
    default:
      return changes;
  }
};

const MultiValueInput = ({
  inputRef,
  className,
  selectedItems,
  removeItem,
  ...props
}: InputBaseComponentProps & {
  selectedItems: Item[];
  removeItem: (id: string) => void;
}) => {
  return (
    <div
      className={className}
      css={{ display: 'flex', flexWrap: 'wrap' }}
      onClick={event => focusWrappedInputRef({ current: event.currentTarget })}
    >
      {selectedItems.map(item => (
        <Chip
          key={item.id}
          size="small"
          css={{
            marginRight: 8,
            marginTop: 2,
            marginBottom: 2,
            flex: '0 0 auto',
          }}
          label={getLabel(item)}
          onDelete={() => removeItem(item.id)}
        />
      ))}
      <input
        ref={inputRef}
        css={{
          all: 'inherit',
          padding: 0,
          width: 80,
          flex: '1 1 auto',
        }}
        {...props}
      />
    </div>
  );
};

export const getMultiSelectShrink = (items: string[]): undefined | boolean =>
  items.length === 0 ? undefined : true;

export const MultiSelectInput = (props: MultiSelectProps) => {
  const { items, value, onChange } = props;
  const targetRef = React.useRef(null);
  const [filtered, search] = React.useReducer<React.Reducer<Item[], string>>(
    (_state, inputValue) => filterItems(items, inputValue),
    items,
  );
  const removeItem = (removingId: string) =>
    onChange(value.filter(id => id !== removingId));
  const addItem = (addingId: string) => onChange([...value, addingId]);
  return (
    <Downshift<Item>
      selectedItem={null}
      itemToString={() => ''}
      stateReducer={multiInputStateReducer}
      onInputValueChange={inputValue => search(inputValue)}
      onChange={item => {
        if (item && value.includes(item.id) === false) {
          addItem(item.id);
        }
      }}
    >
      {downshift => {
        const { getInputProps, inputValue, openMenu } = downshift;
        return (
          <div>
            <FilledInput
              {...(getInputProps({
                ref: targetRef,
                onFocus: () => {
                  search(inputValue ?? '');
                  openMenu();
                },
                onBlur: props.onBlur,
                onKeyDown: (event: React.KeyboardEvent) => {
                  if (event.key === 'Backspace' && inputValue?.length === 0) {
                    removeItem(value[value.length - 1]);
                  }
                },
                inputComponent: MultiValueInput,
                inputProps: {
                  selectedItems: props.value
                    .map(id => items.find(item => item.id === id))
                    .filter(Boolean),
                  removeItem,
                },
              }) as any)}
            />
            <SelectMenu
              targetRef={targetRef}
              items={filtered.filter(item => !value.includes(item.id))}
              downshift={downshift}
            />
          </div>
        );
      }}
    </Downshift>
  );
};
