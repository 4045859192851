import type {
  Boolean_Comparison_Exp,
  Date_Comparison_Exp,
  Float8_Comparison_Exp,
  Float_Comparison_Exp,
  Int_Comparison_Exp,
  String_Comparison_Exp,
} from '../__generated__/graphql';

import { SQLIntervalToISODate, isSQLIntervalString } from './parseSQLInterval';

export type LogicalOperator = '_and' | '_or' | '_not._and' | '_not._or';

export type ConditionOperator =
  | '_eq'
  | '_neq'
  | '_gt'
  | '_gte'
  | '_lt'
  | '_lte'
  | '_like'
  | '_nlike'
  | '_ilike'
  | '_nilike'
  | '_is_null'
  | '_in'
  | '_nin'
  | '_regex'
  | '_nregex'
  | '_iregex'
  | '_niregex';

export const CONDITION_OPERATORS = [
  '_eq',
  '_neq',
  '_gt',
  '_gte',
  '_lt',
  '_lte',
  '_like',
  '_nlike',
  '_ilike',
  '_nilike',
  '_is_null',
  '_in',
  '_nin',
  '_nregex',
  '_regex',
  '_iregex',
  '_niregex',
] as const;

export type WhereClause = {
  operator: LogicalOperator;
  object: string | null;
  object_operator: '_not' | null;
  conditions: (WhereClause | ConditionClause)[];
};

export type ConditionClause = {
  column: string;
  operator: ConditionOperator;
  value: string | boolean | number | string[] | null;
};

type HasuraConditionClause = {
  [key: string]:
    | HasuraConditionClause
    | String_Comparison_Exp
    | Int_Comparison_Exp
    | Boolean_Comparison_Exp
    | Date_Comparison_Exp
    | Float8_Comparison_Exp
    | Float_Comparison_Exp;
};

const isConditionOperator = (operator: string): operator is ConditionOperator =>
  CONDITION_OPERATORS.includes(operator as ConditionOperator);

const parseConditonValue = (value: any): ConditionClause['value'] => {
  // check if value is a string, number, boolean and return it
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return value;
  }
  // check if value is an array of strings and return it
  if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
    return value;
  }
  throw new Error('Invalid condition value');
};

const parseConditonOperator = (value: any): ConditionClause['operator'] => {
  // check if value is valid condition operator and return it
  if (isConditionOperator(value)) {
    return value;
  }
  throw new Error('Invalid condition operator: ' + JSON.stringify(value));
};

export const isWhereClause = (
  obj: WhereClause | ConditionClause | {},
): obj is WhereClause => {
  // if object has conditions it is a where clause
  return (obj as WhereClause)?.conditions !== undefined;
};

export const isConditionClause = (
  obj: WhereClause | ConditionClause,
): boolean => {
  // if object has value check it is a condition clause
  return (obj as ConditionClause).value !== undefined;
};

export const parseWhereClause = (
  whereObj: Record<string, any>,
  parentObject?: string,
): WhereClause | ConditionClause | {} => {
  // if whereObj is null throw error
  if (whereObj == null) {
    throw new Error('Invalid where clause: null');
  }
  const keys = Object.keys(whereObj);

  // if there is more than one key wrap in _and group and process
  if (keys.length > 1) {
    return {
      operator: '_and',
      object: parentObject ?? null,
      object_operator: null,
      conditions: keys.map(key => parseWhereClause({ [key]: whereObj[key] })),
    };
  }

  // if where object has only one key
  const key = keys[0];
  // if key is logical operator _and & _or
  if (['_and', '_or'].includes(key)) {
    const operator = key as LogicalOperator;
    const conditions = whereObj[key].map((condition: Record<string, any>) =>
      parseWhereClause(condition),
    );
    return {
      operator,
      object: parentObject ?? null,
      object_operator: null,
      conditions,
    };
    // if key is logical operator _not
  } else if (key === '_not') {
    // _not is hard to use in UI so we either:
    // 1. wrap the whole where clause in _not._and or _not._or group if it precends a group operator
    // 2. use a as object operator if it precends a group with an object
    // 3. wrap conditions in _not._and group if it precends a condition
    // get the sub where clause

    const subWhereClause = parseWhereClause(whereObj[key], parentObject);

    // 1. if sub where clause is an empty object
    if (Object.keys(subWhereClause).length === 0) {
      return {};
    }
    // 2. if sub where clause is a group operator
    else if (
      isWhereClause(subWhereClause) &&
      !isConditionClause(subWhereClause) &&
      ['_and', '_or'].includes(subWhereClause.operator)
    ) {
      // if sub where clause is a group operator
      // append _not to the operator
      if (subWhereClause.object == null) {
        subWhereClause.operator =
          `_not.${subWhereClause.operator}` as LogicalOperator;
        return subWhereClause;
      } else {
        // if sub where clause is has an object operator
        subWhereClause.object_operator = '_not';
        return subWhereClause;
      }
    } else {
      // if sub where clause is a condition clause
      // wrap it in _not._and group
      return {
        operator: '_not._and',
        object: parentObject ?? null,
        object_operator: null,
        conditions: [subWhereClause],
      };
    }
  } else {
    // Treat as a nested object if it has
    // more than one key or it's an empty object
    // subkey is not an operator
    const subKeys = Object.keys(whereObj[key]);
    // check if empty object
    if (subKeys.length === 0) {
      return {};
    } else if (subKeys.length > 1 || !isConditionOperator(subKeys[0])) {
      // if is has a parent object wrap it in a _and group
      if (parentObject) {
        return {
          operator: '_and',
          object: parentObject,
          object_operator: null,
          conditions: [parseWhereClause(whereObj[key], key)],
        };
      }
      // else parse the nested object
      return parseWhereClause(whereObj[key], key);
    } else {
      // if it's a condition clause return it
      const column = key;
      const operator = parseConditonOperator(Object.keys(whereObj[key])[0]);
      const value = parseConditonValue(whereObj[key][operator]);
      const conditionClause = {
        column,
        operator,
        value,
      };
      // if column is id add the parent object as a prefix
      // this is needed to better support specific filters such as user select
      if (column === 'id') {
        return {
          column: `${parentObject}.${column}`,
          operator,
          value,
        };
      }
      // if is has a parent object wrap it in a _and group
      if (parentObject) {
        return {
          operator: '_and',
          object: parentObject,
          object_operator: null,
          conditions: [conditionClause],
        };
      }
      // otherwise return the condition clause
      return conditionClause;
    }
  }
};

const buildHasuraConditionClause = (
  flatKey: string,
  operator: ConditionOperator,
  value: any,
): HasuraConditionClause => {
  // If column has dot it is a nested column
  const keys = flatKey.split('.');
  // Add operator to the end of the keys
  keys.push(operator);
  // Create the hasura condition object
  const obj: HasuraConditionClause = {};

  let currentObj: Record<string, any> = obj;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      currentObj[key] = value; // set the value
    } else {
      currentObj[key] = {}; // create a nested object
      currentObj = currentObj[key]; // update the current object
    }
  }

  return obj;
};

export const revertWhereClause = (
  whereClause: WhereClause | ConditionClause,
): Record<string, any> | null => {
  if (
    'column' in whereClause &&
    'operator' in whereClause &&
    'value' in whereClause
  ) {
    return buildHasuraConditionClause(
      whereClause.column,
      whereClause.operator,
      whereClause.value,
    );
  }

  if ('operator' in whereClause && 'conditions' in whereClause) {
    const conditions = (whereClause as WhereClause).conditions.map(condition =>
      revertWhereClause(condition),
    );

    // Build normal hasura close with group operator
    let hasuraWhereClause: Record<string, any> = {
      [whereClause.operator]: conditions,
    };

    // Build hasura close with group operator and negation
    if (whereClause.operator.startsWith('_not.')) {
      const [operator, subOperator] = whereClause.operator.split('.');
      hasuraWhereClause = {
        [operator]: {
          [subOperator]: conditions,
        },
      };
    }

    // Add object if it exists
    if (whereClause.object) {
      hasuraWhereClause = {
        [whereClause.object]: hasuraWhereClause,
      };
    }

    // Add object operator if it exists
    if (whereClause.object_operator) {
      hasuraWhereClause = {
        [whereClause.object_operator]: hasuraWhereClause,
      };
    }

    return hasuraWhereClause;
  }

  return null;
};

// Recursive function to optimize where clause before querying hasura
// - Replaces interval string with ISO date (util hasura is compatible with interval strings)
// - Replaces place.id with _or operator and adds array of state_id, locality_id, country_id, municipality_id with _in

export const prepareWhereClauseQuery = (
  clause: Record<string, any>,
): Record<string, any> => {
  // Recursive function for iterating through arrays
  const traverseArray = (arr: any[]): any[] => arr.map(prepareWhereClauseQuery);

  // Recursive function for iterating through objects
  const traverseObject = (obj: Record<string, any>): Record<string, any> => {
    for (const key in obj) {
      if (typeof obj[key] === 'string' && isSQLIntervalString(obj[key])) {
        obj[key] = SQLIntervalToISODate(obj[key]);
      } else if (key === 'places') {
        // replace places with _or operator and add array of state_id, locality_id, country_id, municipality_id with _in
        const ids = obj[key].id._in;
        obj._or = {
          _or: [
            { state_id: { _in: ids } },
            { district_id: { _in: ids } },
            { municipality_id: { _in: ids } },
            { locality_id: { _in: ids } },
            { postcode_id: { _in: ids } },
            { neighbourhood_id: { _in: ids } },
          ],
        };
        // rename object key
        delete obj[key];
      } else if (Array.isArray(obj[key])) {
        obj[key] = traverseArray(obj[key]);
      } else if (typeof obj[key] === 'object' && obj[key] != null) {
        obj[key] = traverseObject(obj[key]);
      }
    }
    return obj;
  };

  // Main function logic
  if (Array.isArray(clause)) {
    return traverseArray(clause);
  } else if (typeof clause === 'object' && clause != null) {
    return traverseObject(clause);
  }

  // If the clause is not an object or an array, return it as is
  return clause;
};
