import { FormControl, FormHelperText } from '@mui/material';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { TeamsSelect } from '../teams-select/TeamsSelect';

import { RaLabel } from './RaLabel';

export type RaTeamProps = {
  control: Control<any>;
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
};

export const RaTeam = ({
  name,
  label,
  control,
  disabled = false,
  required,
}: RaTeamProps) => {
  const { t } = useLocale();
  const rules = {
    required: required ? t('This field is required') : undefined,
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <RaLabel label={label} required={required} />
          <TeamsSelect
            disabled={disabled}
            teamId={value}
            onChange={teamId => onChange(teamId)}
            size="small"
            clearOnBlur={value == null}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
