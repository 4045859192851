import { type ImportDialogProps } from '../../../list-toolbar/ListToolbar';
import { CSVImportModal } from '../../components/importer/CSVImportModal';

import { INSERT_LOTS } from './lotsQueries';

const columns = [
  {
    key: 'imported_id',
    name: 'imported_id',
    required: false,
    suggested_mappings: ['imported_id', 'id'],
  },
  {
    key: 'lot_reference',
    name: 'lot_reference',
    required: false,
    suggested_mappings: ['lot_reference', 'reference'],
  },
  {
    key: 'status',
    name: 'status',
    required: true,
    suggested_mappings: ['status'],
  },
  {
    key: 'title',
    name: 'title',
    required: false,
    suggested_mappings: ['title', 'name'],
  },
  {
    key: 'description',
    name: 'description',
    required: false,
    suggested_mappings: ['description', 'details'],
  },
  {
    key: 'offer_type',
    name: 'offer_type',
    required: true,
    suggested_mappings: ['offer_type', 'type'],
  },
  {
    key: 'price_unit',
    name: 'price_unit',
    required: true,
    suggested_mappings: ['price_unit', 'unit'],
  },
  {
    key: 'currency',
    name: 'currency',
    required: true,
    suggested_mappings: ['currency', 'currency_code'],
  },
  {
    key: 'sale_price',
    name: 'sale_price',
    required: false,
    suggested_mappings: ['sale_price', 'price'],
    data_type: 'number',
  },
  {
    key: 'rent_extra',
    name: 'rent_extra',
    required: false,
    suggested_mappings: ['rent_extra', 'additional_rent'],
    data_type: 'number',
  },
  {
    key: 'rent_net',
    name: 'rent_net',
    required: false,
    suggested_mappings: ['rent_net', 'net_rent'],
    data_type: 'number',
  },
  {
    key: 'available_from',
    name: 'available_from',
    required: false,
    suggested_mappings: ['available_from', 'availability_date'],
  },
  {
    key: 'brochure_title',
    name: 'brochure_title',
    required: false,
    suggested_mappings: ['brochure_title', 'brochure_name'],
  },
  {
    key: 'brochure_description',
    name: 'brochure_description',
    required: false,
    suggested_mappings: ['brochure_description', 'brochure_details'],
  },
  {
    key: 'video_url',
    name: 'video_url',
    required: false,
    suggested_mappings: ['video_url', 'video_link'],
  },
  {
    key: 'virtual_visit_url',
    name: 'virtual_visit_url',
    required: false,
    suggested_mappings: ['virtual_visit_url', 'virtual_tour_link'],
  },
  {
    key: 'use_custom_brochure_description',
    name: 'use_custom_brochure_description',
    required: false,
    suggested_mappings: [
      'use_custom_brochure_description',
      'custom_description',
    ],
    data_type: 'boolean',
  },
  {
    key: 'use_custom_brochure_title',
    name: 'use_custom_brochure_title',
    required: false,
    suggested_mappings: ['use_custom_brochure_title', 'custom_title'],
    data_type: 'boolean',
  },
  {
    key: 'hide_price_on_brochure',
    name: 'hide_price_on_brochure',
    required: false,
    suggested_mappings: ['hide_price_on_brochure', 'obscure_price_brochure'],
    data_type: 'boolean',
  },
  {
    key: 'hide_price_on_portals',
    name: 'hide_price_on_portals',
    required: false,
    suggested_mappings: ['hide_price_on_portals', 'obscure_price_portals'],
    data_type: 'boolean',
  },
  {
    key: 'send_enquiries_to_sellers',
    name: 'send_enquiries_to_sellers',
    required: false,
    suggested_mappings: ['send_enquiries_to_sellers', 'forward_enquiries'],
    data_type: 'boolean',
  },
  {
    key: 'origin',
    name: 'origin',
    required: false,
    suggested_mappings: ['origin', 'source'],
  },
  {
    key: 'shared_on_mls',
    name: 'shared_on_mls',
    required: false,
    suggested_mappings: ['shared_on_mls', 'mls_sharing'],
    data_type: 'boolean',
  },
  {
    key: 'enquiry_response_enabled',
    name: 'enquiry_response_enabled',
    required: false,
    suggested_mappings: ['enquiry_response_enabled', 'allow_enquiries'],
    data_type: 'boolean',
  },
  {
    key: 'mandate_type',
    name: 'mandate_type',
    required: false,
    suggested_mappings: ['mandate_type', 'type_of_mandate'],
  },
  {
    key: 'is_exclusive',
    name: 'is_exclusive',
    required: false,
    suggested_mappings: ['is_exclusive', 'exclusive'],
    data_type: 'boolean',
  },
  {
    key: 'signed_at',
    name: 'signed_at',
    required: false,
    suggested_mappings: ['signed_at', 'date_signed'],
  },
  {
    key: 'is_sales_tax_inclusive',
    name: 'is_sales_tax_inclusive',
    required: false,
    suggested_mappings: ['is_sales_tax_inclusive', 'tax_inclusive'],
    data_type: 'boolean',
  },
  {
    key: 'commission_rate',
    name: 'commission_rate',
    required: false,
    suggested_mappings: ['commission_rate', 'rate_of_commission'],
    data_type: 'number',
  },
  {
    key: 'duration_in_months',
    name: 'duration_in_months',
    required: false,
    suggested_mappings: ['duration_in_months', 'contract_duration'],
    data_type: 'number',
  },
  {
    key: 'fixed_fee',
    name: 'fixed_fee',
    required: false,
    suggested_mappings: ['fixed_fee', 'fixed_charge'],
    data_type: 'number',
  },
  {
    key: 'has_success_fee',
    name: 'has_success_fee',
    required: false,
    suggested_mappings: ['has_success_fee', 'success_fee_applicable'],
    data_type: 'boolean',
  },
  {
    key: 'mandate_sale_price',
    name: 'mandate_sale_price',
    required: false,
    suggested_mappings: ['mandate_sale_price', 'sale_price_mandate'],
    data_type: 'number',
  },
  {
    key: 'minimum_net_seller_price',
    name: 'minimum_net_seller_price',
    required: false,
    suggested_mappings: ['minimum_net_seller_price', 'net_seller_minimum'],
    data_type: 'number',
  },
  {
    key: 'success_fee_percentage',
    name: 'success_fee_percentage',
    required: false,
    suggested_mappings: ['success_fee_percentage', 'percentage_success_fee'],
    data_type: 'number',
  },
  {
    key: 'expected_time_to_sell',
    name: 'expected_time_to_sell',
    required: false,
    suggested_mappings: ['expected_time_to_sell', 'sale_timeframe'],
  },
  {
    key: 'success_fee_custom',
    name: 'success_fee_custom',
    required: false,
    suggested_mappings: ['success_fee_custom', 'custom_success_fee'],
  },
  {
    key: 'success_fee_type',
    name: 'success_fee_type',
    required: false,
    suggested_mappings: ['success_fee_type', 'type_of_success_fee'],
  },
  {
    key: 'broker_email',
    name: 'broker_email',
    required: false,
    suggested_mappings: [
      'broker_email',
      'email_broker',
      'broker.emails[0].email',
    ],
  },
  {
    key: 'broker_imported_id',
    name: 'broker_imported_id',
    required: false,
    suggested_mappings: ['broker_imported_id', 'broker_id', 'broker.id'],
  },
  {
    key: 'property_property_type_name',
    name: 'property_property_type_name',
    required: false,
    suggested_mappings: ['property.property_type.name', 'property_type'],
  },
  {
    key: 'property_route',
    name: 'property_route',
    required: false,
    suggested_mappings: ['property.route', 'route', 'street'],
  },
  {
    key: 'property_street_number',
    name: 'property_street_number',
    required: false,
    suggested_mappings: ['property.street_number', 'street_number', 'number'],
  },
  {
    key: 'property_locality',
    name: 'property_locality',
    required: false,
    suggested_mappings: ['property.locality', 'locality', 'city'],
  },
  {
    key: 'property_postcode',
    name: 'property_postcode',
    required: false,
    suggested_mappings: ['property.postcode', 'postcode', 'zip'],
  },
  {
    key: 'property_state',
    name: 'property_state',
    required: false,
    suggested_mappings: ['property.state', 'state'],
  },
  {
    key: 'property_country',
    name: 'property_country',
    required: false,
    suggested_mappings: ['property.country', 'country'],
  },
  {
    key: 'property_country_code',
    name: 'property_country_code',
    required: false,
    suggested_mappings: ['property.country_code', 'country_code', 'cc'],
  },
  {
    key: 'property_lat',
    name: 'property_lat',
    required: false,
    suggested_mappings: ['property.lat', 'latitude', 'lat'],
    data_type: 'number',
  },
  {
    key: 'property_lng',
    name: 'property_lng',
    required: false,
    suggested_mappings: ['property.lng', 'longitude', 'lng'],
    data_type: 'number',
  },
  {
    key: 'property_macro_location',
    name: 'property_macro_location',
    required: false,
    suggested_mappings: [
      'property.macro_location',
      'macro_location',
      'macro_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_micro_location',
    name: 'property_micro_location',
    required: false,
    suggested_mappings: [
      'property.micro_location',
      'micro_location',
      'micro_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_living_surface',
    name: 'property_living_surface',
    required: false,
    suggested_mappings: [
      'property.living_surface',
      'living_surface',
      'living_space',
    ],
    data_type: 'number',
  },
  {
    key: 'property_usable_surface',
    name: 'property_usable_surface',
    required: false,
    suggested_mappings: [
      'property.usable_surface',
      'usable_surface',
      'usable_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_weighted_floor_surface',
    name: 'property_weighted_floor_surface',
    required: false,
    suggested_mappings: [
      'property.weighted_floor_surface',
      'weighted_floor_surface',
      'weighted_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_gross_floor_surface',
    name: 'property_gross_floor_surface',
    required: false,
    suggested_mappings: [
      'property.gross_floor_surface',
      'gross_floor_surface',
      'total_floor_space',
    ],
    data_type: 'number',
  },
  {
    key: 'property_built_surface',
    name: 'property_built_surface',
    required: false,
    suggested_mappings: [
      'property.built_surface',
      'built_surface',
      'constructed_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_land_surface',
    name: 'property_land_surface',
    required: false,
    suggested_mappings: ['property.land_surface', 'land_surface', 'plot_size'],
    data_type: 'number',
  },
  {
    key: 'property_balcony_surface',
    name: 'property_balcony_surface',
    required: false,
    suggested_mappings: [
      'property.balcony_surface',
      'balcony_surface',
      'balcony_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_terrace_surface',
    name: 'property_terrace_surface',
    required: false,
    suggested_mappings: [
      'property.terrace_surface',
      'terrace_surface',
      'terrace_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_garden_surface',
    name: 'property_garden_surface',
    required: false,
    suggested_mappings: [
      'property.garden_surface',
      'garden_surface',
      'garden_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_basement_surface',
    name: 'property_basement_surface',
    required: false,
    suggested_mappings: [
      'property.basement_surface',
      'basement_surface',
      'basement_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_commercial_surface',
    name: 'property_commercial_surface',
    required: false,
    suggested_mappings: [
      'property.commercial_surface',
      'commercial_surface',
      'commercial_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_residential_surface',
    name: 'property_residential_surface',
    required: false,
    suggested_mappings: [
      'property.residential_surface',
      'residential_surface',
      'residential_area',
    ],
    data_type: 'number',
  },
  {
    key: 'property_ceiling_height',
    name: 'property_ceiling_height',
    required: false,
    suggested_mappings: [
      'property.ceiling_height',
      'ceiling_height',
      'ceiling',
    ],
    data_type: 'number',
  },
  {
    key: 'property_building_volume',
    name: 'property_building_volume',
    required: false,
    suggested_mappings: [
      'property.building_volume',
      'building_volume',
      'volume',
    ],
    data_type: 'number',
  },
  {
    key: 'property_building_volume_norm',
    name: 'property_building_volume_norm',
    required: false,
    suggested_mappings: [
      'property.building_volume_norm',
      'building_volume_norm',
      'volume_norm',
    ],
  },
  {
    key: 'property_number_of_rooms',
    name: 'property_number_of_rooms',
    required: false,
    suggested_mappings: [
      'property.number_of_rooms',
      'number_of_rooms',
      'rooms',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_bedrooms',
    name: 'property_number_of_bedrooms',
    required: false,
    suggested_mappings: [
      'property.number_of_bedrooms',
      'number_of_bedrooms',
      'bedrooms',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_bathrooms',
    name: 'property_number_of_bathrooms',
    required: false,
    suggested_mappings: [
      'property.number_of_bathrooms',
      'number_of_bathrooms',
      'bathrooms',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_toilets',
    name: 'property_number_of_toilets',
    required: false,
    suggested_mappings: [
      'property.number_of_toilets',
      'number_of_toilets',
      'toilets',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_floors',
    name: 'property_number_of_floors',
    required: false,
    suggested_mappings: [
      'property.number_of_floors',
      'number_of_floors',
      'floors',
    ],
    data_type: 'number',
  },
  {
    key: 'property_floor_of_flat',
    name: 'property_floor_of_flat',
    required: false,
    suggested_mappings: [
      'property.floor_of_flat',
      'floor_of_flat',
      'apartment_floor',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_indoor_parkings',
    name: 'property_number_of_indoor_parkings',
    required: false,
    suggested_mappings: [
      'property.number_of_indoor_parkings',
      'number_of_indoor_parkings',
      'indoor_parkings',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_outdoor_parkings',
    name: 'property_number_of_outdoor_parkings',
    required: false,
    suggested_mappings: [
      'property.number_of_outdoor_parkings',
      'number_of_outdoor_parkings',
      'outdoor_parkings',
    ],
    data_type: 'number',
  },
  {
    key: 'property_parking_potential_rental_income',
    name: 'property_parking_potential_rental_income',
    required: false,
    suggested_mappings: [
      'property.parking_potential_rental_income',
      'parking_potential_rental_income',
      'parking_rental_income',
    ],
    data_type: 'number',
  },
  {
    key: 'property_parking_yearly_rental_income',
    name: 'property_parking_yearly_rental_income',
    required: false,
    suggested_mappings: [
      'property.parking_yearly_rental_income',
      'parking_yearly_rental_income',
      'yearly_parking_income',
    ],
    data_type: 'number',
  },
  {
    key: 'property_income_includes_parking',
    name: 'property_income_includes_parking',
    required: false,
    suggested_mappings: [
      'property.income_includes_parking',
      'income_includes_parking',
      'parking_income_included',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_lifts',
    name: 'property_number_of_lifts',
    required: false,
    suggested_mappings: [
      'property.number_of_lifts',
      'number_of_lifts',
      'lifts',
    ],
    data_type: 'number',
  },
  {
    key: 'property_minergie_code',
    name: 'property_minergie_code',
    required: false,
    suggested_mappings: [
      'property.minergie_code',
      'minergie_code',
      'energy_efficiency_code',
    ],
    data_type: 'number',
  },
  {
    key: 'property_is_oriented_north',
    name: 'property_is_oriented_north',
    required: false,
    suggested_mappings: [
      'property.is_oriented_north',
      'is_oriented_north',
      'north',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_oriented_south',
    name: 'property_is_oriented_south',
    required: false,
    suggested_mappings: [
      'property.is_oriented_south',
      'is_oriented_south',
      'south',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_oriented_east',
    name: 'property_is_oriented_east',
    required: false,
    suggested_mappings: [
      'property.is_oriented_east',
      'is_oriented_east',
      'east',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_oriented_west',
    name: 'property_is_oriented_west',
    required: false,
    suggested_mappings: [
      'property.is_oriented_west',
      'is_oriented_west',
      'west',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_has_view',
    name: 'property_has_view',
    required: false,
    suggested_mappings: ['property.has_view', 'has_view', 'view'],
    data_type: 'boolean',
  },
  {
    key: 'property_has_fireplace',
    name: 'property_has_fireplace',
    required: false,
    suggested_mappings: [
      'property.has_fireplace',
      'has_fireplace',
      'fireplace',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_has_sauna',
    name: 'property_has_sauna',
    required: false,
    suggested_mappings: ['property.has_sauna', 'has_sauna', 'sauna'],
    data_type: 'boolean',
  },
  {
    key: 'property_has_gym',
    name: 'property_has_gym',
    required: false,
    suggested_mappings: ['property.has_gym', 'has_gym', 'gym'],
    data_type: 'boolean',
  },
  {
    key: 'property_has_wellness_area',
    name: 'property_has_wellness_area',
    required: false,
    suggested_mappings: [
      'property.has_wellness_area',
      'has_wellness_area',
      'wellness_area',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_has_photovoltaic_panels',
    name: 'property_has_photovoltaic_panels',
    required: false,
    suggested_mappings: [
      'property.has_photovoltaic_panels',
      'has_photovoltaic_panels',
      'photovoltaic_panels',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_has_swimming_pool',
    name: 'property_has_swimming_pool',
    required: false,
    suggested_mappings: [
      'property.has_swimming_pool',
      'has_swimming_pool',
      'swimming_pool',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_has_attic',
    name: 'property_has_attic',
    required: false,
    suggested_mappings: ['property.has_attic', 'has_attic', 'attic'],
    data_type: 'boolean',
  },
  {
    key: 'property_has_cellar',
    name: 'property_has_cellar',
    required: false,
    suggested_mappings: ['property.has_cellar', 'has_cellar', 'cellar'],
    data_type: 'boolean',
  },
  {
    key: 'property_has_storage_room',
    name: 'property_has_storage_room',
    required: false,
    suggested_mappings: [
      'property.has_storage_room',
      'has_storage_room',
      'storage_room',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_has_elevator',
    name: 'property_has_elevator',
    required: false,
    suggested_mappings: ['property.has_elevator', 'has_elevator', 'elevator'],
    data_type: 'boolean',
  },
  {
    key: 'property_is_child_friendly',
    name: 'property_is_child_friendly',
    required: false,
    suggested_mappings: [
      'property.is_child_friendly',
      'is_child_friendly',
      'child_friendly',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_commercial_fully_rented',
    name: 'property_is_commercial_fully_rented',
    required: false,
    suggested_mappings: [
      'property.is_commercial_fully_rented',
      'is_commercial_fully_rented',
      'commercial_fully_rented',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_luxury',
    name: 'property_is_luxury',
    required: false,
    suggested_mappings: ['property.is_luxury', 'is_luxury', 'luxury'],
    data_type: 'boolean',
  },
  {
    key: 'property_is_maisonette',
    name: 'property_is_maisonette',
    required: false,
    suggested_mappings: [
      'property.is_maisonette',
      'is_maisonette',
      'maisonette',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_parking_fully_rented',
    name: 'property_is_parking_fully_rented',
    required: false,
    suggested_mappings: [
      'property.is_parking_fully_rented',
      'is_parking_fully_rented',
      'parking_fully_rented',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_rental',
    name: 'property_is_rental',
    required: false,
    suggested_mappings: ['property.is_rental', 'is_rental', 'rental'],
    data_type: 'boolean',
  },
  {
    key: 'property_is_residential_fully_rented',
    name: 'property_is_residential_fully_rented',
    required: false,
    suggested_mappings: [
      'property.is_residential_fully_rented',
      'is_residential_fully_rented',
      'residential_fully_rented',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_secondary_residence',
    name: 'property_is_secondary_residence',
    required: false,
    suggested_mappings: [
      'property.is_secondary_residence',
      'is_secondary_residence',
      'secondary_residence',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_is_wheelchair_accessible',
    name: 'property_is_wheelchair_accessible',
    required: false,
    suggested_mappings: [
      'property.is_wheelchair_accessible',
      'is_wheelchair_accessible',
      'wheelchair_accessible',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_construction_year',
    name: 'property_construction_year',
    required: false,
    suggested_mappings: [
      'property.construction_year',
      'construction_year',
      'built_year',
    ],
    data_type: 'number',
  },
  {
    key: 'property_renovation_year',
    name: 'property_renovation_year',
    required: false,
    suggested_mappings: [
      'property.renovation_year',
      'renovation_year',
      'year_renovated',
    ],
    data_type: 'number',
  },
  {
    key: 'property_building_condition',
    name: 'property_building_condition',
    required: false,
    suggested_mappings: [
      'property.building_condition',
      'building_condition',
      'condition',
    ],
    data_type: 'number',
  },
  {
    key: 'property_flat_condition',
    name: 'property_flat_condition',
    required: false,
    suggested_mappings: [
      'property.flat_condition',
      'flat_condition',
      'apartment_condition',
    ],
    data_type: 'number',
  },
  {
    key: 'property_plot_number',
    name: 'property_plot_number',
    required: false,
    suggested_mappings: [
      'property.plot_number',
      'plot_number',
      'land_plot_number',
    ],
  },
  {
    key: 'property_plot_registry_number',
    name: 'property_plot_registry_number',
    required: false,
    suggested_mappings: [
      'property.plot_registry_number',
      'plot_registry_number',
      'registry_number',
    ],
  },
  {
    key: 'property_plot_is_constructible',
    name: 'property_plot_is_constructible',
    required: false,
    suggested_mappings: [
      'property.plot_is_constructible',
      'plot_is_constructible',
      'constructible_plot',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_plot_has_constructible_reserve',
    name: 'property_plot_has_constructible_reserve',
    required: false,
    suggested_mappings: [
      'property.plot_has_constructible_reserve',
      'plot_has_constructible_reserve',
      'constructible_reserve',
    ],
    data_type: 'boolean',
  },
  {
    key: 'property_number_of_residential_units',
    name: 'property_number_of_residential_units',
    required: false,
    suggested_mappings: [
      'property.number_of_residential_units',
      'number_of_residential_units',
      'residential_units',
    ],
    data_type: 'number',
  },
  {
    key: 'property_number_of_commercial_units',
    name: 'property_number_of_commercial_units',
    required: false,
    suggested_mappings: [
      'property.number_of_commercial_units',
      'number_of_commercial_units',
      'commercial_units',
    ],
    data_type: 'number',
  },
  {
    key: 'property_share_of_ownership',
    name: 'property_share_of_ownership',
    required: false,
    suggested_mappings: [
      'property.share_of_ownership',
      'share_of_ownership',
      'ownership_share',
    ],
    data_type: 'number',
  },
  {
    key: 'property_residential_yearly_rental_income',
    name: 'property_residential_yearly_rental_income',
    required: false,
    suggested_mappings: [
      'property.residential_yearly_rental_income',
      'residential_yearly_rental_income',
      'yearly_residential_income',
    ],
    data_type: 'number',
  },
  {
    key: 'property_residential_potential_rental_income',
    name: 'property_residential_potential_rental_income',
    required: false,
    suggested_mappings: [
      'property.residential_potential_rental_income',
      'residential_potential_rental_income',
      'potential_residential_income',
    ],
    data_type: 'number',
  },
  {
    key: 'property_commercial_yearly_rental_income',
    name: 'property_commercial_yearly_rental_income',
    required: false,
    suggested_mappings: [
      'property.commercial_yearly_rental_income',
      'commercial_yearly_rental_income',
      'yearly_commercial_income',
    ],
    data_type: 'number',
  },
  {
    key: 'property_commercial_potential_rental_income',
    name: 'property_commercial_potential_rental_income',
    required: false,
    suggested_mappings: [
      'property.commercial_potential_rental_income',
      'commercial_potential_rental_income',
      'potential_commercial_income',
    ],
    data_type: 'number',
  },
  {
    key: 'property_renovation_fund',
    name: 'property_renovation_fund',
    required: false,
    suggested_mappings: [
      'property.renovation_fund',
      'renovation_fund',
      'fund_for_renovation',
    ],
    data_type: 'number',
  },
  {
    key: 'property_heating',
    name: 'property_heating',
    required: false,
    suggested_mappings: ['property.heating', 'heating', 'heating_system'],
  },
  {
    key: 'property_cooling',
    name: 'property_cooling',
    required: false,
    suggested_mappings: ['property.cooling', 'cooling', 'cooling_system'],
  },
  {
    key: 'property_heating_distribution',
    name: 'property_heating_distribution',
    required: false,
    suggested_mappings: [
      'property.heating_distribution',
      'heating_distribution',
      'distribution_of_heating',
    ],
  },
  {
    key: 'property_energy_certificate_version',
    name: 'property_energy_certificate_version',
    required: false,
    suggested_mappings: [
      'property.energy_certificate_version',
      'energy_certificate_version',
      'energy_cert_version',
    ],
  },
  {
    key: 'property_energy_consumption_class',
    name: 'property_energy_consumption_class',
    required: false,
    suggested_mappings: [
      'property.energy_consumption_class',
      'energy_consumption_class',
      'energy_class',
    ],
  },
  {
    key: 'property_energy_reference_year',
    name: 'property_energy_reference_year',
    required: false,
    suggested_mappings: [
      'property.energy_reference_year',
      'energy_reference_year',
      'reference_year_for_energy',
    ],
    data_type: 'number',
  },
  {
    key: 'property_energy_inspection_date',
    name: 'property_energy_inspection_date',
    required: false,
    suggested_mappings: [
      'property.energy_inspection_date',
      'energy_inspection_date',
      'inspection_date',
    ],
  },
  {
    key: 'property_emissions',
    name: 'property_emissions',
    required: false,
    suggested_mappings: ['property.emissions', 'emissions', 'CO2_emissions'],
    data_type: 'number',
  },
  {
    key: 'property_gas_emissions_class',
    name: 'property_gas_emissions_class',
    required: false,
    suggested_mappings: [
      'property.gas_emissions_class',
      'gas_emissions_class',
      'emissions_class',
    ],
  },
  {
    key: 'property_estimated_higher_energy_cost',
    name: 'property_estimated_higher_energy_cost',
    required: false,
    suggested_mappings: [
      'property.estimated_higher_energy_cost',
      'estimated_higher_energy_cost',
      'higher_energy_cost',
    ],
    data_type: 'number',
  },
  {
    key: 'property_estimated_lower_energy_cost',
    name: 'property_estimated_lower_energy_cost',
    required: false,
    suggested_mappings: [
      'property.estimated_lower_energy_cost',
      'estimated_lower_energy_cost',
      'lower_energy_cost',
    ],
    data_type: 'number',
  },
  {
    key: 'property_property_images',
    name: 'property_property_images',
    required: false,
    suggested_mappings: ['property.property_images', 'property_images'],
    data_type: 'string[]',
  },
] as const;

export const ListingsImport = (props: ImportDialogProps) => {
  return (
    <CSVImportModal
      open={props.open}
      objectLabel="Listings"
      columns={columns}
      onImportSuccess={props.onImportSuccess}
      onImporterClose={props.onImporterClose}
      mutationQuery={INSERT_LOTS}
      constructMutationVariables={formattedData => ({
        objects: formattedData,
      })}
    />
  );
};

export default ListingsImport;
