import { memo, useCallback, useMemo, useState } from 'react';

import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { DeepPartial } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { type CreateLeadInput, useCreateLead } from '../../hooks/useCreateLead';
import { HorizontalScrollContainer } from '../../pages/cma-reports/shared';
import type { PropertyDetailsStepForm } from '../create-listing/PropertyDetailsStep';
import type { RaAddressType } from '../form/RaAddressInput';
import { MutationErrorSnackbar } from '../MutationErrorModal';

import { CreateLeadAddressStep } from './CreateLeadAddressStep';
import {
  type ContactFormData,
  CreateLeadContactStep,
} from './CreateLeadContactStep';
import { CreateLeadPropertyDetailsStep } from './CreateLeadPropertyDetailsStep';

type StepId = 'address' | 'contact' | 'propertyDetails';

type FormData = {
  address: RaAddressType;
  contact: ContactFormData;
  propertyDetails: PropertyDetailsStepForm;
};

type StepComponentProps<T> = {
  data: T;
  footerActionsComponent: React.ReactNode;
  onFormSubmitted: (data: Partial<T>) => Promise<void>;
};

type StepConfig<T> = {
  id: StepId;
  label: string;
  component: React.FC<StepComponentProps<T>>;
};

const initialFormData: DeepPartial<FormData> = {
  address: {},
  contact: {},
  propertyDetails: {},
};

type LeadStepperNavProps = {
  currentStep: number;
  steps: Array<StepConfig<any>>;
};

const LeadStepperNav = memo<LeadStepperNavProps>(({ currentStep, steps }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Paper square variant="outlined">
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        component={HorizontalScrollContainer}
        height={isMobile ? 90 : 100}
        nodeIndex={currentStep}
      >
        {steps.map(({ label }, index) => (
          <Step key={label} sx={{ minWidth: '90px', mt: 1 }}>
            <StepLabel
              sx={{
                '& .MuiStepLabel-label': {
                  mt: { xs: 1, sm: 1.5 },
                  fontWeight: currentStep === index ? 'bold' : 'normal',
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
});

LeadStepperNav.displayName = 'LeadStepperNav';

type CreateLeadStepperProps = {
  onLeadCreated: (leadId: string) => void;
  userId?: string;
};

export const CreateLeadStepper = ({
  onLeadCreated,
  userId = '',
}: CreateLeadStepperProps) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const stageId = searchParams.get('stage_id');

  const [leadApolloError, leadLoading, createNewLead, resetError] =
    useCreateLead();

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<DeepPartial<FormData>>({
    ...initialFormData,
    contact: {
      ...initialFormData.contact,
      contact_id: userId,
    },
  });

  const steps = useMemo<
    [
      StepConfig<RaAddressType>,
      StepConfig<ContactFormData>,
      StepConfig<PropertyDetailsStepForm>,
    ]
  >(
    () => [
      {
        id: 'address',
        label: t('Address'),
        component: CreateLeadAddressStep,
      },
      {
        id: 'contact',
        label: t('Contact'),
        component: CreateLeadContactStep,
      },
      {
        id: 'propertyDetails',
        label: t('Property details'),
        component: CreateLeadPropertyDetailsStep,
      },
    ],
    [t],
  );

  const handleCreateLead = useCallback(
    async (finalFormData: FormData) => {
      const leadInput: CreateLeadInput = {
        stage_id: stageId,
        contact_id: finalFormData.contact.contact_id,
        relationship: finalFormData.contact.relationship,
        sale_horizon: finalFormData.contact.sale_horizon,
        source: finalFormData.contact.source,
        property: {
          ...finalFormData.address,
          ...finalFormData.propertyDetails,
        },
      };

      const leadId = await createNewLead(leadInput);

      if (leadId == null) {
        return null;
      }

      onLeadCreated(leadId);
      setFormData(initialFormData);
      setActiveStep(0);
    },
    [createNewLead, onLeadCreated, stageId],
  );

  const onFormSubmitted = useCallback(
    async (data: Partial<FormData[StepId]>) => {
      try {
        if (activeStep === steps.length - 1) {
          const stepId = steps[activeStep].id;
          const finalFormData = {
            ...formData,
            [stepId]: { ...formData[stepId], ...data },
          };
          await handleCreateLead(finalFormData as FormData);
        } else {
          const stepId = steps[activeStep].id;
          setFormData(prev => ({
            ...prev,
            [stepId]: { ...prev[stepId], ...data },
          }));
          setActiveStep(prevStep => prevStep + 1);
        }
      } catch (error) {
        console.error('Form submission failed:', error);
      }
    },
    [activeStep, steps, formData, handleCreateLead],
  );

  const handleBack = useCallback(() => {
    setActiveStep(prevStep => Math.max(0, prevStep - 1));
  }, []);

  const CurrentStepComponent = steps[activeStep].component as React.FC<
    StepComponentProps<FormData[StepId]>
  >;
  const currentStepData = formData[steps[activeStep].id] ?? {};

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: 0 }}
      >
        <LeadStepperNav currentStep={activeStep} steps={steps} />

        <Box
          sx={{ flex: 1, minHeight: 0, overflow: 'hidden', display: 'flex' }}
        >
          <CurrentStepComponent
            data={currentStepData as FormData[StepId]}
            onFormSubmitted={onFormSubmitted}
            footerActionsComponent={
              <Paper square variant="outlined" sx={{ px: 2, py: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    {t('Back')}
                  </Button>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={leadLoading}
                    loadingPosition={
                      activeStep === steps.length - 1 ? 'start' : undefined
                    }
                    startIcon={
                      activeStep === steps.length - 1 ? <Save /> : undefined
                    }
                  >
                    {activeStep === steps.length - 1 ? t('Finish') : t('Next')}
                  </LoadingButton>
                </Box>
              </Paper>
            }
          />
        </Box>
      </Box>

      <MutationErrorSnackbar error={leadApolloError} onClose={resetError} />
    </>
  );
};

CreateLeadStepper.displayName = 'CreateLeadStepper';
