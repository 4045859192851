import {
  type MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import type { PageId } from '../shared';

import { useCMAEditor } from './CMAReportEditorWorkflow';

const TOP_MARGIN = 10; // pixels
const SIDE_MARGIN = 20; // pixels
const ACTION_BOX_WIDTH = 56; // pixels

export const scaleIframeBody = (
  iFrameElement: HTMLIFrameElement,
  markup: string | null,
  fullPageScale = true,
  isDesktop = true,
) => {
  const iFrameDocument = iFrameElement?.contentDocument;
  const iFrameBody = iFrameDocument?.body;

  if (!iFrameBody || !iFrameElement || markup == null) {
    return;
  }

  const updateScale = () => {
    const iFramePage = iFrameBody.querySelector('page');

    if (!iFramePage) {
      return;
    }

    const { width: containerWidth, height: containerHeight } =
      iFrameElement.getBoundingClientRect();

    // Subtract margins from container dimensions
    let availableWidth = containerWidth - 2 * SIDE_MARGIN;
    if (isDesktop) {
      availableWidth -= ACTION_BOX_WIDTH;
    }
    const availableHeight = containerHeight - 2 * TOP_MARGIN;

    // Get the natural dimensions of the page
    const naturalWidth = iFramePage.scrollWidth;
    const naturalHeight = iFramePage.scrollHeight;

    // Calculate scale based on fullPageScale
    let scale;
    if (fullPageScale) {
      const scaleX = availableWidth / naturalWidth;
      const scaleY = availableHeight / naturalHeight;
      scale = Math.min(scaleX, scaleY, 1); // Never scale up
    } else {
      scale = Math.min(availableWidth / naturalWidth, 1); // Scale down only
    }

    // Apply transformations
    iFrameBody.style.transform = `scale(${scale})`;
    iFrameBody.style.transformOrigin = 'top left';
    iFrameBody.style.margin = `${TOP_MARGIN}px ${SIDE_MARGIN}px`;
    iFrameBody.style.width = `${naturalWidth}px`;
    iFrameBody.style.height = `${naturalHeight}px`;

    // Calculate the scaled dimensions
    const scaledWidth = naturalWidth * scale;

    // Center the content if there's extra space
    const horizontalExtraSpace = Math.max(0, availableWidth - scaledWidth);

    iFrameBody.style.marginLeft = `${SIDE_MARGIN + horizontalExtraSpace / 2}px`;
  };

  updateScale();

  const resizeObserver = new ResizeObserver(updateScale);
  resizeObserver.observe(iFrameElement);

  return () => {
    resizeObserver.disconnect();
  };
};

const useMarkupRenderer = (
  iFrameRef: MutableRefObject<HTMLIFrameElement>,
  markup: string | null,
) => {
  const { viewerPageId } = useCMAEditor();
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('md'));
  const scrollableElRef = useRef<HTMLElement | null>(null);
  const pagesRef = useRef<HTMLElement[]>([]);
  const isInViewportRef = useRef(false);

  const scrollToPage = useCallback((targetPageId: PageId) => {
    const scrollableEl = scrollableElRef.current;
    const pages = pagesRef.current;
    if (!scrollableEl || pages.length === 0) {
      return;
    }

    const targetPage = pages.find(page => page.id === targetPageId);
    if (!targetPage) {
      return;
    }

    targetPage.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  // Set up the iframe content
  useLayoutEffect(() => {
    if (markup == null) {
      return;
    }

    const iFrameDocument = iFrameRef.current.contentDocument;
    if (iFrameDocument == null) {
      return;
    }

    // First write the base markup
    iFrameDocument.documentElement.innerHTML = markup;

    // Then inject the Tailwind script properly
    const script = iFrameDocument.createElement('script');
    script.src = 'https://cdn.tailwindcss.com/3.4.1';
    iFrameDocument.head.appendChild(script);

    const bodyEl = iFrameDocument.body;
    if (bodyEl == null) {
      return;
    }

    bodyEl.style.height = '100%';

    scrollableElRef.current = bodyEl;
    pagesRef.current = Array.from(bodyEl.querySelectorAll('page'));
  }, [markup, iFrameRef]);

  // Scroll to the page when the markup is loaded and pageId changes
  // Not on mobile because it will go to the `preview` tab everytime the pageId changes
  // We use the next useLayoutEffect for mobile
  useLayoutEffect(() => {
    if (markup != null && isDesktop) {
      let pageId = viewerPageId;

      // If update this, also update the CMAReportEditorStepperComponent
      // Special case where 2 steps refer to the same page
      if (
        viewerPageId === 'page-cap-rate-valuation' ||
        viewerPageId === 'page-intrinsic-valuation'
      ) {
        // This is the single page to reference in the iFrame
        pageId = 'page-other-valuations' as PageId;
      }
      requestAnimationFrame(() => scrollToPage(pageId));
    }
  }, [markup, viewerPageId, scrollToPage, isDesktop]);

  // Scroll to the page when the iframe is in the viewport (mobile view)
  useLayoutEffect(() => {
    if (isDesktop) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        isInViewportRef.current = entry.isIntersecting;
        if (entry.isIntersecting && markup != null) {
          requestAnimationFrame(() => scrollToPage(viewerPageId));
        }
      },
      { threshold: 1 /* Trigger when 100% of the iframe is visible */ },
    );

    if (iFrameRef.current) {
      observer.observe(iFrameRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [iFrameRef, markup, viewerPageId, scrollToPage, isDesktop]);

  // Set up the scaling
  useLayoutEffect(() => {
    const iFrameElement = iFrameRef.current;
    if (iFrameElement && markup != null) {
      return scaleIframeBody(iFrameElement, markup, true, isDesktop);
    }
  }, [iFrameRef, markup, isDesktop]);

  return { scrollToPage };
};

export default useMarkupRenderer;
