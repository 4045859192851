import * as React from 'react';

import { Dialog, IconButton } from '@mui/material';
import { Carousel } from '@realadvisor/carousel';
import { useConstant } from '@realadvisor/hooks';

import { Close } from '../../icons/close';
import { useTheme } from '../../src/hooks/theme';

import type { CardImage } from './select-image-card';

// allows to use callback in effects without triggering update on every render
const useStableCallback = <T extends (...args: any[]) => any>(
  callback: T,
): T => {
  const callbackRef = React.useRef(callback);
  const stable: any = React.useCallback(
    (...args: any) => callbackRef.current?.(...args),
    [],
  );
  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  return stable;
};

const CarouselPositionTrack = React.memo<{
  index: number;
  onChange: (index: number) => void;
}>(({ index, onChange }) => {
  React.useLayoutEffect(() => {
    onChange(index);
  }, [index, onChange]);
  return null;
});

const WrappedCarousel = ({
  images,
  viewIndex,
  onViewIndexChange,
}: {
  images: CardImage[];
  viewIndex: number;
  onViewIndexChange: (viewIndex: number) => void;
}) => {
  const initialViewIndex = useConstant(() => viewIndex);
  const stableOnViewIndexChange = useStableCallback(onViewIndexChange);
  const CarouselPosition = React.useCallback(
    ({ value }: { value: number }) => {
      return (
        <CarouselPositionTrack
          index={value - 1}
          onChange={stableOnViewIndexChange}
        />
      );
    },
    [stableOnViewIndexChange],
  );
  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        backgroundColor: '#000',
      }}
    >
      <Carousel
        media={{
          gap: 0,
          itemsOnScreen: 1,
          aspectRatio: window.innerHeight / window.innerWidth,
        }}
        autoFocus={true}
        initialOffset={initialViewIndex}
        Position={CarouselPosition}
      >
        {images.map(item => {
          return (
            <img
              key={item.id}
              css={{ width: '100%', height: '100%', objectFit: 'contain' }}
              src={item.image.url}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export const FullScreenImage = ({
  images,
  open = false,
  onCloseFullScreen,
  viewIndex,
  onViewIndexChange,
}: {
  viewIndex: number;
  onViewIndexChange: (viewIndex: number) => void;
  onCloseFullScreen: () => void;
  open?: boolean;
  images: CardImage[];
}) => {
  const { text, colors } = useTheme();
  return (
    <Dialog fullScreen={true} open={open} onClose={onCloseFullScreen}>
      <IconButton
        css={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 2,
          svg: {
            color: 'white',
          },
        }}
        color="inherit"
        onClick={onCloseFullScreen}
      >
        <Close />
      </IconButton>

      <WrappedCarousel
        images={images}
        viewIndex={viewIndex}
        onViewIndexChange={onViewIndexChange}
      />

      {images[viewIndex].title != null && (
        <div
          css={[
            text.subtitle2,
            {
              position: 'absolute',
              bottom: 0,
              left: 0,
              padding: '8px 16px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: colors.white,
            },
          ]}
        >
          {images[viewIndex].title}
        </div>
      )}
      <div
        css={[
          text.subtitle2,
          {
            position: 'absolute',
            bottom: 0,
            right: 0,
            padding: '8px 16px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: colors.white,
          },
        ]}
      >
        {viewIndex + 1}/{images.length}
      </div>
    </Dialog>
  );
};
