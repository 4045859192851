import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
} from '@mui/material';
import { Slider } from '@realadvisor/slider';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { number_of_string, string_of_number } from '../utils/number-format';

type Props = {
  min: number;
  max: number;
  step: number;
  valueGte: string;
  valueLte: string;
  error?: string | null;
  disabled?: boolean;
  onChange: (value: { valueGte: string; valueLte: string }) => void;
  renderInput: (props: {
    onChange: (value: string) => void;
    value: string;
    onBlur: () => void;
  }) => JSX.Element;
};

export const RangeInput = ({
  min,
  max,
  step,
  valueGte,
  valueLte,
  error,
  disabled,
  onChange,
  renderInput,
}: Props) => {
  const { colors } = useTheme();
  const { t } = useLocale();
  const color = colors.primaryMain;
  const disabledColor = colors.grey500;

  const swapValues = () => {
    let gte = number_of_string(valueGte) ?? min;
    let lte = number_of_string(valueLte) ?? max;

    if (gte < min) {
      gte = min;
    } else if (gte > max) {
      gte = max;
    } else if (lte > max) {
      lte = max;
    } else if (lte < min) {
      lte = min;
    }

    if (gte > lte) {
      onChange({
        valueGte: string_of_number(lte),
        valueLte: string_of_number(gte),
      });
    } else {
      onChange({
        valueGte: string_of_number(gte),
        valueLte: string_of_number(lte),
      });
    }
  };

  return (
    <>
      <Box py={1}>
        <Slider
          bg={disabled ? disabledColor : color}
          valueConstraints={{ min, max, step }}
          value={{
            from: number_of_string(valueGte) ?? min,
            to: number_of_string(valueLte) ?? max,
          }}
          onChange={value =>
            onChange({
              valueGte: disabled
                ? valueGte
                : value.from === min
                ? string_of_number(min)
                : string_of_number(value.from),
              valueLte: disabled
                ? valueLte
                : value.to === max
                ? string_of_number(max)
                : string_of_number(value.to),
            })
          }
        />
      </Box>
      <Box
        component={Grid}
        // @ts-ignore poor markup, better not use Grid
        container={true}
        spacing={1}
        alignItems="center"
        mt={1}
      >
        <Grid item={true} sm={5} xs={12}>
          <FormControl
            variant="filled"
            error={error != null}
            disabled={disabled}
          >
            <InputLabel>{t('From')}</InputLabel>
            {renderInput({
              value: valueGte,
              onChange: from => onChange({ valueGte: from, valueLte }),
              onBlur: swapValues,
            })}
          </FormControl>
        </Grid>
        <Box
          component={Grid}
          // @ts-ignore poor markup, better not use Grid
          item={true}
          sm={2}
          sx={{
            display: { xs: 'none', sm: 'block' },
            textAlign: 'center',
          }}
        >
          <p>–</p>
        </Box>
        <Grid item={true} sm={5} xs={12}>
          <FormControl
            variant="filled"
            error={error != null}
            disabled={disabled}
          >
            <InputLabel>{t('to')}</InputLabel>
            {renderInput({
              value: valueLte,
              onChange: to => onChange({ valueLte: to, valueGte }),
              onBlur: swapValues,
            })}
          </FormControl>
        </Grid>
      </Box>
      {error != null && (
        <FormHelperText error={error != null} variant="standard">
          {error}
        </FormHelperText>
      )}
    </>
  );
};
