import React, { useCallback, useState } from 'react';

import { useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PhotoIcon from '@mui/icons-material/Photo';
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  LinearProgress,
  Stack,
  useTheme,
} from '@mui/material';
import { useFileDialog } from '@realadvisor/hooks';
import { Image } from '@realadvisor/image';
import { type Control, Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { customPalette } from '../../../src/styles';
import { gql } from '../../__generated__';
import { useFileUpload } from '../../utils/files/file-upload';
import { UploadImageCard } from '../UploadCard';

import { RaLabel } from './RaLabel';

const GET_IMAGE_QUERY = gql(/* GraphQL */ `
  query GetImage($id: uuid!) {
    images_by_pk(id: $id) {
      id
      url
    }
  }
`);

type RaImageUploadProps = {
  control: Control<any>;
  required?: boolean;
  name: string;
  label?: string;
  disabled?: boolean;
  onFileUpload: (file: ImageUploadResult) => Promise<string | null>;
};

export type ImageUploadResult = { url: string; id: string; file: File };

const ImageCard: React.FC<{
  imageUrl: string | null | undefined;
  isLoading?: boolean;
  error?: Error;
  onDelete?: () => void;
  onEdit?: () => void;
  disabled?: boolean;
}> = ({
  imageUrl,
  isLoading = false,
  error,
  onDelete,
  onEdit,
  disabled = false,
}) => {
  const { shape, palette } = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingTop: '100%',
        '&:hover .RaImageUpload-MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.87)',
        },
        ...(error != null
          ? {
              '.RaImageUpload-MuiOutlinedInput-notchedOutline, &:focus-within .RaImageUpload-MuiOutlinedInput-notchedOutline':
                {
                  borderColor: palette.error.main,
                },
            }
          : {}),
      }}
    >
      <Box
        component="fieldset"
        className="RaImageUpload-MuiOutlinedInput-notchedOutline"
        sx={{
          position: 'absolute',
          inset: 0,
          zIndex: 2,
          padding: 1.5,
          borderRadius: `${shape.borderRadius}px`,
          border: '1px solid #9CA3AF',
          overflow: 'hidden',
          margin: 0,
        }}
      >
        {!disabled && (
          <Stack
            direction="column"
            spacing={0.5}
            sx={{ position: 'absolute', zIndex: 3, top: 16, right: 16 }}
          >
            {onEdit && (
              <IconButton
                onClick={onEdit}
                sx={{
                  bgcolor: 'rgba(0, 0, 0, 0.1)',
                  '&.MuiIconButton-root:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <EditIcon sx={{ color: 'white.main' }} />
              </IconButton>
            )}
            {onDelete && (
              <IconButton
                onClick={onDelete}
                sx={{
                  bgcolor: 'rgba(0, 0, 0, 0.1)',
                  '&.MuiIconButton-root:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <DeleteIcon sx={{ color: 'white.main' }} />
              </IconButton>
            )}
          </Stack>
        )}
        {error && (
          <Alert
            severity="error"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              padding: 8,
              zIndex: 2,
              background: 'rgba(255, 255, 255, 0.6)',
              backdropFilter: 'blur(10px)',
              borderRadius: 0,
            }}
          >
            <AlertTitle>{error.message}</AlertTitle>
            <pre style={{ fontSize: '0.9em' }}>
              {JSON.stringify(error, null, 2)}
            </pre>
          </Alert>
        )}
        {imageUrl && (
          <Image
            src={imageUrl}
            options={{ h: 250, f: 'jpg' }}
            objectFit="cover"
          />
        )}
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'end',
              flexDirection: 'column',
              inset: 0,
              zIndex: 3,
              bgcolor: 'rgba(255, 255, 255, 0.3)',
            }}
          >
            <LinearProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const UploadField: React.FC<{
  value: string | null;
  disabled?: boolean;
  onChange: (value: string | null) => void;
  onImageUploaded: (value: ImageUploadResult) => Promise<string | null>;
}> = ({ value, disabled = false, onChange, onImageUploaded }) => {
  const { data: getImageData } = useQuery(GET_IMAGE_QUERY, {
    variables: { id: value ?? '' },
    skip: value == null,
  });
  const [uploadFile] = useFileUpload('image');
  const [isUploading, setIsUploading] = useState(false);
  const [newImageUrl, setNewImageUrl] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<Error | null>(null);

  const onFileUpload = useCallback(
    async (files: File[]) => {
      if (files.length === 0) {
        return;
      }

      const file = files[0];

      try {
        setUploadError(null);
        setIsUploading(true);
        setNewImageUrl(URL.createObjectURL(file));
        const uploadResult = await new Promise<ImageUploadResult>(
          (resolve, reject) => {
            uploadFile(file, ({ data, error }) => {
              if (data) {
                return resolve({ ...data, file });
              }

              reject(error);
            });
          },
        );

        const imageId = await onImageUploaded(uploadResult);

        if (imageId == null) {
          throw new Error('Image upload failed');
        }

        setNewImageUrl(null);
        onChange(imageId);
      } catch (error: any) {
        setUploadError(error);
      } finally {
        setIsUploading(false);
      }
    },
    [uploadFile, onChange, onImageUploaded, setUploadError, setIsUploading],
  );

  const openFileDialog = useFileDialog({
    accept: 'image/*',
    onChange: onFileUpload,
  });

  if (getImageData == null) {
    return disabled ? (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          paddingTop: '100%',
          backgroundColor: customPalette.gray200,
        }}
      >
        <PhotoIcon
          sx={{
            fontSize: 64,
            color: customPalette.gray400,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    ) : (
      <UploadImageCard
        onClick={openFileDialog}
        canDrop={false}
        isLoading={isUploading}
        error={uploadError ?? undefined}
      />
    );
  }

  return (
    <ImageCard
      imageUrl={newImageUrl ?? getImageData.images_by_pk?.url}
      onEdit={openFileDialog}
      onDelete={() => onChange(null)}
      isLoading={isUploading}
      error={uploadError ?? undefined}
      disabled={disabled}
    />
  );
};

export const RaImageUpload: React.FC<RaImageUploadProps> = ({
  name,
  label,
  required = false,
  disabled = false,
  control,
  onFileUpload,
}) => {
  const { t } = useLocale();
  const { shape } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? t('This field is required') : undefined }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth error={error != null} sx={{ maxWidth: 200 }}>
          {label != null && <RaLabel label={label} required={required} />}
          <Box
            sx={{
              width: '100%',
              borderRadius: `${shape.borderRadius}px`,
              overflow: 'hidden',
            }}
          >
            <UploadField
              disabled={disabled}
              value={value}
              onChange={onChange}
              onImageUploaded={onFileUpload}
            />
          </Box>
          {error != null && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
