// @flow

import * as React from 'react';

import {
  DateRangeCalendar,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameYear,
  max,
  min,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { Box, Flex } from 'react-system';

import { Filter } from '../controls/Filters';
import { Radio } from '../controls/radio';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';

const formatRange = ({ start, end }, { locale }) => {
  const startDay = format(start, 'd', { locale });
  const startMonth = format(start, 'LLLL', { locale });
  const startYear = format(start, 'yyyy', { locale });
  const endDate = format(end, `d LLLL yyyy`, { locale });
  if (isSameDay(start, end)) {
    return endDate;
  }
  if (isSameMonth(start, end)) {
    return [startDay, '-', endDate].join('');
  }
  if (isSameYear(start, end)) {
    return [startDay, ' ', startMonth, ' - ', endDate].join('');
  }
  return [startDay, ' ', startMonth, ' ', startYear, ' - ', endDate].join('');
};

const PresetRadio = ({ value, start, end, onChange, children }) => {
  const { text, colors } = useTheme();
  const { dateLocale } = useLocale();
  return (
    <Radio
      color="primary"
      checked={
        value != null && isEqual(value.start, start) && isEqual(value.end, end)
      }
      onChange={() => onChange({ start, end })}
    >
      <div>
        <div css={text.body2}>{children}</div>
        <div css={[text.caption, { color: colors.mediumText }]}>
          {formatRange({ start, end }, { locale: dateLocale })}
        </div>
      </div>
    </Radio>
  );
};

const Presets = ({ value, onChange }) => {
  const { t, dateLocale } = useLocale();
  const now = Date.now();
  return (
    <>
      <PresetRadio
        start={startOfDay(now)}
        end={endOfDay(now)}
        value={value}
        onChange={onChange}
      >
        {t('Today')}
      </PresetRadio>
      <PresetRadio
        start={startOfDay(subDays(now, 1))}
        end={endOfDay(subDays(now, 1))}
        value={value}
        onChange={onChange}
      >
        {t('Yesterday')}
      </PresetRadio>
      <PresetRadio
        start={startOfDay(subDays(now, 6))}
        end={endOfDay(now)}
        value={value}
        onChange={onChange}
      >
        {t('last7Days')}
      </PresetRadio>
      <PresetRadio
        start={startOfWeek(now, { locale: dateLocale })}
        end={endOfWeek(now, { locale: dateLocale })}
        value={value}
        onChange={onChange}
      >
        {t('thisWeek')}
      </PresetRadio>
      <PresetRadio
        start={startOfWeek(subWeeks(now, 1), { locale: dateLocale })}
        end={endOfWeek(subWeeks(now, 1), { locale: dateLocale })}
        value={value}
        onChange={onChange}
      >
        {t('Last week')}
      </PresetRadio>
      <PresetRadio
        start={startOfMonth(now)}
        end={endOfMonth(now)}
        value={value}
        onChange={onChange}
      >
        {t('This month')}
      </PresetRadio>
      <PresetRadio
        start={startOfMonth(subMonths(now, 1))}
        end={endOfMonth(subMonths(now, 1))}
        value={value}
        onChange={onChange}
      >
        {t('Last month')}
      </PresetRadio>
    </>
  );
};

type DateRange = {|
  start: Date,
  end: Date | null,
|};

type RangePickerProps = {|
  value: ?{|
    start: Date,
    end: Date,
  |},
  onChange: DateRange => void,
|};

const RangePicker = ({ value, onChange }: RangePickerProps) => {
  // start and end of day helps with comparisons
  const start = value ? startOfDay(min([value.start, value.end])) : null;
  const end = value ? endOfDay(max([value.start, value.end])) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangeCalendar
        calendars={1}
        value={[start, end]}
        onChange={val => {
          const [start, end] = val;
          // same day if end is not set
          const newVal = {
            start: start ? startOfDay(start) : startOfDay(new Date()),
            end: end ? endOfDay(end) : null,
          };
          onChange(newVal);
        }}
      />
    </LocalizationProvider>
  );
};

type Props = {|
  onReset: () => void,
  setDateRange: ({|
    start: Date,
    end: null | Date,
  |}) => void,
  dateRange: ?{|
    start: Date,
    end: Date,
  |},
  label: string,
  dialogTitle: string,
  children?: React.Node,
  position?: 'top' | 'bottom',
|};

export const BaseDateFilter = ({
  onReset,
  dateRange,
  setDateRange,
  label,
  dialogTitle,
  children,
  position = 'bottom',
}: Props): React.Node => {
  const { dateLocale } = useLocale();
  return (
    <Filter
      label={dateRange ? formatRange(dateRange, { locale: dateLocale }) : label}
      dialogTitle={dialogTitle}
      empty={dateRange == null}
      onReset={onReset}
    >
      {position === 'top' && children}
      <Flex width={['280px', '540px']} flexWrap="wrap">
        <Box width={[1, 2 / 5]} order={[1, 0]} px={3} py={2}>
          <Presets value={dateRange} onChange={setDateRange} />
        </Box>
        <Box width={[1, 3 / 5]} order={[0, 1]} css={{ overflow: 'hidden' }}>
          <RangePicker value={dateRange} onChange={setDateRange} />
        </Box>
      </Flex>
      {position === 'bottom' && children}
    </Filter>
  );
};
