import { Box, Link, Stack, Typography } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import {
  type CMAReportComponentProps,
  FooterActionsComponentWithoutForm,
} from '../shared';

const StepAgentBase = (_props: CMAReportComponentProps) => {
  const { t } = useLocale();

  return (
    <Stack height="100%" justifyContent="space-between">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 8,
          flexGrow: 1,
        }}
      >
        <Box sx={{ textAlign: 'center', maxWidth: '90%' }}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            {t("We'd Love Your Feedback!")}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            {t('Do you have suggestions to improve this page?')}
            <br />
            {t("We'd love to hear them!")}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t('Send us your feedback at')}{' '}
            <Link
              href="mailto:feedback@realadvisor.com"
              underline="hover"
              sx={{ color: 'primary.main' }}
            >
              feedback@realadvisor.com
            </Link>
          </Typography>
        </Box>
      </Box>
      <FooterActionsComponentWithoutForm updating={false} {..._props} />
    </Stack>
  );
};

export default StepAgentBase;
