import * as React from 'react';

import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  Switch,
} from '@mui/material';
import {
  Form,
  type FormikErrors,
  type FormikHook,
  useFormik,
} from '@realadvisor/form';
import { useFileDialog } from '@realadvisor/hooks';
import { useClickOutsideObserver } from '@realadvisor/observe';
import { format, parseISO } from 'date-fns';
import { dequal } from 'dequal';
import type { EmailName } from 'nylas';
import prettyBytes from 'pretty-bytes';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { Box, Flex, useResponsive, useSystem } from 'react-system';

import { EmailCounterAlert } from '../../../apollo/components/EmailCounterAlert';
import { isZodError } from '../../../apollo/utils/parseError';
import { fetchApiQuery } from '../../../networking';
import { fromGlobalId } from '../../../shared/global-id';
import { email_service_origin } from '../../config';
import { FileIcon } from '../../controls/file-icon';
import { ProgressButton } from '../../controls/progress-button';
import { type Translate, useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { AttachFile } from '../../icons/attach-file';
import { ExpandLess } from '../../icons/expand-less';
import { ExpandMore } from '../../icons/expand-more';
import { FileDocumentEdit } from '../../icons/file-document-edit';
import { MoreVert } from '../../icons/more-vert';
import { Notes } from '../../icons/notes';
import { type Language, languagesList } from '../../locale';
import { useFileUpload } from '../../shared/file-upload';
import { shortenFilename } from '../../utils/string';
import { createDocumentUrl } from '../../utils/url';
import { ReconnectEmailDialog } from '../ReconnectEmailDialog';

import type {
  EmailForm_forwardMessage$data,
  EmailForm_forwardMessage$key,
} from './__generated__/EmailForm_forwardMessage.graphql';
import type {
  EmailForm_initialTemplate$data,
  EmailForm_initialTemplate$key,
} from './__generated__/EmailForm_initialTemplate.graphql';
import type {
  EmailForm_me$data,
  EmailForm_me$key,
} from './__generated__/EmailForm_me.graphql';
import type {
  EmailForm_replyToMessage$data,
  EmailForm_replyToMessage$key,
} from './__generated__/EmailForm_replyToMessage.graphql';
import type {
  EmailFormRecipientQuery,
  EmailFormRecipientQuery$data,
} from './__generated__/EmailFormRecipientQuery.graphql';
import { AddressField } from './AddressField';
import { EmailSubjectField } from './EmailSubjectField';
import {
  type LotSnippetData,
  RichEditor,
  appendCustomHTML,
  createEmptyValue,
  createValueFromString,
  pushASTValue,
  pushEmptyValue,
  pushRawStringValue,
  updateSuggestions,
} from './RichEditor';
import type { RichEditorValue } from './RichEditorValue';
import { TemplatesDialog } from './TemplatesDialog';

type InitialRecipient = {
  userId: string;
  buyerLeadId?: string;
};

type PropertyDataAppraisal = {
  realadvisor: {
    min: number | null;
    max: number | null;
    value: number | null;
  };
};

export type PropertyData = {
  municipality?: string | null;
  postcode?: string | null;
  route?: string | null;
  streetNumber?: string | null;
  locality?: string | null;
  state?: string | null;
  type?: string | null;
  leadId: string;
  lotId: string;
  leadTemplateId: string;
  lotTemplateId: string;
  cmaId: string;
  cmaTemplateId: string;
  mainType?: string;
  numberOfRooms?: number | null;
  livingSurface?: number | null;
  landSurface?: number | null;
  latestAppraisal?: PropertyDataAppraisal;
};

export type AgentData = {
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  primaryEmail: string | null;
  primaryPhoneNumber: string | null;
  organisationName: string | null;
  organisationAddress: string | null;
  title: string | null;
};

export type LotData = {
  rentNet: number | null;
  rentExtra: number | null;
  salePrice: number | null;
} & LotSnippetData;

export type EmailFormProps = {
  isDialog: boolean;
  dialogTitle?: string;
  parentName?: 'BuyerLead' | 'Enquiry' | 'Lead' | 'Lot' | 'User';
  parentId: null | string;
  initialRecipients?: null | InitialRecipient[];
  me: EmailForm_me$key;
  refetch: () => void;
  // Generate and send individual emails for every recipient, not using bcc and cc
  campaignMode?: boolean;
  onCancel?: () => void;
  onEmailSent?: (data: any) => void;
  setContactsCount?: (count: number) => void;
  setAlertOpen?: (open: boolean) => void;
  lot?: LotData;
  property?: PropertyData;
  agent?: AgentData;
  initialTemplate?: null | EmailForm_initialTemplate$key;
  replyToMessage?: null | EmailForm_replyToMessage$key;
  forwardMessage?: null | EmailForm_forwardMessage$key;
  showUnfinishedWarning?: boolean;
  setShowUnfinishedWarning?: (value: boolean) => void;
};

export const toRecipients = (
  users: ContactType[],
  initialRecipients?: null | InitialRecipient[],
) => {
  return users
    .map(u => {
      if (u.primaryEmail != null) {
        const user: EmailName & { buyerLeadId?: string } = {
          email: u.primaryEmail.email,
          name: [u.firstName, u.lastName].filter(d => d != null).join(' '),
        };

        if (initialRecipients != null) {
          const recipient = initialRecipients.filter(r => r.userId === u.id)[0];
          if (recipient != null) {
            user['buyerLeadId'] = recipient.buyerLeadId
              ? fromGlobalId(recipient.buyerLeadId)
              : undefined;
          }
        }

        return user;
      } else {
        console.warn('User has no primary email', u);
      }
      return null;
    })
    .filter(Boolean);
};

const renderAttachments = ({
  files,
  removeFile,
  isLoading,
}: {
  files: EmailFile[];
  removeFile: (file: EmailFile) => void;
  isLoading: boolean;
}) => {
  if (!files.length) {
    return null;
  }

  return (
    <Flex
      p={2}
      alignItems={'center'}
      css={{ gap: 5, flexShrink: 0, flexWrap: 'wrap' }}
    >
      {files.map(file => (
        <Chip
          key={`${file.name}-${file.id}`}
          label={`${shortenFilename(file.name)} (${prettyBytes(
            file.size ?? 0,
          )})`}
          icon={<FileIcon mimetype={file.mimetype} />}
          onDelete={() => removeFile(file)}
        />
      ))}

      {isLoading === true && <CircularProgress size={24} disableShrink />}
    </Flex>
  );
};

graphql`
  fragment EmailForm_contact on User {
    id
    firstName
    lastName
    gender
    primaryEmail {
      id
      email
    }
    primaryPhoneNumber {
      id
      formattedNumber
    }
    organisation {
      id
      name
      formattedAddress
    }
    title
    language
  }
`;

const Subtitle = ({ children }: { children: React.ReactNode }) => {
  const { text } = useTheme();
  return <div css={[text.subtitle2]}>{children}</div>;
};

const generateLanguageUrls = (
  templateId: string,
  documentId: string,
  urlPrefix: string,
  defaultLanguage: Language,
) =>
  languagesList.reduce(
    (acc, lang) => ({
      ...acc,
      [`${urlPrefix}_${lang}`]: createDocumentUrl(
        templateId,
        documentId,
        lang,
      ).toString(),
    }),
    {
      [urlPrefix]: createDocumentUrl(
        templateId,
        documentId,
        defaultLanguage,
      ).toString(),
    },
  );

const getTemplateSuggestions = (
  defaultLanguageTemplate: Language,
  me: EmailForm_me$data,
  values: any,
  campaignMode: boolean,
  t: Translate,
  extraSuggestionsData?: {
    agent: AgentData | null;
    property: PropertyData | null;
    lot: LotData | null;
  },
) => {
  let agent = null;
  let property = null;
  let lot = null;
  if (extraSuggestionsData) {
    agent = extraSuggestionsData.agent;
    property = extraSuggestionsData.property;
    lot = extraSuggestionsData.lot;
  }
  // TODO: additional data could be passed from props, like lot.title
  // TODO: move possible keys somewhere
  const keys = {
    sender_firstName: '#sender.firstName',
    sender_lastName: '#sender.lastName',
    recipient_firstName: '#recipient.firstName',
    recipient_lastName: '#recipient.lastName',
    sender_title: '#sender.title',
    recipient_title: '#recipient.title',
    sender_primaryEmail: '#sender.primaryEmail',
    recipient_primaryEmail: '#recipient.primaryEmail',
    recipient_primaryPhoneNumber: '#recipient.primaryPhoneNumber',
    sender_primaryPhoneNumber: '#sender.primaryPhoneNumber',
    sender_organisationName: '#sender.organisationName',
    sender_organisationAddress: '#sender.organisationAddress',
    recipient_organisationName: '#recipient.organisationName',
    recipient_organisationAddress: '#recipient.organisationAddress',
  };

  const users = [
    {
      type: 'sender',
      user: me,
    },
  ];

  // Disable recipient suggestions in campaignMode as they should be parsed on backend
  if (
    values.usersTo.length !== 0 &&
    values.usersTo[0] != null &&
    campaignMode !== true
  ) {
    users.push({
      type: 'recipient',
      user: values.usersTo[0],
    });
  }

  const getGenderLabel = (gender: string | null) => {
    switch (gender) {
      case 'female':
        return t('madame');
      case 'male':
        return t('sir');
      default:
        return null;
    }
  };

  const result: Record<string, string> = {};
  if (property != null) {
    if (property.leadId !== '' && property.leadTemplateId !== '') {
      Object.assign(
        result,
        generateLanguageUrls(
          property.leadTemplateId,
          property.leadId,
          '#property_latestAppraisal_reportURL',
          defaultLanguageTemplate,
        ),
      );
    }

    if (property.lotId !== '' && property.lotTemplateId !== '') {
      Object.assign(
        result,
        generateLanguageUrls(
          property.lotTemplateId,
          property.lotId,
          '#property_lot_brochureURL',
          defaultLanguageTemplate,
        ),
      );
    }

    if (property.cmaId !== '' && property.cmaTemplateId !== '') {
      Object.assign(
        result,
        generateLanguageUrls(
          property.cmaTemplateId,
          property.cmaId,
          '#property_latestCMA_cmaURL',
          defaultLanguageTemplate,
        ),
      );
    }

    result['#property_fullAddress'] = `${property.route ?? ''} ${
      property.streetNumber ?? ''
    }, ${property.postcode ?? ''} ${property.municipality ?? ''}, ${
      property.state ?? ''
    }`;
    result['#property_municipality'] = property.municipality ?? '';
    result['#property_route'] = property.route ?? '';
    result['#property_postcode'] = property.postcode ?? '';
    result['#property_streetNumber'] = property.streetNumber ?? '';
    result['#property_state'] = property.state ?? '';
    result['#property_type'] = property.type ?? '';
    result['#property_numberOfRooms'] = (
      property.numberOfRooms ?? ''
    ).toString();
    result['#property_livingSurface'] = (
      property.livingSurface ?? ''
    ).toString();
    result['#property_landSurface'] = (property.landSurface ?? '').toString();

    result['#property_latestAppraisal_realadvisor_min'] = (
      property.latestAppraisal?.realadvisor.min ?? ''
    ).toString();
    result['#property_latestAppraisal_realadvisor_max'] = (
      property.latestAppraisal?.realadvisor.max ?? ''
    ).toString();
    result['#property_latestAppraisal_realadvisor_value'] = (
      property.latestAppraisal?.realadvisor.value ?? ''
    ).toString();
  }

  if (agent != null) {
    result['#agent_firstName'] = agent.firstName ?? '';
    result['#agent_lastName'] = agent.lastName ?? '';
    result['#agent_title'] = getGenderLabel(agent.gender) ?? '';
    result['#agent_primaryEmail'] = agent.primaryEmail ?? '';
    result['#agent_primaryPhoneNumber'] = agent.primaryPhoneNumber ?? '';
    result['#agent_organisationName'] = agent.organisationName ?? '';
    result['#agent_organisationAddress'] = agent.organisationAddress ?? '';
    result['#agent_title'] = agent.title ?? '';
  }

  if (lot != null) {
    result['#property_lot_title'] = lot.title ?? '';
    result['#property_lot_salePrice'] = (lot.salePrice ?? '').toString();
    result['#property_lot_rentNetPrice'] = (lot.rentNet ?? '').toString();
    result['#property_lot_rentExpenses'] = (lot.rentExtra ?? '').toString();
    result['#property_lot_rentGrossPrice'] = (
      lot.rentNet != null && lot.rentExtra != null
        ? lot.rentNet + lot.rentExtra
        : ''
    ).toString();
  }
  // Prefill result
  Object.values(keys).forEach((k: any) => {
    result[k] = '';
  });

  users.forEach(contact => {
    const user = contact.user;

    if (user.firstName !== '' && user.firstName != null) {
      result[keys[(contact.type + '_firstName') as keyof typeof keys]] =
        user.firstName;
    }
    if (user.lastName !== '' && user.lastName != null) {
      result[keys[(contact.type + '_lastName') as keyof typeof keys]] =
        user.lastName;
    }

    const genderLabel = getGenderLabel(user.gender);
    if (genderLabel != null) {
      result[keys[(contact.type + '_title') as keyof typeof keys]] =
        genderLabel;
    }

    if (user.primaryEmail != null) {
      result[keys[(contact.type + '_primaryEmail') as keyof typeof keys]] =
        user.primaryEmail.email;
    }

    if (user.primaryPhoneNumber != null) {
      result[
        keys[(contact.type + '_primaryPhoneNumber') as keyof typeof keys]
      ] = user.primaryPhoneNumber.formattedNumber;
    }

    if (user.organisation != null && typeof user.organisation === 'object') {
      const organisation = user.organisation;
      result[keys[(contact.type + '_organisationName') as keyof typeof keys]] =
        organisation.name ?? '';
      result[
        keys[(contact.type + '_organisationAddress') as keyof typeof keys]
      ] = organisation.formattedAddress ?? '';
    }
  });

  if (campaignMode === true) {
    Object.assign(
      result,
      [
        '#recipient.firstName',
        '#recipient.lastName',
        '#recipient.title',
        '#recipient.primaryEmail',
        '#recipient.primaryPhoneNumber',
        '#recipient.organisationName',
        '#recipient.organisationAddress',
      ].reduce((acc, key) => {
        acc[key] = key;
        return acc;
      }, {} as Record<string, string>),
    );
  }
  return result;
};

const emptyBlockAst = [
  'block',
  ['unstyled', 'bki65', [['inline', [[], '']]], {}],
];

const getReplyEditorValue = ({
  editorValue,
  replyToMessage,
  t,
  dateLocale,
}: {
  editorValue: RichEditorValue;
  replyToMessage: EmailForm_replyToMessage$data;
  t: Translate;
  dateLocale: Locale;
}) => {
  const currentAST = editorValue.toAST();

  const quoteIntro = t('onDatePersonWrote', {
    date: format(parseISO(replyToMessage.date), 'd MMMM yyyy HH:mm', {
      locale: dateLocale,
    }),
    person: `${replyToMessage.from[0]?.name ?? ''} &lt;${
      replyToMessage.from[0]?.email ?? ''
    }&gt;`,
  });

  const introAST = createValueFromString(
    `<div></div><div></div><div>${quoteIntro}</div>
      `,
    'html',
  ).toAST();

  const quoteAST = appendCustomHTML(
    createEmptyValue(),
    `
   <blockquote>
    ${replyToMessage.body ?? ''}
   </blockquote>
  `,
  ).toAST();

  return pushASTValue(editorValue, [...currentAST, ...introAST, ...quoteAST]);
};

const getForwardEditorValue = ({
  editorValue,
  forwardMessage,
  dateLocale,
  t,
}: {
  editorValue: RichEditorValue;
  forwardMessage: EmailForm_forwardMessage$data;
  dateLocale: Locale;
  t: Translate;
}) => {
  const currentAST = editorValue.toAST();

  // TODO: add reply-to also?
  const from = forwardMessage.from[0];
  const cc = forwardMessage.cc;
  const bcc = forwardMessage.bcc;

  const toString = forwardMessage.to
    .map(to => `${to?.name ?? ''} &lt;${to?.email ?? ''}&gt`)
    .join(', ');
  const ccString =
    cc != null && cc.length > 0
      ? `${t('Cc')}: ${cc
          .map(cc => `${cc?.name ?? ''} &lt;${cc?.email ?? ''}&gt`)
          .join(', ')}`
      : null;
  const bccString =
    bcc != null && bcc.length > 0
      ? `${t('Bcc')}: ${bcc
          .map(bcc => `${bcc?.name ?? ''} &lt;${bcc?.email ?? ''}&gt`)
          .join(', ')}`
      : null;

  const lines = [
    `---------- ${t('forwardedMessage')} ---------`,
    `${t('From')}: ${from?.name ?? from?.email ?? ''} &lt;${
      from?.email ?? ''
    }&gt;`,
    `${t('date')}: ${format(
      parseISO(forwardMessage.date),
      'd MMMM yyyy HH:mm',
      {
        locale: dateLocale,
      },
    )}`,
    `${t('Subject')}: ${forwardMessage.subject ?? ''}`,
    `${t('emailTo')}: ${toString}`,
    ccString,
    bccString,
  ].filter(Boolean);

  const intro = lines.join('<br />');

  const introAST = createValueFromString(
    `<div></div><div></div><div>${intro}</div><div></div><div></div>`,
    'html',
  ).toAST();

  const quoteAST = appendCustomHTML(
    createEmptyValue(),
    `
   <div>
    ${forwardMessage?.body ?? ''}
   </div>
  `,
  ).toAST();

  return pushASTValue(editorValue, [...currentAST, ...introAST, ...quoteAST]);
};

const recipientToContact = (
  r: NonNullable<EmailForm_replyToMessage$data['to'][number]>,
) => ({
  ...r.user,
  // id is null which breaks key in user multi input
  // TODO fix this shit somehow
  id: r.user?.id,
  firstName: r.user?.firstName ?? r.name,
  lastName: r.user?.lastName ?? '',
  primaryEmail: {
    email: r.email,
  },
});

type ContactType =
  | ReturnType<typeof recipientToContact>
  | NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<EmailFormRecipientQuery$data['users']>['edges']
        >[number]
      >['node']
    >;

type EmailFile = {
  id?: string;
  internalId?: number;
  mimetype: string | null;
  name: string;
  size: number;
  url: string;
};
type State = {
  usersTo: ContactType[];
  usersCc: ContactType[];
  usersBcc: ContactType[];
  files: EmailFile[];
  editorValue: RichEditorValue;
  subjectEditorValue: RichEditorValue;
  signatureEnabled: boolean;
  signatureBlockIds: any;
};

const FormComponentFields = ({
  onSubmit,
  campaignMode,
  errors,
  values,
  setValues,
  me,
  suggestionsArr,
  lot,
  property,
  agent,
  uploadingFile,
  expandAddressFields,
  setExpandAddressFields,
}: {
  onSubmit: () => void;
  campaignMode: boolean;
  errors: FormikErrors<State>;
  values: State;
  setValues: FormikHook<State>['setValues'];
  me: EmailForm_me$data;
  suggestionsArr: {
    id: string;
    name: string;
  }[];
  lot: LotData | null;
  property: PropertyData | null;
  agent: AgentData | null;
  uploadingFile: boolean;
  expandAddressFields: boolean;
  setExpandAddressFields: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t, language } = useLocale();
  const defaultLanguageTemplate = me.language ?? language;
  const { media } = useSystem();

  return (
    <Form
      css={media({
        display: 'grid',
        height: ['auto', '100%'],
        gridColumnGap: [8, 12],
        gridRowGap: 12,
        alignItems: 'center',
        gridTemplateColumns: '24px 1fr',
        gridTemplateRows: [
          'none',
          expandAddressFields ? 'auto auto auto auto 1fr' : 'auto auto 1fr',
        ],
      })}
      onSubmit={onSubmit}
    >
      <Subtitle>{campaignMode === true ? t('Bcc') : t('emailTo')}</Subtitle>
      <AddressField
        readOnly={campaignMode}
        maxVisibleItems={campaignMode === true ? 2 : null}
        errorText={errors.usersTo}
        value={values.usersTo}
        onChange={val => {
          const prevId = values.usersTo.length ? values.usersTo[0].id : null;
          const nextId = val.length ? val[0].id : null;
          const isRecipientUpdated = nextId !== prevId;

          setValues({ usersTo: val as ContactType[] });

          // Check if first recipient is changed
          // and update email template expressions
          if (isRecipientUpdated) {
            const sugg = getTemplateSuggestions(
              defaultLanguageTemplate,
              me,
              // https://github.com/jaredpalmer/formik/issues/529
              { ...values, usersTo: val },
              campaignMode,
              t,
              {
                agent,
                property,
                lot,
              },
            );

            // Update suggestions
            const newEditorValue = updateSuggestions(values.editorValue, sugg);

            setValues({ editorValue: newEditorValue });

            const newSubjectEditorValue = updateSuggestions(
              values.subjectEditorValue,
              sugg,
            );

            setValues({ subjectEditorValue: newSubjectEditorValue });
          }
        }}
        actionComponent={
          campaignMode !== true ? (
            <IconButton
              size="small"
              onClick={() => {
                setExpandAddressFields(prev => !prev);
              }}
            >
              {expandAddressFields ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          ) : null
        }
      />

      {expandAddressFields && (
        <>
          <Subtitle>{t('Cc')}</Subtitle>
          <AddressField
            value={values.usersCc}
            onChange={usersCc =>
              setValues({ usersCc: usersCc as ContactType[] })
            }
          />

          <Subtitle>{t('Bcc')}</Subtitle>
          <AddressField
            value={values.usersBcc}
            onChange={usersBcc =>
              setValues({ usersBcc: usersBcc as ContactType[] })
            }
          />
        </>
      )}

      <Notes />
      <EmailSubjectField
        placeholder={t('Subject')}
        value={values.subjectEditorValue}
        onChange={v => {
          setValues({ subjectEditorValue: v });
        }}
        suggestions={suggestionsArr}
        errorText={errors.subjectEditorValue}
        variant="filled"
      />

      <Flex
        flexDirection="column"
        pt={2}
        css={media({
          gridColumn: '1/-1',
          height: '100%',
          '.MuiFormControl-root': {
            flexGrow: 1,
            overflow: ['visible', 'hidden'],
          },
        })}
      >
        <FormControl error={errors.editorValue != null}>
          <RichEditor
            value={values.editorValue}
            onChange={v => {
              setValues({ editorValue: v });
            }}
            lot={lot}
            suggestions={suggestionsArr}
            variant="filled"
            css={media({
              '.public-DraftEditor-content': {
                minHeight: '300px',
                height: ['auto', 'calc(100% - 47px)'], // Minus the toolbar
                maxHeight: ['calc(100vh - 400px)', '100%'],
                overflow: 'auto',
                padding: '15px 9px',
              },
              '.DraftEditor-root, .DraftEditor-editorContainer, .RichEditor-editorContainer':
                {
                  height: '100%',
                },
              '.MuiPaper-root': {
                overflow: 'auto',
                overflowX: 'hidden',
              },
            })}
          />
          {errors.editorValue != null && (
            <FormHelperText>{errors.editorValue}</FormHelperText>
          )}
        </FormControl>

        {renderAttachments({
          files: values.files ?? [],
          removeFile: file => {
            const matchField = file.id != null ? 'id' : 'internalId';

            setValues(prevValues => {
              return {
                files: prevValues.files.filter(
                  f => f[matchField] !== file[matchField],
                ),
              };
            });
          },
          isLoading: uploadingFile,
        })}
      </Flex>
    </Form>
  );
};

const FormComponentActions = ({
  onSubmit,
  openFileDialog,
  setTemplatesDialog,
  templatesDialog,
  setTemplate,
  setValues,
  me,
  values,
  campaignMode,
  resetForm,
  onCancel,
  initialTemplate,
  isSubmitting,
  valid,
  limitReached = false,
}: {
  onSubmit: () => void;
  openFileDialog: () => void;
  setTemplatesDialog: (open: boolean) => void;
  templatesDialog: boolean;
  setTemplate: (template: EmailForm_initialTemplate$data | null) => void;
  setValues: FormikHook<State>['setValues'];
  me: EmailForm_me$data;
  values: State;
  campaignMode: boolean;
  resetForm: FormikHook<State>['resetForm'];
  onCancel?: () => void;
  initialTemplate: EmailForm_initialTemplate$data | null;
  isSubmitting: boolean;
  valid: boolean;
  limitReached: boolean;
  sequenceContactObject: {
    leadId?: string;
    lotId?: string;
  };
  contactsAdded: (count: number) => void;
  onAlreadyEnrolledAlert: () => void;
}) => {
  const { t, language } = useLocale();
  const defaultLanguageTemplate = me.language ?? language;
  const { depth } = useTheme();
  const responsive = useResponsive();
  const buttonRef = React.useRef(null);
  const [mobileMenu, setPipelineMenu] = React.useState(false);
  const mobileMenuRef = React.useRef(null);
  useClickOutsideObserver([buttonRef, mobileMenuRef], () =>
    setPipelineMenu(false),
  );

  const checkSignature = (_e: any, checked: boolean) => {
    // If true - append signature if exists
    setValues({
      signatureEnabled: checked,
    });

    // If no signature - exiting
    // TODO: in signature editor make sure to save null, in case of empty text
    const signature = me.emailSignature;
    if (signature == null) {
      return;
    }

    const sugg = getTemplateSuggestions(
      defaultLanguageTemplate,
      me,
      values,
      campaignMode,
      t,
    );

    const signatureValue = createValueFromString(signature ?? '', 'raw');
    const signatureAst = signatureValue.toAST();

    const editorAst = values.editorValue.toAST();

    if (checked) {
      const newEditorValue = pushASTValue(
        values.editorValue,
        [...editorAst, ...signatureAst],
        {
          suggestions: sugg,
        },
      );

      const newEditorAst = newEditorValue.toAST();

      // When we created the new value - block ids are changed
      // Extract new block ids
      const newSignatureAst = newEditorAst.slice(-signatureAst.length);

      const signatureBlockIds = newSignatureAst.map((block: any[]) => {
        return block[1][1];
      });

      setValues({
        editorValue: newEditorValue,
        signatureBlockIds,
      });
    } else {
      const blockIds = values.signatureBlockIds;
      // Lets try to remove signature
      const newEditorAst = editorAst.filter((block: any[]) => {
        return !blockIds.includes(block[1][1]);
      });

      const newEditorValue = pushASTValue(values.editorValue, newEditorAst);

      setValues({
        editorValue: newEditorValue,
        signatureBlockIds: [],
      });
    }
  };

  return (
    <Flex width={1} flexWrap={['wrap', 'nowrap']}>
      <Flex alignItems="center" mr="auto">
        <IconButton onClick={openFileDialog}>
          <AttachFile />
        </IconButton>
        <IconButton onClick={() => setTemplatesDialog(true)}>
          <FileDocumentEdit />
        </IconButton>
        {responsive([
          <>
            <IconButton
              ref={buttonRef}
              edge="end"
              onClick={() => setPipelineMenu(!mobileMenu)}
            >
              <MoreVert />
            </IconButton>
            <Popper
              css={{ zIndex: depth.tooltip }}
              open={mobileMenu}
              anchorEl={buttonRef.current}
              ref={mobileMenuRef}
            >
              <Paper>
                <MenuList>
                  <MenuItem>
                    <FormControlLabel
                      label={t('addSignature')}
                      control={
                        <Switch
                          checked={values.signatureEnabled}
                          onChange={checkSignature}
                        />
                      }
                    />
                  </MenuItem>
                </MenuList>
              </Paper>
            </Popper>
          </>,
          <Box pl={2}>
            <FormControlLabel
              label={t('addSignature')}
              control={
                <Switch
                  checked={values.signatureEnabled}
                  onChange={checkSignature}
                />
              }
            />
          </Box>,
        ])}
        <TemplatesDialog
          open={templatesDialog}
          onClose={() => setTemplatesDialog(false)}
          onTemplateSelect={templateNode => {
            setTemplatesDialog(false);
            setTemplate(templateNode);
          }}
        />
      </Flex>

      <Flex ml="auto" alignItems="center" justifyContent="flex-end">
        <Button
          onClick={() => {
            resetForm();
            setTemplate(initialTemplate);
            onCancel?.();
          }}
          disabled={isSubmitting}
        >
          {t('Cancel')}
        </Button>

        <Box ml={2}>
          <ProgressButton
            disabled={
              !valid || (limitReached === true && campaignMode === false)
            }
            loading={isSubmitting}
            onClick={onSubmit}
          >
            {t('send')}
          </ProgressButton>
        </Box>
      </Flex>
    </Flex>
  );
};

type EmailCampaignInput = {
  email: {
    to: ((EmailName & { buyerLeadId?: string }) | null)[];
    subjectTemplate: string;
    bodyTemplate: string;
    files: EmailFile[];
  };
};

type SendNylasEmailInput = {
  email: {
    to: ((EmailName & { buyerLeadId?: string }) | null)[];
    cc?: ((EmailName & { buyerLeadId?: string }) | null)[];
    bcc?: ((EmailName & { buyerLeadId?: string }) | null)[];
    subject?: string;
    body?: string;
    files: EmailFile[];
    replyToMessageId?: string | null;
  };
  activity?: {
    parentId: string | null;
  };
  parentEmailMessageId?: string | null;
  newThreadVisibility?: string | null;
};

const FormComponent = ({
  isDialog,
  dialogTitle,
  props,
  replyToMessage,
  forwardMessage,
  initialTo,
  initialCc,
  initialBcc,
  me,
}: {
  isDialog: boolean;
  dialogTitle?: string;
  props: EmailFormProps;
  replyToMessage?: null | EmailForm_replyToMessage$data;
  forwardMessage?: null | EmailForm_forwardMessage$data;
  initialTo?: null | ContactType[];
  initialCc?: null | ContactType[];
  initialBcc?: null | ContactType[];
  me: EmailForm_me$data;
}) => {
  const { t, language, dateLocale } = useLocale();
  const defaultLanguageTemplate = me.language ?? language;

  const [limitReached, setLimitReached] = React.useState(false);
  const responsive = useResponsive();
  const [expandAddressFields, setExpandAddressFields] = React.useState(
    initialCc != null && initialCc.length > 0,
  );
  const [sending, setSending] = React.useState(false);
  const [startingCampaign, setStartingCampaign] = React.useState(false);

  const [snackErrorOpened, setSnackErrorOpened] = React.useState(false);
  const [snackError, setSnackError] = React.useState('');

  const handleSnackClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackErrorOpened(false);
  };

  const sequenceContactObject =
    props.parentName === 'Lead'
      ? { leadId: props.property?.leadId }
      : { lotId: props.property?.lotId };

  const sendNylasEmail = async (input: SendNylasEmailInput) => {
    setSending(true);
    if (input.email.files.length > 0) {
      for (const file of input.email.files) {
        if (file.id != null) {
          // check the id format if not global id
          const id = fromGlobalId(file.id, false);
          if (id != null) {
            file.id = id;
          }
        }
      }
    }
    const response = await fetchApiQuery(
      `${email_service_origin}/api/nylas/send-email`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(input),
      },
    );

    const data = await response.json();

    if (response.ok === false) {
      // setReconnectDialogOpen(true);
      if ([500, 400].includes(response.status)) {
        // show an snackbar
        setSnackError(data.error);
        setSnackErrorOpened(true);
        setSending(false);
      } else {
        setReconnectDialogOpen(true);
      }
    }

    setSending(false);
    return data;
  };

  const startEmailCampaign = async (input: EmailCampaignInput) => {
    setStartingCampaign(true);
    if (input.email.files.length > 0) {
      for (const file of input.email.files) {
        if (file.id != null) {
          // check the id format if not global id
          const id = fromGlobalId(file.id, false);
          if (id != null) {
            file.id = id;
          }
        }
      }
    }

    const response = await fetchApiQuery(
      `${email_service_origin}/api/nylas/create-and-start-email-campaign`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(input),
      },
    );

    const data = await response.json();

    if (response.ok === false) {
      // setReconnectDialogOpen(true);
      if ([500, 400].includes(response.status)) {
        if (Array.isArray(data.error) && isZodError(data.error[0])) {
          data.error.forEach((error: any) => {
            if (
              !isZodError(error) ||
              error.path.length < 3 ||
              error.path.slice(0, 2).join('.') !== 'email.to' ||
              error.validation !== 'email'
            ) {
              return;
            }

            const index = Number(error.path[2]);

            error.incriminated_recipient = {
              email: input.email.to[index]?.email,
              name: input.email.to[index]?.name,
            };
          });
        }

        setSnackError(data.error);
        setSnackErrorOpened(true);
        setSending(false);
      } else {
        setReconnectDialogOpen(true);
      }
    }

    setStartingCampaign(false);
    return data;
  };

  const [reconnectDialogOpen, setReconnectDialogOpen] = React.useState(false);
  const isSubmitting = sending || startingCampaign;

  const signature = me.emailSignature;
  let signatureBlockIds = [];
  let editorValue = createEmptyValue();

  let signatureAst = [];
  if (signature != null) {
    const signatureValue = createValueFromString(
      me.emailSignature ?? '',
      'raw',
    );

    signatureAst = signatureValue.toAST();

    editorValue = pushASTValue(editorValue, [emptyBlockAst, ...signatureAst], {
      suggestions: getTemplateSuggestions(
        defaultLanguageTemplate,
        me,
        {
          usersTo: initialTo != null ? [initialTo[0]] : [],
          property: props.property,
        },
        props.campaignMode ?? false,
        t,
        {
          agent: props.agent ?? null,
          property: props.property ?? null,
          lot: props.lot ?? null,
        },
      ),
    });
  }

  // Initial value
  if (replyToMessage != null) {
    editorValue = getReplyEditorValue({
      editorValue,
      replyToMessage,
      t,
      dateLocale,
    });
  }

  if (forwardMessage != null) {
    editorValue = getForwardEditorValue({
      editorValue,
      forwardMessage,
      t,
      dateLocale,
    });
  }

  // Store signature block ids
  if (signature != null) {
    const editorAst = editorValue.toAST();
    const newSignatureAst = editorAst.slice(1, signatureAst.length + 1);
    signatureBlockIds = newSignatureAst.map((block: any[]) => {
      return block[1][1];
    });
  }

  const getInitialSubjectEditorValue = () => {
    if (replyToMessage != null) {
      return createValueFromString(replyToMessage.subject ?? '', 'html');
    }

    if (forwardMessage != null) {
      return createValueFromString(
        `Fwd: ${forwardMessage.subject ?? ''}`,
        'html',
      );
    }

    return createEmptyValue();
  };

  const initialValues = {
    usersTo: initialTo ?? [],
    usersCc: initialCc ?? [],
    usersBcc: initialBcc ?? [],
    files: [],
    editorValue,
    subjectEditorValue: getInitialSubjectEditorValue(),
    signatureEnabled: true,
    signatureBlockIds,
  };

  const getReportType = (encodedId: string): string | null => {
    const type = atob(encodedId).split(':')[0];
    if (type === 'Lot') {
      return 'lot';
    }
    if (type === 'Lead') {
      return 'appraisal';
    }
    if (type === 'CmaReport') {
      return 'cma';
    }
    return null;
  };

  const getLinks = (baseString: string, t: (text: string) => string) => {
    let finalString = baseString.replace(/&amp;/g, '&');
    const divElements = baseString.split('</div>');

    const links = divElements
      .map(div => {
        const match = div.match(/href="([^"]*)"/);
        return match ? match[1] : null;
      })
      .filter(Boolean) as string[];

    for (const link of links) {
      try {
        const url = new URL(decodeURIComponent(link));
        const typeFromUrl = url.searchParams.get('documentId');

        if (typeFromUrl != null) {
          const documentIdRegex = new RegExp(
            /^([A-Za-z0-9-_]{4})*([A-Za-z0-9-_]{2}==|[A-Za-z0-9-_]{3}=)?$/,
          );
          const regexResult = typeFromUrl.match(documentIdRegex);

          if (regexResult && regexResult.length >= 1) {
            const reportType = getReportType(regexResult[0]);

            const replaceText =
              reportType === 'lot'
                ? t('lotBrochure')
                : reportType === 'appraisal'
                ? t('appraisalReport')
                : t('cmaReport');

            finalString = finalString.replace(
              link,
              `<a href=${url.href}>${replaceText}</a>`,
            );
          }
        }
      } catch (error) {
        console.error('Invalid URL encountered:', link, error);
      }
    }

    return finalString;
  };

  const formik = useFormik<State>({
    initialValues,
    onSubmit: async values => {
      const files = values.files.map((f: EmailFile) => ({
        id: f.id,
        mimetype: f.mimetype,
        name: f.name,
        size: f.size,
        url: f.url,
      }));

      const preparedBody = getLinks(
        values.editorValue.toString('html', t, language),
        t,
      );
      let parentEmailMessageId: string | null = null;
      if (replyToMessage != null) {
        parentEmailMessageId = replyToMessage.id;
      } else if (forwardMessage != null) {
        parentEmailMessageId = forwardMessage.id;
      }
      const input =
        props.campaignMode !== true
          ? {
              email: {
                to: toRecipients(values.usersTo),
                cc: toRecipients(values.usersCc),
                bcc: toRecipients(values.usersBcc),
                subject: values.subjectEditorValue.toString('plaintext'),
                body: preparedBody,
                files,
                replyToMessageId:
                  replyToMessage != null ? replyToMessage.messageId : null,
              },
              activity: {
                parentId: props.parentId,
              },
              parentEmailMessageId,
              newThreadVisibility:
                forwardMessage != null ? forwardMessage.visibility : null,
            }
          : {
              email: {
                to: toRecipients(values.usersTo, props.initialRecipients),
                subjectTemplate:
                  values.subjectEditorValue.toString('plaintext'),
                bodyTemplate: preparedBody,
                files,
              },
            };

      const onEmailSent = (data: any) => {
        formik.resetForm();
        // TODO: fragile workaround, to avoid reloading a value from initialValues
        // The only allowed way to change value for now is push. Otherwise it breaks mention plugin
        // See https://github.com/draft-js-plugins/draft-js-plugins/issues/624
        const newEditorValue = pushEmptyValue(values.editorValue);
        const newSubjectEditorValue = pushEmptyValue(values.subjectEditorValue);
        formik.setValues({
          editorValue: newEditorValue,
          subjectEditorValue: newSubjectEditorValue,
        });

        props.onEmailSent?.(data);
      };

      if (props.campaignMode !== true) {
        const response = await sendNylasEmail(input);
        if (response.error == null) {
          onEmailSent(response);
        }
      } else {
        if ('subjectTemplate' in input.email) {
          const response = await startEmailCampaign(
            input as EmailCampaignInput,
          );
          if (response.error == null) {
            onEmailSent(response);
          }
        }
      }
    },
    validate: values => {
      const errors: FormikErrors<State> = {};

      const messageIsEmpty = !values.editorValue
        .getEditorState()
        .getCurrentContent()
        .hasText();

      const subjectIsEmpty = !values.subjectEditorValue
        .getEditorState()
        .getCurrentContent()
        .hasText();

      const files = Object.values(values.files);

      // Allow message without text but with files
      const filesAreEmpty = !files.length;

      if (messageIsEmpty && filesAreEmpty) {
        errors.editorValue = t('messageOrFilesRequiredError');
      }

      if (subjectIsEmpty) {
        errors.subjectEditorValue = t('subjectIsRequired');
      }

      if (!values.usersTo.length) {
        errors.usersTo = t('usersToIsRequired');
      }

      if (values.usersTo.some(u => u.primaryEmail == null)) {
        errors.usersTo = t(
          'At least one of your recipients does not have an email address',
        );
      }

      return errors;
    },
  });

  const { values, setValues, resetForm, submitForm, valid, errors } = formik;

  const suggestions = getTemplateSuggestions(
    defaultLanguageTemplate,
    me,
    values,
    props.campaignMode ?? false,
    t,
    {
      agent: props.agent ?? null,
      property: props.property ?? null,
      lot: props.lot ?? null,
    },
  );
  const suggestionsArr = Object.keys(suggestions).map(key => ({
    id: key,
    // TODO: support null as name
    name: suggestions[key] ?? key,
  }));

  // TODO: set initial values from template on first render
  const setTemplate = (templateNode: EmailForm_initialTemplate$data | null) => {
    if (templateNode == null) {
      return;
    }

    const sugg = getTemplateSuggestions(
      defaultLanguageTemplate,
      me,
      values,
      props.campaignMode ?? false,
      t,
      {
        agent: props.agent ?? null,
        property: props.property ?? null,
        lot: props.lot ?? null,
      },
    );

    let newEditorValue;

    // Append signature
    if (me.emailSignature != null && templateNode.addSignature === true) {
      const signatureValue = createValueFromString(
        me.emailSignature ?? '',
        'raw',
      );
      const signatureAst = signatureValue.toAST();
      const templateValue = createValueFromString(
        templateNode.text ?? '',
        'raw',
      );
      const templateAst = templateValue.toAST();
      newEditorValue = pushASTValue(
        values.editorValue,
        [...templateAst, emptyBlockAst, ...signatureAst],
        {
          suggestions: sugg,
        },
      );

      const newSignatureAst = newEditorValue
        .toAST()
        .slice(-signatureAst.length);

      const signatureBlockIds = newSignatureAst.map((block: any[]) => {
        return block[1][1];
      });

      // Set signature switch to on
      // TODO: it is redundant - can use only signatureBlockIds
      setValues({
        signatureEnabled: true,
        signatureBlockIds,
      });
    } else {
      newEditorValue = pushRawStringValue(
        values.editorValue,
        templateNode.text ?? '',
        {
          suggestions: sugg,
        },
      );

      // Set signature switch to off
      // TODO: it is redundant - can use only signatureBlockIds
      setValues({
        signatureEnabled: false,
        signatureBlockIds: [],
      });
    }

    setValues({ editorValue: newEditorValue });

    const newSubjectEditorValue = pushRawStringValue(
      values.subjectEditorValue,
      templateNode.subject ?? '',
      {
        suggestions: sugg,
      },
    );
    setValues({
      subjectEditorValue: newSubjectEditorValue,
    });

    if (templateNode.cc?.length) {
      setValues({
        usersCc: (templateNode.cc ?? []).map(recipientToContact),
      });
      setExpandAddressFields(true);
    }

    if (templateNode.bcc?.length) {
      setValues({
        usersBcc: (templateNode.bcc ?? []).map(recipientToContact),
      });
      setExpandAddressFields(true);
    }

    // Set files
    if (templateNode.files?.length) {
      setValues({
        files: templateNode.files as EmailFile[],
      });
    }
  };

  const initialTemplate = useFragment(
    graphql`
      fragment EmailForm_initialTemplate on EmailTemplate {
        id
        name
        cc
        bcc
        text
        subject
        addSignature
        files {
          id
          size
          name
          url
          mimetype
        }
      }
    `,
    props.initialTemplate ?? null,
  );

  React.useEffect(() => {
    setTemplate(initialTemplate);
    // TODO: check required props for setTemplate(), wrap it with React.useCallback, enable the rule below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTemplate]);

  const [templatesDialog, setTemplatesDialog] = React.useState(false);

  const [uploadFile, uploadingFile] = useFileUpload();

  const lastId = React.useRef(0);
  const upload = (file: File) => {
    uploadFile(file, 'files', url => {
      if (url != null) {
        lastId.current += 1;
        const fileData = {
          internalId: lastId.current,
          name: file.name,
          url,
          size: file.size,
          mimetype: file.type,
        };
        setValues(prevValues => ({
          files: [...prevValues.files, fileData],
        }));
      }
    });
  };

  const openFileDialog = useFileDialog({
    // accept: 'image/*',
    multiple: true,
    onChange: files => {
      if (files.length !== 0) {
        files.forEach(f => upload(f));
      }
    },
  });

  // Using formik's 'changed' is not enough because of the RichEditor values
  // so we need this custom checkChanged
  const checkChanged = () => {
    return (
      initialValues.editorValue.toString('html', t, language) !==
        values.editorValue.toString('html', t, language) ||
      initialValues.subjectEditorValue.toString('plaintext') !==
        values.subjectEditorValue.toString('plaintext') ||
      !dequal(initialValues.usersTo, values.usersTo)
    );
  };

  const onClose = () => {
    if (checkChanged()) {
      if (confirm(t('emailNotSent'))) {
        props.onCancel?.();
      }
    } else {
      props.onCancel?.();
    }
  };

  // for open Email form activity show confirm message if email form was changed
  if (isDialog !== true && props.showUnfinishedWarning === true) {
    props.setShowUnfinishedWarning?.(false);
    onClose();
  }

  const ErrorSnackbar = () => {
    return (
      <Snackbar
        css={{ width: '75%' }}
        open={snackErrorOpened}
        autoHideDuration={20000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {t('An error occured while submitting your form')}
          <pre>{JSON.stringify(snackError, null, 2)}</pre>
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      <ReconnectEmailDialog
        open={reconnectDialogOpen}
        onClose={() => {
          setReconnectDialogOpen(false);
        }}
        onSuccess={() => {
          setReconnectDialogOpen(false);
          submitForm();
        }}
      />
      {isDialog === true && (
        <Dialog
          css={{
            '.MuiDialog-paperScrollPaper': {
              height: '100%',
            },
          }}
          open={true}
          onClose={onClose}
          maxWidth="md"
          fullScreen={responsive([true, false])}
        >
          <ErrorSnackbar />
          <DialogTitle>{dialogTitle ?? t('sendAnEmail')}</DialogTitle>
          <DialogContent>
            <FormComponentFields
              onSubmit={submitForm}
              suggestionsArr={suggestionsArr}
              uploadingFile={uploadingFile}
              me={me}
              property={props.property ?? null}
              agent={props.agent ?? null}
              lot={props.lot ?? null}
              campaignMode={props.campaignMode ?? false}
              errors={errors}
              values={values}
              setValues={setValues}
              expandAddressFields={expandAddressFields}
              setExpandAddressFields={setExpandAddressFields}
            />
          </DialogContent>
          <EmailCounterAlert onCount={setLimitReached} />
          <DialogActions>
            <FormComponentActions
              onSubmit={submitForm}
              me={me}
              campaignMode={props.campaignMode ?? false}
              limitReached={limitReached}
              values={values}
              setValues={setValues}
              valid={valid}
              isSubmitting={isSubmitting}
              initialTemplate={initialTemplate}
              onCancel={props.onCancel}
              resetForm={resetForm}
              setTemplate={setTemplate}
              templatesDialog={templatesDialog}
              setTemplatesDialog={setTemplatesDialog}
              openFileDialog={openFileDialog}
              sequenceContactObject={sequenceContactObject}
              contactsAdded={count => {
                props.setContactsCount?.(count);
                props.onCancel?.();
                props.setAlertOpen?.(true);
              }}
              onAlreadyEnrolledAlert={() => {
                props.onCancel?.();
                props.setAlertOpen?.(true);
              }}
            />
          </DialogActions>
        </Dialog>
      )}
      {isDialog !== true && (
        <>
          <ErrorSnackbar />
          <FormComponentFields
            onSubmit={submitForm}
            suggestionsArr={suggestionsArr}
            uploadingFile={uploadingFile}
            me={me}
            property={props.property ?? null}
            agent={props.agent ?? null}
            lot={props.lot ?? null}
            campaignMode={props.campaignMode ?? false}
            errors={errors}
            values={values}
            setValues={setValues}
            expandAddressFields={expandAddressFields}
            setExpandAddressFields={setExpandAddressFields}
          />
          <FormComponentActions
            onSubmit={submitForm}
            me={me}
            limitReached={limitReached}
            campaignMode={props.campaignMode ?? false}
            values={values}
            setValues={setValues}
            valid={valid}
            isSubmitting={isSubmitting}
            initialTemplate={initialTemplate}
            onCancel={props.onCancel}
            resetForm={resetForm}
            setTemplate={setTemplate}
            templatesDialog={templatesDialog}
            setTemplatesDialog={setTemplatesDialog}
            openFileDialog={openFileDialog}
            sequenceContactObject={sequenceContactObject}
            contactsAdded={count => {
              props.setContactsCount?.(count);
              props.onCancel?.();
              props.setAlertOpen?.(true);
            }}
            onAlreadyEnrolledAlert={() => {
              props.onCancel?.();
              props.setAlertOpen?.(true);
            }}
          />
        </>
      )}
    </>
  );
};

const isNotNull = <T extends unknown>(
  value: T | null | undefined,
): value is T => {
  return value != null;
};

const FormComponentWithRecipients = ({
  props,
  isDialog,
  dialogTitle,
  me,
  replyToMessage,
  forwardMessage,
}: {
  props: EmailFormProps;
  isDialog: boolean;
  dialogTitle?: string;
  me: EmailForm_me$data;
  replyToMessage?: null | EmailForm_replyToMessage$data;
  forwardMessage?: null | EmailForm_forwardMessage$data;
}) => {
  const ids = new Set<string>();

  const recipients = useLazyLoadQuery<EmailFormRecipientQuery>(
    graphql`
      query EmailFormRecipientQuery($filters: UserFilters) {
        users(first: 99999, filters: $filters)
          @connection(
            key: "ConnectionPrefilledRecipientData_users"
            filters: []
          ) {
          edges {
            node {
              id
              ...EmailForm_contact @relay(mask: false)
            }
          }
        }
      }
    `,
    {
      filters: {
        id_in: props.initialRecipients
          ? props.initialRecipients.map(r => r.userId)
          : Array.from(ids),
      },
    },
    { fetchPolicy: 'network-only' },
  );

  const initialTo =
    recipients.users?.edges?.reduce((acc, edge) => {
      if (edge?.node?.primaryEmail?.email != null) {
        acc.push(edge.node);
      }
      return acc;
    }, [] as ContactType[]) ?? null;

  return (
    <FormComponent
      isDialog={isDialog}
      dialogTitle={dialogTitle}
      me={me}
      replyToMessage={replyToMessage}
      forwardMessage={forwardMessage}
      initialTo={initialTo}
      initialCc={null}
      initialBcc={null}
      props={props}
    />
  );
};

export const EmailForm = (props: EmailFormProps) => {
  // TODO: we might want to load EmailForm_replyToMessage on demand, and not include fragment in all messages

  const replyToMessage = useFragment(
    graphql`
      fragment EmailForm_replyToMessage on EmailMessage {
        id
        date
        body
        subject
        visibility
        from {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        to {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        cc {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        bcc {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        replyTo {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        messageId
        isSent
      }
    `,
    props.replyToMessage ?? null,
  );

  const forwardMessage = useFragment(
    graphql`
      fragment EmailForm_forwardMessage on EmailMessage {
        id
        date
        body
        subject
        visibility
        from {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        to {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        cc {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        bcc {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        replyTo {
          name
          email
          user {
            ...EmailForm_contact @relay(mask: false)
            id
            firstName
            lastName
          }
        }
        messageId
        isSent
      }
    `,
    props.forwardMessage ?? null,
  );

  const me = useFragment(
    graphql`
      fragment EmailForm_me on User {
        ...EmailForm_contact @relay(mask: false)
        emailSignature
      }
    `,
    props.me,
  );

  if (replyToMessage != null) {
    let initialTo = replyToMessage.to;

    if (replyToMessage.isSent === false) {
      if (replyToMessage.replyTo.length > 0) {
        initialTo = replyToMessage.replyTo;
      } else {
        initialTo = replyToMessage.from;
      }
    }

    const contactInitialTo = initialTo
      .filter(isNotNull)
      .map(r => recipientToContact(r));
    const initialCc =
      replyToMessage != null
        ? replyToMessage.cc.filter(isNotNull).map(r => recipientToContact(r))
        : null;
    const initialBcc =
      replyToMessage != null
        ? replyToMessage.bcc.filter(isNotNull).map(r => recipientToContact(r))
        : null;

    return (
      <FormComponent
        isDialog={props.isDialog}
        dialogTitle={props.dialogTitle}
        me={me}
        props={props}
        replyToMessage={replyToMessage}
        forwardMessage={forwardMessage}
        initialTo={contactInitialTo}
        initialCc={initialCc}
        initialBcc={initialBcc}
      />
    );
  }

  if (props.initialRecipients == null) {
    return (
      <FormComponent
        isDialog={props.isDialog}
        dialogTitle={props.dialogTitle}
        me={me}
        props={props}
        replyToMessage={replyToMessage}
        forwardMessage={forwardMessage}
        initialTo={null}
        initialCc={null}
        initialBcc={null}
      />
    );
  }

  return (
    <React.Suspense fallback={null}>
      <FormComponentWithRecipients
        isDialog={props.isDialog}
        dialogTitle={props.dialogTitle}
        me={me}
        replyToMessage={replyToMessage}
        forwardMessage={forwardMessage}
        props={props}
      />
    </React.Suspense>
  );
};
