// @flow

import * as React from 'react';

import { graphql, useFragment } from 'react-relay';

import { useLocale } from '../hooks/locale';
import { getCurrencyByCountryCode } from '../locale';
import { formatPrice } from '../utils/format-price';
import {
  translateAppraisalPerception,
  translateAppraisalReason,
  translateBuyHorizon,
  translateMortgageBuyingStatus,
  translateMortgageStatus,
  translateReceivedMortgageOffer,
  translateSaleHorizon,
} from '../utils/lead-labels';

import type { leadQualificationFunnel_lead$key } from './__generated__/leadQualificationFunnel_lead.graphql';

type Props = {|
  lead: ?leadQualificationFunnel_lead$key,
|};

export const commercialTypes = [
  'house_multiple_dwelling',
  'indus_commercial',
  'indus_commercial_and_residential',
];

export const landTypes = [
  'prop_building_land',
  'prop_agricultural_land',
  'prop_commercial_land',
  'prop_industrial_land',
];

const getQualificationFunnel = ({ lead, currency, t, locale }) => {
  const getStep1 = () => {
    if (
      commercialTypes.includes(lead.property?.propertyType?.name) &&
      lead.ownership
    ) {
      // Add ownership structure under the contact name, before appraisal reason
      switch (lead.ownership) {
        case 'sole_ownership':
          return t('soleOwnership');
        case 'co_ownership':
          return t('coOwnership');
        case 'corporate_ownership':
          return t('corporateOwnership');
        default:
          return null;
      }
    } else {
      switch (lead.relationship) {
        case 'owner':
          return t('Owner');
        case 'tenant':
          return t('Tenant renter');
        case 'agent':
          return t('Agent');
        case 'other':
          return t('Other');
        default:
          return null;
      }
    }
  };

  const getStep3 = () => {
    switch (lead.appraisalReason) {
      case 'sell':
        return translateSaleHorizon(t, lead.saleHorizon);
      case 'refinance':
        return lead.mortgageTerm;
      case 'separation':
        return lead.appraisalSeparationNextStep;
      case 'inheritance':
        return lead.appraisalInheritanceNextStep;
      case 'buy_soon':
        return translateBuyHorizon(t, lead.buyHorizon);
      default:
        return null;
    }
  };

  const getSellingWith = () => {
    switch (lead.appraisalSellingWith) {
      case 'by_myself': {
        return t('byMyself');
      }
      case 'agent_exclusive': {
        return t('agentExclusive');
      }
      case 'agent_nonexclusive': {
        return t('Agent');
      }
      default: {
        return null;
      }
    }
  };

  const getStep4 = () => {
    if (
      (lead.appraisalReason === 'other_not_owner' &&
        lead.relationship === 'tenant') ||
      lead.appraisalReason === 'move_rental'
    ) {
      return lead.appraisalMonthlyGrossRent;
    }
    return lead.appraisalPerceivedValue != null
      ? formatPrice(lead.appraisalPerceivedValue, locale, currency)
      : null;
  };

  if (getStep1() == null) {
    return [
      translateAppraisalReason(t, lead.appraisalReason),
      translateMortgageBuyingStatus(t, lead.mortgageBuyingStatus),
      translateMortgageStatus(t, lead.mortgageStatus),
      translateReceivedMortgageOffer(t, lead.receivedMortgageOffer),
    ].filter(step => step != null);
  }

  const getOpenToOffer = () => {
    switch (lead.appraisalOpenToOffer) {
      case 'depends_on_price': {
        return t('maybeOpenToOffer');
      }
      case 'no': {
        return t('notOpenToOffers');
      }
      default: {
        return null;
      }
    }
  };

  const getMarketTime = () => {
    if (lead.appraisalMarketTime != null) {
      return t('sinceMonths', {
        numberMonths: lead.appraisalMarketTime,
      });
    }

    return null;
  };

  return [
    // step 1
    getStep1(),
    // step 2
    lead.appraisalReason !== 'not_set'
      ? translateAppraisalReason(t, lead.appraisalReason)
      : null,
    // step 3
    getStep3(),
    getSellingWith(),
    // step 4
    getStep4(),
    // step 5
    lead.appraisalPerception == null
      ? null
      : translateAppraisalPerception(t, lead.appraisalPerception ?? ''),
    // step 6
    lead.appraisalNextStep,
    getOpenToOffer(),
    getMarketTime(),
  ].filter(step => step != null);
};

export const LeadQualificationFunnel = (props: Props): React.Node => {
  const { t, locale } = useLocale();
  const lead = useFragment(
    graphql`
      fragment leadQualificationFunnel_lead on Lead {
        appraisalReason
        appraisalOpenToOffer
        appraisalSellingWith
        appraisalMarketTime
        appraisalNextStep
        appraisalMonthlyGrossRent
        appraisalPerceivedValue
        appraisalSeparationNextStep
        appraisalInheritanceNextStep
        saleHorizon
        appraisalPerception
        buyHorizon
        ownership
        relationship
        mortgageTerm
        mortgageBuyingStatus
        mortgageStatus
        receivedMortgageOffer
        property {
          countryCode
          propertyType {
            name
          }
        }
      }
    `,
    props.lead,
  );

  if (lead == null) {
    return null;
  }

  const currency = getCurrencyByCountryCode(lead.property?.countryCode ?? 'CH');
  const qualificationFunnel = getQualificationFunnel({
    lead,
    currency,
    t,
    locale,
  }).join(' • ');

  return <>{qualificationFunnel}</>;
};
