import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';

type Props = {
  fileId: string;
  open: boolean;
  onDelete?: (id: string) => void;
  onClose: () => void;
};

const DELETE_FILE = gql(/* GraphQL */ `
  mutation DeleteFile($id: uuid!) {
    delete_files_by_pk(id: $id) {
      id
    }
  }
`);

export const FileDeleteDialog = (props: Props) => {
  const { t } = useLocale();
  const [deleteFile, { loading: deleting }] = useMutation(DELETE_FILE);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {t('Are you sure you want to delete this file')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.onClose}>{t('Cancel')}</Button>
        <LoadingButton
          color="primary"
          size="medium"
          variant="contained"
          onClick={() => {
            deleteFile({
              variables: { id: props.fileId },
              onCompleted: () => {
                props.onDelete?.(props.fileId);
                props.onClose();
              },
            });
          }}
          loading={deleting}
        >
          {t('delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
