// @flow

import * as React from 'react';

import { FormControl, FormHelperText } from '@mui/material';

import { useTheme } from '../../hooks/theme';

import { type Props, RichEditor } from './RichEditor';

export const EmailSubjectField = (props: Props): React.Node => {
  const { text } = useTheme();

  return (
    <FormControl error={props.errorText != null}>
      <RichEditor
        singleLine={true}
        css={{
          '.RichEditor-editorContainer': {
            fontSize: '16px',
            fontWeight: text.font.medium,
            lineHeight: '17px',
            padding: '15px 12px',
            '&:hover': {
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 2,
                right: 2,
                bottom: 0,
                height: 1,
                backgroundColor: 'black',
              },
            },
          },
          '.MuiPaper-root': {
            overflow: 'auto',
            overflowX: 'hidden',
          },
        }}
        {...props}
      />
      {props.errorText != null && (
        <FormHelperText>{props.errorText}</FormHelperText>
      )}
    </FormControl>
  );
};
