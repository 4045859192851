import { useCallback, useMemo } from 'react';

import { type MutationFunction } from '@apollo/client';
import { type Breakpoint } from '@mui/material';
import { type ErrorOption, type FieldPath } from 'react-hook-form';

import { type Translate, useLocale } from '../../../src/hooks/locale';
import {
  Currencies,
  type IntlLocale,
  getCurrencyByCountryCode,
} from '../../../src/locale';
import { gql } from '../../__generated__';
import type {
  Lots_Set_Input,
  MandateFormFragment,
  PropertyFeaturesFragment,
  PropertyFormPricingFragment,
  PropertyPoiFragment,
  UpdateListingWithPropertyMutationVariables,
} from '../../__generated__/graphql';
import {
  Address_Display_Options_Enum_Enum,
  Dictionaries_Types_Enum_Enum,
} from '../../__generated__/graphql';
import type { Optional } from '../../common/types';
import { type GetListingDetailsData } from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import {
  EXPECTED_TIME_TO_SELL,
  MANDATE_TYPE,
  SUCCESS_FEE_TYPE,
  getExpectedTimeToSellList,
  getMandateTypeList,
  getSuccessFeeTypeList,
} from '../../utils/general-labels';
import {
  isCommercialBuilding,
  isHouseOrAppt,
  isMixedUseBuilding,
} from '../../utils/propertyDataChecks';
import {
  getOfferTypeOptions,
  getPriceUnitOptions,
} from '../../utils/propertyTemplateFunctions';
import { type RaAddressType } from '../form/RaAddressInput';
import {
  type FormDefinitionType,
  createCategoryElement,
  createCheckboxElement,
  createNumberFieldElement,
  createSelectElement,
  createTextFieldElement,
} from '../form/RaForm';
import { LotGenerateDescription } from '../LotGenerateDescription';
import {
  type PropertyFormContext,
  type PropertyFormDetailsData,
  getDefaultPropertyDetailsFormValues,
  getPropertyDetailsFormDefinition,
  preparePropertyDetailsFormData,
} from '../property-form/forms-definitions/propertyDetailsFormDefinition';
import { getValidatePropertyDetailsFormData } from '../property-form/forms-definitions/propertyDetailsFormValidation';
import {
  getDefaultPropertyFeaturesEquipmentsFormValues,
  preparePropertyFeaturesFormData,
  standaloneEquipmentFormDefinition,
  standaloneFeaturesFormDefinition,
} from '../property-form/forms-definitions/propertyFeaturesDefinition';
import {
  getDefaultPropertyPoiFormValues,
  poiFormDefinition,
} from '../property-form/forms-definitions/propertyPoiDefinition';
import {
  getDefaultPropertyPricingFormValues,
  getStandalonePricingFormDefinition,
  getValidatePricingFormData,
  preparePricingFormData,
} from '../property-form/forms-definitions/propertyPricingDefinition';

import {
  type ListingAvailability,
  ListingOverviewAvailabilitySelect,
} from './ListingOverviewAvailabilitySelect';
import { EEditListingForms } from './ListingOverviewEditModal';
import { type FormDefinition, type LightFormDefinition } from './models';
import {
  type AddressOverridesFormData,
  type ListedByOverridesFormData,
  getAddressOverridesDefinition,
  getListedByOverridesDefinition,
} from './sharedFormDefinition';

type ListingData = Optional<
  Omit<GetListingDetailsData, 'property'>,
  | 'id'
  | 'enquiries'
  | 'buyer_leads'
  | 'portal_listings'
  | 'enquiries_aggregate'
  | 'sellers'
  | 'has_success_fee'
  | 'success_fee_type'
  | 'expected_time_to_sell'
  | 'hide_price_on_portals'
  | 'use_dummy_contact'
  | 'created_at'
  | 'status'
  | 'is_parking_included_in_total'
  | 'address_display_option'
> & {
  property: Optional<
    GetListingDetailsData['property'],
    | 'is_oriented_north'
    | 'is_oriented_south'
    | 'is_oriented_east'
    | 'is_oriented_west'
  >;
};

export type TTitleDescriptionFormData = Pick<
  Lots_Set_Input,
  'title' | 'description'
>;
export type TAvailabilityFormData = Pick<Lots_Set_Input, 'available_from'> & {
  availability: ListingAvailability;
};
export type TPricingFormData = Pick<
  Lots_Set_Input,
  | 'hide_price_on_portals'
  | 'offer_type'
  | 'currency'
  | 'price_unit'
  | 'sale_price'
  | 'minimum_net_seller_price'
  | 'rent_net'
  | 'rent_extra'
  | 'parking_price'
  | 'is_parking_included_in_total'
> & { property: PropertyFormPricingFragment };

export type TVrVideosFormData = Pick<
  Lots_Set_Input,
  'virtual_visit_url' | 'video_url'
>;
export type TListedByFormData = Pick<Lots_Set_Input, 'broker_id'> &
  ListedByOverridesFormData;

export type TPropertyAddressFormData = AddressOverridesFormData & {
  property?: RaAddressType;
};

export const MANDATE_FORM_FRAGMENT = gql(/* GraphQL */ `
  fragment MandateForm on lots {
    ...ListingMandateCard
    mandate_sale_price
    is_sales_tax_inclusive
    duration_in_months
    origin_id
    has_success_fee
    success_fee_type
    success_fee_custom
    success_fee_percentage
  }
`);

export type TMandateFormData = Pick<
  Lots_Set_Input,
  Exclude<keyof MandateFormFragment, 'property' | 'origin'>
>;

const getFormDefinitionMap = (
  listing: ListingData,
  displayMinergieCode: boolean,
  country: string,
  locale: IntlLocale,
) => {
  // Set default values.
  const listingWithDefaults = {
    is_parking_included_in_total: true,
    address_display_option: Address_Display_Options_Enum_Enum.FullAddress,
    ...listing,
    property: {
      is_oriented_north: false,
      is_oriented_south: false,
      is_oriented_east: false,
      is_oriented_west: false,
      ...listing.property,
    },
  };

  return {
    [EEditListingForms.TITLE_DESCRIPTION]:
      (): LightFormDefinition<TTitleDescriptionFormData> => ({
        hasContext: false,
        formDefinition: ({ t }) => [
          {
            name: 'title',
            label: t('Title'),
            type: 'text',
            gridProps: { md: 12 },
          },
          {
            name: 'description',
            label: t('Description'),
            type: 'rich-text',
            gridProps: { md: 12 },
            actions: ({ setValue }) => (
              <LotGenerateDescription
                lot_id={listingWithDefaults.id}
                description={listingWithDefaults.description ?? ''}
                onComplete={(description: string) => {
                  setValue('description', description, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            ),
          },
        ],
        defaultValues: {
          title: listingWithDefaults.title,
          description: listingWithDefaults.description,
        },
        transformData: ({ title, description }) => ({
          lot: { title, description },
        }),
      }),
    [EEditListingForms.AVAILABILITY]:
      (): LightFormDefinition<TAvailabilityFormData> => {
        let defaultAvailability: ListingAvailability = 'available_at';

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (listing.available_from == null) {
          defaultAvailability = 'by_request';
        } else {
          const availableFromDate = new Date(listing.available_from);
          availableFromDate.setHours(0, 0, 0, 0);

          if (availableFromDate <= today) {
            defaultAvailability = 'available_now';
          }
        }

        return {
          hasContext: false,
          formDefinition: ({ t }) => [
            {
              type: 'custom',
              name: 'availability',
              element: <ListingOverviewAvailabilitySelect />,
              gridProps: { md: 12 },
            },
            {
              name: 'available_from',
              label: t('Available from'),
              type: 'date',
              gridProps: { md: 12 },
              required: true,
              render: ({ availability }: TAvailabilityFormData) =>
                availability === 'available_at',
            },
          ],
          defaultValues: {
            available_from: listing.available_from,
            availability: defaultAvailability,
          },
          transformData: ({ available_from }) => ({ lot: { available_from } }),
        };
      },
    [EEditListingForms.PRICING]: (): LightFormDefinition<TPricingFormData> => ({
      hasContext: false,
      formDefinition: ({ t }) => {
        const definition: ReturnType<FormDefinitionType<TPricingFormData>> = [
          {
            name: 'hide_price_on_portals',
            label: t('hidePriceOnPortals'),
            type: 'checkbox',
            style: 'switch',
          },
          createSelectElement(
            'offer_type',
            t('offerType'),
            () =>
              getOfferTypeOptions(t)
                .map(offerType => ({
                  value: offerType.id,
                  label: offerType.label,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            { gridProps: { xs: 6 } },
          ),
          createSelectElement(
            'currency',
            t('currency'),
            () =>
              Currencies.map(currency => ({
                value: currency,
                label: currency,
              })).sort((a, b) => a.label.localeCompare(b.label)),
            { gridProps: { xs: 6 } },
          ),
          createSelectElement(
            'price_unit',
            t('priceUnit'),
            ({ offer_type }) =>
              getPriceUnitOptions(t, offer_type).sort((a, b) =>
                a.label.localeCompare(b.label),
              ),
            { gridProps: { xs: 6 } },
          ),
          {
            name: 'sale_price',
            label: t('salePrice'),
            type: 'number',
            render: ({ offer_type }) => offer_type === 'sell',
            gridProps: { xs: 6 },
          },
          {
            name: 'minimum_net_seller_price',
            label: t('minimumNetSellerPrice'),
            type: 'number',
            render: ({ offer_type }) => offer_type === 'sell',
            gridProps: { xs: 6 },
          },
          {
            name: 'rent_net',
            label: t('rentNet'),
            type: 'number',
            render: ({ offer_type }) => offer_type === 'rent',
            gridProps: { xs: 6 },
          },
          {
            name: 'rent_extra',
            label: t('rentExtra'),
            type: 'number',
            gridProps: { xs: 6 },
            render: ({ offer_type }) => offer_type === 'rent',
          },
          {
            name: 'parking_title',
            type: 'category-title',
            label: t('Parking'),
            render: () =>
              isHouseOrAppt(listingWithDefaults.property.__property_type) ||
              isMixedUseBuilding(
                listingWithDefaults.property.__property_type,
              ) ||
              isCommercialBuilding(
                listingWithDefaults.property.__property_type,
              ),
          },
          createCheckboxElement(
            'is_parking_included_in_total',
            t('Parking price included in total'),
            {
              gridProps: { xs: 6 },
              style: 'switch',
              helpText: t(
                'If your property does not have a parking space/slot, just let this option checked.',
              ),
              render: () =>
                isHouseOrAppt(listingWithDefaults.property.__property_type) ||
                isMixedUseBuilding(
                  listingWithDefaults.property.__property_type,
                ) ||
                isCommercialBuilding(
                  listingWithDefaults.property.__property_type,
                ),
            },
          ),
          createNumberFieldElement('parking_price', t('Parking price'), {
            gridProps: { xs: 6 },
            render: ({ is_parking_included_in_total }) =>
              !is_parking_included_in_total &&
              (isHouseOrAppt(listingWithDefaults.property.__property_type) ||
                isMixedUseBuilding(
                  listingWithDefaults.property.__property_type,
                ) ||
                isCommercialBuilding(
                  listingWithDefaults.property.__property_type,
                )),
          }),
        ];

        const pricingFormDefinition =
          getStandalonePricingFormDefinition('property');
        definition.push(
          ...pricingFormDefinition({
            t,
            context: {
              propertyType: listingWithDefaults.property.__property_type,
            },
          }),
        );

        return definition;
      },
      defaultValues: {
        hide_price_on_portals:
          listingWithDefaults.hide_price_on_portals ?? false,
        sale_price: listingWithDefaults.sale_price,
        minimum_net_seller_price: listingWithDefaults.minimum_net_seller_price,
        rent_net: listingWithDefaults.rent_net,
        rent_extra: listingWithDefaults.rent_extra,
        price_unit: listingWithDefaults.price_unit ?? 'sell',
        offer_type: listingWithDefaults.offer_type ?? 'sell',
        currency:
          listingWithDefaults.currency ??
          getCurrencyByCountryCode(
            listingWithDefaults.property.country_code ?? 'CH',
          ),
        parking_price: listingWithDefaults.parking_price,
        is_parking_included_in_total:
          listingWithDefaults.is_parking_included_in_total,
        property: getDefaultPropertyPricingFormValues(
          listingWithDefaults.property,
        ),
      },
      transformData: data => {
        const {
          currency,
          offer_type,
          price_unit,
          minimum_net_seller_price,
          sale_price: salePriceInput,
          rent_extra: rentExtraInput,
          rent_net: rentNetInput,
          hide_price_on_portals,
          property,
          parking_price,
          is_parking_included_in_total,
        } = data;

        let rentNet;
        let rentExtra;
        let salePrice;
        let parkingPrice = parking_price;
        if (offer_type === 'sell') {
          salePrice = salePriceInput;
          rentExtra = null;
          rentNet = null;
        } else {
          salePrice = null;
          rentExtra = rentExtraInput;
          rentNet = rentNetInput;
        }

        const propertyPayload = preparePricingFormData(
          property,
          listingWithDefaults.property.__property_type,
        );

        if (is_parking_included_in_total) {
          parkingPrice = null;
        }

        return {
          lot: {
            offer_type,
            currency,
            price_unit,
            rent_net: rentNet,
            rent_extra: rentExtra,
            minimum_net_seller_price,
            sale_price: salePrice,
            hide_price_on_portals,
            parking_price: parkingPrice,
            is_parking_included_in_total,
          },
          property: propertyPayload,
        };
      },
    }),
    [EEditListingForms.VR_VIDEOS]:
      (): LightFormDefinition<TVrVideosFormData> => ({
        hasContext: false,
        formDefinition: ({ t }) => [
          {
            name: 'virtual_visit_url',
            label: t('Virtual visit URL'),
            type: 'text',
          },
          {
            name: 'video_url',
            label: t('Video URL'),
            type: 'text',
          },
        ],
        defaultValues: {
          virtual_visit_url: listingWithDefaults.virtual_visit_url,
          video_url: listingWithDefaults.video_url,
        },
        transformData: ({ virtual_visit_url, video_url }) => ({
          lot: { virtual_visit_url, video_url },
        }),
      }),
    [EEditListingForms.LISTED_BY]:
      (): LightFormDefinition<TListedByFormData> => {
        const overrideDefinition = getListedByOverridesDefinition(
          listingWithDefaults,
          country,
        );

        return {
          hasContext: false,
          formDefinition: ({ t }) => [
            {
              name: 'broker_id',
              label: t('broker'),
              type: 'user',
            },
            {
              name: '',
              type: 'category-title',
              label: t('displayContactOptions'),
            },
            ...overrideDefinition.formDefinition({ t, context: undefined }),
          ],
          defaultValues: {
            broker_id: listingWithDefaults.broker?.id,
            ...overrideDefinition.defaultValues,
          },
          transformData: data => {
            const { broker_id } = data;

            return {
              lot: {
                broker_id,
                ...(overrideDefinition.transformData(data).lot ?? {}),
              },
            };
          },
        };
      },
    [EEditListingForms.MANDATE]: (): LightFormDefinition<TMandateFormData> => ({
      hasContext: false,
      formDefinition: ({ t }) => [
        {
          name: 'mandate_sale_price',
          label: t('Desired sale price'),
          type: 'number',
          gridProps: { xs: 12, sm: 6, md: 4 },
        },
        createSelectElement(
          'mandate_type',
          t('Mandate type'),
          () => getMandateTypeList(t),
          { gridProps: { xs: 12, sm: 6, md: 4 } },
        ),
        createNumberFieldElement('commission_rate', t('Rate'), {
          suffix: '%',
          gridProps: { xs: 12, sm: 6, md: 4 },
          decimalNumbers: 1,
          render: ({ mandate_type }: TMandateFormData) =>
            mandate_type === MANDATE_TYPE.COMMISSION_BASED,
        }),
        createNumberFieldElement('fixed_fee', t('Fixed fee'), {
          gridProps: { xs: 12, sm: 6, md: 4 },
          render: ({ mandate_type }: TMandateFormData) =>
            mandate_type === MANDATE_TYPE.FIXED_FEE,
        }),
        {
          name: 'signed_at',
          type: 'date',
          label: t('Signed at'),
          gridProps: { xs: 12, sm: 6, md: 4 },
        },
        {
          name: 'is_exclusive',
          type: 'checkbox',
          label: t('Exclusive listing'),
          gridProps: { xs: 12, sm: 6, md: 4 },
        },
        {
          name: 'is_sales_tax_inclusive',
          type: 'checkbox',
          label: t('Sales tax inclusive'),
          gridProps: { xs: 12, sm: 6, md: 4 },
        },
        createNumberFieldElement('duration_in_months', t('Duration'), {
          suffix: t('months'),
          gridProps: { xs: 12, sm: 6, md: 4 },
        }),
        createSelectElement(
          'expected_time_to_sell',
          t('Expected time to sell'),
          () => getExpectedTimeToSellList(t),
          { gridProps: { xs: 12, sm: 6, md: 4 } },
        ),
        {
          name: 'origin_id',
          label: t('Mandate origin'),
          type: 'dictionary',
          valueField: 'id',
          dictionaryType: Dictionaries_Types_Enum_Enum.LotOriginTypes,
          multiple: false,
          gridProps: { xs: 12, sm: 6, md: 4 },
        },
        {
          name: 'has_success_fee',
          type: 'checkbox',
          label: t('Success fee'),
          gridProps: { md: 12 },
        },
        createSelectElement(
          'success_fee_type',
          t('Success fee type'),
          () => getSuccessFeeTypeList(t),
          {
            render: ({ has_success_fee }: TMandateFormData) =>
              has_success_fee ?? false,
            gridProps: { xs: 12, sm: 6 },
          },
        ),
        createNumberFieldElement('success_fee_percentage', t('Percentage'), {
          suffix: '%',
          gridProps: { xs: 12, sm: 6 },
          decimalNumbers: 1,
          render: ({ success_fee_type, has_success_fee }: TMandateFormData) =>
            !!has_success_fee &&
            success_fee_type ===
              SUCCESS_FEE_TYPE.PERCENTAGE_OF_PROCEEDS_ABOVE_SALE_PRICE,
        }),
        createTextFieldElement(
          'success_fee_custom',
          t('Custom success fee structure'),
          {
            gridProps: { xs: 12, sm: 6 },
            render: ({ success_fee_type, has_success_fee }: TMandateFormData) =>
              !!has_success_fee && success_fee_type === SUCCESS_FEE_TYPE.CUSTOM,
          },
        ),
      ],
      defaultValues: {
        expected_time_to_sell:
          listingWithDefaults.expected_time_to_sell ??
          EXPECTED_TIME_TO_SELL.NOT_SET,
        mandate_sale_price: listingWithDefaults.mandate_sale_price,
        is_sales_tax_inclusive:
          listingWithDefaults.is_sales_tax_inclusive ?? false,
        duration_in_months: listingWithDefaults.duration_in_months,
        origin_id: listingWithDefaults.origin_id,
        has_success_fee: listingWithDefaults.has_success_fee ?? false,
        success_fee_type: listingWithDefaults.success_fee_type,
        success_fee_custom: listingWithDefaults.success_fee_custom,
        success_fee_percentage:
          listingWithDefaults.success_fee_percentage != null
            ? listingWithDefaults.success_fee_percentage * 100
            : listingWithDefaults.success_fee_percentage,
        currency: listingWithDefaults.currency,
        commission_rate:
          listingWithDefaults.commission_rate != null
            ? listingWithDefaults.commission_rate * 100
            : listingWithDefaults.commission_rate,
        fixed_fee: listingWithDefaults.fixed_fee,
        is_exclusive: listingWithDefaults.is_exclusive ?? false,
        mandate_type:
          listingWithDefaults.mandate_type ?? MANDATE_TYPE.COMMISSION_BASED,
        signed_at: listingWithDefaults.signed_at,
      },
      transformData: ({
        success_fee_percentage,
        commission_rate,
        ...otherProps
      }) => ({
        lot: {
          success_fee_percentage:
            success_fee_percentage != null
              ? success_fee_percentage / 100
              : null,
          commission_rate:
            commission_rate != null ? commission_rate / 100 : null,
          ...otherProps,
        },
      }),
    }),
    [EEditListingForms.ADDRESS]:
      (): LightFormDefinition<TPropertyAddressFormData> => {
        const overrideDefinition = getAddressOverridesDefinition(
          listingWithDefaults,
          country,
        );

        return {
          hasContext: false,
          formDefinition: ({ t }) => [
            {
              label: t('Address'),
              path: 'property',
              type: 'address',
              countryRestriction: country,
              gridProps: { xs: 12 },
            },
            createCategoryElement(
              'display_address_options',
              t('displayAddressOptions'),
            ),
            ...overrideDefinition.formDefinition({ t, context: undefined }),
          ],
          defaultValues: {
            ...overrideDefinition.defaultValues,
            property: {
              street_number: listingWithDefaults.property.street_number,
              route: listingWithDefaults.property.route,
              locality: listingWithDefaults.property.locality,
              postcode: listingWithDefaults.property.postcode,
              state: listingWithDefaults.property.state,
              country: listingWithDefaults.property.country,
              country_code: listingWithDefaults.property.country_code,
              lat: listingWithDefaults.property.lat,
              lng: listingWithDefaults.property.lng,
            },
          },
          transformData: ({ property, ...data }) => ({
            lot: overrideDefinition.transformData(data).lot,
            property,
          }),
        };
      },
    [EEditListingForms.POI]: (): LightFormDefinition<PropertyPoiFragment> => ({
      hasContext: false,
      formDefinition: poiFormDefinition,
      defaultValues: getDefaultPropertyPoiFormValues(
        listingWithDefaults.property,
      ),
      transformData: data => ({
        property: data,
      }),
    }),
    [EEditListingForms.EQUIPMENT]:
      (): LightFormDefinition<PropertyFeaturesFragment> => ({
        hasContext: false,
        formDefinition: ({ t }) => {
          const equipmentFields = standaloneEquipmentFormDefinition({
            t,
            context: {
              propertyType: listingWithDefaults.property.__property_type,
            },
          });
          const featuresFields = standaloneFeaturesFormDefinition({
            t,
            context: undefined,
          });

          return [...equipmentFields, ...featuresFields];
        },
        defaultValues: getDefaultPropertyFeaturesEquipmentsFormValues(
          listingWithDefaults.property,
        ),
        transformData: data => ({
          property: preparePropertyFeaturesFormData(
            data,
            listingWithDefaults.property.__property_type,
          ),
        }),
      }),
    [EEditListingForms.PROPERTY]: (): LightFormDefinition<
      PropertyFormDetailsData,
      PropertyFormContext
    > => ({
      hasContext: true,
      formDefinition: getPropertyDetailsFormDefinition(
        displayMinergieCode,
        listingWithDefaults.currency,
        listingWithDefaults.property.country_code ?? country,
        locale,
      ),
      defaultValues: getDefaultPropertyDetailsFormValues(
        listingWithDefaults.property,
      ),
      transformData: data => ({
        property: preparePropertyDetailsFormData(data, displayMinergieCode),
      }),
    }),
  };
};

export const getListingOverviewEditFormDefinition = (
  t: Translate,
  listing: GetListingDetailsData,
  updateFn: MutationFunction<any, UpdateListingWithPropertyMutationVariables>,
  displayMinergieCode: boolean,
  locale: IntlLocale,
  country: string,
) => {
  // Allows to leverage TFormData typing for each form definition.
  const getFormDefinition = <
    TFormData extends Record<string, any>,
    TContext extends Record<string, any> | undefined = undefined,
  >(
    title: string,
    formDefinition: LightFormDefinition<TFormData, TContext>,
    validate?:
      | ((data: TFormData) => [FieldPath<TFormData>, ErrorOption][])
      | null,
    modalMaxWidth?: Breakpoint,
  ): FormDefinition<TFormData, TContext> => {
    const definition: FormDefinition<TFormData, TContext> = {
      title,
      hasContext: formDefinition.hasContext as any,
      formDefinition: formDefinition.formDefinition,
      defaultValues: formDefinition.defaultValues,
      onSubmit: data => {
        const { lot, property } = formDefinition.transformData(data);

        return updateFn({
          variables: {
            id: listing.id,
            prop_id: listing.property.id,
            lot: lot ?? {},
            property: property ?? {},
          },
        });
      },
      modalMaxWidth,
    };

    if (validate != null) {
      definition.validate = validate;
    }

    return definition;
  };

  const formDefinitionMap = getFormDefinitionMap(
    listing,
    displayMinergieCode,
    country,
    locale,
  );

  const listingFormDefinition = new Map<
    EEditListingForms,
    | FormDefinition<TTitleDescriptionFormData>
    | FormDefinition<TAvailabilityFormData>
    | FormDefinition<TPricingFormData>
    | FormDefinition<TVrVideosFormData>
    | FormDefinition<TListedByFormData>
    | FormDefinition<TMandateFormData>
    | FormDefinition<TPropertyAddressFormData>
    | FormDefinition<PropertyFormDetailsData, PropertyFormContext>
    | FormDefinition<PropertyPoiFragment>
    | FormDefinition<PropertyFeaturesFragment>
  >([
    [
      EEditListingForms.TITLE_DESCRIPTION,
      getFormDefinition<TTitleDescriptionFormData>(
        t('Title and description'),
        formDefinitionMap[EEditListingForms.TITLE_DESCRIPTION](),
      ),
    ],
    [
      EEditListingForms.AVAILABILITY,
      getFormDefinition<TAvailabilityFormData>(
        t('Availability'),
        formDefinitionMap[EEditListingForms.AVAILABILITY](),
        null,
        'xs',
      ),
    ],
    [
      EEditListingForms.PRICING,
      getFormDefinition<TPricingFormData>(
        t('Price'),
        formDefinitionMap[EEditListingForms.PRICING](),
        data => {
          const errors: [FieldPath<TPricingFormData>, ErrorOption][] = [];
          const validateErrors = getValidatePricingFormData(
            t,
            listing.property.__property_type,
            'property',
          )(data);
          errors.push(...validateErrors);

          if (
            !data.is_parking_included_in_total &&
            data.parking_price == null
          ) {
            errors.push([
              'parking_price',
              {
                message: t(
                  'Parking price is required if parking is not included in price',
                ),
              },
            ]);
          }

          return errors;
        },
      ),
    ],
    [
      EEditListingForms.VR_VIDEOS,
      getFormDefinition<TVrVideosFormData>(
        t('VR tour and Video'),
        formDefinitionMap[EEditListingForms.VR_VIDEOS](),
      ),
    ],
    [
      EEditListingForms.LISTED_BY,
      getFormDefinition<TListedByFormData>(
        t('Listed by'),
        formDefinitionMap[EEditListingForms.LISTED_BY](),
      ),
    ],
    [
      EEditListingForms.MANDATE,
      getFormDefinition<TMandateFormData>(
        t('Mandate'),
        formDefinitionMap[EEditListingForms.MANDATE](),
      ),
    ],
    // Property
    [
      EEditListingForms.ADDRESS,
      getFormDefinition<TPropertyAddressFormData>(
        t('Address'),
        formDefinitionMap[EEditListingForms.ADDRESS](),
      ),
    ],
    [
      EEditListingForms.PROPERTY,
      getFormDefinition<PropertyFormDetailsData, PropertyFormContext>(
        t('Property details'),
        formDefinitionMap[EEditListingForms.PROPERTY](),
        getValidatePropertyDetailsFormData(t),
      ),
    ],
    [
      EEditListingForms.POI,
      getFormDefinition<PropertyPoiFragment>(
        t('Points of interest nearby'),
        formDefinitionMap[EEditListingForms.POI](),
      ),
    ],
  ]);

  // If not Land.
  if (listing.property.__property_type?.main_type !== 'PROP') {
    listingFormDefinition.set(
      EEditListingForms.EQUIPMENT,
      getFormDefinition<PropertyFeaturesFragment>(
        t('Equipment & Characteristics'),
        formDefinitionMap[EEditListingForms.EQUIPMENT](),
        null,
        'sm',
      ),
    );
  }

  return listingFormDefinition;
};

type ListingFormDefinitionMap = ReturnType<typeof getFormDefinitionMap>;

export const useListingOverviewEditFormDefinition = <
  Key extends keyof ListingFormDefinitionMap,
>({
  listing,
  formType,
}: {
  listing?: ListingData | null;
  formType: Key;
}) => {
  const { countryCode, locale } = useLocale();
  const { me } = useAppData();

  const isCHTenant = me?.tenant.country_code === 'CH';

  const memoizedGetFormDefinitionMap = useCallback(
    () =>
      listing != null
        ? getFormDefinitionMap(listing, isCHTenant, countryCode, locale)
        : null,
    [listing, countryCode, locale, isCHTenant],
  );

  const formDefinition = useMemo(() => {
    const formDefinitionMap = memoizedGetFormDefinitionMap();

    if (formDefinitionMap == null) {
      return null;
    }

    return formDefinitionMap[formType]();
  }, [memoizedGetFormDefinitionMap, formType]);

  return formDefinition as ReturnType<ListingFormDefinitionMap[Key]> | null;
};
