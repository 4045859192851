import React from 'react';

import { Alert, Collapse } from '@mui/material';

import { useLocale } from '../src/hooks/locale';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  contactsCount: number;
};

export const EmailSequenceContactsAddedAlert = ({
  contactsCount,
  open,
  setOpen,
}: Props) => {
  const { t } = useLocale();

  React.useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => setOpen(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [open, setOpen]);

  return (
    <Collapse in={open}>
      <Alert severity="success">
        {contactsCount === 0
          ? t('contactIsAlreadyEnrolledInThisSequence')
          : t('contactsWereSuccessfullyEnrolled', { count: contactsCount })}
      </Alert>
    </Collapse>
  );
};
