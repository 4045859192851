import { useTheme } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { Drawer } from '../drawer/Drawer';

import { CreateLeadStepper } from './CreateLeadStepper';

type CreateLeadDrawerProps = {
  onClose: () => void;
  onCreate: (leadId: string) => void;
  userId?: string;
};

export const CreateLeadDrawer = ({
  onClose,
  onCreate,
  userId,
}: CreateLeadDrawerProps) => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();

  return (
    <Drawer
      onClose={onClose}
      title={t('Create seller lead')}
      maxWidth={breakpoints.values.md}
      sx={{ zIndex: 1300 }}
    >
      <CreateLeadStepper onLeadCreated={onCreate} userId={userId} />
    </Drawer>
  );
};

CreateLeadDrawer.displayName = 'CreateLeadDrawer';
