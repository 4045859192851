// @flow

import * as React from 'react';

import {
  Button,
  ButtonBase,
  Divider,
  IconButton,
  LinearProgress,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useGoogleApi } from '@realadvisor/google-maps';
import { useResizeRect } from '@realadvisor/observe';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Box, Flex, useResponsive } from 'react-system';
import { Map, useMap } from 'rgm';

import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { ArrowLeftFilled } from '../../icons/arrow-left-filled';
import { GpsFixed } from '../../icons/gps-fixed';
import { KeyboardArrowLeft } from '../../icons/keyboard-arrow-left';
import { MapOutlined } from '../../icons/map-outlined';
import { OpenInNew } from '../../icons/open-in-new';
import { Public } from '../../icons/public';
import { UserMale } from '../../icons/user-male';
import { LandRegistryOwnersCard } from '../../shared/land-registry-owners-card';
import { LeadCreateDrawer } from '../../shared/lead-create-drawer';
import { LeadSnippet } from '../../shared/lead-snippet';
import { Plot } from '../../shared/property-card';

import type { PoiMapDrawerLeadsQuery } from './__generated__/PoiMapDrawerLeadsQuery.graphql';
import type { PoiMapDrawerPlaceQuery } from './__generated__/PoiMapDrawerPlaceQuery.graphql';

type Props = {|
  lat?: ?number,
  lng?: ?number,
  poiId?: ?string,
  withSearch?: boolean,
  onClose: () => void,
|};

const GoogleMapsButton = ({ address, color }) => {
  const { text } = useTheme();
  const { t } = useLocale();
  const googleMapsUrl = new URL('https://google.com/maps/search/?api=1');

  if (address != null) {
    const { googlePlaceId, ...readableAddress } = address;
    googleMapsUrl.searchParams.set(
      'query',
      Object.values(readableAddress).filter(Boolean).join('+'),
    );

    if (googlePlaceId != null) {
      googleMapsUrl.searchParams.set('query_place_id', googlePlaceId);
    }
  }
  return (
    <Button
      css={{
        justifyContent: 'flex-start',
        textTransform: 'none',
        fontSize: text.size(14),
        paddingLeft: 0,
        color,
      }}
      component="a"
      target="_blank"
      size="small"
      href={googleMapsUrl.toString()}
      endIcon={<OpenInNew />}
    >
      {t('openInGoogleMaps')}
    </Button>
  );
};

const Drawer = ({ open, onClose, children, withSearch, lat, lng, address }) => {
  const { colors, bgColor, text, textColor } = useTheme();
  const responsive = useResponsive();
  const mobileRef = React.useRef(null);
  const mobileContainerRef = React.useRef(null);
  const mobileRect = useResizeRect(mobileRef) || { height: 0 };
  const mobileContainerRect = useResizeRect(mobileContainerRef);

  const MOBILE_MIN_HEIGHT = 140;
  const [mobileTranslate, setMobileTranslate] =
    React.useState(MOBILE_MIN_HEIGHT);
  const [mobileStartY, setMobileStartY] = React.useState(null);
  const [mobileUseScroll, setMobileUseScroll] = React.useState(false);
  const [mobileScroll, setMobileScroll] = React.useState(0);

  const handleTouchMove = e => {
    const yPos = e.touches[0].pageY;

    if (
      mobileStartY != null &&
      mobileRect &&
      mobileContainerRect &&
      mobileRef.current != null
    ) {
      const deltaY = mobileStartY - yPos;
      const maxTranslate = mobileRect.height - MOBILE_MIN_HEIGHT;
      const maxPossibleTranslate =
        mobileContainerRect.height - MOBILE_MIN_HEIGHT;

      if (mobileRef.current.scrollTop === 0) {
        if (deltaY > 0 && mobileTranslate === 0) {
          setMobileTranslate(
            maxTranslate < maxPossibleTranslate
              ? -maxTranslate
              : -maxPossibleTranslate,
          );
          if (maxTranslate >= maxPossibleTranslate) {
            setMobileUseScroll(true);
          }
        }

        if (deltaY < 0 && mobileTranslate !== 0) {
          setMobileTranslate(0);
          setMobileUseScroll(false);
        }
      }

      setMobileStartY(yPos);
    }
  };

  const handleScroll = () => {
    if (mobileRef.current != null) {
      if (mobileScroll > 0 && mobileRef.current.scrollTop <= 0) {
        setMobileTranslate(0);
        setMobileUseScroll(false);
      }

      setMobileScroll(mobileRef.current.scrollTop);
    }
  };

  const handleTouch = () => {
    if (mobileTranslate === 0 && mobileContainerRect && mobileRect) {
      const maxPossibleTranslate =
        mobileContainerRect.height - MOBILE_MIN_HEIGHT;
      const maxTranslate = mobileRect.height - MOBILE_MIN_HEIGHT;
      if (maxTranslate >= maxPossibleTranslate) {
        setMobileUseScroll(true);
      }
      setMobileTranslate(
        maxTranslate < maxPossibleTranslate
          ? -maxTranslate
          : -maxPossibleTranslate,
      );
    } else {
      if (mobileRef.current != null) {
        mobileRef.current.scrollTop = 0;
      }
      setMobileTranslate(0);
      setMobileUseScroll(false);
    }
  };

  const handleTouchStart = e => {
    setMobileStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    setMobileStartY(0);
  };

  // Fix for iOS
  React.useEffect(() => {
    const el = mobileRef.current;

    if (el) {
      const event = e => {
        if (!mobileUseScroll) {
          e.preventDefault();
        }
      };

      el.addEventListener('touchmove', event);

      return () => {
        el.removeEventListener('touchmove', event);
      };
    }
  }, [mobileUseScroll]);

  React.useEffect(() => {
    if (mobileRect.height > 0) {
      const maxTranslate = mobileRect.height - MOBILE_MIN_HEIGHT;
      if (-mobileTranslate > maxTranslate) {
        setMobileTranslate(-maxTranslate);
      }
    }
  }, [mobileRect.height, mobileTranslate]);

  React.useEffect(() => {
    if (open) {
      setMobileTranslate(0);
    } else {
      setMobileTranslate(MOBILE_MIN_HEIGHT);
    }
  }, [open]);

  React.useEffect(() => {
    if (open) {
      setMobileTranslate(0);
    }
  }, [lat, lng, open]);

  return responsive([
    <Box
      key={'mobile'}
      css={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        pointerEvents: 'none',
        zIndex: 130,
      }}
      ref={mobileContainerRef}
    >
      {mobileUseScroll && address != null && (
        <Flex
          alignItems="center"
          css={[
            bgColor('blue500'),
            textColor('white'),
            {
              zIndex: 150,
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              pointerEvents: 'auto',
              touchAction: 'none',
            },
          ]}
          p={2}
        >
          <IconButton color="inherit" onClick={handleTouch}>
            <KeyboardArrowLeft key="mobile" />
          </IconButton>
          <Box pl={2} css={[text.truncate(1), text.h6]}>
            {address.route} {address.streetNumber}
          </Box>
        </Flex>
      )}
      <Box
        style={{
          transform: `translateY(${mobileTranslate}px)`,
          willChange: 'transform',
          overflowY: 'auto',
        }}
        css={{
          position: 'absolute',
          zIndex: 120,
          left: 0,
          top: `calc(100% - ${MOBILE_MIN_HEIGHT}px)`,
          width: '100%',
          boxShadow: '0 -1px 2px rgba(0,0,0,0.3)',
          backgroundColor: colors.white,
          transition: 'transform 0.3s',
          pointerEvents: 'auto',
          touchAction: 'none',
          minHeight: MOBILE_MIN_HEIGHT,
          maxHeight: '100%',
          WebkitOverflowScrolling: 'touch',
        }}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onScroll={handleScroll}
        ref={mobileRef}
      >
        {address != null && (
          <Box
            p={3}
            css={[bgColor('blue500'), textColor('white')]}
            onClick={handleTouch}
          >
            <Box css={text.h6}>
              {address.route} {address.streetNumber}
            </Box>
            <Box>
              {address.postcode} {address.locality}
            </Box>
            <Box>
              <GoogleMapsButton address={address} color={colors.white} />
            </Box>
          </Box>
        )}
        <Box css={{ overflowY: 'auto' }}>{children}</Box>
      </Box>
    </Box>,
    <Slide direction="right" in={open} key="desktop">
      <Flex
        flexDirection="column"
        css={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: 400,
          zIndex: 100,
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
          borderTop: `1px solid ${colors.grey300}`,
        }}
      >
        <ButtonBase
          css={{
            position: 'absolute',
            top: 8,
            left: '100%',

            width: 25,
            height: 50,
            backgroundColor: colors.white,
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.3)',
            borderLeft: `1px solid ${colors.grey300}`,
          }}
          onClick={onClose}
        >
          <ArrowLeftFilled fill={colors.grey600} />
        </ButtonBase>

        {withSearch && (
          <Box css={[bgColor('white')]} height={'65px'} width={1} />
        )}

        <Box
          flexGrow={1}
          width={1}
          height={1}
          css={[
            bgColor('white'),
            {
              overflowY: 'auto',
              position: 'relative',
            },
          ]}
        >
          {children}
        </Box>
      </Flex>
    </Slide>,
  ]);
};

const MatchedLeads = ({ fetchKey, filters }) => {
  const { t } = useLocale();
  const { text } = useTheme();
  const data = useLazyLoadQuery<PoiMapDrawerLeadsQuery>(
    graphql`
      query PoiMapDrawerLeadsQuery($count: Int!, $filters: PoisFilters) {
        pois(first: $count, filters: $filters) {
          edges {
            node {
              id
              lat
              lng
              properties {
                id
                lead {
                  ...leadSnippet_lead
                }
              }
            }
          }
        }
      }
    `,
    { count: 100, filters },
    { fetchKey, fetchPolicy: 'store-and-network' },
  );
  const properties = (data.pois?.edges ?? []).flatMap(
    poi => poi?.node?.properties ?? [],
  );
  if (properties.length === 0) {
    return null;
  }
  return (
    <>
      <Box pt={3} px={3} css={text.subtitle2}>
        {t('leads')} ({properties.length})
      </Box>
      {properties.map(property => (
        <React.Fragment key={property.id}>
          <LeadSnippet
            lead={property.lead}
            showMap={false}
            showAddress={false}
            openOnNewTab={true}
          />
          <Box px={3}>
            <Divider />
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};

type MarkerProps = {|
  lat: number,
  lng: number,
|};

const GoogleMarker = ({ lat, lng }: MarkerProps) => {
  const { api, map } = useMap();
  React.useEffect(() => {
    if (api) {
      const marker = new api.Marker({
        map,
        position: {
          lat,
          lng,
        },
      });
      return () => {
        marker.setMap(null);
      };
    }
  }, [api, map, lat, lng]);
  return null;
};

const PoiMapDrawerContent = ({ lat, lng, setAddress, poiId }) => {
  const { t, language } = useLocale();
  const { text, colors, textColor } = useTheme();
  const initialMapTypeId = 'satellite';
  const [mapTypeId, setMapTypeId] = React.useState(initialMapTypeId);
  const mapApi = useGoogleApi(true);
  const [map, setMap] = React.useState(null);
  const responsive = useResponsive();

  const [leadAdd, setLeadAdd] = React.useState(null);
  const [matchedLeadsFetchKey, retryMatchedLeads] = React.useReducer(
    d => d + 1,
    0,
  );

  const data = useLazyLoadQuery<PoiMapDrawerPlaceQuery>(
    graphql`
      query PoiMapDrawerPlaceQuery($lat: Float!, $lng: Float!) {
        tenantSettings {
          activateLandRegistryOwners
        }
        googlePlace(lat: $lat, lng: $lng) {
          address {
            route
            streetNumber
            postcode
            state
            locality
            countryCode
            googlePlaceId
          }
          location {
            ...propertyCardPlot_location
            plot {
              owners {
                names
              }
            }
          }
        }
      }
    `,
    { lat, lng },
  );

  const activateLandRegistryOwners =
    data.tenantSettings?.activateLandRegistryOwners ?? false;
  const address = data.googlePlace?.address;
  const location = data.googlePlace?.location;
  const plot = location?.plot;

  const owners = plot?.owners?.names ?? [];
  const leadFilter = poiId != null ? { id_eq: poiId } : null;

  setAddress(address);

  const GOOD_ZOOM_FOR_TILT = 19;
  const MAX_POSSIBLE_TILT = 45;

  const mapOptions = {
    center: { lat, lng },
    disableDefaultUI: true,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    // Don't pick from state to avoid map reload on change
    mapTypeId: initialMapTypeId,
    zoom: GOOD_ZOOM_FOR_TILT,
    tilt: MAX_POSSIBLE_TILT,
  };

  return (
    <>
      <LeadCreateDrawer
        open={leadAdd != null}
        address={leadAdd}
        onClose={() => setLeadAdd(null)}
        onCreate={leadId => {
          retryMatchedLeads();
          setLeadAdd(null);
          window.open(
            `${window.location.origin}/${language}/leads/${leadId}`,
            '_blank',
          );
        }}
      />
      {address != null && (
        <>
          <Flex height="250px" css={{ position: 'relative' }}>
            {mapApi && (
              <Map
                //$FlowFixMe[incompatible-type]
                ref={setMap}
                api={mapApi}
                options={mapOptions}
              >
                <GoogleMarker lat={lat} lng={lng} />
                <Box
                  css={{
                    position: 'absolute',
                  }}
                  mt={2}
                  ml={2}
                >
                  <ToggleButtonGroup
                    css={{
                      backgroundColor: '#ffffff',
                    }}
                    size="small"
                    orientation="vertical"
                  >
                    <ToggleButton
                      value="roadmap"
                      selected={mapTypeId === 'roadmap'}
                      onClick={() => {
                        setMapTypeId('roadmap');
                        map?.setMapTypeId('roadmap');
                      }}
                    >
                      <MapOutlined />
                    </ToggleButton>
                    <ToggleButton
                      value="satellite"
                      selected={mapTypeId === 'satellite'}
                      onClick={() => {
                        setMapTypeId('satellite');
                        map?.setMapTypeId('satellite');
                      }}
                    >
                      <Public />
                    </ToggleButton>
                    <ToggleButton
                      value="street-view"
                      onClick={() => {
                        const streetView = map?.getStreetView();
                        if (streetView != null) {
                          streetView?.setPosition({ lat, lng });
                          streetView.setPov({
                            heading: 0,
                            pitch: 0,
                          });
                          const toggle = streetView.getVisible();
                          if (toggle === false) {
                            streetView.setVisible(true);
                          } else {
                            streetView.setVisible(false);
                          }
                        }
                      }}
                    >
                      <UserMale />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Map>
            )}
          </Flex>

          {responsive([
            null,
            <Box p={3} key={'desktop'}>
              <Box css={text.h6}>
                {address.route} {address.streetNumber}
              </Box>
              <Box>
                {address.postcode} {address.locality}
              </Box>
              <GoogleMapsButton address={address} color={colors.primaryMain} />
            </Box>,
          ])}

          <Divider />
          <Box p={3} css={text.subtitle2}>
            {t('landRegistry')}
          </Box>
          <Plot location={location} />
          <Divider />

          <Flex p={3} justifyContent="center" alignItems="center">
            <Box
              css={{ cursor: 'pointer' }}
              onClick={() => {
                setLeadAdd({
                  lat,
                  lng,
                  route: address.route,
                  streetNumber: address.streetNumber,
                  postcode: address.postcode,
                  state: address.state,
                  locality: address.locality,
                  countryCode: address.countryCode,
                });
              }}
            >
              <Box
                width={'44px'}
                height={'44px'}
                css={{
                  border: `2px solid ${colors.blue500}`,
                  borderRadius: '100%',
                  padding: '5px',
                  margin: '0 auto',
                }}
              >
                <GpsFixed fill={colors.blue500} size={30} />
              </Box>
              <Box css={[text.subtitle2, textColor('blue500')]}>
                {['+', ' ', t('lead')]}
              </Box>
            </Box>
          </Flex>
          <Divider />
        </>
      )}

      {activateLandRegistryOwners && (
        <LandRegistryOwnersCard names={owners} defaultExpanded={false} />
      )}

      {leadFilter != null && (
        <React.Suspense fallback={<LinearProgress variant="indeterminate" />}>
          <MatchedLeads fetchKey={matchedLeadsFetchKey} filters={leadFilter} />
        </React.Suspense>
      )}
    </>
  );
};

export const PoiMapDrawer = ({
  withSearch = true,
  lat,
  lng,
  onClose,
  poiId,
}: Props): React.Node => {
  const [address, setAddress] = React.useState(null);

  return (
    <>
      <Drawer
        open={lat != null && lng != null}
        onClose={onClose}
        withSearch={withSearch}
        lat={lat}
        lng={lng}
        address={address}
      >
        <React.Suspense fallback={<LinearProgress variant="indeterminate" />}>
          {lat != null && lng != null && (
            <PoiMapDrawerContent
              lat={lat}
              lng={lng}
              setAddress={setAddress}
              poiId={poiId}
            />
          )}
        </React.Suspense>
      </Drawer>
    </>
  );
};
