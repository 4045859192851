import { gql } from '@apollo/client';
import { Typography } from '@mui/material';
import { type To } from 'react-router-dom';

import type { TeamInfo_TeamFragment } from '../../__generated__/graphql';
import { ConditionalLinkWrapper } from '../../components/ConditionalLinkWrapper';
import { TeamAvatar } from '../../components/TeamAvatar';

export const TEAM_INFO_TEAM_FRAGMENT = gql(/* GraphQL */ `
  fragment TeamInfo_team on teams {
    id
    name
    logo {
      id
      url
    }
    locality
    state
    postcode
  }
`);

interface TeamInfoProps {
  team: TeamInfo_TeamFragment;
  enableLink?: boolean;
  to?: To;
}

export const TeamInfo = (props: TeamInfoProps) => {
  const { enableLink = false, team, to } = props;

  if (team == null) {
    console.error('Only teams should be passed to TeamInfo');
    return null;
  }

  const { id, logo, name, postcode, locality, state } = team;

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: 8,
        height: '100%',
      }}
    >
      <ConditionalLinkWrapper
        link={
          enableLink
            ? {
                to: to ? to : `/v2/teams/${id}`,
              }
            : undefined
        }
      >
        <TeamAvatar logoUrl={logo?.url} />
        <div style={{ minWidth: 0 }}>
          <Typography variant="body2" noWrap>
            {name}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            noWrap
            paragraph
            marginBottom={0}
          >
            {postcode} {locality}, {state}
          </Typography>
        </div>
      </ConditionalLinkWrapper>
    </div>
  );
};
