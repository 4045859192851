import { useCallback, useState } from 'react';

import { useFragment as useApolloFragment, useMutation } from '@apollo/client';
import Add from '@mui/icons-material/Add';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Button, Divider, IconButton, Skeleton, Stack } from '@mui/material';
import {
  type UseFormSetError,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { RemoveCircle } from '../../../icons/remove-circle';
import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../src/locale';
import { Cma_Reports_Construction_Base_Enum_Enum } from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';
import { RaNumber } from '../../components/form/RaNumber';
import { RaTextField } from '../../components/form/RaTextfield';
import { PropertyForm } from '../../components/property-form/PropertyForm';
import { UPDATE_PROPERTY } from '../../components/property-form/propertyFormQueries';
import { getCurrencySymbol } from '../../utils/formatting';

import { useCMAEditor } from './CMAReportEditor/CMAReportEditorWorkflow';
import { STEP_INTRINSIC_VALUATION_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

type CustomField = {
  id: string;
  title: string;
  value: number | null;
};

type StepIntrinsicValuationForm = {
  construction_cost_base: Cma_Reports_Construction_Base_Enum_Enum;
  building_volume: number | null | undefined;
  intrinsic_building_surface: number | null | undefined;
  intrinsic_construction_cost: number | null | undefined;
  construction_year: number | null | undefined;
  renovation_year: number | null | undefined;
  intrinsic_annual_deprecation_rate: number | null | undefined;
  land_surface: number | null | undefined;
  intrinsic_land_value: number | null | undefined;
  intrinsic_custom_fields: CustomField[];
};

const PROPERTY_FIELDS = [
  'building_volume',
  'construction_year',
  'renovation_year',
  'land_surface',
];

const CURRENT_YEAR = new Date().getFullYear();

const SURFACE_FIELDS = [
  'living_surface',
  'usable_surface',
  'basement_surface',
  'commercial_surface',
  'balcony_surface',
  'garden_surface',
  'gross_floor_surface',
  'residential_surface',
  'terrace_surface',
  'weighted_floor_surface',
] as const;

const calculateMaxSurface = (property: any) =>
  !property
    ? 0
    : Math.max(...SURFACE_FIELDS.map(field => property[field] ?? 0));

const CustomFields = () => {
  const { t, countryCode, locale } = useLocale();
  const { cmaReport } = useCMAEditor();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<StepIntrinsicValuationForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'intrinsic_custom_fields',
  });

  const [updateCmaReport] = useUpdateCmaReport(
    cmaReport.id,
    'page-intrinsic-valuation',
  );

  const handleDelete = async (index: number) => {
    remove(index);

    if (errors.intrinsic_custom_fields) {
      return;
    }
    await updateCmaReport({
      intrinsic_custom_fields: fields.filter((_, i) => i !== index),
    });
  };

  const handleAdd = () => append({ id: uuidv4(), title: '', value: null });

  return (
    <Stack gap={3}>
      {fields.map((field, index) => (
        <Stack key={field.id} gap={2}>
          <Divider />

          <Stack direction="row" alignItems="center" gap={2}>
            <Stack flex={1} gap={2}>
              <RaTextField
                register={register}
                name={`intrinsic_custom_fields.${index}.title`}
                label={t('Field title', { number: index + 1 })}
                errors={errors}
                required
              />
              <RaNumber
                name={`intrinsic_custom_fields.${index}.value`}
                label={t('Field value', { number: index + 1 })}
                control={control}
                errors={errors}
                required
                prefix={getCurrencySymbol(
                  getCurrencyByCountryCode(countryCode),
                  locale,
                )}
              />
            </Stack>
            <IconButton
              color="error"
              onClick={() => handleDelete(index)}
              sx={{ mt: 2 }}
            >
              <RemoveCircle />
            </IconButton>
          </Stack>
        </Stack>
      ))}
      <Button
        fullWidth
        color="primary"
        variant="outlined"
        startIcon={<Add />}
        onClick={handleAdd}
      >
        {t('Add custom field')}
      </Button>
    </Stack>
  );
};

const StepIntrinsicValuation = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const { t, locale, countryCode } = useLocale();
  const [open, setOpen] = useState(false);

  const { data, complete } = useApolloFragment({
    fragment: STEP_INTRINSIC_VALUATION_FRAGMENT,
    fragmentName: 'StepIntrinsicValuation',
    from: { __typename: 'cma_reports', id: cmaReportId },
  });

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-intrinsic-valuation',
  );

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const update = useCallback(
    async (formData?: Partial<StepIntrinsicValuationForm>) => {
      await updateCmaReport({
        construction_cost_base: formData?.construction_cost_base,
        intrinsic_building_surface: formData?.intrinsic_building_surface,
        intrinsic_construction_cost: formData?.intrinsic_construction_cost,
        intrinsic_annual_deprecation_rate:
          formData?.intrinsic_annual_deprecation_rate,
        intrinsic_land_value: formData?.intrinsic_land_value,
        intrinsic_custom_fields: formData?.intrinsic_custom_fields,
      });
    },
    [updateCmaReport],
  );

  const calculateIntrinsicBuildingSurface = useCallback(
    (intrinsic_building_surface: number | null) =>
      intrinsic_building_surface ?? calculateMaxSurface(data?.lead?.property),
    [data?.lead?.property],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepIntrinsicValuationForm> =
    useCallback(
      async (formData, name, type, { setValue, runValidation }) => {
        if (type !== 'change') {
          return;
        }

        const surfaceValue = !formData.construction_cost_base
          ? null
          : calculateIntrinsicBuildingSurface(
              name === 'construction_cost_base' &&
                formData?.intrinsic_building_surface == null
                ? null
                : formData?.intrinsic_building_surface ?? 0,
            );

        if (
          name === 'construction_cost_base' &&
          formData?.construction_cost_base
        ) {
          setValue('intrinsic_building_surface', surfaceValue);
        }

        if (name && PROPERTY_FIELDS.includes(name)) {
          if (runValidation() === false) {
            return;
          }
          await updateProperty({
            variables: {
              id: data?.lead?.property?.id ?? '',
              property: { [name]: formData?.[name as keyof typeof formData] },
            },
          });
        }

        debouncedUpdate({
          ...formData,
          intrinsic_building_surface: surfaceValue || null,
          intrinsic_custom_fields: formData.intrinsic_custom_fields?.filter(
            (field): field is CustomField =>
              field != null &&
              field.title?.trim() !== '' &&
              field.value != null,
          ),
        });
      },
      [
        debouncedUpdate,
        calculateIntrinsicBuildingSurface,
        updateProperty,
        data,
      ],
    );

  const stepIntrinsicValuationFormDefinition = useCallback<
    FormDefinitionType<StepIntrinsicValuationForm>
  >(
    ({ t }) => {
      const currencyProps = {
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
      };

      return [
        {
          type: 'custom',
          name: 'property-details',
          gridProps: { md: 12 },
          element: (
            <Button
              endIcon={<ChevronRight />}
              fullWidth
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{ display: 'inline-flex' }}
            >
              {t('editPropertyDetails')}
            </Button>
          ),
        },
        {
          name: 'construction_cost_base',
          label: t('constructionCostBasedOn'),
          type: 'select',
          options: () => [
            { value: 'volume', label: t('volume') },
            { value: 'surface', label: t('surface') },
          ],
          gridProps: { md: 12 },
        },
        {
          name: 'building_volume',
          label: t('buildingVolume'),
          type: 'number',
          suffix: 'm³',
          min: 40,
          max: 20_000,
          decimalNumbers: 1,
          render: (formData: StepIntrinsicValuationForm) =>
            formData.construction_cost_base === 'volume',
          gridProps: { md: 12 },
        },
        {
          name: 'intrinsic_building_surface',
          label: t('surface'),
          type: 'number',
          suffix: 'm²',
          decimalNumbers: 1,
          min: 0,
          max: 50_000,
          render: (formData: StepIntrinsicValuationForm) =>
            formData.construction_cost_base === 'surface',
          gridProps: { md: 12 },
        },
        {
          name: 'intrinsic_construction_cost',
          label: t('constructionCostPerSquareMeter'),
          type: 'number',
          ...currencyProps,
          render: (formData: StepIntrinsicValuationForm) =>
            formData.construction_cost_base === 'surface',
          gridProps: { md: 12 },
        },
        {
          name: 'intrinsic_construction_cost',
          label: t('constructionCostPerCubicMeter'),
          type: 'number',
          ...currencyProps,
          render: (formData: StepIntrinsicValuationForm) =>
            formData.construction_cost_base !== 'surface',
          gridProps: { md: 12 },
        },
        {
          name: 'construction_year',
          label: t('Construction year'),
          type: 'number',
          disableFormatting: true,
          max: CURRENT_YEAR,
          gridProps: { md: 12 },
        },
        {
          name: 'renovation_year',
          label: t('Renovation year'),
          type: 'number',
          disableFormatting: true,
          max: CURRENT_YEAR,
          gridProps: { md: 12 },
        },
        {
          name: 'intrinsic_annual_deprecation_rate',
          label: t('annualDeprecationRate'),
          type: 'number',
          suffix: '%',
          min: 0,
          max: 100,
          decimalNumbers: 2,
          gridProps: { md: 12 },
        },
        {
          name: 'land_surface',
          label: t('landSurface'),
          type: 'number',
          min: 0,
          max: 500_000,
          suffix: 'm²',
          gridProps: { md: 12 },
        },
        {
          name: 'intrinsic_land_value',
          label: t('landValuePerSquareMeter'),
          type: 'number',
          ...currencyProps,
          gridProps: { md: 12 },
        },
        {
          type: 'custom',
          name: 'intrinsic_custom_fields',
          element: <CustomFields />,
          gridProps: { md: 12 },
        },
      ];
    },
    [countryCode, locale],
  );

  const onSubmit = async (formData: StepIntrinsicValuationForm) => {
    await update(formData);
    props.setStep(props.step + 1);
  };

  if (!complete) {
    return (
      <Stack gap={4}>
        <Stack gap={1}>
          <Skeleton variant="rounded" width={'30%'} height={30} />
          <Skeleton variant="rounded" width={'50%'} height={40} />
        </Stack>
        <Stack gap={2}>
          <Skeleton variant="rounded" height={20} />
          <Stack direction={'row'}>
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{ mr: 2 }}
            />
            <Skeleton variant="rounded" width={'30%'} height={24} />
          </Stack>
          <Stack gap={3}>
            {Array.from({ length: 2 }).map((_, index) => (
              <Skeleton
                variant="rounded"
                key={`intrinsic-${index}`}
                height={50}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} title={t('Property')}>
        <PropertyForm
          propertyId={data?.lead?.property?.id}
          onCancel={() => setOpen(false)}
          onSave={() => {
            setOpen(false);
          }}
        />
      </Drawer>
      <RaForm
        freezeInitialDefaultValues={true}
        formDefinition={stepIntrinsicValuationFormDefinition}
        defaultValues={{
          construction_cost_base:
            data?.construction_cost_base ??
            Cma_Reports_Construction_Base_Enum_Enum.Volume,
          building_volume: data?.lead?.property?.building_volume,
          intrinsic_building_surface:
            data?.intrinsic_building_surface != null
              ? data.intrinsic_building_surface
              : calculateMaxSurface(data?.lead?.property),
          intrinsic_construction_cost: data?.intrinsic_construction_cost,
          construction_year: data?.lead?.property?.construction_year,
          renovation_year: data?.lead?.property?.renovation_year,
          intrinsic_annual_deprecation_rate:
            data?.intrinsic_annual_deprecation_rate,
          land_surface: data?.lead?.property?.land_surface,
          intrinsic_land_value: data?.intrinsic_land_value,
          intrinsic_custom_fields: data?.intrinsic_custom_fields,
        }}
        validate={data => {
          const errors: Parameters<
            UseFormSetError<StepIntrinsicValuationForm>
          >[] = [];

          if (
            data.construction_year &&
            data.renovation_year &&
            data.renovation_year < data.construction_year
          ) {
            errors.push([
              'renovation_year',
              {
                message: t(
                  'Renovation year cannot be earlier than construction year',
                ),
              },
            ]);
          }

          if (
            data.building_volume &&
            (data.building_volume < 40 || data.building_volume > 20000)
          ) {
            errors.push([
              'building_volume',
              {
                message: t('Building volume should be between', {
                  min: 40,
                  max: 20000,
                }),
              },
            ]);
          }

          if (data.intrinsic_custom_fields) {
            data.intrinsic_custom_fields.forEach((field, index) => {
              if (!field.title?.trim()) {
                errors.push([
                  `intrinsic_custom_fields.${index}.title`,
                  {
                    message: t('This field is required'),
                  },
                ]);
              }
              if (field.value == null) {
                errors.push([
                  `intrinsic_custom_fields.${index}.value`,
                  {
                    message: t('This field is required'),
                  },
                ]);
              }
            });
          }

          return errors;
        }}
        onSubmit={onSubmit}
        contentScrollable
        onChange={onChangeHandler}
        actionButtonsComponent={
          <FooterActions {...props} updating={updating} />
        }
      />
    </>
  );
};

export default StepIntrinsicValuation;
