import * as React from 'react';

import type { SxProps, TextFieldProps } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';
import {
  addDays,
  addHours,
  addMinutes,
  format,
  isBefore,
  isValid,
  parse,
} from 'date-fns';

import { useLocale } from '../hooks/locale';
import { number_of_string } from '../utils/number-format';

type Props = {
  sx?: SxProps;
  value: string;
  onChange: (time: string) => void;
  textFieldProps?: TextFieldProps;
};

const TIME_FORMAT = 'HH:mm';

export const parseTime = (baseDate: Date, time: string): null | Date => {
  const parsed = parse(time, TIME_FORMAT, baseDate);
  if (!isValid(parsed)) {
    return null;
  }
  // add day to not get past time
  if (isBefore(parsed, baseDate)) {
    return addDays(parsed, 1);
  }
  return parsed;
};

export const formatTime = (baseDate: Date): string => {
  return format(baseDate, TIME_FORMAT);
};

export const TimeInput = (props: Props) => {
  const { t } = useLocale();
  const [hours, minutes] = props.value.split(':');
  let time = new Date(new Date().setHours(0, 0, 0, 0));
  if (number_of_string(hours) != null) {
    time = addHours(time, number_of_string(hours) ?? 0);
  }
  if (number_of_string(minutes) != null) {
    time = addMinutes(time, number_of_string(minutes) ?? 0);
  }
  const [isError, setIsError] = React.useState(false);
  return (
    <TimePicker
      slotProps={{
        textField: {
          ...props.textFieldProps,
          InputProps: {
            sx: props.sx,
          },
        },
      }}
      label={t('time')}
      ampm={false}
      onError={() => {
        setIsError(true);
      }}
      onOpen={() => {
        if (props.value === '') {
          props.onChange(format(new Date().setHours(12, 0, 0, 0), 'HH:mm'));
        }
      }}
      value={props.value !== '' ? time : null}
      onChange={newTime => {
        const parsedTime = new Date(newTime ?? Date.now());

        if (isError) {
          props.onChange(`${new Date().getHours()}:${new Date().getMinutes()}`);
        }
        if (!isNaN(parsedTime.getTime())) {
          const hoursAndMinutes = format(parsedTime, 'HH:mm');
          props.onChange(hoursAndMinutes);
        }
        if (newTime == null) {
          props.onChange('');
        }
      }}
    />
  );
};
