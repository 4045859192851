import { TextField } from '@mui/material';
import { Flex } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';

type Props = {
  color?: string | null;
  onChange: (color: string) => void;
};

export const ColorPicker = ({ color, onChange }: Props) => {
  const { borderRadius } = useTheme();
  const { t } = useLocale();

  return (
    <Flex>
      <div
        css={{
          position: 'relative',
          overflow: 'hidden',
          width: 56,
          height: 56,
          borderRadius: borderRadius.small,
          marginTop: 8,
          marginBottom: 8,
          marginRight: 16,
          border: '1px solid #000000',
        }}
      >
        <input
          type="color"
          value={color ?? ''}
          width="100%"
          css={{
            position: 'absolute',
            right: -8,
            top: -8,
            width: 78,
            height: 78,
            cursor: 'pointer',
          }}
          onChange={event => {
            onChange(event.target.value);
          }}
        />
      </div>
      <TextField
        css={{ marginTop: 8 }}
        label={t('mainColor')}
        margin="normal"
        value={color ?? ''}
        onChange={event => {
          onChange(event.target.value);
        }}
      />
    </Flex>
  );
};
