// @flow

import * as React from 'react';

import {
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Image } from '@realadvisor/image';
import Downshift from 'downshift';
import { graphql } from 'react-relay';
import { Flex } from 'react-system';

import { InputPopup } from '../controls/popup';
import { useLocale } from '../hooks/locale';
import { useInputQuery } from '../hooks/relay';
import { useTheme } from '../hooks/theme';
import { Business } from '../icons/business';
import { Cancel } from '../icons/cancel';

import type {
  DevelopmentsFilters,
  developmentInputQuery,
} from './__generated__/developmentInputQuery.graphql';

export type Development = $ReadOnly<{
  id: string,
  title: ?string,
  deliveryDate: ?string,
  primaryDevelopmentImage: ?$ReadOnly<{
    image: $ReadOnly<{
      url: ?string,
      ...
    }>,
    ...
  }>,
  ...
}>;

type Props = {|
  filters?: DevelopmentsFilters,
  value: ?Development,
  onChange: (?Development) => void,
  onBlur?: () => void,
|};

graphql`
  fragment developmentInput_development on Development {
    id
    title
    deliveryDate
    primaryDevelopmentImage {
      image {
        url
      }
    }
  }
`;

const getDevelopmentName = development => development?.title ?? '';

const getItems = data =>
  (data?.developments.edges ?? []).map(edge => edge?.node).filter(Boolean);

const DevelopmentAvatar = ({ development, size }) => {
  const { colors } = useTheme();
  return (
    <Flex css={{ width: size, height: size, flexShrink: 0 }} mr={2}>
      {development?.primaryDevelopmentImage?.image.url == null ? (
        <Business size={size} fill={colors.grey400} />
      ) : (
        <Image
          objectFit="cover"
          options={{ w: 40, h: 40, c: 'fill' }}
          src={development.primaryDevelopmentImage.image.url}
          css={{ borderRadius: '50%' }}
        />
      )}
    </Flex>
  );
};

const DevelopmentMenu = ({ targetRef, items, downshift }) => {
  const { t } = useLocale();
  const { isOpen, highlightedIndex, getMenuProps, getItemProps } = downshift;
  return (
    <InputPopup referenceRef={targetRef} open={isOpen}>
      <List {...getMenuProps()}>
        {items.length === 0 && (
          <ListItem disabled={true}>{t('No options')}</ListItem>
        )}
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              {...getItemProps({ item })}
              key={item.id}
              button={true}
              selected={highlightedIndex === index}
            >
              <DevelopmentAvatar development={item} size={40} />
              <ListItemText
                primary={getDevelopmentName(item)}
                secondary={item?.deliveryDate}
              />
            </ListItem>
          ))}
      </List>
    </InputPopup>
  );
};

export const DevelopmentInput = (props: Props): React.Node => {
  const [data, refetch] = useInputQuery<developmentInputQuery>(
    graphql`
      query developmentInputQuery($filters: DevelopmentsFilters) {
        developments(first: 20, filters: $filters) {
          edges {
            node {
              ...developmentInput_development @relay(mask: false)
            }
          }
        }
      }
    `,
  );
  const { filters } = props;
  const targetRef = React.useRef(null);
  return (
    <Downshift
      // make selectedItem always controlled
      selectedItem={props.value ?? null}
      itemToString={getDevelopmentName}
      onInputValueChange={inputValue =>
        refetch({ filters: { ...filters, search: inputValue } })
      }
      onChange={selectedItem => {
        props.onChange(selectedItem);
      }}
    >
      {downshift => {
        const { getInputProps, inputValue, selectedItem } = downshift;
        const { openMenu } = downshift;
        return (
          <div>
            <FilledInput
              {...getInputProps({
                ref: targetRef,
                onFocus: () => {
                  refetch({ filters: { ...filters, search: inputValue } });
                  openMenu();
                },
                onBlur: props.onBlur,
                startAdornment: selectedItem != null && (
                  <InputAdornment position="start">
                    <DevelopmentAvatar development={selectedItem} size={24} />
                  </InputAdornment>
                ),
                endAdornment: selectedItem && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => props.onChange(null)}>
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
              })}
            />
            <DevelopmentMenu
              targetRef={targetRef}
              items={getItems(data)}
              downshift={downshift}
            />
          </div>
        );
      }}
    </Downshift>
  );
};
