import type { Translate } from '../../src/hooks/locale';
import {
  type Currency,
  type IntlLocale,
  getCurrencyByCountryCode,
} from '../../src/locale';
import { formatPrice } from '../../src/utils/format-price';
import type {
  Cooling_Enum_Enum,
  Heating_Distribution_Enum_Enum,
  Heating_Enum_Enum,
  ListingPriceFragment,
  Properties,
} from '../__generated__/graphql';
import { Property_Side_Enum_Enum } from '../__generated__/graphql';

export const getOfferTypeOptions = (t: Translate) => [
  { id: 'rent', label: t('rent') },
  { id: 'sell', label: t('sell') },
];

export const getPriceUnitOptions = (
  t: Translate,
  offerType:
    | ReturnType<typeof getOfferTypeOptions>[number]['id']
    | null
    | undefined,
) =>
  offerType === 'rent'
    ? [
        { value: 'monthly', label: t('monthly') },
        { value: 'yearly', label: t('yearly') },
        { value: 'weekly', label: t('weekly') },
        { value: 'daily', label: t('daily') },
        { value: 'm2yearly', label: t('yearlyPerMeterSquared') },
      ]
    : [
        { value: 'sell', label: t('totalPrice') },
        { value: 'sellm2', label: t('pricePerMeterSquared') },
      ];

export const getListingPrices = (
  lot: ListingPriceFragment,
  t: Translate,
  locale: IntlLocale,
): {
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  appraisalDiff?: null | number;
} => {
  const {
    offer_type,
    sale_price,
    price_unit,
    rent_net,
    rent_extra,
    currency,
    property,
  } = lot;

  const currencyToFormat = (currency ??
    getCurrencyByCountryCode(property.country_code ?? 'CH')) as Currency;

  const localeToUseForPrice = currencyToFormat === 'CHF' ? 'de-CH' : locale;

  const formatNumber = (price: number) =>
    price.toLocaleString(localeToUseForPrice, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'decimal',
    });

  const surface = property.computed_surface;

  if (offer_type === 'sell') {
    if (sale_price == null) {
      return {};
    }

    if (price_unit === 'sell') {
      const appraisal = property.latest_appraisal?.value;

      return {
        primary: formatPrice(sale_price, locale, currencyToFormat),
        secondary:
          surface &&
          t('{{price}} / m²', {
            price: formatPrice(sale_price / surface, locale, currencyToFormat),
          }),
        appraisalDiff:
          appraisal != null && appraisal !== 0
            ? ((sale_price - appraisal) / appraisal) * 100
            : null,
      };
    }

    if (price_unit === 'sellm2') {
      const appraisal =
        property.latest_appraisal?.price_per_living_surface_meter_squared;
      return {
        primary: t('{{price}} / m²', {
          price: formatPrice(sale_price, locale, currencyToFormat),
        }),
        appraisalDiff:
          appraisal != null && appraisal !== 0
            ? ((sale_price - appraisal) / appraisal) * 100
            : null,
      };
    }

    // in case a new price_unit was added
    return { primary: formatPrice(sale_price, locale, currencyToFormat) };
  }

  if (offer_type === 'rent') {
    if (rent_net == null) {
      return {};
    }

    const extra = (multiplier: number) =>
      rent_extra == null
        ? []
        : [' (+ ', formatNumber(rent_extra * multiplier), ')'];

    const secondary = (multiplier: number) =>
      surface && [
        t('{{price}} / m² / {{unit}}', {
          price: formatPrice(
            (rent_net * multiplier) / surface,
            locale,
            currencyToFormat,
          ),
          unit: t('year'),
        }),
        ...extra(multiplier),
      ];

    if (price_unit === 'daily') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('day'),
          ...extra(1),
        ],
        secondary: secondary(365),
      };
    }

    if (price_unit === 'monthly') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('month'),
          ...extra(1),
        ],
        secondary: secondary(12),
      };
    }

    if (price_unit === 'weekly') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('week'),
          ...extra(1),
        ],
        secondary: secondary(365 / 7),
      };
    }

    if (price_unit === 'yearly') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('year'),
          ...extra(1),
        ],
        secondary: secondary(1),
      };
    }

    if (price_unit === 'm2yearly') {
      return {
        primary: [
          t('{{price}} / m² / {{unit}}', {
            price: formatPrice(rent_net, locale, currencyToFormat),
            unit: t('year'),
          }),
          ...extra(1),
        ],
      };
    }

    // in case a new price_unit was added
    return {
      primary: [formatPrice(rent_net, locale, currencyToFormat), ...extra(1)],
    };
  }

  return {};
};

export const getPropertyHeatingLabel = (
  heatingSlug: Heating_Enum_Enum | undefined | null,
  t: Translate,
) => {
  switch (heatingSlug) {
    case 'none':
      return t('None');
    case 'central':
      return t('centralHeating');
    case 'district':
      return t('districtHeating');
    case 'oil':
      return t('heatingOil');
    case 'gas':
      return t('gas');
    case 'electric':
      return t('electric');
    case 'wood':
      return t('wood');
    case 'solar':
      return t('solarCollector');
    case 'heat_pump':
      return t('heatPump');
    case 'other':
      return t('Other');
    default:
      return null;
  }
};

export const getPropertyHeatingDistributionLabel = (
  heatingDistributionSlug: Heating_Distribution_Enum_Enum | undefined | null,
  t: Translate,
) => {
  switch (heatingDistributionSlug) {
    case 'radiators':
      return t('radiators');
    case 'floor':
      return t('floorHeating');
    case 'forced_air':
      return t('forcedAir');
    case 'stove':
      return t('stove');
    case 'other':
      return t('Other');
    default:
      return null;
  }
};

export const getPropertyCoolingLabel = (
  coolingSlug: Cooling_Enum_Enum | undefined | null,
  t: Translate,
) => {
  switch (coolingSlug) {
    case 'none':
      return t('None');
    case 'air_conditioning':
      return t('airConditioning');
    case 'ceiling_fans':
      return t('ceilingFans');
    default:
      return null;
  }
};

const getPropertyOrientationsMap = (t: Translate) =>
  new Map([
    ['N', t('northShort')],
    ['NE', t('northEastShort')],
    ['E', t('eastShort')],
    ['SE', t('southEastShort')],
    ['S', t('southShort')],
    ['SW', t('southWestShort')],
    ['W', t('westShort')],
    ['NW', t('northWestShort')],
  ]);

export const getPropertyOrientations = (t: Translate) =>
  [...getPropertyOrientationsMap(t).entries()].map(([value, label]) => ({
    value,
    label,
  }));

export const getOrientationTranslation = (
  t: Translate,
  orientation: string | null | undefined,
) =>
  orientation == null
    ? '-'
    : getPropertyOrientationsMap(t).get(orientation) ?? '-';

export const extractPropertyOrientations = (
  property: Pick<
    Properties,
    | 'is_oriented_north'
    | 'is_oriented_south'
    | 'is_oriented_east'
    | 'is_oriented_west'
  >,
) => {
  const orientations = [];
  if (property.is_oriented_north) {
    orientations.push('N');

    if (property.is_oriented_east) {
      orientations.push('NE');
    }

    if (property.is_oriented_west) {
      orientations.push('NW');
    }
  }

  if (property.is_oriented_south) {
    orientations.push('S');

    if (property.is_oriented_east) {
      orientations.push('SE');
    }

    if (property.is_oriented_west) {
      orientations.push('SW');
    }
  }

  if (property.is_oriented_east) {
    orientations.push('E');
  }

  if (property.is_oriented_west) {
    orientations.push('W');
  }

  return orientations;
};

export const getPropertySides = (
  t: Translate,
): { value: Property_Side_Enum_Enum; label: string }[] => [
  { value: Property_Side_Enum_Enum.Exterior, label: t('exterior') },
  { value: Property_Side_Enum_Enum.Interior, label: t('interior') },
];

export const getEnergyClassValues = (t: Translate) => [
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' },
  { value: 'd', label: 'D' },
  { value: 'e', label: 'E' },
  { value: 'f', label: 'F' },
  { value: 'g', label: 'G' },
  { value: 'not_set', label: t('Not set') },
];
