import { useCallback } from 'react';

import {
  type LeadSelectFragment,
  type Leads_Bool_Exp,
  Order_By,
} from '../../../__generated__/graphql';
import {
  type FormDefinitionType,
  RaForm,
} from '../../../components/form/RaForm';
import { useAppData } from '../../../providers/AppDataProvider';

type CreateCMAFromLeadFormData = {
  lead: LeadSelectFragment;
};

type CreateCMAFromLeadProps = {
  onClose: () => void;
  handleCreateCMA: (leadId: string, contactId?: string) => Promise<void>;
};

const CreateCMAFromLead = ({
  onClose,
  handleCreateCMA,
}: CreateCMAFromLeadProps) => {
  const { me } = useAppData();

  const fromLeadFormDefinition: FormDefinitionType<CreateCMAFromLeadFormData> =
    useCallback(
      ({ t }) => {
        const isTeamLeader = !!me?.user_ids_in_led_teams.length;

        return [
          {
            label: t('lead'),
            type: 'lead',
            name: 'lead',
            placeholder: t('Search lead by user or address'),
            instantQuery: true,
            autoFocus: true,
            customWhereClause: (searchValue: string = '') => {
              const trimmedSearch = searchValue.replace(/\s+/g, ' ').trim();
              const searchCondition: Leads_Bool_Exp = {
                _or: [
                  { contact: { full_name: { _ilike: `%${trimmedSearch}%` } } },
                  { property: { route: { _ilike: `%${trimmedSearch}%` } } },
                  { property: { locality: { _ilike: `%${trimmedSearch}%` } } },
                ],
              };

              const whereClause: Leads_Bool_Exp = {
                _and: [],
              };

              if (trimmedSearch !== '') {
                whereClause._and?.push(searchCondition);
              }

              if (me?.is_admin) {
                return whereClause;
              }

              // Only include claimed leads
              whereClause._and?.push({ claimed_by: { _is_null: false } });

              // Add team-specific or broker-specific conditions
              if (isTeamLeader) {
                // For team leaders, include leads from all team members
                whereClause._and?.push({
                  broker_id: {
                    _in: me?.user_ids_in_led_teams ?? [],
                  },
                });
              } else if (me?.is_broker) {
                // For individual brokers, only include their own leads
                whereClause._and?.push({ broker_id: { _eq: me.id } });
              }

              return whereClause;
            },
            orderBy: { requalified_or_created_at: Order_By.Desc },
          },
        ];
      },
      [me],
    );

  const handleSubmit = useCallback(
    async (formData: CreateCMAFromLeadFormData) => {
      await handleCreateCMA(formData.lead.id, formData.lead.contact?.id);
    },
    [handleCreateCMA],
  );

  return (
    <RaForm
      onCancel={onClose}
      formDefinition={fromLeadFormDefinition}
      onSubmit={handleSubmit}
    />
  );
};

export default CreateCMAFromLead;
