import { forwardRef } from 'react';

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  type InputBaseProps,
  OutlinedInput,
} from '@mui/material';
import { type Control, Controller } from 'react-hook-form';
import { IMaskInput, type IMaskInputProps } from 'react-imask';

import { useLocale } from '../../../src/hooks/locale';
import { getDecimalSymbol } from '../../../src/utils/number-format';
import { REALADVISOR_ES_TENANT_ID } from '../../../src/utils/tenant';
import { useAppData } from '../../providers/AppDataProvider';

import { RaLabel } from './RaLabel';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min?: number;
  max?: number;
  decimalnumbers?: number;
  disableFormatting?: boolean;
}

const DOT_SEPARATOR = '.';
const DEFAULT_SEPARATOR = `'`;
const COMMA_SEPARATOR = ',';

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  ({ disableFormatting = false, ...props }, ref) => {
    const { intlLocale } = useLocale();
    const { me } = useAppData();
    const { onChange, min, max, ...other } = props;

    const separator = [REALADVISOR_ES_TENANT_ID].includes(me?.tenant_id ?? '')
      ? DOT_SEPARATOR
      : DEFAULT_SEPARATOR;

    const radixSymbol = [REALADVISOR_ES_TENANT_ID].includes(me?.tenant_id ?? '')
      ? COMMA_SEPARATOR
      : getDecimalSymbol(intlLocale).decimalSymbol;

    const maskProps: IMaskInputProps<HTMLInputElement> = {
      mask: Number,
      scale: props.decimalnumbers ?? 0,
      thousandsSeparator: disableFormatting === true ? '' : separator,
      radix: radixSymbol,
    };

    if (min != null) {
      maskProps.min = min;
    }
    if (max != null) {
      maskProps.max = max;
    }

    return (
      <IMaskInput
        {...other}
        {...maskProps}
        inputRef={ref}
        unmask={true}
        onAccept={(value: string) => {
          // TODO: fix this properly. parseFloat(-0) return 0.
          // temporary fix
          if (value === '-0') {
            onChange({ target: { name: props.name, value: '-' } });
          } else {
            onChange({ target: { name: props.name, value } });
          }
        }}
        overwrite
        inputMode="numeric"
      />
    );
  },
);

export type RaNumberProps = Omit<
  InputBaseProps,
  | 'name'
  | 'autoComplete'
  | 'size'
  | 'fullWidth'
  | 'sx'
  | 'minRows'
  | 'value'
  | 'onChange'
> & {
  control: Control<any>;
  name: string;
  errors: any;
  label: string;
  min?: number;
  max?: number;
  helpText?: string;
  decimalNumbers?: number;
  suffix?: string;
  prefix?: string;
  disableFormatting?: boolean;
};

export const RaNumber: React.FC<RaNumberProps> = ({
  control,
  name,
  label,
  required = false,
  multiline = false,
  min,
  max,
  helpText,
  decimalNumbers,
  suffix,
  prefix,
  errors,
  disableFormatting = false,
  ...inputProps
}) => {
  const { t } = useLocale();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? t('This field is required') : undefined }}
      render={({
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error || errors[name]}>
          <RaLabel label={label} required={required} />
          <OutlinedInput
            autoComplete="off"
            size="small"
            fullWidth
            sx={{
              background: 'white',
              '& .MuiInputAdornment-positionStart': {
                marginTop: '0px !important',
              },
            }}
            multiline={multiline}
            minRows={multiline ? 3 : undefined}
            inputComponent={TextMaskCustom as any}
            inputProps={{
              min,
              max,
              decimalnumbers: decimalNumbers,
              disableFormatting,
            }}
            endAdornment={
              suffix ? (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ) : null
            }
            startAdornment={
              prefix ? (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ) : null
            }
            value={value?.toString() ?? ''}
            name={name}
            onBlur={onBlur}
            onChange={event => {
              let parsedValue = null;
              if (event.target.value != null) {
                const inputValue = event.target.value;

                // Keep the minus sign and decimal point as is
                if (inputValue === '-' || inputValue.endsWith('.')) {
                  onChange({ target: { name, value: inputValue } });
                  return;
                }

                // Remove thousand separators before parsing
                const cleanValue = inputValue.replace(/[^0-9.-]/g, '');
                const parsed = parseFloat(cleanValue);
                if (!Number.isNaN(parsed)) {
                  parsedValue = parsed;
                }
              }

              if (value !== parsedValue) {
                onChange({
                  target: { name, value: parsedValue },
                });
              }
            }}
            ref={ref}
            {...inputProps}
          />
          <FormHelperText>
            {error?.message ?? errors[name]?.message ?? helpText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
