import { Suspense } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, Box, Button, Paper, Stack, Typography } from '@mui/material';
import { type GridRowsProp } from '@mui/x-data-grid-premium';
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import { type GetListingOffersQuery } from '../../__generated__/graphql';
import { OffersTable } from '../../components/offers/OffersTable';
import { OfferDrawer } from '../offers/OfferDrawer';

export const GET_LISTING_OFFERS_QUERY = gql(/* GraphQL */ `
  query GetListingOffers($listingId: uuid!) {
    offers(
      where: { lot_id: { _eq: $listingId } }
      order_by: { made_on: desc }
    ) {
      id
      amount
      valid_until
      lot {
        id
        currency
        broker {
          ...UserCard_user
        }
      }
      status
      offer_refused_type {
        id
        label
      }
      made_on
      comments
      buyer {
        ...UserCard_user
      }
      created_by
      ...ChangeOfferStatusModal
    }
  }
`);

type OfferRow = GetListingOffersQuery['offers'][number];

export const ListingOffers: React.FC = () => {
  const { lotId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useLocale();

  const { data, loading, error } = useQuery(GET_LISTING_OFFERS_QUERY, {
    skip: lotId == null,
    variables: { listingId: lotId ?? '' },
  });

  if (error != null) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  const rows: GridRowsProp<OfferRow> = data?.offers ?? [];

  return (
    <>
      <Stack
        spacing={2}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        p={2}
      >
        <Typography variant="h6">
          {t('Offers ({{offersCount}})', {
            offersCount: rows.length ?? 0,
          })}
        </Typography>

        <Button
          variant="contained"
          component={Link}
          to={{
            pathname: 'new',
            search: searchParams.toString(),
          }}
          disableElevation
        >
          {t('New')}
        </Button>
      </Stack>
      <Box px={{ sm: 0, md: 2 }}>
        <Paper
          variant="outlined"
          sx={theme => ({
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: { borderRadius: 0 },
          })}
        >
          <OffersTable
            rows={rows}
            isLoading={loading}
            fromListing
            onRowClick={({ id }) => {
              navigate({
                pathname: `./${id}`,
                search: searchParams.toString(),
              });
            }}
          />
        </Paper>
      </Box>
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="new"
            element={
              <OfferDrawer refetchQueries={[GET_LISTING_OFFERS_QUERY]} />
            }
          />
          <Route path=":offerId" element={<OfferDrawer />} />
        </Routes>
      </Suspense>
    </>
  );
};
