import { useCallback, useMemo } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import { Skeleton, Stack } from '@mui/material';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../src/locale';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';
import { getCurrencySymbol } from '../../utils/formatting';

import { STEP_CAP_RATE_VALUATION_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

type StepCapRateValuationForm = {
  caprate_estimated_monthly_rent: number | null | undefined;
  caprate_minimum_required_yield: number | null | undefined;
};

const StepCapRateValuation = ({
  cmaReportId,
  ...props
}: CMAReportComponentProps) => {
  const { locale, countryCode } = useLocale();
  const { data, complete } = useApolloFragment({
    fragment: STEP_CAP_RATE_VALUATION_FRAGMENT,
    fragmentName: 'StepCapRateValuation',
    from: { __typename: 'cma_reports', id: cmaReportId },
  });

  const latestAppraisal = data?.lead?.property?.latest_appraisal;

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-cap-rate-valuation',
  );

  const update = useCallback(
    async (formData?: Partial<StepCapRateValuationForm>) =>
      await updateCmaReport({
        caprate_estimated_monthly_rent:
          formData?.caprate_estimated_monthly_rent,
        caprate_minimum_required_yield:
          formData?.caprate_minimum_required_yield,
      }),
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepCapRateValuationForm> = useCallback(
    async formData => debouncedUpdate(formData),
    [debouncedUpdate],
  );

  const stepCapRateValuationFormDefinition = useCallback<
    FormDefinitionType<StepCapRateValuationForm>
  >(
    ({ t }) => [
      {
        name: 'caprate_estimated_monthly_rent',
        label: t('estimatedMonthlyRent'),
        gridProps: { md: 12 },
        type: 'number',
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        min: 0,
        max: 999_999_999,
      },
      {
        name: 'caprate_minimum_required_yield',
        label: t('minimumRequiredYield'),
        type: 'number',
        min: 0,
        max: 100,
        suffix: '%',
        decimalNumbers: 2,
        gridProps: { md: 12 },
      },
    ],
    [countryCode, locale],
  );

  const defaultValues = useMemo(() => {
    const monthlyRent =
      data?.caprate_estimated_monthly_rent ??
      data?.lead?.property?.latest_appraisal?.rent_value;
    const calculatedYield =
      latestAppraisal &&
      ((12 * (latestAppraisal.rent_value ?? 0)) /
        (latestAppraisal.value ?? 0)) *
        100;

    return {
      caprate_estimated_monthly_rent: monthlyRent,
      caprate_minimum_required_yield:
        data?.caprate_minimum_required_yield ?? calculatedYield ?? null,
    };
  }, [data, latestAppraisal]);

  const onSubmit = async (formData: StepCapRateValuationForm) => {
    await update(formData);
    props.setStep(props.step + 1);
  };

  if (!complete) {
    return (
      <Stack gap={4}>
        <Stack gap={1}>
          <Skeleton variant="rounded" width={'30%'} height={30} />
          <Skeleton variant="rounded" width={'50%'} height={40} />
        </Stack>
        <Stack gap={2}>
          <Skeleton variant="rounded" height={20} />
          <Stack direction={'row'}>
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{ mr: 2 }}
            />
            <Skeleton variant="rounded" width={'30%'} height={24} />
          </Stack>
          <Stack gap={3}>
            {Array.from({ length: 2 }).map((_, index) => (
              <Skeleton
                variant="rounded"
                key={`caprate-${index}`}
                height={50}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <RaForm
      freezeInitialDefaultValues={true}
      formDefinition={stepCapRateValuationFormDefinition}
      defaultValues={defaultValues}
      onChange={onChangeHandler}
      onSubmit={onSubmit}
      contentScrollable
      actionButtonsComponent={<FooterActions {...props} updating={updating} />}
    />
  );
};

export default StepCapRateValuation;
