import { forwardRef, useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, AlertTitle, Dialog, DialogTitle, Slide } from '@mui/material';
import { type TransitionProps } from '@mui/material/transitions';
import {
  type DeepPartial,
  type ErrorOption,
  type FieldPath,
} from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { type Currency, getCurrencyByCountryCode } from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import { gql } from '../../__generated__';
import {
  type ChangeOfferStatusModalFragment,
  Offers_Status_Enum,
  type UpdateOfferMutation,
} from '../../__generated__/graphql';
import {
  GET_OFFER_REFUSED_TYPES_QUERY,
  UPDATE_OFFER_MUTATION,
} from '../../pages/offers/offersQueries';
import { getCurrencySymbol } from '../../utils/formatting';
import { type FormDefinitionType, RaForm } from '../form/RaForm';

export const CHANGE_OFFER_STATUS_MODAL_FRAGMENT = gql(/* GraphQL */ `
  fragment ChangeOfferStatusModal on offers {
    id
    amount
    lot {
      id
      currency
      property {
        id
        country_code
      }
    }
  }
`);

type ChangeStatusModalProps = {
  offer: ChangeOfferStatusModalFragment | null;
  targetStatus?: Offers_Status_Enum.Accepted | Offers_Status_Enum.Refused;
  open: boolean;
  onClose: (updateData?: UpdateOfferMutation['update_offers_by_pk']) => void;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type RefusedFormData = {
  comments: string | null;
  offer_refused_type: string | null;
};

type AcceptedFormData = {
  comments: string | null;
  amount: number;
};

export const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
  targetStatus,
  open,
  onClose,
  offer,
}) => {
  const { t, countryCode, locale } = useLocale();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateOffer] = useMutation(UPDATE_OFFER_MUTATION, {
    onCompleted: data => {
      if (data.update_offers_by_pk != null) {
        onClose(data.update_offers_by_pk);
      }
    },
    onError: error => {
      setErrorMessage(error.message);
    },
  });
  const { data: refusedTypesData } = useQuery(GET_OFFER_REFUSED_TYPES_QUERY, {
    skip: targetStatus !== Offers_Status_Enum.Refused,
  });

  const currency = useMemo(
    () =>
      (offer?.lot.currency ??
        getCurrencyByCountryCode(
          offer?.lot.property.country_code ?? countryCode,
        )) as Currency,
    [countryCode, offer],
  );

  const refuseOffer = useCallback(
    (data: RefusedFormData) => {
      if (offer == null) {
        return Promise.resolve();
      }

      return updateOffer({
        variables: {
          id: offer.id,
          updates: {
            status: Offers_Status_Enum.Refused,
            comments: data.comments,
            offer_refused_id: data.offer_refused_type,
          },
        },
      });
    },
    [offer, updateOffer],
  );

  const acceptOffer = useCallback(
    (data: AcceptedFormData) => {
      if (offer == null) {
        return Promise.resolve();
      }

      return updateOffer({
        variables: {
          id: offer.id,
          updates: {
            status: Offers_Status_Enum.Accepted,
            comments: data.comments,
            amount: data.amount,
          },
        },
      });
    },
    [offer, updateOffer],
  );

  const formDefinition = useCallback<
    FormDefinitionType<RefusedFormData | AcceptedFormData>
  >(
    ({ t }) =>
      targetStatus === Offers_Status_Enum.Accepted
        ? [
            {
              type: 'number',
              required: true,
              name: 'amount',
              label: t('Amount'),
              gridProps: { md: 12 },
              prefix: getCurrencySymbol(currency, locale),
            },
            {
              type: 'text',
              name: 'comments',
              multiline: true,
              label: t('Comments'),
              gridProps: { md: 12 },
            },
          ]
        : [
            {
              type: 'select',
              name: 'offer_refused_type',
              required: true,
              label: t('Refused reason'),
              options: () =>
                refusedTypesData?.dictionaries.map(({ id, label }) => ({
                  value: id,
                  label: label ?? '',
                })) ?? [],
              gridProps: { md: 12 },
            },
            {
              type: 'text',
              name: 'comments',
              multiline: true,
              label: t('Comments'),
              gridProps: { md: 12 },
            },
          ],
    [targetStatus, currency, locale, refusedTypesData],
  );

  const defaultValues = useMemo<
    DeepPartial<RefusedFormData> | DeepPartial<AcceptedFormData>
  >(
    () =>
      targetStatus === Offers_Status_Enum.Accepted
        ? { amount: offer?.amount }
        : {},
    [targetStatus, offer],
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() => onClose()}
      TransitionComponent={Transition}
      sx={{
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
      }}
    >
      <DialogTitle>
        {targetStatus === Offers_Status_Enum.Accepted
          ? t('Mark offer as accepted')
          : t('Mark offer as refused')}
      </DialogTitle>

      <RaForm
        formDefinition={formDefinition}
        onCancel={() => onClose()}
        defaultValues={defaultValues}
        allowSubmitNotDirty
        validate={values => {
          const errors: [
            FieldPath<RefusedFormData | AcceptedFormData>,
            ErrorOption,
          ][] = [];

          if ('amount' in values && values.amount > 1_000_000_000) {
            errors.push([
              'amount',
              {
                type: 'max',
                message: t('The maximum value is {{value}}', {
                  value: formatPrice(1_000_000_000, locale, currency),
                }),
              },
            ]);
          }

          return errors;
        }}
        onSubmit={async data => {
          if ('amount' in data) {
            await acceptOffer(data);
          } else {
            await refuseOffer(data);
          }
        }}
      >
        {errorMessage != null && (
          <Alert severity="error" sx={{ m: 2 }}>
            <AlertTitle>
              {t('An error occured while updating the offer status.')}
            </AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </RaForm>
    </Dialog>
  );
};
