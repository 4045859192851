import { gql } from '@apollo/client';

import { CONTACT_CARD_USER_FRAGMENT } from '../../components/contact-card/ContactCard';
import { ACTIVITY_FIELDS } from '../../components/feed/feedQueries';
import { INVOICE_FRAGMENT } from '../subscriptions/subscriptionQueries';

export const GET_USERS_LIST = gql`
  query GetUsersList(
    $where: users_bool_exp!
    $order_by: [users_order_by!]!
    $limit: Int!
    $offset: Int!
  ) {
    users(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      created_at
      first_name
      last_name
      is_admin
      is_broker
      user_images(where: { is_primary: { _eq: true } }, limit: 1) {
        id
        image {
          url
        }
      }
      emails {
        primary
        email
      }
      phone_numbers {
        primary
        number
      }
    }
  }
`;

export const GET_USERS_LIST_COUNT = gql`
  query GetUsersListCount($where: users_bool_exp!, $limit: Int) {
    users_aggregate(where: $where, limit: $limit) {
      aggregate {
        count
      }
    }
  }
`;

export const USER_FEED = gql`
  ${ACTIVITY_FIELDS}
  query UserFeed($user_id: uuid!) {
    users_by_pk(id: $user_id) {
      leads {
        id
        activities(order_by: { created_at: desc }) {
          ...activityFields
        }
      }
    }
  }
`;

export const GET_USER = gql`
  ${CONTACT_CARD_USER_FRAGMENT}
  ${INVOICE_FRAGMENT}
  query GetUser($id: uuid!, $includeSubscriptions: Boolean!) {
    users_by_pk(id: $id) {
      id
      is_broker
      is_admin
      is_deleted
      full_name
      ...ContactCardUserFragment
      subscriptions(order_by: { created: desc })
        @include(if: $includeSubscriptions) {
        id
        total_amount
        status
        created
        current_period_start
        current_period_end
        invoices(order_by: { created: desc }) {
          id
          ...InvoiceFragment
        }
        invoices_aggregate {
          aggregate {
            sum {
              amount_paid
            }
          }
        }
      }
    }
  }
`;

export const AGENT_FEED = gql`
  ${ACTIVITY_FIELDS}
  query AgentFeed($user_id: uuid!) {
    activities(
      where: {
        _or: [
          { created_by: { _eq: $user_id } }
          { assigned_to: { _eq: $user_id } }
        ]
      }
      order_by: { updated_at: desc }
      limit: 250
    ) {
      ...activityFields
    }
  }
`;

const USER_SETTINGS_FRAGMENT = gql`
  fragment UserSettingsFragment on users {
    id
    created_at
    created_by
    first_name
    last_name
    is_broker
    has_free_crm_access
    agent_slug
    show_in_agency_pages
    linked_in_profile
    instagram_profile
    youtube_profile
    twitter_profile
    facebook_profile
    description
    services
    certifications
    languages
    active_since
    hires_on_realadvisor
  }
`;

export const GET_USER_SETTINGS = gql`
  ${USER_SETTINGS_FRAGMENT}
  query GetUserSettings($id: uuid!) {
    users_by_pk(id: $id) {
      ...UserSettingsFragment
    }
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  ${USER_SETTINGS_FRAGMENT}
  mutation UpdateUserSettings($id: uuid!, $user: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $user) {
      id
      ...UserSettingsFragment
    }
  }
`;

export const DELETE_USER_IMAGE = gql`
  mutation DeleteUserImage($id: uuid!) {
    delete_user_images_by_pk(id: $id) {
      image_id
    }
  }
`;

export const SET_USER_PROFILE_IMAGE = gql`
  mutation SetUserProfileImage($user_id: uuid!, $user_images_id: uuid!) {
    update_user_images(
      where: { user_id: { _eq: $user_id } }
      _set: { is_primary: false }
    ) {
      affected_rows
    }
    update_user_images_by_pk(
      pk_columns: { id: $user_images_id }
      _set: { is_primary: true }
    ) {
      id
      user {
        user_images(where: { is_primary: { _eq: true } }, limit: 1) {
          id
          image {
            id
            url
          }
        }
      }
    }
  }
`;

export const INSERT_USERS = gql`
  mutation InsertUsers($users: [users_insert_input!]!) {
    insert_users(
      objects: $users
      on_conflict: { constraint: users_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_USERS = gql`
  mutation DeleteUsers($ids: [uuid!]!) {
    delete_users(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export const EXPORT_USERS = gql`
  query ExportUsers($where: users_bool_exp!) {
    users(where: $where) {
      id
      first_name
      last_name
      emails {
        email
      }
      phone_numbers {
        number
      }
      created_at
    }
  }
`;
