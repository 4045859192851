import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import * as liveRegion from '@atlaskit/pragmatic-drag-and-drop-live-region';
import { Box, Stack } from '@mui/material';

export type KanbanColumnDefinition<
  Item,
  ColumnData extends Record<string, any>,
> = {
  id: string;
  data: ColumnData;
  extractItems: (items: Item[], columnId: string) => Item[];
  render: (args: {
    index: number;
    column: { data: ColumnData; id: string };
    items: Item[];
  }) => React.ReactNode;
};

type KanbanBoardProps<Item, ColumnData extends Record<string, any>> = {
  getItemId: (item: Item) => string;
  items: Item[];
  onItemMoved: (item: Item, columnId: string, newIndex: number) => void;
  columns: KanbanColumnDefinition<Item, ColumnData>[];
  disableDragging?: boolean;
  selectedItems?: string[];
  onItemsSelected?: (items: string[]) => void;
  instanceId?: string;
  getLiveRegionAnnouncements?: (
    operation:
      | {
          type: 'item-reorder';
          item: Item;
          column: ColumnData;
          startIndex: number;
          finishIndex: number;
        }
      | {
          type: 'item-move';
          item: Item;
          sourceColumn: ColumnData;
          destinationColumn: ColumnData;
          startIndex: number;
          finishIndex: number;
        },
  ) => string | null;
};

type Trigger = 'pointer' | 'keyboard';

type Outcome =
  | {
      type: 'item-reorder';
      columnId: string;
      startIndex: number;
      finishIndex: number;
    }
  | {
      type: 'item-move';
      startColumnId: string;
      finishColumnId: string;
      itemIndexInStartColumn: number;
      itemIndexInFinishColumn: number;
    };

type Operation = {
  trigger: Trigger;
  outcome: Outcome;
};

export type KanbanItemsContextType = {
  disabled: boolean;
  selectedItems?: string[];
  setSelectedItems?: (items: string[]) => void;
  instanceId: symbol | null;
};

export const KanbanItemsContext = createContext<KanbanItemsContextType>({
  disabled: false,
  instanceId: null,
});

export const KanbanInstanceContext = createContext<symbol | null>(null);

export const useKanbanState = () => {
  const { disabled, instanceId } = useContext(KanbanItemsContext);

  return { disabled, instanceId };
};

export const useKanbanInstanceId = () => {
  const instanceId = useContext(KanbanInstanceContext);

  return instanceId;
};

export const useKanbanSelectedItems = () => {
  const { selectedItems, setSelectedItems } = useContext(KanbanItemsContext);

  return { selectedItems, setSelectedItems };
};

const KanbanItemsContextProvider = (props: {
  disabled?: boolean;
  children: React.ReactNode;
  selectedItems?: string[];
  setSelectedItems?: (items: string[]) => void;
  instanceId: symbol;
}) => {
  const memoizedValue = useMemo(
    () => ({
      disabled: props.disabled ?? false,
      selectedItems: props.selectedItems,
      setSelectedItems: props.setSelectedItems,
      instanceId: props.instanceId,
    }),
    [
      props.disabled,
      props.selectedItems,
      props.setSelectedItems,
      props.instanceId,
    ],
  );
  return (
    <KanbanItemsContext.Provider value={memoizedValue}>
      {props.children}
    </KanbanItemsContext.Provider>
  );
};

const flatItemsToColumns = <Item, ColumnData extends Record<string, any>>(
  items: Item[],
  columns: KanbanColumnDefinition<Item, ColumnData>[],
) =>
  columns.reduce(
    (acc, column) => ({
      ...acc,
      [column.id]: column.extractItems(items, column.id),
    }),
    {} as Record<string, Item[]>,
  );

const Board: React.FC<{ children: React.ReactNode; instanceId: symbol }> = ({
  children,
  instanceId,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current == null) {
      return;
    }

    return autoScrollForElements({
      element: ref.current,
      canScroll: ({ source }) => source.data.instanceId === instanceId,
      getAllowedAxis: () => 'horizontal',
    });
  }, [instanceId]);

  return (
    <Box sx={{ width: '100%', overflow: 'auto', height: '100%' }} ref={ref}>
      <Stack direction="row" sx={{ height: '100%' }}>
        <KanbanInstanceContext.Provider value={instanceId}>
          {children}
        </KanbanInstanceContext.Provider>
      </Stack>
    </Box>
  );
};

export const KanbanBoard = <Item, ColumnData extends Record<string, any>>({
  getItemId,
  items: itemsList,
  onItemMoved,
  columns,
  disableDragging = false,
  selectedItems,
  onItemsSelected,
  instanceId = 'kboad-instance',
  getLiveRegionAnnouncements,
}: KanbanBoardProps<Item, ColumnData>) => {
  const [boardId] = useState(() => Symbol(instanceId));
  const [kanbanState, setKanbanState] = useState<{
    columns: Record<string, Item[]>;
    lastOperation: Operation | null;
  }>({ columns: flatItemsToColumns(itemsList, columns), lastOperation: null });
  const { lastOperation, columns: items } = kanbanState;
  const stableItems = useRef(items);

  useEffect(() => {
    setKanbanState(prevState => ({
      ...prevState,
      columns: flatItemsToColumns(itemsList, columns),
    }));
  }, [itemsList, columns]);

  useEffect(() => {
    stableItems.current = items;
  }, [items]);

  const renderedColumns = useMemo(
    () =>
      columns.map(({ render, data, id }, index) =>
        render({
          column: {
            data,
            id,
          },
          index,
          items: items[id],
        }),
      ),
    [columns, items],
  );

  const reorderItem = useCallback(
    ({
      columnId,
      startIndex,
      finishIndex,
      trigger = 'keyboard',
    }: {
      columnId: string;
      startIndex: number;
      finishIndex: number;
      trigger?: Trigger;
    }) => {
      setKanbanState(prevState => ({
        lastOperation: {
          trigger,
          outcome: {
            type: 'item-reorder',
            columnId,
            startIndex,
            finishIndex,
          },
        },
        columns: {
          ...prevState.columns,
          [columnId]: reorder({
            list: prevState.columns[columnId],
            startIndex,
            finishIndex,
          }),
        },
      }));
    },
    [],
  );

  const moveItem = useCallback(
    ({
      startColumnId,
      finishColumnId,
      itemIndexInStartColumn,
      itemIndexInFinishColumn,
      trigger = 'keyboard',
    }: {
      startColumnId: string;
      finishColumnId: string;
      itemIndexInStartColumn: number;
      itemIndexInFinishColumn?: number;
      trigger?: Trigger;
    }) => {
      // We do nothing
      if (startColumnId === finishColumnId) {
        return;
      }

      setKanbanState(prevState => {
        const sourceColumnItems = prevState.columns[startColumnId];
        const destinationColumnItems = prevState.columns[finishColumnId];

        const activeItem = sourceColumnItems[itemIndexInStartColumn];
        const activeId = getItemId(activeItem);
        const destinationIndex =
          itemIndexInFinishColumn ?? destinationColumnItems.length;

        const updatedDestinationColItems = Array.from(destinationColumnItems);
        updatedDestinationColItems.splice(destinationIndex, 0, activeItem);

        return {
          lastOperation: {
            trigger,
            outcome: {
              type: 'item-move',
              startColumnId,
              finishColumnId,
              itemIndexInStartColumn,
              itemIndexInFinishColumn: destinationIndex,
            },
          },
          columns: {
            ...prevState.columns,
            [startColumnId]: sourceColumnItems.filter(
              item => getItemId(item) !== activeId,
            ),
            [finishColumnId]: updatedDestinationColItems,
          },
        };
      });
    },
    [getItemId],
  );

  useEffect(() => {
    if (lastOperation == null || getLiveRegionAnnouncements == null) {
      return;
    }

    const { outcome, trigger } = lastOperation;

    if (outcome.type === 'item-reorder') {
      const { columnId, startIndex, finishIndex } = outcome;

      const itemsByColumn = stableItems.current;
      const column = columns.find(col => col.id === columnId);
      const item = itemsByColumn[columnId][finishIndex];

      if (trigger !== 'keyboard' || column == null) {
        return;
      }

      const announcement = getLiveRegionAnnouncements({
        type: 'item-reorder',
        item,
        column: column.data,
        startIndex,
        finishIndex,
      });

      if (announcement != null) {
        liveRegion.announce(announcement);
      }

      return;
    }

    if (outcome.type === 'item-move') {
      const {
        startColumnId,
        finishColumnId,
        itemIndexInStartColumn,
        itemIndexInFinishColumn,
      } = outcome;

      const itemsByColumn = stableItems.current;
      const destinationColumn = columns.find(col => col.id === finishColumnId);
      const startColumn = columns.find(col => col.id === startColumnId);
      const item = itemsByColumn[finishColumnId][itemIndexInFinishColumn];

      const finishPosition =
        typeof itemIndexInFinishColumn === 'number'
          ? itemIndexInFinishColumn + 1
          : itemsByColumn[finishColumnId].length;

      if (
        trigger !== 'keyboard' ||
        destinationColumn == null ||
        startColumn == null
      ) {
        return;
      }

      const announcement = getLiveRegionAnnouncements({
        type: 'item-move',
        item,
        sourceColumn: startColumn.data,
        destinationColumn: destinationColumn.data,
        startIndex: itemIndexInStartColumn,
        finishIndex: finishPosition,
      });

      if (announcement != null) {
        liveRegion.announce(announcement);
      }
      return;
    }
  }, [lastOperation, columns, getLiveRegionAnnouncements]);

  useEffect(() => {
    return combine(
      monitorForElements({
        canMonitor({ source }) {
          return source.data.instanceId === boardId;
        },
        onDrop(args) {
          const { location, source } = args;
          // didn't drop on anything
          if (!location.current.dropTargets.length) {
            return;
          }

          const itemId = source.data.itemId as string;
          const sourceId = source.data.columnId as string;
          const sourceColumn = items[sourceId];
          const itemIndex = sourceColumn.findIndex(
            item => getItemId(item) === itemId,
          );

          if (location.current.dropTargets.length === 1) {
            const [destinationColumnRecord] = location.current.dropTargets;
            const destinationId = destinationColumnRecord.data
              .columnId as string;

            // reordering in same column
            if (sourceId === destinationId) {
              const destinationIndex = getReorderDestinationIndex({
                startIndex: itemIndex,
                indexOfTarget: sourceColumn.length - 1,
                closestEdgeOfTarget: null,
                axis: 'vertical',
              });

              reorderItem({
                columnId: sourceId,
                startIndex: itemIndex,
                finishIndex: destinationIndex,
                trigger: 'pointer',
              });

              onItemMoved(
                source.data.content as Item,
                sourceId,
                destinationIndex,
              );
              return;
            }

            // moving to a new column
            moveItem({
              itemIndexInStartColumn: itemIndex,
              startColumnId: sourceId,
              finishColumnId: destinationId,
              trigger: 'pointer',
            });

            onItemMoved(
              source.data.content as Item,
              destinationId,
              items[destinationId].length,
            );
            return;
          }

          // dropping in a column (relative to a card)
          if (location.current.dropTargets.length === 2) {
            const [destinationCardRecord, destinationColumnRecord] =
              location.current.dropTargets;
            const destinationColumnId = destinationColumnRecord.data
              .columnId as string;
            const destinationColumn = items[destinationColumnId];

            const indexOfTarget = destinationColumn.findIndex(
              item => getItemId(item) === destinationCardRecord.data.itemId,
            );
            const closestEdgeOfTarget: Edge | null = extractClosestEdge(
              destinationCardRecord.data,
            );

            // case 1: ordering in the same column
            if (sourceId === destinationColumnId) {
              const destinationIndex = getReorderDestinationIndex({
                startIndex: itemIndex,
                indexOfTarget,
                closestEdgeOfTarget,
                axis: 'vertical',
              });
              reorderItem({
                columnId: sourceId,
                startIndex: itemIndex,
                finishIndex: destinationIndex,
                trigger: 'pointer',
              });

              onItemMoved(
                source.data.content as Item,
                sourceId,
                destinationIndex,
              );
              return;
            }

            // case 2: moving into a new column relative to a card
            const destinationIndex =
              closestEdgeOfTarget === 'bottom'
                ? indexOfTarget + 1
                : indexOfTarget;

            moveItem({
              itemIndexInStartColumn: itemIndex,
              startColumnId: sourceId,
              finishColumnId: destinationColumnId,
              itemIndexInFinishColumn: destinationIndex,
              trigger: 'pointer',
            });

            onItemMoved(
              source.data.content as Item,
              destinationColumnId,
              destinationIndex,
            );
          }
        },
      }),
    );
  }, [boardId, moveItem, reorderItem, items, getItemId, onItemMoved]);

  return (
    <Board instanceId={boardId}>
      <KanbanItemsContextProvider
        disabled={disableDragging}
        selectedItems={selectedItems}
        setSelectedItems={onItemsSelected}
        instanceId={boardId}
      >
        {renderedColumns}
      </KanbanItemsContextProvider>
    </Board>
  );
};
