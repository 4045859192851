import React, { useEffect, useReducer } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { GroupOutlined } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  ListItem,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ExpandMore } from '../../icons/expand-more';
import { useLocale } from '../../src/hooks/locale';
import { TopbarTitle } from '../../src/shared/topbar';
import { gql } from '../__generated__';
import { type GetSmartListsQuery } from '../__generated__/graphql';
import { useAppData } from '../providers/AppDataProvider';

import {
  AutocompletePaper,
  AutocompleteTextField,
} from './filters/quick-filters/QuickFilters';

const GET_SMART_LISTS = gql(/* GraphQL */ `
  query GetSmartLists($crmEntity: String!) {
    smart_lists(where: { crm_entity: { _eq: $crmEntity } }) {
      id
      created_at
      name
      where
      is_shared
      is_tenant_shared
      crm_entity
      user {
        id
        full_name
      }
    }
  }
`);
const refetchQueries = [GET_SMART_LISTS];

const UPDATE_SMART_LIST = gql(/* GraphQL */ `
  mutation UpdateSmartList(
    $id: uuid!
    $name: String!
    $is_shared: Boolean!
    $is_tenant_shared: Boolean!
  ) {
    update_smart_lists_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        is_shared: $is_shared
        is_tenant_shared: $is_tenant_shared
      }
    ) {
      id
      name
      is_shared
      is_tenant_shared
    }
  }
`);

const DELETE_SMART_LIST = gql(/* GraphQL */ `
  mutation DeleteSmartList($id: uuid!) {
    delete_smart_lists_by_pk(id: $id) {
      id
    }
  }
`);

// Update the INSERT_SMART_LIST mutation
const INSERT_SMART_LIST = gql(/* GraphQL */ `
  mutation InsertSmartList(
    $name: String!
    $where: jsonb!
    $crmEntity: String!
    $is_shared: Boolean!
    $is_tenant_shared: Boolean!
  ) {
    insert_smart_lists_one(
      object: {
        name: $name
        where: $where
        crm_entity: $crmEntity
        is_shared: $is_shared
        is_tenant_shared: $is_tenant_shared
      }
    ) {
      id
      name
      where
      is_shared
      is_tenant_shared
      user {
        id
        full_name
      }
    }
  }
`);

type SmartList = GetSmartListsQuery['smart_lists'][number];

interface SmartListTitleProps {
  tableName: string;
  defaultTitle: string;
}

interface EditSmartListDialogProps {
  smartList?: {
    where: Object;
    crm_entity: string;
    id?: string;
    created_at?: string;
    name?: string;
    is_shared?: boolean;
    is_tenant_shared?: boolean;
    user?: {
      id: string;
      full_name?: string | null;
    };
  };
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

interface SmartListFormData {
  name: string;
  visibility: 'private' | 'team' | 'tenant';
}

// Define the state interface
interface SmartListTitleState {
  title: string;
  anchorEl: HTMLElement | null;
  currentSmartList: SmartList | undefined;
  editingSmartList: SmartList | undefined;
}

// Define action types
type SmartListTitleAction =
  | { type: 'SET_TITLE'; payload: string }
  | { type: 'SET_ANCHOR_EL'; payload: HTMLElement | null }
  | { type: 'SET_CURRENT_SMART_LIST'; payload: SmartList | undefined }
  | { type: 'SET_EDITING_SMART_LIST'; payload: SmartList | undefined };

// Define the reducer function
function smartListTitleReducer(
  state: SmartListTitleState,
  action: SmartListTitleAction,
): SmartListTitleState {
  switch (action.type) {
    case 'SET_TITLE':
      return { ...state, title: action.payload };
    case 'SET_ANCHOR_EL':
      return { ...state, anchorEl: action.payload };
    case 'SET_CURRENT_SMART_LIST':
      return { ...state, currentSmartList: action.payload };
    case 'SET_EDITING_SMART_LIST':
      return { ...state, editingSmartList: action.payload };
    default:
      return state;
  }
}

export const EditSmartListDialog: React.FC<EditSmartListDialogProps> = ({
  smartList,
  open,
  onClose,
  onUpdate,
}) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const isAdmin = me?.is_admin;
  const { control, handleSubmit, reset } = useForm<SmartListFormData>({
    defaultValues: {
      name: smartList?.name || '',
      visibility: smartList?.is_tenant_shared
        ? 'tenant'
        : smartList?.is_shared
        ? 'team'
        : 'private',
    },
  });
  const [updateSmartList] = useMutation(UPDATE_SMART_LIST, { refetchQueries });
  const [insertSmartList] = useMutation(INSERT_SMART_LIST, { refetchQueries });
  const [deleteSmartList] = useMutation(DELETE_SMART_LIST, { refetchQueries });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    reset({
      name: smartList?.name || '',
      visibility: smartList?.is_tenant_shared
        ? 'tenant'
        : smartList?.is_shared
        ? 'team'
        : 'private',
    });
  }, [smartList, reset]);

  const onSubmit = async (data: SmartListFormData) => {
    const isTenantShared = data.visibility === 'tenant';
    const isTeamShared = data.visibility === 'team';

    if (typeof smartList?.id === 'string') {
      await updateSmartList({
        variables: {
          id: smartList.id,
          name: data.name,
          is_shared: isTeamShared,
          is_tenant_shared: isTenantShared,
        },
      });
      onUpdate();
    } else if (smartList?.crm_entity) {
      const { data: newSmartList } = await insertSmartList({
        variables: {
          ...smartList,
          crmEntity: smartList.crm_entity,
          name: data.name,
          where: JSON.stringify(smartList?.where),
          is_shared: isTeamShared,
          is_tenant_shared: isTenantShared,
        },
      });
      if (newSmartList?.insert_smart_lists_one) {
        await onUpdate();
        searchParams.set(
          'smart_list_id',
          newSmartList.insert_smart_lists_one.id,
        );
        navigate({
          search: searchParams.toString(),
        });
      }
    }
    onClose();
  };

  const handleDelete = async () => {
    if (smartList?.id) {
      await deleteSmartList({
        variables: { id: smartList.id },
      });

      // Clear smart_list_id and where parameters
      searchParams.delete('smart_list_id');
      searchParams.delete('where');
      navigate({
        search: searchParams.toString(),
      });

      await onUpdate();
      onClose();
    }
  };

  const dialogTitle = smartList?.id
    ? t('Edit Smart List')
    : t('Save Smart List');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-smart-list-dialog"
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
              {t('Name')}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <OutlinedInput {...field} autoFocus fullWidth size="small" />
              )}
            />
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">{t('Visibility')}</FormLabel>
              <Controller
                name="visibility"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="private"
                      control={<Radio />}
                      label={t('Save for me only')}
                    />
                    <FormControlLabel
                      value="team"
                      control={<Radio />}
                      label={t('Share with my team')}
                    />
                    {isAdmin && (
                      <FormControlLabel
                        value="tenant"
                        control={<Radio />}
                        label={t('Share with everyone')}
                      />
                    )}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </FormControl>
          {smartList?.created_at && (
            <Typography variant="subtitle2">
              {t('Created by {{name}} on {{date}}', {
                name: smartList.user?.full_name || 'unknown',
                date: new Date(smartList.created_at).toLocaleDateString(),
              })}
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
          {smartList?.id && (
            <IconButton onClick={handleDelete} color="error">
              <Delete />
            </IconButton>
          )}
          <Stack direction="row" spacing={1} sx={{ ml: 'auto' }}>
            <Button onClick={onClose}>{t('Cancel')}</Button>
            <Button type="submit" variant="contained" color="primary">
              {smartList ? t('Save') : t('Create')}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const SmartListTitle: React.FC<SmartListTitleProps> = ({
  tableName,
  defaultTitle,
}) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const isAdmin = me?.is_admin;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Initialize the reducer
  const [state, dispatch] = useReducer(smartListTitleReducer, {
    title: defaultTitle,
    anchorEl: null,
    currentSmartList: undefined,
    editingSmartList: undefined,
  });

  const { data: smartListsData, refetch } = useQuery(GET_SMART_LISTS, {
    variables: { crmEntity: tableName },
  });

  const whereParam = searchParams.get('where');

  useEffect(() => {
    if (!state.currentSmartList || !whereParam) {
      return;
    }
    try {
      const currentWhere = JSON.parse(whereParam);
      const smartListWhere = JSON.parse(state.currentSmartList.where);
      if (JSON.stringify(currentWhere) !== JSON.stringify(smartListWhere)) {
        dispatch({ type: 'SET_TITLE', payload: defaultTitle });
      }
    } catch (error) {
      console.error('Error parsing where parameter:', error);
    }
  }, [whereParam, state.currentSmartList, defaultTitle]);
  const smartLists = smartListsData?.smart_lists ?? [];

  const handleEditClick = (smartList: SmartList) => {
    dispatch({ type: 'SET_EDITING_SMART_LIST', payload: smartList });
  };

  // Add effect to handle smart_list_id parameter
  useEffect(() => {
    const smartListId = searchParams.get('smart_list_id');

    if (smartListId && smartListsData?.smart_lists) {
      const smartList = smartListsData.smart_lists.find(
        list => list.id === smartListId,
      );
      if (smartList) {
        dispatch({ type: 'SET_CURRENT_SMART_LIST', payload: smartList });
        dispatch({ type: 'SET_TITLE', payload: smartList.name });

        // Set the where parameter if it's not already set
        if (!searchParams.has('where')) {
          searchParams.set('where', smartList.where);
          navigate({
            search: searchParams.toString(),
          });
        }
      }
    } else {
      // Reset state when smart_list_id is removed
      dispatch({ type: 'SET_CURRENT_SMART_LIST', payload: undefined });
      dispatch({ type: 'SET_TITLE', payload: defaultTitle });
    }
  }, [smartListsData, searchParams, navigate, defaultTitle]);

  return (
    <TopbarTitle titleOverride={state.title}>
      <Button
        onClick={event =>
          dispatch({ type: 'SET_ANCHOR_EL', payload: event.currentTarget })
        }
        size="small"
        sx={{
          color: 'text.primary',
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: 16,
          gap: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '100%',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 'calc(100% - 24px)', // Adjust based on the icon size
          }}
        >
          {state.title}
        </Typography>
        <ExpandMore />
      </Button>
      <Popover
        open={Boolean(state.anchorEl)}
        anchorEl={state.anchorEl}
        onClose={() => dispatch({ type: 'SET_ANCHOR_EL', payload: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transitionDuration={0}
        slotProps={{
          paper: {
            style: {
              minWidth: 150,
              maxWidth: 400,
              width: 'calc(100vw - 30px)',
              background: 'transparent',
              boxShadow: 'none',
              overflow: 'visible',
              padding: '0 0 auto 0',
            },
          },
        }}
      >
        <Autocomplete
          options={smartLists}
          getOptionLabel={option => option.name}
          noOptionsText={t('No smart lists found')}
          renderInput={params => (
            <AutocompleteTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: t('Search smart lists...'),
              }}
            />
          )}
          PaperComponent={AutocompletePaper}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              <Typography
                noWrap
                variant="body2"
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  pr: 4,
                }}
              >
                {option.name}
              </Typography>
              {me?.id === option.user?.id || isAdmin ? (
                <IconButton
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    handleEditClick(option);
                  }}
                  sx={{ position: 'absolute', right: 8 }}
                >
                  <Edit />
                </IconButton>
              ) : (
                <GroupOutlined sx={{ position: 'absolute', right: 8 }} />
              )}
            </ListItem>
          )}
          onChange={(_e, option) => {
            if (!option) {
              return;
            }
            dispatch({ type: 'SET_CURRENT_SMART_LIST', payload: option });
            dispatch({ type: 'SET_TITLE', payload: option.name });
            dispatch({ type: 'SET_ANCHOR_EL', payload: null });

            // Update both where and smart_list_id parameters
            searchParams.set('where', option.where);
            searchParams.set('smart_list_id', option.id);
            navigate({
              search: searchParams.toString(),
            });
          }}
          size="small"
          sx={{ width: '100%' }}
          openOnFocus
          open={Boolean(state.anchorEl)}
          onClose={() => dispatch({ type: 'SET_ANCHOR_EL', payload: null })}
        />
      </Popover>
      <EditSmartListDialog
        open={Boolean(state.editingSmartList)}
        smartList={state.editingSmartList}
        onClose={() =>
          dispatch({ type: 'SET_EDITING_SMART_LIST', payload: undefined })
        }
        onUpdate={refetch}
      />
    </TopbarTitle>
  );
};
