// @flow

import * as React from 'react';

import {
  FormControl,
  Input,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { Box, useSystem } from 'react-system';

import { formatDate, parseDate } from '../../controls/date-input';
import { TimeInput } from '../../controls/time-input';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { GpsFixed } from '../../icons/gps-fixed';
import { Home } from '../../icons/home';
import { Person } from '../../icons/person';
import { Photo } from '../../icons/photo';
import { AddressInput } from '../../shared/address-input';
import { BuyerLeadInput } from '../../shared/buyer-lead-input';
import { LeadInput } from '../../shared/lead-input';
import { LotInput } from '../../shared/lot-input';
import { type User, UserInput } from '../../shared/user-input';
import { UserMultiInput } from '../../shared/user-input';

export const useNow = (): Date => {
  const nowRef = React.useRef(null);
  if (nowRef.current == null) {
    const now = new Date();
    nowRef.current = now;
    return now;
  }
  return nowRef.current;
};

type DateTimeValues = {
  startDate: string,
  dueAt: string,
  ...
};

const baseInputStyles = {
  minHeight: 48,
  borderRadius: 4,
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  paddingLeft: 12,
  paddingRight: 8,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 2,
      right: 2,
      bottom: 0,
      height: 1,
      backgroundColor: 'black',
    },
  },
};

export const InlineDateTime = ({
  values,
  setValues,
  errorText,
}: {|
  values: DateTimeValues,
  setValues: ($Shape<DateTimeValues>) => void,
  errorText: ?string,
|}): React.Node => {
  const { t, dateLocale } = useLocale();
  const { media } = useSystem();

  return (
    <div
      css={media({
        display: 'grid',
        gridColumnGap: 8,
        alignItems: 'center',
        gridTemplateColumns: '70% 1fr',
      })}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateLocale}
      >
        <DatePicker
          value={parseDate(values.startDate)}
          onChange={date => {
            setValues({ startDate: formatDate(date) });
          }}
          label={t('date')}
          slotProps={{
            textField: {
              error: errorText != null,
              helperText: errorText,
            },
          }}
        />
      </LocalizationProvider>
      <TimeInput
        // fallback invalid date with current date
        value={values.dueAt}
        onChange={time => setValues({ dueAt: time })}
      />
    </div>
  );
};

const InlineSelect = ({ helperText, ...props }: any): React.Node => {
  return (
    <Select
      css={{
        '& .MuiSelect-select': {
          padding: '12px',
        },
      }}
      fullWidth={true}
      disableUnderline={true}
      {...props}
    />
  );
};

const BaseContactUserInput = React.forwardRef((props, ref) => {
  const { t } = useLocale();
  return (
    <Input
      ref={ref}
      {...props}
      fullWidth={true}
      disableUnderline={true}
      css={baseInputStyles}
      placeholder={t('addContact')}
    />
  );
});

const BaseUserInput = React.forwardRef((props, ref) => {
  const { t } = useLocale();
  return (
    <Input
      ref={ref}
      {...props}
      fullWidth={true}
      disableUnderline={true}
      css={baseInputStyles}
      placeholder={t('selectUser')}
    />
  );
});

type ContactsValues = {
  targetUsers: $ReadOnlyArray<User>,
  ...
};

export const FieldContacts = ({
  error,
  helperText,
  values,
  setValues,
}: {|
  error?: boolean,
  helperText?: ?string,
  values: ContactsValues,
  setValues: ($Shape<ContactsValues>) => void,
|}): React.Node => {
  const { colors, text } = useTheme();
  return (
    <Box>
      <UserMultiInput
        Input={BaseContactUserInput}
        value={values.targetUsers}
        onChange={users => setValues({ targetUsers: users })}
      />
      {error === true && (
        <Box
          css={[
            text.caption,
            {
              color: colors.errorText,
              borderTop: '2px solid currentColor',
            },
          ]}
          px={2}
          py={1}
        >
          {helperText}
        </Box>
      )}
    </Box>
  );
};

type AssignedToValues = {
  assignedTo: ?User,
  ...
};

const BaseBrokerInput = React.forwardRef((props, ref) => {
  const { t } = useLocale();
  const { colors } = useTheme();

  return (
    <Input
      ref={ref}
      {...props}
      fullWidth={true}
      disableUnderline={true}
      css={{
        ...baseInputStyles,
        // Fix inconsistent placeholder color
        '.MuiInputBase-input::placeholder': {
          color: colors.grey600,
          opacity: 1,
        },
      }}
      placeholder={t('Assign to broker')}
    />
  );
});

export const FieldAssignedTo = ({
  values,
  setValues,
  error,
  helperText,
}: {
  error?: boolean,
  helperText?: ?string,
  values: AssignedToValues,
  setValues: ($Shape<AssignedToValues>) => void,
  ...
}): React.Node => {
  const { colors, text } = useTheme();
  return (
    <Box>
      <UserInput
        Input={BaseBrokerInput}
        creatable={false}
        filters={{ isBroker: true }}
        value={values.assignedTo}
        onChange={user => setValues({ assignedTo: user })}
      />
      {error === true && (
        <Box
          css={[
            text.caption,
            {
              color: colors.errorText,
              borderTop: '2px solid currentColor',
            },
          ]}
          px={2}
          py={1}
        >
          {helperText}
        </Box>
      )}
    </Box>
  );
};

const getAddress = values => {
  return [
    [values.route, values.streetNumber].filter(Boolean).join(' ').trim(),
    [values.postcode, values.locality].filter(Boolean).join(' ').trim(),
  ]
    .filter(Boolean)
    .join(', ');
};

type LocationValues = {
  location: ?string,
  ...
};

type VisibilityValues = {
  visibility: ?'private' | 'shared' | 'organisation',
  ...
};

export const FieldLocation = ({
  values,
  setValues,
}: {
  values: LocationValues,
  setValues: ($Shape<LocationValues>) => void,
  ...
}): React.Node => {
  const { t } = useLocale();

  return (
    <FormControl size="small" variant="filled">
      <InputLabel>{t('location')}</InputLabel>
      <AddressInput
        value={values.location ?? ''}
        onChange={value => setValues({ location: value })}
        onSelect={address => {
          if (address) {
            setValues({ location: getAddress(address) });
          }
        }}
      />
    </FormControl>
  );
};

type ParentValues = {
  parentType: string,
  parent: ?any,
  ...
};

const BaseLeadInput = React.forwardRef((props, ref) => {
  const { t } = useLocale();

  return (
    <Input
      ref={ref}
      {...props}
      fullWidth={true}
      disableUnderline={true}
      css={baseInputStyles}
      placeholder={t('selectLead')}
    />
  );
});

const BaseLotInput = React.forwardRef((props, ref) => {
  const { t } = useLocale();
  return (
    <Input
      ref={ref}
      {...props}
      fullWidth={true}
      disableUnderline={true}
      css={baseInputStyles}
      placeholder={t('selectLot')}
    />
  );
});

const BaseBuyerLeadInput = React.forwardRef((props, ref) => {
  const { t } = useLocale();
  return (
    <Input
      ref={ref}
      {...props}
      fullWidth={true}
      disableUnderline={true}
      css={baseInputStyles}
      placeholder={t('selectBuyerLead')}
    />
  );
});

type CreatedByValues = {
  createdBy: ?User,
  ...
};

export const FieldCreatedBy = ({
  values,
  setValues,
}: {|
  values: CreatedByValues,
  setValues: ($Shape<CreatedByValues>) => void,
|}): React.Node => {
  const { media } = useSystem();
  return (
    <div
      css={media({
        display: 'grid',
        gridColumnGap: 8,
        gridRowGap: 12,
        alignItems: 'center',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <UserInput
        Input={BaseUserInput}
        creatable={false}
        value={values.createdBy}
        onChange={user => setValues({ createdBy: user })}
      />
    </div>
  );
};

export const FieldParent = ({
  values,
  setValues,
  error,
  helperText,
}: {|
  values: ParentValues,
  error?: boolean,
  helperText?: string | null,
  setValues: ($Shape<ParentValues>) => void,
|}): React.Node => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { colors, text } = useTheme();
  return (
    <>
      <div
        css={media({
          display: 'grid',
          gridColumnGap: 8,
          gridRowGap: 12,
          alignItems: 'flex-start',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
        })}
      >
        <InlineSelect
          // css={{ '& .MuiSelect-select': { minHeight: 48 } }}
          value={values.parentType}
          onChange={e => {
            setValues({
              parentType: e.target.value,
              parent: null,
            });
          }}
        >
          <MenuItem value="Lot">
            <ListItemIcon>
              <Photo />
            </ListItemIcon>
            {t('listing')}
          </MenuItem>
          <MenuItem value="Lead">
            <ListItemIcon>
              <GpsFixed />
            </ListItemIcon>
            {t('lead')}
          </MenuItem>
          <MenuItem value="BuyerLead">
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            {t('buyerLead')}
          </MenuItem>
          <MenuItem value="User">
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            {t('user')}
          </MenuItem>
        </InlineSelect>
        <div>
          {values.parentType === 'User' && (
            <UserInput
              Input={BaseUserInput}
              creatable={false}
              value={values.parent}
              onChange={parent => setValues({ parent })}
            />
          )}
          {values.parentType === 'Lot' && (
            <LotInput
              Input={BaseLotInput}
              value={values.parent}
              onChange={parent => setValues({ parent })}
            />
          )}
          {values.parentType === 'Lead' && (
            <LeadInput
              Input={BaseLeadInput}
              filters={{ completed_in: [true] }}
              value={values.parent}
              onChange={parent => setValues({ parent })}
            />
          )}
          {values.parentType === 'BuyerLead' && (
            <BuyerLeadInput
              Input={BaseBuyerLeadInput}
              value={values.parent}
              onChange={parent => setValues({ parent })}
            />
          )}
          {error === true && (
            <Box
              css={[
                text.caption,
                {
                  color: colors.errorText,
                  borderTop: '2px solid currentColor',
                },
              ]}
              px={2}
              py={1}
            >
              {helperText}
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export const FieldVisibility = ({
  values,
  setValues,
}: {
  values: VisibilityValues,
  setValues: ($Shape<VisibilityValues>) => void,
  ...
}): React.Node => {
  const { t } = useLocale();

  return (
    <TextField
      label={t('visibility')}
      value={values.visibility}
      select={true}
      onChange={event => {
        setValues({ visibility: (event.target.value: any) });
      }}
      size="small"
    >
      <MenuItem value="shared">{t('Share with everyone')}</MenuItem>
      <MenuItem value="organisation">{t('shareWithinYourCompany')}</MenuItem>
      <MenuItem value="private">{t('private')}</MenuItem>
    </TextField>
  );
};
