import * as React from 'react';

import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  Skeleton,
} from '@mui/material';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useRefetchableFragment,
} from 'react-relay';
import { Box, Flex, useResponsive, useSystem } from 'react-system';

import { AutoAssignAssistant } from '../../../apollo/components/AutoAssignAssistant';
import { AppraisalRequestsCard } from '../../../apollo/pages/leads/AppraisalRequestsCard';
import { LeadAgents } from '../../../apollo/pages/leads/LeadAgents';
import { fromGlobalId } from '../../../shared/global-id';
import { Feed, type FeedApi, LoadingFeed } from '../../components/Feed/Feed';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { ChevronRight } from '../../icons/chevron-right';
import { type leadDrawerQuery$data } from '../../shared/__generated__/leadDrawerQuery.graphql';
import { FinancingLeadsCard } from '../../shared/financing-leads-card';
import { LandRegistryOwnersCard } from '../../shared/land-registry-owners-card';
import { LeadBrokerCard } from '../../shared/lead-broker-card';
import { LeadContactCard } from '../../shared/lead-contact-card';
import { LeadSnippet } from '../../shared/lead-snippet';
import { PropertyCard } from '../../shared/property-card';

import type { leadDetails_lead$key } from './__generated__/leadDetails_lead.graphql';
import { type leadDetails_leadClaimData$key } from './__generated__/leadDetails_leadClaimData.graphql';
import type { leadDetails_root$key } from './__generated__/leadDetails_root.graphql';
import { type leadDetailsClaimDataRefreshQuery } from './__generated__/leadDetailsClaimDataRefreshQuery.graphql';
import type { leadDetailsQuery } from './__generated__/leadDetailsQuery.graphql';
import { LeadCmaCard } from './lead-cma-card';
import { LeadControls } from './lead-controls';
import { LeadFilesCard } from './lead-files-card';
import { LeadLotCard } from './lead-lot-card';
import { LeadMandateCard } from './lead-mandate-card';
import { LeadOtherLeadsCard } from './lead-other-leads-card';
import { LeadPropertySearchCard } from './lead-property-search-card';
import { LeadDetailsQualificationCard } from './lead-qualification-card';
import { LeadDetailsAnswersCard } from './LeadDetailsAnswersCard';
import { LeadDetailsLatestAppraisalCard } from './LeadDetailsLatestAppraisalCard';
import { LeadDetailsMap } from './LeadDetailsMap';

type Props = {
  root: leadDetails_root$key;
  lead: leadDrawerQuery$data['leadById'];
  onDelete?: () => void;
  closeLead: (forceRefresh?: boolean) => void;
};

const useLeadFragment = (leadRef: null | leadDetails_lead$key) => {
  const lead = useFragment(
    graphql`
      fragment leadDetails_lead on Lead {
        ...Feed_lead
        ...leadFilesCard_lead
        ...LeadDetailsAnswersCard_lead
        ...leadBrokerCard_lead
        ...leadContactCard_lead
        ...leadSnippet_lead
        ...leadControls_lead
        ...leadQualificationCard_lead
        ...LeadDetailsLatestAppraisalCard_lead
        ...leadLotCard_lead
        ...leadCmaCard_lead
        id
        appraisalPropertyFormattedAddress
        potentialDevelopment
        userCanViewLeadDetails
        contact {
          ...financingLeadsCard_user
          id
          firstName
          lastName
        }
        property {
          ...LeadDetailsMap_property
          ...propertyCard_property
          id
          formattedAddress
          formattedLocality
          owners {
            id
          }
        }
      }
    `,
    leadRef,
  );
  return lead;
};

export const useLeadTitle = (leadRef: null | leadDetails_lead$key): string => {
  const { t } = useLocale();
  const lead = useLeadFragment(leadRef);
  if (lead == null) {
    return t('leads');
  }
  const name = `${lead.contact?.firstName ?? ''} ${
    lead.contact?.lastName != null && lead.contact.lastName !== ''
      ? lead.userCanViewLeadDetails
        ? lead.contact.lastName
        : lead.contact.lastName.slice(0, 1) + '.'
      : ''
  }`;
  const address = lead.userCanViewLeadDetails
    ? lead.property?.formattedAddress ??
      lead.appraisalPropertyFormattedAddress ??
      ''
    : lead.property?.formattedLocality ?? '';

  return `${name} - ${address} / ${t('leads')}`;
};

type LandRegistryOwnersProps = {
  propertyId: string;
};

const LandRegistryOwners = ({ propertyId }: LandRegistryOwnersProps) => {
  const data = useLazyLoadQuery<leadDetailsQuery>(
    graphql`
      query leadDetailsQuery($id: ID!) {
        property: node(id: $id) {
          ... on Property {
            location {
              plot {
                owners {
                  names
                }
              }
            }
          }
        }
      }
    `,
    { id: propertyId },
  );
  const names = data.property?.location?.plot?.owners?.names ?? [];

  return (
    <React.Suspense
      fallback={
        <Flex justifyContent="center" pb={3}>
          <CircularProgress disableShrink />
        </Flex>
      }
    >
      <LandRegistryOwnersCard names={names} defaultExpanded={false} />
    </React.Suspense>
  );
};

export const LeadDetails = (props: Props) => {
  const root = useFragment(
    graphql`
      fragment leadDetails_root on Query {
        ...propertyCard_root
        ...leadBrokerCard_root
        ...leadControls_root
        ...LeadDetailsLatestAppraisalCard_root
        ...leadQualificationCard_root
        me {
          modules
          isAdmin
          canEditSettings
        }
        tenantSettings {
          id
          showAdditionalLinks
          activateLandRegistryOwners
        }
      }
    `,
    props.root,
  );
  const lead = useLeadFragment(props.lead);
  const [leadClaimData, refreshLeadClaimData] = useRefetchableFragment<
    leadDetailsClaimDataRefreshQuery,
    leadDetails_leadClaimData$key
  >(
    graphql`
      fragment leadDetails_leadClaimData on Lead
      @refetchable(queryName: "leadDetailsClaimDataRefreshQuery") {
        id
        claimedBy {
          id
        }
      }
    `,
    props.lead,
  );

  const { t } = useLocale();
  const { text } = useTheme();
  const responsive = useResponsive();
  const { media } = useSystem();
  const feedRef = React.useRef<FeedApi>(null);

  const retryFeed = () => {
    if (feedRef.current) {
      feedRef.current.retry();
    }
  };

  const [openAnswers, setOpenAnswers] = React.useState(false);

  const { tenantSettings, me } = root;
  if (lead == null) {
    return null;
  }

  const activateLandRegistryOwners =
    tenantSettings?.activateLandRegistryOwners ?? false;

  return (
    <div style={{ width: '100%' }}>
      {lead.property != null && (
        <LeadDetailsMap
          property={lead.property}
          tab={lead.potentialDevelopment === true ? 'mapbox' : 'satellite'}
          previewMode={!lead.userCanViewLeadDetails}
        />
      )}

      <Box
        width={1}
        p={3}
        css={media({
          maxWidth: 1400,
          margin: '0 auto',
          display: 'grid',
          gridColumnGap: 16,
          gridRowGap: 32,
          gridTemplateColumns: [
            'minmax(0, 1fr)',
            'minmax(0, 1fr) minmax(0, 1fr)',
            'minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr)',
          ],
          gridTemplateRows: [
            'auto auto auto auto',
            'auto auto auto',
            'min-content minmax(0, 1fr)',
          ],
        })}
      >
        <div
          css={media({
            gridColumn: '1 / span 1',
            gridRow: ['2 / span 1', '2 / span 1', '1 / -1'],
          })}
        >
          {lead.userCanViewLeadDetails && (
            <LeadDetailsQualificationCard
              root={root}
              lead={lead}
              defaultExpanded={false}
            />
          )}
          <LeadDetailsLatestAppraisalCard
            root={root}
            lead={lead}
            defaultExpanded={responsive([false, false, true])}
          />
          {lead.userCanViewLeadDetails && (
            <React.Suspense fallback={null}>
              {me?.modules.includes('cma_reports') && (
                <LeadCmaCard
                  lead={lead}
                  defaultExpanded={responsive([false, false, true])}
                />
              )}

              {lead.property != null && (
                <PropertyCard
                  root={root}
                  property={lead.property}
                  defaultExpanded={false}
                />
              )}

              {activateLandRegistryOwners && lead.property && (
                <LandRegistryOwners propertyId={lead.property.id} />
              )}
            </React.Suspense>
          )}
        </div>
        <div
          css={media({
            gridColumn: ['1 / span 1', '1 / -1', '2 / span 1'],
            gridRow: '1 / span 1',
          })}
        >
          <Dialog
            open={openAnswers}
            onClose={() => setOpenAnswers(false)}
            maxWidth="sm"
            scroll="body"
          >
            <LeadDetailsAnswersCard lead={lead} />
          </Dialog>
          <Card>
            <LeadSnippet
              showMap={responsive([false, true])}
              lead={lead}
              hasLink={false}
              controls={
                me?.isAdmin &&
                lead.userCanViewLeadDetails && (
                  <LeadControls
                    root={root}
                    lead={lead}
                    onActivityCreate={retryFeed}
                    onDelete={props.onDelete}
                  />
                )
              }
              bottomButton={
                lead.userCanViewLeadDetails && (
                  <Flex pt={2}>
                    <Button
                      css={{
                        textTransform: 'none',
                        fontSize: text.size(14),
                        padding: 0,
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                      size="small"
                      onClick={() => setOpenAnswers(true)}
                      endIcon={<ChevronRight />}
                    >
                      {t('viewLeadAnswers')}
                    </Button>
                  </Flex>
                )
              }
            />
          </Card>
          {me?.canEditSettings && (
            <div style={{ marginTop: '16px' }}>
              <LeadAgents leadId={fromGlobalId(lead.id)} />
            </div>
          )}
        </div>
        <div
          css={media({
            gridColumn: ['1 / span 1', '1 / -1', '2 / span 1'],
            gridRow: ['4 / span 1', '3 / span 1', '2 / span 1'],
          })}
        >
          <React.Suspense fallback={<LoadingFeed />}>
            <Feed
              ref={feedRef}
              lead={lead}
              isAdmin={me?.isAdmin ?? false}
              sortBy={'pending'}
              showPinned={true}
              disableForm={!lead.userCanViewLeadDetails}
              onChange={trigger => {
                if (trigger === 'passLead' && me?.isAdmin !== true) {
                  props.closeLead();
                } else {
                  retryFeed();
                  refreshLeadClaimData(
                    {},
                    { fetchPolicy: 'store-and-network' },
                  );
                }
              }}
              filters={{
                feed_eq: {
                  allLinkedUserId_in: [
                    ...(lead.contact ? [lead.contact.id] : []),
                    ...(lead.property?.owners ?? []).map(owner => owner.id),
                  ],
                  parentId_eq: lead.id,
                },
              }}
            />
          </React.Suspense>
        </div>
        <div
          css={media({
            gridColumn: ['1 / span 1', '2 / span 1', '3 / span 1'],
            gridRow: ['3 / span 1', '2 / span 1', '1 / -1'],
          })}
        >
          {lead.userCanViewLeadDetails && (
            <LeadContactCard
              lead={lead}
              defaultExpanded={responsive([
                false,
                false,
                leadClaimData?.claimedBy != null,
              ])}
            />
          )}

          <LeadBrokerCard
            root={root}
            lead={lead}
            onChange={leads => {
              if (leads.length > 0) {
                retryFeed();
              } else {
                // If the mutation returns no leads
                // it probably means that the agent has lost access to the lead after assigning
                // so we close the lead
                props.closeLead(true);
              }
            }}
            defaultExpanded={
              !lead.userCanViewLeadDetails
                ? true
                : responsive([false, false, true])
            }
          />
          {me?.isAdmin === true && (
            <React.Suspense fallback={null}>
              <AppraisalRequestsCard
                leadId={fromGlobalId(lead.id)}
                defaultExpanded={false}
              />
            </React.Suspense>
          )}

          {lead.userCanViewLeadDetails && (
            <>
              {/* hiding mandate card for now Ref #14810 */}
              {false && tenantSettings?.showAdditionalLinks && (
                <LeadMandateCard
                  // @ts-ignore types breaks because of falsy condition
                  leadId={lead.id}
                  // @ts-ignore types breaks because of falsy condition
                  tenantId={tenantSettings.id}
                  defaultExpanded={false}
                />
              )}

              {me?.modules.includes('financing') && (
                <FinancingLeadsCard
                  user={lead.contact}
                  onCreate={leadId => {
                    open(`/financing/${leadId ?? ''}`);
                  }}
                />
              )}
              {me?.modules.includes('brokerage') && (
                <>
                  <LeadLotCard lead={lead} />
                  <React.Suspense
                    fallback={<Skeleton variant="rectangular" height={48} />}
                  >
                    <LeadPropertySearchCard
                      leadId={lead.id}
                      defaultExpanded={false}
                    />
                  </React.Suspense>
                  <LeadFilesCard lead={lead} defaultExpanded={false} />
                </>
              )}

              {me?.isAdmin === true && lead.property && (
                <React.Suspense fallback={null}>
                  <LeadOtherLeadsCard
                    leadId={lead.id}
                    defaultExpanded={false}
                  />
                </React.Suspense>
              )}
            </>
          )}
          {me?.isAdmin === true && lead.property && tenantSettings?.id && (
            <AutoAssignAssistant
              leadId={fromGlobalId(lead.id)}
              tenantId={fromGlobalId(tenantSettings?.id)}
            />
          )}
        </div>
      </Box>
    </div>
  );
};
