import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  type InternalStandardProps,
  Switch,
} from '@mui/material';
import type { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import type { Control, ControllerRenderProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';

type RaCheckboxProps = Omit<
  InternalStandardProps<SwitchBaseProps, 'checkedIcon' | 'color' | 'icon'>,
  'name' | 'onChange' | 'onBlur' | 'checked' | 'ref'
> & {
  control: Control<any>;
  name: string;
  label: string;
  useSwitch?: boolean;
  helpText?: string;
  inFormGroup?: boolean;
};

export const RaCheckbox: React.FC<RaCheckboxProps> = ({
  control,
  name,
  helpText,
  inFormGroup = false,
  ...resProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        inFormGroup ? (
          <CheckboxControl {...resProps} field={field} />
        ) : (
          <FormControl component="fieldset" variant="standard" error={!!error}>
            <CheckboxControl {...resProps} field={field} />
            <FormHelperText>{error?.message ?? helpText}</FormHelperText>
          </FormControl>
        )
      }
    />
  );
};

const CheckboxControl: React.FC<
  Omit<RaCheckboxProps, 'inFormGroup' | 'helpText' | 'control' | 'name'> & {
    field: ControllerRenderProps<any, string>;
  }
> = ({ label, useSwitch = false, field, ...resProps }) => {
  return (
    <FormControlLabel
      control={
        useSwitch ? (
          <Switch
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            ref={field.ref}
            checked={field.value ?? false}
            {...resProps}
          />
        ) : (
          <Checkbox
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            ref={field.ref}
            checked={field.value ?? false}
            {...resProps}
          />
        )
      }
      label={label}
    />
  );
};
