import * as React from 'react';

import {
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useCombobox } from 'downshift';
import { getNames, registerLocale } from 'i18n-iso-countries';
import countriesDeLocale from 'i18n-iso-countries/langs/de.json';
import countriesEnLocale from 'i18n-iso-countries/langs/en.json';
import countriesEsLocale from 'i18n-iso-countries/langs/es.json';
import countriesFrLocale from 'i18n-iso-countries/langs/fr.json';
import countriesItLocale from 'i18n-iso-countries/langs/it.json';

import { InputPopup } from '../controls/popup';
import { type Language, useLocale } from '../hooks/locale';
import { Cancel } from '../icons/cancel';

registerLocale(countriesEnLocale);
registerLocale(countriesFrLocale);
registerLocale(countriesEsLocale);
registerLocale(countriesDeLocale);
registerLocale(countriesItLocale);

type Props = {
  value: null | string;
  onChange: (value: null | string) => void;
  onBlur?: () => void;
  disabled?: boolean;
};

type Country = {
  code: string;
  name: string;
};

type IndexType = {
  codes: Map<string, Country>;
  tokens: Map<string, Country>;
};

const generateCountriesArray = (language: Language) => {
  const countries = getNames(language);
  const countriesArray: Country[] = [];
  for (const [code, name] of Object.entries(countries)) {
    if (typeof name === 'string') {
      const country: Country = { code, name };
      countriesArray.push(country);
    }
  }
  return countriesArray;
};

const indexCountries = (countries: Country[]) => {
  const tokens = new Map<string, Country>();
  const codes = new Map<string, Country>();
  for (const country of countries) {
    const { code, name } = country;
    const token =
      code.toLowerCase() + name.toLocaleLowerCase().replace(/\s+/, '');
    tokens.set(token, country);
    codes.set(code, country);
  }
  return {
    codes,
    tokens,
  };
};

const searchTerm = (
  index: IndexType,
  rawTerm: string,
  countries: Country[],
) => {
  if (rawTerm === '') {
    return countries;
  }
  const term = rawTerm.toLocaleLowerCase();
  const matched = [];
  for (const [token, country] of index.tokens) {
    if (token.includes(term)) {
      matched.push(country);
    }
  }
  return matched;
};

export const CountryInput = (props: Props) => {
  const { t, language } = useLocale();
  const targetRef = React.useRef(null);
  const [term, setTerm] = React.useState('');
  const countries = React.useMemo(
    () => generateCountriesArray(language),
    [language],
  );
  const index = React.useMemo(() => indexCountries(countries), [countries]);
  const matched = React.useMemo(
    () => searchTerm(index, term, countries),
    [index, term, countries],
  );
  const combobox = useCombobox({
    items: matched,
    itemToString: item => item?.name ?? '',
    // make selectedItem always controlled by coalescing null
    selectedItem: index.codes.get(props.value ?? '') ?? null,
    onInputValueChange: ({ inputValue }) => setTerm(inputValue ?? ''),
    onSelectedItemChange: ({ selectedItem }) =>
      props.onChange(selectedItem?.code ?? null),
  });
  const { getComboboxProps } = combobox;
  const { getInputProps, getMenuProps, getItemProps } = combobox;
  const { isOpen, highlightedIndex, inputValue, selectedItem } = combobox;
  const { openMenu } = combobox;
  return (
    <div {...getComboboxProps()}>
      <FilledInput
        {...getInputProps({
          // prevent native autocomplete by providing invalid value
          autoComplete: 'invalid',
          ref: targetRef,
          disabled: props.disabled,
          onFocus: () => {
            if (isOpen === false) {
              setTerm(inputValue ?? '');
              openMenu();
            }
          },
          onBlur: props.onBlur,
        })}
        endAdornment={
          selectedItem != null ? (
            <InputAdornment position="end">
              <IconButton onClick={() => props.onChange(null)}>
                <Cancel />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
      <InputPopup referenceRef={targetRef} open={isOpen}>
        <List {...getMenuProps()}>
          {matched.length === 0 && (
            <ListItem disabled={true}>{t('No options')}</ListItem>
          )}
          {isOpen &&
            matched.map((item, index) => (
              <ListItem
                {...getItemProps({ index, item })}
                key={item.code}
                button={true}
                selected={highlightedIndex === index}
              >
                <ListItemText>{item.name}</ListItemText>
              </ListItem>
            ))}
        </List>
      </InputPopup>
    </div>
  );
};
