import { memo } from 'react';

import {
  Box,
  Card,
  CardActionArea,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';

const ComparableListingsSkeleton = () => {
  const theme = useTheme();

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Skeleton
        variant="rectangular"
        sx={{
          height: '200px',
          width: '100%',
          background: theme.palette.grey[200],
        }}
      />
      <CardActionArea sx={{ flexGrow: 1 }}>
        <Stack direction="column" flex="1">
          <Box p={2}>
            <Stack spacing={1}>
              <Skeleton variant="rounded" height={16} />
              <Stack direction="row" spacing={1}>
                <Skeleton variant="rounded" width={100} height={14} />
                <Skeleton variant="rounded" width={80} height={14} />
              </Stack>
              <Skeleton variant="rounded" width="50%" height={22} />
              <Skeleton variant="rounded" width="40%" height={16} />
            </Stack>
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default memo(ComparableListingsSkeleton);
