import { FormControl, FormHelperText } from '@mui/material';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { type Users_Bool_Exp } from '../../__generated__/graphql';
import { UserSelect, type UserSelectProps } from '../user-select/UserSelect';

import { RaLabel } from './RaLabel';

export type RaUserProps = {
  control: Control<any>;
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  filters?: Users_Bool_Exp;
  multiple?: boolean;
  createUserSelected?: UserSelectProps['onCreate'];
};

export const RaUser = ({
  control,
  name,
  label,
  required,
  createUserSelected,
  filters,
  disabled = false,
  multiple = false,
}: RaUserProps) => {
  const { t } = useLocale();
  const rules = {
    required: required ? t('This field is required') : undefined,
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <RaLabel label={label} required={required} />
          <UserSelect
            userId={value}
            onChange={onChange}
            disabled={disabled}
            creatable={createUserSelected != null}
            onCreate={createUserSelected}
            filters={filters}
            multiple={multiple}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
