import { CircularProgress, Stack } from '@mui/material';

export const LoadingSpinner = () => {
  return (
    <Stack flexGrow={1} justifyContent="center" alignItems="center" p={3}>
      <CircularProgress disableShrink />
    </Stack>
  );
};

LoadingSpinner.displayName = 'LoadingSpinner';
