/**
 * @generated SignedSource<<c210a5623067cea5639fe89a310c340a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeadStageStatus = "active" | "lost" | "won";
export type AssignLeadsInput = {
  brokerId?: string | null;
  clientMutationId?: string | null;
  id?: ReadonlyArray<string | null> | null;
};
export type leadsActionsMenuAssignBrokerMutation$variables = {
  input: AssignLeadsInput;
};
export type leadsActionsMenuAssignBrokerMutation$data = {
  readonly assignLeads: {
    readonly leads: ReadonlyArray<{
      readonly broker: {
        readonly emails: ReadonlyArray<{
          readonly email: string;
        }>;
        readonly firstName: string | null;
        readonly lastName: string | null;
      } | null;
      readonly id: string;
      readonly stage: {
        readonly id: string;
        readonly label: string;
        readonly status: LeadStageStatus;
      } | null;
    } | null> | null;
  } | null;
};
export type leadsActionsMenuAssignBrokerMutation = {
  response: leadsActionsMenuAssignBrokerMutation$data;
  variables: leadsActionsMenuAssignBrokerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "LeadStage",
  "kind": "LinkedField",
  "name": "stage",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadsActionsMenuAssignBrokerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignLeadsPayload",
        "kind": "LinkedField",
        "name": "assignLeads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "leads",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "broker",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Email",
                    "kind": "LinkedField",
                    "name": "emails",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadsActionsMenuAssignBrokerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignLeadsPayload",
        "kind": "LinkedField",
        "name": "assignLeads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "leads",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "broker",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Email",
                    "kind": "LinkedField",
                    "name": "emails",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c807a297af91cb5a2f8c06271d7b0a95",
    "id": null,
    "metadata": {},
    "name": "leadsActionsMenuAssignBrokerMutation",
    "operationKind": "mutation",
    "text": "mutation leadsActionsMenuAssignBrokerMutation(\n  $input: AssignLeadsInput!\n) {\n  assignLeads(input: $input) {\n    leads {\n      id\n      broker {\n        firstName\n        lastName\n        emails {\n          email\n          id\n        }\n        id\n      }\n      stage {\n        id\n        status\n        label\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "22db5e8511b23f3c28456e2bbb8d3c54";

export default node;
