import React, { Suspense, lazy, useMemo, useState } from 'react';

import type { ApolloError, QueryOptions } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import {
  Delete,
  GridViewOutlined,
  GridViewRounded,
  Search,
  TableRowsOutlined,
  TableRowsRounded,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type {
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
} from '@mui/x-data-grid-premium';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_REORDER_COL_DEF,
} from '@mui/x-data-grid-premium';
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import {
  type IntlLocale,
  type Translate,
  useLocale,
} from '../../../src/hooks/locale';
import { lazyRetry } from '../../../src/utils/lazy';
import { gql } from '../../__generated__';
import {
  type GetLotsListQuery,
  type Lots_Bool_Exp,
  Lots_Status_Enum_Enum,
} from '../../__generated__/graphql';
import { ListingCell } from '../../components/data-grid/ListingCell';
import { ListingPriceCell } from '../../components/data-grid/ListingPriceCell';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { LoadingCheckBox } from '../../components/LoadingCheckbox';
import { LotCardCarousel } from '../../components/LotCardCarousel';
import {
  LotCardInfo,
  getLotStatusTagVariant,
} from '../../components/LotCardInfo';
import { LotSelect } from '../../components/LotsSelect';
import { MutationErrorModal } from '../../components/MutationErrorModal';
import { PortalPublicationSnackbar } from '../../components/PortalPublicationSnackbar';
import RaGrid from '../../components/RaGrid';
import { SmartListTitle } from '../../components/SmartListTitle';
import { Tag } from '../../components/Tag';
import { TimeAgo } from '../../components/TimeAgo';
import { UserChip } from '../../components/UserChip';
import { useAppData } from '../../providers/AppDataProvider';
import { getPaginationVariables } from '../../utils/paginations';
import { prepareWhereClauseQuery } from '../../utils/parseWhereClause';
import {
  extractPropertyOrientations,
  getOrientationTranslation,
} from '../../utils/propertyTemplateFunctions';

const CreateListingDrawer = lazy(() =>
  lazyRetry(() => import('./CreateListingDrawer')),
);
const ListingDrawer = lazy(() => lazyRetry(() => import('./ListingDrawer')));

import { ListingsExport } from './ListingsExport';
import ListingsImport from './ListingsImport';
import {
  DELETE_LOTS,
  GET_LOTS_LIST,
  GET_LOTS_LIST_COUNT,
  REORDER_LOTS,
  TOGGLE_PORTAL_LISTING,
  UPDATE_LISTING_STATUS,
} from './lotsQueries';

const GRID_PAGE_SIZE = 24;
const LIST_PAGE_SIZE = 25;

export const initialListingsListQuery = (
  searchParams: URLSearchParams,
  reorderable: boolean,
  developmentId: string | undefined,
): QueryOptions => {
  const whereClause = prepareWhereClauseQuery(
    JSON.parse(searchParams.get('where') || '{}'),
  );

  const where: Lots_Bool_Exp = {
    ...whereClause,
    development_id: developmentId != null ? { _eq: developmentId } : undefined,
  };

  const initialOrderBy = reorderable
    ? '[{ "order_nr": "asc" }]'
    : '[{ "created_at": "desc" }]';

  const order_by = JSON.parse(searchParams.get('order_by') || initialOrderBy);
  const defaultLimit =
    searchParams.get('view') === 'list' ? LIST_PAGE_SIZE : GRID_PAGE_SIZE;
  const { limit, offset } = getPaginationVariables(searchParams, defaultLimit);

  return {
    query: GET_LOTS_LIST,
    variables: {
      where,
      offset,
      limit,
      order_by,
    },
  };
};

const quickFilters = (t: any) => [
  {
    label: t('Created'),
    path: ['lots_bool_exp', 'created_at'],
  },
  {
    label: t('Signed'),
    path: ['lots_bool_exp', 'signed_at'],
  },
  { label: t('Status'), path: ['lots_bool_exp', 'status', '_in'] },
  {
    label: t('Source'),
    path: ['lots_bool_exp', 'origin', 'id', '_in'],
    displayedColumn: 'label',
    filter: {
      path: ['name', 'type', '_in'],
      value: 'lot_origin_types',
    },
  },
  {
    label: t('Agent'),
    path: ['lots_bool_exp', 'broker'],
    filter: {
      path: ['users', 'is_broker', '_eq'],
      value: true,
    },
  },
  {
    label: t('Seller'),
    path: ['lots_bool_exp', 'sellers', 'seller'],
  },
  {
    label: t('Team'),
    path: ['lots_bool_exp', 'broker', 'teams_users', 'team'],
  },
  {
    label: t('Location'),
    path: ['lots_bool_exp', 'property', 'places'],
  },
  {
    label: t('Price'),
    path: ['lots_bool_exp', 'sale_price'],
  },
  {
    label: t('Online'),
    path: ['lots_bool_exp', 'portal_listings', 'live'],
  },
  {
    label: t('Buy / Rent'),
    path: ['lots_bool_exp', 'offer_type', '_in'],
  },
  {
    label: t('Property type'),
    path: ['lots_bool_exp', 'property', 'property_type', 'name', '_in'],
    displayedColumn: 'label',
    filter: {
      path: ['property_type', 'main_type', '_is_null'],
      value: false,
    },
  },
  {
    label: t('Rooms'),
    path: ['lots_bool_exp', 'property', 'number_of_rooms'],
  },
  {
    label: t('Offers'),
    path: ['lots_bool_exp', 'offers_aggregate', 'count', 'predicate'],
  },
  {
    label: t('Enquiries'),
    path: ['lots_bool_exp', 'enquiries_aggregate', 'count', 'predicate'],
  },
  {
    label: t('Exclusivity'),
    path: ['lots_bool_exp', 'is_exclusive'],
  },
  {
    label: t('Time to sell'),
    path: ['lots_bool_exp', 'expected_time_to_sell', '_in'],
  },
];

type Lot = GetLotsListQuery['lots'][number];

type ListingsProps = {
  developmentId?: string;
  detailedView?: boolean;
  hideTitle?: boolean;
  reorderable?: boolean;
};

const LotCard = React.memo(
  ({
    data,
    listingLink,
  }: {
    data: Lot;
    listingLink: (id: string) => { pathname: string; search: string };
  }) => {
    const { t } = useLocale();

    const { sellers, broker } = data;
    const seller = sellers[0]?.seller;

    return (
      <Link to={listingLink(data.id as string)} css={{ height: '100%' }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: '100%',
          }}
        >
          <LotCardCarousel lot={data} />

          <CardActionArea sx={{ flexGrow: 1 }}>
            <Stack direction="column" flex={1} height="100%">
              <Box p={2} flex={1} height="100%">
                <LotCardInfo
                  lot={data}
                  showTitle={true}
                  showInlineLabels={true}
                />
              </Box>
              <Divider sx={{ opacity: 0.5 }} />

              <Stack
                p={2}
                direction="row"
                justifyContent="space-between"
                height="83.91px"
                spacing={2}
              >
                {seller != null && (
                  <Stack minWidth={0}>
                    <Typography variant="caption">{t('seller')}</Typography>
                    <UserChip user={seller} />
                  </Stack>
                )}

                {broker != null && (
                  <Stack minWidth={0}>
                    <Typography variant="caption">{t('Agent')}</Typography>
                    <UserChip user={broker} />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </CardActionArea>
        </Card>
      </Link>
    );
  },
);

const LotCardSkeleton = () => {
  return (
    <Card>
      <Skeleton
        variant="rectangular"
        sx={{
          paddingTop: '56.25%',
        }}
        height="30%"
        width="100%"
      />
      <CardActionArea sx={{ flexGrow: 1 }}>
        <Stack direction="column" flex="1">
          <Box p={2}>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={230} height={14} />
              <Skeleton variant="rounded" width={230} height={18} />
              <Skeleton variant="rounded" width={160} height={24} />
              <Skeleton variant="rounded" width={80} height={16} />
              <Stack spacing={1} direction="row">
                <Skeleton variant="rounded" width={70} height={22} />
                <Skeleton variant="rounded" width={80} height={22} />
              </Stack>
            </Stack>
          </Box>
          <Divider sx={{ opacity: 0.5 }} />
          <Stack p={2} direction="row" justifyContent="space-between">
            <Stack spacing={0.8}>
              <Skeleton variant="rounded" width={40} height={14} />
              <Skeleton
                variant="rounded"
                width={100}
                height={32}
                sx={{
                  borderRadius: 16,
                }}
              />
            </Stack>
            <Stack spacing={0.8}>
              <Skeleton variant="rounded" width={40} height={14} />
              <Skeleton
                variant="rounded"
                width={100}
                height={32}
                sx={{
                  borderRadius: 16,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

type GridViewProps = {
  lots?: GetLotsListQuery['lots'];
  totalCount?: number;
  listingLink: (id: string) => { pathname: string; search: string };
  loading?: boolean;
};

const GridView = ({
  lots,
  totalCount,
  listingLink,
  loading,
}: GridViewProps) => {
  return (
    <RaGrid
      items={lots}
      loading={loading}
      totalCount={totalCount || 0}
      pageSize={GRID_PAGE_SIZE}
      getItemLink={
        listingLink as (id: string | number) => {
          pathname: string;
          search: string;
        }
      }
      renderSkeleton={LotCardSkeleton}
      renderItemCard={lot => <LotCard data={lot} listingLink={listingLink} />}
    />
  );
};

export const LISTINGS_DETAILED_VIEW_FRAGMENT = gql(/* GraphQL */ `
  fragment ListingsDetailedView on lots {
    property {
      id
      floor_of_flat
      is_oriented_north
      is_oriented_south
      is_oriented_east
      is_oriented_west
      number_of_rooms
      number_of_bedrooms
      number_of_bathrooms
      number_of_toilets
      number_of_indoor_parkings
      number_of_outdoor_parkings
      living_surface
      built_surface
      usable_surface
      gross_floor_surface
      balcony_surface
      terrace_surface
      basement_surface
      garden_surface
      land_surface
    }
  }
`);

const getListingsTableViewColumns = (t: Translate): GridColDef<Lot>[] => [
  {
    field:
      'property.postcode,property.locality,property.route,property.street_number',
    headerName: t('Listing'),
    width: 250,
    display: 'flex',
    renderCell: ({ row }) => <ListingCell listing={row} />,
  },
  {
    field: 'sellers',
    headerName: t('Seller'),
    sortable: false,
    width: 200,
    renderCell: ({ row }) =>
      row.sellers.length > 0 && <UserInfo user={row.sellers[0].seller} />,
  },
  {
    field: 'broker.last_name,broker.first_name',
    headerName: t('Agent'),
    width: 200,
    renderCell: ({ row }) => row.broker && <UserInfo user={row.broker} />,
  },
  {
    field: 'status',
    headerName: t('Status'),
    width: 100,
    display: 'flex',
    renderCell: ({ row }) => (
      <Tag status={getLotStatusTagVariant(row.status)}>
        {row.lots_status.label ? row.lots_status.label : row.status}
      </Tag>
    ),
  },
  {
    field: 'signed_at,created_at',
    headerName: t('Signed'),
    width: 100,
    display: 'flex',
    renderCell: ({ row }) => (
      <Box>
        <Typography
          variant="body2"
          color={row.is_exclusive ? 'success.main' : 'grey.600'}
          fontWeight={row.is_exclusive ? 'bold' : 'normal'}
        >
          {row.is_exclusive
            ? t('exclusive')
            : row.is_exclusive === false
            ? t('non-exclusive')
            : t('unspecified')}
        </Typography>
        <Typography variant="caption" sx={{ display: 'block' }}>
          <TimeAgo
            dateString={row.signed_at ?? row.created_at}
            ignoreTime={row.signed_at != null}
          />
        </Typography>
      </Box>
    ),
  },
  {
    field: 'sale_price',
    headerName: t('Price'),
    width: 200,
    align: 'right',
    display: 'flex',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography lineHeight="20px" variant="body2" fontWeight={500}>
          {t('Price')}
        </Typography>
        <Typography lineHeight="20px" variant="caption">
          {t('Appraisal')}
        </Typography>
      </Box>
    ),
    renderCell: ({ row }) => <ListingPriceCell row={row} />,
  },
  {
    field: 'enquiries_aggregate.max.created_at',
    headerName: t('Enquiries'),
    width: 100,
    display: 'flex',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography lineHeight="20px" variant="body2" fontWeight={500}>
          {t('Enquiries')}
        </Typography>
        <Typography lineHeight="20px" variant="caption">
          {t('Last enquiry')}
        </Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography variant="body2" display="inline-block">
          {row.enquiries_aggregate.aggregate?.count ?? 0}
        </Typography>
        &nbsp;
        <Typography variant="caption">{t('enquiries')}</Typography>
        <Typography variant="caption" display="block">
          <TimeAgo
            dateString={row.enquiries_aggregate.aggregate?.max?.created_at}
          />
        </Typography>
      </Box>
    ),
  },
];

const getSurfaceValue = (
  value: number | null | undefined,
  t: Translate,
  locale: IntlLocale,
) =>
  value != null
    ? t('{{value}} m²', {
        value: value.toLocaleString(locale, {
          maximumFractionDigits: 2,
        }),
      })
    : '-';

const getListingsDetailedViewColumns = (
  t: Translate,
  locale: IntlLocale,
): GridColDef<Lot>[] => [
  {
    field:
      'property.postcode,property.locality,property.route,property.street_number',
    headerName: t('Listing'),
    width: 300,
    display: 'flex',
    renderCell: ({ row }) => <ListingCell listing={row} />,
  },
  {
    field: 'status',
    headerName: t('Status'),
    width: 100,
    display: 'flex',
    renderCell: ({ row }) => (
      <Tag status={getLotStatusTagVariant(row.status)}>
        {row.lots_status.label ? row.lots_status.label : row.status}
      </Tag>
    ),
  },
  {
    field: 'property.floor_of_flat',
    headerName: t('Floor'),
    width: 100,
    valueFormatter: (value: Lot['property']['floor_of_flat']) =>
      value === 0 ? t('ground') : value,
  },
  {
    field:
      'property.is_oriented_north,property.is_oriented_south,property.is_oriented_east,property.is_oriented_west',
    headerName: t('Orientation'),
    width: 100,
    renderCell: ({ row }) => (
      <div>
        {extractPropertyOrientations(row.property)
          .map(value => getOrientationTranslation(t, value))
          .join(', ')}
      </div>
    ),
  },
  {
    field: 'property.number_of_rooms',
    headerName: t('Rooms'),
    width: 100,
    display: 'flex',
    renderCell: ({ row }) => (
      <div>
        {t('roomsShort', {
          count: row.property.number_of_rooms || 0,
        })}
        <Box sx={theme => theme.text.caption}>
          {t('numberOfBedroomsWithCount', {
            count: row.property.number_of_bedrooms || 0,
          })}
        </Box>
      </div>
    ),
  },
  {
    field: 'property.number_of_bathrooms',
    headerName: t('Bathrooms'),
    width: 100,
    display: 'flex',
    renderCell: ({ row }) => (
      <div>
        {t('numberOfBathroomsWithCount', {
          count: row.property.number_of_bathrooms || 0,
        })}
        <Box sx={theme => theme.text.caption}>
          {t('numberOfToiletsWithCount', {
            count: row.property.number_of_toilets || 0,
          })}
        </Box>
      </div>
    ),
  },
  {
    field: 'property.number_of_indoor_parkings',
    headerName: t('Parkings'),
    width: 100,
    display: 'flex',
    renderCell: ({ row }) => (
      <div>
        {t('numberOfIndoorParkingsWithCount', {
          count: row.property.number_of_indoor_parkings || 0,
        })}
        <Box sx={theme => theme.text.caption}>
          {t('numberOfOutdoorParkingsWithCount', {
            count: row.property.number_of_outdoor_parkings || 0,
          })}
        </Box>
      </div>
    ),
  },
  {
    field: 'property.living_surface',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography variant="body2" fontWeight={500}>
          {t('Housing')}
        </Typography>
        <Typography variant="caption">{t('livingSurfaceShort')}</Typography>
      </Box>
    ),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(
          property.living_surface ?? property.built_surface,
          t,
          locale,
        )}
      </Box>
    ),
  },
  {
    field: 'property.usable_surface',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography variant="body2" fontWeight={500}>
          {t('Housing')}
        </Typography>
        <Typography variant="caption">{t('usableSurfaceShort')}</Typography>
      </Box>
    ),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(property.usable_surface, t, locale)}
      </Box>
    ),
  },
  {
    field: 'property.gross_floor_surface',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography variant="body2" fontWeight={500}>
          {t('Housing')}
        </Typography>
        <Typography variant="caption">{t('grossFloorSurfaceShort')}</Typography>
      </Box>
    ),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(property.gross_floor_surface, t, locale)}
      </Box>
    ),
  },
  {
    field: 'property.balcony_surface',
    headerName: t('Balcony'),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(property.balcony_surface, t, locale)}
      </Box>
    ),
  },
  {
    field: 'property.terrace_surface',
    headerName: t('Terrace'),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(property.terrace_surface, t, locale)}
      </Box>
    ),
  },
  {
    field: 'property.basement_surface',
    headerName: t('Basement'),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(property.basement_surface, t, locale)}
      </Box>
    ),
  },
  {
    field: 'property.garden_surface',
    headerName: t('Garden'),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(property.garden_surface, t, locale)}
      </Box>
    ),
  },
  {
    field: 'property.land_surface',
    headerName: t('Land'),
    width: 100,
    renderCell: ({ row: { property } }) => (
      <Box sx={theme => theme.text.ellipsis}>
        {getSurfaceValue(property.land_surface, t, locale)}
      </Box>
    ),
  },
  {
    field: 'sale_price',
    headerName: t('Price'),
    width: 150,
    display: 'flex',
    renderCell: ({ row }) => <ListingPriceCell row={row} />,
  },
];

export const Listings = ({
  developmentId,
  detailedView = false,
  hideTitle = false,
  reorderable = false,
}: ListingsProps) => {
  const [searchParams] = useSearchParams();
  const { t, locale } = useLocale();
  const navigate = useNavigate();
  const [selectedListings, setSelectedListings] =
    useState<GridRowSelectionModel>([]);
  const { me } = useAppData();

  const editMode = searchParams.get('edit') === 'true';
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const { variables } = useMemo(
    () => initialListingsListQuery(searchParams, reorderable, developmentId),
    [searchParams, reorderable, developmentId],
  );
  const { where, order_by, limit, offset } = variables ?? {};

  const viewType = searchParams.get('view') ?? 'grid';

  const listingLink = (id: string) => ({
    pathname: `./${id}`,
    search: searchParams.toString(),
  });

  const { data, loading, error, refetch } = useQuery(GET_LOTS_LIST, {
    variables: {
      where,
      order_by,
      limit,
      offset,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: countData } = useQuery(GET_LOTS_LIST_COUNT, {
    variables: {
      where,
    },
  });

  const [updateListingStatus] = useMutation(UPDATE_LISTING_STATUS);
  const [reorderListing] = useMutation(REORDER_LOTS, {
    update: (cache, { data: reorderData }, { variables }) => {
      if (variables == null || reorderData == null) {
        return;
      }

      const targetOrder = variables.target_position;
      const listingRef = cache.identify({
        __typename: 'lots',
        id: variables.id,
      });

      const currentListing = cache.readFragment<{
        id: string;
        order_nr: number;
      }>({
        id: listingRef,
        fragment: gql(/* GraphQL */ `
          fragment CurrentListing on lots {
            id
            order_nr
          }
        `),
      });

      if (currentListing == null) {
        return;
      }

      const lotsQuery = cache.readQuery<GetLotsListQuery>({
        query: GET_LOTS_LIST,
        variables: {
          where,
          order_by,
          limit,
          offset,
        },
      });

      if (lotsQuery == null) {
        return;
      }

      const oldOrder = currentListing.order_nr;

      const updatedLots = lotsQuery?.lots
        .map(lot => {
          if (lot.id !== currentListing.id) {
            if (
              targetOrder > oldOrder &&
              lot.order_nr <= targetOrder &&
              lot.order_nr > oldOrder
            ) {
              return { ...lot, order_nr: lot.order_nr - 1 };
            }

            if (
              targetOrder < oldOrder &&
              lot.order_nr >= targetOrder &&
              lot.order_nr < oldOrder
            ) {
              return { ...lot, order_nr: lot.order_nr + 1 };
            }

            return lot;
          } else {
            return { ...lot, order_nr: targetOrder };
          }
        })
        .toSorted((a, b) => a.order_nr - b.order_nr);

      cache.writeQuery<GetLotsListQuery>({
        query: GET_LOTS_LIST,
        variables: {
          where,
          order_by,
          limit,
          offset,
        },
        data: {
          ...lotsQuery,
          lots: updatedLots,
        },
      });

      cache.modify({
        id: listingRef,
        fields: {
          order_nr: () => targetOrder,
        },
      });
    },
  });
  const [deleteLots, deleteOptions] = useMutation(DELETE_LOTS, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleDelete = (ids: string[]) => {
    deleteLots({
      variables: {
        ids,
      },
    });
  };

  const rows: GridRowsProp<Lot> = data?.lots ?? [];

  const [toggleError, setToggleError] = useState<null | ApolloError>(null);
  const [toggleWarnings, setToggleWarnings] = useState<
    | null
    | {
        message: string;
        raw: string;
        timestamp: number;
        type: string;
      }[]
  >(null);
  const [togglePortalListing] = useMutation(TOGGLE_PORTAL_LISTING, {
    onError: e => setToggleError(e),
    onCompleted: onCompletedData => {
      if (!onCompletedData?.toggle_portal_listing) {
        return;
      }

      if (
        onCompletedData.toggle_portal_listing.last_publication_warnings != null
      ) {
        setToggleWarnings(
          onCompletedData.toggle_portal_listing.last_publication_warnings,
        );
      }

      // Will update the status of the listing on the first publication.
      if (
        onCompletedData.toggle_portal_listing.lot?.status ===
        Lots_Status_Enum_Enum.Draft
      ) {
        updateListingStatus({
          variables: {
            id: onCompletedData.toggle_portal_listing.lot.id,
            status: Lots_Status_Enum_Enum.Available,
          },
        });
      }
    },
    update: (cache, { data: togglePortalData }) => {
      if (!togglePortalData?.toggle_portal_listing) {
        return;
      }

      // Because the action doesn't return portal_listing type but PortalListing type
      cache.modify({
        id: `portal_listings:${togglePortalData.toggle_portal_listing.id}`,
        fields: {
          live() {
            return togglePortalData.toggle_portal_listing?.live ?? false;
          },
        },
      });
    },
  });

  const columns = detailedView
    ? getListingsDetailedViewColumns(t, locale)
    : getListingsTableViewColumns(t);

  // Create an array of my teams ids to sort the portals
  const myTeams = data?.me?.teams_users.map(({ team_id }) => team_id);
  if (data?.portals.length && !detailedView) {
    const portalColumns = [...data.portals]
      // Sort alphabetically first
      .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
      // Then sort my team's portal first and other portals last (only visible to admin users)
      .sort((a, b) => {
        const portalATeams = [
          a.team_id,
          ...a.teams_portals.map(({ team_id }) => team_id),
        ];
        const portalBTeams = [
          b.team_id,
          ...b.teams_portals.map(({ team_id }) => team_id),
        ];
        const aIsMine = myTeams?.some(id => portalATeams.includes(id));
        const bIsMine = myTeams?.some(id => portalBTeams.includes(id));
        return aIsMine && !bIsMine ? -1 : !aIsMine && bIsMine ? 1 : 0;
      })
      .map(
        (portal): GridColDef<Lot> => ({
          field: `portals.${portal.id}`,
          headerAlign: 'center',
          renderHeader: () => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography lineHeight="20px" variant="body2" fontWeight={500}>
                {portal.name ?? 'Portal'}
              </Typography>
              <Typography lineHeight="20px" variant="caption">
                {portal.team?.name ?? 'shared'} (
                {portal.portal_listings_aggregate.aggregate?.count ?? 0}/
                {portal.quota ?? '∞'})
              </Typography>
            </Box>
          ),
          width: 100,
          sortable: false,
          align: 'center',
          display: 'flex',
          renderCell: ({ row }) => {
            const portalListing = row.portal_listings.find(
              ({ portal_id }: { portal_id: string }) => portal_id === portal.id,
            );
            const allowedTeamsIds = [
              portal.team_id,
              ...portal.teams_portals.map(({ team_id }) => team_id),
            ];
            const brokerTeamIds = row.broker?.teams_users.map(
              ({ team_id }) => team_id,
            );
            const disabled = !brokerTeamIds?.some(id =>
              allowedTeamsIds.includes(id),
            );
            const enquiriesCount = row.enquiries.filter(
              ({ portal_id }) => portal_id === portal.id,
            ).length;
            return (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  marginTop: -10,
                }}
              >
                <LoadingCheckBox
                  disabled={disabled}
                  checked={portalListing?.live ?? false}
                  onClick={async e => {
                    e.stopPropagation();
                    const previousValue = portalListing?.live ?? false;

                    return togglePortalListing({
                      variables: {
                        portal_id: portal.id,
                        lot_id: row.id,
                      },
                    }).then(
                      ({ data }) =>
                        data?.toggle_portal_listing?.live ?? previousValue,
                    );
                  }}
                />
                <Typography
                  variant="caption"
                  style={{
                    position: 'absolute',
                    bottom: -10,
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {enquiriesCount}
                </Typography>
              </div>
            );
          },
        }),
      );
    columns.push(...portalColumns);
  }

  return (
    <>
      {!hideTitle && (
        <SmartListTitle tableName="lots" defaultTitle={t('Listings')} />
      )}
      <ListToolbar
        tableName="lots"
        newLink={{
          pathname: 'new',
          search: searchParams.toString(),
        }}
        refetch={refetch}
        searchComponent={
          <LotSelect
            size="small"
            InputProps={{
              startAdornment: <Search />,
            }}
            sx={{ maxWidth: 350, minWidth: 100, flexGrow: 1, ml: 1 }}
            autoFocus
            onChange={lotId => {
              if (!lotId) {
                return;
              }
              navigate(listingLink(lotId));
            }}
          />
        }
        quickFilters={quickFilters(t)}
        viewLinks={[
          {
            name: 'grid',
            icon:
              viewType === 'grid' ? <GridViewRounded /> : <GridViewOutlined />,
          },
          {
            name: 'list',
            icon:
              viewType === 'list' ? (
                <TableRowsRounded />
              ) : (
                <TableRowsOutlined />
              ),
          },
        ]}
        ExportDialog={ListingsExport}
        ImportDialog={ListingsImport}
        bulkActions={
          <>
            {me?.is_admin && (
              <LoadingButton
                disabled={selectedListings.length === 0}
                disableElevation
                onClick={() => {
                  handleDelete(selectedListings as string[]);
                }}
                loading={deleteOptions.loading}
                loadingPosition="start"
                startIcon={<Delete />}
                color="error"
              >
                {t('Delete')}
              </LoadingButton>
            )}
          </>
        }
      />
      {toggleError && (
        <MutationErrorModal
          error={toggleError}
          onClose={() => setToggleError(null)}
        />
      )}
      {toggleWarnings && (
        <PortalPublicationSnackbar
          warnings={toggleWarnings}
          onClose={() => setToggleWarnings(null)}
        />
      )}
      {error ? (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      ) : viewType === 'list' ? (
        <RaDataGrid
          columns={columns}
          rows={rows}
          rowCount={countData?.lots_aggregate.aggregate?.count ?? 0}
          onRowClick={({ id }, e) => {
            if (e.metaKey || e.ctrlKey) {
              window.open(listingLink(id as string).pathname, '_blank');
              return;
            }
            navigate(listingLink(id as string));
          }}
          loading={loading}
          rowHeight={60}
          checkboxSelection={editMode}
          disableRowSelectionOnClick
          onRowSelectionModelChange={newSelection => {
            setSelectedListings(newSelection);
          }}
          columnVisibilityModel={{
            [GRID_CHECKBOX_SELECTION_COL_DEF.field]: editMode,
          }}
          rowSelectionModel={selectedListings}
          pinnedColumns={{
            left: isMobile
              ? undefined
              : [
                  GRID_REORDER_COL_DEF.field,
                  GRID_CHECKBOX_SELECTION_COL_DEF.field,
                  'property.postcode,property.locality,property.route,property.street_number',
                ],
          }}
          rowReordering={reorderable}
          onRowOrderChange={newModel => {
            reorderListing({
              variables: {
                id: newModel.row.id,
                target_position: rows[newModel.targetIndex].order_nr,
              },
            });
          }}
        />
      ) : (
        <GridView
          lots={data?.lots}
          listingLink={listingLink}
          totalCount={countData?.lots_aggregate.aggregate?.count}
          loading={loading}
        />
      )}
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="new/*"
            element={
              <CreateListingDrawer
                developmentId={developmentId}
                onListingCreated={() => {
                  refetch();
                }}
              />
            }
          />
          <Route path=":lotId/*" element={<ListingDrawer />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Listings;
