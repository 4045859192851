import { forwardRef, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';

import { useLocale } from '../../src/hooks/locale';

const SlideUp = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type FileViewerDialogProps = {
  url?: string;
  name: string;
  open: boolean;
  onClose: () => void;
};

const supportObject = !/iPad|iPhone|iPod/.test(navigator.userAgent); // do not show embed viewer on iOS

export const FileViewerDialog: React.FC<FileViewerDialogProps> = ({
  url,
  name,
  open,
  onClose,
}) => {
  const { t } = useLocale();
  const iframe = useMemo(() => {
    const encodedUri = url == null ? null : encodeURIComponent(url);

    return encodedUri == null ? null : (
      <iframe
        title={name}
        css={{ flexGrow: 1 }}
        src={`https://docs.google.com/viewer?url=${encodedUri}&embedded=true`}
      />
    );
  }, [url, name]);

  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUp}
    >
      <AppBar
        position="static"
        sx={theme => ({
          zIndex: theme.zIndex.drawer + 2,
        })}
        elevation={1}
        color="white"
      >
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }} noWrap>
            {name}
          </Typography>
          <Tooltip title={t('Download as {{name}}', { name })}>
            <IconButton
              color="inherit"
              component="a"
              href={url ?? ''}
              disabled={url == null}
              download={true}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {supportObject ? (
        <object
          data={url}
          type="application/pdf"
          css={{ flex: 1, display: 'flex' }}
        >
          {iframe}
        </object>
      ) : (
        iframe
      )}
    </Dialog>
  );
};
