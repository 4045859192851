import { type ReactNode, memo, useCallback, useEffect, useState } from 'react';

import { NetworkStatus, useQuery } from '@apollo/client';
import Add from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { GetLeadsQueryVariables } from '../../__generated__/graphql';
import IndicatorLeadStage, {
  type IndicatorStatus,
} from '../../components/IndicatorLeadStage';
import { KanbanColumn } from '../../components/kanban/KanbanColumn';

import LeadsKanbanCard from './LeadsKanbanCard';
import {
  type Lead,
  type MenuAction,
  PAGE_SIZE,
  buildQueryVars,
} from './LeadsKanbanView';
import { GET_LEADS, GET_LEADS_COUNT } from './leadsQueries';

const LeadsKanbanColumn = memo(
  ({
    where,
    columnId,
    label,
    status,
    onItemClick,
    index,
    onLeadUpdate,
    onItemsChange,
  }: {
    where: GetLeadsQueryVariables['where'];
    columnId: string;
    label: string | ReactNode;
    status: IndicatorStatus;
    onItemClick: (lead: Lead) => void;
    index: number;
    onLeadUpdate: (leadId: string, action: MenuAction) => void;
    onItemsChange: (items: Lead[]) => void;
  }) => {
    const [offset, setOffset] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useLocale();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const createEndControls = useCallback(
      (columnId: string) => (
        <Box p={1}>
          <Button
            onClick={() => {
              searchParams.set('stage_id', columnId);
              setSearchParams(searchParams);
              navigate({
                pathname: './new',
                search: searchParams.toString(),
              });
            }}
            size="small"
            startIcon={<Add />}
            sx={{ mx: 'auto' }}
            fullWidth
          >
            {t('New lead')}
          </Button>
        </Box>
      ),
      [navigate, searchParams, setSearchParams, t],
    );

    const { data, fetchMore, networkStatus } = useQuery(GET_LEADS, {
      variables: buildQueryVars(where, columnId),
      skip: !isVisible,
      notifyOnNetworkStatusChange: true,
    });

    const { data: countData } = useQuery(GET_LEADS_COUNT, {
      variables: {
        where: {
          _and: [where ?? {}, { stage_id: { _eq: columnId } }],
        },
      },
      skip: !isVisible,
      notifyOnNetworkStatusChange: true,
    });

    const leads = data?.leads ?? [];
    const totalCount = countData?.leads_aggregate?.aggregate?.count ?? 0;
    const hasMore = leads.length < totalCount;

    const isFullLoading = [
      NetworkStatus.loading,
      NetworkStatus.refetch,
    ].includes(networkStatus);

    const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

    const handleScroll = useCallback(() => {
      if (!hasMore || isFullLoading || isLoadingMore) {
        return;
      }

      const nextOffset = offset + PAGE_SIZE;

      fetchMore({
        variables: { offset: nextOffset },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setOffset(nextOffset);
          return {
            ...prev,
            leads: [...prev.leads, ...fetchMoreResult.leads],
          };
        },
      });
    }, [fetchMore, hasMore, isFullLoading, isLoadingMore, offset]);

    const handleVisibilityChange = useCallback((visible: boolean) => {
      setIsVisible(visible);
    }, []);

    useEffect(() => {
      if (data?.leads) {
        onItemsChange(data.leads);
      }
    }, [data?.leads, onItemsChange]);

    return (
      <Box position="relative">
        <KanbanColumn
          title={
            <IndicatorLeadStage status={status}>
              {`${label} (${totalCount})`}
            </IndicatorLeadStage>
          }
          colId={columnId}
          items={leads}
          renderItem={(lead: Lead) => (
            <LeadsKanbanCard
              key={lead.id}
              lead={lead}
              onUpdate={action => onLeadUpdate(lead.id, action)}
            />
          )}
          getItemId={(lead: Lead) => lead.id}
          onItemClicked={onItemClick}
          onScroll={handleScroll}
          hasMore={hasMore}
          isLoadingMore={isLoadingMore}
          endControls={createEndControls(columnId)}
          index={index}
          onVisibilityChange={handleVisibilityChange}
          onClickHeaderCheckbox={() => {
            const currentValue = searchParams.get('edit');
            if (currentValue === 'true') {
              searchParams.delete('edit');
            } else {
              searchParams.set('edit', 'true');
            }
            setSearchParams(searchParams);
          }}
        />
        {isFullLoading && (
          <Stack
            sx={{
              position: 'absolute',
              inset: 0,
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1,
            }}
          >
            <CircularProgress disableShrink size={24} />
          </Stack>
        )}
      </Box>
    );
  },
);

export default LeadsKanbanColumn;
