// @flow

import * as React from 'react';

import {
  AppBar,
  Badge,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { type MutateOptions, useMutationWithPolling } from '@realadvisor/relay';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Box, Flex } from 'react-system';

import {
  DrawerBottomToolbar,
  DrawerContent,
  DrawerLayer,
} from '../../controls/drawer';
import { ProgressButton } from '../../controls/progress-button';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { KeyboardArrowLeft } from '../../icons/keyboard-arrow-left';
import { PropertyPhotos } from '../../shared/property-photos';
import type { PropertyInput } from '../__generated__/PropertyFormUpdateMutation.graphql';
import { PropertyForm, type PropertyFormState } from '../PropertyForm';
import { PropertyMortgageForm } from '../PropertyMortgageForm';

import type { PropertyDrawerAppraiseMutation } from './__generated__/PropertyDrawerAppraiseMutation.graphql';
import type { PropertyDrawerAppraiseQuery } from './__generated__/PropertyDrawerAppraiseQuery.graphql';
import type { PropertyDrawerQuery } from './__generated__/PropertyDrawerQuery.graphql';
import { AppraisalListItem } from './AppraisalListItem';

type Props = {|
  propertyId: ?string,
  open: boolean,
  tab?: 'property' | 'photos' | 'appraisals' | 'mortgage',
  onClose: () => void,
  onSave?: () => void,
  handleSubmit?: (PropertyInput, PropertyFormState) => void,
  initialValues?: $Shape<PropertyFormState>,
  disabledFields?: $ReadOnlyArray<$Keys<PropertyFormState>>,
  showAppraisalAlert?: boolean,
  isCreatingProperty?: boolean,
|};

export const availableTypesForCommercial = [
  'indus_commercial',
  'indus_commercial_and_residential',
];

export const useAppraiseProperty = (): [
  (
    options: MutateOptions<
      PropertyDrawerAppraiseMutation,
      PropertyDrawerAppraiseQuery,
    >,
  ) => void,
  boolean,
] => {
  return useMutationWithPolling<
    PropertyDrawerAppraiseMutation,
    PropertyDrawerAppraiseQuery,
  >(
    graphql`
      mutation PropertyDrawerAppraiseMutation($input: AppraisePropertyInput!) {
        appraiseProperty(input: $input) {
          pendingAppraisalId
        }
      }
    `,

    // this query acts as a mutation payload,
    // that's why it has unusued fields (they update data in relay store)
    /* eslint-disable relay/unused-fields */
    graphql`
      query PropertyDrawerAppraiseQuery($appraisalId: ID!) {
        appraisalStatus(id: $appraisalId) {
          isPending
          updatedProperty {
            ...PropertyForm_property
            id
            latestAppraisal {
              ...AppraisalListItem_appraisal
              updatedAt
              realadvisor {
                min
                max
                value
                pricePerSquareMeterLivingSurface
                pricePerSquareMeterBuiltSurface
                rentalValue
              }
              id
            }
            appraisals {
              ...AppraisalListItem_appraisal
              updatedAt
              realadvisor {
                min
                max
                value
                pricePerSquareMeterLivingSurface
                pricePerSquareMeterBuiltSurface
                rentalValue
              }
            }
          }
        }
      }
    `,
    /* eslint-enable relay/unused-fields */

    {
      mutationResponseToVariables: data =>
        data.appraiseProperty?.pendingAppraisalId == null
          ? null
          : { appraisalId: data.appraiseProperty.pendingAppraisalId },
      isDone: data => data.appraisalStatus?.isPending === false,
      initialDelay: 1500,
      basePeriod: 500,
      periodIncreaseRate: 1.3,

      // if it's not done in 10 minutes, no point to continue polling
      maxPollingTime: 1000 * 60 * 10,
    },
  );
};

const PropertyDrawerContent = props => {
  const { t } = useLocale();
  const isNew = props.propertyId == null;
  const isCreatingPropertyFromCma = props.isCreatingProperty ?? false;
  const { colors, text } = useTheme();
  const [fetchKey, retry] = React.useReducer(d => d + 1, 0);

  const data = useLazyLoadQuery<PropertyDrawerQuery>(
    graphql`
      query PropertyDrawerQuery($id: ID!, $skip: Boolean!) {
        ...PropertyForm_root
        ...AppraisalListItem_root

        property: node(id: $id) @skip(if: $skip) {
          ... on Property {
            ...propertyPhotos_property
            ...PropertyForm_property
            id
            formattedAddress
            propertyType {
              mainType
              name
            }
            appraisals {
              ...AppraisalListItem_appraisal
              id
            }
          }
        }
      }
    `,
    { id: props.propertyId ?? '', skip: isNew },
    { fetchKey, fetchPolicy: 'store-and-network' },
  );

  const [appraiseProperty, appraising] = useAppraiseProperty();

  const [tab, setTab] = React.useState<
    'property' | 'photos' | 'appraisals' | 'mortgage',
  >('property');

  const tabFromProps = props.tab ?? 'property';
  React.useEffect(() => {
    setTab(tabFromProps);
  }, [tabFromProps]);

  return (
    <DrawerContent>
      <PropertyForm
        holder="modal"
        property={data.property}
        root={data}
        handleSubmit={props.handleSubmit}
        initialValues={props.initialValues}
        disabledFields={props.disabledFields}
        showAppraisalAlert={props.showAppraisalAlert}
        retry={() => {
          if (props.onSave) {
            props.onSave();
          }
          retry();
        }}
      >
        {({ form, submitForm, resetForm, changed, submitting }) => {
          const canAppraise =
            !isNew &&
            (['HOUSE', 'APPT'].includes(
              data.property?.propertyType?.mainType,
            ) ||
              (data.property?.propertyType?.mainType === 'INDUS' &&
                availableTypesForCommercial.includes(
                  data.property.propertyType.name,
                )));

          const appraiseButton = (
            <ProgressButton
              color="secondary"
              loading={appraising}
              disabled={changed || !canAppraise}
              onClick={() =>
                appraiseProperty({
                  variables: {
                    input: { id: props.propertyId ?? '' },
                  },
                  onCompleted: () => {
                    props.onSave?.();
                    setTab('appraisals');
                  },
                })
              }
            >
              {t('Appraise')}
            </ProgressButton>
          );

          return (
            <Flex flexGrow={1} flexDirection="column">
              <AppBar
                position="sticky"
                color="inherit"
                css={{
                  zIndex: 10,
                }}
              >
                <Toolbar>
                  <IconButton onClick={props.onClose} css={{ marginRight: 8 }}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <Box css={[text.subtitle1, text.truncate(1)]} mr="auto">
                    {data.property?.formattedAddress}
                  </Box>
                  {canAppraise ? (
                    appraiseButton
                  ) : (
                    <Tooltip title={t('propertyAppraisalUnavailable')}>
                      <div>{appraiseButton}</div>
                    </Tooltip>
                  )}
                </Toolbar>

                {data.property && (
                  <Tabs
                    css={{
                      '.MuiTabs-indicator': {
                        backgroundColor: colors.primaryMain,
                      },
                      '.Mui-selected': { color: colors.black },
                    }}
                    variant="fullWidth"
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                  >
                    <Tab value="property" label={t('Property')} />
                    <Tab value="photos" label={t('photos')} disabled={isNew} />
                    <Tab
                      value="appraisals"
                      disabled={isNew}
                      label={
                        <Badge
                          css={{ marginRight: -16, paddingRight: 16 }}
                          color="primary"
                          badgeContent={
                            data.property?.appraisals?.filter(Boolean).length
                          }
                        >
                          {t('appraisals')}
                        </Badge>
                      }
                    />
                    <Tab
                      value="mortgage"
                      disabled={isNew}
                      label={t('mortgage')}
                    />
                  </Tabs>
                )}
              </AppBar>

              {tab === 'property' && (
                <>
                  <Box p={3}>{form}</Box>
                  <DrawerBottomToolbar>
                    <Button
                      disabled={!changed}
                      onClick={() => {
                        resetForm();
                        props.onClose();
                      }}
                    >
                      {t('Cancel')}
                    </Button>
                    <ProgressButton
                      disabled={!changed}
                      loading={submitting}
                      onClick={submitForm}
                    >
                      {isNew && isCreatingPropertyFromCma
                        ? t('create')
                        : t('save')}
                    </ProgressButton>
                  </DrawerBottomToolbar>
                </>
              )}

              {tab === 'photos' && data.property && (
                <PropertyPhotos property={data.property} />
              )}

              {tab === 'appraisals' &&
                (data.property?.appraisals ?? [])
                  .filter(Boolean)
                  .map(appraisal => (
                    <React.Fragment key={appraisal.id}>
                      <AppraisalListItem
                        root={data}
                        appraisal={appraisal}
                        onDelete={retry}
                      />
                      <Divider variant="inset" />
                    </React.Fragment>
                  ))}

              {tab === 'mortgage' && (
                <React.Suspense
                  fallback={
                    <Flex
                      flexGrow={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress size={48} disableShrink={true} />
                    </Flex>
                  }
                >
                  <PropertyMortgageForm
                    propertyId={props.propertyId ?? ''}
                    retry={() => {
                      if (props.onSave) {
                        props.onSave();
                      }
                      retry();
                    }}
                  />
                </React.Suspense>
              )}
            </Flex>
          );
        }}
      </PropertyForm>
    </DrawerContent>
  );
};

export const PropertyDrawer = (props: Props): React.Node => (
  <DrawerLayer
    open={props.open}
    onClose={props.onClose}
    width={['100vw', '80vw', '70vw', '60vw']}
  >
    <React.Suspense
      fallback={
        <Flex
          height="100vh"
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={48} disableShrink={true} />
        </Flex>
      }
    >
      <PropertyDrawerContent
        isCreatingProperty={props.isCreatingProperty}
        propertyId={props.propertyId}
        tab={props.tab}
        handleSubmit={props.handleSubmit}
        initialValues={props.initialValues}
        onSave={props.onSave}
        onClose={props.onClose}
        disabledFields={props.disabledFields}
        showAppraisalAlert={props.showAppraisalAlert}
      />
    </React.Suspense>
  </DrawerLayer>
);
