import * as React from 'react';

import { useQuery } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Accordion, Button, CircularProgress, Stack } from '@mui/material';
import { useMutationWithPolling } from '@realadvisor/relay';
import { graphql, useFragment } from 'react-relay';
import { Box, Flex } from 'react-system';

import AlertPropertyAppraisable from '../../../apollo/components/AlertPropertyAppraisable';
import { GET_PROPERTY_APPRAISABLE } from '../../../apollo/pages/cma-reports/cmaReportsQueries';
import { useAppData } from '../../../apollo/providers/AppDataProvider';
import { fromGlobalId } from '../../../shared/global-id';
import { PropertyDrawer } from '../../components/BasicListItem';
import { BellCurve } from '../../controls/bell-curve';
import { ProgressButton } from '../../controls/progress-button';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import type { Currency, IntlLocale } from '../../locale';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';
import { AppraisalGraph } from '../../shared/appraisal-graph';
import { TemplatesListButtonGroup } from '../../shared/templates-list-button-group';
import { formatPrice } from '../../utils/format-price';

import type { LeadDetailsLatestAppraisalCard_lead$key } from './__generated__/LeadDetailsLatestAppraisalCard_lead.graphql';
import type { LeadDetailsLatestAppraisalCard_root$key } from './__generated__/LeadDetailsLatestAppraisalCard_root.graphql';
import type { LeadDetailsLatestAppraisalCardMutation } from './__generated__/LeadDetailsLatestAppraisalCardMutation.graphql';
import type { LeadDetailsLatestAppraisalCardQuery } from './__generated__/LeadDetailsLatestAppraisalCardQuery.graphql';

type Props = {
  root: LeadDetailsLatestAppraisalCard_root$key;
  lead: LeadDetailsLatestAppraisalCard_lead$key;
  defaultExpanded: boolean;
};

const formatCurrencyRange = (
  range: null | {
    min: null | number;
    max: null | number;
  },
  currency: Currency,
  locale: IntlLocale,
) => {
  if (range && range.min != null && range.max != null) {
    const { min, max } = range;
    const minFormatted = formatPrice(min, locale, currency);
    const maxFormatted = formatPrice(max, locale, currency);
    return `${minFormatted} - ${maxFormatted}`;
  }
  return '-';
};

const formatYield = (
  value: number,
  rent_value: number,
  currency: Currency,
  locale: IntlLocale,
) => {
  const yearly_yield = (12 * rent_value) / value;
  const yieldFormatted = yearly_yield.toLocaleString(locale, {
    style: 'percent',
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const rentFormatted = formatPrice(1 * rent_value, locale, currency);
  return `${rentFormatted} / month • ${yieldFormatted} / year`;
};

export const LeadDetailsLatestAppraisalCard = (props: Props) => {
  const { t, locale } = useLocale();
  const { text, colors } = useTheme();
  const { me } = useAppData();
  const tenantId = me?.tenant_id;

  const [appraiseProperty, appraising] = useMutationWithPolling<
    LeadDetailsLatestAppraisalCardMutation,
    LeadDetailsLatestAppraisalCardQuery
  >(
    graphql`
      mutation LeadDetailsLatestAppraisalCardMutation(
        $input: AppraisePropertyInput!
      ) {
        appraiseProperty(input: $input) {
          pendingAppraisalId
        }
      }
    `,

    // this query acts as a mutation payload,
    // that's why it has unusued fields (they update data in relay store)
    /* eslint-disable relay/unused-fields */
    graphql`
      query LeadDetailsLatestAppraisalCardQuery($appraisalId: ID!) {
        appraisalStatus(id: $appraisalId) {
          isPending
          updatedProperty {
            id
            propertyType {
              mainType
            }
            latestAppraisal {
              ...appraisalGraph_appraisal
              realadvisor {
                min
                max
                value
                pricePerSquareMeterLivingSurface
                pricePerSquareMeterBuiltSurface
                rentalValue
              }
              updatedAt
            }
          }
        }
      }
    `,
    /* eslint-enable relay/unused-fields */

    {
      mutationResponseToVariables: data =>
        data.appraiseProperty?.pendingAppraisalId == null
          ? null
          : { appraisalId: data.appraiseProperty.pendingAppraisalId },
      isDone: data => data.appraisalStatus?.isPending === false,
      initialDelay: 1500,
      basePeriod: 500,
      periodIncreaseRate: 1.3,

      // if it's not done in 10 minutes, no point to continue polling
      maxPollingTime: 1000 * 60 * 10,
    },
  );

  const { tenantSettings, docTemplates } = useFragment(
    graphql`
      fragment LeadDetailsLatestAppraisalCard_root on Query {
        tenantSettings {
          ...appraisalGraph_tenantSettings
          id
          activateModelDetails
        }
        docTemplates(first: 100, filters: { type_in: ["appraisal_report"] }) {
          edges {
            node {
              id
              name
              active
              docTemplatesTenants {
                tenantId
                isDefault
              }
            }
          }
        }
      }
    `,
    props.root,
  );
  const lead = useFragment(
    graphql`
      fragment LeadDetailsLatestAppraisalCard_lead on Lead {
        id
        userCanViewLeadDetails
        property {
          id
          appraisable
          latestAppraisal {
            ...appraisalGraph_appraisal
            realadvisor {
              min
              max
              value
              pricePerSquareMeterBuiltSurface
              pricePerSquareMeterLivingSurface
              rentalValue
            }
            updatedAt
          }
          countryCode
        }
      }
    `,
    props.lead,
  );

  const { data: propertyAppraisable, loading: propertyAppraisableLoading } =
    useQuery(GET_PROPERTY_APPRAISABLE, {
      variables: {
        propertyId:
          lead.property?.id != null ? fromGlobalId(lead.property.id) : '',
      },
      skip: lead.property?.id == null,
    });

  const [expanded, setExpanded] = React.useState(true);
  const [propertyDialog, setPropertyDialog] = React.useState(false);

  const templateNodes =
    docTemplates?.edges?.map(edge => ({
      id: edge?.node.id ?? '',
      name: edge?.node.name ?? '',
      isDefault:
        edge?.node.docTemplatesTenants?.some(
          tenant => tenant?.tenantId === tenantId && tenant?.isDefault,
        ) ?? false,
      active: edge?.node.active ?? true,
    })) ?? [];

  const property = lead.property;
  const latestAppraisal = property?.latestAppraisal;
  const currency = property?.countryCode === 'CH' ? 'CHF' : 'EUR';

  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={t('Latest appraisal')}
        subTitle={
          latestAppraisal?.realadvisor?.value != null &&
          property?.appraisable === true
            ? formatPrice(latestAppraisal.realadvisor.value, locale, currency)
            : ''
        }
      />
      {propertyAppraisableLoading ? (
        <Stack flexGrow={1} justifyContent="center" alignItems="center" p={3}>
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <>
          {!propertyAppraisable?.property_appraisable?.appraisable.result && (
            <Flex
              flexDirection="column"
              flexWrap="wrap"
              p={3}
              // alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <AlertPropertyAppraisable
                errors={
                  propertyAppraisable?.property_appraisable?.appraisable
                    ?.errors ?? []
                }
              />

              <Button
                css={{
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                  fontSize: text.size(14),
                  marginTop: 16,
                }}
                size="small"
                endIcon={<ChevronRight />}
                onClick={() => setPropertyDialog(true)}
              >
                {t('editProperty')}
              </Button>
            </Flex>
          )}
          {propertyAppraisable?.property_appraisable?.appraisable.result && (
            <Box px={3} pb={3}>
              {latestAppraisal != null && property != null && (
                <Box width={1} px={3} css={{ textAlign: 'center' }}>
                  <div css={text.subtitle1}>
                    {formatCurrencyRange(
                      latestAppraisal.realadvisor,
                      currency,
                      locale,
                    )}
                  </div>
                  {latestAppraisal.updatedAt != null && (
                    <div css={[text.caption, { color: colors.grey700 }]}>
                      {[
                        formatPrice(
                          latestAppraisal.realadvisor?.value ?? 0,
                          locale,
                          currency,
                        ),
                        `${formatPrice(
                          latestAppraisal.realadvisor
                            ?.pricePerSquareMeterLivingSurface ??
                            latestAppraisal.realadvisor
                              ?.pricePerSquareMeterBuiltSurface ??
                            0,
                          locale,
                          currency,
                        )} / m²`,
                      ].join(' • ')}
                    </div>
                  )}
                  {latestAppraisal.updatedAt != null &&
                    latestAppraisal.realadvisor != null &&
                    latestAppraisal.realadvisor.value != null &&
                    latestAppraisal.realadvisor.rentalValue != null && (
                      <div css={[text.caption, { color: colors.grey700 }]}>
                        {formatYield(
                          latestAppraisal.realadvisor.value,
                          latestAppraisal.realadvisor.rentalValue,
                          currency,
                          locale,
                        )}
                      </div>
                    )}
                </Box>
              )}

              <Box width={1} p={3}>
                {lead.property &&
                  lead.property.latestAppraisal &&
                  tenantSettings?.activateModelDetails === true && (
                    <AppraisalGraph
                      tenantSettings={tenantSettings}
                      appraisal={lead.property.latestAppraisal}
                    />
                  )}

                {lead.property &&
                  lead.property.latestAppraisal &&
                  tenantSettings?.activateModelDetails !== true && (
                    <BellCurve
                      percentile10={
                        lead.property.latestAppraisal.realadvisor?.min ?? 0
                      }
                      percentile90={
                        lead.property.latestAppraisal.realadvisor?.max ?? 0
                      }
                      median={
                        lead.property.latestAppraisal.realadvisor?.value ?? 0
                      }
                      currency={
                        lead.property.countryCode === 'CH' ? 'CHF' : '€'
                      }
                    />
                  )}
              </Box>

              {lead.userCanViewLeadDetails && (
                <Flex
                  flexWrap="wrap"
                  justifyContent="center"
                  width={1}
                  // css={{ marginTop: -8, marginLeft: -4 }}
                >
                  <Stack mt={2} direction="row" spacing={1}>
                    <ProgressButton
                      variant="contained"
                      size="small"
                      loading={appraising}
                      onClick={() => {
                        if (lead.property) {
                          appraiseProperty({
                            variables: {
                              input: {
                                id: lead.property.id,
                              },
                            },
                          });
                        }
                      }}
                    >
                      {t('Appraise')}
                    </ProgressButton>
                    <Button
                      color="inherit"
                      variant="outlined"
                      size="small"
                      disabled={appraising}
                      onClick={() => setPropertyDialog(true)}
                    >
                      {t('Edit')}
                    </Button>
                    <TemplatesListButtonGroup
                      documentId={lead.id}
                      label={t('Report')}
                      size="small"
                      docTemplates={templateNodes}
                      disabled={appraising}
                    />
                  </Stack>
                </Flex>
              )}
            </Box>
          )}
        </>
      )}

      <PropertyDrawer
        propertyId={lead.property?.id ?? ''}
        open={propertyDialog}
        onClose={() => setPropertyDialog(false)}
      />
    </Accordion>
  );
};
