// @flow

import * as React from 'react';

import {
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Downshift from 'downshift';
import { graphql } from 'react-relay';

import { InputPopup } from '../../controls/popup';
import { useLocale } from '../../hooks/locale';
import { useInputQuery } from '../../hooks/relay';
import { Map } from '../../icons/map';

import type { TypeOfPlace } from './__generated__/placePicker_place.graphql';
import type { placePickerInputQuery } from './__generated__/placePickerInputQuery.graphql';
import { PlaceListItem } from './place-list-item';
import type { PlacePickerPlace } from './place-picker';

type Props = {|
  showSwitcher: boolean,
  value: $ReadOnlyArray<PlacePickerPlace>,
  onSwitchView: () => void,
  onChange: ($ReadOnlyArray<PlacePickerPlace>) => void,
  allowedPlaceTypes?: $ReadOnlyArray<TypeOfPlace>,
|};

// Max length for `places(search)` argumnet. Defined here:
// https://github.com/realadvisor/realadvisor/blob/acfdbfb3fb681db2938b22c5deed4d5aab0c9533/realadvisor.api/src/schema/gis/queries.js#L96
const MAX_SEARCH_LENGTH = 50;

export const PlacePickerInput = ({
  showSwitcher,
  value,
  onSwitchView,
  onChange,
  allowedPlaceTypes = [
    'district',
    'locality',
    'municipality',
    'neighbourhood',
    'postcode',
    'region',
    'state',
  ],
}: Props): React.Node => {
  const [data, refetch] = useInputQuery<placePickerInputQuery>(
    graphql`
      query placePickerInputQuery(
        $search: String
        $allowedPlaceTypes: [TypeOfPlace]
      ) {
        places(search: $search, limit: 20, placeType_in: $allowedPlaceTypes) {
          ...placePicker_place @relay(mask: false)
          ...placeListItem_place
        }
      }
    `,
  );
  const { t } = useLocale();
  const targetRef = React.useRef(null);
  const items = (data?.places ?? []).filter(
    x => value.some(ex => ex.objectId === x.objectId) === false,
  );
  return (
    <Downshift
      itemToString={() => ''}
      onInputValueChange={inputValue =>
        refetch({ search: inputValue, allowedPlaceTypes })
      }
      onChange={selectedItem => {
        if (selectedItem != null) {
          const newItem = {
            id: selectedItem.id,
            type: selectedItem.type,
            objectId: selectedItem.objectId,
            lat: selectedItem.lat,
            lng: selectedItem.lng,
            label: selectedItem.label,
          };
          onChange([...value, newItem]);
        }
      }}
    >
      {downshift => {
        const { getInputProps, getMenuProps, getItemProps } = downshift;
        const { isOpen, inputValue, highlightedIndex } = downshift;
        const { openMenu } = downshift;
        return (
          <div>
            <FilledInput
              inputProps={{ maxLength: MAX_SEARCH_LENGTH }}
              {...getInputProps({
                ref: targetRef,
                onFocus: () => {
                  refetch({ search: inputValue, allowedPlaceTypes });
                  openMenu();
                },
                endAdornment: showSwitcher && (
                  <InputAdornment position="end">
                    <IconButton onClick={onSwitchView}>
                      <Map />
                    </IconButton>
                  </InputAdornment>
                ),
              })}
            />
            <InputPopup referenceRef={targetRef} open={isOpen}>
              <List {...getMenuProps()} dense={true}>
                {items.length === 0 && (
                  <ListItem>
                    <ListItemText secondary={t('No options')} />
                  </ListItem>
                )}
                {isOpen &&
                  items.map((item, index) => (
                    <ListItem
                      {...getItemProps({ item })}
                      key={index}
                      button={true}
                      selected={highlightedIndex === index}
                    >
                      <PlaceListItem place={item} />
                    </ListItem>
                  ))}
              </List>
            </InputPopup>
          </div>
        );
      }}
    </Downshift>
  );
};
