// @flow

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { graphql, useFragment, useMutation } from 'react-relay';
import { useResponsive } from 'react-system';

import { ProgressButton } from '../controls/progress-button';
import { useLocale } from '../hooks/locale';

import type { leadDeleteDialog_lead$key } from './__generated__/leadDeleteDialog_lead.graphql';
import type { leadDeleteDialogMutation } from './__generated__/leadDeleteDialogMutation.graphql';
import { LeadSnippet } from './lead-snippet';

type Props = {|
  open: boolean,
  lead: leadDeleteDialog_lead$key,
  onClose: () => void,
  onDelete: () => void,
|};

export const LeadDeleteDialog = (props: Props): React.Node => {
  const { open, onClose, onDelete } = props;
  const responsive = useResponsive();
  const { t } = useLocale();
  const [deleteLeads, deletingLeads] = useMutation<leadDeleteDialogMutation>(
    graphql`
      mutation leadDeleteDialogMutation($input: DeleteLeadsInput!) {
        deleteLeads(input: $input) {
          affectedIds
        }
      }
    `,
  );

  const lead = useFragment(
    graphql`
      fragment leadDeleteDialog_lead on Lead {
        ...leadSnippet_lead
        id
      }
    `,
    props.lead,
  );
  return (
    <Dialog
      fullScreen={responsive([true, false])}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('areYouSureToDeleteLead')}</DialogTitle>
      <DialogContent>
        <LeadSnippet lead={lead} hasLink={false} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <ProgressButton
          color="secondary"
          variant="contained"
          onClick={() => {
            deleteLeads({
              variables: {
                input: { where: { id_in: [lead.id] } },
              },
              onCompleted: onDelete,
            });
          }}
          loading={deletingLeads}
        >
          {t('yes')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};
