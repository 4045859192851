// @flow

import * as React from 'react';

import {
  IconButton,
  FilledInput as Input,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useResizeRect } from '@realadvisor/observe';
import Downshift from 'downshift';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { Box } from 'react-system';
import { FixedSizeList } from 'react-window';

import { focusWrappedInputRef } from '../controls/popup';
import { useWindowPagination } from '../hooks/relay';
import { Cancel } from '../icons/cancel';

import type { userSectionInput_root$key } from './__generated__/userSectionInput_root.graphql';
import type {
  UserSearchFilters,
  userSectionInputQuery,
} from './__generated__/userSectionInputQuery.graphql';
import userSectionInputQueryRefetchableNode from './__generated__/userSectionInputQueryRefetchable.graphql';
import type { userSectionInputQueryRefetchable } from './__generated__/userSectionInputQueryRefetchable.graphql';
import { UserAvatar, getUserName } from './user-input';
import type { User } from './user-input';

type Props = {|
  creatable?: boolean,
  clearable?: boolean,
  autoFocus?: boolean,
  focusOnDelete?: boolean,
  filters?: UserSearchFilters,
  onChange: (?User) => void,
  onBlur?: () => void,
  showSubscriptionStatus?: boolean,
  sections: $ReadOnlyArray<{|
    title: string,
    filter: (node: User) => boolean,
    sort?: (a: any, b: any) => number,
    notFoundMessage?: string,
  |}>,
  value?: User | null,
|};

const UserItem = props => {
  const { data, index, style } = props;
  const { getItemProps, highlightedIndex, showSubscriptionStatus } = data;
  const item = data.sections[index];

  const ListItemProps = {
    ...getItemProps({
      item,
      index,
    }),
    key: index,
    button: true,
    selected: highlightedIndex === index,
    style,
  };

  if (item != null && item.type === 'title') {
    return (
      <ListItem {...ListItemProps} disabled={true} onClick={() => {}}>
        <ListSubheader>{item.title}</ListSubheader>
      </ListItem>
    );
  }

  if (item != null && item.type === 'error') {
    return (
      <ListItem {...ListItemProps} disabled={true} onClick={() => {}}>
        <ListItemText primary={item.message} />
      </ListItem>
    );
  }

  return (
    <ListItem {...ListItemProps}>
      <UserAvatar
        user={item}
        size={40}
        showSubscriptionStatus={showSubscriptionStatus}
      />
      <ListItemText
        primary={getUserName(item)}
        secondary={item?.primaryEmail?.email}
      />
    </ListItem>
  );
};

const UserList = ({
  sections,
  getItemProps,
  highlightedIndex,
  showSubscriptionStatus,
  initialVariables,
  refetchRef,
}) => {
  const containerRef = React.useRef(null);
  const containerRect = useResizeRect(containerRef);

  const queryData = useLazyLoadQuery<userSectionInputQuery>(
    graphql`
      query userSectionInputQuery($count: Int!, $filters: UserSearchFilters) {
        ...userSectionInput_root @arguments(count: $count, filters: $filters)
      }
    `,
    initialVariables,
  );

  const paginationFragment = usePaginationFragment<
    userSectionInputQueryRefetchable,
    userSectionInput_root$key,
  >(
    graphql`
      fragment userSectionInput_root on Query
      @refetchable(queryName: "userSectionInputQueryRefetchable")
      @argumentDefinitions(
        count: { type: "Int!" }
        cursor: { type: "String", defaultValue: null }
        search: { type: "String", defaultValue: null }
        filters: { type: "UserSearchFilters", defaultValue: null }
      ) {
        usersSearch(
          first: $count
          after: $cursor
          search: $search
          filters: $filters
        ) @connection(key: "userSectionInputQueryConnection_usersSearch") {
          edges {
            node {
              ...userInput_user @relay(mask: false)
            }
          }
        }
      }
    `,
    queryData,
  );

  const { data, refetch, loadUntil } = useWindowPagination(
    paginationFragment,
    data => data.usersSearch?.edges?.length ?? 0,
    userSectionInputQueryRefetchableNode,
  );

  refetchRef.current = refetch;

  const users = [];
  for (const edge of data.usersSearch?.edges ?? []) {
    if (edge?.node != null) {
      users.push(edge.node);
    }
  }

  const sectionsDefined = sections
    .map(section => {
      const { filter, sort } = section;
      const allUsers = users.filter(filter).sort(sort);
      const titleFormat = section?.title
        ? [
            {
              type: 'title',
              title: section.title,
            },
          ]
        : [];
      return [
        ...titleFormat,
        ...(allUsers.length === 0
          ? [
              {
                disabled: true,
                message: section.notFoundMessage ?? '',
                type: 'error',
              },
            ]
          : allUsers ?? []),
      ];
    })
    .flat();

  return (
    <Box flexGrow={1} ref={containerRef}>
      {containerRect && (
        <FixedSizeList
          width={containerRect.width}
          height={500}
          itemSize={64}
          itemCount={sectionsDefined.length}
          overscanCount={20}
          itemData={{
            sections: sectionsDefined,
            getItemProps,
            highlightedIndex,
            showSubscriptionStatus,
          }}
          onItemsRendered={({ overscanStopIndex }) =>
            loadUntil(overscanStopIndex)
          }
        >
          {UserItem}
        </FixedSizeList>
      )}
    </Box>
  );
};

export const UserSectionInput = ({
  sections,
  showSubscriptionStatus,
  clearable = true,
  focusOnDelete = true,
  onChange,
  value,
  onBlur,
  autoFocus,
  filters,
}: Props): React.Node => {
  const initialVariables = {
    count: 10,
    filters,
  };

  const refetchRef = React.useRef(null);

  const targetRef = React.useRef(null);
  return (
    <Downshift
      selectedItem={value ?? null}
      itemToString={getUserName}
      onInputValueChange={inputValue => {
        refetchRef.current?.({ search: inputValue, ...initialVariables });
      }}
      onChange={selectedItem => {
        if (selectedItem == null) {
          onChange(null);
        } else {
          onChange({
            ...selectedItem,
            organisation:
              selectedItem.organisation != null
                ? {
                    name: selectedItem.organisation.name,
                    formattedAddress:
                      selectedItem.organisation.formattedAddress,
                  }
                : null,
          });
        }
      }}
    >
      {downshift => {
        const {
          getInputProps,
          inputValue,
          selectedItem,
          getItemProps,
          highlightedIndex,
          isOpen,
        } = downshift;
        const { openMenu } = downshift;
        return (
          <div>
            <Input
              {...getInputProps({
                ref: targetRef,
                onFocus: () => {
                  refetchRef.current?.({
                    search: inputValue,
                    filters,
                    count: 10,
                  });
                  openMenu();
                },
                onBlur,
                autoFocus,
                startAdornment: selectedItem != null && (
                  <InputAdornment position="start">
                    <UserAvatar user={selectedItem} size={24} />
                  </InputAdornment>
                ),
                endAdornment: clearable && selectedItem && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        if (focusOnDelete) {
                          focusWrappedInputRef(targetRef);
                        }
                        onChange(null);
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
              })}
            />
            {isOpen && (
              <React.Suspense fallback={null}>
                <UserList
                  sections={sections}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  showSubscriptionStatus={showSubscriptionStatus}
                  refetchRef={refetchRef}
                  initialVariables={initialVariables}
                />
              </React.Suspense>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};
