import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import type { DeepPartial } from 'react-hook-form';

import { useLocale } from '../../../../src/hooks/locale';
import type { Properties_Insert_Input } from '../../../__generated__/graphql';
import { GET_PROPERTY_TYPES } from '../../../common/queries';
import { AddUserModal } from '../../../components/AddUserModal';
import type { RaAddressType } from '../../../components/form/RaAddressInput';
import {
  type FormDefinitionType,
  RaForm,
} from '../../../components/form/RaForm';
import { MutationErrorSnackbar } from '../../../components/MutationErrorModal';
import { createPropertyTypeOptions } from '../../../components/property-form/forms-definitions/propertyDetailsFormDefinition';
import { useCreateLead } from '../../../hooks/useCreateLead';

import { useCreateCMAReport } from './utils';

type CMAFromScratchForm = RaAddressType &
  Pick<Properties_Insert_Input, 'property_type_id'> & {
    contact_id: string;
    main_type: any;
  };

type CreateCMAFromScratchProps = {
  onClose: () => void;
  onCreated: (newCMAReportId: string) => void;
};

const CreateCMAFromScratch = ({
  onClose,
  onCreated,
}: CreateCMAFromScratchProps) => {
  const { countryCode } = useLocale();

  const [leadApolloError, _leadLoading, createNewLead, resetLeadError] =
    useCreateLead();
  const [
    cmaReportApolloError,
    _cmaReportLoading,
    createCMAReport,
    resetCmaReportError,
  ] = useCreateCMAReport();

  const [valuesOverride, setValuesOverride] = useState<
    DeepPartial<CMAFromScratchForm>
  >({});

  const [userCreationData, setUserCreationData] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
  } | null>(null);

  const { data } = useQuery(GET_PROPERTY_TYPES);
  const property_types = data?.property_types ?? [];

  const fromScratchFormDefinition: FormDefinitionType<CMAFromScratchForm> = ({
    t,
  }) => [
    {
      label: t('Contact'),
      name: 'contact_id',
      type: 'user',
      createUserSelected: userData => {
        const newUser: {
          firstName?: string;
          lastName?: string;
          email?: string;
        } = {};

        if (userData.first_name != null) {
          newUser.firstName = userData.first_name;
        }

        if (userData.last_name != null) {
          newUser.lastName = userData.last_name;
        }

        if (userData.email != null) {
          newUser.email = userData.email;
        }

        setUserCreationData(newUser);
      },
    },
    {
      name: 'category title',
      label: t('Property address'),
      type: 'category-title',
    },
    {
      label: t('Address'),
      type: 'address',
      required: true,
      countryRestriction: countryCode,
      requiredFields: ({ main_type }) => ({
        street_number: main_type !== 'PROP',
        country_code: true,
        country: true,
      }),
    },
    {
      name: 'main_type',
      label: t('Property main type'),
      type: 'select',
      options: () =>
        createPropertyTypeOptions(
          property_types,
          pt => pt.type === 'main',
          'name',
        ),
      required: true,
    },
    {
      name: 'property_type_id',
      label: t('Property type'),
      type: 'select',
      options: ({ main_type }) =>
        createPropertyTypeOptions(
          property_types,
          pt => pt.main_type === main_type && pt.type === 'simple',
          'id',
        ),
      disabled: ({ main_type }) => main_type == null,
      required: true,
    },
  ];

  const handleSubmit = async (values: CMAFromScratchForm) => {
    const createdLeadId = await createNewLead({
      contact_id: values.contact_id,
      property: {
        lat: values.lat,
        lng: values.lng,
        route: values.route,
        street_number: values.street_number,
        postcode: values.postcode,
        state: values.state,
        locality: values.locality,
        country_code: values.country_code,
        property_type_id: values.property_type_id,
      },
    });

    if (!createdLeadId) {
      return null;
    }

    const newCMAReportId = await createCMAReport({
      leadId: createdLeadId,
      contactId: values.contact_id,
    });

    if (!newCMAReportId) {
      return null;
    }

    onCreated(newCMAReportId);
  };

  const handleResetError = () => {
    resetLeadError();
    resetCmaReportError();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minHeight: 0,
        }}
      >
        <RaForm
          formDefinition={fromScratchFormDefinition}
          onSubmit={handleSubmit}
          valuesOverride={valuesOverride}
          onCancel={onClose}
        />
        <AddUserModal
          opened={userCreationData != null}
          user={userCreationData ?? undefined}
          onClose={user => {
            setUserCreationData(null);
            if (user != null) {
              setValuesOverride({ contact_id: user.id });
            }
          }}
        />
      </Box>

      <MutationErrorSnackbar
        error={leadApolloError || cmaReportApolloError}
        onClose={handleResetError}
      />
    </>
  );
};

export default CreateCMAFromScratch;
