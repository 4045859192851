import * as React from 'react';
import { useCallback, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  type InputProps,
  type SxProps,
  TextField,
  type Theme,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__/';
import {
  type Dictionaries_Types_Enum_Enum,
  type DictionarySelectFragment,
  type GetDictionariesQuery,
} from '../../__generated__/graphql';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

gql(/* GraphQL */ `
  fragment DictionarySelect on dictionaries {
    id
    name
    label
    type
  }
`);

const GET_DICTIONARY = gql(/* GraphQL */ `
  query GetDictionaries($type: dictionaries_types_enum_enum!) {
    dictionaries(where: { type: { _eq: $type } }) {
      ...DictionarySelect
    }
  }
`);

interface DictionarySelectPropsBase {
  label?: string;
  dictionaryType: Dictionaries_Types_Enum_Enum;
  size?: 'small' | 'medium';
  excludeDictionariesIds?: string[];
  sx?: SxProps<Theme>;
  InputProps?: InputProps;
  autoFocus?: boolean;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  blurOnSelect?: boolean;
  clearOnBlur?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  valueField?: 'id' | 'name';
}
interface DictionarySelectPropsSingle extends DictionarySelectPropsBase {
  multiple?: false;
  onChange?: (value: string | null) => void;
  defaultValue?: string | null;
}
interface DictionarySelectPropsMultiple extends DictionarySelectPropsBase {
  multiple: true;
  onChange?: (value: string[]) => void;
  defaultValue?: string[];
}

type DictionarySelectProps =
  | DictionarySelectPropsSingle
  | DictionarySelectPropsMultiple;

export const DictionarySelect = ({
  onChange,
  InputProps,
  dictionaryType,
  autoFocus = false,
  size = 'medium',
  sx,
  disabled = false,
  label,
  fullWidth = false,
  blurOnSelect = false,
  clearOnBlur = false,
  multiple = false,
  disableCloseOnSelect,
  defaultValue = multiple ? [] : null,
  valueField = 'name',
}: DictionarySelectProps) => {
  const { t } = useLocale();

  const [getDictionaries, { loading, error, data }] =
    useLazyQuery<GetDictionariesQuery>(GET_DICTIONARY, {
      variables: {
        type: dictionaryType,
      },
    });

  // if defaultValue is provided, set it as selected value query dictionaries
  useEffect(() => {
    if (
      (multiple && (defaultValue?.length ?? 0) > 0) ||
      (!multiple && defaultValue != null)
    ) {
      getDictionaries();
    }
  }, [defaultValue, getDictionaries, multiple]);

  const getDefaultOptions = useCallback(() => {
    if (multiple) {
      const dictionaryMap = new Map(
        (data?.dictionaries ?? []).map(x => [x[valueField], x]),
      );
      return (defaultValue as string[])
        ?.map(value => dictionaryMap.get(value))
        .filter((x): x is DictionarySelectFragment => x != null);
    } else {
      return (
        (data?.dictionaries ?? []).find(
          x => x[valueField] === (defaultValue as string),
        ) ?? null
      );
    }
  }, [data, defaultValue, multiple, valueField]);

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    value: DictionarySelectFragment[] | DictionarySelectFragment | null,
  ) => {
    if (multiple) {
      (onChange as DictionarySelectPropsMultiple['onChange'])?.(
        (value as DictionarySelectFragment[]).map(x => x[valueField]) ?? [],
      );
    } else {
      (onChange as DictionarySelectPropsSingle['onChange'])?.(
        (value as DictionarySelectFragment)?.[valueField] ?? null,
      );
    }
  };

  return (
    <Autocomplete
      sx={sx}
      disabled={disabled}
      autoComplete={false}
      autoCorrect="off"
      onOpen={() => getDictionaries()}
      value={getDefaultOptions()}
      fullWidth={fullWidth}
      clearOnBlur={clearOnBlur}
      blurOnSelect={blurOnSelect}
      isOptionEqualToValue={(option, value) =>
        option[valueField] === value[valueField]
      }
      disableCloseOnSelect={disableCloseOnSelect ?? multiple}
      options={data?.dictionaries ?? []}
      multiple={multiple}
      loading={loading}
      loadingText={t('Loading...')}
      noOptionsText={error ? t('Error') : t('No results')}
      onChange={handleChange}
      renderOption={
        multiple
          ? (props, option, { selected }) => {
              const { id, ...optionProps } = props;
              return (
                <li key={id} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              );
            }
          : undefined
      }
      componentsProps={{
        paper: {
          elevation: 3,
          sx: {
            borderRadius: '4px',
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          autoFocus={autoFocus}
          placeholder={InputProps?.placeholder ?? t('Search')}
          variant="outlined"
          sx={{ background: 'white' }}
          size={size}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'one-time-code', // disable autocomplete and autofill
            type: 'new-password',
            name: 'new-password',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} disableShrink />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
