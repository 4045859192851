import { gql } from '../../__generated__';

export const ActivityCellFragment = gql(/* GraphQL */ `
  fragment ActivityCell on activities {
    id
    created_at
    due_at
    done_at
    start_date
    end_date
    done
    success
    subject
    activity_type
    note
  }
`);

export const GET_LEADS = gql(/* GraphQL */ `
  query GetLeads(
    $limit: Int
    $offset: Int
    $where: leads_bool_exp
    $order_by: [leads_order_by!] = [{ requalified_or_created_at: asc }]
  ) {
    leads(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      created_at
      created_by
      requalified_at
      requalified_or_created_at
      appraisal_reason
      sale_horizon
      buy_horizon
      appraisal_perceived_value
      appraisal_perception
      relationship
      completed
      mandate_probability
      pos_within_stage
      predicted_listing_date
      lots {
        id
      }
      stage {
        id
        label
        status
      }
      source {
        label
      }
      landing_page_url
      contact {
        id
        first_name
        last_name
        ...UserInfo_user
      }
      broker {
        id
        first_name
        last_name
        ...UserInfo_user
      }
      lead_agents {
        id
        claimed_at
        user_id
        source
        user {
          id
          ...UserAvatar
        }
      }
      claimed_by
      next_activity: activities(
        limit: 1
        where: {
          done: { _eq: false }
          activity_type: { _in: [call, visit, task, assignment] }
        }
        order_by: { due_at: asc }
      ) {
        id
        ...ActivityCell
      }
      last_activity: activities(
        limit: 1
        where: {
          done: { _eq: true }
          activity_type: { _in: [note, call, visit, assignment, task] }
        }
        order_by: { consolidated_done_at: desc }
      ) {
        id
        ...ActivityCell
      }
      property {
        id
        route
        street_number
        locality
        postcode
        land_surface
        living_surface
        built_surface
        property_type {
          id
          label
          main_type
        }
        latest_appraisal {
          id
          min
          value
          max
        }
      }
      stage {
        id
        label
        status
        pipeline {
          id
          lead_type
        }
      }
      updated_at
    }
  }
`);

export const GET_LEADS_COUNT = gql(/* GraphQL */ `
  query GetLeadsCount($where: leads_bool_exp, $limit: Int) {
    leads_aggregate(where: $where, limit: $limit) {
      aggregate {
        count
      }
    }
  }
`);

export const GET_LEAD = gql(/* GraphQL */ `
  query GetLead($lead_id: uuid!) {
    leads_by_pk(id: $lead_id) {
      id
      tenant_id
      ...MarketingCardLeadFragment
      appraisal_reason
      appraisal_perception
      appraisal_perceived_value
      property {
        id
        latest_appraisal {
          id
          value
        }
      }
      lots {
        id
      }
      broker {
        id
        ...UserCard_user
        default_team {
          id
          name
        }
      }
      contact {
        id
        ...ContactCardUserFragment
      }
    }
  }
`);

export const LEAD_FEED = gql(/* GraphQL */ `
  query LeadFeed(
    $user_id: uuid!
    $email_addresses: [String!]
    $skip_email_activities: Boolean!
  ) {
    user_activities: activities(
      where: {
        # Skip activities that are already included in lead_activites
        lead_id: { _is_null: true }
        user_id: { _eq: $user_id }
        # Skip activities that are already included in email_activities
        activity_type: { _neq: email }
      }
      order_by: { created_at: desc }
      limit: 250
    ) {
      ...activityFields
    }
    lead_activites: activities(
      where: {
        lead: { contact_id: { _eq: $user_id } }
        # Skip activities that are already included in email_activities
        activity_type: { _neq: email }
      }
      order_by: { created_at: desc }
      limit: 250
    ) {
      ...activityFields
    }
    email_activities: activities(
      where: {
        activities_target_users: { email: { _in: $email_addresses } }
        # Only query email_activities
        activity_type: { _eq: email }
      }
      order_by: { created_at: desc }
      limit: 250
    ) @skip(if: $skip_email_activities) {
      ...activityFields
    }
  }
`);

export const ASSIGN_LEAD = gql(/* GraphQL */ `
  mutation AssignLead($lead_id: uuid!, $broker_id: uuid) {
    update_leads_by_pk(
      pk_columns: { id: $lead_id }
      _set: { broker_id: $broker_id }
    ) {
      id
    }
    insert_activities_one(
      object: {
        lead_id: $lead_id
        assigned_to: $broker_id
        activity_type: assignment
      }
    ) {
      id
    }
  }
`);

export const GET_LEAD_AGENTS = gql(/* GraphQL */ `
  query GetLeadAgents($lead_id: uuid!) {
    lead_agents(
      where: { lead_id: { _eq: $lead_id } }
      order_by: { created_at: asc }
    ) {
      id
      created_at
      claimed_at
      passed_at
      source
      win_probability
      predicted_win_date
      lead_stage {
        id
        name
        label
        status
      }
      user {
        id
        ...UserAvatar
        first_name
        last_name
        default_team {
          name
        }
      }
      lead {
        id
        tenant_id
      }
    }
  }
`);

export const ADD_LEAD_AGENT = gql(/* GraphQL */ `
  mutation AddLeadAgent($lead_id: uuid!, $user_id: uuid!) {
    insert_lead_agents_one(
      object: { lead_id: $lead_id, user_id: $user_id, source: crm }
    ) {
      id
    }
    insert_activities_one(
      object: {
        lead_id: $lead_id
        assigned_to: $user_id
        activity_type: assignment
      }
    ) {
      id
    }
  }
`);

export const DELETE_LEAD_AGENT = gql(/* GraphQL */ `
  mutation DeleteLeadAgent($id: uuid!) {
    delete_lead_agents_by_pk(id: $id) {
      id
    }
  }
`);

export const GET_PIPELINE_STAGES = gql(/* GraphQL */ `
  query GetPipelineStages($pipeline_id: uuid!) {
    pipelines_by_pk(id: $pipeline_id) {
      id
      stages(order_by: { order_nr: asc }) {
        id
        label
        status
        pipeline {
          id
          lead_type
        }
      }
    }
  }
`);

export const UPDATE_LEAD = gql(/* GraphQL */ `
  mutation UpdateLead($id: uuid!, $set: leads_set_input!) {
    update_leads_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      stage_id
      stage {
        id
        label
      }
    }
  }
`);
