import {
  type CSSProperties,
  type UIEventHandler,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  useTheme,
} from '@mui/material';
import type { SystemStyleObject } from '@mui/system';
import {
  type ListChildComponentProps,
  type ListOnScrollProps,
  VariableSizeList,
} from 'react-window';

import { useLocale } from '../../../src/hooks/locale';

import { useKanbanInstanceId, useKanbanSelectedItems } from './KanbanBoard';
import { KanbanItem } from './KanbanItem';

type KanbanColumnProps<Item> = {
  title: React.ReactNode;
  colId: string;
  items: Item[];
  renderItem: (data: Item) => React.ReactNode;
  getItemId: (item: Item) => string;
  width?: number;
  topControls?: React.ReactNode;
  index: number;
  onItemClicked?: (item: Item) => void;
  endControls?: React.ReactNode;
  onClickHeaderCheckbox?: () => void;
  onScroll?: () => void;
  isLoadingMore?: boolean;
  hasMore?: boolean;
  onVisibilityChange?: (visible: boolean) => void;
  onHeightCalculated?: (height: number, index: number) => void;
};

type TitleProps = {
  label: React.ReactNode;
  hideArrow?: boolean;
  itemsIds: string[];
  onClickHeaderCheckbox?: () => void;
};

const TITLE_HEIGHT = 48;
const ARR_HEIGHT = TITLE_HEIGHT / Math.sqrt(2);

const Title = ({
  label,
  hideArrow = false,
  itemsIds,
  onClickHeaderCheckbox,
}: TitleProps) => {
  const { palette } = useTheme();
  const { selectedItems, setSelectedItems } = useKanbanSelectedItems();
  const border = `1px solid ${palette.grey[300]}`;

  const arrow = {
    content: '""',
    position: 'absolute',
    borderTop: border,
    borderRight: border,
    left: -ARR_HEIGHT / 1.8 + 2,
    top: (TITLE_HEIGHT - ARR_HEIGHT) / 2 - 1,
    transformOrigin: `${ARR_HEIGHT / 2}px ${ARR_HEIGHT / 2}px`,
    width: ARR_HEIGHT,
    height: ARR_HEIGHT,
    transform: 'scale(0.55, 1) rotateZ(45deg)',
  } as SystemStyleObject;

  const columnSelectionState = useMemo(() => {
    if (selectedItems == null) {
      return undefined;
    }

    const nbSelectedItems = itemsIds.filter(itemId =>
      selectedItems.includes(itemId),
    ).length;

    if (nbSelectedItems === 0) {
      return 'none';
    }

    if (nbSelectedItems === itemsIds.length) {
      return 'all';
    }

    return 'some';
  }, [itemsIds, selectedItems]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      pl={4}
      pr={2}
      sx={{
        height: TITLE_HEIGHT,
        minHeight: TITLE_HEIGHT,
        position: 'relative',
        overflow: 'hidden',
        borderTop: border,
        borderBottom: border,
        background: 'white',
        typography: 'subtitle2',
        '&:before': hideArrow ? null : arrow,
      }}
    >
      <Checkbox
        sx={{ p: 0, mr: 1 }}
        disabled={itemsIds.length === 0}
        checked={columnSelectionState === 'all'}
        indeterminate={columnSelectionState === 'some'}
        onClick={onClickHeaderCheckbox}
        onChange={event => {
          if (setSelectedItems == null || selectedItems == null) {
            return;
          }
          if (event.target.checked) {
            setSelectedItems([...new Set([...selectedItems, ...itemsIds])]);
          } else {
            setSelectedItems(
              selectedItems.filter(id => !itemsIds.includes(id)),
            );
          }
        }}
      />
      {label}
    </Stack>
  );
};

type VirtualizedRowData<Item> = Pick<
  KanbanColumnProps<Item>,
  | 'getItemId'
  | 'renderItem'
  | 'onItemClicked'
  | 'colId'
  | 'items'
  | 'hasMore'
  | 'isLoadingMore'
  | 'onHeightCalculated'
>;

const VirtualizedRow = <Item,>({
  index,
  data,
  style,
}: ListChildComponentProps<VirtualizedRowData<Item>>) => {
  const { t } = useLocale();

  const {
    getItemId,
    renderItem,
    onItemClicked,
    colId,
    onHeightCalculated,
    items,
    hasMore,
    isLoadingMore,
  } = data;

  const onHeightCalc = useCallback(
    (height: number) => onHeightCalculated?.(height, index),
    [index, onHeightCalculated],
  );

  if (index === items.length) {
    return (
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text.secondary',
          fontSize: '0.875rem',
        }}
        style={style}
      >
        {isLoadingMore && hasMore ? (
          <CircularProgress disableShrink size={20} />
        ) : (
          t('No more items to load')
        )}
      </Box>
    );
  }

  const item = items[index];
  const id = getItemId(item);

  return (
    <div style={{ ...style, paddingLeft: 4, paddingRight: 4 }}>
      <KanbanItem
        renderItem={renderItem}
        data={item}
        itemId={id}
        columnId={colId}
        key={id}
        onHeightCalculated={onHeightCalc}
        onClick={() => {
          onItemClicked?.(item);
        }}
      />
    </div>
  );
};

const VirtualizedListOuterEl = forwardRef<
  HTMLDivElement,
  {
    onScroll: UIEventHandler<HTMLDivElement>;
    style: CSSProperties;
    className?: string;
    children: React.ReactNode;
  }
>((props, ref) => {
  const context = useContext(BackgroundContext);
  const { palette } = useTheme();
  const background = context?.background ?? palette.grey[100];

  return (
    <Box
      onScroll={props.onScroll}
      sx={{
        ...props.style,
        // In WebKit we customize scrollbar,
        // in other engines just hide it
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { width: 4, background },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 3,
          background: 'grey.300',
          border: `1px solid ${background}`,
        },
        '&::-webkit-scrollbar-track': { background },
      }}
      ref={ref}
    >
      {props.children}
    </Box>
  );
});

const BackgroundContext = createContext<null | { background: string }>(null);

const SCROLL_THRESHOLD = 100; // pixels from bottom to trigger loading

export const KanbanColumn = <Item,>({
  title,
  colId,
  width = 336,
  index,
  topControls,
  items,
  renderItem,
  getItemId,
  onItemClicked,
  endControls,
  onClickHeaderCheckbox,
  onScroll,
  isLoadingMore,
  hasMore,
  onVisibilityChange,
}: KanbanColumnProps<Item>) => {
  const { palette } = useTheme();
  const itemHeightsRef = useRef<number[]>([]);
  const virtualizedListRef = useRef<VariableSizeList | null>(null);
  const columnInnerRef = useRef<HTMLDivElement | null>(null);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const [isItemOver, setIsItemOver] = useState(false);
  const instanceId = useKanbanInstanceId();

  const isFirstColumn = index === 0;
  const borderLeft = isFirstColumn
    ? undefined
    : `1px solid ${palette.grey[300]}`;
  const widthWithBorder = borderLeft == null ? width : width + 1;
  const background = isItemOver ? palette.grey[200] : palette.grey[100];
  const itemsIds = useMemo(() => items.map(getItemId), [items, getItemId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        onVisibilityChange?.(entry.isIntersecting);
      },
      { threshold: 0.1 },
    );

    if (columnInnerRef.current) {
      observer.observe(columnInnerRef.current);
    }

    return () => observer.disconnect();
  }, [onVisibilityChange]);

  useEffect(() => {
    if (columnInnerRef.current == null || scrollableRef.current == null) {
      return;
    }

    return combine(
      dropTargetForElements({
        element: columnInnerRef.current,
        getData: () => ({ columnId: colId, type: 'column' }),
        canDrop: ({ source }) => {
          return (
            source.data.instanceId === instanceId && source.data.type === 'card'
          );
        },
        getIsSticky: () => true,
        onDragEnter: () => setIsItemOver(true),
        onDragLeave: () => setIsItemOver(false),
        onDragStart: () => setIsItemOver(true),
        onDrop: () => setIsItemOver(false),
      }),
      autoScrollForElements({
        element: scrollableRef.current,
        canScroll: ({ source }) =>
          source.data.instanceId === instanceId && source.data.type === 'card',
      }),
    );
  }, [instanceId, colId]);

  const onItemHeightCalculated = useCallback(
    (height: number, itemIndex: number) => {
      if (itemHeightsRef.current[itemIndex] === height) {
        //  We do not update if the height is the same.
        return;
      }
      itemHeightsRef.current[itemIndex] = height;

      if (virtualizedListRef.current != null) {
        virtualizedListRef.current.resetAfterIndex(itemIndex);
      }
    },
    [],
  );

  const handleScroll = ({
    scrollOffset,
    scrollUpdateWasRequested,
  }: ListOnScrollProps) => {
    if (scrollUpdateWasRequested) {
      return;
    }

    const scrollableElement = scrollableRef.current;
    if (!scrollableElement) {
      return;
    }

    const isNearBottom =
      scrollableElement.scrollHeight -
        scrollOffset -
        scrollableElement.clientHeight <
      SCROLL_THRESHOLD;

    if (isNearBottom && onScroll) {
      onScroll();
    }
  };

  return (
    <Stack
      flexDirection="column"
      sx={{
        minWidth: widthWithBorder,
        maxWidth: widthWithBorder,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Title
        onClickHeaderCheckbox={onClickHeaderCheckbox}
        hideArrow={isFirstColumn}
        label={title}
        itemsIds={itemsIds}
      />

      <Stack
        flexGrow={1}
        sx={{
          background,
          borderLeft,
          minHeight: 0,
          transition: 'background 0.2s',
        }}
        ref={columnInnerRef}
      >
        {topControls != null && (
          <Box
            flexShrink={0}
            sx={{ borderBottom: `1px solid ${palette.grey[300]}` }}
          >
            {topControls}
          </Box>
        )}
        <Box
          ref={virtualizedListContainerRef}
          sx={{
            flex: 1,
            height: '100%',
            minHeight: 0,
          }}
        >
          <BackgroundContext.Provider value={{ background }}>
            <VariableSizeList<VirtualizedRowData<Item>>
              outerElementType={VirtualizedListOuterEl}
              ref={virtualizedListRef}
              outerRef={scrollableRef}
              onScroll={handleScroll}
              width="100%"
              height={virtualizedListContainerRef.current?.clientHeight ?? 725}
              estimatedItemSize={80}
              itemSize={itemIndex => itemHeightsRef.current[itemIndex] ?? 80}
              itemCount={items.length + (onScroll != null ? 1 : 0)}
              overscanCount={30}
              itemData={{
                items,
                getItemId,
                renderItem,
                onItemClicked,
                colId,
                onHeightCalculated: onItemHeightCalculated,
                hasMore,
                isLoadingMore,
              }}
            >
              {VirtualizedRow}
            </VariableSizeList>
          </BackgroundContext.Provider>
        </Box>
        {endControls != null && <Box flexShrink={0}>{endControls}</Box>}
      </Stack>
    </Stack>
  );
};
