import * as React from 'react';

import { FilledInput } from '@mui/material';
import {
  type CountryCode,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
} from 'libphonenumber-js';
import { useRifm } from 'rifm';

import { useSelectionBound } from '../hooks/selection-bound';

export const getInitialPhonePrefix = (countryCode: null | string): string => {
  return `+${getCountryCallingCode((countryCode as CountryCode) ?? 'CH')}`;
};

type Props = {
  autoFocus?: boolean;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
};

export const formatPhoneNumber = (str: string): string => {
  const clean = str.replace(/[^\d]+/gi, '').slice(0, 12);
  const formatted = formatIncompletePhoneNumber(`+${clean}`);
  return formatted;
};

export const PhoneInput = (props: Props) => {
  const inputRef = React.useRef(null);
  const rifm = useRifm({
    accept: /[\d+]+/g,
    format: formatPhoneNumber,
    value: formatPhoneNumber(props.value),
    onChange: v => props.onChange(v === '+' ? '' : v),
  });

  useSelectionBound(inputRef, 1, -1);

  return (
    <FilledInput
      inputRef={inputRef}
      autoFocus={props.autoFocus}
      type="tel"
      autoComplete="tel"
      value={rifm.value}
      onChange={rifm.onChange}
      onBlur={props.onBlur}
    />
  );
};
