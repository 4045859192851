import RefreshSharp from '@mui/icons-material/RefreshSharp';
import { LoadingButton } from '@mui/lab';
import { Box, Skeleton, Stack, Typography } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { TimeAgo } from '../../../components/TimeAgo';
import { usePropertyAppraisal } from '../../../hooks/useAppraiseProperty';

import type { AppraisalResult } from './StepHedonisticValuation';

type AppraisalSectionProps = {
  propertyId: string | undefined;
  latestAppraisalId: string | undefined;
  appraisalsLength: number;
  appraisalLoading: boolean;
  appraisable?: AppraisalResult;
  onAppraise: () => void;
};

const AppraisalSection = ({
  propertyId,
  latestAppraisalId,
  appraisalsLength,
  appraisalLoading,
  appraisable,
  onAppraise,
}: AppraisalSectionProps) => {
  const { t } = useLocale();
  const { appraiseProperty, appraisalData, mutationLoading } =
    usePropertyAppraisal(latestAppraisalId);

  const handleAppraiseProperty = async () => {
    if (propertyId) {
      await appraiseProperty(propertyId);
    }
    onAppraise();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
      <Typography variant="subtitle2" fontWeight={700}>
        {t('Latest appraisal')}
      </Typography>
      {appraisalsLength > 0 && appraisable?.result === true ? (
        <>
          {appraisalData != null && (
            <Typography variant="subtitle1">
              <TimeAgo dateString={appraisalData?.updated_at} />
            </Typography>
          )}
          <Box>
            <LoadingButton
              loading={mutationLoading}
              variant="contained"
              loadingPosition="start"
              startIcon={<RefreshSharp />}
              onClick={handleAppraiseProperty}
            >
              {t('appraiseAgain')}
            </LoadingButton>
          </Box>
        </>
      ) : appraisalLoading ? (
        <Stack gap={1}>
          <Skeleton variant="rounded" height={24} width={150} />
          <Skeleton variant="rounded" height={24} width={140} />
          <Skeleton variant="rounded" height={36} width={200} />
        </Stack>
      ) : (
        appraisable?.result === true && (
          <>
            <Typography variant="subtitle1">
              {t('thisPropertyIsNotAppraisedYet')}
            </Typography>
            <Box>
              <LoadingButton
                loading={mutationLoading}
                loadingPosition="start"
                startIcon={<RefreshSharp />}
                onClick={handleAppraiseProperty}
              >
                {t('startAppraisal')}
              </LoadingButton>
            </Box>
          </>
        )
      )}
    </Box>
  );
};

export default AppraisalSection;
