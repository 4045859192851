// @flow

import * as React from 'react';

import {
  Divider,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
} from '@mui/material';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box } from 'react-system';

import { DrawerLayer } from '../controls/drawer';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { DeleteForever } from '../icons/delete-forever';
import { KeyboardArrowLeft } from '../icons/keyboard-arrow-left';

import type { appraisalDrawer_appraisal$key } from './__generated__/appraisalDrawer_appraisal.graphql';
import type { appraisalDrawer_root$key } from './__generated__/appraisalDrawer_root.graphql';
import type { appraisalDrawerDeleteMutation } from './__generated__/appraisalDrawerDeleteMutation.graphql';
import { AppraisalGraph } from './appraisal-graph';

type Props = {|
  open: boolean,
  root: appraisalDrawer_root$key,
  appraisal: appraisalDrawer_appraisal$key,
  onDelete: () => void,
  onClose: () => void,
|};

const DataRow = ({ label, value }) => {
  if (value == null) {
    return null;
  } else {
    return (
      <TableRow>
        <TableCell>{label}</TableCell>
        <TableCell>{value}</TableCell>
      </TableRow>
    );
  }
};

const IaziInputTable = ({ data }) => {
  const { t } = useLocale();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('input')}</TableCell>
          <TableCell>{t('value')}</TableCell>
        </TableRow>
      </TableHead>
      {data != null && (
        <TableBody>
          <DataRow label={t('modelType')} value={data.modelType} />
          <DataRow label={t('modelDate')} value={data.modelDate} />
          <DataRow label={t('ortId')} value={data.ortId} />
          <DataRow label={t('qualityMicro')} value={data.qualityMicro} />
          <DataRow label={t('buildYear')} value={data.buildYear} />
          <DataRow label={t('surfaceLiving')} value={data.surfaceLiving} />
          <DataRow label={t('surfaceGround')} value={data.surfaceGround} />
          <DataRow label={t('houseType')} value={data.houseType} />
          <DataRow label={t('flatType')} value={data.flatType} />
          <DataRow label={t('roomNb')} value={data.roomNb} />
          <DataRow label={t('Number of bathrooms')} value={data.bathroomNb} />
          <DataRow label={t('sepWcNb')} value={data.sepWcNb} />
          <DataRow label={t('zip')} value={data.zip} />
          <DataRow label={t('town')} value={data.town} />
          <DataRow label={t('street')} value={data.street} />
          <DataRow label={t('buildCondition')} value={data.buildCondition} />
          <DataRow label={t('flatCondition')} value={data.flatCondition} />
          <DataRow label={t('buildQuality')} value={data.buildQuality} />
          <DataRow label={t('floorNb')} value={data.floorNb} />
          <DataRow label={t('Floor')} value={data.floor} />
          <DataRow label={t('volume')} value={data.volume} />
          <DataRow label={t('volumeCode')} value={data.volumeCode} />
          <DataRow label={t('minergieCode')} value={data.minergieCode} />
          <DataRow label={t('parkExternalNb')} value={data.parkExternalNb} />
          <DataRow label={t('parkOpenNb')} value={data.parkOpenNb} />
          <DataRow label={t('servituteFlag')} value={data.servituteFlag} />
          <DataRow label={t('surfaceBalcony')} value={data.surfaceBalcony} />
        </TableBody>
      )}
    </Table>
  );
};

const IaziOutputTable = ({ data }) => {
  const { t } = useLocale();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('output')}</TableCell>
          <TableCell>{t('value')}</TableCell>
        </TableRow>
      </TableHead>
      {data != null && (
        <TableBody>
          <DataRow label={t('modelDate')} value={data.modelDate} />
          <DataRow label={t('hiConfInt95')} value={data.hiConfInt95} />
          <DataRow label={t('hiConfInt50')} value={data.hiConfInt50} />
          <DataRow label={t('value')} value={data.value} />
          <DataRow label={t('lowConfInt50')} value={data.lowConfInt50} />
          <DataRow label={t('lowConfInt95')} value={data.lowConfInt95} />
          <DataRow label={t('qualityCode')} value={data.qualityCode} />
          <DataRow label={t('qualityText')} value={data.qualityText} />
        </TableBody>
      )}
    </Table>
  );
};

const WupInputTable = ({ data }) => {
  const { t } = useLocale();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('input')}</TableCell>
          <TableCell>{t('value')}</TableCell>
        </TableRow>
      </TableHead>
      {data != null && (
        <TableBody>
          <DataRow label={t('valuationDate')} value={data.valuationDate} />
          <DataRow
            label={t('hedonicModelValues')}
            value={data.hedonicModelValues}
          />
          <DataRow label={t('residenceType')} value={data.residenceType} />
          <DataRow label={t('numberOfRooms')} value={data.numberOfRooms} />
          <DataRow label={t('houseType')} value={data.houseType} />
          <DataRow label={t('flatType')} value={data.flatType} />
          <DataRow label={t('terraceSurface')} value={data.terraceArea} />
          <DataRow label={t('addressLine1')} value={data.addressLine1} />
          <DataRow label={t('zipCode')} value={data.zipCode} />
          <DataRow label={t('place')} value={data.place} />
          <DataRow label={t('countryIsoCode')} value={data.countryIsoCode} />
          <DataRow label={t('standard')} value={data.standard} />
          <DataRow label={t('condition')} value={data.condition} />
          <DataRow
            label={t('numberOfOutdoorParkingSpaces')}
            value={data.numberOfOutdoorParkingSpaces}
          />
          <DataRow
            label={t('numberOfGarageParkingSpaces')}
            value={data.numberOfGarageParkingSpaces}
          />
          <DataRow label={t('landPlotArea')} value={data.landPlotArea} />
          <DataRow
            label={t('Construction year')}
            value={data.constructionYear}
          />
          <DataRow label={t('usableArea')} value={data.usableArea} />
        </TableBody>
      )}
    </Table>
  );
};

const WupOutputTable = ({ data }) => {
  const { t } = useLocale();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('output')}</TableCell>
          <TableCell>{t('value')}</TableCell>
        </TableRow>
      </TableHead>
      {data != null && (
        <TableBody>
          <DataRow
            label={t('marketValueBeforeCorrection')}
            value={data.marketValueBeforeCorrection}
          />
          <DataRow
            label={t('marketValueAfterCorrection')}
            value={data.marketValueAfterCorrection}
          />
        </TableBody>
      )}
    </Table>
  );
};

const PricehubbleInputTable = ({ data }) => {
  const { t } = useLocale();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('input')}</TableCell>
          <TableCell>{t('value')}</TableCell>
        </TableRow>
      </TableHead>
      {data != null && (
        <TableBody>
          <DataRow label={t('city')} value={data.city} />
          <DataRow label={t('postcode')} value={data.postcode} />
          <DataRow label={t('street')} value={data.street} />
          <DataRow label={t('houseNumber')} value={data.houseNumber} />
          <DataRow label={t('buildingYear')} value={data.buildingYear} />
          <DataRow label={t('conditionDelta')} value={data.conditionDelta} />
          <DataRow label={t('livingArea')} value={data.livingArea} />
          <DataRow label={t('landArea')} value={data.landArea} />
          <DataRow label={t('numberOfRooms')} value={data.numberOfRooms} />
          <DataRow
            label={t('propertyTypeCode')}
            value={data.propertyTypeCode}
          />
          <DataRow
            label={t('propertyTypeSubcode')}
            value={data.propertyTypeSubcode}
          />
        </TableBody>
      )}
    </Table>
  );
};

const PricehubbleOutputTable = ({ data }) => {
  const { t } = useLocale();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('output')}</TableCell>
          <TableCell>{t('value')}</TableCell>
        </TableRow>
      </TableHead>
      {data != null && (
        <TableBody>
          <DataRow label={t('upperValueRange')} value={data.upperValueRange} />
          <DataRow label={t('lowerValueRange')} value={data.lowerValueRange} />
          <DataRow label={t('confidence')} value={data.confidence} />
          <DataRow label={t('geoQuality')} value={data.geoQuality} />
        </TableBody>
      )}
    </Table>
  );
};

export const AppraisalDrawer = (props: Props): React.Node => {
  const appraisal = useFragment(
    graphql`
      fragment appraisalDrawer_appraisal on Appraisal {
        ...appraisalGraph_appraisal
        id
        iazi {
          output {
            modelDate
            hiConfInt95
            hiConfInt50
            value
            lowConfInt50
            lowConfInt95
            qualityCode
            qualityText
          }
          input {
            modelType
            modelDate
            ortId
            qualityMicro
            buildYear
            surfaceLiving
            surfaceGround
            houseType
            flatType
            roomNb
            bathroomNb
            sepWcNb
            zip
            town
            street
            buildCondition
            flatCondition
            buildQuality
            floorNb
            floor
            volume
            volumeCode
            minergieCode
            parkExternalNb
            parkOpenNb
            servituteFlag
            surfaceBalcony
          }
        }
        iazi_cv {
          output {
            modelDate
            hiConfInt95
            hiConfInt50
            value
            lowConfInt50
            lowConfInt95
            qualityCode
            qualityText
          }
          input {
            modelType
            modelDate
            ortId
            qualityMicro
            buildYear
            surfaceLiving
            surfaceGround
            houseType
            flatType
            roomNb
            bathroomNb
            sepWcNb
            zip
            town
            street
            buildCondition
            flatCondition
            buildQuality
            floorNb
            floor
            volume
            volumeCode
            minergieCode
            parkExternalNb
            parkOpenNb
            servituteFlag
            surfaceBalcony
          }
        }
        pricehubble {
          input {
            city
            postcode
            street
            houseNumber
            buildingYear
            conditionDelta
            livingArea
            landArea
            numberOfRooms
            propertyTypeCode
            propertyTypeSubcode
          }
          output {
            upperValueRange
            lowerValueRange
            confidence
            geoQuality
          }
        }
        wup {
          input {
            valuationDate
            hedonicModelValues
            residenceType
            numberOfRooms
            houseType
            flatType
            terraceArea
            addressLine1
            zipCode
            place
            countryIsoCode
            standard
            condition
            numberOfOutdoorParkingSpaces
            numberOfGarageParkingSpaces
            landPlotArea
            constructionYear
            usableArea
          }
          output {
            marketValueBeforeCorrection
            marketValueAfterCorrection
          }
        }
      }
    `,
    props.appraisal,
  );

  const { colors } = useTheme();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [subTabIndex, setSubTabIndex] = React.useState(0);
  const [deleteAppraisal] = useMutation<appraisalDrawerDeleteMutation>(
    graphql`
      mutation appraisalDrawerDeleteMutation($input: DeleteAppraisalInput!) {
        deleteAppraisal(input: $input) {
          deletedAppraisalId
        }
      }
    `,
  );
  const { tenantSettings } = useFragment(
    graphql`
      fragment appraisalDrawer_root on Query {
        tenantSettings {
          ...appraisalGraph_tenantSettings
        }
      }
    `,
    props.root,
  );

  return (
    <DrawerLayer open={props.open} onClose={props.onClose}>
      <Toolbar>
        <IconButton onClick={props.onClose}>
          <KeyboardArrowLeft />
        </IconButton>
        <Box ml="auto">
          <IconButton
            onClick={() => {
              deleteAppraisal({
                variables: {
                  input: {
                    id: appraisal.id,
                  },
                },
                onCompleted: props.onDelete,
              });
            }}
          >
            <DeleteForever />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />

      <Box css={{ overflow: 'auto' }}>
        {/* always not null for logged in user */}
        {tenantSettings != null && (
          <Box p={3}>
            <AppraisalGraph
              tenantSettings={tenantSettings}
              appraisal={appraisal}
            />
          </Box>
        )}
        <Divider />
        <Paper>
          <Tabs
            css={{
              '.MuiTabs-indicator': { backgroundColor: colors.primaryMain },
              '.Mui-selected': { color: colors.black },
            }}
            variant="scrollable"
            value={tabIndex}
            onChange={(event, index) => setTabIndex(index)}
          >
            <Tab label="IAZI" />
            <Tab label="IAZI CV" />
            <Tab label="Wuest" />
            <Tab label="PHubble" />
          </Tabs>
        </Paper>
        <Paper>
          <Tabs
            css={{
              '.MuiTabs-indicator': { backgroundColor: colors.primaryMain },
              '.Mui-selected': { color: colors.black },
            }}
            variant="fullWidth"
            value={subTabIndex}
            onChange={(event, index) => setSubTabIndex(index)}
          >
            <Tab label="Input" />
            <Tab label="Output" />
          </Tabs>
        </Paper>

        {tabIndex === 0 && appraisal.iazi && (
          <>
            {subTabIndex === 0 && (
              <IaziInputTable data={appraisal.iazi.input} />
            )}
            {subTabIndex === 1 && (
              <IaziOutputTable data={appraisal.iazi.output} />
            )}
          </>
        )}
        {tabIndex === 1 && appraisal.iazi_cv && (
          <>
            {subTabIndex === 0 && (
              <IaziInputTable data={appraisal.iazi_cv.input} />
            )}
            {subTabIndex === 1 && (
              <IaziOutputTable data={appraisal.iazi_cv.output} />
            )}
          </>
        )}
        {tabIndex === 2 && appraisal.wup && (
          <>
            {subTabIndex === 0 && <WupInputTable data={appraisal.wup.input} />}
            {subTabIndex === 1 && (
              <WupOutputTable data={appraisal.wup.output} />
            )}
          </>
        )}
        {tabIndex === 3 && appraisal.pricehubble && (
          <>
            {subTabIndex === 0 && (
              <PricehubbleInputTable data={appraisal.pricehubble.input} />
            )}
            {subTabIndex === 1 && (
              <PricehubbleOutputTable data={appraisal.pricehubble.output} />
            )}
          </>
        )}
      </Box>
    </DrawerLayer>
  );
};
