import * as React from 'react';

import { Button, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { Form } from '@realadvisor/form';
import { useCaptcha } from '@realadvisor/hooks';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { graphql, useMutation } from 'react-relay';
import { Box } from 'react-system';

import * as config from '../../config';
import { PhoneInput, getInitialPhonePrefix } from '../../controls/phone-input';
import { useLocale } from '../../hooks/locale';

import type { LoginPhoneFormPinCodeRequestMutation } from './__generated__/LoginPhoneFormPinCodeRequestMutation.graphql';

const detectCountryCode = async (signal: AbortSignal) => {
  const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace', {
    signal,
  });
  const body = await response.text();
  /*
    name=value
    loc=RU
    name=value
    */
  const countryCode = body.match(/\bloc=([\w]+)/)?.[1];
  return countryCode;
};

type Props = {
  onChange: (state: { number: string; tenantId: string | null }) => void;
  tenantId: string | null;
};

export const LoginPhoneForm = (props: Props) => {
  const { t } = useLocale();
  const [requestPinCode, requesting] =
    useMutation<LoginPhoneFormPinCodeRequestMutation>(
      graphql`
        mutation LoginPhoneFormPinCodeRequestMutation(
          $input: PhoneSignInRequestInput!
        ) {
          phoneSignInRequest(input: $input) {
            hint
            errors {
              number
              captcha
            }
          }
        }
      `,
    );
  const captcha = useCaptcha({
    reCaptchaKeyV3: config.reCaptchaKeyV3,
    reCaptchaKeyV2: config.reCaptchaKeyV2,
  });
  const [state, setState] = React.useState<{
    number: string;
    error: null | string;
  }>({
    number: getInitialPhonePrefix(null),
    error: null,
  });
  const submitForm = () => {
    captcha.requestToken((recaptchaToken, recaptchaVersion) => {
      requestPinCode({
        variables: {
          input: {
            number:
              parsePhoneNumberFromString(state.number)?.format('E.164') ?? '',
            recaptchaToken,
            recaptchaVersion,
          },
        },
        onCompleted: payload => {
          if (payload.phoneSignInRequest?.hint != null) {
            const hint = payload.phoneSignInRequest.hint;
            alert(hint);
          }
          if (payload.phoneSignInRequest?.errors) {
            const errors = payload.phoneSignInRequest.errors;
            if (errors.captcha != null) {
              captcha.verifyUser();
            }
            setState({ number: state.number, error: errors.number });
          } else {
            props.onChange({ number: state.number, tenantId: props.tenantId });
          }
        },
      });
    });
  };
  React.useEffect(() => {
    // TODO rewrite with suspense to get correct country code on first render
    const controller = new AbortController();
    detectCountryCode(controller.signal)
      .then(countryCode => {
        setState({
          number: getInitialPhonePrefix(countryCode ?? null),
          error: null,
        });
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          setState({ number: getInitialPhonePrefix(null), error: null });
        }
      });
    return () => {
      controller.abort();
    };
  }, []);
  return (
    <Form onSubmit={submitForm}>
      <Box py={1}>
        <FormControl error={state.error != null} disabled={requesting}>
          <InputLabel>{t('phoneNumber')}</InputLabel>
          <PhoneInput
            value={state.number}
            onChange={number => setState({ number, error: null })}
          />
          {state.error != null && (
            <FormHelperText>{state.error}</FormHelperText>
          )}
        </FormControl>
      </Box>
      {captcha.element}
      <Box py={1}>
        <Button
          fullWidth
          css={{ textAlign: 'center' }}
          variant="contained"
          color="secondary"
          disabled={state.error != null || requesting}
          onClick={submitForm}
        >
          {t('signInWithPhone')}
        </Button>
      </Box>
    </Form>
  );
};
