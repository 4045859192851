// @flow

import * as React from 'react';

import { Avatar, Chip } from '@mui/material';
import { Image } from '@realadvisor/image';
import { graphql, useFragment } from 'react-relay';
// $FlowFixMe[untyped-import]
import { Link } from 'react-router-dom';
import { Box, Flex, useSystem } from 'react-system';

import { fromGlobalId } from '../../shared/global-id';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { Person } from '../icons/person';
import { Photo } from '../icons/photo';
import { formatPrice } from '../utils/format-price';

import type { lotSnippet_lot$key } from './__generated__/lotSnippet_lot.graphql';

const CountBadge = ({ children, ...props }) => {
  const { text, colors, borderRadius } = useTheme();
  return (
    <Box
      css={[
        text.caption,
        text.truncate(1),
        {
          color: colors.mediumText,
          border: 'solid 1px',
          borderRadius: borderRadius.small,
          borderColor: colors.grey300,
        },
      ]}
      {...props}
    >
      {children}
    </Box>
  );
};

const UserChip = props => {
  const imageUrl = props.primaryImage?.url ?? null;

  return (
    <Chip
      size="small"
      label={[props.firstName, ' ', props.lastName]}
      style={{ maxWidth: '100%' }}
      avatar={
        imageUrl == null ? (
          <Avatar>
            <Person />
          </Avatar>
        ) : (
          <Avatar src={imageUrl} />
        )
      }
    />
  );
};

type Props = {|
  lot: lotSnippet_lot$key,
  href?: string,
|};

export const LotSnippet = (props: Props): React.Node => {
  const lot = useFragment(
    graphql`
      fragment lotSnippet_lot on Lot {
        id
        primaryPropertyImage {
          image {
            url
          }
        }
        property {
          route
          streetNumber
          postcode
          locality
        }
        currency
        salePrice
        rentNet
        rentExtra
        offerType
        seller {
          firstName
          lastName
          primaryImage {
            url
          }
        }
        broker {
          firstName
          lastName
          primaryImage {
            url
          }
        }
        enquiries {
          id
        }
        matchingBuyerLeads {
          id
        }
      }
    `,
    props.lot,
  );
  const { text, colors, textColor } = useTheme();
  const { media } = useSystem();
  const { t, locale } = useLocale();

  const lotLink = props.href ?? `/listings/${fromGlobalId(lot.id)}`;

  const {
    offerType,
    salePrice,
    currency,
    rentNet,
    rentExtra,
    seller,
    broker,
    matchingBuyerLeads,
    property,
  } = lot;

  const rentPrice =
    rentNet == null || rentExtra == null ? null : rentNet + rentExtra;
  const price = offerType === 'sell' ? salePrice : rentPrice;
  const enquiries = lot.enquiries ?? [];

  return (
    <Link to={lotLink} target="_blank">
      <Flex
        width="100%"
        justifyContent="flex-start"
        css={{ textAlign: 'left' }}
      >
        <Flex
          flexGrow={0}
          flexShrink={0}
          width="140px"
          css={{ minHeight: 152 }}
        >
          {lot.primaryPropertyImage?.image.url != null ? (
            <Image
              objectFit="cover"
              options={{ w: 300, h: 300, c: 'fill' }}
              src={lot.primaryPropertyImage.image.url}
            />
          ) : (
            <div
              css={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: colors.grey400,
                backgroundColor: colors.grey200,
              }}
            >
              <Photo fill={colors.grey400} size={32} />
              <div css={text.caption}>{t('noPhotos')}</div>
            </div>
          )}
        </Flex>

        <Box p={3}>
          <Box css={[text.subtitle2, text.truncate(1)]} pb={2}>
            <span css={media({ display: ['none', 'inline'] })}>
              {property.route} {property.streetNumber},{' '}
            </span>
            <span>
              {property.postcode} {property.locality}
            </span>
          </Box>

          {seller != null && (
            <Flex pb={2}>
              <Box
                css={[text.body2, textColor('mediumText')]}
                flexShrink={0}
                mr={2}
              >
                {t('seller')}:
              </Box>
              <Box>
                <UserChip {...seller} />
              </Box>
            </Flex>
          )}

          {broker != null && (
            <Flex pb={2}>
              <Box
                css={[text.body2, textColor('mediumText')]}
                flexShrink={0}
                mr={2}
              >
                {t('broker')}:
              </Box>
              <Box>
                <UserChip {...broker} />
              </Box>
            </Flex>
          )}

          {price != null && (
            <Box css={[text.h6, text.ellipsis]} pb={2}>
              {price !== 0
                ? formatPrice(price, locale, currency ?? 'CHF')
                : t('priceOnRequest')}
            </Box>
          )}

          {(enquiries.length > 0 || matchingBuyerLeads.length > 0) && (
            <Flex>
              {enquiries.length > 0 && (
                <CountBadge px="4px" py="2px" mr={2}>
                  {t('enquiriesCount', {
                    count: enquiries.length,
                  })}
                </CountBadge>
              )}
              {matchingBuyerLeads.length > 0 && (
                <CountBadge px="4px" py="2px" mr={2}>
                  {t('matchesCount', {
                    count: matchingBuyerLeads.length,
                  })}
                </CountBadge>
              )}
            </Flex>
          )}
        </Box>
      </Flex>
    </Link>
  );
};
