/**
 * @generated SignedSource<<db8ad5c78e3457efb3cf93269f7a9004>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type autocompleteMultiple_tag$data = ReadonlyArray<{
  readonly createdAt: string;
  readonly createdBy: {
    readonly displayName: string;
  } | null;
  readonly dictionary: {
    readonly name: string;
  } | null;
  readonly " $fragmentType": "autocompleteMultiple_tag";
}>;
export type autocompleteMultiple_tag$key = ReadonlyArray<{
  readonly " $data"?: autocompleteMultiple_tag$data;
  readonly " $fragmentSpreads": FragmentRefs<"autocompleteMultiple_tag">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "autocompleteMultiple_tag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "dictionary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LeadsTag",
  "abstractKey": null
};

(node as any).hash = "431a14b2e4e0591fcb32704f99784fd5";

export default node;
