import * as React from 'react';

import { Button, ButtonGroup, type ButtonProps } from '@mui/material';
import { useSystem } from 'react-system';

import { useLocale } from '../hooks/locale';

type Props = {
  valueGte: number | null;
  valueLte: number | null;
  onChange: (entry: {
    valueGte: number | null;
    valueLte: number | null;
  }) => void;
};

// Material-ui ButtonGroup clones Button elements replacing all props with it's own
// here is the hack to allow button to have it's own variant and color
const WrapButton = ({
  wvariant,
  wcolor,
  ...props
}: {
  wvariant: ButtonProps['variant'];
  wcolor: ButtonProps['color'];
} & Partial<ButtonProps>) => (
  <Button {...props} variant={wvariant} color={wcolor} />
);

export const RoomsFilterInput = (props: Props) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { valueGte, valueLte, onChange } = props;

  const [selectionIndex, setSelectionIndex] = React.useState<number | null>(
    null,
  );

  const checkboxes: Array<{
    lte: number | null;
    gte: number;
    title: string;
    checked: boolean;
    insideSelectionRange: boolean;
  }> = Array.from(Array(5).keys()).map(k => ({
    gte: k + 1,
    lte: k + 1.5,
    title: `${k + 1}`,
    checked:
      (valueGte != null || valueLte != null) &&
      (valueGte == null || valueGte <= k + 1) &&
      (valueLte == null || valueLte >= k + 1),
    insideSelectionRange: false,
  }));
  checkboxes.push({
    gte: 6,
    lte: null,
    title: '6+',
    checked: valueGte != null && valueGte <= 6 && valueLte == null,
    insideSelectionRange: false,
  });

  const firstCheckedIndex = checkboxes.findIndex(ch => ch.checked);
  const checkeds = checkboxes.filter(ch => ch.checked);

  if (selectionIndex != null && checkeds.length === 1) {
    for (
      let i = Math.min(firstCheckedIndex, selectionIndex);
      i <= Math.max(firstCheckedIndex, selectionIndex);
      i += 1
    ) {
      checkboxes[i].insideSelectionRange = true;
    }
  }

  const onClick = (index: number) => {
    const rangeStartOrEnd =
      firstCheckedIndex !== -1 && checkeds.length === 1
        ? firstCheckedIndex
        : index;

    const rangeStart = Math.min(rangeStartOrEnd, index);
    const rangeEnd = Math.max(rangeStartOrEnd, index);
    const newChecks = checkboxes.map(ch => ({ ...ch }));

    if (rangeEnd - rangeStart === 0) {
      if (checkeds.length <= 1) {
        newChecks[rangeStart].checked = !newChecks[rangeStart].checked;
      } else {
        newChecks.forEach(ch => {
          ch.checked = false;
        });
        newChecks[rangeStart].checked = true;
      }
    } else {
      for (let i = rangeStart; i <= rangeEnd; i += 1) {
        newChecks[i].checked = true;
      }
    }

    const checked = newChecks.filter(x => x.checked);
    const valueGte =
      checked.length === 0 ? null : Math.min(...checked.map(x => x.gte));

    let valueLte = null;
    if (checked.length !== 0) {
      valueLte = 1;
      for (const { lte } of checked) {
        valueLte =
          lte == null || valueLte == null ? null : Math.max(lte, valueLte);
      }
    }

    onChange({
      valueGte,
      valueLte,
    });
  };

  const empty = valueGte == null && valueLte == null;

  return (
    <ButtonGroup fullWidth={true} css={{ margin: '8px 0' }}>
      <WrapButton
        css={[
          {
            padding: '8px 16px',
            fontWeight: 600,
            width: 'auto',
            flexShrink: 0,
          },
          empty
            ? { border: '1px solid transparent' }
            : { border: '1px solid rgba(0, 0, 0, 0.23)' },
        ]}
        onClick={() => {
          onChange({
            valueGte: null,
            valueLte: null,
          });
        }}
        wvariant={empty ? 'contained' : 'outlined'}
        wcolor={empty ? 'primary' : 'inherit'}
      >
        {t('any')}
      </WrapButton>

      {checkboxes.map((ch, index) => (
        <WrapButton
          key={index}
          onMouseEnter={() => {
            if (checkeds.length === 1) {
              setSelectionIndex(index);
            }
          }}
          onMouseLeave={() => setSelectionIndex(null)}
          css={[
            media({
              padding: ['8px 0', '8px 16px'],
              fontWeight: 600,
              borderLeftColor: 'rgba(0, 0, 0, 0.1)',
            }),
            ch.checked || ch.insideSelectionRange
              ? {
                  border: '1px solid transparent',
                }
              : {
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                },
          ]}
          onClick={() => {
            onClick(index);
          }}
          wvariant={
            ch.checked || ch.insideSelectionRange ? 'contained' : 'outlined'
          }
          wcolor={ch.checked || ch.insideSelectionRange ? 'primary' : 'inherit'}
        >
          {ch.title}
        </WrapButton>
      ))}
    </ButtonGroup>
  );
};
