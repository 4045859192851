import * as React from 'react';

import type { Interpolation } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';
import { useResizeRect } from '@realadvisor/observe';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import type { To } from 'react-router-dom';
import { Box, Flex } from 'react-system';
import { FixedSizeList } from 'react-window';

import { useTheme } from '../hooks/theme';
import type { SortDirection, Sorting } from '../utils/sorting';

import type { ColumnConfig, HeadColumn, RowColumn } from './table/BaseTable';
import {
  Footer,
  Head,
  Row,
  getHeadColumn,
  getRowColumn,
} from './table/BaseTable';

export type { ColumnConfig };

type RowLinkGetter<T> = (props: { node: T; rowIndex: number }) => To;

type RowStyleGetter<T> = (props: { node: T }) => null | Interpolation<unknown>;

type RowHoverHandler = (props: { rowIndex: number }) => void;

type RowClickHandler<T> = (props: { node: T }) => void;

type Props<T> = {
  columns: ColumnConfig<T>[];
  data: T[];

  // row config
  headerSize?: number;
  rowSize: number;
  getRowLink?: null | RowLinkGetter<T>;
  getRowStyle?: null | RowStyleGetter<T>;
  hoveredRow?: number;
  onRowHover?: null | RowHoverHandler;
  onRowClick?: null | RowClickHandler<T>;
  rowTarget?: '_self' | '_blank';

  // sorting
  sort?: Sorting;
  onSort?: (sorting: Sorting) => void;

  // pagination
  loadUntil: (
    stopIndex: number,
    batchSize?: number,
    loadBeforeEndSize?: number,
  ) => void;
  hasMore: () => boolean;
  initialSortDirection?: SortDirection;
  showFooter?: boolean;
  onShowTotalCount?: () => void;
  displayedCount?: number;
  totalCount?: number;
};

type RowData<T> = {
  columns: RowColumn<T>[];
  data: T[];
  rowWidth: number;
  getRowLink: null | RowLinkGetter<T>;
  getRowStyle: null | RowStyleGetter<T>;
  hoveredRow: number;
  onRowHover: null | RowHoverHandler;
  onRowClick: null | RowClickHandler<T>;
  rowTarget: null | '_self' | '_blank';
};

const InnerElementContext = React.createContext<null | {
  columns: HeadColumn[];
  rowWidth: number;
  rowSize: number;
  headerSize?: number;
  sort: Sorting | undefined;
  onSort: ((sorting: Sorting) => void) | undefined;
  initialSortDirection: SortDirection | undefined;
}>(null);

const InnerElement = ({
  style,
  children,
}: {
  style: React.CSSProperties;
  children: React.ReactNode;
}) => {
  const data = React.useContext(InnerElementContext);

  if (data == null) {
    return null;
  }

  const { rowWidth } = data;

  return <div style={{ ...style, width: rowWidth }}>{children}</div>;
};

const LoadingRow = (props: { style: React.CSSProperties }) => {
  const { colors } = useTheme();
  return (
    <Flex style={props.style} css={{ backgroundColor: colors.white }}>
      <Flex
        width={1}
        alignItems="center"
        justifyContent="center"
        css={{
          // center loading row when table is aligned to left
          // substract FixedSizeList vertical scroll
          maxWidth: `calc(100vw - ${scrollbarSize()}px)`,
        }}
      >
        <CircularProgress size={20} disableShrink />
      </Flex>
    </Flex>
  );
};

type ListRowProps<T> = {
  style: React.CSSProperties;
  index: number;
  isScrolling?: boolean;
  data: RowData<T>;
};

const ListRow = <T extends unknown>({
  style,
  index,
  data,
}: ListRowProps<T>) => {
  // skip heading index
  const fakeIndex = index;
  const datum = data.data[fakeIndex];
  const columns = data.columns;
  const rowWidth = data.rowWidth;

  const enhancedStyle = { ...style, width: rowWidth };

  if (datum == null) {
    return <LoadingRow style={enhancedStyle} />;
  }

  return (
    <div style={enhancedStyle}>
      <Row
        width={rowWidth}
        height={Number(style.height)}
        columns={columns}
        node={datum}
        rowIndex={fakeIndex}
        getRowLink={data.getRowLink}
        getRowStyle={data.getRowStyle}
        hoveredRow={data.hoveredRow}
        onRowHover={data.onRowHover}
        onRowClick={data.onRowClick}
        rowTarget={data.rowTarget}
      >
        {null}
      </Row>
    </div>
  );
};

export const InfiniteTable = <T extends unknown>(props: Props<T>) => {
  const { colors } = useTheme();
  const containerRef = React.useRef(null);
  const resizeRect = useResizeRect(containerRef);

  const {
    columns,
    data,
    rowSize,
    headerSize,
    getRowLink = null,
    getRowStyle = null,
    hoveredRow = -1,
    onRowHover = null,
    onRowClick = null,
    rowTarget = null,
    sort,
    onSort,
    loadUntil,
    hasMore,
    initialSortDirection,
    showFooter,
    onShowTotalCount,
    totalCount,
    displayedCount,
  } = props;

  const minWidth = columns.reduce((acc, d) => acc + d.width, 0) * 8;
  const rowWidth =
    resizeRect && minWidth < resizeRect.width
      ? resizeRect.width - scrollbarSize()
      : minWidth;
  const tableWidth = rowWidth + scrollbarSize();

  const innerElementData = React.useMemo(() => {
    return {
      columns: columns.map(getHeadColumn),
      rowWidth,
      rowSize,
      headerSize,
      sort,
      onSort,
      initialSortDirection,
    };
  }, [
    columns,
    rowWidth,
    rowSize,
    headerSize,
    sort,
    onSort,
    initialSortDirection,
  ]);

  const rowData = React.useMemo((): RowData<T> => {
    return {
      columns: columns.map(getRowColumn),
      data,
      rowWidth,
      getRowLink,
      getRowStyle,
      hoveredRow,
      onRowHover,
      onRowClick,
      rowTarget,
    };
  }, [
    columns,
    data,
    rowWidth,
    getRowLink,
    getRowStyle,
    hoveredRow,
    onRowHover,
    onRowClick,
    rowTarget,
  ]);

  // 1 for table heading
  // 1 for loading row
  const rowCount = data.length + (hasMore() ? 1 : 0);

  // -1 to skip heading row
  const rowsVisibleOnScreen = resizeRect
    ? Math.ceil((resizeRect.height - (headerSize ?? rowSize)) / (rowSize - 1)) -
      1
    : 0;

  // start preload items when 2 screen to end left
  const loadBeforeEndSize = Math.max(rowsVisibleOnScreen * 2, 10);
  const batchSize = Math.max(loadBeforeEndSize * 2, 20);

  const emptySort = { sortBy: null, sortDirection: null, nullsLast: false };

  return (
    <Box
      ref={containerRef}
      flexGrow={1}
      css={{ position: 'relative', backgroundColor: colors.white, zIndex: 0 }}
    >
      {/* fix scroll not reducing */}
      <div css={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
        {resizeRect && (
          <div
            css={{
              width: resizeRect.width,
              height: resizeRect.height,
              // ios safari does not understand combined value overflow: auto hidden
              overflowX: 'auto',
              overflowY: 'hidden',
              WebkitOverflowScrolling: 'touch',
            }}
          >
            <Head
              width={rowWidth}
              height={headerSize ?? rowSize}
              columns={innerElementData.columns}
              sorting={
                sort
                  ? {
                      sortBy: sort.sortBy,
                      sortDirection: sort.sortDirection,
                      nullsLast: sort.nullsLast,
                    }
                  : emptySort
              }
              onSort={s => onSort?.(s ?? emptySort)}
              initialSortDirection={initialSortDirection}
            />
            <InnerElementContext.Provider value={innerElementData}>
              <FixedSizeList<RowData<T>>
                style={{ overflowX: 'hidden', overflowY: 'auto' }}
                width={tableWidth}
                height={
                  resizeRect.height -
                  scrollbarSize() -
                  (showFooter ? 42 : 0) -
                  (headerSize ?? rowSize)
                }
                innerElementType={InnerElement}
                itemSize={rowSize}
                itemCount={rowCount}
                itemData={rowData}
                onItemsRendered={({ visibleStopIndex }) => {
                  loadUntil(visibleStopIndex + 1, batchSize, loadBeforeEndSize);
                }}
              >
                {ListRow}
              </FixedSizeList>
            </InnerElementContext.Provider>
            {showFooter && (
              <div
                css={{
                  position: 'sticky',
                  bottom: 0,
                  left: 0,
                  zIndex: 1000,
                }}
              >
                <Footer
                  height={42}
                  displayedCount={displayedCount}
                  totalCount={totalCount}
                  onShowTotalCount={onShowTotalCount}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
};
