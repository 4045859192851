import { memo, useCallback, useEffect, useMemo } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  type DeepPartial,
  type FieldPath,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { type Currency, getCurrencyByCountryCode } from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import { roundToTwo } from '../../../src/utils/number-format';
import { DEFAULT_TAX_RATE } from '../../utils/constants';
import { getCurrencySymbol } from '../../utils/formatting';
import { getKeys } from '../../utils/objects';
import { RaNumber } from '../form/RaNumber';
import { RaTeam } from '../form/RaTeam';
import { RaUser } from '../form/RaUser';

import {
  type TransactionCommissionFields,
  type TransactionTotalCommissionFields,
  computeCommissionFields,
} from './TransactionTotalCommissionField';

type TransactionCommissionSplitsFieldProps = {
  currency?: string | null;
  propertyCountryCode?: string | null;
  disabled?: boolean;
};

export type TransactionCommissionSplitsFields = Pick<
  TransactionTotalCommissionFields,
  'total_commission'
> & {
  split_commissions: Array<
    {
      contact_id?: string | null;
      beneficiary_team_id?: string | null;
    } & TransactionCommissionFields
  >;
};

const computeCommissionSplit = (
  totalCommission: number | null | undefined,
  trigger: FieldPath<TransactionTotalCommissionFields> | 'none',
  commission: Partial<
    TransactionCommissionSplitsFields['split_commissions'][number]
  >,
  isFixedFee?: boolean,
) => {
  const calculatedCommission = computeCommissionFields(
    {
      purchase_price: totalCommission,
      total_commission: commission,
    },
    trigger,
    isFixedFee,
  );

  if (calculatedCommission == null) {
    return null;
  }

  return {
    ...commission,
    ...calculatedCommission.total_commission,
  };
};

type CommissionSplitUpdate = {
  commission: TransactionCommissionSplitsFields['split_commissions'][number];
  index: number;
};

export const computeCommissionSplitsFields = (
  fields: DeepPartial<TransactionCommissionSplitsFields>,
  trigger: FieldPath<TransactionCommissionSplitsFields> | 'none',
  isFixedFee?: boolean,
): CommissionSplitUpdate[] | null => {
  if (trigger === 'total_commission.vat' || fields.split_commissions == null) {
    return null;
  }

  const totalCommissionAmount = fields.total_commission?.amount ?? 0;

  // If the total commission amount is changed, we need to update all the split commissions.
  if (trigger === 'total_commission.amount' || trigger === 'none') {
    return fields.split_commissions.reduce((acc, commission, index) => {
      const computed =
        commission != null
          ? computeCommissionSplit(
              totalCommissionAmount,
              trigger === 'total_commission.amount'
                ? 'purchase_price'
                : trigger,
              commission,
              isFixedFee,
            )
          : null;

      if (computed != null) {
        acc.push({ commission: computed, index });
      }
      return acc;
    }, [] as CommissionSplitUpdate[]);
  }

  const splitCommissionMatch = trigger.match(/^split_commissions\.(\d+)\.(.*)/);

  if (splitCommissionMatch == null || splitCommissionMatch.length <= 2) {
    return null;
  }

  const commissionIndex = parseInt(splitCommissionMatch[1]);
  const customTrigger =
    `total_commission.${splitCommissionMatch[2]}` as FieldPath<TransactionTotalCommissionFields>;

  const commissionToUpdate = fields.split_commissions[commissionIndex];

  if (commissionToUpdate == null) {
    return null;
  }

  const computedCommission = computeCommissionSplit(
    totalCommissionAmount,
    customTrigger,
    commissionToUpdate,
    isFixedFee,
  );

  return computedCommission != null
    ? [
        {
          commission: computedCommission,
          index: commissionIndex,
        },
      ]
    : null;
};

export const TransactionCommissionSplitsField: React.FC<
  TransactionCommissionSplitsFieldProps
> = ({ currency, propertyCountryCode, disabled }) => {
  const { t, countryCode, locale } = useLocale();
  const { watch, setValue, setError, clearErrors, getValues } =
    useFormContext<TransactionCommissionSplitsFields>();
  const { fields, remove, append } =
    useFieldArray<TransactionCommissionSplitsFields>({
      name: 'split_commissions',
    });

  const computedCurrency = useMemo(
    () =>
      (currency ??
        getCurrencyByCountryCode(
          propertyCountryCode ?? countryCode,
        )) as Currency,
    [countryCode, propertyCountryCode, currency],
  );

  const splitCommissionAmount = useCallback(() => {
    const { split_commissions, total_commission } = getValues();

    if (!total_commission.amount || split_commissions.length < 2) {
      return;
    }

    const amountPerSplit = roundToTwo(
      total_commission.amount / split_commissions.length,
    );

    // Initializes an array with amountPerSplit for each split commission.
    const amountUpdates = Array.from(
      { length: split_commissions.length },
      () => amountPerSplit,
    );

    if (amountPerSplit * split_commissions.length !== total_commission.amount) {
      amountUpdates[amountUpdates.length - 1] = roundToTwo(
        amountPerSplit +
          (total_commission.amount - amountPerSplit * split_commissions.length),
      );
    }

    const updates: {
      path: FieldPath<TransactionCommissionSplitsFields>;
      value: any;
    }[] = split_commissions.flatMap((commission, index) => {
      const computedCommission = computeCommissionSplit(
        total_commission.amount,
        'total_commission.amount',
        { ...commission, amount: amountUpdates[index] },
        false,
      );

      if (computedCommission == null) {
        return [];
      }

      return getKeys(computedCommission).map(key => {
        // As per react-hook-form documentation, it is more performant to target the complete field name rather than passing an object as second parameter.
        // https://react-hook-form.com/docs/useform/setvalue
        return {
          path: `split_commissions.${index}.${key}`,
          value: computedCommission[key],
        };
      });
    });

    updates.slice(0, -1).forEach(({ path, value }) => {
      setValue(path, value, {
        shouldValidate: false,
        shouldDirty: false,
      });
    });

    setValue(
      updates[updates.length - 1].path,
      updates[updates.length - 1].value,
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  }, [getValues, setValue]);

  useEffect(() => {
    const { unsubscribe } = watch((values, { name, type }) => {
      if (
        type === 'change' &&
        name != null &&
        name.startsWith('split_commissions.')
      ) {
        const computedValues = computeCommissionSplitsFields(
          values,
          name,
          false,
        );

        if (computedValues == null) {
          return;
        }

        // When the change is triggered with "split_commissions." as a starting path, we only want to update one commission split.
        const updatedSplitCommission = computedValues[0];

        getKeys(updatedSplitCommission.commission).forEach(key => {
          // As per react-hook-form documentation, it is more performant to target the complete field name rather than passing an object as second parameter.
          // https://react-hook-form.com/docs/useform/setvalue
          setValue(
            `split_commissions.${updatedSplitCommission.index}.${key}`,
            updatedSplitCommission.commission[key],
            {
              shouldValidate: false,
              shouldDirty: false,
            },
          );
        });
      }

      if (
        values.split_commissions != null &&
        values.total_commission?.amount != null
      ) {
        const commissionSplitsSum = values.split_commissions?.reduce(
          (sum, commission) => {
            // If commission is not set or 0, we want to invalidate the sum.
            if (isNaN(sum) || !commission?.amount) {
              return NaN;
            }

            return roundToTwo(sum + commission.amount);
          },
          0,
        );

        const totalCommissionAmount = roundToTwo(
          values.total_commission.amount,
        );

        if (
          !isNaN(commissionSplitsSum) &&
          commissionSplitsSum !== totalCommissionAmount
        ) {
          values.split_commissions.forEach((_, index) => {
            setError(`split_commissions.${index}.amount`, {
              type: 'validate',
              message: t('Commission split must add up to {{value}}', {
                value: formatPrice(
                  totalCommissionAmount,
                  locale,
                  computedCurrency,
                ),
              }),
            });
          });
        } else {
          clearErrors('split_commissions');
        }
      }
    });
    return () => unsubscribe();
  }, [setValue, watch, setError, computedCurrency, locale, t, clearErrors]);

  return (
    <Paper sx={{ pb: 2, pr: 2, pt: 2 }} variant="outlined">
      <Typography variant="h6" component="h3" sx={{ ml: 2 }}>
        {t('{{count}} parties', {
          count: fields.length,
        })}
      </Typography>
      {fields.map((field, index) => (
        <Box key={field.id}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <TransactionCommissionSplitsItem
              index={index}
              currency={computedCurrency}
              disabled={disabled}
            />
            <IconButton onClick={() => remove(index)} color="error">
              <RemoveCircleIcon />
            </IconButton>
          </Stack>
          <Divider variant="middle" sx={{ mt: 2 }} />
        </Box>
      ))}
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ mt: 2, justifyContent: 'flex-end', gap: 2 }}
      >
        <Button
          onClick={splitCommissionAmount}
          startIcon={<SplitscreenIcon />}
          variant="outlined"
          disabled={fields.length < 2}
        >
          {t('Split in equal parts')}
        </Button>
        <Button
          onClick={() => append({ __vat_rate: DEFAULT_TAX_RATE })}
          startIcon={<AddCircleOutlineIcon />}
          variant="outlined"
        >
          {t('Add new commission')}
        </Button>
      </Stack>
    </Paper>
  );
};

type TransactionCommissionSplitsItemProps = {
  index: number;
  currency: Currency;
  disabled?: boolean;
};

const TransactionCommissionSplitsItem: React.FC<TransactionCommissionSplitsItemProps> =
  memo(({ index, currency, disabled }) => {
    const { t, locale } = useLocale();
    const {
      control,
      formState: { errors },
    } = useFormContext<TransactionCommissionSplitsFields>();

    return (
      <Grid container spacing={2}>
        <Grid item sm={6} sx={{ width: '100%' }}>
          <RaNumber
            name={`split_commissions.${index}.amount`}
            label={t('Amount')}
            required={true}
            min={0}
            control={control}
            errors={errors}
            decimalNumbers={2}
            prefix={getCurrencySymbol(currency, locale)}
            disabled={disabled}
          />
        </Grid>
        <Grid item sm={6} sx={{ width: '100%' }}>
          <RaNumber
            name={`split_commissions.${index}.__rate`}
            label={t('Commission share')}
            required={true}
            min={0}
            max={100}
            control={control}
            errors={errors}
            decimalNumbers={2}
            suffix="%"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} sx={{ width: '100%' }}>
          <RaNumber
            name={`split_commissions.${index}.vat`}
            label={t('VAT')}
            required={true}
            min={0}
            control={control}
            errors={errors}
            decimalNumbers={2}
            prefix={getCurrencySymbol(currency, locale)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} sx={{ width: '100%' }}>
          <RaNumber
            name={`split_commissions.${index}.__vat_rate`}
            label={t('VAT (%)')}
            required={true}
            min={0}
            max={100}
            control={control}
            errors={errors}
            decimalNumbers={2}
            suffix="%"
            disabled={disabled}
          />
        </Grid>
        <Grid item sm={6} sx={{ width: '100%' }}>
          <RaNumber
            name={`split_commissions.${index}.__total`}
            label={t('Commission payable')}
            required={true}
            min={0}
            control={control}
            errors={errors}
            decimalNumbers={2}
            prefix={getCurrencySymbol(currency, locale)}
            disabled={disabled}
          />
        </Grid>

        <Grid item sm={6} sx={{ width: '100%' }}>
          <RaTeam
            name={`split_commissions.${index}.beneficiary_team_id`}
            label={t('Beneficiary team')}
            control={control}
            disabled={disabled}
          />
        </Grid>
        <Grid item sm={6} sx={{ width: '100%' }}>
          <RaUser
            name={`split_commissions.${index}.contact_id`}
            label={t('Beneficiary')}
            control={control}
            filters={{ is_broker: { _eq: true } }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    );
  });
