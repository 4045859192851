import { useMemo } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Alert, LinearProgress } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import type { DeepPartial } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { type Translate, useLocale } from '../../../src/hooks/locale';
import type { TeamSettingsFragmentFragment } from '../../__generated__/graphql';
import {
  type FormDefinitionType,
  RaForm,
  type TextFieldDefinition,
} from '../../components/form/RaForm';
import type { IFormError } from '../../utils/parseError';

import {
  DELETE_TEAM_EMAIL,
  DELETE_TEAM_PHONE_NUMBER,
  GET_TEAM_SETTINGS,
  UPDATE_TEAM_PHONE_NUMBER,
  UPDATE_TEAM_SETTINGS,
  UPSERT_TEAM_EMAIL,
} from './teamQueries';

const socialMediaFields = [
  { name: 'facebook_url', label: 'Facebook' },
  { name: 'instagram_url', label: 'Instagram' },
  { name: 'youtube_url', label: 'YouTube' },
  { name: 'linkedin_url', label: 'LinkedIn' },
  { name: 'twitter_url', label: 'Twitter' },
];

type TeamSettingsFormData = Omit<
  TeamSettingsFragmentFragment,
  'phone_numbers' | 'id' | 'emails'
> & {
  phone_number?: string;
  email?: string;
};

const TeamFormDefinition: FormDefinitionType<TeamSettingsFormData> = ({
  t,
}) => [
  {
    name: 'address-title',
    label: t('Address'),
    type: 'category-title',
  },
  {
    label: t('Address'),
    type: 'address',
    gridProps: { xs: 12 },
    includeGoogleFields: true,
  },
  {
    name: 'details',
    label: 'Details',
    type: 'category-title',
  },
  {
    name: 'name',
    label: t('Name'),
    type: 'text',
    required: true,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'active_since',
    label: t('Active since (year)'),
    type: 'number',
    disableFormatting: true,
    required: false,
    gridProps: { xs: 12, md: 6 },
    max: new Date().getFullYear(),
  },
  {
    name: 'legal_name',
    label: t('Legal name'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'description',
    label: t('Description'),
    type: 'text',
    required: false,
    multiline: true,
    gridProps: { xs: 12 },
  },
  {
    name: 'phone_number',
    label: t('Phone number'),
    type: 'phone_number',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'email',
    label: t('Email'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'website_url',
    label: t('Website URL'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'agency_slug',
    label: t('Slug (agency pages)'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'organisation_id',
    label: t('Parent organisation'),
    type: 'organisation',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'options',
    label: t('Options'),
    type: 'checkbox-group',
    checkboxes: [
      {
        name: 'show_in_agency_pages',
        label: t('Show in agency pages'),
        type: 'checkbox',
      },
      {
        name: 'do_follow_link',
        label: t('Dofollow link'),
        type: 'checkbox',
      },
      {
        name: 'hide_exact_address',
        label: t('Hide exact address'),
        type: 'checkbox',
      },
    ],
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'branding_color',
    label: t('Branding color'),
    type: 'color-picker',
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'social_links',
    label: t('Social links'),
    type: 'category-title',
  },
  ...socialMediaFields.map(
    ({ name, label }) =>
      ({
        name,
        label,
        type: 'text',
        gridProps: { xs: 12, md: 6 },
      } as TextFieldDefinition<TeamSettingsFormData>),
  ),
  {
    name: 'listings',
    label: t('Listings'),
    type: 'category-title',
  },
  {
    name: 'idx_ftp_client_id',
    label: t('RealAdvisor IDX FTP Client ID'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'idx_agency_id',
    label: t('RealAdvisor IDX Agency ID'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'listings_agency_names',
    label: t('Listings Agency Names'),
    type: 'array',
    required: false,
    gridProps: { xs: 12, md: 12 },
  },
  {
    name: 'enquiry_email_contact',
    label: t('Enquiry Email Contact'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'enquiry_email_options',
    label: t('Enquiry Email Options'),
    type: 'checkbox-group',
    checkboxes: [
      {
        name: 'use_json_for_enquiry_emails',
        label: t('Send enquiry emails in JSON format'),
        type: 'checkbox',
      },
    ],
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'meta',
    label: t('Metadata'),
    type: 'category-title',
  },
  {
    name: 'created_at',
    label: t('Created at'),
    type: 'date',
    disabled: () => true,
  },
  {
    name: 'created_by',
    label: t('Created by'),
    type: 'user',
    disabled: () => true,
  },
];

const extractMessagesFromError = (
  error: Error,
  _: any,
  t: Translate,
): IFormError<any>[] => {
  if (!(error instanceof ApolloError)) {
    return [];
  }

  const regex = new RegExp(/"teams_show_in_agency_pages_agency_slug_check"$/);

  const isShowInAgencyPagesError = error.graphQLErrors.find(graphQLError => {
    const msg = graphQLError.message;

    // Message in the form: "Check constraint violation. new row for relation \"teams\" violates check constraint \"teams_show_in_agency_pages_agency_slug_check\""
    return msg.includes('check constraint') && regex.test(msg);
  });

  return isShowInAgencyPagesError
    ? [
        {
          field: 'agency_slug',
          errorMessage: t(
            'You cannot activate show in agency pages without a valid slug',
          ),
        },
      ]
    : [];
};

export const TeamSettings = () => {
  const { t } = useLocale();
  const { teamId } = useParams();

  const { data, loading, error } = useQuery(GET_TEAM_SETTINGS, {
    variables: { id: teamId ?? '' },
    skip: teamId == null,
  });

  const teamData = data?.teams_by_pk;
  const defaultValues = useMemo<DeepPartial<TeamSettingsFormData>>(() => {
    const { phone_numbers, emails, ...team } = teamData || {};

    return {
      ...team,
      phone_number: phone_numbers?.[0]?.number ?? '',
      email: emails?.[0]?.email ?? '',
    };
  }, [teamData]);

  const [updateTeam] = useMutation(UPDATE_TEAM_SETTINGS);
  const [updatePhoneNumber] = useMutation(UPDATE_TEAM_PHONE_NUMBER);
  const [upsertEmail] = useMutation(UPSERT_TEAM_EMAIL);
  const [deleteEmail] = useMutation(DELETE_TEAM_EMAIL, {
    update: cache => {
      const teamRef = cache.identify({ __typename: 'teams', id: teamId });
      cache.modify({
        id: teamRef,
        fields: {
          emails: () => [],
        },
      });
    },
  });

  const [deletePhoneNumber] = useMutation(DELETE_TEAM_PHONE_NUMBER, {
    update: cache => {
      const teamRef = cache.identify({ __typename: 'teams', id: teamId });
      cache.modify({
        id: teamRef,
        fields: {
          phone_numbers: () => [],
        },
      });
    },
  });

  if (loading) {
    return (
      <LinearProgress
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2000,
        }}
      />
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (!teamData) {
    return <Alert severity="error">{t('Team not found')}</Alert>;
  }

  const onSubmit = async (formData: any) => {
    const { phone_number, email, ...rest } = formData;
    if (phone_number !== defaultValues.phone_number) {
      if (phone_number != null) {
        await updatePhoneNumber({
          variables: {
            id: teamData.id,
            phone_number:
              parsePhoneNumberFromString(phone_number)?.format('E.164') ?? '',
          },
        });
      } else {
        // delete phone number
        await deletePhoneNumber({
          variables: {
            id: teamData.id,
          },
        });
      }
    }

    if (email !== defaultValues.email) {
      if (email != null) {
        await upsertEmail({
          variables: { id: teamData.id, email },
        });
      } else {
        // delete email
        await deleteEmail({
          variables: { id: teamData.id },
        });
      }
    }

    return updateTeam({
      variables: {
        id: teamData.id,
        team: {
          ...rest,
          id: undefined,
        },
      },
    });
  };

  return (
    <RaForm
      formDefinition={TeamFormDefinition}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      customExtractMessagesFromError={extractMessagesFromError}
    />
  );
};
