import { gql } from '../../__generated__';

export const GET_PROPERTY_TRANSACTIONS = gql(/* GraphQL */ `
  query getPropertyTransactions(
    $where: property_transactions_bool_exp!
    $order_by: [property_transactions_order_by!]
    $limit: Int!
    $offset: Int!
    $team_ids: [uuid!]!
  ) {
    property_transactions(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      ...TransactionsListRow
      reviews(where: { completed: { _eq: true } }) {
        id
        is_buyer
      }
    }
  }
`);

export const GET_PROPERTY_TRANSACTIONS_LIST_COUNT = gql(/* GraphQL */ `
  query getPropertyTransactionsListCount(
    $where: property_transactions_bool_exp!
  ) {
    property_transactions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const DRAWER_GET_PROPERTY_TRANSACTION = gql(/* GraphQL */ `
  query TransactionDrawerGetTransaction($id: uuid!) {
    property_transactions_by_pk(id: $id) {
      id
      lot_id
      property_id
      buyer_broker_team_id
      seller_broker_team_id
    }
  }
`);

export const DELETE_TRANSACTION = gql(/* GraphQL */ `
  mutation DeleteTransaction($id: uuid!) {
    delete_property_transactions_by_pk(id: $id) {
      id
    }
  }
`);

export const TRANSACTION_FORM_FRAGMENT = gql(/* GraphQL */ `
  fragment TransactionFormFragment on property_transactions {
    id
    created_by
    created_at
    signature_date
    signature_date
    buyer_broker_id
    seller_broker_id
    buyer_broker_team_id
    seller_broker_team_id
    signature_date
    estimated_signature_date
    payment_date
    purchase_price
    is_published
    lot_id
    lot {
      id
      currency
      property {
        id
        country_code
      }
    }
    sellers {
      transaction_id
      user_id
    }
    buyers {
      transaction_id
      user_id
    }
  }
`);

export const TRANSACTION_COMMISSIONS_FORM_FRAGMENT = gql(/* GraphQL */ `
  fragment TransactionCommissionsFormFragment on property_transactions {
    id
    purchase_price
    commissions(order_by: { payment_date: asc }) {
      id
      type
      amount
      vat
      contact_id
      beneficiary_team_id
    }
    lot {
      id
      currency
      mandate_type
      property {
        id
        country_code
      }
    }
  }
`);

export const TRANSACTION_FORM_QUERY = gql(/* GraphQL */ `
  query TransactionFormQuery($id: uuid!) {
    property_transactions_by_pk(id: $id) {
      id
      ...TransactionFormFragment
    }
  }
`);

export const TRANSACTION_FORM_UPDATE = gql(/* GraphQL */ `
  mutation TransactionFormUpdate(
    $id: uuid!
    $transaction: property_transactions_set_input!
    $sellers: [transaction_sellers_insert_input!]!
    $buyers: [transaction_buyers_insert_input!]!
  ) {
    update_property_transactions_by_pk(
      pk_columns: { id: $id }
      _set: $transaction
    ) {
      id
      ...TransactionFormFragment
      seller_broker {
        id
        ...UserInfo_user
      }
      buyer_broker {
        id
        ...UserInfo_user
      }
      buyer_broker_team {
        id
        ...TeamInfo_team
      }
      seller_broker_team {
        id
        ...TeamInfo_team
      }
    }
    delete_transaction_buyers(where: { transaction_id: { _eq: $id } }) {
      returning {
        transaction_id
        user_id
      }
    }
    delete_transaction_sellers(where: { transaction_id: { _eq: $id } }) {
      returning {
        transaction_id
        user_id
      }
    }
    insert_transaction_buyers(objects: $buyers) {
      returning {
        transaction_id
        user_id
      }
    }
    insert_transaction_sellers(objects: $sellers) {
      returning {
        transaction_id
        user_id
      }
    }
  }
`);

export const TRANSACTION_COMMISSIONS_FORM_UPDATE = gql(/* GraphQL */ `
  mutation TransactionCommissionsFormUpdate(
    $id: uuid!
    $commissions: [commissions_insert_input!]!
  ) {
    delete_commissions(where: { property_transaction_id: { _eq: $id } }) {
      returning {
        id
        type
        amount
        vat
        contact_id
        beneficiary_team_id
        property_transaction_id
      }
    }
    insert_commissions(objects: $commissions) {
      returning {
        id
        type
        amount
        vat
        contact_id
        beneficiary_team_id
        property_transaction_id
      }
    }
  }
`);

export const CREATE_TRANSACTION = gql(/* GraphQL */ `
  mutation CreateTransaction(
    $transaction: property_transactions_insert_input!
  ) {
    insert_property_transactions_one(object: $transaction) {
      id
      ...TransactionFormFragment
      seller_broker {
        id
        ...UserInfo_user
      }
      buyer_broker {
        id
        ...UserInfo_user
      }
      buyer_broker_team {
        id
        ...TeamInfo_team
      }
      seller_broker_team {
        id
        ...TeamInfo_team
      }
      property {
        id
      }
      buyers {
        user_id
        transaction_id
      }
      sellers {
        user_id
        transaction_id
      }
    }
  }
`);

export const GET_USER_DEFAULT_TEAM_ID = gql(/* GraphQL */ `
  query GetUserDefaultTeamId($id: uuid!) {
    users_by_pk(id: $id) {
      default_team_id
    }
  }
`);

export const GET_TRANSACTION_DOCUMENTS = gql(/* GraphQL */ `
  query GetTransactionDocuments($id: uuid!) {
    property_transactions_by_pk(id: $id) {
      id
      property {
        id
        property_files(order_by: { order_nr: desc }) {
          ...PropertyFile
        }
      }
    }
  }
`);
