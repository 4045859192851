import * as React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';
import { ErrorReporting } from '@realadvisor/error';
import { GoogleKeyProvider } from '@realadvisor/google-maps';
import { useConstant } from '@realadvisor/hooks';
import { ImageProvider } from '@realadvisor/image';
import { HeadProvider } from 'react-head';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { SchemaInitializer } from '../apollo/components/filters/SchemaProvider';
import CMAReportsList from '../apollo/pages/cma-reports/CMAReportsList';
import Leads from '../apollo/pages/leads/Leads';
import Listings from '../apollo/pages/listings/Listings';
import { SelectTenant } from '../apollo/pages/select-tenant/SelectTenant';
import { Teams } from '../apollo/pages/teams/Teams';
import { Transactions } from '../apollo/pages/transactions/Transactions';
import Users from '../apollo/pages/users/Users';
import { type Me, useAppData } from '../apollo/providers/AppDataProvider';
import { errorsEmitter } from '../networking';

import { NavigationProvider } from './components/Navigation/index';
import { SuspenseFallback } from './components/SuspenseFallback';
import { GOOGLE_MAPS_TOKEN, isProduction } from './config';
import { LocaleProvider, useLocale } from './hooks/locale';
import { StyleProvider } from './hooks/theme';
import {
  extractLanguageFromUrl,
  fallbackLanguage,
  getLanguage,
  replacePathnameLanguage,
} from './locale';
import { NoAccess } from './pages/noaccess';
import { Plans } from './pages/plans';
import { Login } from './routes/Login/Login';
import { Layout } from './shared/layout';
import { lazyRetry } from './utils/lazy';

const LegacyRoutes = React.lazy(() =>
  lazyRetry(() => import('./pages/LegacyRoutes')),
);
const V2Routes = React.lazy(() =>
  lazyRetry(() => import('../apollo/V2Routes')),
);
const CMAReportEditor = React.lazy(() =>
  lazyRetry(
    () => import('../apollo/pages/cma-reports/CMAReportEditor/CMAReportEditor'),
  ),
);
const ComparableListings = React.lazy(() =>
  lazyRetry(
    () => import('../apollo/pages/comparable-listings/ComparableListings'),
  ),
);
const VisibilityDashboard = React.lazy(() =>
  lazyRetry(
    () => import('../apollo/pages/visibility-dashboard/VisibilityDashboard'),
  ),
);
const VisibilityTransactions = React.lazy(() =>
  lazyRetry(
    () =>
      import('../apollo/pages/visibility-transactions/VisibilityTransactions'),
  ),
);
const VisibilityStats = React.lazy(() =>
  lazyRetry(() => import('../apollo/pages/visibility-stats/VisibilityStats')),
);
const VisibilityInbox = React.lazy(() =>
  lazyRetry(() => import('../apollo/pages/visibility-inbox/VisibilityInbox')),
);
const VisibilityReviews = React.lazy(() =>
  lazyRetry(
    () => import('../apollo/pages/visibility-reviews/VisibilityReviews'),
  ),
);
const Offers = React.lazy(() =>
  lazyRetry(() => import('../apollo//pages/offers/Offers')),
);
const Enquiries = React.lazy(() =>
  lazyRetry(() => import('../apollo/pages/enquiries/Enquiries')),
);
const PropertySearches = React.lazy(() =>
  lazyRetry(() => import('../apollo/pages/property-searches/PropertySearches')),
);
const Reviews = React.lazy(() =>
  lazyRetry(() => import('../apollo/pages/reviews/Reviews')),
);

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

// Report to Google Tag Manager
const GTMReportUserDetails = () => {
  const { me } = useAppData();

  React.useEffect(() => {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({
      event: 'CRM.UserDetails',
      tenantId: me?.tenant_id ?? null,
      id: me?.id,
      email:
        me?.emails?.find(({ primary }) => primary)?.email ??
        me?.emails?.[0]?.email ??
        null,
      firstName: me?.first_name ?? null,
      lastName: me?.last_name ?? null,
      organisationName: me?.default_team?.name ?? null,
      organisationId: me?.default_team?.id ?? null,
      teamId: me?.default_team?.id ?? null,
      teamName: me?.default_team?.name ?? null,
      phoneNumber: me?.phone_numbers?.find(p => p.primary)?.number ?? null,
      avatarSrc: me?.user_images?.[0]?.image?.url ?? null,
      isAdmin: me?.is_admin ?? null,
      isBroker: me?.is_broker ?? null,
      // https://www.intercom.com/help/en/articles/183-enable-identity-verification-for-web-and-mobile
      intercomHash: me?.intercom_hash ?? null,
    });
  }, [me]);

  return null;
};

// Register MUIX license key
LicenseInfo.setLicenseKey(
  '82b54df2defa4673291813f7abe3be47Tz04ODMwMCxFPTE3NDQ1MjIxOTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

const BaseApp = ({
  me,
  noAccess,
}: {
  me: Me;
  noAccess?: { userId: string };
}) => {
  const loginParams = new URLSearchParams({ return: window.location.href });
  React.useEffect(() => {
    const titleTags = document.querySelectorAll('title');
    titleTags?.[0]?.remove();
  }, []);

  // Anonymous user
  if (me == null && noAccess == null) {
    return (
      <Routes>
        {localStorage.getItem('refresh_token') != null && (
          <Route path="/select-tenant" element={<SelectTenant />} />
        )}
        <Route path="/login" element={<Login />} />
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: '/login',
                search: loginParams.toString(),
              }}
              replace
            />
          }
        />
      </Routes>
    );
  }

  // User without access (not an admin or a broker)
  if (noAccess != null || (me?.is_admin === false && me?.is_broker === false)) {
    return (
      <Routes>
        <Route
          path="/noaccess"
          element={<NoAccess id={noAccess?.userId ?? me?.id} />}
        />
        <Route path="*" element={<Navigate to="/noaccess" replace />} />
      </Routes>
    );
  }

  // User without subscription
  if (me?.show_plans != null && me?.show_plans !== 'no') {
    return (
      <Routes>
        <Route
          path="/plans"
          element={
            <Layout>
              <GTMReportUserDetails />
              <Plans
                tenantCountryCode={me?.tenant?.country_code ?? 'FR'}
                stripeCustomer={me?.subscriptions?.[0]?.customer}
                showPlansType={me?.show_plans}
              />
            </Layout>
          }
        />
        <Route path="*" element={<Navigate to="/plans" replace />} />
      </Routes>
    );
  }

  // if module has leads, financing, brokerage in modules
  const isCRMActive = me?.modules?.some(module =>
    ['leads', 'financing', 'brokerage'].includes(module),
  );

  return (
    <React.Suspense fallback={null}>
      <Layout>
        <GTMReportUserDetails />
        <React.Suspense fallback={<SuspenseFallback />}>
          <Routes>
            {/* Route migrated to v2 */}
            <Route path="/v2/*" element={<V2Routes />} />
            <Route path="/teams/*" element={<Teams />} />
            <Route path="/listings/*" element={<Listings />} />
            <Route path="/enquiries/*" element={<Enquiries />} />
            <Route path="/property-searches/*" element={<PropertySearches />} />
            <Route path="/leads/*" element={<Leads />} />
            <Route path="/users/*" element={<Users />} />
            <Route path="/transactions/*" element={<Transactions />} />
            <Route path="/offers/*" element={<Offers />} />
            <Route
              path="/visibility/overview"
              element={<VisibilityDashboard />}
            />
            <Route
              path="/visibility/statistics"
              element={<VisibilityStats />}
            />
            <Route
              path="/visibility/transactions"
              element={<VisibilityTransactions />}
            />
            <Route path="/visibility/inbox/*" element={<VisibilityInbox />} />
            <Route
              path="/visibility/reviews/*"
              element={<VisibilityReviews />}
            />
            {me?.modules?.includes('cma_reports') && (
              <>
                <Route
                  path="/cma-reports/:cmaReportId/:pageId/*"
                  element={<CMAReportEditor />}
                />
                <Route path="/cma-reports/*" element={<CMAReportsList />}>
                  <Route path="new/*" />
                </Route>
                <Route
                  path="/comparable-listings/*"
                  element={<ComparableListings />}
                />
              </>
            )}
            {me?.is_admin && <Route path="/reviews/*" element={<Reviews />} />}
            {/* Routes in development */}

            {/* Legacy routes */}
            <Route path="/*" element={<LegacyRoutes />} />
            {/* Default routes */}
            <Route
              index
              element={
                <Navigate
                  to={isCRMActive ? 'dashboard' : 'visibility/overview'}
                />
              }
            />
          </Routes>
        </React.Suspense>
      </Layout>
    </React.Suspense>
  );
};

const LocaleInitializer = ({ children }: { children: React.ReactNode }) => {
  const { dateLocale } = useLocale();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateLocale}
    >
      {children}
    </LocalizationProvider>
  );
};

export const App = () => {
  const { me, noAccess } = useAppData();

  // prevent changing ui language when user changes it in own profile
  const userLanguage = useConstant(() => getLanguage(me?.language));
  const urlLanguage = useConstant(() => extractLanguageFromUrl());
  const currentLanguage = userLanguage ?? urlLanguage ?? fallbackLanguage;

  // redirect when laguage not found in url
  // or when it's not matched with user language
  if (urlLanguage !== currentLanguage) {
    const pathname = replacePathnameLanguage(
      location.pathname,
      currentLanguage,
    );
    // redirect in render to interrupt everything else
    // preserve table filters in search
    location.href = `${pathname}${location.search}`;
    return null;
  }

  return (
    <>
      {/* show errors only on test server, or in dev mode or if user is admin */}
      {(import.meta.env.NODE_ENV !== 'production' ||
        !isProduction ||
        me?.is_admin === true) && (
        <ErrorReporting errors={[]} emitter={errorsEmitter} />
      )}
      <React.Suspense fallback={null}>
        <SchemaInitializer />
      </React.Suspense>
      <ImageProvider>
        <BrowserRouter basename={`/${currentLanguage}`}>
          {/* locale provider should be upper to prevent conflicting with app mui style provider */}
          <LocaleProvider
            currentLanguage={currentLanguage}
            countryCode={me?.tenant?.country_code ?? 'CH'}
          >
            <LocaleInitializer>
              {/* @ts-ignore */}
              <HeadProvider headTags={[]}>
                <NavigationProvider>
                  <StyleProvider>
                    <GoogleKeyProvider googleKey={GOOGLE_MAPS_TOKEN}>
                      <BaseApp me={me} noAccess={noAccess} />
                    </GoogleKeyProvider>
                  </StyleProvider>
                </NavigationProvider>
              </HeadProvider>
            </LocaleInitializer>
          </LocaleProvider>
        </BrowserRouter>
      </ImageProvider>
    </>
  );
};
