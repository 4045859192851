import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { formatDate } from '../../src/controls/date-input';
import type { Translate } from '../../src/hooks/locale';
import { type Currency, type IntlLocale } from '../../src/locale';
import { formatPrice } from '../../src/utils/format-price';

type TAddress = {
  street_number?: string | null;
  route?: string | null;
  street?: string | null;
  postcode?: string | null;
  locality?: string | null;
  state?: string | null;
  country?: string | null;
};

type TDummyAddress = {
  [K in keyof TAddress as `dummy_${K}`]: TAddress[K];
};

type TFormatAddressFnOptions = {
  hideRoute?: boolean | null;
  hideStreetNumber?: boolean | null;
  useDummyAddress?: boolean | null;
  dummyData?: TDummyAddress;
};

type TFormatAddressFn = {
  (
    address: TAddress,
    joinSeparator: string,
    shortFormat?: boolean,
    options?: TFormatAddressFnOptions,
  ): string;
  (
    address: TAddress,
    joinSeparator: null,
    shortFormat?: boolean,
    options?: TFormatAddressFnOptions,
  ): string[];
};

/**
 * Formats an address object into a string or string parts.
 *
 * @param param0 An address object
 * @param joinSeparator A separator to join the address parts. If `null`, the address parts will be returned as an array.
 * @param shortFormat Whether to use a short format (default) or a long format.
 */
export const formatAddress: TFormatAddressFn = (
  address,
  joinSeparator: string | null,
  shortFormat = true,
  options = {},
): any => {
  let { street_number, route, street, postcode, locality, state, country } =
    address;

  // For compatibility with Organisations.
  if (route == null) {
    route = street;
  }

  if (options.useDummyAddress === true) {
    street_number = options.dummyData?.dummy_street_number;
    route = options.dummyData?.dummy_route;
    postcode = options.dummyData?.dummy_postcode;
    locality = options.dummyData?.dummy_locality;
    state = options.dummyData?.dummy_state;
    country = options.dummyData?.dummy_country;
  }

  const addressParts = [
    options.hideRoute
      ? null
      : [route, options.hideStreetNumber ? null : street_number]
          .filter(Boolean)
          .join(' ')
          .trim(),
    [postcode, locality].filter(Boolean).join(' ').trim(),
    !shortFormat ? [state, country].filter(Boolean).join(' - ') : null,
  ].filter(Boolean) as string[];

  return joinSeparator ? addressParts.join(joinSeparator) : addressParts;
};

export const formatCurrencyRange = (
  range:
    | {
        max?: number | null;
        min?: number | null;
      }
    | null
    | undefined,
  currency: Currency,
  locale: IntlLocale,
) =>
  `${formatPrice(range?.min, locale, currency)} - ${formatPrice(
    range?.max,
    locale,
    currency,
  )}`;

export const formatIncome = (
  income: number,
  currency: Currency,
  locale: IntlLocale,
) => {
  if (income < 1_000) {
    return income.toLocaleString(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      currency,
      style: 'currency',
    });
  }

  if (income < 1_000_000) {
    return (
      (income / 1_000).toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currency,
        style: 'currency',
      }) + 'K'
    );
  }

  return (
    (income / 1_000_000).toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency,
      style: 'currency',
    }) + 'M'
  );
};

export const getCurrencySymbol = (currency: Currency, locale: IntlLocale) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(1)
    .find(x => x.type === 'currency')?.value ?? 'CHF';

export const roundRating = (rating?: number | null) => {
  if (!rating) {
    return 0;
  }

  return Math.round(rating * 10) / 10;
};

export const getRatingLabel = (
  rating: number | undefined | null | string,
  t: Translate,
) => {
  if (rating == null) {
    return null;
  }

  const ratingNumber = Math.round(
    typeof rating === 'string' ? Number.parseFloat(rating) : rating,
  );

  switch (ratingNumber) {
    case 1:
      return t('onePoor');
    case 2:
      return t('twoFair');
    case 3:
      return t('threeGood');
    case 4:
      return t('fourVeryGood');
    case 5:
      return t('fiveExcellent');
    default:
      return null;
  }
};

export const formatPhone = (phone?: string | null) => {
  const parsed = phone == null ? null : parsePhoneNumberFromString(phone, 'CH');
  const formatted = parsed == null ? null : parsed.formatInternational();
  return formatted;
};

/**
 * Formats the provided date string or timestamp into a human-readable date string.
 * @param locale The current locale of the user in which date should be formatted.
 * @param isoStrOrTimestamp Either an ISO Date string or a timestamp.
 * @returns A formatted date string in the user's locale.
 */
export const toHumanReadableDate = (
  locale: IntlLocale,
  isoStrOrTimestamp: string | number,
  formatOptions?: Intl.DateTimeFormatOptions,
) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    ...(formatOptions ?? {}),
  };

  return new Intl.DateTimeFormat(locale, options).format(
    typeof isoStrOrTimestamp === 'string'
      ? new Date(isoStrOrTimestamp)
      : isoStrOrTimestamp,
  );
};

export const formatNumber = (value: number, locale: Intl.LocalesArgument) =>
  value.toLocaleString(locale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const formatTimeAgo = (
  dateString: string,
  addSuffix: boolean,
  ignoreTime: boolean,
  t: (key: string, options?: { count?: number }) => string,
  dateLocale: Locale,
  strictDate: boolean = false,
  limitToMonths: boolean = false,
): { timeAgo: string; tooltipDate: string } => {
  const now = Date.now();
  const timestamp = new Date(dateString).getTime();
  const timeDifference = now - timestamp;

  const formatOptions = {
    addSuffix,
    locale: dateLocale,
  };

  const formatDate = (date: Date, options: any) =>
    strictDate
      ? formatDistanceToNowStrict(date, options)
      : formatDistanceToNow(date, options);

  let tooltipDate = '';
  try {
    tooltipDate = new Intl.DateTimeFormat(dateLocale.code, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...(ignoreTime ? {} : { hour: 'numeric', minute: 'numeric' }),
    }).format(timestamp);
  } catch (error) {
    console.error(error);
  }

  if (ignoreTime) {
    const hoursElapsed = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hoursElapsed < 24) {
      return { timeAgo: t('Today'), tooltipDate };
    }
  }

  const monthsElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
  if (limitToMonths && monthsElapsed >= 11) {
    return {
      timeAgo: formatDate(new Date(dateString), {
        ...formatOptions,
        unit: 'month',
      }),
      tooltipDate,
    };
  }

  let timeAgo = '';
  try {
    timeAgo = formatDate(new Date(dateString), formatOptions);
  } catch (error) {
    console.error(error);
  }

  return {
    timeAgo,
    tooltipDate,
  };
};

export const formatDateNumeric = (date: Date, locale: IntlLocale) => {
  return new Intl.DateTimeFormat(locale, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
};

const fromISOString = (date: null | string) =>
  date != null ? formatDate(new Date(date)) : '';

export const formatListingAvailability = (
  availableFrom: string | null | undefined,
  t: Translate,
) => {
  if (availableFrom == null) {
    return t('Available upon request');
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const availableFromDate = new Date(availableFrom);
  availableFromDate.setHours(0, 0, 0, 0);

  return availableFromDate > today
    ? t('Available from {{date}}', {
        date: fromISOString(availableFrom),
      })
    : t('Available immediately');
};
