// @flow

import * as React from 'react';

import { Box, FormControl, FormHelperText, Stack } from '@mui/material';
import { format, parse } from 'date-fns';

import { DateInput, formatDate, parseDate } from '../../controls/date-input';
import { TimeInput, formatTime, parseTime } from '../../controls/time-input';
import { useLocale } from '../../hooks/locale';

export type TimeRange = {|
  date: string,
  startTime: string,
  endTime: string,
  endDate: string,
|};

type ActivityDates = {|
  dueAt: ?Date,
  endAt?: ?Date,
  startDate?: ?string,
  endDate?: ?string,
|};

type Props = {|
  value: TimeRange,
  onChange: (TimeRange, isChangingEndTime: boolean) => void,
  errorText: ?string,
|};

export const timeRangeOfActivity = (data: ActivityDates): TimeRange => {
  if (data.dueAt == null) {
    return { date: '', startTime: '', endTime: '', endDate: '' };
  }

  const date =
    data.startDate != null
      ? formatDate(new Date(data.startDate))
      : formatDate(data.dueAt);
  const startTime = data.startDate == null ? formatTime(data.dueAt) : '';

  const endDate =
    data.endDate != null
      ? formatDate(new Date(data.endDate))
      : data.endAt != null
      ? formatDate(data.endAt)
      : '';

  const endTime = data.endAt != null ? formatTime(data.endAt) : '';

  return { date, startTime, endTime, endDate };
};

export const isValidTime = (time: string): boolean =>
  /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);

export const activityOfTimeRange = (timeRange: TimeRange): ActivityDates => {
  let startDate = null;

  const startDateParsed = parseDate(timeRange.date);
  const endDateParsed = parseDate(timeRange.endDate);
  if (startDateParsed == null) {
    return {
      dueAt: null,
      startDate: null,
      endDate: null,
      endAt: null,
    };
  }

  const startTimeString = timeRange.startTime;
  let endTimeString = timeRange.endTime;

  if (!isValidTime(startTimeString)) {
    // No start time specified — single date or date range
    startDate = format(startDateParsed, 'yyyy-MM-dd');

    if (endDateParsed != null) {
      return {
        dueAt: null,
        endAt: null,
        startDate,
        endDate: format(endDateParsed, 'yyyy-MM-dd'),
      };
    }

    return {
      dueAt: null,
      endAt: null,
      startDate,
      endDate: null,
    };
  }

  // Start time specified and endDate specified — date time range
  if (endDateParsed != null) {
    if (!isValidTime(endTimeString)) {
      endTimeString = '00:00';
    }

    return {
      dueAt: parseTime(startDateParsed, startTimeString),
      endAt: parseTime(endDateParsed, endTimeString),
      startDate: null,
      endDate: null,
    };
  }

  // Start date and time, no end date time specified
  const startTime = parseTime(startDateParsed, startTimeString);
  const endTime =
    startTime != null ? parseTime(startTime, timeRange.endTime) : null;

  return {
    dueAt: startTime,
    endAt: endTime, // Might be null and will be set as default on API side
    startDate: null,
    endDate: null,
  };
};

export const TimeRangePicker = ({
  value,
  onChange,
  errorText,
}: Props): React.Node => {
  const { t } = useLocale();

  return (
    <Stack direction="column" spacing={2} alignItems="stretch">
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={0.5}
        alignItems="center"
      >
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <FormControl
            required={true}
            error={errorText != null}
            sx={{ flex: 1.5 }}
          >
            <DateInput
              label={t('startDate')}
              value={value.date}
              onChange={date => {
                onChange(
                  {
                    date,
                    startTime: value.startTime,
                    endTime: value.endTime,
                    endDate: value.endDate,
                  },
                  false,
                );
              }}
            />
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <TimeInput
              sx={{ fontSize: { xs: '1rem', sm: '0.8rem' } }}
              value={value.startTime}
              onChange={startTime => {
                onChange(
                  {
                    date: value.date,
                    startTime,
                    endTime: value.endTime,
                    endDate: value.endDate,
                  },
                  false,
                );
              }}
            />
          </FormControl>
        </Stack>
        <Box
          sx={{
            fontSize: '1.25rem',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          {':'}
        </Box>
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <FormControl sx={{ flex: 1.5 }}>
            <DateInput
              label={t('endDate')}
              value={value.endDate}
              onChange={endDate => {
                onChange(
                  {
                    date: value.date,
                    startTime: value.startTime,
                    endTime: value.endTime,
                    endDate,
                  },
                  false,
                );
              }}
            />
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <TimeInput
              sx={{ fontSize: { xs: '1rem', sm: '0.8rem' } }}
              value={value.endTime}
              onChange={endTime => {
                const parsedEndDate = parse(
                  value.endDate,
                  'dd/MM/yyyy',
                  new Date(),
                );
                // Parse time to stop user input triggering wrong end date
                if (parseTime(new Date(parsedEndDate), endTime)) {
                  onChange(
                    {
                      date: value.date,
                      startTime: value.startTime,
                      endTime,
                      endDate: value.endDate,
                    },
                    true,
                  );
                }
              }}
            />
          </FormControl>
        </Stack>
      </Stack>
      {errorText != null && <FormHelperText>{errorText}</FormHelperText>}
    </Stack>
  );
};
