import * as React from 'react';

import { sanitizeHtml } from '@realadvisor/html-sanitizer';
import { setupRenderer } from '@realadvisor/template-renderer';

import { useCMAEditor } from '../../apollo/pages/cma-reports/CMAReportEditor/CMAReportEditorWorkflow';
import { GOOGLE_MAPS_TOKEN, IMAGE_PROXY_DOMAIN } from '../config';
import { useLocale } from '../hooks/locale';
import { type Language, getLocale } from '../locale';

import { createDataUrl } from './url';

type Props = {
  key?: number;
  documentId: null | string;
  content: string;
  filenameTemplate: string;
  countryCode: string;
  documentType: null | string;
  translations: Record<Language, Record<string, string>>;
};

export function useTemplateRenderer(props: Props): {
  filename: null | string;
  markup: null | string;
  dataReady: boolean;
  loading: boolean;
} {
  const {
    key,
    documentId,
    content,
    filenameTemplate,
    countryCode,
    documentType,
    translations,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [markup, setMarkup] = React.useState<null | string>(null);
  const [filename, setFilename] = React.useState<null | string>(null);
  const [data, setData] = React.useState(null);
  const { language } = useLocale();
  const { cmaLanguage } = useCMAEditor();

  const { render } = React.useMemo(
    () =>
      setupRenderer({
        imageProxyDomain: IMAGE_PROXY_DOMAIN,
        mainLocale: getLocale(countryCode, cmaLanguage, true),
        dateFnsLocale: cmaLanguage,
        translations: translations[cmaLanguage],
        sanitize: str =>
          sanitizeHtml(str, {
            tagsBlacklist: ['script'],
            attrsBlacklist: ['onclick'],
            attrsWithScheme: ['href'],
            allowedSchemes: ['http', 'https', 'mailto', 'tel'],
            selfClosingTags: [],
          }),
      }),
    [cmaLanguage, countryCode, translations],
  );

  React.useEffect(() => {
    if (documentId != null && documentType != null) {
      setLoading(true);
      const url = createDataUrl(documentId, documentType, cmaLanguage);
      const controller = new AbortController();
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        signal: controller.signal,
      })
        .then(response => {
          return response.json();
        })
        .then(result => {
          if (result.error != null) {
            console.error(result.error);
          } else {
            setData(result.data);
            setLoading(false);
          }
        })
        .catch(console.error);
      return () => {
        controller.abort();
      };
    }
  }, [key, documentId, documentType, cmaLanguage]);

  const renderTemplate = React.useCallback(
    async ({
      content,
      meta,
    }: {
      content: string;
      meta?: Record<string, unknown>;
    }) => {
      try {
        return await render({
          content,
          data,
          meta: {
            language: cmaLanguage,
            ...meta,
          },
        });
      } catch (error) {
        console.error('Template rendering failed:', error);
        return undefined;
      }
    },
    [render, data, cmaLanguage],
  );

  // render template
  React.useEffect(() => {
    let mounted = true;

    const renderDocument = async () => {
      // Get title first if template exists
      const title =
        (await renderTemplate({
          content: filenameTemplate,
        })) ?? 'document';

      // Render main content
      const markup = await renderTemplate({
        content,
        meta: {
          title,
          googleMapsChannel: 'crm',
          googleMapsKey: GOOGLE_MAPS_TOKEN,
        },
      });

      if (mounted && markup != null) {
        setMarkup(markup);
      }
    };

    renderDocument();

    return () => {
      mounted = false;
    };
  }, [content, filenameTemplate, renderTemplate]);

  // render filename
  React.useEffect(() => {
    let mounted = true;
    render({
      content: filenameTemplate,
      data,
      meta: {
        language,
      },
    })
      .then(filename => {
        if (mounted) {
          setFilename(filename);
        }
      })
      // TODO expose error
      .catch(() => {});
    return () => {
      mounted = false;
    };
  }, [render, data, filenameTemplate, language]);

  return { markup, filename, dataReady: data != null, loading };
}
