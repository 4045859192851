import * as React from 'react';

import { Accordion, AccordionDetails, Button } from '@mui/material';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { SearchesPanel } from '../../components/SearchesPanel';
import { useLocale } from '../../hooks/locale';
import { PlusCircleOutline } from '../../icons/plus-circle-outline';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';

import type { leadPropertySearchCardQuery } from './__generated__/leadPropertySearchCardQuery.graphql';

type Props = {
  leadId: string;
  defaultExpanded: boolean;
};

export const LeadPropertySearchCard = (props: Props) => {
  const [fetchKey, refetch] = React.useReducer(d => d + 1, 0);
  const data = useLazyLoadQuery<leadPropertySearchCardQuery>(
    graphql`
      query leadPropertySearchCardQuery($leadId: ID!) {
        leadById(id: $leadId) {
          contact {
            ...SearchesPanel_predefinedUser
            propertySearches(first: 100)
              @connection(key: "SearchesList_propertySearches", filters: []) {
              edges {
                node {
                  ...SearchesPanel_item
                }
              }
            }
          }
        }
      }
    `,
    { leadId: props.leadId },
    { fetchKey, fetchPolicy: 'store-and-network' },
  );
  const lead = data.leadById;

  const { t } = useLocale();
  const [expanded, setExpanded] = React.useState(props.defaultExpanded);

  if (lead == null) {
    return null;
  }

  const numberOfSearches = (lead.contact?.propertySearches?.edges ?? []).length;

  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, expanded) => setExpanded(expanded)}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={`${t('propertySearch')} (${numberOfSearches})`}
        subTitle={numberOfSearches > 0 ? t('viewSearch') : t('addSearch')}
      />

      {lead.contact && (
        <SearchesPanel
          predefinedUser={lead.contact}
          searches={(lead.contact.propertySearches?.edges ?? []).flatMap(edge =>
            edge?.node ? [edge.node] : [],
          )}
          onCreate={refetch}
          onDelete={refetch}
          buttonRenderer={({ openDrawer }) => {
            return (
              <AccordionDetails css={{ flexDirection: 'column' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PlusCircleOutline />}
                  onClick={openDrawer}
                >
                  {t('addSearch')}
                </Button>
              </AccordionDetails>
            );
          }}
        />
      )}
    </Accordion>
  );
};
