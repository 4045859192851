import * as React from 'react';

import { CircularProgress } from '@mui/material';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from 'react-relay';
import { Box, Flex } from 'react-system';

import { EmailSequenceContactsAddedAlert } from '../../../shared/email-sequence-contacts-added-alert';
import { LoadMoreIndicator } from '../../controls/load-more-indicator';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';

import type { Feed_activities$key } from './__generated__/Feed_activities.graphql';
import type { Feed_buyerLead$key } from './__generated__/Feed_buyerLead.graphql';
import type { Feed_enquiry$key } from './__generated__/Feed_enquiry.graphql';
import type { Feed_lead$key } from './__generated__/Feed_lead.graphql';
import type { Feed_lot$key } from './__generated__/Feed_lot.graphql';
import type { Feed_root$key } from './__generated__/Feed_root.graphql';
import type { Feed_user$key } from './__generated__/Feed_user.graphql';
import type { FeedPagination_root$key } from './__generated__/FeedPagination_root.graphql';
import type { FeedPaginationQuery } from './__generated__/FeedPaginationQuery.graphql';
import type {
  ActivityFilters,
  FeedQuery,
} from './__generated__/FeedQuery.graphql';
import { FeedEmailForm } from './FeedEmailForm';
import { FeedItem } from './FeedItem';
import { FeedNewForm } from './FeedNewForm';

type FeedProps = {
  enquiry?: null | Feed_enquiry$key;
  lot?: null | Feed_lot$key;
  lead?: null | Feed_lead$key;
  user?: null | Feed_user$key;
  isAdmin?: boolean;
  buyerLead?: null | Feed_buyerLead$key;
  filters?: ActivityFilters;
  showForm?: boolean;
  disableForm?: boolean;
  disableDialogCollapse?: boolean;
  showPinned?: boolean;
  inDrawer?: boolean;
  sortBy?: string;
  onChange?: (trigger?: string) => void;
  onEmailSent?: () => void;
  onCallScheduled?: () => void;
};

type BaseFeedProps = Omit<FeedProps, 'filters' | 'sortBy' | 'onChange'> & {
  onChange?: (evtArgs?: { trigger: string }) => void;
  root: Feed_root$key;
  activities: Feed_activities$key;
  hasMore: () => boolean;
  loadUntil: (value: number) => void;
  reloading: boolean;
};

export type ActiveDialogType =
  | 'note'
  | 'visit'
  | 'call'
  | 'task'
  | 'email'
  | 'workflow'
  | null;

export const LoadingFeed = ({
  showForm = true,
}: {
  showForm?: null | boolean;
}) => {
  return (
    <Flex flexGrow={1} flexDirection="column">
      {/* Show form here to do not confuse user */}
      {showForm && (
        <Box pb={3}>
          <FeedNewForm
            enquiry={null}
            lot={null}
            lead={null}
            user={null}
            buyerLead={null}
            root={null}
            disabled={true}
            onCreate={() => {}}
            onCallScheduled={() => {}}
            openDialog={false}
            setOpenDialog={() => {}}
            activeDialogType={null}
            setActiveDialogType={() => {}}
            showUnfinishedWarning={false}
            setShowUnfinishedWarning={() => {}}
            setNextActiveDialogType={() => {}}
          />
        </Box>
      )}
      <Flex
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        height="250px"
      >
        <CircularProgress disableShrink />
      </Flex>
    </Flex>
  );
};

export const BaseFeed = ({ showForm = true, ...props }: BaseFeedProps) => {
  const enquiry = useFragment(
    graphql`
      fragment Feed_enquiry on Enquiry {
        ...FeedNewForm_enquiry
        ...FeedEmailForm_enquiry
        __typename
        id
      }
    `,
    props.enquiry ?? null,
  );
  const lot = useFragment(
    graphql`
      fragment Feed_lot on Lot {
        ...FeedNewForm_lot
        ...FeedEmailForm_lot
        __typename
        id
      }
    `,
    props.lot ?? null,
  );
  const lead = useFragment(
    graphql`
      fragment Feed_lead on Lead {
        ...FeedNewForm_lead
        ...FeedEmailForm_lead
        __typename
        id
      }
    `,
    props.lead ?? null,
  );
  const user = useFragment(
    graphql`
      fragment Feed_user on User {
        ...FeedNewForm_user
        ...FeedEmailForm_user
        __typename
        id
      }
    `,
    props.user ?? null,
  );
  const buyerLead = useFragment(
    graphql`
      fragment Feed_buyerLead on BuyerLead {
        ...FeedNewForm_buyerLead
        ...FeedEmailForm_buyerLead
        __typename
        id
      }
    `,
    props.buyerLead ?? null,
  );
  const parent = enquiry ?? lot ?? lead ?? user ?? buyerLead;

  const root = useFragment(
    graphql`
      fragment Feed_root on Query {
        ...FeedItem_root
        ...FeedNewForm_root
        ...FeedEmailForm_root
      }
    `,
    props.root,
  );
  const activities = useFragment(
    graphql`
      fragment Feed_activities on Activity @relay(plural: true) {
        ...FeedItem_activity
        id
        activityType
        createdBy {
          __typename
        }
        assignedTo {
          __typename
        }
        enquiry {
          id
          lot {
            id
          }
        }
        lot {
          id
        }
        lead {
          id
          lot {
            id
          }
        }
        user {
          id
        }
        buyerLead {
          id
          lot {
            id
          }
        }
        offer {
          id
        }
      }
    `,
    props.activities,
  );

  const { t } = useLocale();
  const { colors } = useTheme();
  const [contactsCount, setContactsCount] = React.useState(0);
  const [alertOpen, setAlertOpen] = React.useState(false);

  // State that is used so if an activity dialog is collapsed, a new dialog cannot be opened as activeDialogType
  // will store the current activity type open (even if it's collapsed).
  const [activeDialogType, setActiveDialogType] =
    React.useState<ActiveDialogType>(null);

  // State that is used so if an email activity is open, a new dialog can be opened
  // if email form wasn't changed
  const [nextActiveDialogType, setNextActiveDialogType] =
    React.useState<ActiveDialogType>(null);
  const [showUnfinishedWarning, setShowUnfinishedWarning] =
    React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const isAdmin = props.isAdmin ?? false;

  // Reset warning message when active dialog is closed
  React.useEffect(() => {
    if (!openDialog) {
      setShowUnfinishedWarning(false);
    }
  }, [openDialog]);

  const onChange = (evtArgs?: { trigger: string }) => {
    props.onChange?.(evtArgs);
  };

  return (
    <Flex flexGrow={1} flexDirection="column">
      {showForm && (
        <Box pb={3}>
          <FeedNewForm
            enquiry={enquiry}
            lot={lot}
            lead={lead}
            user={user}
            buyerLead={buyerLead}
            root={root}
            onCreate={onChange}
            onCallScheduled={props.onCallScheduled}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            activeDialogType={activeDialogType}
            setActiveDialogType={setActiveDialogType}
            showUnfinishedWarning={showUnfinishedWarning}
            setShowUnfinishedWarning={setShowUnfinishedWarning}
            setNextActiveDialogType={setNextActiveDialogType}
            disabled={props.disableForm}
          />
        </Box>
      )}
      {
        <Box mb={3}>
          <EmailSequenceContactsAddedAlert
            open={alertOpen}
            setOpen={value => setAlertOpen(value)}
            contactsCount={contactsCount}
          />
        </Box>
      }
      {activeDialogType === 'email' && root && (
        <FeedEmailForm
          root={root}
          enquiry={enquiry}
          lot={lot}
          lead={lead}
          user={user}
          buyerLead={buyerLead}
          showUnfinishedWarning={showUnfinishedWarning}
          setShowUnfinishedWarning={setShowUnfinishedWarning}
          onEmailSent={() => {
            props.onEmailSent?.();
            onChange();
            setActiveDialogType(null);
            setOpenDialog(false);
          }}
          onCancel={() => {
            setActiveDialogType(nextActiveDialogType);
            setOpenDialog(nextActiveDialogType != null);
          }}
          setContactsCount={setContactsCount}
          setAlertOpen={setAlertOpen}
        />
      )}
      {props.reloading && (
        <CircularProgress
          css={{ margin: 'auto', marginBottom: 16 }}
          disableShrink
        />
      )}
      {activities.length === 0 ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          height="250px"
          css={{ color: colors.mediumText }}
        >
          {t('activitiesEmpty')}
        </Flex>
      ) : (
        activities.map(activity => {
          const activityParent =
            activity.enquiry ??
            activity.lot ??
            activity.lead ??
            activity.user ??
            activity.buyerLead ??
            activity.offer;
          // TODO: generalize this logic
          const direct =
            activityParent?.id === parent?.id ||
            (parent?.__typename === 'Lot' &&
              activityParent?.id != null &&
              activityParent.id === parent.id);

          if (
            activity.createdBy == null ||
            (['call', 'visit', 'task', 'assignment'].includes(
              activity.activityType,
            ) &&
              activity.assignedTo == null &&
              !isAdmin)
          ) {
            return null;
          }

          return (
            <Box key={activity.id} pb={3}>
              <FeedItem
                root={root}
                activity={activity}
                indirect={!direct}
                onCreate={onChange}
                showIcon={true}
                onDelete={onChange}
                onEdit={onChange}
                showPinned={props.showPinned}
                inDrawer={props.inDrawer}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                disableDialogCollapse={props.disableDialogCollapse}
                showUnfinishedWarning={showUnfinishedWarning}
                activeDialogType={activeDialogType}
                setActiveDialogType={setActiveDialogType}
                setShowUnfinishedWarning={setShowUnfinishedWarning}
              />
            </Box>
          );
        })
      )}
      {props.hasMore() && (
        <LoadMoreIndicator
          loadMore={() => props.loadUntil(activities.length + 20)}
        />
      )}
    </Flex>
  );
};

export type FeedApi = {
  retry: () => void;
};

export const Feed = React.forwardRef<FeedApi, FeedProps>(
  ({ filters, sortBy, ...props }, ref) => {
    const [reloading, setReloading] = React.useState(false);
    const queryData = useLazyLoadQuery<FeedQuery>(
      graphql`
        query FeedQuery(
          $count: Int!
          $filters: ActivityFilters
          $sortBy: String
        ) {
          ...Feed_root
          ...FeedPagination_root
            @arguments(count: $count, filters: $filters, sortBy: $sortBy)
        }
      `,
      {
        count: 50,
        sortBy,
        filters,
      },
      // feed on each page is reloaded
      // by default cache is reused from other pages
      // which confuse users
      // perhaps some better solution is coming here
      // https://github.com/facebook/relay/commit/3ea3ac7d4f64f9260c69f49316a92cdc78dd4827
      { fetchPolicy: 'network-only' },
    );

    const { data, loadNext, hasNext, isLoadingNext, refetch } =
      usePaginationFragment<FeedPaginationQuery, FeedPagination_root$key>(
        graphql`
          fragment FeedPagination_root on Query
          @refetchable(queryName: "FeedPaginationQuery")
          @argumentDefinitions(
            count: { type: "Int" }
            cursor: { type: "String" }
            filters: { type: "ActivityFilters" }
            sortBy: { type: "String" }
          ) {
            activities(
              first: $count
              after: $cursor
              filters: $filters
              sortBy: $sortBy
            ) @connection(key: "Feed_activities", filters: []) {
              edges {
                node {
                  ...Feed_activities
                }
              }
            }
          }
        `,
        queryData,
      );

    const activities = [];
    for (const edge of data.activities?.edges ?? []) {
      if (edge?.node != null) {
        activities.push(edge.node);
      }
    }

    const retry = () => {
      setReloading(true);
      refetch(
        {
          count: Math.max(50, activities.length),
          sortBy,
          filters,
        },
        {
          fetchPolicy: 'store-and-network',
          onComplete: () => setReloading(false),
        },
      );
    };

    React.useImperativeHandle(ref, () => ({
      retry,
    }));

    return (
      <BaseFeed
        {...props}
        onChange={evtArgs => {
          props.onChange?.(evtArgs?.trigger);
          retry();
        }}
        root={queryData}
        activities={activities}
        hasMore={() => hasNext}
        reloading={reloading}
        loadUntil={stopIndex => {
          const LOAD_ITEMS_MORE = 50;
          if (
            activities.length <= stopIndex + 1 &&
            hasNext === true &&
            isLoadingNext === false
          ) {
            loadNext(LOAD_ITEMS_MORE);
          }
        }}
      />
    );
  },
);
