import type { LeadCardFragment } from '../__generated__/graphql';
import type { Me } from '../providers/AppDataProvider';

type TUser = Pick<
  NonNullable<Me>,
  'id' | 'is_admin' | 'tenant_id' | 'user_ids_in_led_teams' | 'is_broker'
>;

export const userCanViewLeadDetails = (
  user: TUser | null | undefined,
  lead: LeadCardFragment,
) => {
  return (
    user?.is_admin ||
    (user?.is_broker && lead.claimed_by != null) ||
    user?.id === lead.created_by || // show LeadDetails to agent who created lead https://github.com/realadvisor/realadvisor/issues/16500
    lead.completed === false || // show incomplete to agent : https://github.com/realadvisor/realadvisor/issues/16929
    // show leads if there is a lead agent
    lead.lead_agents
      .filter(agent => agent.source === 'result_page' || agent.claimed_at)
      .some(agent => agent.user_id === user?.id)
  );
};
