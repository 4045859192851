import { gql, useQuery } from '@apollo/client';
import { Abc } from '@mui/icons-material';

import { useFiltersSearchParams } from '../useFiltersSearchParams';

import { FilterChip } from './FilterChip';
import type { AutocompleteItem, QuickFilterProps } from './QuickFilters';
import {
  AutocompletePaper,
  AutocompleteTextField,
  FilterAutocomplete,
} from './QuickFilters';

type GetStringFiltersQuery = {
  dictionaries: {
    name: string;
    label: string;
  }[];
};

const GET_STRING_FILTERS = gql`
  query GetStringFilters {
    dictionaries(where: { type: { _eq: string_filters } }) {
      name
      label
    }
  }
`;

type UseStringFilterTranslationProps = {
  tableName: string;
  columnName: string;
  displayedColumn?: string;
  defaultValue?: string[];
  filter?: QuickFilterProps['filter'];
  addWhereClause: QuickFilterProps['addWhereClause'];
  orderBy?: QuickFilterProps['orderBy'];
  isNullable?: boolean;
  isScraperSchema?: boolean;
};

export const useStringFilterTranslation = ({
  tableName,
  columnName,
  displayedColumn,
  defaultValue,
  filter,
  addWhereClause,
  orderBy,
  isNullable,
  isScraperSchema,
}: UseStringFilterTranslationProps) => {
  const GET_COLUMN_VALUES = gql`
    query GetColumnValues($distinct_on: [${tableName}_select_column!], $where: ${tableName}_bool_exp!, $orderBy: [${tableName}_order_by!]) {
      ${tableName}(distinct_on: $distinct_on, limit: 100, where: $where, order_by: $orderBy) {
        ${displayedColumn ?? ''}
        ${columnName}
      }
    } 
  `;

  // allow filtering list of options
  const whereFilter = filter
    ? addWhereClause({}, filter.path, filter.value)
    : {};

  const { data } = useQuery(GET_COLUMN_VALUES, {
    variables: {
      // We use distinct_on for query optimization when possible:
      // 1. It reduces data transfer by deduplicating at the database level
      // 2. We can't use it with orderBy due to PostgreSQL requiring distinct_on columns first
      distinct_on: orderBy ? undefined : [columnName],
      where: whereFilter,
      orderBy,
    },
    context: { clientName: isScraperSchema ? 'scrapers' : undefined },
  });

  const { data: stringFiltersData } =
    useQuery<GetStringFiltersQuery>(GET_STRING_FILTERS);

  const columnValues = data?.[tableName] as
    | Record<string, string | null>[]
    | Record<string, string[] | null>[]
    | undefined;

  const nullValue = isNullable ? 'null' : null;

  const availableValues =
    columnValues
      ?.map(row => row[displayedColumn ?? columnName] || nullValue)
      .filter(v => v != null) ?? [];

  // We retrieve the first non-null value to check if the column stores an array of values.
  const valuesAreArray = Array.isArray(
    columnValues?.find(row => row[columnName] != null)?.[columnName],
  );

  const options: AutocompleteItem[] = Array.from(
    new Set(availableValues.flat()),
  ).map(value => {
    const dictionaryKey = `${tableName}.${columnName}.${value}`;
    const translatedLabel = stringFiltersData?.dictionaries?.find(
      d => d.name === dictionaryKey,
    )?.label;

    return {
      value,
      label: translatedLabel || value,
    };
  });

  // if defaultValue is not null, we need to map it to displayed column value
  // so that it can be displayed in the chip
  const translatedDefaultValue = (() => {
    if (!defaultValue) {
      return [];
    }

    // If the column stores an array of values, we cannot map our default value to the correct one so we just return them as is.
    return valuesAreArray
      ? defaultValue
      : defaultValue.map(v => {
          const row = columnValues?.find(row => row[columnName] === v);
          const displayVal = row?.[displayedColumn ?? columnName];
          return displayVal == null ? 'null' : displayVal.toString();
        }) ?? [];
  })();

  return {
    columnValues,
    valuesAreArray,
    options,
    translatedDefaultValue,
  };
};

export const StringFilter = ({
  label,
  path,
  displayedColumn,
  where,
  filter,
  addWhereClause,
  deleteWhereClause,
  getValueFromPath,
  getTypeFromPath,
  onChange,
  queryParamsScope,
  disabled,
  orderBy,
  filterAutocompleteRenderOption,
  isNullable = true,
  isScraperSchema,
}: QuickFilterProps) => {
  const parentPath = path.slice(0, -2);
  const parentType = getTypeFromPath(parentPath);
  if (!parentType) {
    throw new Error('Parent type not found');
  }
  const tableName = parentType?.name.split('_bool_exp')[0];
  const columnName = path[path.length - 2];
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);

  const defaultValue: string[] | undefined = getValueFromPath(path, where);

  const { columnValues, valuesAreArray, options, translatedDefaultValue } =
    useStringFilterTranslation({
      tableName,
      columnName,
      displayedColumn,
      defaultValue,
      filter,
      addWhereClause,
      orderBy,
      isNullable,
      isScraperSchema,
    });

  const handleChange = (value: AutocompleteItem[]) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (value.length > 0) {
      // map displayed column value to actual column value
      // we can have multiple values selected
      const values = valuesAreArray
        ? value.map(v => v.value)
        : value.map(v => {
            const row = columnValues?.find(
              row => row[displayedColumn ?? columnName] === v.value,
            );
            return row?.[columnName]?.toString();
          });

      newWhere = addWhereClause(newWhere, path, values);
      newWhere = onChange?.(values, newWhere) ?? newWhere;
    } else {
      newWhere = onChange?.(null, newWhere) ?? newWhere;
    }
    setFiltersParams(newWhere);
  };

  const handleDelete =
    translatedDefaultValue?.length > 0 ? () => handleChange([]) : undefined;

  const chipLabel = (() => {
    if (!translatedDefaultValue || translatedDefaultValue.length === 0) {
      return label;
    }

    const translatedValues = translatedDefaultValue.map(value => {
      const option = options.find(o => o.value === value);
      return option ? option.label : value;
    });

    switch (translatedValues.length) {
      case 1:
        return `${label}: ${translatedValues[0]}`;
      default:
        return `${label}: ${translatedValues[0]} +${
          translatedValues.length - 1
        }`;
    }
  })();

  return (
    <FilterChip
      label={chipLabel}
      icon={<Abc />}
      onDelete={handleDelete}
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <FilterAutocomplete
          options={options}
          onClose={handleClose}
          PaperComponent={AutocompletePaper}
          onChange={(_e, value) => handleChange(value)}
          value={
            translatedDefaultValue?.map(
              v => options.find(o => o.value === v) || { value: v, label: v },
            ) ?? []
          }
          renderInput={AutocompleteTextField}
          renderOption={filterAutocompleteRenderOption}
        />
      )}
    />
  );
};
