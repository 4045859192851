import React, { Suspense, memo, useCallback, useMemo, useState } from 'react';

import {
  type ApolloError,
  NetworkStatus,
  type QueryOptions,
  useQuery,
} from '@apollo/client';
import { Card } from '@mui/material';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import { useLocale } from '../../../src/hooks/locale';
import type { SetHook } from '../../../src/hooks/set';
import type {
  ComparableCmaListingsQuery,
  ComparableCmaListingsQueryVariables,
} from '../../__generated__/graphql';
import { GET_PROPERTY_TYPES } from '../../common/queries';
import { SchemaInitializer } from '../../components/filters/SchemaProvider';
import { MutationErrorSnackbar } from '../../components/MutationErrorModal';
import RaGrid from '../../components/RaGrid';
import { SmartListTitle } from '../../components/SmartListTitle';
import { prepareWhereClauseQuery } from '../../utils/parseWhereClause';
import {
  COMPARABLE_CMA_LISTINGS,
  COMPARABLE_CMA_LISTINGS_COUNT,
} from '../cma-reports/cmaReportsQueries';
import ListingCardInfo from '../cma-reports/step-listings-compare/ListingCardInfo';
import ListingComparableDetails from '../cma-reports/step-listings-compare/ListingComparableDetails';
import { SelectableCard } from '../cma-reports/step-listings-compare/SelectableCard';
import { PropertyTypesProvider } from '../cma-reports/step-listings-compare/StepListingsCompare';

import ComparableListingsSkeleton from './ComparableListingsSkeleton';

export const COMPARABLE_GRID_PAGE_SIZE = 24;

type ComparableListingsWrapperProps = {
  children: React.ReactNode;
  propertyTypes: any[];
};

const ComparableListingsFallback = React.memo(() => (
  <RaGrid
    loading
    renderSkeleton={() => <ComparableListingsSkeleton />}
    renderItemCard={() => null}
  />
));

const ComparableListingsWrapper = React.memo(
  ({ children, propertyTypes }: ComparableListingsWrapperProps) => (
    <Suspense fallback={<ComparableListingsFallback />}>
      <SchemaInitializer isScraperSchema />
      <PropertyTypesProvider propertyTypes={propertyTypes}>
        {children}
      </PropertyTypesProvider>
    </Suspense>
  ),
);

export const initialComparableListings = (
  searchParams: URLSearchParams,
  rowsPerPage: number = COMPARABLE_GRID_PAGE_SIZE,
  countryCode: string,
): QueryOptions<ComparableCmaListingsQueryVariables> => {
  const whereClause = prepareWhereClauseQuery(
    JSON.parse(searchParams.get('where') || '{}'),
  );

  const offset = parseInt(searchParams.get('offset') ?? '0');

  return {
    query: COMPARABLE_CMA_LISTINGS,
    variables: {
      pageSize: rowsPerPage,
      page: offset,
      chWhere: whereClause,
      esWhere: whereClause,
      frWhere: whereClause,
      itWhere: whereClause,
      isCh: countryCode === 'CH',
      isEs: countryCode === 'ES',
      isFr: countryCode === 'FR',
      isIt: countryCode === 'IT',
    },
    context: { clientName: 'scrapers' },
  };
};

type ComparableListingsProps = {
  selection: SetHook<string | number>;
  onSelect: (id: string | number) => void;
  isDrawer?: boolean;
};

export type ComparableListingsCmaListing =
  | ComparableCmaListingsQuery['ch_cma_listings']
  | ComparableCmaListingsQuery['es_cma_listings']
  | ComparableCmaListingsQuery['fr_cma_listings']
  | ComparableCmaListingsQuery['it_cma_listings'];

type ListingCardProps = {
  listing: NonNullable<ComparableListingsCmaListing>[number];
} & ComparableListingsProps;

const cardStyles = {
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const ListingCard = ({ listing, selection, onSelect }: ListingCardProps) => {
  if (!selection) {
    return (
      <Card sx={cardStyles}>
        <ListingCardInfo listing={listing} variant="vertical" />
      </Card>
    );
  }

  return (
    <SelectableCard
      selected={selection.has(listing.id)}
      onChange={() => onSelect(listing.id)}
    >
      <ListingCardInfo listing={listing} variant="vertical" />
    </SelectableCard>
  );
};

const ComparableListings = ({
  selection,
  onSelect,
  isDrawer = false,
}: ComparableListingsProps) => {
  const { t, countryCode } = useLocale();
  const [searchParams] = useSearchParams();
  const [apolloError, setApolloError] = useState<ApolloError | null>(null);

  const queryOptions = useMemo(
    () =>
      initialComparableListings(
        searchParams,
        COMPARABLE_GRID_PAGE_SIZE,
        countryCode,
      ),
    [searchParams, countryCode],
  );

  const {
    data: comparablesData,
    loading: comparablesLoading,
    refetch,
    networkStatus,
  } = useQuery(queryOptions.query, {
    variables: queryOptions.variables,
    context: { clientName: 'scrapers' },
    onError: setApolloError,
    notifyOnNetworkStatusChange: true,
  });

  const { data: propertyTypesData, loading: propertyTypesLoading } = useQuery(
    GET_PROPERTY_TYPES,
    { onError: setApolloError },
  );

  const countVariables = {
    isCh: countryCode === 'CH',
    isEs: countryCode === 'ES',
    isFr: countryCode === 'FR',
    isIt: countryCode === 'IT',
    chWhere: queryOptions.variables?.chWhere,
    esWhere: queryOptions.variables?.esWhere,
    frWhere: queryOptions.variables?.frWhere,
    itWhere: queryOptions.variables?.itWhere,
  };

  const { data: totalCountData, loading: totalCountLoading } = useQuery(
    COMPARABLE_CMA_LISTINGS_COUNT,
    {
      variables: countVariables,
      context: { clientName: 'scrapers' },
      onError: setApolloError,
    },
  );

  const listings = useMemo(() => {
    if (!comparablesData) {
      return [];
    }

    return (
      comparablesData.ch_cma_listings ??
      comparablesData.es_cma_listings ??
      comparablesData.fr_cma_listings ??
      comparablesData.it_cma_listings ??
      []
    );
  }, [comparablesData]);

  const totalCount = useMemo(() => {
    if (!totalCountData) {
      return 0;
    }

    return (
      totalCountData.ch_cma_listings_aggregate?.aggregate?.count ??
      totalCountData.es_cma_listings_aggregate?.aggregate?.count ??
      totalCountData.fr_cma_listings_aggregate?.aggregate?.count ??
      totalCountData.it_cma_listings_aggregate?.aggregate?.count ??
      0
    );
  }, [totalCountData]);

  const isLoading = useMemo(
    () =>
      comparablesLoading ||
      propertyTypesLoading ||
      networkStatus === NetworkStatus.refetch,
    [comparablesLoading, propertyTypesLoading, networkStatus],
  );

  const tableName = `${countryCode.toLowerCase()}_cma_listings`;

  const quickFilters = useMemo(
    () => [
      { label: t('Location'), path: [`${tableName}_bool_exp`, 'places'] },
      { label: t('Online'), path: [`${tableName}_bool_exp`, 'is_online'] },
      {
        label: t('Property type'),
        path: [`${tableName}_bool_exp`, 'property_types', 'name', '_in'],
        displayedColumn: 'label',
      },
      {
        label: t('Surfaces'),
        path: [`${tableName}_bool_exp`, 'computed_surface'],
      },
      {
        label: t('Price'),
        path: [`${tableName}_bool_exp`, 'sale_price'],
      },
      {
        label: t('Rooms'),
        path: [`${tableName}_bool_exp`, 'number_of_rooms'],
      },
      {
        label: t('Price per m²'),
        path: [`${tableName}_bool_exp`, 'computed_price_per_sqm'],
      },
      {
        label: t('Land surface'),
        path: [`${tableName}_bool_exp`, 'land_surface'],
      },
      {
        label: t('Construction year'),
        path: [`${tableName}_bool_exp`, 'construction_year'],
      },
      {
        label: t('Renovation year'),
        path: [`${tableName}_bool_exp`, 'renovation_year'],
      },
    ],
    [t, tableName],
  );

  const MemoizedListingCard = useCallback(
    (listing: NonNullable<ComparableListingsCmaListing>[number]) => (
      <ListingCard
        listing={listing}
        selection={selection}
        onSelect={onSelect}
      />
    ),
    [selection, onSelect],
  );

  return (
    <>
      <ComparableListingsWrapper
        propertyTypes={propertyTypesData?.property_types ?? []}
      >
        <SmartListTitle
          tableName={tableName}
          defaultTitle={t('Comparable listings')}
        />
        <ListToolbar
          isScraperSchema
          tableName={tableName}
          refetch={refetch}
          quickFilters={quickFilters}
        />
        <RaGrid
          items={listings}
          loading={isLoading}
          loadingCount={totalCountLoading}
          totalCount={totalCount}
          pageSize={COMPARABLE_GRID_PAGE_SIZE}
          selection={selection}
          renderSkeleton={() => <ComparableListingsSkeleton />}
          renderItemCard={MemoizedListingCard}
        />
        {!isDrawer && (
          <Routes>
            <Route
              path=":encodedListingId"
              element={<ListingComparableDetails />}
            />
          </Routes>
        )}
      </ComparableListingsWrapper>

      <MutationErrorSnackbar
        onClose={() => setApolloError(null)}
        error={apolloError}
      />
    </>
  );
};

export default memo(ComparableListings);
