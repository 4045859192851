import * as React from 'react';

import * as colors from '@mui/material/colors';

type ContextValue = {
  dialogBg: string;
  menuBg: string;
};

const FiltersContext = React.createContext<ContextValue>({
  menuBg: colors.green[800],
  dialogBg: colors.green[700],
});

export const useFilter = (): ContextValue => {
  return React.useContext(FiltersContext);
};

export const Provider = FiltersContext.Provider;
