import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import {
  Autocomplete,
  CircularProgress,
  type InputProps,
  ListItem,
  ListItemAvatar,
  ListItemText,
  type SxProps,
  TextField,
  type Theme,
  debounce,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import type {
  GetDefaultTeamQuery,
  TeamsSelectFragment,
  TeamsSelectQuery,
} from '../../__generated__/graphql';
import { OptionalTooltip } from '../OptionalTooltip';
import { TeamAvatar } from '../TeamAvatar';

const TEAMS_SELECT_FRAGMENT = gql`
  fragment TeamsSelect on teams {
    id
    name
    logo {
      id
      url
    }
    team_images {
      image {
        url
      }
    }
  }
`;

const SEARCH_TEAMS = gql`
  ${TEAMS_SELECT_FRAGMENT}
  query TeamsSelect($search: String!, $excludeTeamsIds: [uuid!]!) {
    teams(
      where: { name: { _ilike: $search }, id: { _nin: $excludeTeamsIds } }
      limit: 20
    ) {
      id
      ...TeamsSelect
    }
  }
`;

const GET_DEFAULT_TEAM = gql`
  ${TEAMS_SELECT_FRAGMENT}
  query GetDefaultTeam($id: uuid!) {
    teams_by_pk(id: $id) {
      id
      ...TeamsSelect
    }
  }
`;

interface TeamsSelectProps {
  teamId?: string;
  label?: string;
  onChange?: (teamId: string | null) => void;
  size?: 'small' | 'medium';
  excludeTeamsIds?: string[];
  sx?: SxProps<Theme>;
  InputProps?: InputProps;
  autoFocus?: boolean;
  disabled?: boolean;
  loading?: boolean;
  openOnFocus?: boolean;
  fullWidth?: boolean;
  blurOnSelect?: boolean;
  clearOnBlur?: boolean;
}

export const TeamsSelect = ({
  onChange,
  InputProps,
  teamId,
  excludeTeamsIds = [],
  autoFocus = false,
  size = 'medium',
  sx,
  disabled = false,
  loading = false,
  label,
  openOnFocus = false,
  fullWidth = false,
  blurOnSelect = false,
  clearOnBlur = false,
}: TeamsSelectProps) => {
  const { t } = useLocale();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<TeamsSelectFragment[]>([]);
  const [selectedValue, setSelectedValue] = useState<
    (TeamsSelectFragment & { isRestricted?: true }) | null
  >(null);

  const { data: defaultTeam } = useQuery<GetDefaultTeamQuery>(
    GET_DEFAULT_TEAM,
    {
      skip: !teamId,
      variables: { id: teamId },
    },
  );

  useEffect(() => {
    if (defaultTeam?.teams_by_pk != null) {
      setSelectedValue(defaultTeam.teams_by_pk);
    } else if (teamId != null) {
      // We have a team but we don't have access to it (permission denied).
      setSelectedValue({
        id: teamId,
        name: t('Restricted team'),
        logo: null,
        team_images: [],
        isRestricted: true,
      });
    }
  }, [defaultTeam, teamId, t]);

  const [searchTeams, { loading: loadingTeams, error }] =
    useLazyQuery<TeamsSelectQuery>(SEARCH_TEAMS, {
      variables: {
        excludeTeamsIds,
      },
    });

  const search = useCallback(
    (value: string) => {
      searchTeams({
        variables: {
          search: `%${value ?? ''}%`,
        },
      }).then(response => {
        setOpen(true);
        setOptions(response.data?.teams ?? []);
      });
    },
    [searchTeams],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        search(value);
      }, 275),
    [search],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      debouncedSearch(event.target.value);
    } else {
      setOpen(false);
      setOptions([]);
    }
  };

  return (
    <Autocomplete
      sx={sx}
      disabled={disabled || selectedValue?.isRestricted}
      autoComplete={false}
      autoCorrect="off"
      open={open}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      onOpen={() => {
        search('');
      }}
      fullWidth={fullWidth}
      openOnFocus={openOnFocus}
      onFocus={() => openOnFocus && setOpen(true)}
      clearOnBlur={clearOnBlur}
      blurOnSelect={blurOnSelect}
      value={selectedValue}
      getOptionLabel={option => option.name ?? ''}
      options={options}
      loading={loadingTeams || loading}
      loadingText={t('Loading...')}
      noOptionsText={error ? t('Error') : t('No results')}
      onChange={(_event, value) => {
        setSelectedValue(value);
        onChange?.(value?.id ?? null);
      }}
      onBlur={() => {
        if (clearOnBlur) {
          setSelectedValue(null);
        }
      }}
      filterOptions={x => x}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemAvatar>
              <TeamAvatar
                logoUrl={
                  option.logo?.url ?? option.team_images?.[0]?.image?.url
                }
                size={40}
              />
            </ListItemAvatar>
            <ListItemText primary={option.name} />
          </ListItem>
        );
      }}
      componentsProps={{
        paper: {
          elevation: 3,
          sx: {
            borderRadius: '4px',
          },
        },
      }}
      renderInput={params => (
        <OptionalTooltip
          title={t("You don't have access to this team")}
          disabled={!selectedValue?.isRestricted}
        >
          <TextField
            {...params}
            label={label}
            autoFocus={autoFocus}
            placeholder={InputProps?.placeholder ?? t('Search teams')}
            onChange={handleSearch}
            variant="outlined"
            sx={{ background: 'white' }}
            size={size}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'one-time-code', // disable autocomplete and autofill
              type: 'new-password',
              name: 'new-password',
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: InputProps?.startAdornment ?? (
                <PeopleOutlinedIcon />
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} disableShrink />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </OptionalTooltip>
      )}
    />
  );
};
