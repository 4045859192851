// @flow

import * as React from 'react';

import {
  Accordion,
  Avatar,
  Button,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { Box } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { Person } from '../icons/person';

import { AccordionCardSummary } from './accordion-card-summary';

type Props = {|
  names: $ReadOnlyArray<string>,
  defaultExpanded: boolean,
|};

export const LandRegistryOwnersCard = ({
  names,
  defaultExpanded,
}: Props): React.Node => {
  const { t } = useLocale();
  const { text } = useTheme();
  const [showAll, toggleAll] = React.useReducer(v => !v, false);
  const TRUNCATED_OWNERS_COUNT = 3;

  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const toggleCardAccordion = (event, expanded) => {
    setExpanded(expanded);
  };

  const shouldTruncate = names.length > TRUNCATED_OWNERS_COUNT;
  const visibleNamesCount =
    shouldTruncate && showAll === false ? TRUNCATED_OWNERS_COUNT : names.length;
  const visibleNames = names.slice(0, visibleNamesCount);

  return (
    <Accordion expanded={expanded} onChange={toggleCardAccordion}>
      <AccordionCardSummary
        expanded={expanded}
        title={`${t('landRegistryOwners')} (${names.length})`}
      />
      <List>
        {names.length === 0 ? (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{t('noOwnersFound')}</ListItemText>
          </ListItem>
        ) : (
          visibleNames.map((name, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>{name.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Box css={text.body1}>{name}</Box>
              </ListItemText>
            </ListItem>
          ))
        )}
      </List>
      {shouldTruncate && (
        <CardContent>
          <Button size="small" variant="outlined" onClick={toggleAll}>
            {showAll ? t('hideOwners') : t('seeAllOwners')}
          </Button>
        </CardContent>
      )}
    </Accordion>
  );
};
