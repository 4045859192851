import { useMemo } from 'react';

import { FormControl, FormGroup, FormHelperText } from '@mui/material';
import type { Control, FieldError, FieldValues } from 'react-hook-form';

import { RaCheckbox } from './RaCheckbox';
import type { CheckboxFieldDefinition } from './RaForm';
import { RaLabel } from './RaLabel';

interface RaCheckboxGroupProps<TFormData extends FieldValues = FieldValues> {
  control: Control<any>;
  label: string;
  checkboxes: (Omit<CheckboxFieldDefinition<TFormData>, 'disabled'> & {
    disabled?: boolean;
  })[];
  formValues?: any;
  disabled?: boolean;
}

export const RaCheckboxGroup = <TFormData extends FieldValues = FieldValues>({
  control,
  label,
  checkboxes,
  formValues = {},
  disabled,
}: RaCheckboxGroupProps<TFormData>) => {
  const checkboxErrors = useMemo(
    () =>
      checkboxes.reduce((acc, checkbox) => {
        const { error } = control.getFieldState(checkbox.name);
        if (error != null) {
          acc.push(error);
        }

        return acc;
      }, [] as FieldError[]),
    [checkboxes, control],
  );

  return (
    <FormControl
      fullWidth
      component="fieldset"
      variant="standard"
      error={checkboxErrors.length > 0}
    >
      <RaLabel label={label} component="legend" style={{ fontWeight: 500 }} />
      <FormGroup>
        {checkboxes.map(checkbox => {
          if (checkbox.render && !checkbox.render(formValues)) {
            return null;
          }
          return (
            <RaCheckbox
              key={checkbox.name}
              control={control}
              name={checkbox.name}
              label={checkbox.label}
              disabled={disabled || checkbox.disabled}
              inFormGroup
            />
          );
        })}
      </FormGroup>
      <FormHelperText>
        {checkboxErrors.length > 0
          ? checkboxErrors.map(error => error.message).join(', ')
          : null}
      </FormHelperText>
    </FormControl>
  );
};
