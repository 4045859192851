import { useCallback, useEffect, useState } from 'react';

import { type ApolloError, useQuery } from '@apollo/client';
import { AvatarGroup, Box, Stack } from '@mui/material';
import type {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import { gridClasses } from '@mui/x-data-grid-premium';
import { endOfDay, formatDistanceStrict, isBefore, isToday } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AssignmentInd } from '../../../icons/assignment-ind';
import { AssignmentTurnedIn } from '../../../icons/assignment-turned-in';
import { MeetingRoom } from '../../../icons/meeting-room';
import { NoMeetingRoom } from '../../../icons/no-meeting-room';
import { NoteOutline } from '../../../icons/note-outline';
import { Phone } from '../../../icons/phone';
import { PhoneMissed } from '../../../icons/phone-missed';
import { RoundWarning } from '../../../icons/round-warning';
import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { useTheme } from '../../../src/hooks/theme';
import {
  getHorizonLabel,
  translateAppraisalReason,
} from '../../../src/utils/lead-labels';
import { formatNumberByPrecision } from '../../../src/utils/number-format';
import type {
  GetLeadsQueryVariables,
  Leads_Order_By,
} from '../../__generated__/graphql';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import { UserInfo } from '../../components/data-grid/UserInfo';
import IndicatorLeadStage from '../../components/IndicatorLeadStage';
import { MutationErrorSnackbar } from '../../components/MutationErrorModal';
import { TimeAgo } from '../../components/TimeAgo';
import { UserAvatar } from '../../components/UserAvatar';
import { useAppData } from '../../providers/AppDataProvider';
import { getAppraisalPerception } from '../../utils/appraisal';
import { getPaginationVariables } from '../../utils/paginations';

import type { Lead } from './Leads';
import { GET_LEADS, GET_LEADS_COUNT } from './leadsQueries';

// Optimize order by query for admins
const optimizeOrderByQuery = (
  order_by: Leads_Order_By[],
  is_admin: boolean,
): Leads_Order_By[] => {
  // replace  { next_activity_aggregate: { min: { due_at: $1 } }}  by { next_activity_due_at: $1 }
  // replace  { last_activity_aggregate: { max { consolidated_done_at: $1 } }}  by { last_activity_done_at: $1 }
  if (is_admin) {
    return order_by.map(order => {
      if (order.next_activity_aggregate?.min?.due_at) {
        return {
          next_activity_due_at: order.next_activity_aggregate.min.due_at,
        };
      }
      if (order.last_activity_aggregate?.max?.consolidated_done_at) {
        return {
          last_activity_done_at:
            order.last_activity_aggregate.max.consolidated_done_at,
        };
      }
      return order;
    });
  }
  return order_by;
};

const ActivityCell = ({
  node,
  type,
}: {
  node: Lead;
  type: 'next' | 'latest';
}) => {
  const { t, dateLocale } = useLocale();
  const { colors, text } = useTheme();
  let activity = node?.next_activity?.[0];
  if (type === 'latest') {
    activity = node?.last_activity?.[0];
  }
  if (type === 'next' && activity == null) {
    return (
      <Stack flexDirection="row" alignItems="center">
        <RoundWarning size={18} fill={colors.error} css={{ marginRight: 4 }} />
        {t('No activity')}
      </Stack>
    );
  }
  if (type === 'latest' && activity == null) {
    const { created_at, requalified_at } = node;
    const date = requalified_at
      ? new Date(requalified_at)
      : new Date(created_at);
    const distance = formatDistanceStrict(date, Date.now(), {
      locale: dateLocale,
      addSuffix: true,
    });
    return (
      <div>
        <div>{requalified_at ? t('Lead requalified') : t('Lead created')}</div>
        <div css={{ color: colors.grey700 }}>{distance}</div>
      </div>
    );
  }
  let overdue = false;
  if (
    type === 'next' &&
    activity.activity_type !== 'note' &&
    activity.due_at != null
  ) {
    overdue = new Date(activity.due_at) < new Date();
  }

  let due_at = null;

  if (activity.done_at != null) {
    if (isToday(new Date(activity.done_at))) {
      due_at = t('Today');
    } else if (activity.done_at != null) {
      due_at = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.done_at)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  } else if (activity.activity_type !== 'note' && activity.start_date != null) {
    if (isToday(new Date(activity.start_date))) {
      due_at = t('Today');
    } else if (activity.start_date != null) {
      due_at = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.start_date)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  } else if (activity.activity_type !== 'note' && activity.due_at != null) {
    due_at = (
      <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
        {formatDistanceStrict(new Date(activity.due_at), Date.now(), {
          locale: dateLocale,
          addSuffix: true,
        })}
      </div>
    );
  } else if (activity.created_at != null) {
    if (isToday(new Date(activity.created_at))) {
      due_at = t('Today');
    } else if (activity.created_at != null) {
      due_at = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.created_at)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  }

  const overdueStyles = overdue ? { color: colors.errorText } : null;

  if (activity.activity_type === 'task') {
    const { subject } = activity;
    return (
      <Box css={overdueStyles} title={subject ?? ''}>
        <Stack flexDirection="row" alignItems="center">
          <AssignmentTurnedIn
            fill={overdue ? colors.error : colors.mediumText}
            size={18}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {subject == null || subject.trim() === '' ? t('task') : subject}
          </div>
        </Stack>
        {due_at}
      </Box>
    );
  }

  if (activity.activity_type === 'call') {
    const { note, success, done } = activity;
    const Icon = success === false && done === true ? PhoneMissed : Phone;
    return (
      <div css={overdueStyles} title={note ?? ''}>
        <Stack flexDirection="row" alignItems="center">
          <Icon
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('call') : note}
          </div>
        </Stack>
        {due_at}
      </div>
    );
  }

  if (activity.activity_type === 'visit') {
    const { note, success, done } = activity;
    const Icon =
      success === false && done === true ? NoMeetingRoom : MeetingRoom;
    return (
      <div css={overdueStyles}>
        <Stack flexDirection="row" alignItems="center" css={text.ellipsis}>
          <Icon
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('visit') : note}
          </div>
        </Stack>
        {due_at}
      </div>
    );
  }

  if (activity.activity_type === 'note') {
    const { note } = activity;
    return (
      <div css={overdueStyles}>
        <Stack flexDirection="row" alignItems="center" css={text.ellipsis}>
          <NoteOutline
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('Note') : note}
          </div>
        </Stack>
        {due_at}
      </div>
    );
  }

  if (activity.activity_type === 'assignment') {
    return (
      <div css={overdueStyles}>
        <Stack flexDirection="row" alignItems="center">
          <AssignmentInd
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          {type === 'latest' ? t('leadClaimed') : t('claimLead')}
        </Stack>
        {due_at}
      </div>
    );
  }

  return null;
};

const useColumns = () => {
  const { t, locale } = useLocale();
  const { text, textColor } = useTheme();
  const { me } = useAppData();

  const formatAppraisalValues = (
    realadvisor: NonNullable<NonNullable<Lead['property']>>['latest_appraisal'],
  ) => {
    if (!realadvisor || realadvisor.min == null || realadvisor.max == null) {
      return '-';
    }

    let min = realadvisor.min / 1000;
    let max = realadvisor.max / 1000;
    let minUnit = 'k';
    let maxUnit = 'k';
    let minString = '';
    let maxString = '';

    if (min < 1000) {
      minString = min.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }

    if (max < 1000) {
      maxString = max.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }

    if (min >= 1000) {
      min = min / 1000;
      minString = min.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      minUnit = 'm';
    }

    if (max >= 1000) {
      max = max / 1000;
      maxString = max.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      maxUnit = 'm';
    }

    return `${minString}${minUnit} - ${maxString}${maxUnit}`;
  };

  const columns: GridColDef<Lead>[] = [
    {
      headerName: t('Date'),
      width: 100,
      field: 'requalified_or_created_at',
      display: 'flex',
      renderCell: ({ row }) =>
        me?.is_admin ? (
          <div>
            {row.requalified_at && (
              <div>
                ♺&nbsp;
                <TimeAgo dateString={row.requalified_at} />
              </div>
            )}
            <div>
              <TimeAgo dateString={row.created_at} />
            </div>
          </div>
        ) : (
          <TimeAgo dateString={row.requalified_at ?? row.created_at} />
        ),
    },
    {
      headerName: t('Contact'),
      width: 220,
      field: 'contact',
      sortable: false,
      renderCell: ({ row }) =>
        row.contact && (
          <UserInfo
            user={row.contact}
            anonymize={
              // Not admin
              !me?.is_admin &&
              // Not created by me
              row.created_by !== me?.id &&
              // Not claimed by me
              !row.lead_agents
                .filter(
                  agent => agent.source === 'result_page' || agent.claimed_at,
                )
                .find(agent => agent.user_id === me?.id) &&
              // (Legacy) Not claimed by anyone
              // Can be removed once we fully migrate to non-exclusive leads
              !row.claimed_by
            }
          />
        ),
    },
    {
      headerName: t('Address'),
      width: 220,
      field: 'property_address',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="column">
          {(me?.is_admin || row.created_by === me?.id || row.claimed_by) && (
            <div>{[row.property?.route, ' ', row.property?.street_number]}</div>
          )}
          <div css={[text.caption, textColor('mediumText')]}>
            {[row.property?.postcode, ' ', row.property?.locality]}
          </div>
        </Stack>
      ),
    },
    {
      headerName: t('value'),
      width: 135,
      field: 'appraisal_perceived_value,property.latest_appraisal.value',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="row" alignItems="center" gap={1}>
          <div css={{ fontSize: 22 }}>
            {getAppraisalPerception(
              row.appraisal_perception,
              row.appraisal_perceived_value,
              row.property?.latest_appraisal?.max,
              row.property?.latest_appraisal?.min,
            )}
          </div>
          <Stack>
            <div>
              {formatAppraisalValues(row?.property?.latest_appraisal ?? null)}
            </div>
            <div css={[text.caption, textColor('mediumText')]}>
              {formatNumberByPrecision(
                row?.appraisal_perceived_value ?? null,
                locale,
              )}
            </div>
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: t('intention'),
      width: 150,
      field: 'appraisal_reason',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => {
        const horizonLabel = getHorizonLabel(t, {
          appraisalReason: row.appraisal_reason,
          saleHorizon: row.sale_horizon,
          buyHorizon: row.buy_horizon,
        });

        return (
          <div>
            <div>
              {row.appraisal_reason != null
                ? translateAppraisalReason(t, row.appraisal_reason)
                : '-'}
            </div>
            {horizonLabel != null && (
              <div css={[text.caption, textColor('mediumText')]}>
                {horizonLabel}
              </div>
            )}
          </div>
        );
      },
    },
    {
      headerName: t('propertyType'),
      width: 140,
      field: 'property_type',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => {
        const { main_type, label } = row.property?.property_type ?? {};
        if (main_type == null || label == null) {
          return null;
        }
        const mainTypeLabel =
          {
            HOUSE: t('house'),
            APPT: t('apartment'),
            PROP: t('Land'),
            PARK: t('parking'),
            INDUS: t('commercial'),
            GASTRO: t('hotellerie'),
            AGRI: t('agriculturalProperty'),
            SECONDARY: t('secondary'),
            GARDEN: t('Garden'),
          }[main_type] ?? main_type;
        return (
          <Stack>
            <div>{mainTypeLabel}</div>
            <div css={[text.caption, textColor('mediumText')]}>{label}</div>
          </Stack>
        );
      },
    },
    {
      headerName: t('surfaces'),
      width: 100,
      field: 'property',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => {
        const livingSurface = row.property?.living_surface;
        const builtSurface = row.property?.built_surface;
        const landSurface = row.property?.land_surface;
        return (
          <div css={{ whiteSpace: 'nowrap' }}>
            <div>
              {livingSurface != null
                ? livingSurface.toLocaleString(locale) + ' m²'
                : builtSurface != null
                ? builtSurface.toLocaleString(locale) + ' m²'
                : '-'}
            </div>
            <div css={[text.caption, textColor('mediumText')]}>
              {landSurface != null
                ? landSurface.toLocaleString(locale) + ' m²'
                : '-'}
            </div>
          </div>
        );
      },
    },
    {
      headerName: t('Stage'),
      field: 'stage',
      width: 240,
      display: 'flex',
      renderCell: ({ row }) => (
        <div>
          <IndicatorLeadStage status={row?.stage?.status}>
            <div>{row?.stage?.label || '-'}</div>
          </IndicatorLeadStage>
        </div>
      ),
      sortable: false,
    },
    {
      headerName: t('nextActivity'),
      width: 200,
      field: 'next_activity_aggregate.min.due_at',
      display: 'flex',
      renderCell: ({ row }) => <ActivityCell node={row} type="next" />,
    },
    {
      headerName: t('latestActivity'),
      width: 200,
      field: 'last_activity_aggregate.max.consolidated_done_at',
      display: 'flex',
      renderCell: ({ row }) => <ActivityCell node={row} type="latest" />,
    },
    {
      headerName: t('broker'),
      width: 200,
      field: 'broker',
      display: 'flex',
      renderCell: ({ row }) => row.broker && <UserInfo user={row.broker} />,
      sortable: false,
    },
    {
      headerName: t('Source'),
      width: 150,
      field: 'source.label',
      valueGetter: (_, row) => row?.source?.label || '-',
      display: 'flex',
      sortable: false,
    },
  ];

  if (me?.can_edit_settings) {
    columns.push({
      headerName: t('Agents'),
      width: 200,
      field: 'lead_agents',
      sortable: false,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<Lead>) => (
        <AvatarGroup max={3}>
          {params.row.lead_agents.map(agent => (
            <UserAvatar key={agent.id} user={agent.user} />
          ))}
        </AvatarGroup>
      ),
    });
  }

  return columns;
};

type LeadsListViewProps = {
  isLegacyMode?: boolean;
  selectedRows: GridRowSelectionModel;
  setSelectedRows: (newSelection: GridRowSelectionModel) => void;
  where: GetLeadsQueryVariables['where'];
  order_by: Leads_Order_By[];
  isAdmin: boolean;
};

const LeadsListView = ({
  isLegacyMode = false,
  selectedRows,
  setSelectedRows,
  where,
  order_by,
  isAdmin,
}: LeadsListViewProps) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const editMode = searchParams.get('edit') === 'true';
  const [apolloError, setApolloError] = useState<ApolloError | null>(null);

  const { limit, offset } = getPaginationVariables(searchParams);

  const columns = useColumns();

  const { data, loading } = useQuery(GET_LEADS, {
    variables: {
      limit,
      offset,
      where,
      order_by: optimizeOrderByQuery(order_by, isAdmin === true),
    },
    notifyOnNetworkStatusChange: true,
    onError: setApolloError,
  });

  const { countLimit } = getPaginationVariables(searchParams, undefined, true);
  const { data: countData } = useQuery(GET_LEADS_COUNT, {
    variables: {
      where,
      limit: countLimit,
    },
    onError: setApolloError,
  });

  const handleSelectionChange = useCallback(
    (newSelection: GridRowSelectionModel) => {
      setSelectedRows(newSelection);
    },
    [setSelectedRows],
  );

  useEffect(() => {
    if (selectedRows.length > 0 && !editMode) {
      setSelectedRows([]);
    }
  }, [selectedRows, editMode, setSelectedRows]);

  const leadLink = (id: string) =>
    isLegacyMode
      ? {
          pathname: `/leads/${toGlobalId('Lead', id)}`,
          search: searchParams.toString(),
        }
      : {
          pathname: `/v2/leads/${id}`,
          search: searchParams.toString(),
        };

  return (
    <>
      <RaDataGrid
        loading={loading}
        rows={data?.leads ?? []}
        columns={columns}
        rowCount={countData?.leads_aggregate.aggregate?.count ?? 0}
        approximateRowCount
        onRowClick={({ id }, e) => {
          if (e.metaKey || e.ctrlKey) {
            window.open(leadLink(id as string).pathname, '_blank');
            return;
          }
          navigate(leadLink(id as string));
        }}
        disableColumnSelector={false}
        disableColumnFilter
        checkboxSelection={editMode}
        rowSelectionModel={selectedRows}
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelectionChange}
        sortingMode="server"
        slotProps={{
          columnsManagement: {
            getTogglableColumns: () => columns.map(column => column.field),
            toggleAllMode: 'filteredOnly',
          },
          toolbar: {
            csvOptions: {
              disableToolbarButton: true,
            },
            printOptions: {
              disableToolbarButton: true,
            },
          },
        }}
        getRowClassName={({ row }) => {
          return row.next_activity?.[0]?.due_at &&
            isBefore(new Date(row.next_activity?.[0]?.due_at), Date.now())
            ? 'overdue'
            : '';
        }}
        sx={{
          [`& .${gridClasses.row}.overdue`]: {
            backgroundColor: colors.red200,
          },
        }}
      />

      <MutationErrorSnackbar
        error={apolloError}
        onClose={() => setApolloError(null)}
      />
    </>
  );
};

export default LeadsListView;
