import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { PersonOutlineOutlined } from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  type AutocompleteProps,
  type AutocompleteRenderGetTagProps,
  type AutocompleteValue,
  Avatar,
  Chip,
  type InputProps,
  ListItem,
  ListItemAvatar,
  ListItemText,
  debounce,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { useLocale } from '../../../src/hooks/locale';
import type {
  GetDefaultUsersQuery,
  GetDefaultUsersQueryVariables,
  SearchUsersQuery,
  UserSelectFragment,
  Users_Bool_Exp,
  Users_Order_By,
} from '../../__generated__/graphql';
import {
  RaAutoComplete,
  type RaAutoCompleteCreatableItem,
  isCreatableItem,
} from '../data-grid/RaAutoComplete';
import { USER_AVATAR_FRAGMENT, UserAvatar } from '../UserAvatar';
export const USER_SELECT_FRAGMENT = gql`
  ${USER_AVATAR_FRAGMENT}
  fragment UserSelect on users {
    id
    full_name
    ...UserAvatar
    primaryEmail: emails(
      order_by: { primary: desc, verified: desc }
      limit: 1
    ) {
      email
    }
  }
`;

export const SEARCH_USERS = gql`
  ${USER_SELECT_FRAGMENT}
  query SearchUsers(
    $q: String!
    $order_by: [users_order_by!]
    $where: users_bool_exp
  ) {
    search_users_tsv(
      args: { search_text: $q }
      limit: 10
      order_by: $order_by
      where: $where
    ) {
      id
      ...UserSelect
    }
  }
`;

const GET_DEFAULT_USERS = gql`
  ${USER_SELECT_FRAGMENT}
  query GetDefaultUsers($ids: [uuid!]!) {
    users(where: { id: { _in: $ids } }) {
      id
      ...UserSelect
    }
  }
`;

export interface UserSelectProps<Multiple extends boolean = false>
  extends Omit<
    AutocompleteProps<UserSelectFragment, Multiple, false, false>,
    'renderInput' | 'options' | 'onChange' | 'value'
  > {
  userId?: AutocompleteValue<string, Multiple, false, false>;
  onChange?: (
    userId: AutocompleteValue<string, Multiple, false, false>,
  ) => void;
  onCreate?: (userData: {
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  }) => Promise<UserSelectFragment | undefined> | UserSelectFragment | void;
  InputProps?: InputProps;
  orderResultsBy?: Users_Order_By[];
  filters?: Users_Bool_Exp;
  creatable?: boolean;
}

const getCreatableItem = (
  inputStr: string,
): {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
} => {
  // regex don't need to be complex since it's not a validation
  // we just need to know if string is email-like, not if it is valid
  const EMAIL_REGEX = /^\S{1,64}@\S{1,64}\.\S{1,64}$/;

  let first_name: string | null = null;
  let last_name: string | null = null;
  let email: null | string = null;

  if (EMAIL_REGEX.test(inputStr)) {
    email = inputStr;
  } else {
    let lastnames: string[] = [];
    [first_name, ...lastnames] = inputStr.split(' ');

    last_name = lastnames.join(' ');
  }

  return {
    first_name,
    last_name,
    email,
  };
};

export const UserSelect = <Multiple extends boolean = false>({
  onChange,
  onCreate,
  InputProps,
  userId,
  autoFocus = false,
  disabled,
  orderResultsBy,
  filters,
  sx,
  creatable = false,
  multiple,
}: UserSelectProps<Multiple>) => {
  const { t } = useLocale();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<UserSelectFragment[]>([]);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const [selectedValue, setSelectedValue] = useState<
    AutocompleteValue<
      UserSelectFragment & { isRestricted?: true },
      Multiple,
      false,
      false
    >
  >(
    (multiple ? [] : null) as AutocompleteValue<
      UserSelectFragment,
      Multiple,
      false,
      false
    >,
  );

  const { data: defaultUsers } = useQuery<
    GetDefaultUsersQuery,
    GetDefaultUsersQueryVariables
  >(GET_DEFAULT_USERS, {
    skip: !userId,
    variables: {
      ids: Array.isArray(userId) ? userId : userId == null ? [] : [userId],
    },
  });

  useEffect(() => {
    if (defaultUsers != null) {
      if (multiple) {
        setSelectedValue(
          (userId as string[]).map(uId => {
            const user = defaultUsers.users.find(u => u.id === uId);

            return user == null
              ? {
                  id: uId,
                  full_name: t('Restricted user'),
                  isRestricted: true,
                  primaryEmail: [],
                  user_images: [],
                }
              : user;
          }) as AutocompleteValue<
            UserSelectFragment & { isRestricted?: true },
            Multiple,
            false,
            false
          >,
        );
      } else {
        const selectedUser = defaultUsers.users[0];

        setSelectedValue(
          (selectedUser == null && userId != null
            ? {
                id: userId as string,
                full_name: t('Restricted user'),
                isRestricted: true,
                primaryEmail: [],
                user_images: [],
              }
            : selectedUser) as AutocompleteValue<
            UserSelectFragment & { isRestricted?: true },
            Multiple,
            false,
            false
          >,
        );
      }

      if (autoFocus && autocompleteRef.current != null) {
        autocompleteRef.current.querySelector('input')?.focus();
      }
    }
  }, [defaultUsers, autoFocus, multiple, t, userId]);

  // To handle cases where userId is set to null (not handled by previous useEffect because of the skip on useQuery).
  useEffect(() => {
    if (userId == null) {
      setSelectedValue(
        (multiple ? [] : null) as AutocompleteValue<
          UserSelectFragment & { isRestricted?: true },
          Multiple,
          false,
          false
        >,
      );
    }
  }, [userId, multiple]);

  const [searchUsers, { loading, error }] =
    useLazyQuery<SearchUsersQuery>(SEARCH_USERS);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchUsers({
          variables: {
            q: value,
            order_by: orderResultsBy,
            where: filters,
          },
        }).then(response => {
          setOpen(true);
          setOptions(response.data?.search_users_tsv ?? []);
        });
      }, 275),
    [searchUsers, orderResultsBy, filters],
  );

  const setSelectedValueMultiple = useCallback(
    (value: AutocompleteValue<UserSelectFragment, Multiple, false, false>) => {
      setSelectedValue(prevValue => {
        const valueAsArray = Array.isArray(value) ? value : [value];
        const newValue = Array.isArray(prevValue)
          ? [...prevValue, ...valueAsArray]
          : valueAsArray[0];
        onChange?.(
          Array.isArray(newValue) ? newValue.map(user => user.id) : newValue.id,
        );

        return newValue;
      });
    },
    [setSelectedValue, onChange],
  );

  const isRestricted = useMemo(
    () =>
      !multiple &&
      (
        selectedValue as AutocompleteValue<
          UserSelectFragment & { isRestricted?: true },
          false,
          false,
          false
        >
      )?.isRestricted,
    [multiple, selectedValue],
  );

  return (
    <RaAutoComplete<UserSelectFragment, Multiple>
      sx={sx}
      multiple={multiple}
      InputProps={{
        ...InputProps,
        startAdornment: InputProps?.startAdornment ?? (
          <PersonOutlineOutlined style={{ color: grey[700] }} />
        ),
        placeholder: disabled
          ? t('No user')
          : InputProps?.placeholder ?? t('Search users'),
      }}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option:
          | UserSelectFragment
          | RaAutoCompleteCreatableItem<UserSelectFragment>,
      ) =>
        isCreatableItem(option) ? (
          <ListItem {...props} key="new-user">
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                }}
              >
                <PersonAddIcon sx={{ ml: '-3px' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('Create {{name}}', { name: option.inputValue })}
            />
          </ListItem>
        ) : (
          <ListItem {...props} key={option.id}>
            <ListItemAvatar>
              <UserAvatar user={option} size={40} />
            </ListItemAvatar>
            <ListItemText
              primary={option.full_name}
              secondary={option.primaryEmail[0]?.email}
            />
          </ListItem>
        )
      }
      onChange={async (
        _: any,
        value: AutocompleteValue<
          UserSelectFragment | RaAutoCompleteCreatableItem<UserSelectFragment>,
          Multiple,
          false,
          false
        >,
      ) => {
        if (Array.isArray(value)) {
          const creatableItem = value.find(isCreatableItem);

          if (creatableItem != null) {
            const result = onCreate?.(
              getCreatableItem(creatableItem.inputValue),
            );

            if (result != null) {
              const newUser = result instanceof Promise ? await result : result;

              if (newUser != null) {
                setSelectedValueMultiple(
                  newUser as AutocompleteValue<
                    UserSelectFragment,
                    Multiple,
                    false,
                    false
                  >,
                );
              }
            }
          } else {
            setSelectedValue(
              value as AutocompleteValue<
                UserSelectFragment,
                Multiple,
                false,
                false
              >,
            );
            onChange?.(
              value.map(user => user.id) as AutocompleteValue<
                string,
                Multiple,
                false,
                false
              >,
            );
          }
        } else if (value != null && isCreatableItem(value)) {
          const result = onCreate?.(getCreatableItem(value.inputValue));

          if (result != null) {
            const newUser = result instanceof Promise ? await result : result;

            if (newUser != null) {
              setSelectedValue(
                newUser as AutocompleteValue<
                  UserSelectFragment,
                  Multiple,
                  false,
                  false
                >,
              );
              onChange?.(
                newUser.id as AutocompleteValue<string, Multiple, false, false>,
              );
            }
          }
        } else {
          setSelectedValue(
            value as AutocompleteValue<
              UserSelectFragment,
              Multiple,
              false,
              false
            >,
          );
          onChange?.(
            (value?.id ?? null) as AutocompleteValue<
              string,
              Multiple,
              false,
              false
            >,
          );
        }
      }}
      disabled={disabled || isRestricted}
      tooltip={
        isRestricted ? t("You don't have access to this user") : undefined
      }
      error={error != null}
      value={selectedValue}
      getOptionLabel={option => option.full_name || ''}
      isOptionEqualToValue={(
        option:
          | UserSelectFragment
          | RaAutoCompleteCreatableItem<UserSelectFragment>,
        value:
          | UserSelectFragment
          | RaAutoCompleteCreatableItem<UserSelectFragment>,
      ) => option.id === value.id}
      options={options}
      loading={loading}
      open={open}
      renderTags={
        multiple
          ? (
              value: (
                | (UserSelectFragment & { isRestricted?: true })
                | RaAutoCompleteCreatableItem<UserSelectFragment>
              )[],
              getTagProps: AutocompleteRenderGetTagProps,
            ) =>
              value
                .map((option, index) => {
                  if (isCreatableItem(option)) {
                    return null;
                  }

                  const {
                    key,
                    disabled: chipDisabled,
                    ...tagProps
                  } = getTagProps({ index });

                  return (
                    <Chip
                      sx={{ '& .MuiAvatar-root': { ml: 0.5, mr: -0.5 } }}
                      key={key}
                      size="small"
                      label={option.full_name}
                      avatar={<UserAvatar user={option} size={18} />}
                      {...tagProps}
                      disabled={chipDisabled || option.isRestricted}
                    />
                  );
                })
                .filter(Boolean)
          : undefined
      }
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      autoFocus={autoFocus}
      debouncedSearch={debouncedSearch}
      setOpen={setOpen}
      setOptions={setOptions}
      ref={autocompleteRef}
      creatable={
        creatable
          ? {
              elementPosition: 'first',
              createItemFn: inputValue => ({ new: true, inputValue }),
            }
          : undefined
      }
    />
  );
};
