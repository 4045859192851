import { useState } from 'react';

import {
  type ApolloError,
  type InternalRefetchQueriesInclude,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Autocomplete,
  type AutocompleteProps,
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGridPremium,
  type GridColDef,
  type GridRowOrderChangeParams,
  type GridRowsProp,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { Link } from 'react-router-dom';

import { FileIcon } from '../../src/controls/file-icon';
import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import {
  type CreatePropertyFileMutation,
  type CreatePropertyFileMutationVariables,
  type GetDocumentTypesQuery,
  type PropertyFileFragment,
} from '../__generated__/graphql';
import { usePropertyFileUpload } from '../hooks/usePropertyFileUpload';
import {
  CREATE_PROPERTY_FILE,
  REORDER_PROPERTY_FILES,
  UPDATE_FILE,
  UPDATE_PROPERTY_FILE,
} from '../pages/listings/lotsQueries';
import { useFileActionsDef, useFileDef } from '../utils/files/fileDefs';
import { useIsDragging } from '../utils/mouseEvents';

import { FileDeleteDialog } from './FileDeleteDialog';
import { FileViewerDialog } from './FileViewerDialog';
import { MutationErrorSnackbar } from './MutationErrorModal';
import { TimeAgo } from './TimeAgo';
import { UploadFileCard } from './UploadCard';

type PropertyFilesColumns =
  | 'file'
  | 'document_type'
  | 'uploader'
  | 'created'
  | 'is_visible';

type PropertyFilesProps = {
  propertyId?: string;
  files: PropertyFileFragment[];
  refetchQueries?: InternalRefetchQueriesInclude;
  onDelete?: (id: string) => void;
  loading?: boolean;
  hideColumns?: PropertyFilesColumns[];
};

type DocumentTypes = GetDocumentTypesQuery['document_types'];

export const PROPERTY_FILES_FRAGMENT = gql(/* GraphQL */ `
  fragment PropertyFile on property_files {
    property_id
    file_id
    is_visible
    order_nr
    file {
      ...File
    }
  }
`);

const GET_DOCUMENT_TYPES = gql(/* GraphQL */ `
  query GetDocumentTypes {
    document_types: dictionaries(
      where: { type: { _eq: documents } }
      order_by: { label: asc }
    ) {
      id
      label
    }
  }
`);

const FileTypeAutocomplete: React.FC<
  {
    items: DocumentTypes;
    value?: DocumentTypes[number];
  } & Pick<
    AutocompleteProps<DocumentTypes[number], false, true, false>,
    'onChange'
  >
> = ({ items, onChange, value }) => {
  const { t } = useLocale();
  return (
    <Autocomplete
      fullWidth
      autoComplete={false}
      autoCorrect="off"
      options={items}
      onChange={onChange}
      disableClearable
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      componentsProps={{
        paper: {
          elevation: 3,
          sx: {
            borderRadius: '4px',
            width: 300,
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          sx={{
            background: 'white',
            '& .MuiInputBase-root': {
              py: '10px !important',
            },
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'one-time-code', // disable autocomplete and autofill
            type: 'new-password',
            name: 'new-password',
            autoCorrect: 'off',
            spellCheck: 'false',
          }}
          placeholder={t('Not specified')}
        />
      )}
    />
  );
};

export const PropertyFiles: React.FC<PropertyFilesProps> = ({
  propertyId,
  files,
  refetchQueries,
  onDelete,
  loading = false,
  hideColumns,
}) => {
  const { t } = useLocale();
  const [dataGridEl, setDataGridEl] = useState<HTMLElement | null>(null);
  const isDraggingRows = useIsDragging({ target: dataGridEl });
  const [mutationError, setMutationError] = useState<ApolloError | null>(null);
  const [viewerFile, setViewerFile] = useState<
    PropertyFileFragment['file'] | null
  >(null);
  const [deleteFile, setDeleteFile] = useState<PropertyFileFragment | null>(
    null,
  );
  const { data } = useQuery(GET_DOCUMENT_TYPES);

  const propertyFiles: GridRowsProp<PropertyFileFragment> = files;
  const documentTypes = data?.document_types ?? [];

  const [updateFile] = useMutation(UPDATE_FILE, {
    onError: setMutationError,
    refetchQueries,
  });

  const [updatePropertyFile] = useMutation(UPDATE_PROPERTY_FILE, {
    onError: setMutationError,
  });

  const [reOrderPropertyFiles] = useMutation(REORDER_PROPERTY_FILES, {
    onError: setMutationError,
    refetchQueries,
  });

  const handleRowOrderChange = async (
    { row, targetIndex }: GridRowOrderChangeParams,
    propId: string,
  ) => {
    await reOrderPropertyFiles({
      variables: {
        property_id: propId,
        file_id: row.file.id,
        target_position: propertyFiles[targetIndex].order_nr,
      },
    });
  };

  const [createFile] = useMutation(CREATE_PROPERTY_FILE, {
    onError: setMutationError,
    refetchQueries,
  });

  const { upload, pendingFiles, FileErrors } = usePropertyFileUpload<
    CreatePropertyFileMutation,
    CreatePropertyFileMutationVariables
  >({
    propertyId: propertyId ?? '',
    createFile,
  });

  const fileDef = useFileDef<PropertyFileFragment>();
  const fileActionsDef = useFileActionsDef<PropertyFileFragment>({
    setViewerFile,
    setDeleteFile,
    deleteFile,
    showInMenu: true,
    props: {
      align: 'right',
    },
  });

  let columns: GridColDef<PropertyFileFragment>[] = [
    fileDef,
    {
      field: 'document_type',
      headerName: t('Document type'),
      display: 'flex',
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { file } }) => (
        <FileTypeAutocomplete
          items={documentTypes}
          value={file.document_type ?? undefined}
          onChange={(_, value) => {
            updateFile({
              variables: {
                id: file.id,
                input: { document_type_id: value?.id },
              },
            });
          }}
        />
      ),
      sortable: false,
    },
    {
      field: 'uploader',
      flex: 1,
      minWidth: 150,
      headerName: t('Uploaded by'),
      renderCell: ({ row: { file } }) => {
        return (
          <>
            {file.uploader != null && (
              <Link to={`/users/${file.uploader.id}`} key={file.uploader.id}>
                {[file.uploader.first_name, file.uploader.last_name]
                  .filter(Boolean)
                  .join(' ')}
              </Link>
            )}
          </>
        );
      },
      sortable: false,
    },
    {
      field: 'created',
      flex: 1,
      minWidth: 150,
      headerName: t('Uploaded'),
      renderCell: ({ row: { file } }) => (
        <TimeAgo dateString={file.created_at} addSuffix />
      ),
      sortable: false,
    },
    {
      field: 'is_visible',
      flex: 1,
      minWidth: 150,
      headerName: t('Attach to brochure'),
      renderCell: ({ row: { is_visible, file } }) => {
        return (
          <Checkbox
            size="small"
            sx={{
              '&.MuiCheckbox-root': {
                padding: '4px 10px',
              },
            }}
            disableRipple
            disabled={file.mimetype !== 'application/pdf'}
            checked={is_visible}
            onChange={evt => {
              if (propertyId == null) {
                return;
              }

              updatePropertyFile({
                variables: {
                  file_id: file.id,
                  property_id: propertyId,
                  input: { is_visible: evt.target.checked },
                },
              });
            }}
          />
        );
      },
      sortable: false,
    },
    fileActionsDef,
  ];

  columns = columns.filter(
    column =>
      hideColumns == null ||
      !hideColumns.includes(column.field as PropertyFilesColumns),
  );

  return (
    <Box
      sx={{
        flex: 1,
        minHeight: 0,
        overflow: 'auto',
        height: '100%',
        position: 'relative',
      }}
    >
      <FileViewerDialog
        open={viewerFile != null}
        url={viewerFile?.url ?? ''}
        name={viewerFile?.name ?? ''}
        onClose={() => setViewerFile(null)}
      />
      <FileDeleteDialog
        open={deleteFile != null}
        onDelete={onDelete}
        fileId={deleteFile?.file.id ?? ''}
        onClose={() => setDeleteFile(null)}
      />
      <Box
        p={1}
        sx={theme => ({
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 1,
          backgroundColor: theme.palette.subMenu,
        })}
      >
        <UploadFileCard
          onSelect={files => files.forEach(file => upload(file))}
          noMinHeight
          disableDrop={isDraggingRows}
          accept="application/pdf"
          acceptLabel={t('PDF files')}
        />
      </Box>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <DataGridPremium
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          sx={{
            borderRadius: 0,
            backgroundColor: 'white.main',
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: 'none',
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: 'none',
              },
            [`& .${gridClasses.cell}.file-cell`]: {
              cursor: 'pointer',
            },
          }}
          ref={ref => {
            setDataGridEl(ref);
          }}
          rows={propertyFiles}
          columns={columns}
          loading={loading}
          rowReordering={true}
          onRowOrderChange={newModel => {
            if (propertyId != null) {
              handleRowOrderChange(newModel, propertyId);
            }
          }}
          disableMultipleRowSelection
          disableColumnReorder
          disableRowSelectionOnClick
          getRowId={row => row.file.id}
          rowHeight={64}
          onCellClick={({ field, row }) => {
            if (field !== 'file') {
              return;
            }

            const hasViewer = row.file.mimetype === 'application/pdf';

            if (hasViewer) {
              setViewerFile(row.file);
            }
          }}
        />
      </div>
      {pendingFiles.length > 0 && (
        <Table sx={{ widht: '100%', tableLayout: 'fixed' }}>
          <TableBody>
            {pendingFiles.map((file, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  <FileIcon mimetype={file.type} />
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: '100%' }}>
                    <Typography noWrap>{file.name}</Typography>
                  </Box>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <CircularProgress size={24} disableShrink />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <FileErrors />
      <MutationErrorSnackbar
        error={mutationError}
        onClose={() => setMutationError(null)}
      />
    </Box>
  );
};
