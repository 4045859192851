// @flow

import * as React from 'react';

import { LinearProgress } from '@mui/material';
import { formatDistance, parseISO } from 'date-fns';
import mapbox from 'mapbox-gl';
import type GeoJSONSource from 'mapbox-gl/src/source/geojson_source';
import {
  fetchQuery,
  graphql,
  useLazyLoadQuery,
  usePaginationFragment,
  useRelayEnvironment,
} from 'react-relay';
import 'mapbox-gl/dist/mapbox-gl.css';
// $FlowFixMe[untyped-import]
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { MAPBOX_TOKEN } from '../config';
import { PoiMapDrawer } from '../controls/LocationMap/PoiMapDrawer';
import { useDebouncedHandler } from '../hooks/debounce';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { getCurrencyByCountryCode } from '../locale';
import {
  MapLeadsFilters,
  useLeadsParams,
} from '../routes/PropertiesMap/MapLeadsFilters';
import { formatPrice } from '../utils/format-price';

import type { poiMapbox_root$key } from './__generated__/poiMapbox_root.graphql';
import type { poiMapboxPaginationQuery } from './__generated__/poiMapboxPaginationQuery.graphql';
import type { poiMapboxQuery } from './__generated__/poiMapboxQuery.graphql';
import type { poiMapboxWithPropertiesQuery } from './__generated__/poiMapboxWithPropertiesQuery.graphql';

type Props = {|
  mapRef: {| current: null | mapbox.Map |},
  lat: number,
  lng: number,
  zoom: number,
  withSearch: boolean,
  setSearchTopPosition?: number => void,
  isAdmin?: boolean,
  showFilters?: boolean,
|};

const toNumber = param => {
  const number = Number.parseFloat(param ?? '');
  return Number.isNaN(number) ? null : number;
};

const Mapbox = ({ mapRef, lat, lng, zoom, withSearch }) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const containerRef = React.useRef(null);
  const initialViewport = React.useRef([lat, lng, zoom]);
  React.useEffect(() => {
    if (containerRef.current != null) {
      const container = containerRef.current;
      const initialParams = new URLSearchParams(location.search);
      const [initialLat, initialLng, initialZoom] = initialViewport.current;
      const lng = toNumber(initialParams.get('x')) ?? initialLng;
      const lat = toNumber(initialParams.get('y')) ?? initialLat;
      const zoom = toNumber(initialParams.get('z')) ?? initialZoom;
      const map = new mapbox.Map({
        testMode: null,
        container,
        accessToken: MAPBOX_TOKEN,
        attributionControl: false,
        style: 'mapbox://styles/spingwun/cjoeeyssz16n82rlmpsofotvw',
        center: [lng, lat],
        zoom,
        pitch: 45,
        bearing: 0,
      });
      map.addControl(new mapbox.NavigationControl({}));
      const handleMoveend = () => {
        if (withSearch) {
          const params = new URLSearchParams(location.search);
          const center = map.getCenter();
          params.set('x', center.lng.toString());
          params.set('y', center.lat.toString());
          params.set('z', map.getZoom().toString());
          navigate(
            {
              search: `?${params.toString()}`,
            },
            {
              replace: true,
            },
          );
        }
      };
      map.on('moveend', handleMoveend);
      mapRef.current = map;
      return () => {
        map.off('moveend', handleMoveend);
        map.remove();
        mapRef.current = null;
      };
    }
  }, [mapRef, withSearch, navigate]);

  return (
    <>
      {/* emotion global styles breaks markers styles */}
      <div
        css={getPopupStyles(colors)}
        style={{
          position: 'absolute',
          left: 0,
          width: '100%',
          height: '100%',
        }}
        ref={containerRef}
      ></div>
    </>
  );
};

export const renderIcon = (
  scale: number,
  color: string,
  inner: boolean,
): {| canvas: HTMLCanvasElement, imageData: ImageData |} => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const x = scale * 9;
  const y = scale * 9;
  const radius = scale * 5;
  canvas.width = x * 2;
  canvas.height = y * 2;
  if (inner) {
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(x, y, radius + scale * 3, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = '#ffffff';
    ctx.beginPath();
    ctx.arc(x, y, radius + scale * 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
  }
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
  return { canvas, imageData: ctx.getImageData(0, 0, x * 2, y * 2) };
};

const getMarkerType = poi => {
  let markerType = null;
  if (poi.hasDevLeads === true) {
    if (poi.hasCompletedLeads === true) {
      markerType = 'devlead-and-complete-icon';
    } else {
      markerType = 'devlead-and-incomplete-icon';
    }
  } else {
    if (poi.hasCompletedLeads === true) {
      markerType = 'normal-and-complete-icon';
    } else {
      markerType = 'normal-and-incomplete-icon';
    }
  }
  return markerType;
};

const getPoiFeature = poi => {
  return {
    type: 'Feature',
    properties: {
      id: poi.id,
      iconImage: getMarkerType(poi),
    },
    geometry:
      poi.lng == null || poi.lat == null
        ? null
        : {
            type: 'Point',
            coordinates: [poi.lng, poi.lat],
          },
  };
};

const Markers = ({ mapRef, items, selectedItem }) => {
  const { colors } = useTheme();
  // prerender markers icons
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const handleLoad = () => {
        map.addImage(
          'normal-and-complete-icon',
          renderIcon(2, colors.green500, true).imageData,
          { pixelRatio: 2 },
        );
        map.addImage(
          'devlead-and-complete-icon',
          renderIcon(2, colors.orange500, true).imageData,
          { pixelRatio: 2 },
        );
        map.addImage(
          'normal-and-incomplete-icon',
          renderIcon(2, colors.blue500, false).imageData,
          { pixelRatio: 2 },
        );
        map.addImage(
          'devlead-and-incomplete-icon',
          renderIcon(2, colors.orange500, false).imageData,
          { pixelRatio: 2 },
        );
        map.addSource('markers', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        });
        map.addLayer({
          id: 'markers',
          type: 'symbol',
          source: 'markers',
          layout: {
            'icon-image': ['get', 'iconImage'],
            'icon-allow-overlap': true,
            'symbol-z-order': 'source',
          },
        });
      };
      if (map.loaded()) {
        handleLoad();
      } else {
        map.on('load', handleLoad);
        return () => {
          map.off('load', handleLoad);
        };
      }
    }
  }, [mapRef, colors]);
  // apply data and bind icons

  const handleItemsLoad = React.useCallback(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      ((map.getSource('markers'): any): null | GeoJSONSource)?.setData({
        type: 'FeatureCollection',
        features: items.flatMap(item =>
          item === selectedItem ? [] : [getPoiFeature(item)],
        ),
      });
    }
  }, [mapRef, items, selectedItem]);

  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      if (!map.loaded()) {
        map.on('load', handleItemsLoad);
        return () => {
          map.off('load', handleItemsLoad);
        };
      }
    }
  }, [mapRef, handleItemsLoad]);

  React.useEffect(() => {
    handleItemsLoad();
  }, [mapRef, items, selectedItem, handleItemsLoad]);
  return null;
};

const findMinBy = <T>(array: $ReadOnlyArray<T>, by: T => number): null | T => {
  let min = Number.POSITIVE_INFINITY;
  let minItem = null;
  for (const item of array) {
    const value = by(item);
    if (value < min) {
      min = value;
      minItem = item;
    }
  }
  return minItem;
};

const getPopupStyles = colors => ({
  '.mapboxgl-popup-content-address': {
    padding: '13px 15px 10px 15px',
  },
  '.mapboxgl-popup-content-item': {
    padding: '10px 15px',
  },
  '.mapboxgl-popup-content-separator': {
    borderBottom: `1px solid ${colors.grey300}`,
  },
  '.mapboxgl-popup': {
    pointerEvents: 'none',
  },
  '.mapboxgl-popup-tip': {
    display: 'none',
  },
  '.mapboxgl-popup-content': {
    pointerEvents: 'none',
    fontSize: 12,
    color: '#000',
    backgroundColor: colors.white,
    opacity: 0.95,
    padding: '0px',
  },
  '.mapboxgl-popup-content-appraisal': {
    fontWeight: 'bold',
  },
  '.mapboxgl-popup-content-name-date': {
    display: 'flex',
  },
  '.mapboxgl-popup-content-name': {
    flex: 1,
    paddingRight: '10px',
  },
  '.mapboxgl-popup-content-route': {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '3px',
  },
});

const formatCurrencyRange = (range, currency, locale) =>
  `${formatPrice(range?.min, locale, currency)} - ${formatPrice(
    range?.max,
    locale,
    currency,
  )}`;

const getQualificationFunnel = property => {
  const lead = property.lead;

  if (lead == null) {
    return [];
  }

  const getStep1 = () => {
    switch (lead.relationship) {
      case 'owner':
        return lead.relationship;
      case 'tenant':
        return lead.relationship;
      default:
        return null;
    }
  };

  const getStep3 = () => {
    switch (lead.appraisalReason) {
      case 'sell':
        return lead.saleHorizon;
      case 'refinance':
        return lead.mortgageTerm;
      case 'separation':
        return lead.appraisalSeparationNextStep;
      case 'inheritance':
        return lead.appraisalInheritanceNextStep;
      case 'buy_soon':
        return lead.buyHorizon;
      default:
        return null;
    }
  };

  if (getStep1() == null) {
    return ['not_owner'];
  }

  return [
    property.propertyType?.label,
    // step 1
    getStep1(),
    // step 2
    lead.appraisalReason,
    // step 3
    getStep3(),
  ].filter(step => step != null);
};

const makeMarker = ({
  environment,
  map,
  colors,
  poiId,
  dateLocale,
  locale,
  t,
}) => {
  const variables = {
    id_eq: poiId,
  };

  return fetchQuery<poiMapboxWithPropertiesQuery>(
    environment,
    graphql`
      query poiMapboxWithPropertiesQuery($id_eq: String!) {
        pois(first: 1, filters: { id_eq: $id_eq })
          @connection(key: "ConnectionPoiMapWithProps_pois", filters: []) {
          edges {
            node {
              lat
              lng
              hasDevLeads
              hasCompletedLeads
              properties {
                route
                streetNumber
                countryCode
                postcode
                locality
                latestAppraisal {
                  createdAt
                  realadvisor {
                    min
                    max
                  }
                }
                propertyType {
                  label
                }
                lead {
                  relationship
                  appraisalReason
                  saleHorizon
                  mortgageTerm
                  appraisalSeparationNextStep
                  appraisalInheritanceNextStep
                  buyHorizon
                  contact {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables,
  )
    .toPromise()
    .then(data => {
      const [node] = (data?.pois?.edges ?? []).map(edge => edge?.node);
      if (node == null) {
        throw Error('Marker not found');
      }

      const sorted = [...node.properties].sort((a, b) => {
        const aDate =
          a.latestAppraisal?.createdAt != null
            ? new Date(a.latestAppraisal.createdAt)
            : 0;
        const bDate =
          b.latestAppraisal?.createdAt != null
            ? new Date(b.latestAppraisal.createdAt)
            : 0;
        return aDate < bDate ? 1 : -1;
      });
      const html = sorted
        .map((property, i) => {
          const latestAppraisal = property.latestAppraisal;

          const contact = property.lead?.contact;
          const qualificationFunnel =
            getQualificationFunnel(property).join(' • ');

          const isLast = i + 1 === node.properties.length;

          const name =
            contact?.firstName != null || contact?.lastName != null
              ? `${contact.firstName ?? ''} ${contact.lastName ?? ''}`
              : t('unknown');
          const currency = getCurrencyByCountryCode(
            property.countryCode ?? 'CH',
          );

          const createdAtDistance =
            latestAppraisal?.createdAt != null
              ? formatDistance(
                  parseISO(latestAppraisal.createdAt),
                  Date.now(),
                  {
                    locale: dateLocale,
                  },
                )
              : '';

          const currencyRange =
            latestAppraisal != null
              ? formatCurrencyRange(
                  latestAppraisal.realadvisor,
                  currency,
                  locale,
                )
              : '-';

          return `

          ${
            i === 0
              ? `
            <div class="mapboxgl-popup-content-address mapboxgl-popup-content-separator">
               <div class="mapboxgl-popup-content-route">
                ${property.route ?? ''} ${property.streetNumber ?? ''}
               </div>
               <div>
                ${property.postcode ?? ''} ${property.locality ?? ''}
               </div>
            </div>
          `
              : ''
          }

          <div class="mapboxgl-popup-content-item${
            !isLast ? ' mapboxgl-popup-content-separator' : ''
          }">
            <div class="mapboxgl-popup-content-name-date">
              <div class="mapboxgl-popup-content-name">
                ${name}
              </div>
              <div>
                  ${createdAtDistance}
              </div>
            </div>
            <div class="mapboxgl-popup-content-appraisal">
              ${currencyRange}
            </div>
            <div>
              ${qualificationFunnel}
            </div>
          </div>
        `;
        })
        .join('\n');
      const markerType = getMarkerType(node);
      let canvas;
      if (markerType === 'normal-and-complete-icon') {
        canvas = renderIcon(2.5, colors.green500, true).canvas;
      }
      if (markerType === 'normal-and-incomplete-icon') {
        canvas = renderIcon(2.5, colors.blue500, false).canvas;
      }
      if (markerType === 'devlead-and-complete-icon') {
        canvas = renderIcon(2.5, colors.orange500, true).canvas;
      }
      if (markerType === 'devlead-and-incomplete-icon') {
        canvas = renderIcon(2.5, colors.orange500, false).canvas;
      }
      if (canvas == null) {
        throw Error(`Unknown marker type "${markerType ?? ''}"`);
      }
      const matchedCanvas = canvas;
      const pixelRatio = 2;
      const image = new Image();
      image.style.pointerEvents = 'none';
      image.src = matchedCanvas.toDataURL();
      image.width = matchedCanvas.width / pixelRatio;
      image.height = matchedCanvas.height / pixelRatio;
      const popup = new mapbox.Popup({
        maxWidth: '400px',
        closeButton: false,
        closeOnClick: true,
        closeOnMove: true,
      });
      const marker: mapbox.Marker = new mapbox.Marker({ element: image })
        .setLngLat([node.lng ?? 0, node.lat ?? 0])
        .setPopup(popup.setHTML(html))
        .addTo(map)
        .togglePopup();
      return marker;
    });
};

const HoveredMarker = ({ mapRef }) => {
  const environment = useRelayEnvironment();
  const { colors } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dateLocale, locale, t } = useLocale();
  const [hoveredId, setHoveredId] = React.useState(null);

  // render hovered marker and popup
  React.useEffect(() => {
    let mounted = true;
    let marker = null;
    if (mapRef.current != null && hoveredId != null) {
      const map = mapRef.current;
      makeMarker({
        environment,
        map,
        colors,
        poiId: hoveredId,
        dateLocale,
        locale,
        t,
      }).then(newMarker => {
        if (mounted) {
          marker = newMarker;
        } else {
          newMarker.remove();
        }
      });
      return () => {
        mounted = false;
        if (marker) {
          marker.remove();
        }
      };
    }
  }, [mapRef, environment, colors, hoveredId, dateLocale, locale, t]);

  const setHoveredIdDebounced = useDebouncedHandler(200, setHoveredId);
  // track markers hover and click
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      // mousemove is used to allow switching to overlapped markers
      // where mouseenter is not fired because layer already entered
      let lastFeature = null;
      const handleMousemove = event => {
        const closestFeature = findMinBy(event.features, feature => {
          const [lng, lat] = feature.geometry.coordinates;
          return event.lngLat.distanceTo(new mapbox.LngLat(lng, lat));
        });
        if (
          closestFeature &&
          closestFeature.properties.id !== lastFeature?.properties.id
        ) {
          lastFeature = closestFeature;
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';
          setHoveredIdDebounced(closestFeature.properties.id);
        }
      };
      const handleMouseleave = () => {
        lastFeature = null;
        map.getCanvas().style.cursor = '';
        setHoveredIdDebounced(null);
      };

      const handleClick = e => {
        const { lng, lat } = e.lngLat;
        if (lastFeature) {
          // open hovered lead on click
          const [lng, lat] = lastFeature.geometry.coordinates;
          const poiId = lastFeature.properties.id;
          searchParams.set('pointLat', lat);
          searchParams.set('pointLng', lng);
          searchParams.set('poiId', poiId);
        } else {
          searchParams.set('pointLat', lat);
          searchParams.set('pointLng', lng);
          searchParams.delete('poiId');
        }
        setSearchParams(searchParams);
      };

      map.on('mousemove', 'markers', handleMousemove);
      map.on('mouseleave', 'markers', handleMouseleave);
      map.on('click', handleClick);
      return () => {
        map.off('mousemove', 'markers', handleMousemove);
        map.off('mouseleave', 'markers', handleMouseleave);
        map.off('click', handleClick);
      };
    }
  }, [
    mapRef,
    environment,
    colors,
    searchParams,
    setSearchParams,
    setHoveredIdDebounced,
  ]);
  return null;
};

const PoiMarkers = ({ mapRef }) => {
  const [params] = useLeadsParams();
  const queryData = useLazyLoadQuery<poiMapboxQuery>(
    graphql`
      query poiMapboxQuery($count: Int!, $filters: PoisFilters) {
        ...poiMapbox_root @arguments(count: $count, filters: $filters)
      }
    `,
    {
      count: 0,
      filters: {
        bbox: [0, 0, 0, 0],
        completed_in: params.completed_in,
        saleHorizon_in: params.saleHorizon_in,
        status_in: params.status_in,
        startDate: params.startDate?.toISOString(),
        endDate: params.endDate?.toISOString(),
        mostRecent: params.mostRecent,
        useRequalifiedIfAvailable: params.useRequalifiedIfAvailable,
      },
    },
    { fetchPolicy: 'store-only' }, // we don't need to fetch data on mount
  );

  const { data, refetch } = usePaginationFragment<
    poiMapboxPaginationQuery,
    poiMapbox_root$key,
  >(
    graphql`
      fragment poiMapbox_root on Query
      @refetchable(queryName: "poiMapboxPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int!" }
        cursor: { type: "String", defaultValue: null }
        filters: { type: "PoisFilters" }
      ) {
        pois(first: $count, after: $cursor, filters: $filters)
          @connection(key: "Connection_pois", filters: []) {
          edges {
            node {
              id
              lat
              lng
              hasDevLeads
              hasCompletedLeads
            }
          }
        }
      }
    `,
    queryData,
  );

  const items = [];
  for (const edge of data.pois?.edges ?? []) {
    if (edge?.node) {
      items.push(edge.node);
    }
  }

  const refetchWithNewBounds = useDebouncedHandler(300, map => {
    const bounds = map.getBounds();
    const zoom = map.getZoom();
    if (zoom < 8) {
      return;
    }
    refetch(
      {
        count: 1000,
        filters: {
          bbox: [
            bounds?.getWest(),
            bounds?.getSouth(),
            bounds?.getEast(),
            bounds?.getNorth(),
          ],
          completed_in: params.completed_in,
          saleHorizon_in: params.saleHorizon_in,
          status_in: params.status_in,
          startDate: params.startDate?.toISOString(),
          endDate: params.endDate?.toISOString(),
          mostRecent: params.mostRecent,
          useRequalifiedIfAvailable: params.useRequalifiedIfAvailable,
        },
      },
      { fetchPolicy: 'network-only' },
    );
  });

  // track bounds changes
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      refetchWithNewBounds(map);
      const handleMoveend = () => {
        refetchWithNewBounds(map);
      };
      map.on('moveend', handleMoveend);
      return () => {
        map.off('moveend', handleMoveend);
      };
    }
  }, [mapRef, refetch, params, refetchWithNewBounds]);
  return (
    <>
      <Markers
        mapRef={mapRef}
        items={(params.zoom ?? 10) >= 8 ? items : []} // hide markers on low zoom if params zoom is defined
        selectedItem={null}
      />
      <HoveredMarker mapRef={mapRef} />
    </>
  );
};

export const PoiMapbox = ({
  showFilters = false,
  ...props
}: Props): React.Node => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const point = React.useMemo(() => {
    const params = new URLSearchParams(search);
    const lat = toNumber(params.get('pointLat'));
    const lng = toNumber(params.get('pointLng'));
    const poiId = params.get('poiId');
    if (lat != null && lng != null) {
      if (props.setSearchTopPosition) {
        props.setSearchTopPosition(8);
      }
      return { lat, lng, poiId };
    } else {
      if (props.setSearchTopPosition) {
        props.setSearchTopPosition(58);
      }
      return null;
    }
  }, [search, props]);

  return (
    <>
      <Mapbox
        mapRef={props.mapRef}
        lat={props.lat}
        lng={props.lng}
        zoom={props.zoom}
        withSearch={props.withSearch}
      />
      <PoiMapDrawer
        key={[point?.lat, point?.lng].join('_')}
        lat={point?.lat}
        lng={point?.lng}
        poiId={point?.poiId}
        withSearch={props.withSearch}
        onClose={() => {
          const params = new URLSearchParams(search);
          params.delete('pointLat');
          params.delete('pointLng');
          navigate(
            {
              search: `?${params.toString()}`,
            },
            {
              replace: true,
            },
          );
        }}
      />
      <React.Suspense fallback={<LinearProgress />}>
        <div css={{ height: 4 }}></div> {/* to avoid layout shift */}
        <PoiMarkers mapRef={props.mapRef} />
      </React.Suspense>
      {showFilters && <MapLeadsFilters isAdmin={props.isAdmin ?? false} />}
    </>
  );
};
