import { useRef, useState } from 'react';

import {
  Edit,
  EditOff,
  FileDownload,
  FileUpload,
  FilterAltOffOutlined,
  FilterAltOutlined,
  MoreHoriz,
  QueryStatsOutlined,
  Refresh,
  SearchOutlined,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { Link, type To } from 'react-router-dom';

import {
  type FilterProps,
  Filters,
} from '../apollo/components/filters/Filters';
import { useAppData } from '../apollo/providers/AppDataProvider';
import { useScopedSearchParams } from '../apollo/utils/navigation';
import { useLocale } from '../src/hooks/locale';

export interface ImportDialogProps {
  open: boolean;
  onImportSuccess: () => void;
  onImporterClose: () => void;
}

export interface ExportDialogProps {
  where: any;
  open: boolean;
  onExporterClose: () => void;
}

export interface ListToolbarProps {
  tableName?: string;
  quickFilters?: FilterProps['quickFilters'];
  viewLinks?: {
    name: string;
    icon: React.ReactNode;
  }[];
  searchComponent?: React.ReactNode;
  newLink?: To;
  actions?: React.ReactNode;
  moreActions?: React.ReactNode;
  bulkActions?: React.ReactNode;
  ImportDialog?: React.ElementType<ImportDialogProps>;
  ExportDialog?: React.ElementType<ExportDialogProps>;
  refetch?: () => void;
  queryParamsScope?: string;
  defaultFilterTypeExpanded?: 'advanced' | 'quick';
  showRealCountOption?: boolean;
  isScraperSchema?: boolean;
}

export const ListToolbar = ({
  tableName,
  quickFilters,
  viewLinks,
  searchComponent,
  newLink,
  actions,
  moreActions,
  bulkActions,
  ImportDialog,
  ExportDialog,
  refetch,
  queryParamsScope,
  defaultFilterTypeExpanded = 'quick',
  showRealCountOption = false,
  isScraperSchema,
}: ListToolbarProps) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const isAdmin = me?.is_admin;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const [searchParams, setSearchParams] =
    useScopedSearchParams(queryParamsScope);
  const where = JSON.parse(searchParams.get('where') || '{}');
  const isEditing = searchParams.get('edit') === 'true';

  const [showSearch, setShowSearch] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const menuAnchorRef = useRef<HTMLButtonElement>(null);
  const [expandedFilterType, setExpandedFilterType] = useState<
    'advanced' | 'quick' | null
  >(isMobile ? null : defaultFilterTypeExpanded);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
          width: '100%',
        }}
      >
        {viewLinks && !(isMobile && showSearch) && !isEditing && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              p: 1,
              gap: 1,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {viewLinks.map(({ name, icon }) => (
              <IconButton
                key={name}
                onClick={() => {
                  searchParams.set('view', name);
                  searchParams.delete('limit');
                  searchParams.delete('offset');
                  setSearchParams(searchParams);
                }}
              >
                {icon}
              </IconButton>
            ))}
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            gap: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '56px',
          }}
        >
          <Stack
            direction="row"
            sx={{
              flexGrow: 1,
              maxWidth: 600,
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'relative',
            }}
          >
            {!isEditing && (
              <ClickAwayListener onClickAway={() => setShowSearch(false)}>
                <Stack
                  direction={'row'}
                  gap={1}
                  sx={{ flexGrow: 1, justifyContent: 'flex-end' }}
                >
                  {searchComponent && !showSearch && (
                    <IconButton onClick={() => setShowSearch(!showSearch)}>
                      <SearchOutlined />
                    </IconButton>
                  )}

                  {searchComponent && showSearch && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                      }}
                    >
                      {searchComponent}
                    </Box>
                  )}
                </Stack>
              </ClickAwayListener>
            )}
            {quickFilters && !(isMobile && showSearch) && !isEditing && (
              <IconButton
                onClick={() =>
                  !expandedFilterType
                    ? setExpandedFilterType('quick')
                    : setExpandedFilterType(null)
                }
              >
                <Badge
                  badgeContent={Object.keys(where).length}
                  color="secondary"
                >
                  <FilterAltOutlined />
                </Badge>
              </IconButton>
            )}
            {refetch && !(isMobile && showSearch) && !isEditing && (
              <IconButton onClick={() => refetch()}>
                <Refresh />
              </IconButton>
            )}
            {!(isMobile && showSearch) && !isEditing && (
              <IconButton
                onClick={() => setMenuOpen(!menuOpen)}
                ref={menuAnchorRef}
              >
                <MoreHoriz />
              </IconButton>
            )}
          </Stack>

          {newLink && !(isMobile && showSearch) && !isEditing && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={newLink}
            >
              {t('New')}
            </Button>
          )}
          {isEditing && bulkActions}
          {isEditing && (
            <Button
              onClick={() => {
                searchParams.delete('edit');
                setSearchParams(searchParams);
              }}
              startIcon={<EditOff />}
            >
              {t('Finish')}
            </Button>
          )}
        </Box>
        {actions}
      </Box>
      {menuOpen && (
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={menuOpen}
          anchorEl={menuAnchorRef.current}
          // Align the popper to the right side of the anchor element
          placement="bottom-end"
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                  <MenuList
                    id="split-button-menu"
                    autoFocusItem
                    onClick={() => setMenuOpen(false)}
                  >
                    {tableName && isAdmin && (
                      <MenuItem
                        onClick={() => {
                          setExpandedFilterType(
                            expandedFilterType === 'advanced'
                              ? null
                              : 'advanced',
                          );
                        }}
                      >
                        <ListItemIcon>
                          <FilterAltOutlined />
                        </ListItemIcon>
                        {t('Advanced filters')}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        searchParams.delete('where');
                        searchParams.delete('smart_list_id');
                        setSearchParams(searchParams);
                        //setFilterType(null);
                      }}
                    >
                      <ListItemIcon>
                        <FilterAltOffOutlined />
                      </ListItemIcon>
                      {t('Clear filters')}
                    </MenuItem>
                    {showRealCountOption && tableName && isAdmin && (
                      <MenuItem
                        onClick={() => {
                          if (searchParams.get('show_real_count') === 'true') {
                            searchParams.delete('show_real_count');
                          } else {
                            searchParams.set('show_real_count', 'true');
                          }
                          setSearchParams(searchParams);
                        }}
                      >
                        <ListItemIcon>
                          <QueryStatsOutlined />
                        </ListItemIcon>
                        {searchParams.get('show_real_count') === 'true'
                          ? t('Hide real count')
                          : t('Show real count')}
                      </MenuItem>
                    )}
                    {ImportDialog || ExportDialog ? <Divider /> : null}
                    {ImportDialog && (
                      <MenuItem onClick={() => setShowImportDialog(true)}>
                        <ListItemIcon>
                          <FileUpload />
                        </ListItemIcon>
                        {t('Import')}
                      </MenuItem>
                    )}
                    {ExportDialog && (
                      <MenuItem onClick={() => setShowExportDialog(true)}>
                        <ListItemIcon>
                          <FileDownload />
                        </ListItemIcon>
                        {t('Export')}
                      </MenuItem>
                    )}
                    {bulkActions && [
                      <Divider key="bulk-edit-divider" />,
                      <MenuItem
                        key="bulk-edit-menu-item"
                        onClick={() => {
                          searchParams.set('edit', 'true');
                          setSearchParams(searchParams);
                        }}
                      >
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        {t('Bulk edit')}
                      </MenuItem>,
                    ]}
                    {refetch && [
                      <Divider key="refresh-divider" />,
                      <MenuItem
                        key="refresh-menu-item"
                        onClick={() => {
                          refetch();
                        }}
                      >
                        <ListItemIcon>
                          <Refresh />
                        </ListItemIcon>
                        {t('Refresh')}
                      </MenuItem>,
                    ]}
                    {moreActions && [
                      <Divider key="more-actions-divider" />,
                      moreActions,
                    ]}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {ImportDialog && (
        <ImportDialog
          open={showImportDialog}
          onImporterClose={() => setShowImportDialog(false)}
          onImportSuccess={() => {
            setShowImportDialog(false);
            refetch?.();
          }}
        />
      )}
      {ExportDialog && (
        <ExportDialog
          open={showExportDialog}
          where={where}
          onExporterClose={() => setShowExportDialog(false)}
        />
      )}
      <Filters
        tableName={tableName}
        isScraperSchema={isScraperSchema}
        expandedFilterType={expandedFilterType}
        quickFilters={quickFilters}
        queryParamsScope={queryParamsScope}
      />
    </>
  );
};
