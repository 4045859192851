// @flow

import * as React from 'react';

import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import { ToggleButton } from '@mui/material';
import { useFileDialog } from '@realadvisor/hooks';
import { sanitizeHtml } from '@realadvisor/html-sanitizer';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import createSingleLinePlugin from 'draft-js-single-line-plugin';

import { createLinkPlugin } from '../../controls/editor-link';
import { createSuggestionsPlugin } from '../../controls/editor-suggestions';
import { EditorToolbar } from '../../controls/editor-toolbar';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Image as ImageIcon } from '../../icons/image';
import type { editorSnippetLotQuery$data } from '../../shared/__generated__/editorSnippetLotQuery.graphql';
import { SnippetToggleButton } from '../../shared/editor-snippet';
import { useFileUpload } from '../../shared/file-upload';

import { mediaCss, renderPropertySnippet } from './renderPropertySnippet';
import { type InputFormat, RichEditorValue } from './RichEditorValue';

type ContentBlock = any;

type SuggestionsType = Array<{|
  id: string,
  // TODO: make name optional and handle its value on RichEditor side
  name: string,
|}>;

export type Props = {|
  placeholder?: string,
  className?: string,
  css?: any,
  value: RichEditorValue,
  onChange: (v: RichEditorValue) => void,
  suggestions: SuggestionsType,
  singleLine?: boolean,
  autoFocus?: boolean,
  required?: boolean,
  variant: 'standard' | 'filled' | 'outlined',
  lot?: editorSnippetLotQuery$data['lotById'],
  errorText?: ?string,
  dynamicSnippet?: boolean,
|};

const Snippet = (props: any) => {
  const { t, language } = useLocale();
  const { text } = useTheme();
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const data = entity.getData();

  const [collapsed, setCollapsed] = React.useState(true);

  if (data.type === 'html') {
    return (
      <>
        {!collapsed && (
          <div
            css={{
              whiteSpace: 'normal',
              blockquote: {
                marginLeft: 10,
                borderLeft: '2px solid #efefef',
                paddingLeft: 10,
              },
            }}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(data.html),
            }}
          />
        )}

        {collapsed === true && (
          <div
            css={{
              background: '#efefef',
              borderRadius: 2,
              fontWeight: text.font.bold,
              display: 'inline-block',
              padding: '0px 4px',
            }}
            onClick={() => setCollapsed(false)}
          >
            ...
          </div>
        )}
      </>
    );
  }

  return (
    <div css={mediaCss}>{renderPropertySnippet({ ...data, t, language })}</div>
  );
};

const ImageButton = ({ value, editorState, onChange }) => {
  const [uploadFile] = useFileUpload();

  const openFileDialog = useFileDialog({
    accept: 'image/*',
    multiple: false,
    onChange: files => {
      if (files.length !== 0) {
        uploadFile(files[0], 'images', logoImageUrl => {
          if (logoImageUrl != null) {
            const { origin, pathname } = new URL(logoImageUrl);
            const src = `${origin}${pathname}`;
            const contentState = value.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
              'IMAGE',
              'IMMUTABLE',
              { src },
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
              currentContent: contentStateWithEntity,
            });
            onChange(
              AtomicBlockUtils.insertAtomicBlock(
                newEditorState,
                entityKey,
                src,
              ),
            );
          }
        });
      }
    },
  });

  return (
    <ToggleButton
      tabIndex={-1}
      value="image"
      selected={false}
      onClick={openFileDialog}
    >
      <ImageIcon />
    </ToggleButton>
  );
};

const Image = props => {
  const { block, contentState } = props;
  const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
  return (
    <img
      src={src}
      role="presentation"
      css={{ maxWidth: 256, maxHeight: 128, objectFit: 'contain' }}
    />
  );
};

export class RichEditor extends React.Component<Props> {
  editorRef: any;
  suggestionsPlugin: any;
  Suggestions: any;
  singleLinePlugin: any;
  linkPlugin: any;
  imagePlugin: any;

  constructor(props: Props) {
    super(props);

    const { suggestionsPlugin, Suggestions } = createSuggestionsPlugin();

    this.suggestionsPlugin = suggestionsPlugin;
    this.Suggestions = Suggestions;

    this.singleLinePlugin = createSingleLinePlugin({
      stripEntities: false,
    });

    this.imagePlugin = createImagePlugin({
      imageComponent: props => <Image {...props} />,
    });

    this.linkPlugin = createLinkPlugin();

    this.editorRef = null;
  }

  setEditorRef: (element: any) => void = (element: any) => {
    this.editorRef = element;
  };

  focus: () => void = () => {
    this.editorRef.focus();
  };

  onChange: (editorState: any) => void = (editorState: EditorState) => {
    const { onChange, value } = this.props;
    onChange(value.setEditorState(editorState));
  };

  mediaBlockRenderer: (block: ContentBlock) => null | {|
    component: (props: any) => React.Node,
    editable: boolean,
  |} = (block: ContentBlock) => {
    if (block.getType() === 'atomic') {
      return {
        component: Snippet,
        editable: false,
      };
    }
    return null;
  };

  render(): React.Node {
    const {
      placeholder,
      value,
      className,
      singleLine,
      autoFocus,
      required,
      variant,
    } = this.props;
    const editorState = value.getEditorState();

    const Suggestions = this.Suggestions;
    const plugins = [this.linkPlugin, this.suggestionsPlugin, this.imagePlugin];

    if (singleLine === true) {
      plugins.push(this.singleLinePlugin);
    }

    const focusEditor = callback => {
      // without timeout focus is not applied
      setTimeout(() => {
        this.focus();
        // Editor must be in focus when doing changes,
        // otherwise Safari will blow up
        if (callback) {
          callback();
        }
      });
    };

    const utmParams =
      'utm_source=crm_email&utm_medium=email&utm_campaign=custom_link';

    const appendUTM = url => {
      if (url.startsWith('mailto:') || url.startsWith('tel:')) {
        return url;
      }

      return [url, utmParams].join(url.includes('?') ? '&' : '?');
    };

    const removeUTM = url => {
      return url.endsWith(utmParams)
        ? url.slice(0, url.length - utmParams.length - 1)
        : url;
    };

    let containerStyle;
    if (variant === 'filled') {
      containerStyle = {
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        height: '100%',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      };
    }

    return (
      <div className={className} css={containerStyle}>
        {singleLine !== true && (
          <EditorToolbar
            focusEditor={focusEditor}
            editorState={editorState}
            onChange={this.onChange}
            postprocessLink={appendUTM}
            cleanupLink={removeUTM}
          >
            <ImageButton
              value={this.props.value}
              editorState={editorState}
              onChange={this.onChange}
            />
            <SnippetToggleButton
              lot={this.props.lot}
              focusEditor={focusEditor}
              editorState={editorState}
              onChange={this.onChange}
              dynamicSnippet={this.props.dynamicSnippet}
            />
          </EditorToolbar>
        )}
        <div
          css={{
            '.public-DraftEditorPlaceholder-root': {
              // colors.grey500
              color: '#a2a2a2',
              position: 'absolute',
              pointerEvents: 'none',
            },
            // Fixes iOS zoom bug when font size is less than 16px. This targets only iOS devices
            // to set the font size to 16px but use the transform: scale property to scale down to mimic 14px.
            '@supports (-webkit-touch-callout: none)': {
              '.public-DraftEditor-content': {
                fontSize: '16px',
                transform: 'scale(0.92)',
                width: '108%',
                transformOrigin: 'top left',
              },
            },
            figure: {
              margin: 0,
            },
          }}
          className="RichEditor-editorContainer"
          onClick={() => this.focus()}
        >
          <Editor
            required={required}
            autoFocus={autoFocus}
            editorState={editorState}
            onChange={this.onChange}
            blockRendererFn={this.mediaBlockRenderer}
            placeholder={placeholder}
            ref={this.setEditorRef}
            spellCheck={true}
            plugins={plugins}
          />
          <Suggestions suggestions={this.props.suggestions} />
        </div>
      </div>
    );
  }
}

export function createEmptyValue(): RichEditorValue {
  return RichEditorValue.createEmpty();
}

export function updateSuggestions(
  prevEditorValue: RichEditorValue,
  suggestions: { [string]: string, ... },
): RichEditorValue {
  return RichEditorValue.updateSuggestions(prevEditorValue, suggestions);
}

export function pushRawStringValue(
  prevEditorValue: RichEditorValue,
  markup: string,
  options?: { suggestions?: { [string]: string, ... }, ... },
): RichEditorValue {
  return RichEditorValue.pushRawString(prevEditorValue, markup, options);
}

export function pushASTValue(
  prevEditorValue: RichEditorValue,
  ast: Array<any>,
  options?: { suggestions?: { [string]: string, ... }, ... },
): RichEditorValue {
  return RichEditorValue.pushAST(prevEditorValue, ast, options);
}

export function pushEmptyValue(
  prevEditorValue: RichEditorValue,
): RichEditorValue {
  return RichEditorValue.pushEmptyValue(prevEditorValue);
}

export function createValueFromString(
  markup: string,
  format: InputFormat,
): RichEditorValue {
  return RichEditorValue.createFromString(markup, format);
}

export function appendCustomHTML(
  prevEditorValue: RichEditorValue,
  html: string,
): RichEditorValue {
  return RichEditorValue.appendCustomHTML(prevEditorValue, html);
}
