import { memo, useCallback, useMemo } from 'react';

import {
  type InternalRefetchQueriesInclude,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Alert, LinearProgress } from '@mui/material';
import { type ErrorOption, type FieldPath } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import type { Commissions_Insert_Input } from '../../__generated__/graphql';
import {
  type FormDefinitionType,
  type NonInternalProps,
  RaForm,
} from '../../components/form/RaForm';
import { TRANSACTION_COMMISSIONS_FORM_UPDATE } from '../../pages/transactions/transactionQueries';

import {
  TransactionCommissionSplitsField,
  type TransactionCommissionSplitsFields,
  computeCommissionSplitsFields,
} from './TransactionCommissionSplitsField';
import {
  TransactionTotalCommissionField,
  type TransactionTotalCommissionFields,
  computeCommissionFields,
} from './TransactionTotalCommissionField';

const TRANSACTION_COMMISSIONS_FORM_QUERY = gql(/* GraphQL */ `
  query TransactionCommissionsFormQuery($id: uuid!) {
    property_transactions_by_pk(id: $id) {
      id
      ...TransactionCommissionsFormFragment
    }
  }
`);

type TransactionCommissionsFormData = TransactionTotalCommissionFields &
  TransactionCommissionSplitsFields;

type TransactionFormContext = {
  readonly: boolean;
  listing: {
    currency?: string | null;
    mandate_type?: string | null;
    property: {
      country_code?: string | null;
    };
  } | null;
};

const TransactionTotalCommissionFieldWrapper: React.FC<{
  disabled: boolean;
  listing: TransactionFormContext['listing'];
}> = memo(({ disabled, listing }) => (
  <TransactionTotalCommissionField
    currency={listing?.currency}
    isFixedFee={listing?.mandate_type === 'fixed_fee'}
    propertyCountryCode={listing?.property?.country_code}
    disabled={disabled}
  />
));

const TransactionCommissionSplitsFieldWrapper: React.FC<{
  disabled: boolean;
  listing: TransactionFormContext['listing'];
}> = memo(({ disabled, listing }) => (
  <TransactionCommissionSplitsField
    currency={listing?.currency}
    propertyCountryCode={listing?.property?.country_code}
    disabled={disabled}
  />
));

const transactionFormDefinition: FormDefinitionType<
  TransactionCommissionsFormData,
  TransactionFormContext
> = ({ t, context }) => [
  {
    name: 'terms',
    label: t('Terms'),
    type: 'category-title',
  },
  {
    name: 'purchase_price',
    label: t('Purchase price'),
    type: 'number',
    disabled: () => true,
  },
  {
    name: 'totalCommissionTitle',
    label: t('Total commission'),
    type: 'category-title',
  },
  {
    name: 'totalCommission',
    type: 'custom',
    element: (
      <TransactionTotalCommissionFieldWrapper
        disabled={context.readonly}
        listing={context.listing}
      />
    ),
    gridProps: { md: 12 },
  },
  {
    name: 'commissionSplitsTitle',
    label: t('Commission splits'),
    type: 'category-title',
  },
  {
    name: 'commissionSplits',
    type: 'custom',
    element: (
      <TransactionCommissionSplitsFieldWrapper
        disabled={context.readonly}
        listing={context.listing}
      />
    ),
    gridProps: { md: 12 },
  },
];

type TransactionCommissionsFormProps = {
  transactionId: string;
  readonly?: boolean;
  onCancel?: () => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

export const TransactionCommissionsForm: React.FC<
  TransactionCommissionsFormProps
> = ({ transactionId, readonly = false, onCancel, refetchQueries }) => {
  const { t } = useLocale();
  const { data, loading, error } = useQuery(
    TRANSACTION_COMMISSIONS_FORM_QUERY,
    {
      variables: { id: transactionId },
    },
  );
  const [updateCommissions] = useMutation(TRANSACTION_COMMISSIONS_FORM_UPDATE, {
    refetchQueries: [
      ...(refetchQueries ?? []),
      {
        query: TRANSACTION_COMMISSIONS_FORM_QUERY,
        variables: { id: transactionId },
      },
    ],
  });

  const listing = data?.property_transactions_by_pk?.lot ?? null;
  const purchasePrice = data?.property_transactions_by_pk?.purchase_price ?? 0;

  const defaultValues = useMemo<
    TransactionCommissionsFormData | undefined
  >(() => {
    if (data?.property_transactions_by_pk == null) {
      return undefined;
    }

    const {
      commissions,
      id: _,
      lot,
      purchase_price,
    } = data.property_transactions_by_pk;
    const totalCommission = commissions.find(({ type }) => type === 'total');
    const splitCommissions = commissions.filter(({ type }) => type === 'split');

    return {
      purchase_price,
      ...(computeCommissionFields(
        {
          purchase_price,
          total_commission: {
            amount: totalCommission?.amount,
            vat: totalCommission?.vat,
          },
        },
        'none',
        lot?.mandate_type === 'fixed_fee',
      ) ?? { total_commission: {} }),
      split_commissions:
        computeCommissionSplitsFields(
          {
            total_commission: {
              amount: totalCommission?.amount,
              vat: totalCommission?.vat,
            },
            split_commissions: splitCommissions,
          },
          'none',
          lot?.mandate_type === 'fixed_fee',
        )?.map(({ commission }) => commission) ?? [],
    };
  }, [data?.property_transactions_by_pk]);

  const onSubmit = useCallback(
    (formData: NonInternalProps<TransactionCommissionsFormData>) => {
      const { total_commission, split_commissions } = formData;
      const commissions: Commissions_Insert_Input[] = [
        {
          property_transaction_id: transactionId,
          ...total_commission,
          type: 'total',
        },
      ];

      commissions.push(
        ...split_commissions.map(commission => ({
          property_transaction_id: transactionId,
          ...commission,
          type: 'split',
        })),
      );

      return updateCommissions({
        variables: {
          id: transactionId,
          commissions,
        },
      });
    },
    [transactionId, updateCommissions],
  );

  if (loading) {
    return (
      <LinearProgress
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2000,
        }}
      />
    );
  }

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}

      {purchasePrice === 0 && (
        <Alert severity="warning" sx={{ m: 2 }}>
          {t(
            'This transaction has no purchase price, so the commissions cannot be calculated.',
          )}
        </Alert>
      )}

      <RaForm
        formDefinition={transactionFormDefinition}
        context={{
          readonly: readonly || purchasePrice === 0,
          listing,
        }}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onCancel={isDirty => !isDirty && onCancel?.()}
        validate={data => {
          const errors: [
            FieldPath<TransactionCommissionsFormData>,
            ErrorOption,
          ][] = [];

          if (data.total_commission.amount === 0) {
            errors.push([
              'total_commission.amount',
              {
                type: 'min',
                message: t(
                  'The total commission amount must be greater than 0',
                ),
              },
            ]);
          }

          errors.push(
            ...data.split_commissions.reduce<
              [FieldPath<TransactionCommissionsFormData>, ErrorOption][]
            >((acc, commission, index) => {
              if (commission.amount === 0) {
                acc.push([
                  `split_commissions.${index}.amount`,
                  {
                    type: 'min',
                    message: t(
                      'The commission split amount must be greater than 0, otherwise remove the commission split',
                    ),
                  },
                ]);
              }

              return acc;
            }, []),
          );

          return errors;
        }}
      />
    </>
  );
};
