import {
  type InternalRefetchQueriesInclude,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  DeleteForever,
  MapsHomeWorkOutlined,
  PaidOutlined,
  PhotoLibraryOutlined,
} from '@mui/icons-material';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { Alert, LinearProgress } from '@mui/material';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type DrawerTab,
  generateTab,
} from '../../components/drawer/DrawerTopBar';
import { PropertyForm } from '../../components/property-form/PropertyForm';
import { TransactionCommissionsForm } from '../../components/transactions/TransactionCommissionsForm';
import { useAppData } from '../../providers/AppDataProvider';
import { ListingPropertyImages } from '../listings/ListingPropertyImages';

import { TransactionFiles } from './TransactionFiles';
import { TransactionForm } from './TransactionForm';
import {
  DELETE_TRANSACTION,
  DRAWER_GET_PROPERTY_TRANSACTION,
} from './transactionQueries';

interface TransactionDrawerProps {
  maxWidth?: number;
  refetchQueries?: InternalRefetchQueriesInclude;
}

export const TransactionDrawer = ({
  maxWidth = 960,
  refetchQueries,
}: TransactionDrawerProps) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { transactionId } = useParams() as { transactionId: string };
  const { me } = useAppData();

  const { data, error, loading } = useQuery(DRAWER_GET_PROPERTY_TRANSACTION, {
    variables: { id: transactionId },
  });

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION);

  const matchA = useMatch('/transactions/:transactionId/:tabName/*');
  const matchB = useMatch(
    '/teams/:teamId/transactions/:transactionId/:tabName/*',
  );
  const matchC = useMatch(
    '/listings/:lotId/transaction/:transactionId/:tabName/*',
  );

  const tabName =
    matchA?.params.tabName ?? matchB?.params.tabName ?? matchC?.params.tabName;

  const tabsDef: [string, string, React.ReactElement?][] = [
    [t('Transaction'), 'transaction', <PaidOutlined />],
  ];

  const showPropertyTabs =
    data?.property_transactions_by_pk != null &&
    data.property_transactions_by_pk.lot_id == null;

  if (showPropertyTabs) {
    tabsDef.push([t('Property'), 'property', <MapsHomeWorkOutlined />]);
    tabsDef.push([t('Images'), 'images', <PhotoLibraryOutlined />]);
    tabsDef.push([t('Files'), 'files', <FilePresentOutlinedIcon />]);
  }

  tabsDef.push([t('Commissions'), 'commissions', <CalculateOutlinedIcon />]);

  const tabs: DrawerTab[] = tabsDef.map(([label, value, icon]) =>
    generateTab([searchParams.toString(), label, value, icon]),
  );

  const handleClose = () =>
    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });

  const drawerActions = [
    {
      label: t('Delete transaction'),
      disabled: false,
      onClick: () => {
        if (
          window.confirm(t('Are you sure you want to delete this transaction?'))
        ) {
          // Delete team and remove from cache
          deleteTransaction({
            variables: {
              id: transactionId,
            },
            update: cache => {
              cache.evict({ id: `property_transactions:${transactionId}` });
              cache.gc();
            },
          }).then(() => {
            // Close drawer
            handleClose();
          });
        }
      },
      icon: <DeleteForever />,
    },
  ];

  const isReadOnly =
    !me?.is_admin &&
    !me?.teams
      .filter(t => t.is_leader)
      .some(
        ({ team_id }) =>
          team_id === data?.property_transactions_by_pk?.buyer_broker_team_id ||
          team_id === data?.property_transactions_by_pk?.seller_broker_team_id,
      );

  return (
    <Drawer
      onClose={handleClose}
      title={t('Edit property transaction')}
      currentTab={tabName ?? 'transaction'}
      tabs={tabs}
      actions={drawerActions}
      maxWidth={maxWidth}
    >
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}

      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 2000,
          }}
        />
      )}

      <Routes>
        <Route
          index
          element={
            <Navigate
              to={{
                pathname: 'transaction',
                search: searchParams.toString(),
              }}
            />
          }
        />

        <Route
          path="transaction"
          element={
            <TransactionForm
              transactionId={transactionId}
              fullAccess={me?.is_admin}
              hideListingField={showPropertyTabs}
              onCancel={handleClose}
              refetchQueries={refetchQueries}
              readonly={isReadOnly}
              preventListingChange
            />
          }
        />
        <Route
          path="commissions"
          element={
            <TransactionCommissionsForm
              transactionId={transactionId}
              readonly={isReadOnly}
              onCancel={handleClose}
              refetchQueries={refetchQueries}
            />
          }
        />
        {showPropertyTabs && (
          <>
            <Route
              path="images/*"
              element={
                <ListingPropertyImages
                  propertyId={data?.property_transactions_by_pk?.property_id}
                  canEditImagesMeta={false}
                />
              }
            />
            <Route
              path="property"
              element={
                <PropertyForm
                  propertyId={data?.property_transactions_by_pk?.property_id}
                  onCancel={isDirty => !isDirty && handleClose()}
                />
              }
            />
            <Route
              path="files/*"
              element={<TransactionFiles transactionId={transactionId} />}
            />
          </>
        )}
      </Routes>
    </Drawer>
  );
};

export default TransactionDrawer;
