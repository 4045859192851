import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { type QueryOptions, useApolloClient, useQuery } from '@apollo/client';
import TableRowsOutlined from '@mui/icons-material/TableRowsOutlined';
import TableRowsRounded from '@mui/icons-material/TableRowsRounded';
import ViewKanbanOutlined from '@mui/icons-material/ViewKanbanOutlined';
import ViewKanbanRounded from '@mui/icons-material/ViewKanbanRounded';
import { Button, ListItem } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { useLeadsParams } from '../../../src/routes/Leads/LeadsFilters';
import {
  type GetLeadsQuery,
  type GetLeadsQueryVariables,
  type GetTenantSettingsQuery,
  type GetTenantSettingsQueryVariables,
  type Lead_Stages,
  type Leads_Order_By,
  Order_By,
} from '../../__generated__/graphql';
import { CreateLeadDrawer } from '../../components/create-lead/CreateLeadDrawer';
import type { filterAutocompleteRenderOptionProps } from '../../components/filters/quick-filters/QuickFilters';
import { IndicatorLeadStage } from '../../components/IndicatorLeadStage';
import { SmartListTitle } from '../../components/SmartListTitle';
import { type Me, useAppData } from '../../providers/AppDataProvider';
import { prepareWhereClauseQuery } from '../../utils/parseWhereClause';
import { GET_TENANT_SETTINGS } from '../settings/tenant-settings/tenantSettingsQueries';

import DialogAssignBroker from './DialogAssignBroker';
import DialogUpdatePipeline from './DialogUpdatePipeline';
import { useUpdateLeadsStageInCache } from './kanbanCache';
import { LeadDrawer } from './LeadDrawer';
import LeadsKanbanView from './LeadsKanbanView';
import LeadsListView from './LeadsListView';
import {
  GET_LEADS,
  GET_LEADS_COUNT,
  GET_PIPELINE_STAGES,
} from './leadsQueries';
import LegacyLeadDrawer from './LegacyLeadDrawer';

export type Lead = NonNullable<GetLeadsQuery['leads'][number]>;

export const initialLeadsListQuery = (
  searchParams: URLSearchParams,
  me: Me,
): QueryOptions => {
  const isAdmin = me?.is_admin;
  const myUserId = me?.id;
  const myLedUsersIds = me?.user_ids_in_led_teams ?? [];

  const where: GetLeadsQueryVariables['where'] = {
    // Here we manually exclude the leads from visibility (lead_agents)
    // TODO: Remove where clause once we fully migrate to non-exclusive leads
    _and: [
      prepareWhereClauseQuery(JSON.parse(searchParams.get('where') || '{}')),
      // Can be removed once we fully migrate to non-exclusive leads
      !isAdmin
        ? {
            // Performance hack to run next permission on a smaller set of leads
            _or: [
              {
                created_by: {
                  _in: [myUserId, ...myLedUsersIds],
                },
              },
              {
                broker_id: {
                  _in: [myUserId, ...myLedUsersIds],
                },
              },
            ],
            // Client side permission.
            // TODO: remove once we fully migrate to non-exclusive leads
            _not: {
              _and: [
                { lead_agents: {} },
                {
                  _and: [
                    {
                      broker_id: {
                        _nin: [...myLedUsersIds, myUserId],
                      },
                    },
                    {
                      created_by: {
                        _nin: [...myLedUsersIds, myUserId],
                      },
                    },
                  ],
                },
              ],
            },
          }
        : {},
      {
        mortgage_mortgage_type: { _is_null: true },
      },
    ],
  };

  const order_by: Leads_Order_By[] = JSON.parse(
    searchParams.get('order_by') || '[{ "requalified_or_created_at": "desc" }]',
  );

  return {
    query: GET_LEADS,
    variables: {
      where,
      offset: 0,
      limit: 25,
      order_by,
    },
  };
};

export const Leads = () => {
  const apolloClient = useApolloClient();
  const { t } = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const editMode = searchParams.get('edit') === 'true';
  const navigate = useNavigate();
  const { me } = useAppData();
  const viewType = searchParams.get('view') ?? 'list';
  const isAdmin = me?.is_admin;

  const updateLeadsStage = useUpdateLeadsStageInCache();

  const [assignBrokerDialog, setAssignBrokerDialog] = useState(false);
  const [pipelineDialog, setPipelineDialog] = useState(false);

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (selectedRows.length > 0 && !editMode) {
      setSelectedRows([]);
    }
  }, [selectedRows, editMode]);

  const { variables } = useMemo(
    () => initialLeadsListQuery(searchParams, me),
    [searchParams, me],
  );
  const { where, order_by } = variables ?? {};

  const legacyMatch = useMatch('/leads/*');

  const { data: tenantSettings } = useQuery<
    GetTenantSettingsQuery,
    GetTenantSettingsQueryVariables
  >(GET_TENANT_SETTINGS, {
    variables: {
      id: me?.tenant_id ?? '',
    },
  });

  const handleRefreshLeads = () => {
    apolloClient.refetchQueries({
      include: [GET_LEADS, GET_LEADS_COUNT],
    });
  };

  const [params] = useLeadsParams();

  const pipelineId =
    params.pipelineId_eq ??
    tenantSettings?.tenants_by_pk?.default_pipeline_id ??
    null;

  const { data: stagesData } = useQuery(GET_PIPELINE_STAGES, {
    variables: { pipeline_id: pipelineId ?? '' },
    skip: pipelineId == null,
  });

  const renderLeadStage = useCallback<filterAutocompleteRenderOptionProps>(
    (props, option) => {
      const status = stagesData?.pipelines_by_pk?.stages.find(
        stage => stage.label === option.value,
      )?.status;

      return (
        <ListItem {...props} key={`option-${option.value}`}>
          <IndicatorLeadStage status={status}>
            {option.label}
          </IndicatorLeadStage>
        </ListItem>
      );
    },
    [stagesData],
  );

  return (
    <>
      <SmartListTitle tableName="leads" defaultTitle={t('Leads')} />
      <ListToolbar
        tableName="leads"
        newLink={{
          pathname: 'new',
          search: searchParams.toString(),
        }}
        viewLinks={[
          {
            name: 'list',
            icon:
              viewType === 'list' ? (
                <TableRowsRounded />
              ) : (
                <TableRowsOutlined />
              ),
          },
          {
            name: 'kanban',
            icon:
              viewType === 'kanban' ? (
                <ViewKanbanRounded />
              ) : (
                <ViewKanbanOutlined />
              ),
          },
        ]}
        refetch={handleRefreshLeads}
        quickFilters={[
          {
            label: t('Date'),
            path: ['leads_bool_exp', 'requalified_or_created_at'],
          },
          {
            label: t('Source'),
            path: ['leads_bool_exp', 'source', 'name', '_in'],
            displayedColumn: 'label',
            filter: {
              path: ['source', 'type', '_in'],
              value: 'lead_source_types',
            },
          },
          {
            label: t('Pipeline step'),
            path: ['leads_bool_exp', 'stage', `name`, '_in'],
            displayedColumn: 'label',
            filter: {
              path: ['lead_stages', 'pipeline', 'name', '_eq'],
              value: 'brokerage',
            },
            orderBy: {
              order_nr: Order_By.AscNullsLast,
            } satisfies Partial<Record<keyof Lead_Stages, Order_By>>,
            filterAutocompleteRenderOption: renderLeadStage,
          },
          {
            label: t('Status'),
            path: ['leads_bool_exp', 'stage', 'status', '_in'],
          },
          {
            label: t('completed'),
            path: ['leads_bool_exp', 'completed'],
          },
          {
            label: t('Relation'),
            path: ['leads_bool_exp', 'relationship', '_in'],
          },
          {
            label: t('Appraisal Reason'),
            path: ['leads_bool_exp', 'appraisal_reason', '_in'],
          },
          {
            label: t('Sale Horizon'),
            path: ['leads_bool_exp', 'sale_horizon', '_in'],
          },
          {
            label: t('Contact'),
            path: ['leads_bool_exp', 'contact'],
          },
          {
            label: t('Broker'),
            path: ['leads_bool_exp', 'broker'],
            filter: {
              path: ['users', 'is_broker', '_eq'],
              value: true,
            },
          },
          {
            label: t('Team'),
            path: ['leads_bool_exp', 'broker', 'teams_users', 'team'],
          },
          {
            label: t('Location'),
            path: ['leads_bool_exp', 'property', 'places'],
          },
          {
            label: t('value'),
            path: ['leads_bool_exp', 'property', 'latest_appraisal', 'value'],
          },
          {
            label: t('Mandate Probability'),
            path: ['leads_bool_exp', 'mandate_probability'],
          },
          {
            label: t('Predicted Listing Date'),
            path: ['leads_bool_exp', 'predicted_listing_date'],
          },
          {
            label: t('Tags'),
            path: ['leads_bool_exp', 'leads_tags', 'dictionary', 'name', '_in'],
            displayedColumn: 'label',
            filter: {
              path: ['dictionaries', 'type', '_in'],
              value: 'tags_types',
            },
          },
        ]}
        bulkActions={
          <>
            <Button
              disableElevation
              disabled={selectedRows.length === 0}
              onClick={() => {
                setAssignBrokerDialog(true);
              }}
            >
              {t('Assign to broker')}
            </Button>
            <Button
              disableElevation
              disabled={selectedRows.length === 0}
              onClick={() => {
                setPipelineDialog(true);
              }}
            >
              {t('Change stage')}
            </Button>
            <DialogAssignBroker
              leadsIds={selectedRows as string[]}
              open={assignBrokerDialog}
              onCancel={() => setAssignBrokerDialog(false)}
            />
            <DialogUpdatePipeline
              leadsIds={selectedRows as string[]}
              pipeline="sales"
              open={pipelineDialog}
              initialPipelineId={pipelineId}
              onCancel={() => setPipelineDialog(false)}
              updateCacheFn={({ stageId, leadIds }) =>
                updateLeadsStage({
                  stageId,
                  leadIds,
                  where,
                  stagesData: stagesData ?? undefined,
                  isKanbanView: viewType === 'kanban',
                })
              }
            />
          </>
        }
        showRealCountOption
      />

      {viewType === 'list' ? (
        <LeadsListView
          isLegacyMode={!!legacyMatch}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          where={where}
          order_by={order_by}
          isAdmin={isAdmin ?? false}
        />
      ) : (
        <LeadsKanbanView
          isLegacyMode={!!legacyMatch}
          pipelineId={pipelineId}
          where={where}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="new/*"
            element={
              <CreateLeadDrawer
                onClose={() => {
                  searchParams.delete('stage_id');
                  setSearchParams(searchParams);

                  navigate({
                    pathname: '.',
                    search: searchParams.toString(),
                  });
                }}
                onCreate={leadId => {
                  const id = legacyMatch ? toGlobalId('Lead', leadId) : leadId;
                  navigate({
                    pathname: `./${id}`,
                    search: searchParams.toString(),
                  });
                }}
              />
            }
          />
          <Route
            path=":leadId/*"
            element={legacyMatch ? <LegacyLeadDrawer /> : <LeadDrawer />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default Leads;
