import * as React from 'react';

import { Button, Link } from '@mui/material';
import { Box, Flex } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { Check as CheckIcon } from '../icons/check';
import { useEmailClientConnect } from '../shared/email-client-connect';

type Props = {
  onSuccess: () => void;
  onError: () => void;
  onCancel?: () => void;
};

const Check = () => {
  const { colors } = useTheme();
  return (
    <Box mr={2}>
      <CheckIcon size={20} fill={colors.primaryMain} />
    </Box>
  );
};

export const ConnectEmailPanel = ({ onSuccess, onError, onCancel }: Props) => {
  const { t } = useLocale();
  const { text } = useTheme();
  const connectEmailClient = useEmailClientConnect();
  const [connectionError, setConnectionError] = React.useState<string | null>(
    null,
  );

  return (
    <div>
      <Box css={text.h6}>{t('settingUpYourEmailWithRealAdvisor')}</Box>

      <Box mt={3}>{t('increaseYourProductiveByLinkingYourEmails')}</Box>

      <Box mt={3}>
        <Flex mb={1}>
          <Check /> {t('sendEmailFromTheCRM')}
        </Flex>
        <Flex mb={1}>
          <Check /> {t('receiveAndShareEmailsWithinYourOrganisation')}
        </Flex>
        <Flex mb={1}>
          <Check /> {t('respondToEnquiriesAutomaticallyWithTemplates')}
        </Flex>
        <Flex>
          <Check /> {t('trackEmailOpensAndClicks')}
        </Flex>
      </Box>

      {connectionError != null && <Box mt={3}>{connectionError}</Box>}

      <Flex mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            connectEmailClient(error => {
              if (error == null) {
                onSuccess();
              } else {
                if (error.message !== 'access_denied') {
                  console.error(error);
                  setConnectionError(error.message);
                }
                onError();
              }
            });
          }}
        >
          {t('connectEmail')}
        </Button>

        {onCancel != null && (
          <Box ml={2}>
            <Button onClick={onCancel}>{t('Cancel')}</Button>
          </Box>
        )}
      </Flex>

      <Box mt={3} mb={1}>
        {t('theEmailSyncServicePoweredByNylas')} {t('subjectToTheir')}{' '}
        <Link
          color="primary"
          target="_blank"
          href={'https://www.nylas.com/legal/terms/'}
        >
          {t('termsOfUse')}
        </Link>{' '}
        {t('and')}{' '}
        <Link
          color="primary"
          target="_blank"
          href={'https://www.nylas.com/privacy-policy/'}
        >
          {t('privacyPolicy')}.
        </Link>
      </Box>
      <Box
        mt={3}
        mb={1}
        dangerouslySetInnerHTML={{
          __html: t('loginGoogleDisclaimer', {
            dataPolicyLink: `https://developers.google.com/terms/api-services-user-data-policy`,
          }),
        }}
      />
    </div>
  );
};
